import {
    FETCH_GROUPS,
    NOT_FOUND_GROUP,
    LOADING_GROUP,
    FETCH_PERMISSIONS,
    LOADING_PERMISSIONS,
    LOADING_USER_PERMISSIONS,
    FETCH_USER_PERMISSIONS
} from './types';

import {
    BASE_URL,GROUP_URL,GET_PERMISSION_URL,USER_PERMISSION
} from '../constants/api'
import { SearchURLGenerate } from '../constants/CustomMethod'

import { toast } from 'react-toastify'

import axios from 'axios';



// FETCH Partner
export const fetchGROUP = (pageNumber, parameters={}) => (dispatch, getState) => {
    dispatch({type: LOADING_GROUP});
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let search_key = [  ]
    
    var  search_url=SearchURLGenerate(parameters, search_key);
    
    if((search_url !== '' && search_url !== null) || pageNumber !== '' ){
       var url =GROUP_URL
        if(search_url !== '' && search_url !== null){  
            url = url + "?" +search_url;
        }else if (pageNumber){
            url=url + '?page=' + pageNumber
        }
    axios.get(url, {headers,})
        .then(response => {
            dispatch({
                type: FETCH_GROUPS,
                payload: response.data.results,
                count: response.data.count,
                next: response.data.next
            })
        })
    }else{
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_GROUP });
       
    }
};
export const getAllPermissionAction = (pageNumber, parameters={}) => (dispatch, getState) => {
    dispatch({type: LOADING_PERMISSIONS});
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
   
        
    axios.get(GET_PERMISSION_URL, {headers,})
        .then(response => {
            var permissions=[

            ]
            for(var i=0;i<response.data.length;i++){
                var content=response.data[i].content_type        
                let addedItem = permissions.findIndex(item => item.app_label ===content.app_label)             
                if(addedItem===-1){
                   
                    var  data={
                        'id':content.id,
                        'app_label':content.app_label,
                        'names':[{'name':response.data[i].name,'id':response.data[i].id}]
                    }
                    permissions.push(data)
                }else{
                    permissions[addedItem]["count"]=permissions[addedItem].names.length
                    permissions[addedItem].names.push({'id':response.data[i].id,'name':response.data[i].name})

                }

            }
          
            dispatch({
                type: FETCH_PERMISSIONS,
                payload: permissions,
                count: response.data.count,
                next: response.data.next
            })
        })
    
};

const getRowcheeckPermission=(permissions,total_assigned)=>{
    var data=false
    for (var p = 0, len = permissions.length; p < len; p++) {                
      if(permissions[p].count===total_assigned){
          data =true
        }
    } 
    return data
} 

const cheeckPermission=(permissions,id)=>{
    var data=false
    for (var p = 0, len = permissions.length; p < len; p++) {                
        for (var l=0;l<permissions[p].names.length;l++){
                    if (permissions[p].names[l].id==id){                   
                        data=true
                        break;
                    }
        }
    } 
    return data
} 
export const getGroupPermissionAction = (url,allPermissions) => (dispatch, getState) => {
    dispatch({type: LOADING_PERMISSIONS});
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

   
    axios.get(url, {headers,})
        .then(response => {

            var perm=response.data.permissions
            var permissions=[]
            for(var i=0;i<perm.length;i++){
                var content=perm[i].content_type        
                let addedItem = permissions.findIndex(item => item.app_label ===content.app_label)             
                if(addedItem===-1){      
                  
                    var  data={
                        'id':content.id,
                        'app_label':content.app_label,
                        'names':[{'name':perm[i].name,'id':perm[i].id,checked:true}]
                    }
                    permissions.push(data)
                }else{
                    permissions[addedItem]["count"]=permissions[addedItem].names.length
                    permissions[addedItem].names.push({'id':perm[i].id,'name':perm[i].name,checked:true})

                }
            }
           
            for (var i = 0, len = allPermissions.length; i < len; i++) {  
                
                allPermissions[i].checked =getRowcheeckPermission(permissions,allPermissions[i].count)
                
                for (var j=0;j<allPermissions[i].names.length;j++){
                    allPermissions[i].names[j].checked=cheeckPermission(permissions,allPermissions[i].names[j].id)                                
                }
            }
            dispatch({
                type: FETCH_PERMISSIONS,
                payload: allPermissions,
                count: response.data.count,
                next: response.data.next
            })
        })
    
};


export const createPermission = (data={}) => (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};

    let {token} = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
       
    }

    axios.post(USER_PERMISSION,data,{headers})
        .then(response => {
           if(response.data.success){
            toast.success(response.data.status)

           }else{
            toast.success(response.data.status)
           }
            }).catch(error => {
                console.log(error);
                toast.error(error.messages)
            })
     
         
   
};

export const getUserPermissionAction = (url) => (dispatch, getState) => {
    dispatch({type: LOADING_USER_PERMISSIONS});
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

   
    axios.get(url, {headers,})
        .then(response => {

           
            dispatch({
                type: FETCH_USER_PERMISSIONS,
                payload: response.data,
                count: response.data.count,
                next: response.data.next
            })
        })
    
};



import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { customerDetail } from '../../../actions/CustomerActions';

import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";


class CustomerDetail extends Component {

    componentDidMount() {
        alert(this.props.customerDetail.first_name)
        let id = this.props.match.params.id;
        //alert(id)
        this.props.customerDetail(id);

    }

    render() {
        const { customers } = this.props
        // if (rideDetails.districts !== undefined){
        //     console.log("Test",rideDetails.districts.name)
        // }


        const customerDetails = customers !== undefined ? (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Customer Information</h3>
                    <div className="float-right">
                        <Link to="/customer-list" className="btn btn-primary float-right text-white"><b>
                            <i className="fa fa-backward"></i>
                                Back To List</b>
                        </Link>

                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {/* <div className="col-3">

                        </div> */}
                        <div className="col-12">
                            <ul className="list-group list-group-flush">
                                {/* <li className="list-group-item">

                                    <span className="text-bold mr-3">District/Area :</span> {primarycategory.name !== undefined ? primarycategory.districts.name : null}
                                </li> */}
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Full Name :</span> {customers.first_name}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Phone No :</span> {customers.phone_no}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Email :</span> {customers.email}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Address :</span> {customers.address}
                                </li>








                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        ) : (
                <div className="center"><CircularProgress /></div>
            );
        return (
            <div>
                <h1>Customer detail</h1>
                {customerDetails}

            </div>
        );
    }
}

CustomerDetail.propTypes = {
    customerDetail: PropTypes.func.isRequired,
    // partner: PropTypes.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    // let id = ownProps.match.params.partner_id;
    // return {
    //     partner: state.partners.partners.find(partner => partner.id === id)
    // }

    customers: state.customers.customers,

});

export default connect(mapStateToProps, { customerDetail })(CustomerDetail);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import { fetchGroups, deleteGroup, updateGroup, addGroup } from "../../../actions/GroupAction";
import { required } from "../../../constants/Validate";

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'
import { propTypes } from 'react-widgets/lib/DateTimePicker';

import { Modal, Button } from "react-bootstrap";

// import { fetchRd, deleteRd } from '../../actions/ProductAction';
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

// Modals.setAppElement('#modalRender');

class UserRoleList extends Component {
  constructor() {
    super();
    this.opensweetalert = this.opensweetalert.bind(this)
    this.handleModalShow = this.handleModalShow.bind(this);
    this.saveRole = this.saveRole.bind(this);
    this.handleClose = this.handleClose.bind(this);
    //  this.attributeEdit = this.attributeEdit.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this)
    this.updateGroup = this.updateGroup.bind(this)
  }


  state = {
    role_name: "",
    name: "",
    id: '',
    show: false,
    showedit: false,
    isShowing: false,
    modalIsOpen: false,
    hideButton: false,
    modalIsOpen: false,
    hideButton: false,
  };

  handleModalShow(data) {
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleEditClose() {
    this.setState({ showedit: false, });
  }
  updateGroup() {
    var data = {
      name: this.state.role_name
    }
    this.props.updateGroup(this.state.id, data);
    this.setState({ showedit: false, });
  }
  onChangeedit() {
    this.setState({ showedit: true });
  }
  roleEdit(data) {
    this.setState({ showedit: true, role_name: data.name, id: data.id })
  }
  componentDidMount() {
    this.props.fetchGroups(1)
    // this.props.fetchRd(1);
  }

  opensweetalert(data) {

    Swal.fire({
      text: 'Are you want to delete ' + data.name + ' ?',
      // text: "You won't be able to revert this!",
      type: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.value) {
        if (data.id) {
          this.props.deletePrimaryCategory(data.id)
          //alert(JSON.stringify(result))
          Swal.fire(
            'Deleted!',
            'Your Data has been deleted ' + data.name + '.',
            'success'
          )
        }
      }
    })
  }


  openModal() {
    this.setState({ modalIsOpen: true });
  }

  afterOpenModal() {
    // references are now sync'd and can be accessed.
    this.subtitle.style.color = '#f00';
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  openModalHandler = () => {
    this.setState({
      isShowing: true
    })
  };

  closeModalHandler = () => {
    // const isShowing = this.state.isShowing;
    this.setState({
      isShowing: false
    })
  };
  handlePageClick = (data) => {
    var pageNo = Number(data.selected) + 1
    this.props.fetchGroups(pageNo)
  }

  onChange = e => this.setState({ [e.target.name]: e.target.value });

  onSubmit = e => {
    e.preventDefault();
    const { name } = this.state;

    this.props.fetchGroups('', this.state);
    this.setState({ hideButton: true })

  };
  saveRole() {
    //alert(this.state.name)
    var data = {
      name: this.state.name,
    };
    this.props.addGroup(data);
    this.setState({ show: false, name: "" });
    this.props.fetchGroups(1);
    // values={name:this.state.attribute_name}
  }
  render() {
    const { show, showedit } = this.state;
    const {permissions} = this.props
    const page = Math.ceil(this.props.count / 10)



    return (
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>User Role List </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                  <li className="breadcrumb-item active">User Role list</li>
                </ol>
              </div>
            </div>
          </div>

        </section>

        <div className="row">
          <ToastContainer />



          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">User Role List</h3>
                <div className="float-right">
                {CheckPermisionAction(permissions,'User','add_user_role')?

                  <button onClick={this.handleModalShow} className="btn btn-primary text-white">
                    <b> <i className="fa fa-plus-circle"></i>
                                                                                Add
                                                                                User Role</b></button>
                  :null}
                </div>
              </div>


              <div style={{ margin: "15px" }} className="card-tools">
                <div className="float-left">
                  <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                    <input className="form-control mr-sm-4" name="name" type="text"
                      onChange={this.onChange} placeholder="Name"
                      aria-label="Search" />
                  {CheckPermisionAction(permissions,'User','user_role_search')?

                    <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                      type="submit"><i className="fas fa-search"></i> Search
                                                                                </button>
                    :null}
                  </form>
                </div>


              </div>
              {/* <!-- /.card-header --> */}
              {/* <div id="modalRender">
                                        
                                      </div> */}
            {CheckPermisionAction(permissions,'User','view_user_role_list')?
              <div className="card-body table-responsive p-0">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.is_loading ? (
                      <tr className="text-center">
                        <td>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                        this.props.groups.map((group, index) => (
                          <tr key={group.id}>
                            <td>{index + 1}</td>
                            <td>{group.name}</td>
                            <td>
                            {CheckPermisionAction(permissions,'User','user_role_edit')?

                              <button className="btn btn-warning text-white" onClick={() => this.roleEdit(group)}>
                                <i
                                  className="fas fa-edit"
                                >
                                </i>
                              </button>
                            :null}
                              {/* <button
                                className="btn btn-danger"
                                onClick={() => this.opensweetalert(group)}
                              >
                                <i className="fas fa-trash"></i>
                              </button> */}

                            </td>
                          </tr>
                        ))
                      )}
                  </tbody>
                </table>
              </div>
              :null}
              {/* <!-- /.card-body --> */}
              <div className="card-footer clearfix">
                {page > 1 ? (
                  <ul className="pagination pagination-sm m-0 float-right">
                    <ReactPaginate
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={page}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  </ul>
                ) : null
                }
              </div>
              <Modal
                show={show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Role Name"
                    validate={required}
                    onChange={this.onChange}
                  />
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.saveRole}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showedit}
                onHide={this.handleEditClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Update Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    name="id"
                    type="hidden"
                    value={this.state.id}
                    className="form-control"
                    placeholder="role id"
                    validate={required}
                    onChange={this.onChange}
                  />
                  <input
                    name="role_name"
                    type="text"
                    value={this.state.role_name}
                    className="form-control"
                    placeholder="Role Name"
                    validate={required}
                    onChange={this.onChange}
                  />
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleEditClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.updateGroup}>
                    Update
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            {/* <!-- /.card --> */}
          </div>
        </div>
        {/* <!-- /.row --> */}
      </div>
    )
  }
}

UserRoleList.propTypes = {
  fetchGroups: PropTypes.func.isRequired,
  deleteGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  addGroup: PropTypes.func.isRequired


};

const mapStateToProps = state => ({
  groups: state.groups.groups,
  count: state.groups.count,
  next: state.groups.next,
  is_loading: state.groups.is_loading,
  permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchGroups, addGroup, deleteGroup, updateGroup })(UserRoleList);

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchUsers, addData, deleteUser } from "../../../actions/UserCreateActions"
import { fetchGroups } from "../../../actions/GroupAction"

import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';


import { toast } from "react-toastify";
import { USER_CREATE_URL, GET_PRIMARY_CATEGORY_URL } from "../../../constants/api";


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

// const submitToServer = (data) => {

//     console.log('data', data);


//     var form_data = { "name": data.name };

//     console.log('data', form_data);


//     let url = USEr_CREATE_URL;

//     let token = localStorage.getItem('token');
//     // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
//     console.log(token);
//     axios.post(url, form_data,
//         {
//             headers: {
//                 'content-type': 'application/json',
//                 'Authorization': `Token ${token}`
//             }

//         })
//         .then(res => {
//             console.log(res.data);
//             // dispatch(reset('PartnerAddForm'))
//             toast.success('Successfully added a new Primary Category.')
//         })
//         .catch(err => console.log(err))
// };


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddUser extends Component {

    componentDidMount() {
        console.log(this.props.fetchUsers())
        this.props.fetchUsers(0)
        this.props.fetchGroups()
    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }

    submit(event) {
        // const { reset } = this.props;
        // return sleep(1000)
        //     .then(() => {
        //         submitToServer(values);
        //         reset()
        //     })
        var form_data = new FormData();

        form_data.append("first_name", event.first_name ? event.first_name : '');
        form_data.append("last_name", event.last_name ? event.last_name : '');
        form_data.append("email", event.email ? event.email : '');
        form_data.append("phone_no", event.phone_no ? event.phone_no : '');
        form_data.append("password", event.password ? event.password : '');
        form_data.append('group', event.group ? event.group : '')
        if(event.group===1){
            form_data.append('is_admin',1)
        }
        // if (event.user_photo != '') {
        //     form_data.append("user_photo", event.user_photo ? event.user_photo : '');
        // }

        this.props.addData(form_data, USER_CREATE_URL, "User")




    }

    render() {


        const { handleSubmit, submitting, } = this.props;
        if (this.props.is_added) {
            setTimeout(() => {
                window.location.href = "/users-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add User </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">User add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">User Information</h3>
                        <div className="float-right">
                            <Link to="/users-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="first_name"
                                        type="text"
                                        component={renderField}
                                        label="First Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="last_name"
                                        type="text"
                                        component={renderField}
                                        label="Last Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="phone_no"
                                        type="text"
                                        component={renderField}
                                        label=" Phone No"
                                        validate={[required, phoneNumber]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                        validate={[required, email]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="password1"
                                        type="password"
                                        component={renderField}
                                        label="Password"
                                    // validate={required}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="password"
                                        type="password"
                                        component={renderField}
                                        label="Retype Password"
                                        validate={[required, match("password1")]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Groups</label>
                                    <span className="requiredField">*</span>
                                    <Field name="group" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select groups</option>
                                        {this.props.groups.map(pri_category => (
                                            <option key={pri_category.id} value={pri_category.id}>{pri_category.name}</option>
                                        ))}
                                    </Field>
                                </div>


                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddUser.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchUsers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({


    users: state.users.users,
    groups: state.groups.groups,
    is_added: state.users.is_added,
    is_updated: state.users.is_updated

});


AddUser = reduxForm({
    form: 'AddUser',
}
)(AddUser);


export default connect(mapStateToProps, { fetchUsers, addData, fetchGroups, deleteUser })(AddUser);

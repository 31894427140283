import {
    LOADING_SELLER,
    FETCH_SELLER,
    DETAIL_SELLER,
    UPDATE_SELLER,
    DELETE_SELLER,
    NOT_FOUND_SELLER,
    UPDATE_SELLER_STATUS
} from './types';

import { toast } from 'react-toastify'

import axios from 'axios';
import { SELLER_URL, SELLER_STATUS_URL, SELLER_ALL_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'


export const fetchSellers = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SELLER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["shop_name", "shop_trade_license","user__phone_no"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = SELLER_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
               
                dispatch({
                    type: FETCH_SELLER,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SELLER });

    }
};


export const fetchSellersForSelect = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SELLER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["user__phone_no", "user__email"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = SELLER_ALL_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                var results = []
                for (var i = 0; i < response.data.length; i++) {
                    results.push({ label: response.data[i].shop_name, value: response.data[i].id })
                }
                dispatch({
                    type: FETCH_SELLER,
                    payload: response.data ? results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SELLER });

    }
};


export const searchSellersForSelect = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_SELLER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    var url = SELLER_ALL_URL
        
    axios.get(url+ '?search=' + value, { headers, })
            .then(response => {
                var results = []
                for (var i = 0; i < response.data.length; i++) {
                    results.push({ label: response.data[i].shop_name, value: response.data[i].id })
                }
                dispatch({
                    type: FETCH_SELLER,
                    payload: response.data ? results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
  
};

// // Seller Detail
export const sellerDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = SELLER_URL + `${id}/`;
    axios
        .get(url, { headers })
        .then(response => {
            // alert(JSON.stringify(response.data))
            dispatch({
                type: DETAIL_SELLER,
                payload: response.data,
            })
        })
};

// // Delete Seller
export const deleteSeller = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(SELLER_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_SELLER,
                payload: id,
            });
            toast.success('Seller has been deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};


export const SelllerStatusChange = (data) => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let url = SELLER_STATUS_URL + `${data['id']}/`;

    axios
        .put(url, data, { headers })
        .then(response => {
            dispatch({
                type: UPDATE_SELLER_STATUS,
                payload: data
            });
            toast.success('Status has been changed successfully');
        }).catch(error => {
            console.log(error);
            toast.success('error');
        })
};
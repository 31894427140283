import {
    LOADING_SUPPORT_TICKET,
    FETCH_SUPPORT_TICKET,
    ADD_DATA,
    DETAIL_SUPPORT_TICKET,
    UPDATE_SUPPORT_TICKET,
    DELETE_SUPPORT_TICKET,
    NOT_FOUND_SUPPORT_TICKET,
    UPDATED_DATA,
    LOADING_SUPPORT_MESSAGES,
    FETCH_SUPPORT_MESSAGES,
    NOT_FOUND_SUPPORT_MESSAGES,
    LOADING_CONTACT_MESSAGES,
    FETCH_CONTACT_MESSAGES,
    NOT_FOUND_CONTACT_MESSAGES,


} from '../actions/types';

const initialState = {
    support_tickets: [],
    support_ticket: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
};

export const SupportTicketReducer= (state = initialState, action)=> {
    switch (action.type) {
        case LOADING_SUPPORT_TICKET:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_SUPPORT_TICKET:
            return {
                ...state,
                support_tickets: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case UPDATE_SUPPORT_TICKET:
            return {
                ...state,
                support_tickets: action.payload,
                is_loading: false
            };
        case DETAIL_SUPPORT_TICKET:
            return {
                ...state,
                is_getting: true,
                support_tickets: action.payload,

            };
        case DELETE_SUPPORT_TICKET:
            return {
                ...state,
                support_tickets: state.support_tickets.filter(support_ticket => support_ticket.id !== action.payload)
            };
        case ADD_DATA:
            return {
                ...state,
                is_added: true,
                support_tickets: [action.payload]
            };

        case UPDATED_DATA:
            return {
                ...state,
                is_updated: true,

            };

        case NOT_FOUND_SUPPORT_TICKET:
            return {
                ...state,
                support_tickets: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}





export const SupportMessagesReducer = (state = 
    {
        support_messages: [],
        support_message: {},
        count: null,
        next: null,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
    }, action)=> {
    switch (action.type) {
        case LOADING_SUPPORT_MESSAGES:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_SUPPORT_MESSAGES:
            return {
                ...state,
                support_messages: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        // case UPDATE_SUPPORT_TICKET:
        //     return {
        //         ...state,
        //         support_tickets: action.payload,
        //         is_loading: false
        //     };
        // case DETAIL_SUPPORT_TICKET:
        //     return {
        //         ...state,
        //         is_getting: true,
        //         support_tickets: action.payload,

        //     };
        // case DELETE_SUPPORT_TICKET:
        //     return {
        //         ...state,
        //         support_tickets: state.support_tickets.filter(support_ticket => support_ticket.id !== action.payload)
        //     };
        // case ADD_DATA:
        //     return {
        //         ...state,
        //         is_added: true,
        //         support_tickets: [action.payload]
        //     };

        // case UPDATED_DATA:
        //     return {
        //         ...state,
        //         is_updated: true,

        //     };

        case NOT_FOUND_SUPPORT_MESSAGES:
            return {
                ...state,
                support_messages: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const ContactMessagesReducer = (state = 
    {
        contact_messages: [],
        contact_message: {},
        count: null,
        next: null,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
    }, action)=> {
    switch (action.type) {
        case LOADING_CONTACT_MESSAGES:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_CONTACT_MESSAGES:
            return {
                ...state,
                contact_messages: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        // case UPDATE_SUPPORT_TICKET:
        //     return {
        //         ...state,
        //         support_tickets: action.payload,
        //         is_loading: false
        //     };
        // case DETAIL_SUPPORT_TICKET:
        //     return {
        //         ...state,
        //         is_getting: true,
        //         support_tickets: action.payload,

        //     };
        // case DELETE_SUPPORT_TICKET:
        //     return {
        //         ...state,
        //         support_tickets: state.support_tickets.filter(support_ticket => support_ticket.id !== action.payload)
        //     };
        // case ADD_DATA:
        //     return {
        //         ...state,
        //         is_added: true,
        //         support_tickets: [action.payload]
        //     };

        // case UPDATED_DATA:
        //     return {
        //         ...state,
        //         is_updated: true,

        //     };

        case NOT_FOUND_CONTACT_MESSAGES:
            return {
                ...state,
                contact_messages: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

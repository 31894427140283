import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
// import { Link } from "react-router-dom";
import {
    required,
    phoneNumber,
    // match,
    // validate
} from '../../../constants/Validate'
import { connect } from "react-redux";
// import {  } from "../../actions/auth";
import moment from 'moment'
// import momentLocalizer from 'react-widgets-moment';


import { toast } from "react-toastify";
import { BASE_URL,USER_CHANGE_PASSWORD } from "../../../constants/api";

// momentLocalizer(moment);



const submitToServer = (data) => {
    console.log('data', data);
    let form_data = new FormData();
    form_data.append('phone_no', data.phone_no);
    form_data.append('new_password', data.password);
 
    let url = USER_CHANGE_PASSWORD;
    let token = localStorage.getItem('token');

    console.log(token);
    axios.put(url, form_data,
        {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Token ${token}`
            }

        })
        .then(res => {
            console.log(res.data);
            // dispatch(reset('PartnerAddForm'))
            toast.success('Successfully Change your password.')
        })
        .catch(err => console.log(err))
};

// Input Type
const renderField = ({ input, label, type, meta: {touched, error, warning}}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};




// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


// function submit(values) {
//     return sleep(1000).then(() => {
//         console.log(values);
  
//         submitToServer(values);
        

//     });
// }


// Main form
class ChangePassword extends Component {

    // componentDidMount() {
    //     this.props.getAgents(1)
    // }

    submit(values) {
        const { reset } = this.props;
        return sleep(1000)
            .then(() => {
                submitToServer(values);
                reset()
            })
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Change Password</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                               
                              
                                <div className="col-md-12">
                                    <Field
                                        name="phone_no"
                                        type="number"
                                        component={renderField}
                                        label="Mobile No"
                                        validate={[required, phoneNumber]}
                                    />
                                </div>
                                
                                <div className="col-md-12">
                                    <Field
                                        name="password"
                                        type="password"
                                        component={renderField}
                                        label="New Password"
                                        validate={required}
                                    />
                                </div>
                                {/* <div className="col-md-12">
                                    <Field
                                        name="new_password"
                                        type="password"
                                        component={renderField}
                                        label="Retype Password"
                                        validate={[required, match("password")]}
                                    />
                                </div>   */}
                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Change
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


ChangePassword.propTypes = {
    
};


const mapStateToProps = state => ({
    
    
});

ChangePassword = reduxForm({
    form: 'ChangePassword',
    

}
)(ChangePassword);


export default connect(mapStateToProps)(ChangePassword);

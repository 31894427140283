import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { required } from "../../../constants/Validate";
import { Field, reduxForm } from "redux-form";
import axios from 'axios'
import isEqual from "react-fast-compare";

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import { getPrimaryCategory, addPrimaryCategory, updatePrimaryCategory, deletePrimaryCategory } from "../../../actions/PrimaryCategoryAction"
// import Modal from 'react-modal';
import { Modal, Button } from "react-bootstrap";
import { BulkAction } from '../../../actions/ProductAction';
import { USER_CREATE_URL,PRODUCT_BULK_URL,PRIMARY_CATEGORY_BULK_URL } from '../../../constants/api'

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'
import { propTypes } from 'react-widgets/lib/DateTimePicker';
import { GET_PRIMARY_CATEGORY_URL } from '../../../constants/api';
import Axios from 'axios';

// import { fetchRd, deleteRd } from '../../actions/ProductAction';
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

// Input Type
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);


const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};


class PrimaryCategoryList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
        this.handleModalShow = this.handleModalShow.bind(this)
        this.savePrimaryCategory = this.savePrimaryCategory.bind(this)
        this.updatePrimaryCategory = this.updatePrimaryCategory.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleEditClose = this.handleEditClose.bind(this)
        this.primaryCategoryEdit = this.primaryCategoryEdit.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        primary_name: "",
        name: "",
        id: "",
        primary_category_image: null,
        image: null,
        show: false,
        showedit: false,
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
        featureFile: null,
        allchecked: false,
        bulk:false,
        primcategories:[],
        load:false
    };

    handleModalShow(data) {
        this.setState({ show: true })
    }
    handleClose() {
        this.setState({ show: false })
    }
    handleEditClose() {
        this.setState({ showedit: false, })
    }
    updatePrimaryCategory() {
        var data = {
            name: this.state.primary_name,
            image: this.state.image
        }
        this.props.updatePrimaryCategory(this.state.id, data)
        this.setState({ showedit: false })
    }
    onChangeedit() {
        this.setState({ showedit: true })
    }
    componentDidMount() {
        this.props.getPrimaryCategory(1)
        // this.props.fetchRd(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deletePrimaryCategory(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.name + '.',
                        'success'
                    )
                }
            }
        })
    }


    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = []

            for (var i = 0; i < this.state.primcategories.length; i++) {
                if (this.state.primcategories[i].checked) {
                    results.push(this.state.primcategories[i].id)
                }
            }

            if (value === "1") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, PRIMARY_CATEGORY_BULK_URL
                )
            } else if (value === "0") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, PRIMARY_CATEGORY_BULK_URL
                )
            
            }else {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, PRIMARY_CATEGORY_BULK_URL
                )
            }
        }
    }


    toggleAllChange=(e)=> {
        const { value, checked } = e.target;
        let primcategories = [...this.props.primarycategory]
        primcategories.forEach(product => product.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, primcategories, bulk: true })
        } else {
            this.setState({ allchecked: checked, primcategories, bulk: false })
        }
    }

    onSingleChange = index => (e) => {
        let primcategories = [...this.state.primcategories]
        // alert(primcategories)
        primcategories[index]["checked"] = e.target.checked
        let recent_length = primcategories.filter(product => product.checked).length;

        if (primcategories.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        // this.setState({ primcategories })

    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.is_list) {
    //  alert(JSON.stringify(nextProps.is_list))
            if (nextProps.is_list !== prevState.load) {
                var primcategories = []
                
                for (var i = 0; i < nextProps.primarycategory.length; i++) {
                    Object.assign(nextProps.primarycategory[i], { 'checked': false });
                    primcategories.push(nextProps.primarycategory[i])
                    // alert(JSON.stringify(primcategories))
                }
                if (!isEqual(nextProps.primarycategory, prevState.primcategories)) {
                    return {

                        primcategories:[...primcategories],
                        load: true
                    }
                  }
                

            }
        }else return null;

    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { name } = this.state;
        console.log([name]);

        this.props.getPrimaryCategory('', this.state);
        this.setState({ hideButton: true })

    };
    primaryCategoryEdit(data) {
        this.setState({ showedit: true, primary_name: data.name, image: data.image, id: data.id })

    }
    savePrimaryCategory() {
        var data = {
            name: this.state.name,
            primary_category_image: this.state.primary_category_image
        }
        alert(JSON.stringify(data))
        this.props.addPrimaryCategory(data)
        this.setState({ show: false, name: "", image: "" })
        this.props.getPrimaryCategory(1)
    }

    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.getPrimaryCategory(pageNo)
    }

    handleImageChange = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }
    handleImageSubmit = (e) => {
        const {featureFile} =this.state
        e.preventDefault()
        console.log(this.state)
        let id = this.props.match.params.id;
        let form_data = new FormData()
        if (featureFile != null) {
            let newImage = new File([featureFile], featureFile.name, { type: featureFile.type });
            form_data.append("image", newImage, newImage.name);
          }
        // form_data.append('image', this.state.image, this.state.image.name)
        let token = localStorage.getItem('token')
        let url = GET_PRIMARY_CATEGORY_URL
        axios.post(url, form_data, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }
        })
            .then(res => {
                toast.success("Successfully updated")
                console.log(res.data)
                window.location.reload()
            })
            .catech(err => console.log(err))
    }
    render() {
  
        const { show, showedit,bulk,allchecked } = this.state;
        const { primarycategory, is_loading, count,permissions } = this.props
        const page = Math.ceil(count / 10)

        if (this.props.is_added) {
            setTimeout(function () {

                window.location.href = "/primary-category-list"
            }, 3000)
        }
        if (this.props.is_updated) {
            setTimeout(function () {

                window.location.href = "/primary-category-list"
            }, 3000)
        }
        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/primary-category-list'
            },
                1000);
        }
        // const isAdmin = this.props.auth.user.is_admin;

        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Primary Category List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Primary Category list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Primary Category List</h3>
                                <div className="float-right">
                                {CheckPermisionAction(permissions,'Category','add_primary_category')?

                                    <Link to='/primary-category'>
                                        <button
                                            className="btn btn-primary text-white"
                                            onClick={this.handleModalShow}
                                        >

                                            <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Primary Category</b>
                                        </button>
                                    </Link>
                                    :null}
                                </div>
                            </div>
                            

                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="name" type="text"
                                            onChange={this.onChange} placeholder="Name"
                                            aria-label="Search" />
                                {CheckPermisionAction(permissions,'Category','primary_category_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    :null}
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}

                            <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Action--</option>
                                            <option value="1" >Activated</option>
                                            <option value="0">Deactivated</option>
                                            {/* <option value="2">Delete</option> */}
                                            

                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>
                            {CheckPermisionAction(permissions,'Category','view_primary_category_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input type="checkbox"
                                                    checked={allchecked}
                                                    onChange={this.toggleAllChange}
                                                />
                                            </th>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th>Image</th>
                                            <th>Slug</th>
                                            <th>Status</th>
                                            <th>Action</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/primary-category-list" onClick={() => { window.location.href = "/primary-category-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> : primarycategory.length !== undefined && primarycategory.map((primarycategory, index) => (
                                            <tr key={primarycategory.id}>
                                                <td>
                                                    <input type="checkbox" onChange={this.onSingleChange(index)} checked={primarycategory.checked ? true : false} value={primarycategory.id} />
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{primarycategory.name}</td>
                                                <td>{
                                                    primarycategory.image != null ? (
                                                        <img src={primarycategory.image} height="100px" width="100px" alt="No Image" />
                                                    ) : null
                                                }</td>

                                                <td>{primarycategory.slug}</td>
                                                <td>{primarycategory.is_active ===true? "Active":"Inactive"} </td>
                                                <td className="btn-group btn-group-sm">

                                                    <div className="btn-group">
                                                

                                                        <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                            
                                                        <div className="dropdown-menu">
                                                            <a href={ "/primary-category-update/"+primarycategory.id} className="dropdown-item">Edit</a>
                                                            <div className="dropdown-divider"></div>
                                                            
                                                            <a href="#" onClick={() => this.opensweetalert(primarycategory)} className="dropdown-item">Delete</a>

                                                        </div>
                                                    </div>
                                                </td>
                                                {/* <td>
                                                    
                                                    {CheckPermisionAction(permissions,'Category','primary_category_edit')?
                                                        
                                                        <Link to={"/primary-category-update/"+primarycategory.id} className="btn btn-warning text-white" onClick={() => this.primaryCategoryEdit(primarycategory)}>
                                                            <i className="fas fa-edit text-white" >Edit</i>
                                                        </Link>
                                                    :null}
                                                        
                                                    {CheckPermisionAction(permissions,'Category','primary_category_delete')?

                                                            <button className="btn btn-danger text-white" onClick={() => this.opensweetalert(primarycategory)} >
                                                                <i className="fas fa-trash text-white" >Delete</i>
                                                            </button>
                                                    :null
                                                        }
                                                    

                                                </td> */}
                                            </tr>))}
                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>
                            <Modal
                                show={show}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Primary Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Primary Category Name"
                                        validate={required}
                                        onChange={this.onChange}
                                    />
                                    <div className="col-md-6">
                                        <label className="text-sm">Primary Category Image</label>
                                        <Field
                                            name="primary_category_image"
                                            type="file"
                                            component={FileInput}
                                            accept="image/*"
                                            label="Primary Category Image"

                                        // validate={[required]}
                                        />
                                    </div>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.savePrimaryCategory}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={showedit}
                                onHide={this.handleEditClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Update Primary Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <input
                                        name="id"
                                        type="hidden"
                                        value={this.state.id}
                                        className="form-control"
                                        placeholder="Primary Category id"
                                        validate={required}
                                        onChange={this.onChange}
                                    />
                                    <input
                                        name="primary_name"
                                        type="text"
                                        value={this.state.primary_name}
                                        className="form-control"
                                        placeholder="Primary Category Name"
                                        validate={required}
                                        onChange={this.onChange}
                                    />
                                    <div className="col-md-6">
                                        {/* <button type="button" className="btn btn-info" data-toggle="modal"
                                            data-target="#UploadDrivingLicense">
                                            Upload Driving License
                                        </button> */}
                                        <br />
                                        <br />
                                        <img src={this.state.image}
                                            style={{ width: "250px" }} alt="Not Found" />
                                        <p>
                                            <a href={this.props.primarycategory.image}>{this.props.primarycategory.image}</a>
                                        </p>
                                    </div>
                                    <form onSubmit={this.handleImageSubmit}>
                                        <div className="modal-body">
                                            <p>
                                                <input type="file"
                                                    id="image"
                                                    name="image"
                                                    onChange={this.handleImageChange}
                                                    required />
                                            </p>
                                        </div>

                                    </form>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleEditClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.updatePrimaryCategory}>
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

PrimaryCategoryList.propTypes = {
    getPrimaryCategory: PropTypes.func.isRequired,
    deletePrimaryCategory: PropTypes.func.isRequired,
    addPrimaryCategory: PropTypes.func.isRequired,
    BulkAction: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    primarycategory: state.primarycategory.primary_categories,
    count: state.primarycategory.count,
    next: state.primarycategory.next,
    is_loading: state.primarycategory.is_loading,
    is_added: state.primarycategory.is_added,
    is_updated: state.primarycategory.is_updated,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions,
    bulk: state.products.bulk,
    is_list:state.primarycategory.is_list,
});

// PrimaryCategoryList = reduxForm({
//     form: "PrimaryCategoryList",
// })(PrimaryCategoryList);
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { getPrimaryCategory, addPrimaryCategory, updatePrimaryCategory, deletePrimaryCategory,BulkAction })(PrimaryCategoryList);

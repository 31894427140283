import {
    LOADING_TAGS,
    FETCH_TAGS,
    DETAIL_TAGS,
    DELETE_TAGS,
    ADD_TAGS,
    UPDATE_TAGS,
    NOT_FOUND_TAGS,
} from '../actions/types';

const initialState = {
    tags: [],
    tag: {},
    count: null,
    next: null,
    is_loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_TAGS:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_TAGS:
            return {
                ...state,
                tags: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case UPDATE_TAGS:
            var filteredDataSource = state.tags.filter((item) => {
                if (item.id === action.payload.id) {
                    item.name = action.payload.name;
                }

                return item;
            });
            console.log(filteredDataSource)
            return {
                ...state,
                tags: filteredDataSource,
                is_loading: false
            };
        case DETAIL_TAGS:
            return {
                ...state,
                tag: action.payload,

            };
        case DELETE_TAGS:
            return {
                ...state,
                tags: state.tags.filter(tag => tag.id !== action.payload)
            };
        case ADD_TAGS:
            return {
                ...state,
                // tags: [...state, action.payload]
                tags: state.tags,
                is_loading: false
            };
        case NOT_FOUND_TAGS:
            return {
                ...state,
                tags: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

import React, {Component} from 'react';
class Notfound extends Component {
    render() {
        return (
            <div className="wrapper">
                <h1> 404 Not Found </h1>
            </div>
        );
    }
}

export default Notfound;
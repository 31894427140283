import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { primaryCategoryDetail } from '../../../actions/PrimaryCategoryAction';

import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";


class PrimaryCategoryDetail extends Component {

    componentDidMount() {

        let id = this.props.match.params.id;
        //alert(id)
        this.props.primaryCategoryDetail(id);

    }

    render() {
        const { primarycategory } = this.props
        // if (rideDetails.districts !== undefined){
        //     console.log("Test",rideDetails.districts.name)
        // }


        const primarCategoryDetails = primarycategory !== undefined ? (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Primary Category Information</h3>
                    <div className="float-right">
                        <Link to="/primary-category-list" className="btn btn-primary float-right text-white"><b>
                            <i className="fa fa-backward"></i>
                                Back To List</b>
                        </Link>

                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {/* <div className="col-3">

                        </div> */}
                        <div className="col-12">
                            <ul className="list-group list-group-flush">
                                {/* <li className="list-group-item">

                                    <span className="text-bold mr-3">District/Area :</span> {primarycategory.name !== undefined ? primarycategory.districts.name : null}
                                </li> */}
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Name :</span> {primarycategory.name}
                                </li>








                            </ul>
                        </div>
                    </div>
                </div>
            </div>

        ) : (
                <div className="center"><CircularProgress /></div>
            );
        return (
            <div>
                <h1>Primary Category detail</h1>
                {primarCategoryDetails}

            </div>
        );
    }
}

PrimaryCategoryDetail.propTypes = {
    primaryCategoryDetail: PropTypes.func.isRequired,
    // partner: PropTypes.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
    // let id = ownProps.match.params.partner_id;
    // return {
    //     partner: state.partners.partners.find(partner => partner.id === id)
    // }

    primarycategory: state.primarycategory.primary_category
});

export default connect(mapStateToProps, { primaryCategoryDetail })(PrimaryCategoryDetail);
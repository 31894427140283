import {
    LOADING_PRIMARY_CATEGORY,
    GETTING_PRIMARY_CATEGORY,
    ADD_PRIMARY_CATEGORY,
    DETAIL_PRIMARY_CATEGORY,
    UPDATE_PRIMARY_CATEGORY,
    DELETE_PRIMARY_CATEGORY,
    NOT_FOUND_PRIMARY_CATEGORY,

} from './types';

import { GET_PRIMARY_CATEGORY_URL, GET_ALL_PRIMARY_CATEGORY_URL } from '../constants/api';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { SearchURLGenerate } from '../constants/CustomMethod'
import axios from 'axios';



// FETCH PRIMARY CATEGORY
export const getPrimaryCategory = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_PRIMARY_CATEGORY })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_PRIMARY_CATEGORY_URL
        if (search_url !== '' && search_url !== null) {
            url = url  + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: GETTING_PRIMARY_CATEGORY,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_PRIMARY_CATEGORY });

    }



};

export const getPrimaryCategoryForSelect = (pageNumber, id) => (dispatch, getState) => {

    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    dispatch({ type: LOADING_PRIMARY_CATEGORY });
    var url = GET_ALL_PRIMARY_CATEGORY_URL

    if (id) {
        url = GET_ALL_PRIMARY_CATEGORY_URL + `${id}/`;
    } else if (pageNumber) {
        url = GET_ALL_PRIMARY_CATEGORY_URL + '?page=' + pageNumber
    }
    axios.get(url, { headers, })
        .then(response => {
            // alert(JSON.stringify(response.data.length))
            //    label: "Electronics", value: 1
            var results = []
            for (var i = 0; i < response.data.length; i++) {
                results.push({ label: response.data[i].name, value: response.data[i].id })
            }
            // console.log(results)
            dispatch({
                type: GETTING_PRIMARY_CATEGORY,
                payload: response.data ? results : [],
                count: response.data.count,
                next: response.data.next
            })

        })




};


// Primary Category Detail
export const primaryCategoryDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_PRIMARY_CATEGORY_URL + `${id}/`;

    axios.get(url, { headers })
        .then(response => {

            dispatch({
                type: DETAIL_PRIMARY_CATEGORY,
                payload: response.data,
            })
        })
};



// Update Primary Category
export const updatePrimaryCategory = (id, data) => (dispatch, getState) => {

    let headers = { "Content-Type": "multipart/form-data" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let form_data = new FormData();
    form_data.append('name', data.name);
    if (data.image) {
        form_data.append('image', data.image, data.image.name);
    }

    let url = GET_PRIMARY_CATEGORY_URL + `${id}/`;

    axios.put(url, form_data, { headers })
        .then(response => {
            console.log(response.data)
            dispatch({
                type: UPDATE_PRIMARY_CATEGORY,
                payload: response.data
            });

            toast.success("Primary Category successfully updated");
            console.log('Success');
        })
        .catch(err => {
            // dispatch();
            toast.error('Please try again', err);
            console.log(err);
        })
};


// Delete Primary Category
export const deletePrimaryCategory = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(GET_PRIMARY_CATEGORY_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_PRIMARY_CATEGORY,
                payload: id,
            });
            toast.success('Primary Category deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};

// Add Primary Category
export const addPrimaryCategory = (data) => (dispatch, getState) => {
    let headers = { "Content-Type": "multipart/form-data" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let form_data = new FormData()
    form_data.append('name', data.name)
    if (data.image) {
        form_data.append('image', data.image, data.image.name)
    }
    axios
        .post(GET_PRIMARY_CATEGORY_URL, form_data, { headers })
        .then(response => {

            // dispatch({
            //     type: ADD_PRIMARY_CATEGORY,
            //     payload: response.data.results ? response.data.results : response.data,
            // });
            toast.success('Primary Category added successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchPaymentMethods } from "../../../actions/orderActions"
import { addData } from "../../../actions/CustomerActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import RichTextEditor from 'react-rte';


import { toast } from "react-toastify";
import { BASE_URL, GET_PAYMENT_METHOD_URL } from "../../../constants/api";


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

// const submitToServer = (data) => {
//     // console.log('data', data);


//     var form_data = { "name": data.name };

//     console.log('data', form_data);


//     let url = GET_PAYMENT_METHOD_URL;

//     let token = localStorage.getItem('token');
//     // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
//     console.log(token);
//     axios.post(url, form_data,
//         {
//             headers: {
//                 'content-type': 'application/json',
//                 'Authorization': `Token ${token}`
//             }

//         })
//         .then(res => {
//             console.log(res.data);
//             // dispatch(reset('PartnerAddForm'))
//             toast.success('Successfully added a new Districts.')
//         })
//         .catch(err => console.log(err))
// };


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Text area type
const renderTextArea = ({
    input,
    label,
    meta: { touched, error, warning },
  }) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
      <div>
        <label className="text-sm">{label}</label>
        <div>
          <textarea
            {...input}
            placeholder={label}
            rows="3"
            cols="40"
            className={className}
          />
          {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  };
  

// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddPaymentMethod extends Component {
    static propTypes = {
        onChangeDetails: PropTypes.func
      };
    state={
        payment_type:'1',
        description: RichTextEditor.createEmptyValue(),

    }
  

    componentDidMount() {
        // this.props.getRides(0)
        this.props.fetchPaymentMethods(0)
    }
    onChangePaymentType = (e) => {

        // alert(e.target.value)
        this.setState({[e.target.name]:e.target.value})
    
    }
    onChangeProductSummary = (description) => {
        this.setState({ description });
        if (this.props.onChange) {
          // Send the changes up to the parent component as an HTML string.
          // This is here to demonstrate using `.toString()` but in a real app it
          // would be better to avoid generating a string on each change.
          this.props.onChangeDetails(
            description.toString('html')
          );
        }
      };

    submit(event) {

        var form_data = new FormData();
        Object.keys(event).forEach(key => form_data.append(key, event[key]));
        form_data.append('payment_type',this.state.payment_type)
        form_data.append("description", this.state.description.toString('html') ? this.state.description.toString('html') : '');
        // var form_data = {
        //     name: values.name
        // }
        // alert(JSON.stringify(event))
        this.props.addData(form_data, GET_PAYMENT_METHOD_URL, "Payment")

    }

    render() {
        
        if (this.props.is_added) {
            setTimeout(() => {
                window.location.href = "/payment-method-list"
            }, 3000);
        }

        const { handleSubmit, submitting } = this.props;
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Payment Method </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Payment Method add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Payment Method Information</h3>
                        <div className="float-right">
                            <Link to="/payment-method-list" className="btn btn-info float-right text-white"><b>
                                Back</b>
                            </Link>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                        <div className="col-md-12 form-group clearfix" >
                                <div className="col-md-6 icheck-primary d-inline">
                                    <input type="radio" id="radioPrimary1" onChange={this.onChangePaymentType} value="1" name="payment_type" checked={this.state.payment_type==="1"}/>
                                    <label for="radioPrimary1">Bank Account 
                                    </label>
                                </div>
                                <div className="col-md-6 icheck-primary d-inline">
                                    <input type="radio" id="radioPrimary2" value="2" onChange={this.onChangePaymentType} name="payment_type" checked={this.state.payment_type==="2"}/>
                                    <label for="radioPrimary2">Mobile Wallet
                                    </label>
                                </div>
                                
                                </div>
                                {this.state.payment_type==='1' ? (
                               <>     
                                <div className="col-md-6">
                                    <Field
                                        name="account_name"
                                        type="text"
                                        component={renderField}
                                        label="Account Name"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="account_number"
                                        type="text"
                                        component={renderField}
                                        label="Account Number"
                                     //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="bank_name"
                                        type="text"
                                        component={renderField}
                                        label="Bank Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="branch_name"
                                        type="text"
                                        component={renderField}
                                        label="Branch Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="routing_number"
                                        type="number"
                                        component={renderField}
                                        label="Routing Number"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="description"
                                        type="text"
                                        component={renderTextArea}
                                        label="Description"
                                    />
                                    </div> */}
                                    <div class="col-md-12">
                                    {/* <Field
                                            name="product_details"
                                            type="text"
                                            component={renderTextArea}
                                            label="Product Details"
                                        // required={true}
                                        /> */}
                                    <label>Description</label>
                                    <RichTextEditor
                                        // toolbarConfig={toolbarConfig}
                                        value={this.state.description}
                                        onChange={this.onChangeProductSummary}
                                        
                                    />
                                    </div>
                                </>
                                ):
                                <>
                                <div className="col-md-6">
                                    <label>Mobile Banking</label>
                                    <Field name="mobile_banking_type" component="select" className="form-control"
                                    required={true}
                                    >
                                    <option value="">Select Mobile Banking</option>
                                    <option value="1">Bkash</option>
                                    <option value="2">Nogod</option>
                                    <option value="3">Rocket</option>

                                </Field>
                                </div>
                                  <div className="col-md-6">
                                    <Field
                                        name="wallet_number"
                                        type="text"
                                        component={renderField}
                                        label="Number"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                    </div>
                                    {/* <div className="col-md-6">
                                    <Field
                                        name="description"
                                        type="text"
                                        component={renderTextArea}
                                        label="Description"
                                    />
                                    </div> */}
                                    <div class="col-md-12">
                                    {/* <Field
                                            name="product_details"
                                            type="text"
                                            component={renderTextArea}
                                            label="Product Details"
                                        // required={true}
                                        /> */}
                                    <label>Description</label>
                                    <RichTextEditor
                                        // toolbarConfig={toolbarConfig}
                                        value={this.state.description}
                                        onChange={this.onChangeProductSummary}
                                        
                                    />
                                    </div>
                                 </>   
                                }
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddPaymentMethod.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchPaymentMethods: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

    payments: state.payments.payments,
    is_added: state.payments.is_added


});


AddPaymentMethod = reduxForm({
    form: 'AddPaymentMethod',
}
)(AddPaymentMethod);


export default connect(mapStateToProps, { fetchPaymentMethods, addData })(AddPaymentMethod);
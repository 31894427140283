import {
    USER_LOADED,
    USER_LOADING,
    LOGIN_FAILED,
    LOGIN_SUCCESSFUL,
    LOGOUT_SUCCESSFUL,
    // REGISTRATION_SUCCESSFUL,
    // REGISTRATION_FAILED,
    AUTHENTICATION_ERROR,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
} from './types';

import {
    LOGIN_URL,LOAD_USER_URL,LOGOUT_URL,BASE_URL
} from '../constants/api'
import { toast } from 'react-toastify'

import axios from 'axios';
export const loadUser = () => {
    return (dispatch, getState) => {
        dispatch({type: USER_LOADING});

        let headers = {
            "Content-Type": "application/json",
        };

        const token = getState().auth.token;

    //   alert(token)
        if (token) {
            headers["Authorization"] = `Token ${token}`;
        }

        return fetch(LOAD_USER_URL, {headers,})
            .then(res => {
             
                // alert(JSON.stringify(LOGIN_URL))
                if (res.status < 500) {
                  
                    return res.json().then(data => {
                       // alert(JSON.stringify(data))
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
              
                if (res.status === 200) {
                    dispatch({type: USER_LOADED, user: res.data});
                    return res.data;
                } else if (res.status >= 400 && res.status < 500) {
                    dispatch({type: AUTHENTICATION_ERROR, data: []});
                    throw res.data;
                }else{
                    
                    dispatch({type: AUTHENTICATION_ERROR, data: res.data});
                    throw res.data;
                }
            })
    }
};

export const login = (phone_no, password) => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};

        let body = JSON.stringify({phone_no, password});

       
        return fetch(LOGIN_URL, {headers, body, method: "POST"})
            .then(res => {
                if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    return res;
                }
            })
            .then(res => {
               
                if (res.status === 200) {
                    dispatch({type: LOGIN_SUCCESSFUL, data: res.data});
                    return res.data;
                } else if (res.status === 403 || res.status === 401) {
                    dispatch({type: AUTHENTICATION_ERROR, data: res.data});
                    return res.data;
                } else {
                    console.log( res.data)
                    dispatch({type: LOGIN_FAILED, data: res.data});
                    return res.data;
                }
            })
    }
};

// export const register = (phone_no, password) => {
//     return (dispatch, getState) => {
//         let headers = {"Content-Type": "application/json"};
//         let body = JSON.stringify({phone_no, password});

//         return fetch(BASE_URL + "api/auth/register/", {headers, body, method: "POST"})
//             .then(res => {
//                 if (res.status < 500) {
//                     return res.json().then(data => {
//                         return {status: res.status, data};
//                     })
//                 } else {
//                     console.log("Server Error!");
//                     throw res;
//                 }
//             })
//             .then(res => {
//                 if (res.status === 200) {
//                     dispatch({type: REGISTRATION_SUCCESSFUL, data: res.data});
//                     return res.data;
//                 } else if (res.status === 403 || res.status === 401) {
//                     dispatch({type: AUTHENTICATION_ERROR, data: res.data});
//                     throw res.data;
//                 } else {
//                     dispatch({type: REGISTRATION_FAILED, data: res.data});
//                     throw res.data;
//                 }
//             })
//     }
// };

export const logout = () => {
    return (dispatch, getState) => {
        let headers = {"Content-Type": "application/json"};

        return fetch(LOGOUT_URL, {headers, body: "", method: "POST"})
            .then(res => {
                if (res.status === 204) {
                    return {status: res.status, data: {}};
                } else if (res.status < 500) {
                    return res.json().then(data => {
                        return {status: res.status, data};
                    })
                } else {
                    console.log("Server Error!");
                    throw res;
                }
            })
            .then(res => {
                if (res.status === 204) {
                    // alert(logout)
                    dispatch({type: LOGOUT_SUCCESSFUL});
                    return res.data;
                } else if (res.status === 403 || res.status === 401) {
                    dispatch({type: AUTHENTICATION_ERROR, data:[]});
                    throw res.data;
                }
            })
    }
};





export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token });

    try {
        await axios.post( BASE_URL +`/auth/users/activation/`, body, config);

        dispatch({
            type: ACTIVATION_SUCCESS,
        });
    } catch (err) {
        dispatch({
            type: ACTIVATION_FAIL
        })
    }
};

export const reset_password = (email) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
    // alert(JSON.stringify(email))
    const body = JSON.stringify({ email });

    try {
        await axios.post( BASE_URL +"/auth/djoser/users/reset_password/", body, config);
        toast.success('Please Check your email for changing password')
        dispatch({
            type: PASSWORD_RESET_SUCCESS
        });
    } catch (err) {
        dispatch({
            type: PASSWORD_RESET_FAIL
        });
    }
};

export const reset_password_confirm = (uid, token, new_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };
// alert(JSON.stringify(new_password))
    const body = JSON.stringify({ uid, token, new_password });

    try {
        await axios.post(BASE_URL +"/auth/djoser/users/reset_password_confirm/", body, config);
        toast.success('Your password has been successfully changed!')
        dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS
        });
    } catch (err) {
        toast.error("Please try again!")
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL
        });
    }
};

import {
    UPSELL_FETCH_PRODUCT,
    LOADING_UPSELL_PRODUCT,
    UPSELL_NOT_FOUND,
    CROSSSELL_FETCH_PRODUCT,
    CROSSSELL_NOT_FOUND,
    LOADING_CROSSSELL_PRODUCT

} from '../actions/types';


export const UpsellProductReducer = (state = {
    upsellProducts: [],
    count: null,
    next: null,
    is_product_getting: false,
  
}, action) => {
    switch (action.type) {
        case LOADING_UPSELL_PRODUCT:
            return {
                ...state,
                is_loading: true
            };
    case UPSELL_FETCH_PRODUCT:
        // alert("Test"+JSON.stringify(action.payload))
            return {
                ...state,
                upsellProducts: action.payload,
                count: action.count,
                next: action.next,
                is_product_getting: true,
       
            };
        case UPSELL_NOT_FOUND:
            return {
                ...state,
                upsellProducts: [],
                count: 0,
                next: 0,
                is_product_getting: false
            };
        default:
            return state;
    }
}

export const CrossSellProductReducer = (state = {
    crossSellProducts: [],
    count: null,
    next: null,
    is_product_getting: false,
  
}, action) => {
    switch (action.type) {
        case LOADING_CROSSSELL_PRODUCT:
            return {
                ...state,
                is_loading: true
            };
    case CROSSSELL_FETCH_PRODUCT:
            return {
                ...state,
                crossSellProducts: action.payload,
                count: action.count,
                next: action.next,
                is_product_getting: true,
       
            };
        case CROSSSELL_NOT_FOUND:
            return {
                ...state,
                crossSellProducts: [],
                count: 0,
                next: 0,
                is_product_getting: false
            };
        default:
            return state;
    }
}
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link, Prompt } from "react-router-dom";
import { required } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchBlogPost,blogPostDetail,fetchBlogCategory,fetchBlogTagForSelect,fetchBlogTag, blogCategoryDetail } from "../../../actions/BlogAction"
import {updateData } from "../../../actions/ProductAction"
import Select from "react-select";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
// import { getProductTagsForSelect } from '../../../actions/TagsActions';
// import { getChildCategoryForSelect,getChildCategory } from "../../../actions/ChildCategoryAction"
import RichTextEditor from 'react-rte';


import { toast } from "react-toastify";
import { BLOG_URL } from "../../../constants/api";


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}




// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);


const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class BlogUpdate extends Component {
    state = {
        errorChild: false,
        errorTags: false,
        contents:false,
        selected_tags: "",
        selected_child: null,
        child: null,
        content: RichTextEditor.createEmptyValue(),
    }

    static propTypes = {
        onChange: PropTypes.func
    };

    componentDidMount() {
        // alert(JSON.stringify(this.props.blog_posts))
        // alert(JSON.stringify(this.props.blog_categories))
        let id = this.props.match.params.id;
        this.props.blogPostDetail(id)
        this.props.fetchBlogCategory()

        this.props.fetchBlogTagForSelect("")

    }
    static getDerivedStateFromProps(nextProps, prevState) {
        // alert(JSON.stringify(nextProps.blog_posts.blog_category))
        if (nextProps.is_getting === true) {
            if (nextProps.is_getting !== prevState.contents) {
                if (nextProps.blog_posts.length == undefined) {
                    var blog_posts = nextProps.blog_posts
                    
    
                    if (blog_posts.tag.length > 0) {
                        var tag = blog_posts.tag
                        var tagsdatas = []
                        for (var i = 0; i < tag.length; i++) {
                            tagsdatas.push({ label: tag[i].name, value: tag[i].id })
                        }
                    }
                    
                return {

                    content: RichTextEditor.createValueFromString(blog_posts ? blog_posts.content :'', 'html'),
                    selected_tags: tagsdatas !==undefined && tagsdatas !==null ? tagsdatas.reverse():"",
                    blog_category: blog_posts.blog_category!==undefined && blog_posts.blog_category  !==null ? blog_posts.blog_category.name :"",
                    contents: true
                }
            }
            }
        }
        return null
    }

    onTagsChange = (selected_tags) => {
        this.setState({ selected_tags });
    };
 


    onChangeDetails = (content) => {
        this.setState({ content });
        if (this.props.onChange) {
            this.props.onChangeDetails(
                content.toString('html')
            );
        }
    };

    onChangeText = (e) => {

        this.setState({ [e.target.name]: e.target.value })

    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }
     submitToServer = (id,data) => {
        //  alert(JSON.stringify(data))
        const { selected_child,selected_tags } = this.state
        console.log('data', data);
        let form_data = new FormData();
    
        // form_data.append('name', data.name);

        if (data.images !==null && data.images !=='' && data.images !==undefined) {
            form_data.append('image', data.images, data.images.name);
        } 
        form_data.append('title', data.title)
        form_data.append('author_name', data.author_name)
        form_data.append('content', this.state.content.toString('html') ? this.state.content.toString('html') : '')
        form_data.append('video_url', data.video_url)
        form_data.append('blog_category', data.blog_category ? data.blog_category: '' )
        if (selected_tags === null || selected_tags === '') {
            this.setState({ errorTags: true })
            return false
          }
    
        if (selected_tags != null) {

        form_data.append("tag", JSON.stringify(selected_tags));
    
        }
 
    
    
        let url = BLOG_URL +`${id}/`;
    
        let token = localStorage.getItem('token');
        // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
        console.log(token);
        axios.put(url, form_data,
            {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Authorization': `Token ${token}`
                }
    
            })
            .then(res => {
                console.log(res.data);
                // dispatch(reset('PartnerAddForm'))
                toast.success('Successfully Updated Blog.')
            })
            .catch(err => console.log(err))
    };

    submit(values) {
        const {selected_tags} = this.state
        const { reset } = this.props;
        return sleep(1000)
            .then(() => {
                let id = this.props.match.params.id
                this.submitToServer(id,values);
                reset()
            })
    }

    render() {
        const { selected_tags, errorTags, errorChild, child, selected_child } = this.state
        const { handleSubmit, submitting, blog_tags, blog_categories } = this.props;
        if (this.props.is_updated) {
            setTimeout(function () {

                window.location.href = "/blog-post-list"
            }, 3000)
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Blog </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Blog Post Update</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Blog Post Information</h3>
                        <div className="float-right">
                            <Link to="/blog-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="title"
                                        type="text"
                                        component={renderField}
                                        label="Title"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="content"
                                        type="text"
                                        component={renderField}
                                        label="Content"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-12">

                                    <label>Content</label>
                                    <RichTextEditor
                                        // toolbarConfig={toolbarConfig}
                                        value={this.state.content}
                                        onChange={this.onChangeDetails}
                                    />
                                    <br/>
                                    </div>
                                <div className="col-md-6">
                                    <Field
                                        name="author_name"
                                        type="text"
                                        component={renderField}
                                        label="Author name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                
                                <div className="col-md-6">
                                    <img src={this.props.blog_posts.image} style={{width:150,height:150}}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm"> Blog Image</label>
                                    <Field
                                        name="images"
                                        type="file"
                                        component={FileInput}
                                        accept="image/*"
                                        label=" Image"
                                    // validate={[required]}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Field
                                        name="video_url"
                                        type="text"
                                        component={renderField}
                                        label="Youtube URL"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="blog_category"
                                        type="text"
                                        component={renderField}
                                        label="Blog Category"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                {/* <div className="col-md-6">
                                    <Field
                                        name="blog_child_category"
                                        type="text"
                                        component={renderField}
                                        label="Blog Category"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <label>Blog Category</label>

                                    <Field
                                        name="blog_category"
                                        component="select"
                                        className="form-control"

                                    // onChange={this.handleSelectedValues(idx)}
                                    >
                                        <option value="">--Select Blog Category--</option>

                                        {blog_categories.map((blg_child_cat, index) => (
                                        <option key={index} value={blg_child_cat.id}>{blg_child_cat.name}</option>

                                        ))}
                                    </Field>
                                </div>
                                {/* <div className="col-md-6">
                                    <label>Blog Category</label>
                                    <span className="requiredField">*</span>
                                    <Select
                                        value={selected_child}
                                        onChange={this.childChange}
                                        options={childcategory}
                                        placeholder="Blog Category"
                                        isClearable
                                    />{errorChild ? <span className="text-danger">Select Child Category</span> : ''}
                                </div> */}
                                <div className="col-md-6">
                                    <label> Tags</label>

                                    <Select
                                        value={selected_tags}
                                        onChange={this.onTagsChange}
                                        options={blog_tags}
                                        placeholder="Tags"
                                        isMulti
                                        isClearable
                                    />
                                    {this.state.errorTags ? <span className="text-danger">Select Tags</span> : ''}
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="tags"
                                        type="text"
                                        component={renderField}
                                        label="Tag"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}

                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}

BlogUpdate.propTypes = {
    // getRides:PropTypes.func.isRequired,
    blogPostDetail: PropTypes.func.isRequired,
    fetchBlogTagForSelect:PropTypes.func.isRequired
};

const mapStateToProps = state => ({

    blog_posts: state.blog_posts.blog_posts,
    initialValues: state.blog_posts.blog_posts,
    blog_tags: state.blog_tags.blog_tags,
    blog_categories: state.blog_categories.blog_categories,
    is_getting: state.blog_posts.is_getting,
    is_updated: state.blog_posts.is_updated

});


BlogUpdate = reduxForm({
    form: 'BlogUpdate',
    enableReinitialize: true
}
)(BlogUpdate);


export default connect(mapStateToProps, { blogPostDetail, updateData, fetchBlogCategory, fetchBlogTagForSelect })(BlogUpdate);

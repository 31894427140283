import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import { fetchUsers, deleteUser } from "../../../actions/UserCreateActions"
import { fetchGroups } from "../../../actions/GroupAction"
import Modal from 'react-modal';
import { BASE_URL } from '../../../constants/api';
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'
import { propTypes } from 'react-widgets/lib/DateTimePicker';
import { email } from '../../../constants/Validate';


// import { fetchRd, deleteRd } from '../../actions/ProductAction';
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class UserLists extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        phone_no: "",
        email: "",
        group:"",
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
    };



    componentDidMount() {
        this.props.fetchUsers(1)
        this.props.fetchGroups()
        // this.props.fetchRd(1);
    }

    handleChangeGroup = event => {
        
        this.setState({ group: event.target.value });
      };

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteUser(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.name + '.',
                        'success'
                    )
                }
            }
        })
    }


    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        
        e.preventDefault();
        const { phone_no, email,group } = this.state;
        console.log([phone_no, email,group]);

        this.props.fetchUsers('', this.state);
        this.setState({ hideButton: true })

    };
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchUsers(pageNo)
    }

    render() {
        const page = Math.ceil(this.props.count / 10)
        const isAdmin = this.props.auth.user.is_admin;
        const {groups,permissions} = this.props
        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>User List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">User list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">User List</h3>
                                <div className="float-right">
                                {CheckPermisionAction(permissions,'User','add_user')?
                                <>
                                <a class="btn btn-primary" href={BASE_URL+'/api/v1/export-user-report/'} role="button"><i className='fa fa-download'></i>Download</a>

                                    <Link to="/add-user" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        User</b></Link>
                                </>
                                :null}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="phone_no" type="text"
                                            onChange={this.onChange} placeholder="Phone No"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-4" name="email" type="text"
                                            onChange={this.onChange} placeholder="Email"
                                            aria-label="Search" />
                                        <select 
                                            value = {this.state.group}
                                            onChange={this.handleChangeGroup}
                                        >
                                            <option value="select">Select Role</option>
                                            {groups.map(group=>(
                                                <option key={group.id} value={group.id} onChange={this.onChange}>{group.name}</option>
                                            ))}
                                        </select>
                                    {CheckPermisionAction(permissions,'User','user_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    :null}
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'User','view_user_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Phone No</th>
                                            <th>Email</th>
                                            <th>Groups</th>
                                            <th>Actions</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/users-list" onClick={() => { window.location.href = "/users-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr>


                                            : this.props.users.map((user_create, index) => (
                                                <tr key={user_create.id}>
                                                    <td>{index + 1}</td>
                                                    <td>{user_create.first_name}</td>
                                                    <td>{user_create.last_name}</td>
                                                    <td>{user_create.phone_no}</td>
                                                    <td>{user_create.email}</td>
                                                    <td>{user_create.group? user_create.group.name:""}</td>
                                                    {/* <td></td> */}
                                                    <td>

                                                    {CheckPermisionAction(permissions,'User','user_edit')?

                                                        <Link to={'/user-edit/' + user_create.id}>
                                                            <button className="btn btn-info text-white">
                                                                <i className="fas fa-edit text-white" ></i>
                                                            </button>
                                                        </Link>
                                                    :null}
                                                    {CheckPermisionAction(permissions,'User','user_delete')?

                                                        <button className="btn btn-danger"
                                                            // onClick={this.props.RegDirectorConfirm.bind(this, rgd.id, rgd.is_active)}
                                                            onClick={() => this.opensweetalert(user_create)}>
                                                            <i className="fas fa-trash" />
                                                        </button>
                                                    :null}

                                                    </td>


                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

UserLists.propTypes = {
    fetchUsers: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    users: state.users.users,
    count: state.users.count,
    next: state.users.next,
    is_list: state.users.is_list,
    is_loading: state.users.is_loading,
    groups: state.groups.groups,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchUsers, deleteUser, fetchGroups })(UserLists);

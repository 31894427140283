import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addData } from "../../../actions/ProductAction"
import { fetchBannerSetting } from "../../../actions/SlideSettingAction"
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';


import { toast } from "react-toastify";
import { BANNER_SETTING_URL } from "../../../constants/api";
import { renderTextArea } from '../../../constants/FormInputType';


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

const submitToServer = (data) => {

    console.log('data', data);
    let form_data = new FormData();
    form_data.append('title', data.title !=='' && data.title !==null && data.title !==undefined? data.title:'');
    form_data.append('description', data.description !=='' && data.description !==null && data.description !==undefined? data.description:'');
    form_data.append('button_text', data.button_text !=='' && data.button_text !==null && data.button_text !==undefined? data.button_text:'');
    form_data.append('button_link', data.button_link !=='' && data.button_link !==null && data.button_link !==undefined?data.button_link:'');


    if (data.image) {
        form_data.append('image', data.image, data.image.name);
    }

    // var form_data = { "name": data.name };

    // console.log('data', form_data);


    let url = BANNER_SETTING_URL;

    let token = localStorage.getItem('token');
    // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
    console.log(token);
    axios.post(url, form_data,
        {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }

        })
        .then(res => {
            console.log(res.data);
            // dispatch(reset('PartnerAddForm'))
            toast.success('Successfully added a new Banner.')
        })
        .catch(err => console.log(err))
};


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);


const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddBannerSetting extends Component {

    componentDidMount() {

        this.props.fetchBannerSetting(0)
    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }

    submit(values) {
        const { reset } = this.props;
        return sleep(1000)
            .then(() => {
                submitToServer(values);
                reset()
            })
    }

    render() {

        const { handleSubmit, submitting, } = this.props;
        if (this.props.is_added) {
            setTimeout(function () {

                window.location.href = "/banner-setting-list"
            }, 3000)
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Banner Setting </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Banner Setting add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Banner Setting Information</h3>
                        <div className="float-right">
                            <Link to="/banner-setting-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="title"
                                        type="text"
                                        component={renderField}
                                        label="Title"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="description"
                                        type="text"
                                        component={renderTextArea}
                                        label="Description"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="button_text"
                                        type="text"
                                        component={renderField}
                                        label="Button Text"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="button_link"
                                        type="text"
                                        component={renderField}
                                        label="Button Link"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm"> Image</label> <small>(image size: width:580px, height:240px )</small>
                                    <Field
                                        name="image"
                                        type="file"
                                        component={FileInput}
                                        accept="image/*"
                                        label=" Image"
                                    // validate={[required]}
                                    />
                                </div>

                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddBannerSetting.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchBannerSetting: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

    banner_settings: state.banner_settings.banner_settings,
    is_added: state.banner_settings.is_added,
    is_loading: state.banner_settings.is_loading

});


AddBannerSetting = reduxForm({
    form: 'AddBannerSetting',
}
)(AddBannerSetting);


export default connect(mapStateToProps, { addData, fetchBannerSetting })(AddBannerSetting);

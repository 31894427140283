import {
    LOADING_RESTAURANT,
    FETCH_RESTAURANT,
    DETAIL_RESTAURANT,
    DELETE_RESTAURANT,
    NOT_FOUND_RESTAURANT,
    LOADING_CUISINE,
    FETCH_CUISINE,
    DETAIL_CUISINE,
    DELETE_CUISINE,
    NOT_FOUND_CUISINE,
    LOADING_FOOD_NAME,
    FETCH_FOOD_NAME,
    DETAIL_FOOD_NAME,
    DELETE_FOOD_NAME,
    NOT_FOUND_FOOD_NAME,
    LOADING_RESTAURANT_SLIDER,
    FETCH_RESTAURANT_SLIDER,
    DETAIL_RESTAURANT_SLIDER,
    DELETE_RESTAURANT_SLIDER,
    NOT_FOUND_RESTAURANT_SLIDER,
    
    LOADING_MEDICINE_DELIVERY,
    FETCH_MEDICINE_DELIVERY,
    DETAIL_MEDICINE_DELIVERY,
    DELETE_MEDICINE_DELIVERY,
    NOT_FOUND_MEDICINE_DELIVERY,
    LOADING_RIDER_CAR_TRIP,
    FETCH_RIDER_CAR_TRIP,
    DETAIL_RIDER_CAR_TRIP,
    NOT_FOUND_RIDER_CAR_TRIP,
    DELETE_RIDER_CAR_TRIP,
    LOADING_PERCEL_EXPRESS,
    FETCH_PERCEL_EXPRESS,
    DETAIL_PERCEL_EXPRESS,
    NOT_FOUND_PERCEL_EXPRESS,
    DELETE_PERCEL_EXPRESS,
    LOADING_FOOD_ORDER,
    FETCH_FOOD_ORDER,
    DETAIL_FOOD_ORDER,
    DELETE_FOOD_ORDER,
    NOT_FOUND_FOOD_ORDER,
    LOADING_RIDER_CAR_TYPE,
    FETCH_RIDER_CAR_TYPE,
    DETAIL_RIDER_CAR_TYPE,
    DELETE_RIDER_CAR_TYPE,
    NOT_FOUND_RIDER_CAR_TYPE,
    LOADING_PERCEL_TYPE,
    FETCH_PERCEL_TYPE,
    DETAIL_PERCEL_TYPE,
    DELETE_PERCEL_TYPE,
    NOT_FOUND_PERCEL_TYPE,
    LOADING_PERCEL_PLAN,
    FETCH_PERCEL_PLAN,
    DETAIL_PERCEL_PLAN,
    DELETE_PERCEL_PLAN,
    NOT_FOUND_PERCEL_PLAN,
    NOT_FOUND_MOBILE_APP_SLIDER,
    DELETE_MOBILE_APP_SLIDER,
    DETAIL_MOBILE_APP_SLIDER,
    FETCH_MOBILE_APP_SLIDER,
    LOADING_MOBILE_APP_SLIDER,

} from '../actions/types';

const initialState = {
    restaurants: [],
    restaurant: {},
    count: null,
    next: null,
    bulk: false,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    get_restaurants: false,
};

export const RestaurantReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_RESTAURANT:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_RESTAURANT:
            return {
                ...state,
                restaurants: action.payload,
                count: action.count,
                get_restaurants: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_RESTAURANT:
            return {
                ...state,
                is_getting: true,
                restaurant: action.payload,
                
            };
        case DELETE_RESTAURANT:
            return {
                ...state,
                restaurants: state.restaurants.filter(restaurant => restaurant.id !== action.payload)
            };
        
        case NOT_FOUND_RESTAURANT:
            return {
                ...state,
                restaurants: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const CuisineReducer = (state = 
    {
        cuisines: [],
        cuisine: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_cuisines: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_CUISINE:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_CUISINE:
            return {
                ...state,
                cuisines: action.payload,
                count: action.count,
                get_cuisines: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_CUISINE:
            return {
                ...state,
                is_getting: true,
                cuisine: action.payload,
                
            };
        case DELETE_CUISINE:
            return {
                ...state,
                cuisines: state.cuisines.filter(cuisine => cuisine.id !== action.payload)
            };
        
        case NOT_FOUND_CUISINE:
            return {
                ...state,
                cuisines: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const FoodNameReducer = (state = 
    {
        food_names: [],
        food_name: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_food_name: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_FOOD_NAME:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_FOOD_NAME:
            return {
                ...state,
                food_names: action.payload,
                count: action.count,
                get_food_name: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_FOOD_NAME:
            return {
                ...state,
                is_getting: true,
                food_name: action.payload,
                
            };
        case DELETE_FOOD_NAME:
            return {
                ...state,
                food_names: state.food_names.filter(food_name => food_name.id !== action.payload)
            };
        
        case NOT_FOUND_FOOD_NAME:
            return {
                ...state,
                food_names: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const RestaurantSliderReducer = (state = 
    {
        restaurant_sliders: [],
        restaurant_slider: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_restaurant_slider: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_RESTAURANT_SLIDER:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_RESTAURANT_SLIDER:
            return {
                ...state,
                restaurant_sliders: action.payload,
                count: action.count,
                get_restaurant_slider: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_RESTAURANT_SLIDER:
            return {
                ...state,
                is_getting: true,
                restaurant_slider: action.payload,
                
            };
        case DELETE_RESTAURANT_SLIDER:
            return {
                ...state,
                restaurant_sliders: state.restaurant_sliders.filter(restaurant_slider => restaurant_slider.id !== action.payload)
            };
        
        case NOT_FOUND_RESTAURANT_SLIDER:
            return {
                ...state,
                restaurant_sliders: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const MedicineDeliveryReducer = (state = 
    {
        medicine_deliveries: [],
        medicine_delivery: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_medicine_delivery: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_MEDICINE_DELIVERY:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_MEDICINE_DELIVERY:
            return {
                ...state,
                medicine_deliveries: action.payload,
                count: action.count,
                get_medicine_delivery: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_MEDICINE_DELIVERY:
            return {
                ...state,
                is_getting: true,
                medicine_delivery: action.payload,
                
            };
        case DELETE_MEDICINE_DELIVERY:
            return {
                ...state,
                medicine_deliveries: state.medicine_deliveries.filter(medicine_delivery => medicine_delivery.id !== action.payload)
            };
        
        case NOT_FOUND_MEDICINE_DELIVERY:
            return {
                ...state,
                medicine_deliveries: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}



export const RiderCarTypeReducer = (state = 
    {
        rider_car_types: [],
        rider_car_type: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_rider_car_type: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_RIDER_CAR_TYPE:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_RIDER_CAR_TYPE:
            return {
                ...state,
                rider_car_types: action.payload,
                count: action.count,
                get_rider_car_type: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_RIDER_CAR_TYPE:
            return {
                ...state,
                is_getting: true,
                rider_car_type: action.payload,
                
            };
        case DELETE_RIDER_CAR_TYPE:
            return {
                ...state,
                rider_car_types: state.rider_car_types.filter(rider_car_type => rider_car_type.id !== action.payload)
            };
        
        case NOT_FOUND_RIDER_CAR_TYPE:
            return {
                ...state,
                rider_car_types: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}





export const RiderCarTripReducer = (state = 
    {
        rider_car_trips: [],
        rider_car_trip: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_rider_car_trip: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_RIDER_CAR_TRIP:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_RIDER_CAR_TRIP:
            return {
                ...state,
                rider_car_trips: action.payload,
                count: action.count,
                get_rider_car_trip: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_RIDER_CAR_TRIP:
            return {
                ...state,
                is_getting: true,
                rider_car_trip: action.payload,
                
            };
        case DELETE_RIDER_CAR_TRIP:
            return {
                ...state,
                rider_car_trips: state.rider_car_trips.filter(rider_car_trip => rider_car_trip.id !== action.payload)
            };
        
        case NOT_FOUND_RIDER_CAR_TRIP:
            return {
                ...state,
                rider_car_trips: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const PercelTypeReducer = (state = 
    {
        percel_types: [],
        percel_type: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_percel_type: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_PERCEL_TYPE:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_PERCEL_TYPE:
            return {
                ...state,
                percel_types: action.payload,
                count: action.count,
                get_percel_type: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_PERCEL_TYPE:
            return {
                ...state,
                is_getting: true,
                percel_type: action.payload,
                
            };
        case DELETE_PERCEL_TYPE:
            return {
                ...state,
                percel_types: state.percel_types.filter(percel_type => percel_type.id !== action.payload)
            };
        
        case NOT_FOUND_PERCEL_TYPE:
            return {
                ...state,
                percel_types: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}





export const PercelPlanReducer = (state = 
    {
        percel_plans: [],
        percel_plan: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_percel_plan: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_PERCEL_PLAN:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_PERCEL_PLAN:
            return {
                ...state,
                percel_plans: action.payload,
                count: action.count,
                get_percel_plan: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_PERCEL_PLAN:
            return {
                ...state,
                is_getting: true,
                percel_plan: action.payload,
                
            };
        case DELETE_PERCEL_PLAN:
            return {
                ...state,
                percel_plans: state.percel_plans.filter(percel_plan => percel_plan.id !== action.payload)
            };
        
        case NOT_FOUND_PERCEL_PLAN:
            return {
                ...state,
                percel_plans: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const PercelExpressReducer = (state = 
    {
        percel_expresses: [],
        percel_express: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_percel_express: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_PERCEL_EXPRESS:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_PERCEL_EXPRESS:
            return {
                ...state,
                percel_expresses: action.payload,
                count: action.count,
                get_percel_express: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_PERCEL_EXPRESS:
            return {
                ...state,
                is_getting: true,
                percel_express: action.payload,
                
            };
        case DELETE_PERCEL_EXPRESS:
            return {
                ...state,
                percel_expresses: state.percel_expresses.filter(percel_express => percel_express.id !== action.payload)
            };
        
        case NOT_FOUND_PERCEL_EXPRESS:
            return {
                ...state,
                percel_expresses: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const FoodOrderReducer = (state = 
    {
        foodorders: [],
        foodorder: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_food_order: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_FOOD_ORDER:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_FOOD_ORDER:
            return {
                ...state,
                foodorders: action.payload,
                count: action.count,
                get_food_order: true,
                next: action.next,
                is_loading: false,
                is_getting:true
            };
        case DETAIL_FOOD_ORDER:
            return {
                ...state,
                is_getting: true,
                foodorder: action.payload,
                
            };
        case DELETE_FOOD_ORDER:
            return {
                ...state,
                foodorders: state.foodorders.filter(foodorder => foodorder.id !== action.payload)
            };
        
        case NOT_FOUND_FOOD_ORDER:
            return {
                ...state,
                foodorders: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}



export const MobileAppSliderReducer = (state = 
    {
        mobile_app_sliders: [],
        mobile_app_slider: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_mobile_app_slider: false,
    }
    
    , action) => {
    switch (action.type) {
        case LOADING_MOBILE_APP_SLIDER:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_MOBILE_APP_SLIDER:
            return {
                ...state,
                mobile_app_sliders: action.payload,
                count: action.count,
                get_mobile_app_slider: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_MOBILE_APP_SLIDER:
            return {
                ...state,
                is_getting: true,
                mobile_app_slider: action.payload,
                
            };
        case DELETE_MOBILE_APP_SLIDER:
            return {
                ...state,
                mobile_app_sliders: state.mobile_app_sliders.filter(mobile_app_slider => mobile_app_slider.id !== action.payload)
            };
        
        case NOT_FOUND_MOBILE_APP_SLIDER:
            return {
                ...state,
                mobile_app_sliders: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

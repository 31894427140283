import {
    LOADING_ATTRIBUTE,
    GETTING_ATTRIBUTE,
    DETAIL_ATTRIBUTE,
    UPDATE_ATTRIBUTE,
    DELETE_ATTRIBUTE,
    NOT_FOUND_ATTRIBUTE,
    ADD_ATTRIBUTE

} from './types';

import { GET_ATTRIBUTE_URL, GET_ATTRIBUTE_ALL_URL } from '../constants/api';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { SearchURLGenerate } from '../constants/CustomMethod'




// FETCH ATTRIBUTE
export const getAttribute = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_ATTRIBUTE })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }


    let search_key = ["name",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_ATTRIBUTE_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: GETTING_ATTRIBUTE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_ATTRIBUTE });

    }



};


export const fetchAttributeAll = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_ATTRIBUTE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name",]
    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_ATTRIBUTE_ALL_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //alert(JSON.stringify(response.data))
                dispatch({
                    type: GETTING_ATTRIBUTE,
                    payload: response.data ? response.data : [],
                    // count: response.data.count,
                    // next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_ATTRIBUTE });

    }
};


// Attribute Detail
export const attributeDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_ATTRIBUTE_URL + `${id}/`;

    axios.get(url, { headers })
        .then(response => {

            dispatch({
                type: DETAIL_ATTRIBUTE,
                payload: response.data,
            })
        })
};



// Update Attribute
export const updateAttribute = (id, data) => (dispatch, getState) => {

    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_ATTRIBUTE_URL + `${id}/`;

    axios.put(url, data, { headers })
        .then(response => {
            console.log(response.data)
            dispatch({
                type: UPDATE_ATTRIBUTE,
                payload: response.data
            });

            toast.success("Attribute successfully updated");
            console.log('Success');
        })
        .catch(err => {
            // dispatch();
            toast.error('Please try again', err);
            console.log(err);
        })
};


// Delete Attribute
export const deleteAttribute = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(GET_ATTRIBUTE_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_ATTRIBUTE,
                payload: id,
            });
            toast.success('Attribute deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};

export const addAttribute = (data) => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .post(GET_ATTRIBUTE_URL, data, { headers })
        .then(response => {

            dispatch({
                type: ADD_ATTRIBUTE,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success('Attribute added successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};

export const getAttributeOptionByChildURL = (url) => (dispatch, getState) => {
    dispatch({ type: LOADING_ATTRIBUTE })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios.get(url, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: GETTING_ATTRIBUTE,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })



};
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
// import Modal from 'react-modal';

import CircularProgress from "@material-ui/core/CircularProgress";
import ReactHTMLTableToExcel from "react-html-table-to-excel"

import { getUsers } from '../../actions/Users';


// Modals.setAppElement('#modalRender');

class UserList extends Component {

    state = {
        phone_no:"",
        email:"",
    };

    componentDidMount() {
        this.props.getUsers(1);
    }

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const {phone_no, email} = this.state;
        console.log(phone_no, email);

        this.props.getUsers('', this.state);
        // this.props.getUsers('', '', search);
        // this.props.getUsers('', '', '', search);
        // this.setState({
        //     search: "",
        // })
    };

    render() {
     
        let renderPageNumbers;
        const pageNumbers = [];

        if (this.props.count !== null) {
            for (let i = 1; i <= Math.ceil(this.props.count / 10); i++) {
                pageNumbers.push(i);
            }

            renderPageNumbers = pageNumbers.map(number => {
                // let classes = this.state.current_page === number ? styles.active : '';

                return (
                    <span key={number} className="page-link"
                        onClick={() => this.props.getUsers(number)}>{number}</span>
                    // <li onClick={() => this.makeHttpRequestWithPage(number)}  className="page-item"><a className="page-link">{number}</a></li>
                );
            });
        }

        // const isAdmin = this.props.auth.user.is_admin;
        // let delete_button;
        return (
            <div>
                {/* <!-- /.row --> */}
                <div className="row">
                    <ToastContainer />
                    <div className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-12">
                                    <h1 className="text-dark">User List</h1>
                                </div>
                              
                            </div>
                            {/*<!-- /.row --> */}
                        </div>
                        {/*<!-- /.container-fluid --> */}
                    </div>

                    <div className="col-12">
                        <div className="card">

                            <div className="card-header">
                            </div>
                                <div style={{ margin: "15px" }} className="card-tools">
                                    <div className="float-left">
                                        <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>

                                            <input className="form-control mr-sm-4" name="phone_no" type="number"
                                                onChange={this.onChange} placeholder="Mobile No"
                                                aria-label="Search" />

                                            <input className="form-control mr-sm-4" name="email" type="email"
                                                onChange={this.onChange} placeholder="Email"
                                                aria-label="Search" required />

                                            <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                                type="submit"><i className="fas fa-search"></i> Search
                                    </button>
                                    <button onClick={() => window.location.reload(false)} className="btn ml-3 btn-info my-4 my-sm-0 float-right"><i className="fas fa-sync-alt"></i> Refresh
                                        
                                        </button>
                                        </form>
                                        {/* <div className="float-left"> */}
                                   
                                {/* </div> */}
                                    </div>
                                    <div className="float-right">
                                        
                                    <ReactHTMLTableToExcel
                                        // id="test-table-xls-button"
                                        className="btn btn-info"
                                        table="table-to-xls"
                                        filename="user-list"
                                        sheet="tablexls"
                                        buttonText="Excel"
                                    ><i class="fas fa-file-excel"></i> Excel</ReactHTMLTableToExcel>
                                </div>


                                </div>
                                
                           

                           

                            <div className="card-body table-responsive p-0">
                                <table id="table-to-xls" className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Phone Number</th>
                                            <th>Photo</th>
                                            <th>Email</th>
                                            <th>Groups</th>
                                            {/* <th>Status</th> */}
                                            {/*<th>Action</th>*/}
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.props.is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> :this.props.all_users.map((user, index) => (
                                            <tr key={user.id}>
                                                <td>{index + 1}</td>
                                                <td>{user.first_name}</td>
                                                <td>{user.last_name}</td>
                                                <td>{user.phone_no}</td>
                                                <th><img className='w-15 text-secondary' src={user.user_photo} alt="Not Found" /></th>
                                                <td>{user.email}</td>
                                                <th>{user.group}</th>
                                                <td>{user.is_active}</td>
                                                
                                             
                                                {/* <td>{user.is_active}</td> */}
                                                {/*<td>{staff.user.email}</td>*/}
                                                {/*<td>{staff.is_active}</td>*/}
                                                {/*<td className="btn-group btn-group-sm">*/}
                                                {/*    <button className="btn btn-info">*/}
                                                {/*        <Link to={'/staff/' + staff.id }><i className="fas fa-eye" /></Link>*/}
                                                {/*    </button>*/}

                                                {/*    <button className="btn btn-warning">*/}
                                                {/*        <Link to={'/staff-update/' + staff.id}><i className="fas fa-edit" /></Link>*/}
                                                {/*    </button>*/}
                                                {/*    {isAdmin ?*/}
                                                {/*        <button className="btn btn-danger">*/}
                                                {/*            <i className="fas fa-trash"*/}
                                                {/*               onClick={this.props.deleteStaff.bind(this, staff.id)}*/}
                                                {/*            />*/}
                                                {/*        </button>*/}
                                                {/*        : " "*/}
                                                {/*    }*/}
                                                {/*</td>*/}
                                            </tr>
                                        ) )}
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- /.card-body --> */}

                            <div className="card-footer clearfix">
                                <ul className="pagination pagination-sm m-0 float-right">
                                    {/*<span className="page-link" onClick={() => this.props.fetchstaffs(this.props.next)}>&laquo;</span>*/}
                                    {renderPageNumbers}
                                </ul>
                            </div>
                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}


UserList.propTypes = {
    getUsers: PropTypes.func.isRequired,
    all_users: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    all_users: state.all_users.all_users,
    count: state.all_users.count,
    next: state.all_users.next,
    is_loading: state.all_users.is_loading,
    auth: state.auth
});
// const modalElement = document.getElementById('modalRender');
export default connect(mapStateToProps, { getUsers })(UserList);

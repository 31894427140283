import {
    FETCH_TAX,
    DETAIL_TAX,
    DELETE_TAX,
    LOADING_TAX,
    ADD_DATA,
    UPDATE_TAX,
    UPDATED_DATA,
    NOT_FOUND_TAX,
} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { TAX_URL, GET_TAX_ALL_URL, GET_GALLERY_URL, PRODUCT_CSV_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


export const fetchTaxs = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_TAX });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["tax_class_name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = TAX_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_TAX,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_TAX });

    }
};
export const searchTaxs = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_TAX });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(TAX_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_TAX,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};
// Tax Detail
export const taxDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = TAX_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_TAX,
                payload: response.data,
            })
        })
};

// // Delete Tax
export const deleteTax = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(TAX_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_TAX,
                payload: id,
            });
            toast.success('Product has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

export const addData = (data, url, message = "Data") => (dispatch, getState) => {
    let headers = { 'content-type': 'application/json' };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios
        .post(url, data, { headers })
        .then(response => {
            dispatch({
                type: ADD_DATA,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been added successfully");
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response.data.error)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                toast.warn("Something Wrong ! Please try again");

            } else {
                toast.warn("Something Wrong ! Please try again");
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }

        })
};

export const updateData = (data, url, message = "") => (dispatch, getState) => {

    let headers = { 'content-type': 'application/json' };
    let { token } = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .put(url, data, { headers })
        .then(response => {
            dispatch({
                type: UPDATED_DATA,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been updated successfully");
        }).catch(error => {
            console.log(error)
            toast.warn(message + " has not been updated successfully");
        })
};



export const getProductTaxsForSelect = (tax_class_name) => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    dispatch({ type: LOADING_TAX });
    var url = ''
    if (tax_class_name != '') {
        url = GET_TAX_ALL_URL
    } else {
        url = GET_TAX_ALL_URL + '?search=' + tax_class_name
    }


    axios.get(url, { headers, })
        .then(response => {
            //  alert(JSON.stringify(response))
            var results = []
            for (var i = 0; i < response.data.length; i++) {
                results.push({ label: response.data[i].tax_class_name, value: response.data[i].id })
            }
            dispatch({
                type: FETCH_TAX,
                payload: response.data ? results : [],
                count: response.data.count,
                next: response.data.next
            })
        })




};


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import CircularProgress from "@material-ui/core/CircularProgress";
import {  deleteRestaurantSlider,fetchFoodOrder } from '../../../actions/RestaurantActions';
import Swal from 'sweetalert2'
import ReactPaginate from "react-paginate"
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import moment from 'moment';
import 'moment-timezone';

import {  BulkAction } from '../../../actions/ProductAction';
import {  OrderStatus } from '../../../constants/Datas'
import {findArrayElementByTitle} from '../../../constants/CustomMethod'
import { FOOD_ORDER_BULK_URL } from '../../../constants/api'
import {dateYMD} from "../../../constants/Validate";
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};



// Modals.setAppElement('#modalRender');

class FoodOrderList extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            headers: [],
            fileName: 'ride-category-fare-list.xlsx',
            is_fetching: false,
            modalIsOpen: false,
            get_food__order:false,
            allchecked: false, 
            bulk: false,
            show:false,
            food_orders:[],
            order_no:'',
            customer__phone_no:'',
            order_date_to: "",
            order_date_from:"",
            order_status:"",
        };

        // this.opensweetalert = this.opensweetalert.bind(this)

    }




    componentDidMount() {
        // console.log(this.state.data)
// alert(JSON.stringify(this.props.restaurantsliders))
       
        this.props.fetchFoodOrder(1)
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.title + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteRestaurantSlider(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.title + '.',
                        'success'
                    )
                }
            }
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.get_food_order) {
            if (nextProps.get_food_order !== prevState.get_food_order) {
                var orders = []
               
                for (var i = 0; i < nextProps.foodorders.length; i++) {
                    Object.assign(nextProps.foodorders[i], { 'checked': false });
                    orders.push(nextProps.foodorders[i])
                }
                return {
                    food_orders: orders,
                    get_food_order: true
                }
            }
        }
        return null
    }


    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = [],insert=true
            for (var i = 0; i < this.state.food_orders.length; i++) {
                if (this.state.food_orders[i].checked) {
                    results.push(this.state.food_orders[i].id)
                    if(this.state.food_orders[i].order_status===5){
                        insert=false
                        break;
                    }
                }
            }
            if(insert){
                this.props.BulkAction(
                    { 'id': results, 'action': value }, FOOD_ORDER_BULK_URL
                )
            }else{
                alert("You are not able to change completed order")
            }
           
        }
    }

    onSingleChange = index => (e) => {
        console.log(index)
        let orders = [...this.state.food_orders]
        orders[index]["checked"] = e.target.checked
        let recent_length = orders.filter(order => order.checked).length;

        if (orders.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        this.setState({ orders })

    };
    toggleAllChange=(e)=> {
        const { value, checked } = e.target;
        let foodorders = [...this.props.foodorders]
        foodorders.forEach(order => order.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, foodorders, bulk: true })
        } else {
            this.setState({ allchecked: checked, foodorders, bulk: false })
        }
    }

   

    // componentDidUpdate(prevProps, prevState){
    //     if (prevProps.food_orders != this.props.foodorders){
    //         this.setState({food_orders:this.props.foodorders})
    //     }

    // }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            is_fetching: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            is_fetching: false
        })
    };
    onChangeDateTo = (date) => {
       
        this.setState({order_date_to:dateYMD(date)})

}
    onChangeDateFrom = (date) => {
        
        this.setState({order_date_from:dateYMD(date)})

}
    onSubmit = e => {
        
        e.preventDefault();
        
        this.props.fetchFoodOrder('', this.state);
        this.setState({ hideButton: true })

    };
    onChange = e => this.setState({ [e.target.name]: e.target.value });
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchFoodOrder(pageNo)
    }

    render() {
        const {allchecked, orders, bulk,show}=this.state

        const page = Math.ceil(this.props.count / 10)
        const {permissions} = this.props
        // console.log(this.props.rideCategory)



        // const isAdmin = this.props.auth.user.is_admin;

        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }
        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/food-order-list'
            },
                1000);
        }

        return (

            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Food Order List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active"> Food Order List</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    {/*<!-- /.container-fluid -->*/}
                </section>

                {/* <!-- /.row --> */}
                <div className="row">
                    {/*    <Modals*/}
                    {/*    className="modal"*/}
                    {/*    show={this.state.isShowing}*/}
                    {/*    close={this.closeModalHandler}*/}
                    {/*    Some data*/}
                    {/*/>*/}
                    <ToastContainer />
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >

                        <h2 ref={subtitle => this.subtitle = subtitle}>Hello</h2>
                        <button onClick={this.closeModal}>close</button>
                        <div>I am a modal</div>
                        <form>
                            <input />
                            <button>tab navigation</button>
                            <button>stays</button>
                            <button>inside</button>
                            <button>the modal</button>
                        </form>
                    </Modal>

                    <div className="col-12">
                        <div className="card">
                        <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Bulk Action--</option>
                                            {OrderStatus.map((order, index) => (
                                                <option key={index} value={order.id}>{order.status}</option>

                                             ))}
                                            {/* <option value="delete">Delete</option> */}


                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>
                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                    <input className="form-control mr-sm-4" name="order_no" type="text"
                                            onChange={this.onChange} placeholder="Order No"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-4" name="customer__phone_no" type="text"
                                            onChange={this.onChange} placeholder="Customer Phone Number"
                                            aria-label="Search" />

                                        <DateTimePicker
                                            // onChange={this.onChangeDate}
                                            onChange={date => this.onChangeDateFrom(date)}
                                            name="order_date_from"
                                            format="DD-MM-YYYY"
                                            time={false}
                                        // value={this.state.order_search_date}
                                            placeholder="Order date from"
                                        /> 

                                        <DateTimePicker
                                            // onChange={this.onChangeDate}
                                            onChange={date => this.onChangeDateTo(date)}
                                            name="order_date_to"
                                            format="DD-MM-YYYY"
                                            time={false}
                                        // value={this.state.order_search_date}
                                            placeholder="Order date to"
                                        />  
                                       <select 
                                            name="order_status"
                                            component="select"
                                            className="form-control mr-sm-4"

                                            onChange={this.onChange}
                                        >
                                            <option value="select">--Select Status--</option>
                                            {OrderStatus.map((order, index) => (
                                                <option key={index} value={order.id}>{order.status}</option>

                                             ))}
                                            {/* {groups.map(group=>(
                                                <option key={group.id} value={group.id} onChange={this.onChange}>{group.name}</option>
                                            ))} */}
                                        </select> 
                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    
                                    </form>
                                </div>


                            </div>
                            <div className="card-header">
                                <div className="float-right">
                                {/* {CheckPermisionAction(permissions,'Restaurant','add_food_order')?

                                    <Link to="/add-food-order" className="btn btn-info float-right text-white"><b>
                                        + Add Food Order</b>
                                    </Link>
                                 :null}  */}
                                </div>
                            </div>
                            
                            {/* {CheckPermisionAction(permissions,'Restaurant','view_food_order_list')? */}

                            <div className="card-body table-responsive p-0">
                                <table id="table-to-xls" className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                        <th>
                                                <input type="checkbox"
                                                    checked={allchecked}
                                                    onChange={this.toggleAllChange}
                                                />   
                                        </th>
                                            <th cope="col">SL</th>
                                            <th>Restaurant Name</th>
                                            <th>Order No</th>
                                            <th>Order Date</th>
                                            <th>Delivery Date</th>
                                            <th>Delivery To</th>
                                            <th>Customer Name</th>
                                            <th>Customer Mobile No</th>
                                            {/* <th>Order Item</th> */}
                                            <th>Food Order Status</th>
                                            {/* <th>Payment Method</th> */}
                                            {/* <th>Status</th> */}
                                            <th>Option</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ?
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>

                                            : this.props.foodorders.map((restaurantslider, index) => (
                                                <tr key={restaurantslider.id}>
                                                    <td>
                                                        <input type="checkbox" onChange={this.onSingleChange(index)} checked={restaurantslider.checked ? true : false} value={restaurantslider.id} />
                                                    </td>
                                                    <td>{index + 1}</td>
                                                    <td>{restaurantslider.restaurant !==null && restaurantslider.restaurant !==undefined ? restaurantslider.restaurant.restaurant_name:''}</td>
                                                    <td>#{restaurantslider.order_no}</td>
                                                    <td>{restaurantslider.order_date ? moment(restaurantslider.order_date).tz('Asia/Dhaka').format('llll'):''}</td>
                                                    
                                                    <td>{restaurantslider.delivery_date_time ? moment(restaurantslider.delivery_date_time).tz('Asia/Dhaka').format('llll'):'' }</td>
                                                    <td>{restaurantslider.delivery_to}</td>
                                                    <td>{restaurantslider.customer !==null && restaurantslider.customer !==undefined ? restaurantslider.customer.first_name:''}</td>
                                                    
                                                    <td>{restaurantslider.customer !==null && restaurantslider.customer !==undefined ? restaurantslider.customer.phone_no:''}</td>
                                                    {/* <td>{restaurantslider.food_order_list !==undefined ? restaurantslider.food_order_list.map(ne=>ne.order_restaurant_food.food_names.map(fd=>fd.name + ", ")) :''}</td> */}
                                                    <td>
                                                     
                                                        
                                                            { findArrayElementByTitle(OrderStatus,restaurantslider.order_status) ?  findArrayElementByTitle(OrderStatus,restaurantslider.order_status).status : null }
                                                           
                                                    </td>
                                                    {/* <td>{restaurantslider.payment_method !==null && restaurantslider.payment_method !==undefined ? restaurantslider.payment_method.name:''} </td> */}
                                                {/* <td>{restaurantslider.is_active ? "Activated":"Deactivated"}</td> */}

                                                    <td >
                                                    <div className="btn-group btn-group-sm">
                                                            
                                                        {/* {CheckPermisionAction(permissions,'Restaurant','food_order_options')?
                                                        :null}  */}
                                                        <>
                                                        <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                        <div className="dropdown-menu">
                                                            <a href={'/food-order-detail/' + restaurantslider.id} className="dropdown-item"> Food Order details</a>
                                                            <div className="dropdown-divider"></div>
                                                            {/* <a href={'/food-order-update/' + restaurantslider.id} className="dropdown-item"> Food Order Edit</a>
                                                            <div className="dropdown-divider"></div> */}
                                                            {/* <a href="#" onClick={() => this.opensweetalert(restaurantslider)} className="dropdown-item">Delete</a> */}

                                                        </div>
                                                        </>

                                                                
                                                    </div>
                                                   
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                             {/* :null
                             }  */}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}




FoodOrderList.propTypes = {
    fetchFoodOrder: PropTypes.func.isRequired,
    BulkAction: PropTypes.array.isRequired,

};

const mapStateToProps = state => ({
    foodorders:state.foodorders.foodorders,
    // cuisines: state.cuisines.cuisines,
    count: state.foodorders.count,
    next: state.foodorders.next,
    is_loading: state.foodorders.is_loading,
    is_getting:state.foodorders.is_getting,
    get_food_order:state.foodorders.get_food_order,
    bulk: state.products.bulk,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions

});
// const modalElement = document.getElementById('modalRender');
export default connect(mapStateToProps, {  deleteRestaurantSlider,fetchFoodOrder,BulkAction })(FoodOrderList);



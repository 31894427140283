import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { updateData } from '../../../actions/ProductAction';

import { fetchSellers } from '../../../actions/SellerActions';

import { toast } from "react-toastify";
import { SELLER_URL } from "../../../constants/api";


momentLocalizer(moment);



const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class SellerProfileSettings extends Component {
    state={
      
        id:0,
        phone_no:'',
        first_name:'',
        sellers:[],
        last_name:'',
        email:'',
        get_seller:false,
    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_getting) {
            if (nextProps.is_getting !== prevState.get_seller) {
                var seller =nextProps.sellers[0] 
                return {
                    first_name:seller.user.first_name,
                    last_name:seller.user.last_name,
                    email:seller.user.email,
                    id: seller.id,
                    phone_no: seller.user.phone_no,
                    sellers:seller,
                    get_seller: true
                }
            }
        }
        return null
    }
    onChangeText= (e) => {

        this.setState({[e.target.name]:e.target.value})

    }
    componentDidMount() {
        this.props.fetchSellers(1)
    }


    submit(event) {
        console.log(event)
        var form_data = new FormData();    
            form_data.append('first_name',this.state.first_name)
            form_data.append('last_name',this.state.last_name)
            form_data.append('email',this.state.email)
          
            form_data.append('payment_type',this.state.sellers.payment_type)
            form_data.append('shop_name',this.state.sellers.shop_name)
            form_data.append('shop_details',this.state.sellers.shop_details)
            form_data.append('account_name',this.state.sellers.account_name)
            form_data.append('account_number',this.state.sellers.account_number)
            form_data.append('bank_name',this.state.sellers.bank_name)
            form_data.append('branch_name',this.state.sellersbranch_name)
            form_data.append('routing_number',this.state.sellers.routing_number)
            form_data.append('mobile_banking_type',this.state.sellers.mobile_banking_type)
            form_data.append('wallet_number',this.state.sellers.wallet_number)
            form_data.append('phone_no',this.state.phone_no)
            form_data.append('shop_trade_license',this.state.sellers.shop_trade_license)
            form_data.append('shop_address',this.state.sellers.shop_address)
            
             this.props.updateData(form_data, SELLER_URL+this.state.id+"/", "Profile Settings")


            
    }

    render() {

        const { handleSubmit, submitting } = this.props;
       
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Profile Settings </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Profile Settings</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Profile Settings</h3>
                        {/* <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div> */}
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                <label>First Name</label>
                                    <input
                                        name="first_name"
                                        type="text"
                                        className="form-control"
                                        value={this.state.first_name}
                                        label="First Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Last Name</label>

                                    <input
                                        name="last_name"
                                        type="text"
                                        className="form-control"
                                        value={this.state.last_name}
                                        label="Last Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Mobile No</label>
                                    <input
                                        name="phone_no"
                                        type="text"
                                        value={this.state.phone_no}
                                        label="Mobile No"
                                        readOnly={true}
                                        className="form-control"
                                        validate={required}
                                        onChange={this.onChangeText}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Email</label>
                                    <input
                                        name="email"
                                        type="text"
                                        className="form-control"
                                        value={this.state.email}
                                        label="Email"
                                        readOnly={true}
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                
                             
                             
                                

                            </div>


                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save Settings
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


SellerProfileSettings.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchSellers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    sellers: state.sellers.sellers,
    is_updated: state.products.is_updated,
    is_getting:state.sellers.is_getting,
    initialValues: state.sellers.sellers,

});


SellerProfileSettings = reduxForm({
    form: 'SellerProfileSettings',
    enableReinitialize: true

}
)(SellerProfileSettings);


export default connect(mapStateToProps, { fetchSellers, updateData })(SellerProfileSettings);

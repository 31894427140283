import axios from 'axios';
import {
    USER_STATISTICS
} from './types'

import {
    BASE_URL
} from '../constants/api'

// user statistics
export const userStatistics = () => (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};

    let {token} = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = BASE_URL + '/api/v1/user-stat/';
    axios
        .get(url, {headers})
        .then(response => {
            dispatch({
                type: USER_STATISTICS,
                payload: response.data
            })
        })
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import {CheckPermisionAction} from "../../constants/CustomMethod"
import Modal from 'react-modal';

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'
// import AttributeReducer from '../../reducers/AttributeReducer';

import { getAttributeOption, deleteAttributeOption } from '../../actions/AttributeOptionActions';
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class AttributeOptionList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        name: "",
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
    };



    componentDidMount() {
        this.props.getAttributeOption(1);
    }
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.getAttributeOption(pageNo)
    }
    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteAttributeOption(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.name + '.',
                        'success'
                    )
                }
            }
        })
    }


    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { name } = this.state;
        console.log([name]);

        this.props.getAttributeOption('', this.state);
        this.setState({ hideButton: true })

    };
    componentDidMount() {
        this.props.getAttributeOption(1)
    }

    render() {
        const {permissions}=this.props
        const page = Math.ceil(this.props.count / 10)



        const isAdmin = this.props.auth.user.is_admin;

        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Attribute Option List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Attribute Option list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Attribute Option List</h3>
                                <div className="float-right">
                            {CheckPermisionAction(permissions,'Inventory','add_attribute_option')?

                                    <Link to="/add-attribute-option" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Attribute Option</b></Link>
                                :null}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="name" type="text"
                                            onChange={this.onChange} placeholder=" Name"
                                            aria-label="Search" />
                            {CheckPermisionAction(permissions,'Inventory','attribute_option_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    :null}
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'Inventory','view_attribute_option_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Attribute Name</th>
                                            <th> Name</th>
                                            <th>Action</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/attribute-option-list" onClick={() => { window.location.href = "/attribute-option-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> : this.props.attribute_options.map((attr_options, index) => (
                                            <tr key={attr_options.id}>
                                                <td>{index + 1}</td>
                                                <td>{attr_options.attribute}</td>
                                                <td>{attr_options.name}{attr_options.attribute.toLowerCase() == 'color' ? ' - ' + attr_options.color_code : null}</td>
                                                <td>
                                                    <Link to={'/attribute-option-detail/' + attr_options.id}>
                                                        <button className="btn btn-info">
                                                            <i className="fas fa-eye" >View</i>
                                                        </button>
                                                    </Link>
                                                    {CheckPermisionAction(permissions,'Inventory','attribute_option_edit')?

                                                    <Link to={'/attribute-option-update/' + attr_options.id}>
                                                        <button className="btn btn-warning text-white">
                                                            <i className="fas fa-edit" >Edit</i>
                                                        </button>
                                                    </Link>
                                                    :null}
                                                {CheckPermisionAction(permissions,'Inventory','attribute_option_delete')?
                                                    <>
                                                    {isAdmin ?
                                                        <button className="btn btn-danger" onClick={() => this.opensweetalert(attr_options)}><i className="fas fa-trash" >Delete</i>
                                                        </button> : " "}
                                                    </>
                                                    :null}
                                                </td>


                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>

            </div>
        )
    }
}

AttributeOptionList.propTypes = {
    getAttributeOption: PropTypes.func.isRequired,
    deleteAttributeOption: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    attribute_options: state.attribute_options.attribute_options,
    count: state.attribute_options.count,
    next: state.attribute_options.next,
    is_loading: state.attribute_options.is_loading,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { getAttributeOption, deleteAttributeOption })(AttributeOptionList);

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { updateData } from '../../../actions/ProductAction';

import { fetchSellers } from '../../../actions/SellerActions';



import { toast } from "react-toastify";
import { BASE_URL, SELLER_URL } from "../../../constants/api";


momentLocalizer(moment);


// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class SellerShopSettings extends Component {

    state={
        payment_type:"",
        id:0,
        phone_no:'',
        get_seller:false,
        sellers:[],
        shop_address:'',
        shop_name:'',
        shop_trade_license:'',
        shop_details:'',
        account_name:'',
        account_number:'',
        bank_name:'',
        branch_name:'',
        routing_number:'',
        mobile_banking_type:'',
        wallet_number:''
    }

    // this.props.getRides(0)

    static getDerivedStateFromProps(nextProps, prevState) {
        
        if (nextProps.is_getting) {
            if (nextProps.is_getting !== prevState.get_seller) {
                var seller =nextProps.sellers[0] 
                return {
                    shop_address:seller.shop_address,
                    shop_name:seller.shop_name,
                    shop_trade_license:seller.shop_trade_license,
                    shop_details:seller.shop_details,
                    account_name:seller.account_name,
                    account_number:seller.account_number,
                    bank_name:seller.bank_name,
                    branch_name:seller.branch_name,
                    routing_number:seller.routing_number,
                    mobile_banking_type:seller.mobile_banking_type,
                    wallet_number:seller.wallet_number,
                    id: seller.id,
                    phone_no: seller.user.phone_no,
                    payment_type:seller.payment_type,
                    sellers:seller,
                    get_seller: true
                }
            }
        }
        return null
    }
    componentDidMount() {
        this.props.fetchSellers(1)
    }


    submit(event) {
     
            var form_data = new FormData();
            Object.keys(event).forEach(key => form_data.append(key, event[key]));
            form_data.append('first_name',this.state.sellers.user.first_name)
            form_data.append('last_name',this.state.sellers.user.last_name)
            form_data.append('email',this.state.sellers.user.email)
            form_data.append('shop_name',this.state.shop_name)
            form_data.append('shop_details',this.state.shop_details)
            if (this.state.payment_type === "1") {
                form_data.append('payment_type',this.state.payment_type)
                form_data.append('account_name',this.state.account_name)
                form_data.append('account_number',this.state.account_number)
                form_data.append('bank_name',this.state.bank_name)
                form_data.append('branch_name',this.state.branch_name)
                form_data.append('routing_number',this.state.routing_number)
            }else{
                form_data.append('payment_type',this.state.payment_type)
                form_data.append('mobile_banking_type',this.state.mobile_banking_type)
                form_data.append('wallet_number',this.state.wallet_number)
            }
            form_data.append('phone_no',this.state.phone_no)
            form_data.append('shop_trade_license',this.state.shop_trade_license)
            form_data.append('shop_address',this.state.shop_address)

            
             this.props.updateData(form_data, SELLER_URL+this.state.id+"/", "Shop Settings")

    }
    onChangePaymentType = (e) => {
      var  sellers=this.state.sellers
      sellers["payment_type"]=e.target.value
      this.setState({sellers}) 
    }

    onChangeText= (e) => {

        this.setState({[e.target.name]:e.target.value})

    }
    render() {

        const { handleSubmit, submitting } = this.props;
        const { sellers } = this.state;
        // if (this.props.is_added) {
        //     setTimeout(function () {
        //         window.location.href = '/seller-list'
        //     },
        //         3000);
        // }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Shop Settings </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Shop Settings</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Shop Settings</h3>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <label>Shop Name</label>
                                    <input
                                        name="shop_name"
                                        type="text"
                                        className="form-control"
                                        label="Shop Name"
                                        validate={required}
                                        value={this.state.shop_name}
                                        onChange={this.onChangeText}
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                               
                                <div className="col-md-6">
                                <label>Shop Trade License No</label>
                                    <input
                                        name="shop_trade_license"
                                        type="text"

                                        className="form-control"
                                        label="Shop Trade License No"
                                        validate={required}
                                        value={this.state.shop_trade_license}
                                        onChange={this.onChangeText}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Shop Address</label>
                                    <input
                                        name="shop_address"
                                        type="textarea"
                                        className="form-control"
                                        label="Shop Address"
                                        validate={required}

                                        onChange={this.onChangeText}
                                        // warn={alphaNumeric}

                                     value={this.state.shop_address}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Shop Details</label>
                                    <input
                                        name="shop_details"
                                        type="textarea"
                                        className="form-control"
                                        label="Shop Details"
                                        value={this.state.shop_details }
                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Shop Logo</label>
                                    <Field
                                        name="shop_logo"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="pload Shop Logo"
                                        
                                        // value={sellers ? sellers.shop_name : ''}
                                    />

                                </div>
                                
                                {this.props.sellers.shop_logo != null && this.props.sellers.shop_logo != '' ?
                                    <img src={ this.props.sellers.shop_logo} height="100" width="100px" /> :
                                    null
                                }
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Cover Phoro</label>
                                    <Field
                                        name="shop_image"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Upload Shop Image"
                                   //  validate={[required]}
                                    />

                                </div>
                                {this.props.sellers.shop_image != null && this.props.sellers.shop_image != '' ?
                                    <img src={ this.props.sellers.shop_image} height="100" width="100px" /> :
                                    null
                                }
                                <div className="col-md-12 form-group clearfix" >
                                <div className="col-md-6 icheck-primary d-inline">
                                    <input type="radio" id="radioPrimary1"  onChange={this.onChangeText} value="1" name="payment_type" checked={this.state.payment_type=="1" ? true : false }/>
                                    <label for="radioPrimary1">Bank Account 
                                    </label>
                                </div>
                                <div className="col-md-6 icheck-primary d-inline">
                                    <input type="radio" id="radioPrimary2" value="2"  onChange={this.onChangeText} name="payment_type" checked={this.state.payment_type=="2" ? true : false }/>
                                    <label for="radioPrimary2">Mobile Wallet
                                    </label>
                                </div>
                                
                                </div>
                                {this.state.payment_type==="1" ? (
                               <>     
                                <div className="col-md-6">
                                <label className="text-sm">Account Name</label>

                                    <input
                                        name="account_name"
                                        type="text"
                                        className="form-control"
                                        label="Account Name"
                                        validate={required}
                                        value={this.state.account_name}
                                        onChange={this.onChangeText}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Account Number</label>

                                    <input
                                        name="account_number"
                                        type="text"
                                        className="form-control"
                                        label="Account Number"
                                        value={this.state.account_number}
                                        onChange={this.onChangeText}
                                     //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Bank Name</label>

                                    <input
                                        name="bank_name"
                                        type="text"
                                        className="form-control"
                                        label="Bank Name"
                                        value={this.state.bank_name}
                                        onChange={this.onChangeText}
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Branch Name</label>

                                    <input
                                        name="branch_name"
                                        type="text"
                                        className="form-control"
                                        label="Branch Name"
                                        value={this.state.branch_name}
                                        onChange={this.onChangeText}
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Routing Number</label>

                                    <input
                                        name="routing_number"
                                        type="text"
                                        className="form-control"
                                        label="Routing Number"
                                        value={this.state.routing_number}
                                        onChange={this.onChangeText}
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                </>
                                ):
                                <>
                                <div className="col-md-6">
                                    <label>Mobile Banking</label>
                                    <select name="mobile_banking_type"  onChange={this.onChangeText} component="select" className="form-control"
                                    >
                                    <option value=""  selected={false}>Select Mobile Banking</option> 
                                    <option value="1" selected={this.state.mobile_banking_type===1 ? true :false}>Bkash</option> 
                                    <option value="2" selected={this.state.mobile_banking_type===2 ? true :false}>Nogod</option>
                                    <option value="3" selected={this.state.mobile_banking_type===3 ? true :false}>Rocket</option>

                                    </select>
                                </div>
                                  <div className="col-md-6">
                                  <label className="text-sm">Wallet Number</label>

                                    <input
                                        name="wallet_number"
                                        type="text"
                                        className="form-control"
                                        label="Number"
                                        value={this.state.wallet_number}
                                        onChange={this.onChangeText}
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                    </div>
                                 </>   
                                }
                            </div>


                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save Seetings
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}




SellerShopSettings.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchSellers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    sellers: state.sellers.sellers,
    is_updated: state.products.is_updated,
    is_getting:state.sellers.is_getting,
    initialValues: state.sellers.sellers,

});


SellerShopSettings = reduxForm({
    form: 'SellerUpdate',
    enableReinitialize: true

}
)(SellerShopSettings);


export default connect(mapStateToProps, { fetchSellers, updateData })(SellerShopSettings);





import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { required } from "../../constants/Validate";
import { Field, reduxForm } from "redux-form";

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import {CheckPermisionAction} from "../../constants/CustomMethod"
import { fetchProductUnit, addProductUnit, updateProductUnit, deleteProductUnit } from "../../actions/ProductUnitAction"
// import Modal from 'react-modal';
import { Modal, Button } from "react-bootstrap";

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'
import { propTypes } from 'react-widgets/lib/DateTimePicker';

// import { fetchRd, deleteRd } from '../../actions/ProductAction';
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class ProductUnitList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
        this.handleModalShow = this.handleModalShow.bind(this)
        this.saveProductUnit = this.saveProductUnit.bind(this)
        this.updateProductUnit = this.updateProductUnit.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleEditClose = this.handleEditClose.bind(this)
        this.productUnitEdit = this.productUnitEdit.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        product_unit_name: "",
        name: "",
        unit_limit:"",
        action:"",
        id: "",
        show: false,
        showedit: false,
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
    };

    handleModalShow(data) {
        this.setState({ show: true })
    }
    handleClose() {
        this.setState({ show: false })
    }
    handleEditClose() {
        this.setState({ showedit: false, })
    }
    updateProductUnit() {
        var data = {
            name: this.state.product_unit_name,
            unit_limit:this.state.unit_limit,
            action:this.state.action
        }
        // alert(JSON.stringify(data))
        this.props.updateProductUnit(this.state.id, data)
        this.setState({ showedit: false })
    }
    onChangeedit() {
        this.setState({ showedit: true })
    }
    componentDidMount() {
        this.props.fetchProductUnit()
        // this.props.fetchRd(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteProductUnit(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.name + '.',
                        'success'
                    )
                }
            }
        })
    }


    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { name } = this.state;
        console.log([name]);

        this.props.fetchProductUnit('', this.state);
        this.setState({ hideButton: true })

    };
    productUnitEdit(data) {
        this.setState({ showedit: true, product_unit_name: data.name, id: data.id ,unit_limit:data.unit_limit,action:data.action})

    }
    saveProductUnit() {
        var data = {
            name: this.state.name,
            unit_limit:this.state.unit_limit,
            action:this.state.action
        }
        this.props.addProductUnit(data)
        this.setState({ show: false, name: "",unit_limit:"",action:"" })
        this.props.fetchProductUnit(1)
    }

    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchProductUnit(pageNo)
    }
    render() {
        const { show, showedit } = this.state;
        const {permissions} = this.props
        const { product_units, is_loading, count, is_added, is_updated } = this.props
        const page = Math.ceil(count / 10)
        if (is_added) {
            setTimeout(function () {
                window.location.href = '/product-unit-list'
            }, 3000)
        }
        if (is_updated) {
            setTimeout(function () {
                window.location.href = '/product-unit-list'
            }, 3000)
        }
        // let renderPageNumbers;
        // const pageNumbers = [];
        // if (this.props.count !== null) {
        //     for (let i = 1; i <= Math.ceil(this.props.count / 10); i++) {
        //         pageNumbers.push(i);
        //     }

        //     renderPageNumbers = pageNumbers.map(number => {
        //         // let classes = this.state.current_page === number ? styles.active : '';

        //         return (
        //             <span key={number} className="page-link"
        //                 onClick={() => this.props.getPrimaryCategory(number)}>{number}</span>
        //             // <li onClick={() => this.makeHttpRequestWithPage(number)}  className="page-item"><a className="page-link">{number}</a></li>
        //         );
        //     });
        // }

        // const isAdmin = this.props.auth.user.is_admin;

        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Product Unit </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Product Unit</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Product Unit</h3>
                                <div className="float-right">

                                {CheckPermisionAction(permissions,'Inventory','add_product_unit')?

                                    <button
                                        className="btn btn-primary text-white"
                                        onClick={this.handleModalShow}
                                    >

                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Product Unit</b>
                                    </button>
                                    :null}

                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="name" type="text"
                                            onChange={this.onChange} placeholder="Name"
                                            aria-label="Search" />
                                    {CheckPermisionAction(permissions,'Inventory','product_unit_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                        :null}
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'Inventory','view_product_unit_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th>Unit Limit</th>
                                            <th>Unit Action</th>
                                            <th>Action</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/product-unit-list" onClick={() => { window.location.href = "/product-unit-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> : product_units.length !== undefined && product_units.map((product_unit, index) => (
                                            <tr key={product_unit.id}>
                                                <td>{index + 1}</td>
                                                <td>{product_unit.name}</td>
                                                <th>{product_unit.unit_limit}</th>
                                                <th>{product_unit.action===1?"Multiply":"Division"}</th>
                                                


                                                <td>
                                            {CheckPermisionAction(permissions,'Inventory','product_unit_edit')?

                                            <button className="btn btn-warning text-white" onClick={() => this.productUnitEdit(product_unit)}>
                                                        <i className="fas fa-edit text-white" ></i>
                                                    </button>
                                                    :null}
                                                    {/* <Link to={'/primary-category-detail/' + primarycategory.id}> */}
                                                    {/* </Link> */}

                                                    {/* <Link to={'/primary-category-update/' + primarycategory.id}> */}
                                                {CheckPermisionAction(permissions,'Inventory','product_unit_delete')?
                                                    
                                                    <button className="btn btn-danger text-white" onClick={() => this.opensweetalert(product_unit)} >
                                                        <i className="fas fa-trash text-white" ></i>
                                                    </button>
                                                    :null}
                                                    {/* </Link> */}
                                                    {/* {isAdmin ?
                                                        <button className="btn btn-danger" onClick={() => this.opensweetalert(primarycategory)}><i className="fas fa-trash">Delete</i>
                                                        </button> : " "} */}

                                                </td>
                                            </tr>))}
                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>
                            <Modal
                                show={show}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Product Unit</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Product Unit Name"
                                        validate={required}
                                        onChange={this.onChange}
                                    /><br/>
                                    <input
                                        name="unit_limit"
                                        type="number"
                                        className="form-control"
                                        placeholder="Unit Limit"
                                        validate={required}
                                        onChange={this.onChange}
                                    /><br/>
                                    <select name="action" onChange={this.onChange}  className="form-control">
                                        <option value="">Select Unit Action</option>
                                        <option value="1">Multiply</option>
                                        <option value="2">Division</option>
                                    

                                    </select>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.saveProductUnit}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={showedit}
                                onHide={this.handleEditClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Update Product Unit</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <input
                                        name="id"
                                        type="hidden"
                                        value={this.state.id}
                                        className="form-control"
                                        placeholder="Product Unit Id"
                                        validate={required}
                                        onChange={this.onChange}
                                    />
                                    <input
                                        name="product_unit_name"
                                        type="text"
                                        value={this.state.product_unit_name}
                                        className="form-control"
                                        placeholder="Product Unit Name"
                                        validate={required}
                                        onChange={this.onChange}
                                    /><br/>
                                    <input
                                        name="unit_limit"
                                        type="text"
                                        value={this.state.unit_limit}
                                        className="form-control"
                                        placeholder="Unit Limit"
                                        validate={required}
                                        onChange={this.onChange}
                                    /><br/>
                                    <select name="action" onChange={this.onChange} value={this.state.action} className="form-control">
                                        <option value="">Select Unit Action</option>
                                        <option value="1">Multiply</option>
                                        <option value="2">Division</option>
                                    

                                    </select>
                                    
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleEditClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.updateProductUnit}>
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

ProductUnitList.propTypes = {
    fetchProductUnit: PropTypes.func.isRequired,
    deleteProductUnit: PropTypes.func.isRequired,
    addProductUnit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    product_units: state.product_units.product_units,
    count: state.product_units.count,
    next: state.product_units.next,
    is_loading: state.product_units.is_loading,
    is_added: state.product_units.is_added,
    is_updated: state.product_units.is_updated,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});

ProductUnitList = reduxForm({
    form: "ProductUnitList",
})(ProductUnitList);

// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchProductUnit, addProductUnit, updateProductUnit, deleteProductUnit })(ProductUnitList);

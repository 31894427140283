import {
    FETCH_SUBSCRIBER,
    LOADING_SUBSCRIBER,
    FETCH_SUBSCRIBER_FAILED,
    NOT_FOUND_SUBSCRIBER
} from './types';

import {
    SUBSCRIBER_URL
} from '../constants/api'

import { toast } from 'react-toastify'
import axios from 'axios';
import { convert } from "../constants/FormInputType";
// import actions from "redux-form/lib/actions";
import { SearchURLGenerate } from '../constants/CustomMethod'

// FETCH STAFFS
export const fetchSubscribers = (pageNumber, parameters={}) => (dispatch, getState) => {
    dispatch({type: LOADING_SUBSCRIBER});

    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    
    let search_key = [ "email" ]

    

    
   
    
    // console.log(parameters)

    var  search_url=SearchURLGenerate(parameters,search_key);
   
    if((search_url !== '' && search_url !== null) || pageNumber !== '' ){
       var url =SUBSCRIBER_URL
        if(search_url !== '' && search_url !== null){  
            url = url + "?" +search_url;
        }else if (pageNumber){
            url=url + '?page=' + pageNumber
        }
    axios.get(url, {headers,})
        .then(response => {
            dispatch({
                type: FETCH_SUBSCRIBER,
                payload: response.data.results,
                count: response.data.count,
                next: response.data.next
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_SUBSCRIBER_FAILED,
                payload: error.message
            })
        })
    }else{
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SUBSCRIBER });
       
    }
};


// Detail staff

import {
    LOADING_WAREHOUSE,
    FETCH_WAREHOUSE,
    NOT_FOUND_WAREHOUSE,
    DETAIL_WAREHOUSE,
    DELETE_WAREHOUSE,
    LOADING_STOCK,
    FETCH_STOCK,
    DETAIL_STOCK,
    DELETE_STOCK,
    NOT_FOUND_STOCK
} from '../actions/types';

const initialState = {
    warehouses: [],
    warehouse: {},
    count: null,
    is_getting_warehouse: false,
    next: null,
    is_loading: false,
    errors: ''
};

// Warehouse Reducer
export const WarehouseReducer= (state = initialState, action)=> {
    switch (action.type) {
        case LOADING_WAREHOUSE:
            return {
                ...state,
                is_getting_warehouse: false,
                is_loading: true
            };
        case FETCH_WAREHOUSE:
           
            return {
                ...state,
                warehouses: action.payload,
                count: action.count,
                next: action.next,
                is_getting_warehouse: true,
                is_loading: false
            };
        case DETAIL_WAREHOUSE:
            return {
                ...state,
                is_getting_warehouse: true,
                warehouse: action.payload,
                
            };
        case DELETE_WAREHOUSE:
            return {
                ...state,
                warehouses: state.warehouses.filter(ware => ware.id !== action.payload)
            };

        case NOT_FOUND_WAREHOUSE:
            return {
                ...state,
                warehouses: [],
                count: 0,
                next: 0,
                is_getting_warehouse: false,
                is_loading: false,

            };
        default:
            return state;
    }
}


// Stock Reducer
export const StockReducer= (state = {
                stocks: [],
                stock: {},
                count: null,
                is_getting_stock: false,
                next: null,
                is_loading: false,
                errors: ''
            }, action)=> {
    switch (action.type) {
        case LOADING_STOCK:
            return {
                ...state,
                is_getting_stock: false,
                is_loading: true
            };
        case FETCH_STOCK:
           
            return {
                ...state,
                stocks: action.payload,
                count: action.count,
                next: action.next,
                is_getting_stock: true,
                is_loading: false
            };
        case DETAIL_STOCK:
            return {
                ...state,
                is_getting_stock: true,
                stock: action.payload,
                
            };
        case DELETE_STOCK:
            return {
                ...state,
                stocks: state.stocks.filter(ware => ware.id !== action.payload)
            };

        case NOT_FOUND_STOCK:
            return {
                ...state,
                stocks: [],
                count: 0,
                next: 0,
                is_getting_stock: false,
                is_loading: false,

            };
        default:
            return state;
    }
}
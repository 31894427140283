import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { loadUser, logout } from "../../../actions/auth";
import { connect } from 'react-redux';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
const sideBar = props => {

    return (
        <div>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                {/* <!-- Brand Logo --> */}

                <NavLink to="/" className="brand-link">
                    <img src="/static/dist/img/favicon-32x32.png" alt="My Choice Bd" className="brand-image img-circle elevation-3"
                        style={{ opacity: .8 }} />
                    <span className="brand-text font-weight-light"><b>My Choice BD</b></span>
                </NavLink>


                {/* <!-- Sidebar --> */}
                <div className="sidebar">
                    {/* <!-- Sidebar user panel (optional) --> */}
                    {/* "/static/dist/img/avatar5.png" */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                        <div className="image">
                            <img src={props.auth.user.user_photo} style={{ width: "65px", height: "65px" }} className="rounded-circle elevation-2"
                                alt="" />
                        </div>
                        <div className="info">
                            <NavLink to="/" className="d-block">{props.auth.user ? props.auth.user.first_name : ""}</NavLink>
                            <p style={{ color: "white", fontSize: 14 }}>
                                {props.auth.user ? 'User: ' + props.auth.user.phone_no : ""}
                            </p>
                        </div>
                    </div>


                    {/* <!-- Sidebar Menu --> */}
                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                            {/* Start Agent Management */}
                            {/* {props.auth.user.is_admin ? ( */}

                                <>
                                
                                {/* {CheckPermisionAction(props.permissions,"agentstock") ? ( */}
                                    {props.auth.user.is_admin || props.auth.user.is_primary_agent ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Agent Management">Agent Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/agent-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Agent List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/agent-customer-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Agent Customer List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/agent-stock-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Agent Stock</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/agent-product-sale-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Agent Product Sale</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/agent-product-stock-in-report" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Agent Product Stock IN Report</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/agent-product-stock-out-report" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Agent Product Stock OUT Report</p>
                                            </NavLink>
                                        </li>
                                        {/* <li className="nav-item">
                                            <NavLink to="/agent-product-sale-reports" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Agent Product Sale Reports</p>
                                            </NavLink>
                                        </li> */}
                                        
                                        
                                    </ul>
                                </li>
                                ):null}
                                </>
                            {/* ) : null} */}
                            {props.auth.user.is_agent ? (
                                <>
                                    {/* <li className="nav-item">
                                        <NavLink to="/agent-settings" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Settings</p>
                                        </NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <NavLink to="/agent-profile-settings" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Profile</p>
                                        </NavLink>
                                    </li>
                                </>
                            ) : null}
                            {/* End Agent Management */}
                            {/* Start Agent Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Account Management">Account Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/account-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Account List</p>
                                            </NavLink>
                                        </li>
                                        
                                        
                                    </ul>
                                </li>
                            ) : null}
                            {/* End Agent Management */}


                            {/* Start Inventory Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Inventory Management">Inventory Management <i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/product-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Product List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/bundle-product-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Bundle Product List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/product-unit-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Product Unit</p>
                                            </NavLink>
                                        </li>
                                        {/* Start Category Management */}

                                        {/* End Category Management */}
                                        {/* Start Attributes Management */}
                                        <li className="nav-item has-treeview">
                                            <Link to="/attributes-list" className="nav-link">
                                                <i className="nav-icon fas fa-edit"></i>
                                                <p>Attributes List</p>
                                            </Link>
                                        </li>
                                        <li className="nav-item has-treeview">
                                            <Link to="/attribute-option-list" className="nav-link">
                                                <i className="nav-icon fas fa-edit"></i>
                                                <p>Attributes Option </p>
                                            </Link>
                                        </li>
                                        {/* End Attributes Management */}
                                        {/* Start Tags Management */}
                                        <li className="nav-item has-treeview">
                                            <Link to="/tags-list" className="nav-link">
                                                <i className="nav-icon fas fa-edit"></i>
                                                <p>Tags</p>
                                            </Link>
                                        </li>
                                        {/* End Tags Management */}
                                        {/* Start Brands Management */}
                                        <li className="nav-item has-treeview">
                                            <Link to="/brands-list" className="nav-link">
                                                <i className="nav-icon fas fa-edit"></i>
                                                <p>Brands</p>
                                            </Link>
                                        </li>
                                        {/* End Brands Management */}
                                        {/* Start Ratings/Reviews Management */}
                                        <li className="nav-item has-treeview">
                                            <Link to="/rating-review-list" className="nav-link">
                                                <i className="nav-icon fas fa-edit"></i>
                                                <p>Ratings/Reviews List</p>
                                            </Link>
                                        </li>
                                        {/* End Ratings/Reviews Management */}

                                    </ul>

                                </li>
                            ) : null}
                            {/* End Inventory Management */}
                            {/* Start Category Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Category Management">Category Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/primary-category-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Primary Category</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/secondary-category-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Secondary Category</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/child-category-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Child Category</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : null}
                            {/* End Category Management */}
                            {/* Start Order Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Order Management">Order Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/order-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Order List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/return-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Return/Refund List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/payment-method-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Payment Method</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : null}
                            {/* End Order Management */}

                            {/* Start Seller Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Seller Management">Seller Management <i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        {props.auth.user.is_admin || props.auth.user.is_agent ? (
                                            <li className="nav-item">
                                                <NavLink to="/seller-list" className="nav-link">
                                                    <i className="far fa-circle nav-icon"></i>
                                                    <p>Seller List</p>
                                                </NavLink>
                                            </li>
                                        ) : null}
                                        {/* {props.auth.user.is_admin ? (
                                            <li className="nav-item">
                                                <NavLink to="/withdraw-list" className="nav-link">
                                                    <i className="far fa-circle nav-icon"></i>
                                                    <p>Withdraw List</p>
                                                </NavLink>
                                            </li>
                                        ) : null} */}
                                    </ul>
                                </li>
                            ) : null}
                            {props.auth.user.group === 2 ? (
                                <>
                            {CheckPermisionAction(props.permissions,"Inventory") ? (

                                <li className="nav-item">
                                    <NavLink to="/seller-product-list" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Product List</p>
                                    </NavLink>
                                </li>
                            ):null}
                            </>
                            ) : null}
                            {props.auth.user.group === 2 ? (
                                <>
                            {CheckPermisionAction(props.permissions,"Inventory") ? (

                                <li className="nav-item">
                                    <NavLink to="/bundle-product-list" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Bundle Product List</p>
                                    </NavLink>
                                </li>
                            ):null}
                            </>
                            ) : null}
                            {props.auth.user.group === 2 ? (
                                <>
                            {CheckPermisionAction(props.permissions,"Order") ? (

                                <li className="nav-item">
                                    <NavLink to="/seller-order-list" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Order List</p>
                                    </NavLink>
                                </li>
                            ):null}
                            </>
                            ) : null}
                            {props.auth.user.group === 2 ? (
                                <>
                            {CheckPermisionAction(props.permissions,"Seller") ? (

                                <li className="nav-item">
                                    <NavLink to="/seller-withdraw-list" className="nav-link">
                                        <i className="far fa-circle nav-icon"></i>
                                        <p>Withdraw List</p>
                                    </NavLink>
                                </li>
                            ):null}
                            </>
                            ) : null}
                            {/* { props.auth.user.group ===2 ? (
                                            <li className="nav-item">
                                                <NavLink to="/profile-setting" className="nav-link">
                                                    <i className="far fa-circle nav-icon"></i>
                                                    <p>Porfile Setting</p>
                                                </NavLink>
                                            </li>
                                              ):null} */}
                            {props.auth.user.group === 2 ? (
                                <>
                                    <li className="nav-item">
                                        <NavLink to="/shop-settings" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Shop Settings</p>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to="/profile-settings" className="nav-link">
                                            <i className="far fa-circle nav-icon"></i>
                                            <p>Profile Settings</p>
                                        </NavLink>
                                    </li>
                                </>
                            ) : null}

                            {/* End Seller Management */}
                            {/* Start Customer Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Customer Service Management">Customer Service Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        {/* <li className="nav-item">
                                            <NavLink to="/customer-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Customer List</p>
                                            </NavLink>
                                        </li> */}
                                        <li className="nav-item">
                                            <NavLink to="/subscriber-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Subsribers List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/support-ticket-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p> Support Ticket List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/contact-messages" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p> Contact Messages</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/support-messages" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p> Support Messages</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : null}
                            {/* End Customer Management */}
                            {/* Start User Setting */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="User Management">User Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/user-roles-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>User Roles List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/users-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>User List</p>
                                            </NavLink>
                                        </li>

                                        <li className="nav-item">
                                            <NavLink to="/user-permissions" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>User Permissions</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : null}
                            {/* End User Setting */}
                            {/* Start Campaign and Offer Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Campaign Management">Campaign Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/campaign-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Campaign</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/coupon-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Coupon</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : null}
                            {/* End Campaign and Offer Management */}

                            {/* Start Shipping Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Shipping Management">Shipping Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/districts-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>District</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/shipping-zone-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Shipping Zone</p>
                                            </NavLink>
                                        </li>


                                        <li className="nav-item">
                                            <NavLink to="/shipping-class-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Shipping Class</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : null}
                            {/* End Shipping Management */}
                            {/* Start Tax Management */}
                            {/* {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p>Tax Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/tax-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Tax</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : null} */}
                            {/* End Tax Management */}
                            {/* Start Setting Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Setting Management">Setting<i className="fas fa-angle-left right"></i></p>
                                    </a>


                                    <ul className="nav nav-treeview">
                                    <li className="nav-item">
                                    <NavLink to="/change-password" className="nav-link">
                                        <i className="nav-icon fa fa-lock"></i>
                                        <p>Change Password</p>
                                    </NavLink>
                                    </li>
                                    
                                        <li className="nav-item has-treeview">
                                            <a href="/" className="nav-link">
                                                <i className="nav-icon fas fa-edit"></i>
                                                <p>Appearance Setting<i className="fas fa-angle-left right"></i></p>
                                            </a>
                                            <ul className="nav nav-treeview">

                                            <li className="nav-item has-treeview">
                                            <a href="/" className="nav-link">
                                                <i className="nav-icon fas fa-edit"></i>
                                                <p>Page Setting<i className="fas fa-angle-left right"></i></p>
                                            </a>
                                            <ul className="nav nav-treeview">
                                                <li className="nav-item">
                                                    <NavLink to="/terms-condition" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Terms & Condition</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/shipping-policy" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Shipping Policy</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/privacy-policy" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Privacy Policy</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/return-refund-policy" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Return & Refund Policy</p>
                                                    </NavLink>
                                                </li>

                                                <li className="nav-item">
                                                    <NavLink to="/about-us" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>About Us</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/faq" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>FAQ</p>
                                                    </NavLink>
                                                </li>
                                                
                                            </ul>
                                            </li>
                                                <li className="nav-item">
                                                    <NavLink to="/logo-list" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Logo</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/favicon" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Favicon</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/pre-loader" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Pre-Loader</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/footer-payment-image" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Footer Payment Image</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/add-copyright" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Copyright Text</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/contact-info-list" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Contact Information</p>
                                                    </NavLink>
                                                </li>

                                                <li className="nav-item">
                                                    <NavLink to="/slide-setting-list" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Slide Setting</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/slide-right-side-setting-list" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Slide Right Side Setting</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/banner-setting-list" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Banner Setting</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/social-media-list" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Social Media Setting</p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/footer-left-section" className="nav-link">
                                                        <i className="far fa-circle nav-icon"></i>
                                                        <p>Footer Left Section Setting</p>
                                                    </NavLink>
                                                </li>
                                            </ul>


                                        </li>
                                    </ul>
                                </li>
                            ) : null}
                            {/* End Setting Management */}
                            {/* Start Warehouse Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Transaction Management">Transaction Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/transaction-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Transaction List</p>
                                            </NavLink>
                                        </li>
                                        
                                        
                                    </ul>
                                </li>
                            ) : null}
                            {/* End Warehouse Management */}

                            {/* Start Stock Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Stock Management">Stock Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/stock-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Stock List</p>
                                            </NavLink>
                                        </li>
                                        
                                        
                                    </ul>
                                </li>
                            ) : null}
                            {/* End Stock Management */}

                            {/* Start Warehouse Management */}
                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Warehouse Management">Warehouse Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/warehouse-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Warehouse List</p>
                                            </NavLink>
                                        </li>
                                        
                                        
                                    </ul>
                                </li>
                            ) : null}
                            {/* End Warehouse Management */}


                            {/* Start Blog Management */}


                            {/* {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p>Blog Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/blog-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Blog</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/blog-category-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Blog Category</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/blog-tags-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Blog Tags</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : null} */}
                            {/* End Blog Management */}


                            {/* Start Medicine Delivery Management */}
                            {/* {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p>Medicine Delivery Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/medicine-delivery-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Medicine Delivery List</p>
                                            </NavLink>
                                        </li>
                                        
                                    </ul>
                                </li>
                            ) : null} */}
                            {/* End Medicine Delivery Management */}

                            {/* Start Rider Car Trip Management */}
                            {/* {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p>Rider Car Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/rider-car-trip-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Rider Car Trip List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/rider-car-type-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p> Car Type List</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : null} */}
                            {/* End Rider Car Trip Management */}


                            {/* Start Percel Express Management */}
                            {/* {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p>Percel Express Management<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/percel-express-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Percel Express List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/percel-type-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p> Percel Type List</p>
                                            </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to="/percel-plan-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p> Percel Plan List</p>
                                            </NavLink>
                                        </li>
                                    </ul>
                                </li>
                            ) : null} */}
                            {/* End Percel Express Management */}

                            {/* Start Media Library Management */}


                            {/* {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p>Mobile App Slider<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/mobile-app-slider-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Mobile App Slider</p>
                                            </NavLink>
                                        </li>

                                    </ul>
                                </li>
                            ) : null} */}
                            {/* End Media Library Management */}

                            {/* Start Media Library Management */}


                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Media Library">Media Library<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/media-library-list" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Media Library</p>
                                            </NavLink>
                                        </li>

                                    </ul>
                                </li>
                            ) : null}
                            {/* End Media Library Management */}


                            {/* Start Media Library Management */}


                            {props.auth.user.is_admin ? (
                                <li className="nav-item has-treeview">
                                    <a href="/" className="nav-link">
                                        <i className="nav-icon fas fa-edit"></i>
                                        <p title="Reports">Reports<i className="fas fa-angle-left right"></i></p>
                                    </a>
                                    <ul className="nav nav-treeview">
                                        <li className="nav-item">
                                            <NavLink to="/sales-report" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Sales Report</p>
                                            </NavLink>
                                            <NavLink to="/product-stock-report" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Product Stock Report</p>
                                            </NavLink>
                                            <NavLink to="/agent-wise-report" className="nav-link">
                                                <i className="far fa-circle nav-icon"></i>
                                                <p>Agent Wise Report</p>
                                            </NavLink>
                                        </li>

                                    </ul>
                                </li>
                            ) : null}
                            {/* End Media Library Management */}







                        </ul>
                    </nav>
                    {/* <!-- /.sidebar-menu --> */}
                </div>
                {/* <!-- /.sidebar --> */}
            </aside>
        </div >
    )
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        groups: state.groups,
        permissions: state.userpermissions.userpermissions


    }

};


const mapDispatchToProps = dispatch => {
    return {
        loadUser: () => {
            return dispatch(loadUser());
        },
        logout: () => dispatch(logout()),
    }
};
export default connect(mapStateToProps, mapDispatchToProps)(sideBar);

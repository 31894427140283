import {
    LOADING_TAGS,
    FETCH_TAGS,
    ADD_TAGS,
    DETAIL_TAGS,
    UPDATE_TAGS,
    DELETE_TAGS,
    NOT_FOUND_TAGS

} from './types';

import { GET_TAGS_URL,GET_TAGS_ALL_URL } from '../constants/api';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { SearchURLGenerate } from '../constants/CustomMethod'


// Add Tags
// export const addTags = (data) => {
//     console.log('data', data);

//     // let form_data = {
//     //     'name': data.name,

//     // }

//     let url = GET_TAGS_URL;
//     let token = localStorage.getItem('token');
//     // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
//     console.log(token);
//     axios.post(url, data,
//         {
//             headers: {
//                 'content-type': 'application/json',
//                 'Authorization': `Token ${token}`
//             }

//         })
//         .then(res => {
//             toast.success("New Tags created successfully.");
//             console.log(res.data);

//         })
//         .catch(err => {
//             toast.error("Please try again.");
//             console.log(err)
//         })
// };

export const addTags = (data) => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .post(GET_TAGS_URL, data, { headers })
        .then(response => {

            dispatch({
                type: ADD_TAGS,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success('New Tags added successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};




export const fetchTags = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_TAGS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        //  headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_TAGS_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_TAGS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_TAGS });

    }
};


// Tags Detail
export const tagsDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_TAGS_URL + `${id}/`;

    axios.get(url, { headers })
        .then(response => {

            dispatch({
                type: DETAIL_TAGS,
                payload: response.data,
            })
        })
};



// Update Tags
export const updateTags = (id, data) => (dispatch, getState) => {

    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    // let form_data = {
    //     'name': data.name,
    // }

    let url = GET_TAGS_URL + `${id}/`;

    axios.put(url, data, { headers })
        .then(response => {
            console.log(response.data)
            dispatch({
                type: UPDATE_TAGS,
                payload: response.data
            });

            toast.success("Tags successfully updated");
            console.log('Success');
        })
        .catch(err => {
            // dispatch();
            toast.error('Please try again', err);
            console.log(err);
        })
};


// Delete Tags
export const deleteTags = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(GET_TAGS_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_TAGS,
                payload: id,
            });
            toast.success('Tags deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};

export const getProductTagsForSelect = (name) => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    dispatch({ type: LOADING_TAGS });
    var url = ''
    if (name != '') {
        url = GET_TAGS_ALL_URL
    } else {
        url = GET_TAGS_ALL_URL + '?search=' + name
    }


    axios.get(url, { headers, })
        .then(response => {
            //  alert(JSON.stringify(response))
            var results = []
            for (var i = 0; i < response.data.length; i++) {
                results.push({ label: response.data[i].name, value: response.data[i].id })
            }
            dispatch({
                type: FETCH_TAGS,
                payload: response.data ? results : [],
                count: response.data.count,
                next: response.data.next
            })
        })




};
import {
    LOADING_CUSTOMER,
    FETCH_CUSTOMER,
    DETAIL_CUSTOMER,
    DELETE_CUSTOMER,
    ADD_DATA,
    UPDATE_CUSTOMER,
    UPDATED_DATA,
    EMAIL_SENDING,
    NOT_FOUND_CUSTOMER,


} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { CUSTOMERS_URL, SELLER_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


export const fetchCustomers = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_CUSTOMER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["phone_no", "email"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = CUSTOMERS_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_CUSTOMER,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_CUSTOMER });

    }
};

// Customer Detail
export const customerDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = CUSTOMERS_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_CUSTOMER,
                payload: response.data,
            })
        })
};

// // Delete Customer
export const deleteCustomer = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(CUSTOMERS_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_CUSTOMER,
                payload: id,
            });
            toast.success('Customer has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

export const addData = (data, url, message = "Data") => (dispatch, getState) => {
    let headers = { 'content-type': 'application/json' };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios
        .post(url, data, { headers })
        .then(response => {
            dispatch({
                type: ADD_DATA,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been added successfully");
        })
        .catch(error => {
            console.log(error)
            if (error.response) {
                toast.warn(error.response.data.error)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                toast.warn("Something Wrong ! Please try again");

            } else {
                toast.warn("Something Wrong ! Please try again");
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }

        })
};

export const updateDatas = (data, url, message = "") => (dispatch, getState) => {

    let headers = { 'content-type': 'application/json' };
    let { token } = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .put(url, data, { headers })
        .then(response => {

            // alert(response.data)
            dispatch({
                type: UPDATE_CUSTOMER,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been updated successfully");
        }).catch(error => {
            console.log(error)
            toast.warn(message + " has not been updated successfully");
        })
};

export const sentMail = (data, url,email,message="Email") => (dispatch, getState) => {

    let headers = { 'content-type': 'application/json' };
    let { token } = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios
        .post(url, {data:data,email:email}, { headers })
        .then(response => {

            dispatch({
                type: EMAIL_SENDING,
            });
            toast.success(message+" has been sent successfully");
        }).catch(error => {
            console.log(error)
            toast.warn(message + " has not been sent successfully");
        })
};


export const searchCustomer = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_CUSTOMER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(CUSTOMERS_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_CUSTOMER,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};

export const searchSeller = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_CUSTOMER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(SELLER_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_CUSTOMER,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};
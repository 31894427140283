import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";
import { required,phoneNumber, matched } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { addData } from '../../../actions/CouponActions';
import { AGENT_CUSTOMER_URL} from "../../../constants/api";
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}


momentLocalizer(moment);


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
                }


// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Main form
class AddAgentCustomer extends Component {

  static propTypes = {
    onChangeDetails: PropTypes.func
  };

    state={
        payment_type:"1",
        verified: false,
        
        
        

    }

   
    componentDidMount() {
 
    }

    onChanged=(e)=>{
        
        [e.target.name]=e.target.value
}


    submit(event) {
            const {allowProductSize,cuisine_list,attributes,colorDetails} = this.state
            var form_data={
                customer_name:event.customer_name,
                customer_phone_no:event.customer_phone_no,
                address:event.address,
                is_active:true
            }
            // var form_data = new FormData();
            // // Object.keys(event).forEach(key => form_data.append(key, event[key]));
            // form_data.append('customer_name',event.customer_name);
            // form_data.append('customer_phone_no',event.customer_phone_no);
            // form_data.append('address',event.address);

            //    alert(JSON.stringify(event))
            //    console.log(form_data)
            //    console.log(event)
            this.props.addData(form_data, AGENT_CUSTOMER_URL, "Agent Customer")

            }
    
    render() {
        
        const { handleSubmit, submitting } = this.props;
       
        if (this.props.is_added) {
            setTimeout(function () {
                window.location.href = '/agent-customer-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Agent Customer</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Add Agent Customer</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Agent Customer Information</h3>
                        <div className="float-right">
                            <Link to="/agent-customer-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="customer_name"
                                        type="text"
                                        component={renderField}
                                        label="Customer Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="customer_phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Customer Phone No"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="address"
                                        type="text"
                                        component={renderTextArea}
                                        label="Address"
                                       validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                                
      
                            </div>
                            

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddAgentCustomer.propTypes = {
    
    addData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    is_added: state.products.is_added,

});


AddAgentCustomer = reduxForm({
    form: 'AddAgentCustomer',
}
)(AddAgentCustomer);


export default connect(mapStateToProps, { addData})(AddAgentCustomer);

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchRiderCarType } from "../../../actions/RestaurantActions"
import {  addData } from "../../../actions/CustomerActions"

import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';


import { toast } from "react-toastify";
import { BASE_URL, DISTRICT_URL } from "../../../constants/api";
import {RIDER_CAR_TYPE_URL } from '../../../constants/api';


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}



// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);


const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};

// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddRiderCarType extends Component {

    componentDidMount() {
        // this.props.getRides(0)
        this.props.fetchRiderCarType()
    }


    submit(values) {


// alert(JSON.stringify(values))
        let form_data = {
            'name':values.name,
            
        }
        

        this.props.addData(form_data, RIDER_CAR_TYPE_URL, "Car Type")

    }

    render() {
        if (this.props.is_added) {
            setTimeout(() => {
                window.location.href = "/rider-car-type-list"
            }, 3000);
        }

        const { handleSubmit, submitting } = this.props;
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Rider Car Type </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Rider Car Type add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Rider Car Type Information</h3>
                        <div className="float-right">
                            <Link to="/rider-car-type-list" className="btn btn-info float-right text-white"><b>
                                Back</b>
                            </Link>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="Car Type Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                

                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddRiderCarType.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchRiderCarType: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

    rider_car_types: state.rider_car_types.rider_car_types,
    is_added: state.customers.is_added,



});


AddRiderCarType = reduxForm({
    form: 'AddRiderCarType',
}
)(AddRiderCarType);


export default connect(mapStateToProps, { fetchRiderCarType, addData })(AddRiderCarType);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import { BASE_URL, PRODUCT_BULK_URL, TAX_URL ,RESTAURANT_BULK_URL} from '../../../constants/api'
// import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'
import { BulkAction } from '../../../actions/ProductAction';
import { fetchAgentProductStock,deleteAgentProductSale,fetchAgentProductSale } from '../../../actions/AgentActions';
import Report from '../../../components/pdf/Report'

import { Field, reduxForm } from "redux-form";
import ReactPaginate from 'react-paginate';



const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
    required = false,
  }) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
      <div className="form-group">
        <label className="text-sm">{label}</label>
        {required ? <span className="requiredField">*</span> : ""}
        <div>
          <input
            {...input}
            placeholder={label}
            type={type}
            className={className}
          />
          {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  };
  
  // Date picker
  const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
    <div>
      <label className="text-sm">{label}</label>
      <DateTimePicker
        onChange={onChange}
        format="DD-MM-YYYY hh:mm A"
        time={showTime}
        value={!value ? null : new Date(value)}
        placeholder={label}
      />
    </div>;
  
  


class AddAgentProductStockSaleList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
        // this.getCSVData=this.getCSVData.bind(this)
        this.handleModalShow = this.handleModalShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        
        this.handleDiscountClose = this.handleDiscountClose.bind(this)
        this.handleDiscountModalShow = this.handleDiscountModalShow.bind(this)
        this.handleDateChange=this.handleDateChange.bind(this)


    }


    state = {
        // search: " ",
        // phone_no: "",
        show: false,
        discountshow:false,
        restaurant_name: "",
        csv_data: [],
        contact_number: "",
        agent_product_stocks: [],
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
        created_by__phone_no: '',
        customer__customer_phone_no:'',
        customer__customer_name:'',
        invoice_no:'',
        bulk: false,
        allchecked: false,
        get_stock: false,
        checkedvalues: [],
        allchecked: false,
        bulk:false,
        load:false


    };

    handleClose() {
        this.setState({ show: false });
    }
    handleModalShow() {

        this.setState({ show: true });
    }

    handleDiscountClose() {
        this.setState({ discountshow: false });
        window.location.reload()
    }
    handleDiscountModalShow() {

        this.setState({ discountshow: true });
    }
    handleDateChange(value, e) {
        this.setState({ discount_from_date: value });
    }
    handleToDateChange(value, e) {
        this.setState({ discount_to_date: value });
    }
    componentDidMount() {
        this.props.fetchAgentProductSale(1);
    }
   

    

    
   



    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_getting_stock) {
            if (nextProps.is_getting_stock !== prevState.get_stock) {
                var stocks = []
                for (var i = 0; i < nextProps.stocks.length; i++) {
                    Object.assign(nextProps.stocks[i], { 'checked': false });
                    stocks.push(nextProps.stocks[i])
                }
                console.log(stocks)
                return {
                    stocks: stocks,
                    get_stock: true
                }
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.stocks != this.props.stocks){
            this.setState({stocks:this.props.stocks})
        }

    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete  ' + data.customer + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteAgentProductSale(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.customer + '.',
                        'success'
                    )
                }
            }
        })
    }

    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = []

            for (var i = 0; i < this.state.stocks.length; i++) {
                if (this.state.stocks[i].checked) {
                    results.push(this.state.stocks[i].id)
                }
            }

            if (value === "1") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, RESTAURANT_BULK_URL
                )
            } else if (value === "0") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, RESTAURANT_BULK_URL
                )
            
            }else {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, RESTAURANT_BULK_URL
                )
            }
        }
    }


    toggleAllChange=(e)=> {
        const { value, checked } = e.target;
        let stocks = [...this.props.stocks]
        stocks.forEach(product => product.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, stocks, bulk: true })
        } else {
            this.setState({ allchecked: checked, stocks, bulk: false })
        }
    }

    onSingleChange = index => (e) => {
        let stocks = [...this.state.stocks]
        // alert(primcategories)
        stocks[index]["checked"] = e.target.checked
        let recent_length = stocks.filter(product => product.checked).length;

        if (stocks.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        // this.setState({ primcategories })

    };

   

    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchAgentProductSale(pageNo)
    }
    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };
    handleUploadFile = (event) => {
        this.setState({
            [event.target.name]: event.target.files[0],

        })



    }
    handleInputText = (event) => {
        this.setState({
            [event.target.name]: event.target.value,

        })
    }
    closeModalHandler = () => {
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { product_sku,vendor__shop_name, product_name, status } = this.state;

        this.props.fetchAgentProductSale('', this.state);
        this.setState({get_product:false, hideButton: true })

    };

    render() {
        // alert(JSON.stringify(this.state.products))
        const {permissions,agent_product_sales} = this.props
        const { show, bulk, allchecked, checkedvalues, stocks,discountshow } = this.state;
        const page = Math.ceil(this.props.count / 10)



        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/agent-product-sale-list'
            },
                1000);
        }
 
        if (this.props.is_uploaded) {
            window.location.href = '/agent-product-sale-list'
        }


        return (
            <div>
                {/* <Report/> */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Agent Product Sale List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Agent Product Sale list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">



                    <div className="col-12">
                        <div className="card">

                            <div className="card-header">
                                <h3 className="card-title">Agent Product Sale List</h3>

                                <div className="float-right">
                                

                                <a class="btn btn-primary" href={BASE_URL+'/api/v1/export-stock-report/'} role="button">Download Stock List</a>
                                   
                                    <Link to="/add-agent-product-sale" className="btn btn-success text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Agent Product Sale</b></Link>&nbsp;
                                    


                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        
                                        <input className="form-control mr-sm-3" name="customer__customer_name" type="text"
                                            onChange={this.onChange} placeholder="Customer Name"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-3" name="customer__customer_phone_no" type="text"
                                            onChange={this.onChange} placeholder="Customer Phone No"
                                            aria-label="Search" />
                                         <input className="form-control mr-sm-3" name="invoice_no" type="text"
                                            onChange={this.onChange} placeholder="Invoice No"
                                            aria-label="Search" />
                                        {/* <input className="form-control mr-sm-3" name="email" type="text"
                                            onChange={this.onChange} placeholder="Email"
                                            aria-label="Search" /> */}
                                        
                                        
                                        

                                      
                                        {/* {CheckPermisionAction(permissions,'Stock','search')? */}

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                         {/* :null
                                        }  */}
                                    </form>
                                </div>


                            </div>
                            <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Action--</option>
                                            <option value="1" >Activated</option>
                                            <option value="0">Deactivated</option>
                                            {/* <option value="2">Delete</option> */}
                                            
                                           
                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>
                            
                            {/* {CheckPermisionAction(permissions,'Stock','view_stock_list')? */}
                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                        {/* <th>
                                                <input type="checkbox"
                                                    checked={allchecked}
                                                    onChange={this.toggleAllChange}
                                                />
                                            </th> */}
                                           <th>SL</th>
                                            <th>Invoice No</th>
                                            <th>Customer Name</th>
                                            <th>Customer Phone No</th>
                                            <th>Total Price</th>
                                            <th>Total Quantity</th>
                                            {/* <th>Warehouse name</th> */}
                                            <th>Options</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/agent-product-sale-list" onClick={() => { window.location.href = "/agent-product-sale-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ?
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>
                                            :  agent_product_sales.length != undefined && agent_product_sales.map((agentsale, index) => (
                                                <tr key={agentsale.id}>

                                                {/* <td>
                                                    <input type="checkbox" onChange={this.onSingleChange(index)} checked={stock.checked ? true : false} value={stock.id} />
                                                </td> */}
                                                <td>{index +1}</td>
                                                <td>{agentsale.invoice_no}</td>
                                                    <td>
                                                        {agentsale.customers.customer_name}
                                                    </td>
                                                    <td>{agentsale.customers.customer_phone_no}</td>
                                                    <td>{agentsale.total_price}</td>
                                                    <td>{agentsale.total_quantity}</td>        
                                                    {/* <td>{stock.warehouse_name}</td> */}
                                                   
                                                    <td>

                                                        <div className="btn-group">
                                                    {/* {CheckPermisionAction(permissions,'Stock','stock_options')? */}

                                                     <>
                                                            <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                            <div className="dropdown-menu">
                                                                {/* <a href={'/agent-product-sale-edit/' + agentsale.id} className="dropdown-item">Edit</a> */}
                                                                <div className="dropdown-divider"></div>
                                                                <a href={'/agent-product-sale-view/' + agentsale.id} className="dropdown-item">View</a>
                                                                <div className='dropdown-divider'></div>
                                                                <a href="#" onClick={() => this.opensweetalert(agentsale)} className="dropdown-item">Delete</a>

                                                            </div>
                                                        </>
                                                             {/* :null}  */}
                                                        </div>




                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                             {/* :null}  */}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">

                                {/*<span className="page-link" onClick={() => this.props.fetchstaffs(this.props.next)}>&laquo;</span>*/}
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }

                            </div>
                            

                            

                        </div>
                     
                    </div>
                </div>

            </div>
        )
    }
}

AddAgentProductStockSaleList.propTypes = {
    fetchAgentProductSale: PropTypes.func.isRequired,
    BulkAction: PropTypes.func.isRequired,
    deleteAgentProductSale:PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    agent_product_sales: state.agent_product_sales.agent_product_sales,
    count: state.agent_product_sales.count,
    next: state.agent_product_sales.next,
    is_loading: state.agent_product_sales.is_loading,
    is_uploaded: state.agent_product_sales.is_uploaded,
    bulk: state.products.bulk,
    get_agent_product_sale:state.agent_product_sales.get_agent_product_sale,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});

AddAgentProductStockSaleList = reduxForm({
    form: "AddAgentProductStockSaleList",
})(AddAgentProductStockSaleList);
export default connect(mapStateToProps, { fetchAgentProductStock, BulkAction,deleteAgentProductSale,fetchAgentProductSale})(AddAgentProductStockSaleList);


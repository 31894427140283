import {
    LOADING_BLOG_POST,
    FETCH_BLOG_POST,
    DETAIL_BLOG_POST,
    DELETE_BLOG_POST,
    NOT_FOUND_BLOG_POST,
    LOADING_BLOG_CATEGORY,
    FETCH_BLOG_CATEGORY,
    DETAIL_BLOG_CATEGORY,
    DELETE_BLOG_CATEGORY,
    NOT_FOUND_BLOG_CATEGORY,
    LOADING_BLOG_TAG,
    FETCH_BLOG_TAG,
    DETAIL_BLOG_TAG,
    DELETE_BLOG_TAG,
    NOT_FOUND_BLOG_TAG,


} from '../actions/types';




//*****************************START BLOG MANAGEMENT REDUCER******************************** */

export const BlogPostReducer = (
    state =
        {
            blog_posts: [],
            blog_post: {},
            count: null,
            next: null,
            is_added: false,
            is_loading: false,
            is_getting: false,
            is_updated: false,
        }
    , action) => {
    switch (action.type) {
        case LOADING_BLOG_POST:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_BLOG_POST:
            return {
                ...state,
                blog_posts: action.payload,
                count: action.count,
                next: action.next,
                is_getting: true,
                is_loading: false
            };
        case DETAIL_BLOG_POST:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                blog_posts: action.payload,

            };
        case DELETE_BLOG_POST:
            return {
                ...state,
                blog_posts: state.blog_posts.filter(blog_post => blog_post.id !== action.payload)
            };

        case NOT_FOUND_BLOG_POST:
            return {
                ...state,
                blog_posts: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

//*****************************END BLOG MANAGEMENT REDUCER******************************** */



//*****************************START BLOG CATEGORY REDUCER******************************** */

export const BlogCategoryReducer = (
    state =
        {
            blog_categories: [],
            blog_category: {},
            count: null,
            next: null,
            is_added: false,
            is_loading: false,
            is_getting: false,
            is_updated: false,
        }
    , action) => {
    switch (action.type) {
        case LOADING_BLOG_CATEGORY:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_BLOG_CATEGORY:
            return {
                ...state,
                blog_categories: action.payload,
                count: action.count,
                next: action.next,
                is_getting: true,
                is_loading: false
            };
        case DETAIL_BLOG_CATEGORY:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                blog_categories: action.payload,

            };
        case DELETE_BLOG_CATEGORY:
            return {
                ...state,
                blog_categories: state.blog_categories.filter(blog_category => blog_category.id !== action.payload)
            };

        case NOT_FOUND_BLOG_CATEGORY:
            return {
                ...state,
                blog_categories: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

//*****************************END BLOG CATEGORY REDUCER******************************** */



//*****************************START BLOG TAG REDUCER******************************** */

export const BlogTagReducer = (
    state =
        {
            blog_tags: [],
            blog_tag: {},
            count: null,
            next: null,
            is_added: false,
            is_loading: false,
            is_getting: false,
            is_updated: false,
        }
    , action) => {
    switch (action.type) {
        case LOADING_BLOG_TAG:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_BLOG_TAG:
            // alert('text' + JSON.stringify(action.payload.blog_tags))

            return {
                ...state,
                blog_tags: action.payload,
                count: action.count,
                next: action.next,
                // is_getting: true,
                is_loading: false
            };
        case DETAIL_BLOG_TAG:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_loading: true,
                blog_tags: action.payload,

            };
        case DELETE_BLOG_TAG:
            return {
                ...state,
                blog_tags: state.blog_tags.filter(blog_tag => blog_tag.id !== action.payload)
            };

        case NOT_FOUND_BLOG_TAG:
            return {
                ...state,
                blog_tags: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            // alert(JSON.stringify(state))
            return state;
    }
}

//*****************************END BLOG TAG REDUCER******************************** */

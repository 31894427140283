import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { required } from '../../../constants/Validate'
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { updateData } from '../../../actions/CouponActions';

import {agentCustomerDetail} from "../../../actions/AgentActions"

import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import { AGENT_CUSTOMER_URL } from '../../../constants/api';


export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}


momentLocalizer(moment);
// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
  <div>
    <label className="text-sm">{label}</label>
    <DateTimePicker
      onChange={onChange}
      format="DD-MM-YYYY"
    //   time={showTime}
      time={false}
      value={!value ? null : new Date(value)}
      placeholder={label}
    />
  </div>;



// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AgentCustomerUpdate extends Component {

    state={
        payment_type:"1",
        verified: false,
        details:false,
        agent_customers:[],
        agent_id:'',
        get_agent_customer:false,

    }

    // this.props.getRides(0)
    componentDidMount() {
        
        let id = this.props.match.params.id
        this.props.agentCustomerDetail(id)
        
       
    }

    allowVerfied = () => {
        this.setState({ verified: !this.state.verified });
      };
      static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_getting) {
            if (nextProps.is_getting !== prevState.get_agent) {
                var agent_customers =nextProps.agent_customers
                return {
                    
                   
                    agent_customers:agent_customers,
                    get_agent_customer: true
                }
            }
        }
        return null
    }

    submit(event) {
        const {cuisines_values,foodNameDetails,food_names}=this.state
        var form_data={
            customer_name:event.customer_name,
            customer_phone_no:event.customer_phone_no,
            address:event.address,
            is_active:true
        }


            
             

            


           
            let id = this.props.match.params.id;       
            this.props.updateData(form_data, AGENT_CUSTOMER_URL+ id+'/', "Agent Customer")

    }
    onChangePaymentType = (e) => {

        this.setState({[e.target.name]:e.target.value})
        // alert(e.target.value)
    
    }
    render() {

        const {allowProductSize,foodNameDetails,food_names,cuisines_values} = this.state
        const { handleSubmit, submitting,attributes,cuisines,foodnames } = this.props;
       
        if (this.props.is_updated) {
            setTimeout(function () {
                window.location.href = '/agent-customer-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark"> Agent Customer update</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active"> Agent Customer update</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Agent Customer Information</h3>
                        <div className="float-right">
                            <Link to="/agent-customer-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <div className="card-body">
                            <div className="row">
                                
                              
                            <div className="col-md-6">
                                    <Field
                                        name="customer_name"
                                        type="text"
                                        component={renderField}
                                        label="Customer Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="customer_phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Customer Phone No"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="address"
                                        type="text"
                                        component={renderTextArea}
                                        label="Address"
                                       validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                               
                                
      
                            </div>
                            

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AgentCustomerUpdate.propTypes = {
    // getRides:PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    is_added: state.products.is_added,
    is_updated:state.products.is_updated,
    agent_customers:state.agent_customers.agent_customer,
    initialValues: state.agent_customers.agent_customer,
    is_getting: state.agent_customers.is_getting,


});


AgentCustomerUpdate = reduxForm({
    form: 'AgentCustomerUpdate',
    enableReinitialize: true

}
)(AgentCustomerUpdate);


export default connect(mapStateToProps, { updateData,agentCustomerDetail})(AgentCustomerUpdate);

import {
    LOADING_ATTRIBUTE,
    GETTING_ATTRIBUTE,
    DETAIL_ATTRIBUTE,
    UPDATE_ATTRIBUTE,
    DELETE_ATTRIBUTE,
    NOT_FOUND_ATTRIBUTE,
    ADD_ATTRIBUTE
} from '../actions/types';

const initialState = {
    attributes: [],
    attribute: {},
    count: null,
    is_getting_attribute: false,
    next: null,
    is_loading: false,
    errors: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_ATTRIBUTE:
            return {
                ...state,
                is_getting_attribute: false,
                is_loading: true
            };
        case GETTING_ATTRIBUTE:
            return {
                ...state,
                attributes: action.payload,
                count: action.count,
                next: action.next,
                is_getting_attribute: true,
                is_loading: false
            };
        case DETAIL_ATTRIBUTE:
            return {
                ...state,
                is_getting_attribute: false,
                attribute: action.payload,

            };
        case UPDATE_ATTRIBUTE:

            var filteredDataSource = state.attributes.filter((item) => {
                if (item.id === action.payload.id) {
                    item.name = action.payload.name;
                }

                return item;
            });
            console.log(filteredDataSource)
            return {
                ...state,
                is_getting_attribute: false,
                attributes: filteredDataSource,
                is_loading: false
            };
        case ADD_ATTRIBUTE:
            // let data=[]
            //  if(state.attributes.length>9){
            //     data = [...state.attributes.splice(-9)] 

            //  }else{
            //     data = [...state.attributes]   
            //  }   

            return {
                ...state,
                is_getting_attribute: false,
                attributes: state.attributes,
                is_loading: false
            };
        case DELETE_ATTRIBUTE:
            return {
                ...state,
                is_getting_attribute: false,
                attributes: state.attributes.filter(attribute => attribute.id !== action.payload)
            };

        case NOT_FOUND_ATTRIBUTE:
            return {
                ...state,
                attributes: [],
                count: 0,
                next: 0,
                is_getting_attribute: false,
                is_loading: false,

            };
        default:
            return state;
    }
}
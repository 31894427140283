import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import ReactPaginate from 'react-paginate';

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

import { fetchSupportMessages, deleteSupportTicket } from '../../../actions/SupportTicketActions';
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class SupportMessagesList extends Component {
    constructor() {
        super();
        // this.opensweetalert = this.opensweetalert.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        ticket_id: "",
        user_phone_no: "",
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
    };

    handleStatusValues = i => e => {
        // alert(e.target.value)
        let support_tickets = [...this.props.support_tickets]
        // alert(support_tickets[i]['id'])
        // colorDetails[i][e.target.name] = e.target.value
        // console.log(colorDetails[i])
        // this.setState({
        //   colorDetails
        // })


    }

    componentDidMount() {
        this.props.fetchSupportMessages(1);
    }

    // opensweetalert(data) {

    //     Swal.fire({
    //         text: 'Are you want to delete ' + data.user.first_name + ' ?',
    //         // text: "You won't be able to revert this!",
    //         type: 'warning',
    //         showCancelButton: true,
    //         confirmButtonColor: '#3085d6',
    //         cancelButtonColor: '#d33',
    //         confirmButtonText: 'Yes, delete it!'
    //     }).then((result) => {
    //         if (result.value) {
    //             if (data.id) {
    //                 this.props.deleteSupportTicket(data.id)
    //                 //alert(JSON.stringify(result))
    //                 Swal.fire(
    //                     'Deleted!',
    //                     'Your Data has been deleted ' + data.user.first_name + '.',
    //                     'success'
    //                 )
    //             }
    //         }
    //     })
    // }


    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        this.props.fetchSupportMessages('', this.state);
        this.setState({ hideButton: true })

    };
    handlePageClick = (data)=>{
        var pageNo= Number(data.selected)+1
        this.props.fetchSupportMessages(pageNo)
         
    }
    render() {
     
        const {support_messages,is_loading,count,permissions} =this.props

        const  page = Math.ceil(count / 10)

        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Support Messages List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Support Messages list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Support Messages List</h3>

                                <div className="float-right">
                                    {/* <Link to="/add-subscriber" className="btn btn-info text-white"><b><i className="fa fa-upload"></i>
                                        Bulk Import</b></Link>&nbsp;
                                    <Link to="/add-subscriber" className="btn btn-warning text-white"><b><i className="fa fa-download"></i>
                                        Download CSV</b></Link>&nbsp; */}
                                    {/* {CheckPermisionAction(permissions,'Customer','add_support_ticket')?

                                    <Link to="/add-support-ticket" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Support Messages</b></Link>
                                    :null} */}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="order_id" type="text"
                                            onChange={this.onChange} placeholder="Order No"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-4" name="email" type="text"
                                        onChange={this.onChange} placeholder=" Email"
                                        aria-label="Search" />
                                    {/* {CheckPermisionAction(permissions,'Customer','support_ticket_search')?

                                     :null} */}
                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {/* {CheckPermisionAction(permissions,'Customer','view_support_ticket_list')? */}

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th> Name </th>
                                            <th>Phone No</th>
                                            <th> Email</th>
                                            <th> Order No</th>
                                            <th> Description</th>
                                            <th> Priority</th>
                                            
                                            {/* <th>Option</th> */}
                                            {/* <th>Action</th> */}




                                            {/* {this.state.hideButton ?
                                                <th><Link to="/support-ticket-list" onClick={() => { window.location.href = "/support-ticket-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null} */}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> : support_messages.length !==undefined && support_messages.map((ticket, index) => (
                                            <tr key={ticket.id}>
                                                <td>{index + 1}</td>
                                                <td>{ticket.name}</td>
                                                <td>{ticket.phone_no}</td>
                                                <td>{ticket.email} </td>
                                                <td>{ticket.order_id}</td>
                                                <td>{ticket.description}</td>
                                                <td>{ticket.priorities}</td>
                                                
                                               
                                              


                                               

                                               

                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>
                        {/* :null} */}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                            { page>1 ? (
                                <ul className="pagination pagination-sm m-0 float-right">
                                <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                />
                                </ul>
                                ):null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

SupportMessagesList.propTypes = {
    fetchSupportMessages: PropTypes.func.isRequired,
    // deleteSupportTicket: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    support_messages: state.support_messages.support_messages,
    count: state.support_messages.count,
    next: state.support_messages.next,
    is_loading: state.support_messages.is_loading,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchSupportMessages })(SupportMessagesList);

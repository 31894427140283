import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";
import { required, phoneNumber,match } from '../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { updateData } from '../../actions/ProductAction';
import {WarehouseDetail} from "../../actions/WarehouseAction"
import { GET_WAREHOUSE_LIST } from "../../constants/api";




momentLocalizer(moment);


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class WarehouseUpdate extends Component {

    state={
        payment_type:"1",
        verified: false,
        food_names:[],
        cuisines_values:[],
        details:'',
     
    }

    // this.props.getRides(0)
    componentDidMount() {
        
        let id = this.props.match.params.id
        this.props.WarehouseDetail(id)
       
    }

    allowVerfied = () => {
        this.setState({ verified: !this.state.verified });
      };

    submit(data) {
        const {cuisines_values,foodNameDetails,food_names}=this.state
            var form_data = new FormData();
            Object.keys(data).forEach(key => form_data.append(key, data[key]));
            let id = this.props.match.params.id;       
            this.props.updateData(form_data, GET_WAREHOUSE_LIST+ id+'/', "Warehouse")

    }

    render() {

        const {allowProductSize,foodNameDetails,food_names,cuisines_values} = this.state
        const { handleSubmit, submitting,attributes,cuisines,foodnames } = this.props;
       
        if (this.props.is_updated) {
            setTimeout(function () {
                window.location.href = '/warehouse-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark"> Warehouse update</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active"> Warehouse update</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Warehouse Information</h3>
                        <div className="float-right">
                            <Link to="/warehouse-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <div className="card-body">
                            <div className="row">
                            
                               
                                <div className="col-md-6">
                                    <Field
                                        name="warehouse_name"
                                        type="text"
                                        component={renderField}
                                        label="Warehouse Name"
                                       validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                               
                                <div className="col-md-6">
                                    <Field
                                        name="address"
                                        type="text"
                                        component={renderField}
                                        label="Address"
                                       validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                
                                
                                

                                
                               
      
                            </div>
                            

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


WarehouseUpdate.propTypes = {
   
    updateData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    is_added: state.products.is_added,
    is_updated:state.products.is_updated,
    warehouses:state.warehouses.warehouse,
    initialValues: state.warehouses.warehouse,
    is_getting_warehouse: state.warehouses.is_getting_warehouse,


});


WarehouseUpdate = reduxForm({
    form: 'WarehouseUpdate',
    enableReinitialize: true

}
)(WarehouseUpdate);


export default connect(mapStateToProps, { updateData,WarehouseDetail})(WarehouseUpdate);

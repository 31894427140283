import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchShippingClass, addData, deleteShippingClass } from "../../../actions/ShippingClassActions"
import { fetchGroups } from "../../../actions/GroupAction"
import {fetchShippingZones} from '../../../actions/ShippingZoneActions'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import { toast } from "react-toastify";
import { SHIPPING_CLASS_URL, GET_PRIMARY_CATEGORY_URL } from "../../../constants/api";




// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
    <div>
        <label className="text-sm">{label}</label>
        <DateTimePicker
            onChange={onChange}
            format="DD-MM-YYYY"
            time={showTime}
            value={!value ? null : new Date(value)}
            placeholder={label}
        />
    </div>;


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddShippingClass extends Component {

    componentDidMount() {
     
        this.props.fetchShippingZones(0)

    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }

    submit(event) {
    // alert(JSON.stringify(event))
        this.props.addData(event, SHIPPING_CLASS_URL, "Shipping Class")




    }

    render() {


        const { handleSubmit, submitting, } = this.props;
        if (this.props.is_added) {
            setTimeout(() => {
                window.location.href = "/shipping-class-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Shipping Class </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Shipping Class add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Shipping Class Information</h3>
                        <div className="float-right">
                            <Link to="/shipping-class-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="Shipping Class Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Shipping Zone</label>
                                    <Field
                                        name="shipping_zone"
                                        component="select"
                                        className="form-control"

                                    // onChange={this.handleSelectedValues(idx)}
                                    >
                                        <option value="">--Select Shipping Zone--</option>

                                        {this.props.shipping_zones.map((shipping_zone, index) => (
                                        <option key={index} value={shipping_zone.id}>{shipping_zone.zone_name}</option>

                                        ))}
                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="rate_amount"
                                        type="text"
                                        component={renderField}
                                        label="Shipping Rate"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Status</label>
                                    <Field
                                        name="status"
                                        component="select"
                                        className="form-control" 
                                    >
                                        <option value="">--Select Status--</option>
                                        <option value="1">Active</option>
                                        <option value="0">Inactive</option>
                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="min_weight"
                                        type="text"
                                        component={renderField}
                                        label="Product Minimum Weight"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="max_weight"
                                        type="text"
                                        component={renderField}
                                        label="Product Maximum Weight"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddShippingClass.propTypes = {
    // getRides:PropTypes.func.isRequired,

    fetchShippingClass: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

    shipping_zones: state.shipping_zones.shipping_zones,
    shipping_classes: state.shipping_classes.shipping_classes,
    is_added: state.shipping_classes.is_added

});


AddShippingClass = reduxForm({
    form: 'AddShippingClass',
}
)(AddShippingClass);


export default connect(mapStateToProps, { fetchShippingZones,fetchShippingClass, addData })(AddShippingClass);

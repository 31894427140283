import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { addData } from '../../../actions/CustomerActions';
import RichTextEditor from 'react-rte';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import { fetchFooterLeftSection } from '../../../actions/SlideSettingAction';

import { toast } from "react-toastify";
import { FOOTER_LEFT_SECTION_URL } from "../../../constants/api";


momentLocalizer(moment);



const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class FooterLeftSection extends Component {
    state = {

        id: 0,
        email: '',
        details: false,
        description: RichTextEditor.createEmptyValue(),
        // footer_left_sections: []

    }
    static propTypes = {
        onChange: PropTypes.func
    };
    componentDidMount() {
        this.props.fetchFooterLeftSection(0);

    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_getting === true) {
            if (nextProps.is_getting !== prevState.details) {

                return {
                    id:nextProps.footer_left_sections[0]? nextProps.footer_left_sections[0].id:'',
                    description: RichTextEditor.createValueFromString(nextProps.footer_left_sections[0]? nextProps.footer_left_sections[0].description :'', 'html'),
                    email: nextProps.footer_left_sections[0] ?  nextProps.footer_left_sections[0].email : "",
                    details: true
                }

            }
        }
    }
    onChangeDetails = (description) => {
        this.setState({ description });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChangeDetails(
                description.toString('html'),
                // email.toString('html'),

            );
        }
    };

    onChangeText = (e) => {

        this.setState({ [e.target.name]: e.target.value })

    }
    componentDidMount() {
        this.props.fetchFooterLeftSection(1)
    }


    submit(event) {
        console.log(event)
        // if (this.state.copyright == 'undefined') {
        //     this.setState({ copyright: event.target.value })

        // }
        let data = {
            'description': this.state.description.toString('html') ? this.state.description.toString('html') : '',
            'email': this.state.email


        }
        //alert(COPYRIGHT_URL+this.state.id+'/')

        // alert(JSON.stringify(event.data))
        this.props.addData(data, FOOTER_LEFT_SECTION_URL , "Footer Left Section")



    }

    render() {
        // alert(JSON.stringify(this.state.footer_left_sections))
        // alert(JSON.stringify(this.state.footer_left_sections.email))
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' }
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' }
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' }
            ]
        };

        const { handleSubmit, submitting,permissions } = this.props;

        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Footer Left Section Settings </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Footer Left Section Settings</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                {CheckPermisionAction(permissions,'Appearance','view_footer_left_section_setting_list')?

                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Footer Left Section Settings</h3>
                        {/* <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div> */}
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                {/* <div className="col-md-6">
                                    <Field
                                        name="description"
                                        // value={this.state.footer_left_sections.description}
                                        type="text"
                                        component={renderTextArea}
                                        label="Copyright"
                                    // validate={purchasePrice}

                                    // required={true}
                                    />
                                </div> */}
                                {/* <p>

                                    {this.props.footer_left_sections.map(item => (<><p>{item.email}</p><p>{item.description}</p></>))}
                                </p> */}
                                <div className="col-md-12">

                                    <label>Description</label>
                                    <RichTextEditor
                                        // toolbarConfig={toolbarConfig}
                                        value={this.state.description}
                                        onChange={this.onChangeDetails}
                                    />
                                </div>








                            </div>
                        </div>


                        <div className=" col-md-12" >
                            <label>Email : </label>
                            <input
                                className='from-control'
                                name="email"
                                type="email"
                                value={this.state.email}
                                // component={renderTextArea}
                                label="Email"
                                onChange={this.onChangeText}
                            // validate={purchasePrice}

                            // required={true}
                            />
                        </div>



                        <div className="card-footer text-right">
                            <div>
                            {CheckPermisionAction(permissions,'Appearance','footer_left_section_setting_save')?

                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save Settings
                                </button>
                            :null}

                            </div>
                        </div>
                    </form>
                </div>
            :null}
            </div >

        )
    }
}


FooterLeftSection.propTypes = {
    addData: PropTypes.func.isRequired,
    fetchFooterLeftSection: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    footer_left_sections: state.footer_left_sections.footer_left_sections,
    is_updated: state.footer_left_sections.is_updated,
    is_getting: state.footer_left_sections.is_getting,
    permissions:state.userpermissions.userpermissions
    // initialValues: state.footer_left_sections.footer_left_sections,

});


FooterLeftSection = reduxForm({
    form: 'FooterLeftSection',
    // enableReinitialize: true

}
)(FooterLeftSection);


export default connect(mapStateToProps, { fetchFooterLeftSection, addData })(FooterLeftSection);

import React, { Component } from 'react'
// import { Field, reduxForm } from 'redux-form';
import ReactDOM from 'react-dom';

// import axios from 'axios';
import { Link } from "react-router-dom";
// import { toast } from "react-toastify";
// import { BASE_URL } from "../../constants/api";
class PrivilesList extends Component {

    constructor(props) {
        super(props);
        this.state = {
          allChecked: false,
          list: [
            { id: 1, name: "add", isChecked: false },
            { id: 2, name: "view", isChecked: true },
            { id: 3, name: "update", isChecked: false },
            { id: 4, name: "add", isChecked: false },
            { id: 5, name: "view", isChecked: true },
            { id: 6, name: "update", isChecked: false }
          ]
        };
      }

      handleChange(e) {
        // const element = e.target.value;
        //alert(element.checked)
       
       var classlength= ReactDOM.findDOMNode(this.myRef.current).getElementsByClassName(e.target.name).length

        alert(classlength);
    }

      renderList = () => {
        return this.state.list.map(item => (
          <div>
            <input
              key={item.id}
              type="checkbox"
              name={item.name}
              value={item.name}
              checked={item.isChecked}
              onChange={this.handleChange}
            />
            <label>{item.name}</label>
          </div>
        ));
      };

    render() {
     
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">User Privileges</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a className='text-info' href="/">Dashboard</a></li>
                                    <li className="breadcrumb-item active">User Privileges</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">
                        <div className="icheck-primary d-inline">
                                    <input type="checkbox" id="checkall" name="checkAll"
                                     defaultChecked={this.state.allChecked}
                                     readOnly
                                  
                                    />
                                    <label htmlFor="checkall">
                                    User Privileges
                                    </label>
                                </div>
                            
                            
                            </h3>
                        <div className="float-right">
                       
                            <Link to="/" className="btn btn-info float-right text-white">
                           
                                <b>
                                <i className="fas fa-arrow-left"></i> Back to Home</b>
                            </Link>
                        </div>
                    </div>

                <div className="form-group clearfix">    

                    <div className='row'>
                        <div className="col-md-4">
                            <div className="icheck-primary d-inline">
                                <input type="checkbox"  name='staff' id="staff1"  onChange={this.handleChange}/>
                                <label htmlFor="staff1">
                                    Staff
                                </label>
                            </div>
                        </div>   
                        <div className="col-md-8">
                            <div className="col-md-4">
                                <div className="icheck-primary d-inline">
                                    <input type="checkbox" id="staffview" className='staff allcheck'/>
                                    <label htmlFor="staffview">
                                        View
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icheck-primary d-inline">
                                    <input type="checkbox" id="staffadd"  className='staff allcheck'/>
                                    <label htmlFor="staffadd">
                                        Add
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="col-md-4">
                            <div className="icheck-primary d-inline">
                                <input type="checkbox" id="staff2" />
                                <label htmlFor="staff2">
                                    Regional Director
                                </label>
                            </div>
                        </div>   
                        <div className="col-md-8">
                            <div className="col-md-4">
                                <div className="icheck-primary d-inline">
                                    <input type="checkbox" id="rdview"  className='rdadd allcheck'/>
                                    <label htmlFor="rdview">
                                        View
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="icheck-primary d-inline">
                                    <input type="checkbox" id="rdadd"  className='rdadd allcheck' />
                                    <label htmlFor="rdadd">
                                        Add
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
               </div>


                </div>
            </div>

        )
    }
}





export default PrivilesList;

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { updateData } from '../../../actions/ProductAction';
import { getAgents,searchAgent } from '../../../actions/AgentActions';
import Autosuggest from 'react-autosuggest';
import { sellerDetail } from '../../../actions/SellerActions';

import { toast } from "react-toastify";
import { SELLER_URL } from "../../../constants/api";


momentLocalizer(moment);



const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class SellerUpdate extends Component {
    state={
        is_show:false,
        shopImageFile: null,
        payment_type:"",        
        verified: false,
        sellers:[],
        agents:[],
        agent_id:'',
        agent_suggesion:'',
        value:''

    }
    allowVerfied = () => {
        
        this.setState({ verified: !this.state.verified });
    };

    static getDerivedStateFromProps(nextProps, prevState) {
// alert(JSON.stringify(nextProps.initialValues))
        if (nextProps.is_details === true) {
          if (nextProps.is_details !== prevState.is_show) {

                return{
                    verified:nextProps.initialValues.verified,
                    payment_type:nextProps.sellers.payment_type,
                    // agent_id:nextProps.agents.agent_id,
                    value:nextProps.initialValues.agent,
                    is_show:true
                }

          }
        }     
        return null 
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.sellerDetail(id)
        console.log(this.state.payment_type)
        console.log(this.state.agent_id)
        this.props.searchAgent()

    }


    submit(event) {
        const {shopImageFile,payment_type} = this.state
        console.log(event)
        // Object.keys(event).forEach(key => form_data.append(key, event[key]));

        var form_data = new FormData();
        let id = this.props.match.params.id;
        form_data.append("first_name", event.user.first_name ? event.user.first_name : '');
        form_data.append("last_name", event.user.last_name ? event.user.last_name : '');
        form_data.append("email", event.user.email ? event.user.email : '');
        form_data.append("phone_no", event.user.phone_no ? event.user.phone_no : '');
        form_data.append("shop_name", event.shop_name ? event.shop_name : '');
        if (event.user_photo != '') {
            form_data.append("user_photo", event.user_photo ? event.user_photo : '');
        }
        form_data.append("tag_name", event.tag_name ? event.tag_name : '');
        // if (shopImageFile != null || event.shop_image !=null) {
        //     form_data.append("shop_image", event.shop_image ? event.shop_image : '');
        // }
        if (event.shop_logos != undefined || event.shop_logos !=null) {
            // let newImage = new File([shopImageFile], shopImageFile.name, { type: shopImageFile.type });
            form_data.append("shop_logo", event.shop_logos, event.name);
          }
        if (event.shop_images != undefined || event.shop_images !=null) {
            // let newImage = new File([shopImageFile], shopImageFile.name, { type: shopImageFile.type });
            form_data.append("shop_image", event.shop_images, event.name);
          }
        
        form_data.append('agent',this.state.agent_suggesion)
        form_data.append("shop_trade_license", event.shop_trade_license ? event.shop_trade_license : '');
        form_data.append("shop_details", event.shop_details ? event.shop_details : '');
        form_data.append("shop_address", event.shop_address ? event.shop_address : '');
        form_data.append("commision", event.commision ? event.commision : 0);
        if (this.state.verified){
            form_data.append('verified',this.state.verified)
        }
        if (this.props.sellers.payment_type === 1) {
            form_data.append('payment_type',this.state.payment_type)
            form_data.append('account_name',event.account_name);
            form_data.append('account_number',event.account_number);
            form_data.append('bank_name',event.bank_name);
            form_data.append('branch_name',event.branch_name);
            form_data.append('routing_number',event.routing_number);


        } else{
            form_data.append('payment_type',this.state.payment_type)
            form_data.append('mobile_banking_type',event.mobile_banking_type);
            form_data.append('wallet_number',event.wallet_number);
        }


        this.props.updateData(form_data, SELLER_URL + id + '/', "Seller")

    }
    
    onChangePaymentType = (e) => {

        this.setState({[e.target.name]:e.target.value})
        // alert(e.target.value)
    
    }
    onChangeAgent = (event, { newValue }) => {
        // alert(JSON.stringify(newValue))
        this.setState({
          agent_id: newValue
        });
        // alert(this.state.customer_name)
      };
    getAgentSuggestions = agent_id => {
        if (agent_id !== undefined) {
          const inputValue = agent_id.trim().toLowerCase();
          const inputLength = inputValue.length;
          this.props.searchAgent(agent_id);
          return inputLength === 0 ? [] : this.props.agents.filter(agent =>
            agent.agent_id.toLowerCase().slice(0, inputLength) === inputValue
          );
        }
      };
    
      onSuggestionsAgentClearRequested = () => {
        this.setState({
          agents: []
        });
      };
      getSuggestionAgentValue = agent => agent.agent_id;
    
      onSuggestionsFetchRequestedAgent = ({ value }) => {
    
        this.setState({
          agents: this.getAgentSuggestions(value)
        });
      };
    
      onSuggestionSelectedAgent = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
//    alert(suggestion.id)
        setTimeout(()=>{
                this.setState({ agent_suggesion: suggestion.id })
            // alert(this.state.agent_suggesion)
        },1000)
        
      };
      renderSuggestionAgent = agent => (
        // alert(JSON.stringify(customer))
        <div>
          {agent.agent_id}
        </div>
      );
    render() {

        const { handleSubmit, submitting } = this.props;
        const {verified,payment_type,agent_id} = this.state
        if (this.props.is_updated) {
            setTimeout(function () {
                window.location.href = '/seller-list'
            },
                3000);
        }
        const inputAgentProps = {
            placeholder: 'Type Your Agent ID',
            value: agent_id,
            type: "search",
            name: 'textBoxValue',
            onChange: this.onChangeAgent
          };
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Seller </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Update Seller</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Update Seller Information</h3>
                        <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="user.first_name"
                                        type="text"
                                        component={renderField}
                                        label="First Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="user.last_name"
                                        type="text"
                                        component={renderField}
                                        label="Last Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="user.phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Mobile No"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="user.email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Select Agent</label>
                                <span className="requiredField">*</span>
                                <Autosuggest
                                    suggestions={this.props.agents ? this.props.agents : []}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedAgent}
                                    onSuggestionsClearRequested={this.onSuggestionsAgentClearRequested}
                                    getSuggestionValue={this.getSuggestionAgentValue}
                                    renderSuggestion={this.renderSuggestionAgent}
                                    inputProps={inputAgentProps}

                                    onSuggestionSelected={this.onSuggestionSelectedAgent}
                                    validate={required}
                                    required
                                />

                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="shop_name"
                                        type="text"
                                        component={renderField}
                                        label="Shop Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="shop_trade_license"
                                        type="text"
                                        component={renderField}
                                        label="Shop Trade License No"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                
                                <div className="col-md-6">
                                    <Field
                                        name="shop_address"
                                        type="text"
                                        component={renderTextArea}
                                        label="Shop Address"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="shop_details"
                                        type="text"
                                        component={renderTextArea}
                                        label="Shop Details"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Shop Logo</label>
                                    <Field
                                        name="shop_logos"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Upload Shop Logo"
                                    // validate={[required]}
                                    />

                                </div>
                                {this.props.sellers.shop_logo != null && this.props.sellers.shop_logo != '' ?
                                    <img src={this.props.sellers.shop_logo} height="100" width="100px" /> :
                                    null
                                }
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Shop Image</label>
                                    <Field
                                        name="shop_images"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Upload Shop Image"
                                    // validate={[required]}
                                    />

                                </div>
                                {this.props.sellers.shop_image != null && this.props.sellers.shop_image != '' ?
                                    <img src={this.props.sellers.shop_image} height="100" width="100px" /> :
                                    null
                                }
                                <div className="col-md-6">
                                    <Field
                                        name="commision"
                                        type="number"
                                        component={renderField}
                                        label="Commision"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                            <div className="col-md-12 form-group clearfix" >
                                
                                <div className="col-md-6 icheck-primary d-inline">
                                    <input type="radio" id="radioPrimary1" onChange={this.onChangePaymentType} value="1" name="payment_type" checked={this.props.sellers.payment_type===1?true:false}/>
                                    <label for="radioPrimary1">Bank Account 
                                    </label>
                                </div>
                               
                                <div className="col-md-6 icheck-primary d-inline">
                                    <input type="radio" id="radioPrimary2" value="2" onChange={this.onChangePaymentType} name="payment_type" checked={this.props.sellers.payment_type===2?true:false}/>
                                    <label for="radioPrimary2">Mobile Wallet
                                    </label>
                                </div>
                                
                                
                                </div>
                                {this.props.sellers.payment_type===1 ? (
                               <>     
                                <div className="col-md-6">
                                    <Field
                                        name="account_name"
                                        type="text"
                                        component={renderField}
                                        label="Account Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="account_number"
                                        type="text"
                                        component={renderField}
                                        label="Account Number"
                                     //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="bank_name"
                                        type="text"
                                        component={renderField}
                                        label="Bank Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="branch_name"
                                        type="text"
                                        component={renderField}
                                        label="Branch Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="routing_number"
                                        type="number"
                                        component={renderField}
                                        label="Routing Number"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                </>
                                ):
                                <>
                                <div className="col-md-6">
                                    <label>Mobile Banking</label>
                                    <Field name="mobile_banking_type" component="select" className="form-control"
                                    >
                                    <option value="">Select Mobile Banking</option>
                                    <option value="1">Bkash</option>
                                    <option value="2">Nogod</option>
                                    <option value="3">Rocket</option>

                                </Field>
                                </div>
                                  <div className="col-md-6">
                                    <Field
                                        name="wallet_number"
                                        type="text"
                                        component={renderField}
                                        label="Number"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                    </div>
                                 </>   
                                }
                            
                            </div>

                            <div className="form-check">
                                <input
                               // checked={this.props.sellers.verifed ? true : false}
                                type="checkbox"
                                className="form-check-input"
                                id="allow-product-color"
                                checked={verified ? true : false}
                                value={verified}
                                onChange={this.allowVerfied}
                                />
                            <label className="form-check-label" htmlFor="allow-product-size">
                            <b>Verified
                            </b>
                            </label>
                        </div>           

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Update
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


SellerUpdate.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchSellers: PropTypes.func.isRequired,
    searchAgent: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    sellers: state.sellers.sellers,
    is_updated: state.products.is_updated,
    initialValues: state.sellers.sellers,
    is_details:state.sellers.is_details,
    agents:state.agents.agents

});


SellerUpdate = reduxForm({
    form: 'SellerUpdate',
    enableReinitialize: true

}
)(SellerUpdate);


export default connect(mapStateToProps, { sellerDetail, updateData,getAgents,searchAgent })(SellerUpdate);

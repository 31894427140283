import {
    LOADING_SLIDE_SETTING,
    FETCH_SLIDE_SETTING,
    DETAIL_SLIDE_SETTING,
    DELETE_SLIDE_SETTING,
    UPDATE_SLIDE_SETTING,
    NOT_FOUND_SLIDE_SETTING,
    LOADING_SLIDE_RIGHT_SIDE_SETTING,
    FETCH_SLIDE_RIGHT_SIDE_SETTING,
    DETAIL_SLIDE_RIGHT_SIDE_SETTING,
    DELETE_SLIDE_RIGHT_SIDE_SETTING,
    UPDATE_SLIDE_RIGHT_SIDE_SETTING,
    NOT_FOUND_SLIDE_RIGHT_SIDE_SETTING,
    LOADING_BANNER_SETTING,
    FETCH_BANNER_SETTING,
    DETAIL_BANNER_SETTING,
    DELETE_BANNER_SETTING,
    UPDATE_BANNER_SETTING,
    NOT_FOUND_BANNER_SETTING,
    LOADING_SOCIAL_MEDIA,
    FETCH_SOCIAL_MEDIA,
    NOT_FOUND_SOCIAL_MEDIA,
    DELETE_SOCIAL_MEDIA,
    LOADING_FOOTER_LEFT_SECTION,
    FETCH_FOOTER_LEFT_SECTION,
    NOT_FOUND_FOOTER_LEFT_SECTION,
    DELETE_FOOTER_LEFT_SECTION,
    DETAIL_SOCIAL_MEDIA,

} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { SLIDE_SETTING_URL, SLIDE_RIGHT_SIDE_SETTING_URL, BANNER_SETTING_URL, SOCIAL_MEDIA_URL, FOOTER_LEFT_SECTION_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


//***************************** START SLIDE SETTING********************************** */


export const fetchSlideSetting = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SLIDE_SETTING });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["title"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = SLIDE_SETTING_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_SLIDE_SETTING,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SLIDE_SETTING });

    }
};

// Slide Setting Detail
export const slideSettingDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = SLIDE_SETTING_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_SLIDE_SETTING,
                payload: response.data,
            })
        })
};

// Delete Slide Setting
export const deleteSlideSetting = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(SLIDE_SETTING_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_SLIDE_SETTING,
                payload: id,
            });
            toast.success('Slide Setting has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//***************************** END SLIDE SETTING********************************** */



//***************************** START SLIDE RIGHT SIDE SETTING********************************** */

// Fetch Slide Right Side Setting

export const fetchSlideRightSideSetting = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SLIDE_RIGHT_SIDE_SETTING });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["title"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = SLIDE_RIGHT_SIDE_SETTING_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_SLIDE_RIGHT_SIDE_SETTING,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SLIDE_RIGHT_SIDE_SETTING });

    }
};


// Slide Setting Detail
export const slideRightSettingDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = SLIDE_RIGHT_SIDE_SETTING_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_SLIDE_RIGHT_SIDE_SETTING,
                payload: response.data,
            })
        })
};


// Delete Slide Right Side Setting
export const deleteSlideRightSideSetting = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(SLIDE_RIGHT_SIDE_SETTING_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_SLIDE_RIGHT_SIDE_SETTING,
                payload: id,
            });
            toast.success('Slide Right Side Setting has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//***************************** END SLIDE RIGHT SIDE SETTING********************************** */



//***************************** START BANNER SETTING********************************** */

//Banner Setting Image
export const fetchBannerSetting = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_BANNER_SETTING });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["title"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = BANNER_SETTING_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_BANNER_SETTING,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_BANNER_SETTING });

    }
};


// Banner Setting Detail
export const bannerSettingDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = BANNER_SETTING_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_BANNER_SETTING,
                payload: response.data,
            })
        })
};


// Delete Banner Setting
export const deleteBannerSetting = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(BANNER_SETTING_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_BANNER_SETTING,
                payload: id,
            });
            toast.success('Banner Setting has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//***************************** END BANNER SETTING********************************** */



//***************************** START SOCIAL MEDIA SETTING********************************** */

//Social Media Setting 
export const fetchSocialMedia = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SOCIAL_MEDIA });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["title"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = SOCIAL_MEDIA_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_SOCIAL_MEDIA,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SOCIAL_MEDIA });

    }
};


// Social Meida Detail
export const socialMediaDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = SOCIAL_MEDIA_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_SOCIAL_MEDIA,
                payload: response.data,
            })
        })
};




// Delete Social Media Setting
export const deleteSocialMedia = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(SOCIAL_MEDIA_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_SOCIAL_MEDIA,
                payload: id,
            });
            toast.success('Social Media  has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//***************************** END SOCIAL MEDIA SETTING********************************** */


//***************************** START FOOTER LEFT SECTION SETTING********************************** */

//Footer Left Section Setting 
export const fetchFooterLeftSection = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_FOOTER_LEFT_SECTION });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["title"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = FOOTER_LEFT_SECTION_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_FOOTER_LEFT_SECTION,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_FOOTER_LEFT_SECTION });

    }
};


// Delete Footer Left Section Setting
export const deleteFooterLeftSection = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(FOOTER_LEFT_SECTION_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_FOOTER_LEFT_SECTION,
                payload: id,
            });
            toast.success('Footer left section  has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//***************************** END FOOTER LEFT SECTION SETTING********************************** */
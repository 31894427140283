import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match,dateTime } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { campaignDetail,fetchCampaignGroup, updateData } from "../../../actions/CampaignActions"
import Autosuggest from 'react-autosuggest';
import { searchProducts } from '../../../actions/ProductAction';
import Select from "react-select";
import {fetchSellersForSelect,searchSellersForSelect } from "../../../actions/SellerActions"

import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import { toast } from "react-toastify";
import { CAMPAIGN_URL,BASE_URL, GET_PRIMARY_CATEGORY_URL,PRODUCT_URL,CAMPAIGN_PRODUCT_URL } from "../../../constants/api";




// const submitToServer = (data) => {

//     console.log('data', data);


//     var form_data = { "name": data.name };

//     console.log('data', form_data);


//     let url = CUSTOMER_URL;

//     let token = localStorage.getItem('token');
//     // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
//     console.log(token);
//     axios.post(url, form_data,
//         {
//             headers: {
//                 'content-type': 'application/json',
//                 'Authorization': `Token ${token}`
//             }

//         })
//         .then(res => {
//             console.log(res.data);
//             // dispatch(reset('PartnerAddForm'))
//             toast.success('Successfully added a new Primary Category.')
//         })
//         .catch(err => console.log(err))
// };

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};

// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
  <div>
    <label className="text-sm">{label}</label>
    <DateTimePicker
      onChange={onChange}
      format="DD-MM-YYYY hh:mm A"
      time={showTime}
      value={!value ? null : new Date(value)}
      placeholder={label}
    />
  </div>;


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class CampaignUpdate extends Component {
    onSelectFile = this.onSelectFile.bind(this);

    state={
            product:'',

            value:'',
            sellerProductDetails: [
              {
                index: Math.random(),
                selected_seller: '',
                product_list: [],
                searched_product:null,
                discount_type:'',
                discount_price:0,
               // attribute_options: [],
              },
            ],
            primary: null,
            secondary: null,
            child: null,
            // product_list:[],
            selected_seller:'',
            selected_product:'',
            // product_list:[],
            banner:'',
            feature_photo:'',
            get_product:false

    }
    componentDidMount() {
        // console.log(this.props.campaignDetail(id))
        let id = this.props.match.params.id
        this.props.campaignDetail(id)
        this.props.fetchCampaignGroup()
        // this.props.fetchSellersForSelect(id)

    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.campaigns && nextProps.initialValues.seller_products !==undefined) {
          // alert(JSON.stringify(nextProps.initialValues.seller_products[0].seller.shop_name))
            if (nextProps.is_getting !== prevState.get_product) {
            //  nextProps.campaigns.products,
             var sellerDataProducts = []
             for (var i = 0; i < nextProps.initialValues.seller_products.length; i++) {
               var seller =nextProps.initialValues.seller_products[i].seller
               var product = []
               for (var j = 0; j < nextProps.initialValues.seller_products[i].products.length; j++) {


              // alert(JSON.stringify(nextProps.initialValues.seller_products[i].products[j]))
                 let id = nextProps.initialValues.seller_products[i].products[j].id
                 let product_name = nextProps.initialValues.seller_products[i].products[j].product_name
                 product.push({'value':id,'label':product_name,'data':nextProps.initialValues.seller_products[i].products[j]})
               }
               var discount_type =nextProps.initialValues.seller_products[i].discount_type
               var discount_price =nextProps.initialValues.seller_products[i].discount_price

               
              //  var attributeoptions = []
              //  selected_seller: '',
              //  product_list: [],
              //  searched_product:null,
              //  discount_type:'',
              //  discount_price:0,
              //  for (var j = 0; j < attroption.length; j++) {
              //    attributeoptions.push({ label: attroption[j].name, value: attroption[j].id })
              //  }
              sellerDataProducts.push({ 'selected_seller': {id:seller.id,label:seller.shop_name},'searched_product':product,'product_list': [], 'discount_type': discount_type,'discount_price':discount_price })
             }
              // alert(JSON.stringify(sellerDataProducts))
   
                return {
                    sellerProductDetails:sellerDataProducts,
                    product_list: nextProps.campaigns.products,
                    // sellerProductDetails:nextProps.seller_products !==undefined? nextProps.seller_products.seller.shop_name :'',
                    // selected_seller:nextProps.initialValues.seller_products.seller? nextProps.initialValues.seller_products.seller.shop_name:'',
                    // selected_product:nextProps.initialValues.seller_products.products? nextProps.initialValues.seller_products.products.product_name:'',

                    get_product: true,

                }
            }
        }
        return null
    }
    onSelectFile(event) {
        this.setState({
            [event.target.name]:event.target.files[0]          
        })
      }
   
    onSuggestionsClearRequested = () => {
        this.setState({
          products: []
        });
      };   
      // Trigger suggestions
  getSuggestionValue = product => '';

  // Filter logic
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    this.props.searchProducts(value);
    return inputLength === 0 ? [] : this.props.products.filter(product =>
      product.product_name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };
  // Render Each Option
  renderSuggestion = product => (
    <div>
      {product.product_name}
    </div>
  );

// Suggestion rerender when user types
onSuggestionsFetchRequested = ({ value }) => {
    //alert(value)
    this.setState({
      products: this.getSuggestions(value)
    });
  };
  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

    let { product_list } = this.state;
    suggestion.quantity = 1
    suggestion.subtotal = Number(suggestion.regular_price) 
    var index = product_list.findIndex(x => x.id == suggestion.id);

    // console.log(suggestion);
    if (index === -1) {
        product_list.push(suggestion)
    } 
  };
   // Filter logic
  

   deteteRow = (product) => {
    var products = this.state.product_list.filter((r) => r !== product)
    this.setState({
      product_list: products,
    });
  }

  onChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };

  addSellerRow = (e) => {
    this.setState((prevState) => ({
      sellerProductDetails: [
        ...prevState.sellerProductDetails,
        {
          index: Math.random(),
          selected_seller: [],
          product_list: [],
          searched_product:null,
          discount_type:'',
          discount_price:0,
        },
      ],
    }));
  };

  deteteSellerProductRow(record) {
    this.setState({
      sellerProductDetails: this.state.sellerProductDetails.filter((r) => r !== record),
    });
  }
  onChangeType = index => (e) => {
    var sellerProductDetails = [...this.state.sellerProductDetails]
    sellerProductDetails[index][e.target.name]=e.target.value
    this.setState(sellerProductDetails);
  }  
  getSellersSuggestion  = (value) => { 
   this.props.searchSellersForSelect(value);
  };
  getSeller = index =>  (selected_seller) => {   
   var sellerProductDetails = [...this.state.sellerProductDetails]
   sellerProductDetails[index]["selected_seller"] = selected_seller;
   var url = CAMPAIGN_PRODUCT_URL + '?vendor__shop_name=' + selected_seller.label
   let headers = { "Content-Type": "application/json" };
   let  token  = localStorage.getItem('token')
   if (token) {
       headers["Authorization"] = `Token ${token}`;
   }
  
   axios.get(url, { headers, })
       .then(response => {
           var results = []
           for (var i = 0; i < response.data.length; i++) {
               results.push({ label: response.data[i].product_name, value: response.data[i].id,data: response.data[i] })
           }
           sellerProductDetails[index]["searched_product"] = results;
           this.setState({ sellerProductDetails });
       })  
  }; 

  getSuggestions = value => {
   
   // this.props.searchSelectProducts(value);
  };
  getProduct = index => (selected_product) => {   
    var sellerProductDetails = [...this.state.sellerProductDetails]
    var checkProduct =  sellerProductDetails[index]["product_list"].findIndex(x => x.id == selected_product.value);
    if (checkProduct === -1) {
      sellerProductDetails[index]["product_list"].push(selected_product.data);
      this.setState({ sellerProductDetails });
    }else{
      toast.warning("Product already exists in this list")
    }
   
   
  }; 

   // Filter logic
  

  removeProdcutRow = (index,product) => {
   // event.preventDefault();
    var sellerProductDetails = [...this.state.sellerProductDetails]
    var products = sellerProductDetails[index]["product_list"].filter((r) => r !== product)
    sellerProductDetails[index]["product_list"]=products
    this.setState({
      sellerProductDetails
    });

  }

  onChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };

    submit(event) {
        // const { reset } = this.props;
        // return sleep(1000)
        //     .then(() => {
        //         submitToServer(values);
        //         reset()
        //     })
        const {
            product_list,
            feature_photo,
            banner,
            sellerProductDetails
          } = this.state;
       
        var form_data = new FormData();
        // Object.keys(event).forEach(key => form_data.append(key, event[key]));
        form_data.append("campaign_title", event.campaign_title);
        form_data.append('campaign_group',event.campaign_group);
        form_data.append("to_date", dateTime(event.to_date));
        form_data.append("from_date", dateTime(event.from_date));
        if (feature_photo !=='' && feature_photo !==null && feature_photo !==undefined){
            form_data.append("feature_photo", feature_photo !== '' ? feature_photo : '');

        }
        if (banner !=='' && banner !==null && banner !==undefined){
        form_data.append("banner", banner !== '' ? banner : '');
        }
        // form_data.append("to_date", dateTime(event.to_date));
        // form_data.append("to_date", dateTime(event.to_date));


        // var products=[]
        // for (var i=0;i<product_list.length;i++){
        //     products.push(product_list[i].id)
        // } 
        // form_data.append("products", products);    
        var seller_product = []
        for (var i = 0; i < sellerProductDetails.length; i++) {
          let product_list=[]
         
           for(var j = 0; j < sellerProductDetails[i].product_list.length;j++){
             product_list.push(sellerProductDetails[i].product_list[j].id)
           } 
          let sellers_obj={
            'seller_id':sellerProductDetails[i].selected_seller ? sellerProductDetails[i].selected_seller.value:'',
            'discount_type':sellerProductDetails[i].discount_type!=='' ? sellerProductDetails[i].discount_type:1,
            'discount_price':sellerProductDetails[i].discount_price!=='' ? sellerProductDetails[i].discount_price:0,
            'product_ids':[]
          }
         
          sellers_obj['product_ids']=product_list
          seller_product.push(sellers_obj)
        }  

        form_data.append("seller_products", JSON.stringify(seller_product));    
        let id = this.props.match.params.id;
        this.props.updateData(form_data, CAMPAIGN_URL + id + '/', "Campaign")




    }

    render() {
        
        const { handleSubmit, submitting, sellers} = this.props;
        const { value,product_list,sellerProductDetails,selected_seller,selected_product } = this.state;

        const inputProps = {
            placeholder: 'Type Product Name or Product Sku Here',
            value,
            type: "search",
            onChange: this.onChange
        };

        if (this.props.is_updated) {
            setTimeout(() => {
                window.location.href = "/campaign-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Campaign </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Campaign Update</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Campaign Information</h3>
                        <div className="float-right">
                            <Link to="/campaign-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="campaign_title"
                                        type="text"
                                        component={renderField}
                                        label="Campaign Title"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <Field
                                        name="campaign_description"
                                        type="text"
                                        component={renderField}
                                        label="Campaign Desciption"
                                        // validate={required}
                                        // required={true}
                                    // warn={alphaNumeric}
                                    />
                                </div> 
                             

                                <div className="col-md-6">
                                    <Field
                                        name="from_date"
                                       
                                        type="date"
                                        showTime={true}
                                        component={renderDateTimePicker}
                                        label=" From Date"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="to_date"
                                      
                                        type="date"
                                        showTime={true}
                                        component={renderDateTimePicker}
                                        label="To Date"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Campaign Banner</label> <br />
                                    <input
                                        name="banner"
                                        type="file"
                                        accept="image/*"
                                        className="form-control"
                                        onChange={this.onSelectFile}
                                        // accept="image/*"
                                        label="Campaign banner"
                                        // validate={[required]}
                                    />
                                { this.props.campaigns.banner ? (
                                    <img
                                    src={this.props.campaigns.banner}
                                    height="40px"
                                    width="40px"
                                    alt="No Image"
                                    />
                                    ) : null
                                }       
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Campaign Featured Photo</label><br/>
                                <input
                                        name="feature_photo"
                                        type="file"
                                        accept="image/*"
                                        className="form-control"
                                        onChange={this.onSelectFile}
                                        // accept="image/*"
                                        label="Campaign banner"
                                        // validate={[required]}
                                    />
                                   
                                { this.props.campaigns.feature_photo ? (
                                <img
                                src={this.props.campaigns.feature_photo}
                                height="40px"
                                width="40px"
                                alt="No Image"
                                />
                            ) : null
                            }
                            
                                </div>
                                <div className="col-md-6">
                                    <label>Campaign Group</label>
                                    <Field
                                        name="campaign_group"
                                        component="select"
                                        className="form-control"

                                        label="Campaign Group"
                                        validate={required}
                                        required={true}

                                    >
                                        <option value=""  >--Select Campaign Group--</option>
                                        {this.props.campaign_groups.map((camgroup, index) => (
                                            <option key={index} value={camgroup.id}>{camgroup.name}</option>
                                        ))}

                                    </Field>
                                </div>
                               
                                {/* <div className="col-md-6">
                                <label>Type Your Product</label>
                                <span className="requiredField">*</span>
                                <Autosuggest
                                    suggestions={this.props.products ? this.props.products : []}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    inputProps={inputProps}
                                    onSuggestionSelected={this.onSuggestionSelected}
                                />
                                </div> */}
                               
                              {sellerProductDetails.map((sellerdata, idx) => (
                                <>
                                <div className="col-md-3">
                                 
                                  <label>Select Seller</label>
                               
                                  <Select
                                    value={sellerdata.selected_seller}
                                   
                                    onInputChange={this.getSellersSuggestion}
                                    required={true}
                                    onChange={this.getSeller(idx)}
                                   
                                    options={sellers}
                                    placeholder="Select Seller"
                                    // isClearable
                                  />

                              </div>
                                <div className="col-md-3">
                                  <label>Type Your Product</label>
                               
                                  <Select
                                   value={sellerdata.selected_product}
                                   // onInputChange={this.getSuggestions}
                                    required={true}
                                    onChange={this.getProduct(idx)}
                                    options={sellerdata.searched_product}
                                    placeholder="Type Your Product"
                                    // isClearable
                                  />

                              </div>
                              <div className="col-md-2">
                                <label>Discount Type</label>
                                {/* <span className="requiredField">*</span> */}
                                <select className="form-control" onChange={this.onChangeType(idx)} name="discount_type" component="select" validate={required} className="form-control"
                                >
                                  <option value="" selected={sellerdata.discount_type=='' ? true:false}>Select Discount Type</option>
                                  <option value="1" selected={sellerdata.discount_type=='1' ? true:false}>Percentage (%)</option>
                                  <option value="2" selected={sellerdata.discount_type=='2' ? true:false}>Flat Discount (BDT)</option>
                                 

                                </select>
                              </div>
                              <div className="col-md-2">
                              <label>Discount Amount</label>
                                <input name="discount_price" onChange={this.onChangeType(idx)}    validate={required}  className="form-control"
                                  label="Discount Amount" 
                                  value={sellerdata.discount_price}
                                  placeholder="Discount Amount"
                                  className="form-control"/>
                              </div>
                              {/* <div className="col-md-6">
                                    <Field
                                        name="discount_price"
                                        onChange={this.onChangeType(idx)}
                                        type="text"
                                        showTime={true}
                                        component={renderField}
                                        label="Discount Amount"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                              <div className="col-md-2">
                              
                             
                              {idx === 0 ? (
                              <button
                                    onClick={() => this.addSellerRow()}
                                    type="button"
                                    className="btn btn-primary text-center"
                                  >
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    />
                                  </button>
                                   ) : (
                                    <button
                                    className="btn btn-danger"
                                     onClick={() => this.deteteSellerProductRow(sellerdata)}
                                  >
                                    <i
                                      className="fa fa-minus"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                              </div>  
                
                {sellerdata.product_list.length > 0 ? (
                  <>
                    <div className="col-md-12">
                      <strong>Product List</strong>
                    </div>
                    <div className="col-md-12">
                      <table className="table orderlist">
                        <tr>
                        <th>SL</th> 
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Product Sku</th>
                          {/* <th>Vendor/Seller</th> */}
                          <th>Product Type</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                        <tbody>
                          {sellerdata.product_list.map((product, index) => (

                            <tr id={product.id} key={index}>
                                <td>
                               {index+1}
                              </td>
                              <td>
                                <img src={BASE_URL+ product.feature_image} height="100px" width="100px" alt="No Image" />
                              </td>
                              <td>
                                {product.product_name}
                              </td>
                              <td>
                                {product.product_sku}
                              </td>
                              {/* <td>
                                {product.vendor ? product.vendor.shop_name : ''}
                              </td> */}
                              <td>{product.product_type == 1 ? 'Physical' : 'Virtual'}</td>

                              <td>
                                {product.regular_price}
                              </td>
                             
                             
                              <td>
                                <button
                                  className="btn btn-danger" id={product.id}
                                  onClick={() => this.removeProdcutRow(idx,product)}
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  />
                                </button>

                              </td>
                            </tr>
                          ))}
                         
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null
                }
                          
                              </>
                              ))}




                            </div>

                {/* <div className="row">
                            {product_list.length > 0 ? (
                  <>
                    <div className="col-md-12">
                      <strong>Product List</strong>
                    </div>
                    <div className="col-md-12">
                      <table className="table orderlist">
                        <tr>
                        <th>SL</th> 
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Product Sku</th>
                          <th>Vendor/Seller</th>
                          <th>Product Type</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                        <tbody>
                          {this.state.product_list.map((product, index) => (

                            <tr id={product.id} key={index}>
                                <td>
                               {index+1}
                              </td>
                              <td>
                                <img src={product.feature_image} height="100px" width="100px" alt="No Image" />
                              </td>
                              <td>
                                {product.product_name}
                              </td>
                              <td>
                                {product.product_sku}
                              </td>
                              <td>
                                {product.vendor ? product.vendor.shop_name : ''}
                              </td>
                              <td>{product.product_type == 1 ? 'Physical' : 'Virtual'}</td>

                              <td>
                                {product.regular_price}
                              </td>
                             
                             
                              <td>
                                <button
                                  className="btn btn-danger" id={product.id}
                                  onClick={() => this.deteteRow(product)}
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  />
                                </button>

                              </td>
                            </tr>
                          ))}
                         
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null
                }
                            </div> */}

                           



                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Update
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


CampaignUpdate.propTypes = {
    // getRides:PropTypes.func.isRequired,
    searchCustomer: PropTypes.func.isRequired,

    campaignDetail: PropTypes.func.isRequired,
    searchSellersForSelect: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({

    campaigns: state.campaigns.campaigns,
    campaign_groups:state.campaign_groups.campaign_groups,
    is_getting: state.campaigns.is_getting,
    products: state.products.products,
    sellers:state.sellers.sellers,
    is_updated: state.campaigns.is_updated,
    primarycategory: state.primarycategory.primary_categories,
    childcategory: state.childcategory.child_categories,
    secondarycategory: state.secondarycategory.secondary_categories,

    initialValues: state.campaigns.campaigns

});


CampaignUpdate = reduxForm({
    form: 'CampaignUpdate',
    enableReinitialize: true
}
)(CampaignUpdate);


export default connect(mapStateToProps, { updateData, searchProducts,campaignDetail,searchSellersForSelect,fetchCampaignGroup })(CampaignUpdate);

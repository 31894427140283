import {
    LOADING_USER,
    FETCH_USER,
    DETAIL_USER,
    DELETE_USER,
    ADD_DATA,
    UPDATE_USER,
    UPDATED_DATA,
    NOT_FOUND_USER,

} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { USER_CREATE_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


export const fetchUsers = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_USER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["phone_no", "email", "group"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = USER_CREATE_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_USER,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_USER });

    }
};

// User Detail
export const userDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = USER_CREATE_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_USER,
                payload: response.data,
            })
        })
};

// // Delete Customer
export const deleteUser = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(USER_CREATE_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_USER,
                payload: id,
            });
            toast.success('User has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

export const addData = (data, url, message = "Data") => (dispatch, getState) => {
    let headers = { 'content-type': 'application/json' };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios
        .post(url, data, { headers })
        .then(response => {
            dispatch({
                type: ADD_DATA,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been added successfully");
        })
        .catch(error => {
            console.log(error)
            if (error.response) {
                toast.warn(error.response.data.error)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                toast.warn("Something Wrong ! Please try again");

            } else {
                toast.warn("Something Wrong ! Please try again");
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }

        })
};

export const updateData = (data, url, message = "") => (dispatch, getState) => {

    let headers = { 'content-type': 'application/json' };
    let { token } = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .put(url, data, { headers })
        .then(response => {
            // alert(JSON.stringify(response))

            dispatch({
                type: UPDATE_USER,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been updated successfully");
        }).catch(error => {
            console.log(error)
            toast.warn(message + " has not been updated successfully");
        })
};



import {
    LOADING_SHIPPING_ZONE,
    FETCH_SHIPPING_ZONE,
    ADD_DATA,
    DETAIL_SHIPPING_ZONE,
    UPDATE_SHIPPING_ZONE,
    DELETE_SHIPPING_ZONE,
    NOT_FOUND_SHIPPING_ZONE,
    UPDATED_DATA,


} from '../actions/types';

const initialState = {
    shipping_zones: [],
    shipping_zone: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_SHIPPING_ZONE:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_SHIPPING_ZONE:
            return {
                ...state,
                shipping_zones: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case UPDATE_SHIPPING_ZONE:
            // alert(JSON.stringify(action.payload))
            return {

                ...state,
                shipping_zones: action.payload,
                is_loading: true
            };
        case DETAIL_SHIPPING_ZONE:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                shipping_zones: action.payload,

            };
        case DELETE_SHIPPING_ZONE:
            return {
                ...state,
                shipping_zones: state.shipping_zones.filter(shipping_zone => shipping_zone.id !== action.payload)
            };
        case ADD_DATA:
            return {
                ...state,
                is_added: true,
                shipping_zones: [action.payload]
            };

        case UPDATED_DATA:
            return {
                ...state,
                is_updated: true,
                // customers: action.payload

            };


        case NOT_FOUND_SHIPPING_ZONE:
            return {
                ...state,
                shipping_zones: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

import {
    LOADING_SUPPORT_TICKET,
    FETCH_SUPPORT_TICKET,
    DETAIL_SUPPORT_TICKET,
    DELETE_SUPPORT_TICKET,
    ADD_DATA,
    UPDATE_SUPPORT_TICKET,
    UPDATED_DATA,
    NOT_FOUND_SUPPORT_TICKET,
    LOADING_SUPPORT_MESSAGES,
    FETCH_SUPPORT_MESSAGES,
    NOT_FOUND_SUPPORT_MESSAGES,
    LOADING_CONTACT_MESSAGES,
    FETCH_CONTACT_MESSAGES,
    NOT_FOUND_CONTACT_MESSAGES

} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { CONTACT_MESSAGES_URL, SUPPORT_MESSAGES_URL, SUPPORT_TICKET_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold, suppressDeprecationWarnings } from 'moment';


export const fetchSupportTickets = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SUPPORT_TICKET });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["ticket_id", "user__phone_no"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = SUPPORT_TICKET_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_SUPPORT_TICKET,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SUPPORT_TICKET });

    }
};

// Support Ticket Detail
export const supportTicketDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = SUPPORT_TICKET_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_SUPPORT_TICKET,
                payload: response.data,
            })
        })
};

// // Delete Support Ticket
export const deleteSupportTicket = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(SUPPORT_TICKET_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_SUPPORT_TICKET,
                payload: id,
            });
            toast.success('Customer has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};



// SUPPORT MESSAGES

export const fetchSupportMessages = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SUPPORT_MESSAGES });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["order_id", "email"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = SUPPORT_MESSAGES_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_SUPPORT_MESSAGES,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SUPPORT_MESSAGES });

    }
};



// CONTACT MESSAGES

export const fetchContactMessages = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_CONTACT_MESSAGES });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["order_id", "email"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = CONTACT_MESSAGES_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_CONTACT_MESSAGES,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_CONTACT_MESSAGES });

    }
};

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, phoneNumber,match } from '../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { updateData } from '../../actions/ProductAction';
import { getDistrictsForSelect, fetchDistrict } from "../../actions/DistrictsActions"
import {fetchCuisine,agentDetail} from "../../actions/AgentActions"
import Select from "react-select";
import { AGENT_URL, CUISINE_URL,CUISINE_ALL_URL, GET_ATTRIBUTE_OPTION_LIST_URL, FOOD_NAME_URL,FOOD_NAME_ALL_URL, BASE_URL } from "../../constants/api";
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import { toast } from "react-toastify";


export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(),mnth,day,].join("-");
}


momentLocalizer(moment);
// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
  <div>
    <label className="text-sm">{label}</label>
    <DateTimePicker
      onChange={onChange}
      format="DD-MM-YYYY"
    //   time={showTime}
      time={false}
      value={!value ? null : new Date(value)}
      placeholder={label}
    />
  </div>;



// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AgentUpdate extends Component {

    state={
        payment_type:"1",
        verified: false,
        details:false,
        agents:[],
        agent_id:'',
        get_agent:false,

    }

    // this.props.getRides(0)
    componentDidMount() {
        
        let id = this.props.match.params.id
        this.props.agentDetail(id)
        
       
    }

    allowVerfied = () => {
        this.setState({ verified: !this.state.verified });
      };
      static getDerivedStateFromProps(nextProps, prevState) {
// alert(JSON.stringify(nextProps.initialValues.user))
        if (nextProps.is_getting) {
            if (nextProps.is_getting !== prevState.get_agent) {
                var agent =nextProps.initialValues
                return {
                    first_name:agent.user !==undefined? agent.user.first_name :'',
                    last_name:agent.user !==undefined? agent.user.last_name :'',
                    gender:agent.user !==undefined? agent.user.gender :'',
                    date_of_birth:agent.user !==undefined? agent.user.date_of_birth :'',
                    phone_no:agent.user !==undefined? agent.user.phone_no :'',
                    email:agent.user !==undefined? agent.user.email :'',
                    user_photo:agent.user !==undefined? agent.user.user_photo :'',
                    agent_id: agent.agent_id,
                    total_balance:agent.total_balance,
                    agents:agent,
                    get_agent: true
                }
            }
        }
        return null
    }

    submit(event) {
        const {cuisines_values,foodNameDetails,food_names}=this.state
            var form_data = new FormData();
            // Object.keys(data).forEach(key => form_data.append(key, data[key]));
            let date_of_birth = convert(event.user.date_of_birth);
            // form_data.append('id',this.props.initialValues.user.id);
            form_data.append('first_name',event.user.first_name);
            form_data.append('last_name',event.user.last_name !==undefined && event.user.last_name !==''? event.user.last_name:'');
            form_data.append('phone_no',event.user.phone_no);
            form_data.append('email',event.user.email !==undefined && event.user.email !==''? event.user.email:'');
            form_data.append('gender',event.user.gender !==undefined && event.user.gender !==''? event.user.gender:1);
            form_data.append('date_of_birth',date_of_birth);
            form_data.append('agent_type',event.agent_type);
            form_data.append('present_address',event.present_address);
            form_data.append('permanent_address',event.permanent_address);
            form_data.append('occupation',event.occupation !==undefined && event.occupation !==''? event.occupation:'');
            form_data.append('business_name',event.business_name !==undefined && event.business_name !==''? event.business_name:'');
            form_data.append('trade_license_no',event.trade_license_no !==undefined && event.trade_license_no !==''? event.trade_license_no:'');
            form_data.append('reference_name',event.reference_name);
            form_data.append('reference_phone_no',event.reference_phone_no);
            form_data.append('commission_type',event.commission_type !==undefined && event.commission_type !==''? event.commission_type:2);
            form_data.append('commission_amount',event.commission_amount !==undefined && event.commission_amount !==''? event.commission_amount:0);
            form_data.append('sales_target_amount',event.sales_target_amount !==undefined && event.sales_target_amount !==''? event.sales_target_amount:0);
            
            form_data.append('number_of_seller',event.number_of_seller);
            form_data.append('remarks',event.remarks !==undefined && event.remarks !==''? event.remarks:'');
            


            
            if (event.user.user_photos !==null && event.user.user_photos !==undefined){
                form_data.append('user_photo',event.user.user_photos,event.user.user_photos.name)
                }
            if (event.nid_or_passport_files !==null && event.nid_or_passport_files !==undefined ){
                form_data.append('nid_or_passport_file',event.nid_or_passport_files,event.nid_or_passport_files.name)
              }
            if (event.trade_license_files !==null && event.trade_license_files !==undefined){
            form_data.append('trade_license_file',event.trade_license_files,event.trade_license_files.name)
            }


            
             

            


            // alert(JSON.stringify(event))
            let id = this.props.match.params.id;       
            this.props.updateData(form_data, AGENT_URL+ id+'/', "Agent")

    }
    onChangePaymentType = (e) => {

        this.setState({[e.target.name]:e.target.value})
        // alert(e.target.value)
    
    }
    render() {

        const {allowProductSize,foodNameDetails,food_names,cuisines_values} = this.state
        const { handleSubmit, submitting,attributes,cuisines,foodnames } = this.props;
       
        // if (this.props.is_updated) {
        //     setTimeout(function () {
        //         window.location.href = '/agent-list'
        //     },
        //         3000);
        // }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark"> Agent update</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active"> Agent update</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Agent Information</h3>
                        <div className="float-right">
                            <Link to="/agent-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                    <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="user.first_name"
                                        type="text"
                                        component={renderField}
                                        label="First Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="user.last_name"
                                        type="text"
                                        component={renderField}
                                        label="Last Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="user.phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Phone No"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="user.email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Gender</label>
                                    {/* <span className="requiredField">*</span> */}
                                    <Field name="user.gender" component="select" className="form-control"
                                    >
                                    <option value="">Select Gender</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>

                                    </Field>
                                </div>
                                
                                <div className="col-md-6">
                                    <Field
                                        name="user.date_of_birth"
                                        type="date"
                                        time={false}
                                        // showTime={false}
                                        component={renderDateTimePicker}
                                        label="Date of Birth"
                                        required={true}
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div><br />
                                <div className="col-md-6">
                                    <img src={this.props.agents.user !==undefined? this.props.agents.user.user_photo:''} style={{width:100,height:100}}/>
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Agent Photo</label>
                                    <Field
                                        name="user.user_photos"
                                        type="file"
                                        // accept="image/*"
                                        component={FileInput}
                                        label="Agent Photo"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Agent Type</label>
                                    <span className="requiredField">*</span>
                                    <Field name="agent_type" component="select" className="form-control"
                                    validate={required}
                                    required={true}
                                    >
                                    <option value="">Select Agent Type</option>
                                    <option value="1">Free Agent</option>
                                    <option value="2">Premium Agent</option>

                                    </Field>
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="agent_id"
                                        type="text"
                                        component={renderField}
                                        label="Agent ID"
                                        readOnly={true}

                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                <label>Agent Id</label>
                                    <input
                                        name="agent_id"
                                        type="text"
                                        className="form-control"
                                        value={this.state.agent_id}
                                        label="Agent ID"
                                        readOnly={true}
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                
                                <div className="col-md-6">
                                    <Field
                                        name="present_address"
                                        type="text"
                                        component={renderField}
                                        label="Present Address"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="permanent_address"
                                        type="text"
                                        component={renderField}
                                        label="Permanent Address"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="occupation"
                                        type="text"
                                        component={renderField}
                                        label="Occupation"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="business_name"
                                        type="text"
                                        component={renderField}
                                        label="Business Name"
                                     //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="reference_name"
                                        type="text"
                                        component={renderField}
                                        label="Reference Name"
                                    // validate={required}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="reference_phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Reference Phone No"
                                        // validate={[required, match("password1")]}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="agent_type"
                                        type="text"
                                        component={renderField}
                                        label="Agent Type"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                {/* <div className="col-md-6">
                                <label>Agent Type</label>
                                <span className="requiredField">*</span>
                                <select name="agent_type" onChange={this.onChanged}  className="form-control"
                                >
                                    <option value="">Select Agent Type</option>
                                    <option value="1">Free Agent</option>
                                    <option value="2">Premium Agent</option>
                                    

                                    </select>
                                </div> */}

                                {/* <div className="col-md-6">
                                    <Field
                                        name="commission_type"
                                        type="text"
                                        component={renderField}
                                        label="Commission Type"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                {/* <div className="col-md-6">
                                <label>Commission Type</label>
                                <span className="requiredField">*</span>
                                <select name="commission_type" onChange={this.onChanged}  className="form-control"
                                >
                                    <option value="">Select Commission Type</option>
                                    <option value="1">Percentage</option>
                                    <option value="2">Flat</option>
                                    

                                    </select>
                                </div> */}
                                <div className="col-md-6">
                                    <label>Commission Type</label>
                                    <span className="requiredField">*</span>
                                    <Field name="commission_type" component="select" className="form-control"
                                    validate={required}
                                    required={true}
                                    >
                                    <option value="">Select Commission Type</option>
                                    <option value="1">Percentage</option>
                                    <option value="2">Flat</option>

                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="commission_amount"
                                        type="text"
                                        component={renderField}
                                        label="Commission Amount"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="sales_target_amount"
                                        type="text"
                                        component={renderField}
                                        label="Sales Target Amount"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="total_balance"
                                        type="text"
                                        component={renderField}
                                        label="Total Balance"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                <label>Total Balance</label>
                                    <input
                                        name="total_balance"
                                        type="text"
                                        className="form-control"
                                        value={this.state.total_balance}
                                        label="Total Balance"
                                        readOnly={true}
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <img src={this.props.agents.nid_or_passport_file} style={{width:100,height:100}}/>
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Upload Nid Or Passport File</label>
                                    <Field
                                        name="nid_or_passport_files"
                                        type="file"
                                        component={FileInput}
                                        label="Nid or Passport File"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <img src={this.props.agents.trade_license_file} style={{width:100,height:100}}/>
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Upload Trade License File</label>
                                    <Field
                                        name="trade_license_files"
                                        type="file"
                                        component={FileInput}
                                        label="Trade License File"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="remarks"
                                        type="text"
                                        component={renderTextArea}
                                        label="Remarks"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <label>Select District</label>
                                    <span className="requiredField">*</span>
                                    <Field name="district" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select District</option>
                                        {this.props.districts.map(shipping_cls => (
                                            <option key={shipping_cls.id} value={shipping_cls.id}>{shipping_cls.name}</option>
                                        ))}
                                    </Field>
                                </div> */}
      
                            </div>
                            

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AgentUpdate.propTypes = {
    // getRides:PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    is_added: state.products.is_added,
    is_updated:state.products.is_updated,
    agents:state.agents.agent,
    initialValues: state.agents.agent,
    is_getting: state.agents.is_getting,


});


AgentUpdate = reduxForm({
    form: 'AgentUpdate',
    enableReinitialize: true

}
)(AgentUpdate);


export default connect(mapStateToProps, { updateData,agentDetail})(AgentUpdate);

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, match } from '../../constants/Validate'
import { connect } from "react-redux";

import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { updateData } from '../../actions/ProductAction';

import { getAgents } from '../../actions/AgentActions';


import { toast } from "react-toastify";
import { SELLER_URL,AGENT_URL } from "../../constants/api";


momentLocalizer(moment);



const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AgentProfileSettings extends Component {
    state={
      
        id:0,
        phone_no:'',
        first_name:'',
        agents:[],
        last_name:'',
        email:'',
        present_address:'',
        reference_name:'',
        reference_phone_no:'',
        get_agent:false,
        total_month_sales:'',
        total_month_comission:''
    }
    static getDerivedStateFromProps(nextProps, prevState) {
// alert(JSON.stringify(nextProps.agents))
        if (nextProps.get_agents) {
            if (nextProps.get_agents !== prevState.get_agent) {
                var agent =nextProps.agents[0] 
                return {
                    first_name:agent !==undefined? agent.user.first_name:'',
                    last_name:agent !==undefined? agent.user.last_name:'',
                    email:agent !==undefined?agent.user.email:'',
                    phone_no:agent !==undefined? agent.user.phone_no:'',
                    id: agent !==undefined && agent !==null ? agent.id:'',
                    present_address:agent !==undefined && agent !==null ? agent.present_address:'',
                    permanent_address:agent !==undefined && agent !==null ? agent.permanent_address:'',
                    occupation:agent !==undefined && agent !==null ? agent.occupation:'',
                    business_name:agent !==undefined && agent !==null ? agent.business_name:'',
                    // trade_license_no:agent.trade_license_no,
                    reference_name:agent !==undefined && agent !==null ? agent.reference_name:'',
                    reference_phone_no:agent !==undefined && agent !==null ? agent.reference_phone_no:'',
                    agent_type:agent !==undefined && agent !==null ? agent.agent_type:'',
                    agent_id:agent !==undefined && agent !==null ? agent.agent_id:'',
                    commission_type:agent !==undefined && agent !==null ? agent.commission_type:'',
                    commission_amount:agent !==undefined && agent !==null ? agent.commission_amount:'',
                    sales_target_amount:agent !==undefined && agent !==null ? agent.sales_target_amount:'',
                    total_balance:agent !==undefined && agent !==null ? agent.total_balance:'',
                    number_of_seller:agent !==undefined && agent !==null ? agent.number_of_seller:'',
                    nid_or_passport_file:agent !==undefined && agent !==null ? agent.nid_or_passport_file:'',
                    trade_license_file:agent !==undefined && agent !==null ? agent.trade_license_file:'',
                    remarks:agent !==undefined && agent !==null ? agent.remarks:'',
                    total_month_sales:agent !==undefined && agent !==null ? agent.comissioin_prices_and_sales.total_month_sales:'',
                    total_month_comission:agent !==undefined && agent !==null ? agent.comissioin_prices_and_sales.total_month_comission:'',



                    agents:agent !==undefined && agent !==null ? agent:'',
                    get_agent: true
                }
            }
        }
        return null
    }
    onChangeText= (e) => {

        this.setState({[e.target.name]:e.target.value})
        console.log(e.target.value)

    }
    componentDidMount() {
        this.props.getAgents(1)
    }


    submit(event) {
        console.log(event)
        var form_data = new FormData();    
            form_data.append('first_name',this.state.first_name)
            form_data.append('last_name',this.state.last_name)
            form_data.append('email',this.state.email)
          
            form_data.append('present_address',this.state.present_address)
            form_data.append('permanent_address',this.state.permanent_address)
            form_data.append('occupation',this.state.occupation)
            form_data.append('business_name',this.state.business_name)
            form_data.append('reference_name',this.state.reference_name)
            form_data.append('reference_phone_no',this.state.reference_phone_no)
            form_data.append('agent_type',this.state.agent_type)
            form_data.append('commission_amount',this.state.commission_amount)
            // form_data.append('mobile_banking_type',this.state.agents.mobile_banking_type)
            // form_data.append('wallet_number',this.state.agents.wallet_number)
            // form_data.append('phone_no',this.state.phone_no)
            // form_data.append('shop_trade_license',this.state.agents.shop_trade_license)
            // form_data.append('shop_address',this.state.agents.shop_address)
            // alert(JSON.stringify(form_data))
             this.props.updateData(form_data, AGENT_URL+this.state.id+"/", "Profile")


            
    }

    render() {

        const { handleSubmit, submitting,agents,auth } = this.props;
       
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Profile Settings </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Profile Settings</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Profile Settings</h3>
                        {/* <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div> */}
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                <label>First Name</label>
                                    <input
                                        name="first_name"
                                        type="text"
                                        className="form-control"
                                        value={this.state.first_name}
                                        label="First Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Last Name</label>

                                    <input
                                        name="last_name"
                                        type="text"
                                        className="form-control"
                                        value={this.state.last_name}
                                        label="Last Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Mobile No</label>
                                    <input
                                        name="phone_no"
                                        type="text"
                                        value={this.state.phone_no}
                                        label="Mobile No"
                                        readOnly={true}
                                        className="form-control"
                                        validate={required}
                                        onChange={this.onChangeText}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Email</label>
                                    <input
                                        name="email"
                                        type="text"
                                        className="form-control"
                                        value={this.state.email}
                                        label="Email"
                                        readOnly={true}
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Present Address</label>
                                    <input
                                        name="present_address"
                                        type="text"
                                        className="form-control"
                                        value={this.state.present_address}
                                        label="present address"
                                        validate={required}
                                        onChange={this.onChangeText}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                                <div className="col-md-6">
                                <label>Permanent Address</label>
                                    <input
                                        name="permanent_address"
                                        type="text"
                                        className="form-control"
                                        value={this.state.permanent_address}
                                        label="First Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Occupation</label>
                                    <input
                                        name="occupation"
                                        type="text"
                                        className="form-control"
                                        value={this.state.occupation}
                                        label="First Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Business Name</label>
                                    <input
                                        name="business_name"
                                        type="text"
                                        className="form-control"
                                        value={this.state.business_name}
                                        label="Business Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                <label>Trande License No</label>
                                    <input
                                        name="trade_license_no"
                                        type="text"
                                        className="form-control"
                                        value={this.state.trade_license_no}
                                        label="First Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                <label>Reference Name</label>
                                    <input
                                        name="reference_name"
                                        type="text"
                                        className="form-control"
                                        value={this.state.reference_name}
                                        label="First Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Reference Phone No</label>
                                    <input
                                        name="reference_phone_no"
                                        type="text"
                                        className="form-control"
                                        value={this.state.reference_phone_no}
                                        label="Reference Phone No"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Agent ID</label>
                                    <input
                                        name="agent_id"
                                        type="text"
                                        className="form-control"
                                        value={this.state.agent_id}
                                        label="First Name"
                                        readOnly={true}
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Commission Amount</label>
                                    <input
                                        name="commission_amount"
                                        type="text"
                                        className="form-control"
                                        value={this.state.commission_amount}
                                        label="First Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Sales Target Amount</label>
                                    <input
                                        name="sales_target_amount"
                                        type="text"
                                        className="form-control"
                                        value={this.state.sales_target_amount}
                                        readOnly={true}
                                        label="First Name"
                                        validate={required}

                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Total Balance</label>
                                    <input
                                        name="total_balance"
                                        type="text"
                                        className="form-control"
                                        value={this.state.total_balance}
                                        label="First Name"
                                        validate={required}
                                        readOnly={true}
                                        onChange={this.onChangeText}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Total Monthly Sales</label>
                                    <input
                                        name="total_month_sales"
                                        type="text"
                                        className="form-control"
                                        value={this.state.total_month_sales}
                                        label="Total Month Sales"
                                        readOnly={true}
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Total Montly Commission</label>
                                    <input
                                        name="total_month_comission"
                                        type="text"
                                        className="form-control"
                                        value={this.state.total_month_comission}
                                        label="Total Month Comission"
                                        readOnly={true}
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                
                                
                                
                                
                             
                             
                                

                            </div>


                        </div>
                        <div className="card-footer text-right">
                            {/* <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save Settings
                                </button>

                            </div> */}
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AgentProfileSettings.propTypes = {
    // getRides:PropTypes.func.isRequired,
    getAgents: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    agents: state.agents.agents,
    is_updated: state.products.is_updated,
    is_getting:state.agents.is_getting,
    get_agents:state.agents.get_agents,
    initialValues: state.agents.agents,
    auth:state.auth

});


AgentProfileSettings = reduxForm({
    form: 'AgentProfileSettings',
    enableReinitialize: true

}
)(AgentProfileSettings);


export default connect(mapStateToProps, { getAgents, updateData })(AgentProfileSettings);

import {
    LOADING_PRODUCT,
    FETCH_PRODUCTS,
    DETAIL_PRODUCT,
    DELETE_PRODUCT,
    ADD_DATA,
    UPDATE_PRODUCT,
    DELETE_GALLERY,
    NOT_FOUND_PRODUCT,
    UPDATED_DATA,
    UPLOAD_CSV,
    BULK_PRODUCT_ACTION,
    UPDATE_PRODUCT_LIST_DATA

} from '../actions/types';

const initialState = {
    products: [],
    product: {},
    count: null,
    next: null,
    bulk: false,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    get_products: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_PRODUCT:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_PRODUCTS:
            return {
                ...state,
                products: action.payload,
                count: action.count,
                get_products: true,
                next: action.next,
                is_loading: false
            };
        case UPDATE_PRODUCT:
            return {
                ...state,
                products: action.payload,
                is_loading: false
            };
        case DETAIL_PRODUCT:
            return {
                ...state,
                is_getting: true,
                product: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_PRODUCT:
            return {
                ...state,
                products: state.products.filter(product => product.id !== action.payload)
            };
        case ADD_DATA:
            return {
                ...state,
                is_added: true,
                products: [action.payload]
            };
        case BULK_PRODUCT_ACTION:
            return {
                ...state,
                bulk: true,
                // products: [action.payload]
            };
        case DELETE_GALLERY:
            return {
                ...state,
                // is_added: true,
                // products: [action.payload]
            };
        case UPDATED_DATA:
            return {
                ...state,
                is_updated: true,
                // products: [action.payload]
            };
        case UPLOAD_CSV:
            return {
                ...state,
                is_uploaded: true,

                // products: [action.payload]
            };
        case UPDATE_PRODUCT_LIST_DATA:
                return {
                    ...state,
                    is_uploaded: true,
    
                    // products: [action.payload]
        };
            
        case NOT_FOUND_PRODUCT:
            return {
                ...state,
                products: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

export const ProductCSVData = [{
    'Feature Image URL': '',
    'Product Name': '',
    'Product SKU': '',
    'Primary Category(Use Comma For Multiple)': '',
    'Secondary Category(Use Comma For Multiple)': '',
    'Child Category(Use Comma For Multiple)': '',
    'Merchent / vendor(Mobile Number or Shop Name)': '',
    'Purchase Price': '',
    'Product Regular Price': '',
    'Discount Type(1=Percentage,2=Flat Discount)': '',
    'Discount Amount': '',
    'Product Discount From': '',
    'Product Discount To': '',

    'Alert Stock Quantity': '',
    'Stock Quantity': '',
    'Product Tags(Use Comma For Multiple)': '',
    'Unit': '',
    'Product Weight(KG)': '',
    'Product Brand': '',
    'Tax(Use Comma For Multiple)': '',
    'Product Summary': '',
    'Product Details': '',
    'Product Video URL': '',
    'Allow Attributes(1=Yes)': '',
    'Attribute(For Multiple Use Comma)': '',
    'Attribute Options(Use Space and Comma)': '',
    'Attribute Variation(URL,SKU,Price,Stock Quantity,Discount Type,Discount Amount)(Use Space and Comma For Multiple)':'',
    'Allow Free Delivery(1=Yes)': '',
    'Allow fast delivery(1=Yes)': '',
    'Set as Feature Product(1=Yes)': '',
    'Allow Product SEO(1=Yes)': '',
    'Allow Whole Sell(1=Yes)': '',
    'Whole Sell Price': '',
    'Minimum Purchase Quantity': '',
    'Allow Up Sells(1=Yes)': '',
    'Upsells Product Name(For Multiple Use Comma)': '',
    'Allow Cross Sells(1=Yes)': '',
    'Cross sells Product Name(For Multiple Use Comma)': '',
    'Meta Tag': '',
    'Meta Description': '',
    'Allow Pre Order': '',
    'Pre Order Shipping Estimate': '',
    'Pre Order Policy': '',
    'Gallery Images URL(For Multiple Use Comma)': ''
}
]

export const PaymentStatus = [
    {
        'id': 1,
        'status': 'Pending'
    },
    {
        'id': 2,
        'status': 'Due'
    },
    {
        'id': 3,
        'status': 'Partial'
    },
    {
        'id': 4,
        'status': 'Paid'
    }
]


export const PaymentMethodType = [
    {
        'id': 1,
        'status': 'Bkash'
    },
    {
        'id': 2,
        'status': 'Nogod'
    },
    {
        'id': 3,
        'status': 'Rocket'
    }
]

export const OrderStatus = [
    {
        'id': 1,
        'status': 'Pending'
    },
    {
        'id': 2,
        'status': 'Confirmed'
    },
    // {
    //     'id': 3,
    //     'status': 'In Warehouse'
    // },
    // {
    //     'id': 4,
    //     'status': 'On the way'
    // },
    {
        'id': 5,
        'status': 'Completed'
    },
    {
        'id': 6,
        'status': 'On Hold'
    },
    {
        'id': 7,
        'status': 'Refunded'
    },
    {
        'id': 8,
        'status': 'Returned'
    },
    {
        'id': 9,
        'status': 'Canceled'
    },
    {
        'id':10,
        'status':'Processing'
    }
]


export const RideStatus = [
    {
        'id': 1,
        'status': 'Pending'
    },
    {
        'id': 2,
        'status': 'Confirmed'
    },
    {
        'id':3,
        'status':'Processing'
    },
    {
        'id': 4,
        'status': 'Canceled'
    },
    
    {
        'id': 5,
        'status': 'Completed'
    },
  
    
]


export const ReasonStatus = [
    {
        'id': 1,
        'status': 'Defective piece'
    },
    {
        'id': 2,
        'status': 'Product is damages'
    },
    {
        'id': 3,
        'status': 'Seal is broken'
    }
]


export const TransactionStatus = [
    {
        'id': 1,
        'status': 'Pending'
    },
    {
        'id': 2,
        'status': ' Completed'
    },
    {
        'id': 3,
        'status': 'Canceled'
    },
    {
        'id':4,
        'status':'Due'
    }
]


export const PackageStatus = [
    {
        'id': 1,
        'status': 'Closed'
    },
    {
        'id': 2,
        'status': 'Open'
    },
    {
        'id': 3,
        'status': 'Damaged'
    }
]

export const ResulotionStatus = [
    {
        'id': 1,
        'status': 'Refund'
    },
    {
        'id': 2,
        'status': 'Returned'
    },
    
]


export const StatusChoices = [
    {
        'id': 0,
        'status': 'Pending'
    },
    {
        'id': 1,
        'status': 'Processing'
    },
    {
        'id': 2,
        'status': 'Completed'
    },
    {
        'id': 3,
        'status': 'Canceled'
    },
]


export const StockStatusType = [
    {
        'id': 1,
        'status': 'Stock In'
    },
    {
        'id': 2,
        'status': 'Stock Out'
    },
    {
        'id': 3,
        'status': 'Stock Updated'
    },
    {
        'id': 4,
        'status': 'Sales Updated'
    },
]
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';

import { Redirect } from "react-router-dom";

import { login } from "../actions/auth";
// import { getUserGroup } from "../actions/Users";

import logo from '../assets/mychoicebd.png';

class Login extends Component {

    state = {
        phone_no: "",
        password: "",
    };

    // componentDidMount() {
    //     this.props.getUserGroup();
    // }

    onSubmit = e => {
        e.preventDefault();
        this.props.login(this.state.phone_no, this.state.password);

    };

    render() {
        const centerScreen = {
            position: "fixed",
            top: "50%",
            left: "50%",
            /* bring your own prefixes */
            transform: "translate(-50%, -50%)",
        };

        if (this.props.isAuthenticated) {
            return <Redirect to="/" />
        }
        // console.log(this.props)
        return (
            <div>
                <div className="reds" style={centerScreen}>
                    <img src={logo} alt="gothi-logo" style={{width: '22rem',border:'.2px solid red', borderRadius:'10px' }} />
                    <div className="card bg-gradient-light" style={{ width: '22rem' }}>
                        <div className="card-header text-center">
                            <h5>Welcome To Shopping</h5>
                        </div>
                        <div className="card-body">
                            <form onSubmit={this.onSubmit}>
                                {this.props.errors.length > 0 && (
                                    <ul>
                                        {this.props.errors.map(error => (
                                            <li key={error.field}>{error.message}</li>
                                        ))}
                                    </ul>
                                )}
                                <div className="form-group">
                                    <label htmlFor="phone_no">Username or Phone No</label>
                                    <input
                                        type="text" id="phone_no" className="form-control form-control-sm"
                                        placeholder="Username or Phone No"
                                        onChange={e => this.setState({ phone_no: e.target.value })} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input

                                        type="password" id="password" className="form-control form-control-sm"
                                        placeholder="Password"
                                        onChange={e => this.setState({ password: e.target.value })} />
                                </div>
                               
                              
                                <button type="submit" className="btn btn-dark btn-block">Login</button>


                                <p>
                                    <Link to="/forgot-password">Forgot password</Link>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

let mapStateToProps = state => {
    let errors = [];
    // groups: state.user_groups.groups
    if (state.auth.errors) {
        errors = Object.keys(state.auth.errors).map(field => {
            return { field, message: state.auth.errors[field] };
        });
    }
    return {
        errors,
        isAuthenticated: state.auth.isAuthenticated
    };
};

// mapStateToProps = state => {
//     groups: state.user_group.groups
// }

const mapDispatchToProps = dispatch => {
    return {
        login: (phone_no, password) => {
            return dispatch(login(phone_no, password));
            // getUserGroup: dispatch(getUserGroup())
        }
    };
};



export default connect(mapStateToProps, mapDispatchToProps)(Login);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
// import Modal from 'react-modal';
import { CSVLink } from "react-csv";
import { Modal, Button } from "react-bootstrap";
import { required } from "../../../constants/Validate";
import RichTextEditor from 'react-rte';

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

import { fetchCustomers, deleteCustomer, updateDatas,sentMail } from '../../../actions/CustomerActions';
import { USER_CREATE_URL,USER_EMAIL_SEND_URL } from '../../../constants/api'
import { phoneNo } from '../../../constants/Validate';

// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');



const headers = [
    {label:"First Name",key:"first_name"},
    // {label:"Last Name",key:"last_name"},
    {label:"Phone No",key:"phone_no"},
    {label:"Email",key:"email"},
    {label:"Address",key:"address"},
    {label:"Points",key:"point"}
    // {label:"Status",key:"status"},
    // {label:"Status",key:"status"}


]  


class CustomerList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
       
        phone_no: "",
        email: "",
        customers:[],
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
        allchecked: false,
        bulk:false,
        get_customer:false,
        showedit:false,
        terms_condition: RichTextEditor.createEmptyValue(),

    };


    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_getting) {
            if (nextProps.is_getting !== prevState.get_customer) {
                var customers = []
               
                for (var i = 0; i < nextProps.customers.length; i++) {
                    Object.assign(nextProps.customers[i], { 'checked': false });
                    customers.push(nextProps.customers[i])
                }
                return {
                    customers: customers,
                    get_customer: true
                }
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.customers != this.props.customers){
            this.setState({customers:this.props.customers})
        }

    }

    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchCustomers(pageNo)
    }

    componentDidMount() {
        this.props.fetchCustomers(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.first_name  + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteCustomer(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.first_name  + '.',
                        'danger'
                    )
                }
            }
        })
    }


    handleStatusValues = i => e => {
        let customers = [...this.props.customers]
        var id = Number(customers[i].id)
        let data = {
            'is_active': e.target.value
        }
        this.props.updateDatas(data, USER_CREATE_URL + id + "/", "Status")

    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    handleEditClose=()=> {
        this.setState({ showedit: false, })
    }
    updatePrimaryCategory=()=> {
        var data = {
            subject: this.state.primary_name,
            message: this.state.terms_condition.toString('html')
        }
        var coustomerList = []

        for (var i = 0; i < this.state.customers.length; i++) {
            if (this.state.customers[i].checked) {
                if(this.state.customers[i].email !== null && this.state.customers[i].email!=='' && this.state.customers[i].email!==undefined){
                    coustomerList.push(this.state.customers[i].email)
                }
            }
        }
        this.props.sentMail( data,USER_EMAIL_SEND_URL,coustomerList,"Email")
       this.setState({ showedit: false })
    }

    onChangeDetails = (terms_condition) => {
        this.setState({ terms_condition });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChangeDetails(
                terms_condition.toString('html')
            );
        }
    };
    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { phone_no,email } = this.state;
        console.log([phone_no,email]);

        this.props.fetchCustomers('', this.state);
        this.setState({ hideButton: true })

    };

    toggleAllChange=(e)=> {
        const { value, checked } = e.target;
        let customers = [...this.props.customers]
        this.setState({showedit:true})
        customers.forEach(customer => customer.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, customers, bulk: true })
        } else {
            this.setState({ allchecked: checked, customers, bulk: false })
        }
    }

    onSingleChange = index => (e) => {
        console.log(index)
        let customers = [...this.state.customers]
        customers[index]["checked"] = e.target.checked
        let recent_length = customers.filter(customer => customer.checked).length;
        this.setState({showedit:true})
        if (customers.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        this.setState({ customers })

    };

    
    render() {
        const page = Math.ceil(this.props.count / 10)
        const {permissions}= this.props
        const {allchecked,showedit} = this.state
        const isAdmin = this.props.auth.user.is_admin;
        if (this.props.is_updated) {
            setTimeout(function () {
                window.location.href = '/customer-list'
            },
                1000);
        }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Customer List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Customer list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />


                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Customer List</h3>
                                <div className="float-right">
                                    {/* <Link to="/add-customer" className="btn btn-info text-white"><b><i className="fa fa-upload"></i>
                                        Bulk Import</b></Link>&nbsp;
                                    <Link to="/add-customer" className="btn btn-warning text-white"><b><i className="fa fa-download"></i>
                                        Download CSV</b></Link>&nbsp; */}
                                {CheckPermisionAction(permissions,'Customer','add_customer')?
                                    <>
                                    <Link to="/add-customer" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Customer</b></Link>&nbsp;
                                    <CSVLink data ={this.props.customers} headers={headers}  className="btn btn-info text-white"><b><i className="fa fa-download"></i>
                                    Export CSV</b></CSVLink>&nbsp;
                                    </>
                                :null}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="phone_no" type="text"
                                            onChange={this.onChange} placeholder="Phone No"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-4" name="email" type="text"
                                            onChange={this.onChange} placeholder="Email"
                                            aria-label="Search" />
                                    {CheckPermisionAction(permissions,'Customer','customer_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    :null}
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'Customer','view_customer_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                        <th>
                                            <input type="checkbox"
                                                checked={allchecked}
                                                onChange={this.toggleAllChange}
                                            />   
                                            </th>
                                            <th>SL</th>
                                            <th>Full Name</th>
                                            <th>Phone No</th>
                                            <th>Email</th>
                                            <th>Address</th>
                                            <th>Points</th>
                                            <th>Cash Back Balance</th>
                                            <th>Status</th>
                                            <th>Option</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/customer-list" onClick={() => { window.location.href = "/customer-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> :this.props.customers.length !=undefined &&  this.props.customers.map((customer, index) => (
                                            <tr key={customer.id}>
                                                <td>
                                                    <input type="checkbox" onChange={this.onSingleChange(index)} checked={customer.checked ? true : false} value={customer.id} />
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{customer.first_name }</td>
                                                <td>{customer.phone_no}</td>
                                                <td>{customer.email}</td>
                                                <td>{customer.address}</td>
                                                <td>{customer.point}</td>
                                                <td>{customer.cash_back_balance}</td>
                                                <td>

                                                    <select key={index}
                                                        name="status"
                                                        component="select"
                                                        className="form-control"

                                                        onChange={this.handleStatusValues(index)}
                                                    >


                                                        <option value="1" selected={customer.is_active == 1 ? true : false} >Activated</option>
                                                        <option value="0" selected={customer.is_active == 0 ? true : false}>Deactivated</option>

                                                    </select>
                                                </td>
                                                <td className="btn-group btn-group-sm">

                                                    <div className="btn-group">
                                                {CheckPermisionAction(permissions,'Customer','customer_options')?

                                                        <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                :null} 
                                                        <div className="dropdown-menu">
                                                            <a href={'/customer-update/' + customer.id} className="dropdown-item">Edit</a>
                                                            <div className="dropdown-divider"></div>

                                                            {/* <a href="#" onClick={() => this.openDeclinealert("Set As Featued", customer)} class="dropdown-item">Set As Featued</a>
                                                            <a href="#" onClick={() => this.openDeclinealert("Approve", customer)} class="dropdown-item">Approve</a>
                                                            <a href="#" onClick={() => this.openDeclinealert("Decline", customer)} class="dropdown-item">Decline</a> */}
                                                            <a href="#" onClick={() => this.opensweetalert(customer)} className="dropdown-item">Delete</a>

                                                        </div>
                                                    </div>




                                                </td>
                                                {/* <td className="btn-group btn-group-sm">


                                                    <Link to={'/customer-update/' + customer.id}>
                                                        <button className="btn btn-info text-white">
                                                            <i className="fas fa-edit text-white" ></i>
                                                        </button>
                                                    </Link>

                                                    <button className="btn btn-danger"
                                                        // onClick={this.props.RegDirectorConfirm.bind(this, rgd.id, rgd.is_active)}
                                                        onClick={() => this.opensweetalert(customer)}>
                                                        <i className="fas fa-trash" />
                                                    </button>

                                                </td> */}
                                                {/* <td>
                                                    <Link to={'/customer-detail/' + customer.id}>
                                                        <button className="btn btn-info">
                                                            <i className="fas fa-eye text-white" >View</i>
                                                        </button>
                                                    </Link>

                                                    <Link to={'/customer-update/' + customer.id}>
                                                        <button className="btn btn-warning text-white">
                                                            <i className="fas fa-edit text-white" >Edit</i>
                                                        </button>
                                                    </Link>
                                                    {isAdmin ?
                                                        <button className="btn btn-danger" onClick={() => this.opensweetalert()}><i className="fas fa-trash" >Delete</i>
                                                        </button> : " "}

                                                </td> */}
                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>




                            :null} 
                            <Modal
                                show={showedit}
                                onHide={this.handleEditClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Send Message</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                   
                                    <label>Subject</label>
                                    <input
                                        name="primary_name"
                                        type="text"
                                        // value={this.state.primary_name}
                                        className="form-control"
                                        placeholder="Subject"
                                        validate={required}
                                        onChange={this.onChange}
                                    />
                                    <label>Message</label>
                                    <RichTextEditor
                                        // toolbarConfig={toolbarConfig}
                                        value={this.state.terms_condition}
                                        onChange={this.onChangeDetails}
                                    />
                                    
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleEditClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.updatePrimaryCategory}>
                                        Send
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

CustomerList.propTypes = {
    fetchCustomers: PropTypes.func.isRequired,
    deleteCustomer: PropTypes.func.isRequired,
    updateDatas: PropTypes.func.isRequired,
    sentMail: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    customers: state.customers.customers,
    count: state.customers.count,
    next: state.customers.next,
    is_loading: state.customers.is_loading,
    is_updated: state.customers.is_updated,
    is_getting:state.customers.is_getting,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchCustomers, deleteCustomer, updateDatas,sentMail })(CustomerList);

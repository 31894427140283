import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { required } from "../../constants/Validate";

import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from 'react-paginate';
import {CheckPermisionAction} from "../../constants/CustomMethod"
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";

import {
  getAttribute,
  deleteAttribute,
  addAttribute,
  updateAttribute
} from "../../actions/AttributeActions";
import { getChildCategory } from '../../actions/ChildCategoryAction'
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
}) => {
  const className = `form-control ${error && touched ? "is-invalid" : ""}`;
  return (
    <div className="form-group">
      <label className="text-sm">{label}</label>
      <div>
        <input
          {...input}
          placeholder={label}
          type={type}
          className={className}
        />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

class AttributesList extends Component {
  constructor() {
    super();
    this.opensweetalert = this.opensweetalert.bind(this);
    this.handleModalShow = this.handleModalShow.bind(this);
    this.saveAttribute = this.saveAttribute.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.attributeEdit = this.attributeEdit.bind(this);
    this.handleEditClose = this.handleEditClose.bind(this)
    this.updateAttribute = this.updateAttribute.bind(this)
  }

  state = {
    // search: " ",
    // phone_no: "",
    // regional:null,
    child_category: "",
    attribute_name: "",
    name: "",
    id: '',
    show: false,
    showedit: false,
    isShowing: false,
    modalIsOpen: false,
    hideButton: false,
  };
  handleModalShow(data) {
    this.setState({ show: true });
  }
  handleClose() {
    this.setState({ show: false });
  }

  handleEditClose() {
    this.setState({ showedit: false, });
  }
  updateAttribute() {
    var data = {
      name: this.state.attribute_name,
      child_category: this.state.child_category
    }
    this.props.updateAttribute(this.state.id, data);
    this.setState({ showedit: false, });
  }
  onChangeedit() {
    this.setState({ showedit: true });
  }
  componentDidMount() {
    // console.log(this.props.getChildCategory())
    this.props.getChildCategory()
    this.props.getAttribute(1);
  }

  opensweetalert(data) {
    Swal.fire({
      text: "Are you want to delete " + data.name + " ?",
      // text: "You won't be able to revert this!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.value) {
        if (data.id) {
          this.props.deleteAttribute(data.id);
          //alert(JSON.stringify(result))
          Swal.fire(
            "Deleted!",
            "Your Data has been deleted " + data.name + ".",
            "success"
          );
        }
      }
    });
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });

  onSubmit = (e) => {
    e.preventDefault();
    const { name } = this.state;
    console.log([name]);

    this.props.getAttribute("", this.state);
    this.setState({ hideButton: true });
  };
  attributeEdit(data) {
    this.setState({ showedit: true, attribute_name: data.name, id: data.id })
  }
  saveAttribute() {
    //alert(this.state.name)
    var data = {
      name: this.state.name,
      // child_category: this.state.childcategory
    };
    this.props.addAttribute(data);
    this.setState({ show: false, name: "" });
    this.props.getAttribute(1);
    // values={name:this.state.attribute_name}
  }
  handlePageClick = (data) => {
    var pageNo = Number(data.selected) + 1
    this.props.getAttribute(pageNo)

  }
  render() {
    const {permissions} = this.props
    const { show, showedit } = this.state;
    const { attributes, is_loading, count } = this.props

    const page = Math.ceil(count / 10)

    // let delete_button;
    // if (isAdmin) {
    //     delete_button = <p>News bulletin</p>
    // }

    return (
      <div>
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Attributes List </h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link className="text-info" to="/">
                      Dashboard
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">Attributes list</li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div className="row">
          <ToastContainer />

          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Attributes List</h3>
                <div className="float-right">
                {CheckPermisionAction(permissions,'Inventory','add_attributes')?

                  <button
                    className="btn btn-primary text-white"
                    onClick={this.handleModalShow}
                  >
                    <b>
                      {" "}
                      <i className="fa fa-plus-circle"></i>
                      Add Attributes
                    </b>
                  </button>
                  :null}
                </div>
              </div>

              <div style={{ margin: "15px" }} className="card-tools">
                <div className="float-left">
                  <form
                    className="form-inline my-2 my-lg-0"
                    onSubmit={this.onSubmit}
                  >
                    <input
                      className="form-control mr-sm-4"
                      name="name"
                      type="text"
                      onChange={this.onChange}
                      placeholder="Attributes Name"
                      aria-label="Search"
                    />
                    {CheckPermisionAction(permissions,'Inventory','attributes_search')?

                    <button
                      onClick={this.onSubmit}
                      className="btn btn-outline-info my-4 my-sm-0 float-right"
                      type="submit"
                    >
                      <i className="fas fa-search"></i> Search
                    </button>
                    :null}
                  </form>
                </div>
              </div>
              {/* <!-- /.card-header --> */}
              {/* <div id="modalRender">

                            </div> */}
            {CheckPermisionAction(permissions,'Inventory','view_attributes_list')?
              
              <div className="card-body table-responsive p-0">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>SL</th>
                      <th>Attributes Name</th>
                      {/* <th>Child Category</th> */}
                      <th>Action</th>

                      {this.state.hideButton ? (
                        <th>
                          <Link
                            to="/attributes-list"
                            onClick={() => {
                              window.location.href = "/attributes-list";
                            }}
                            className="btn btn-info float-right text-white"
                          >
                            {" "}
                            <i className="fa fa-arrow-left"> List </i>
                          </Link>
                        </th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    {is_loading ? (
                      <tr className="text-center">
                        <td>
                          <CircularProgress />
                        </td>
                      </tr>
                    ) : (
                        attributes.length !== undefined && attributes.map((attribute, index) => (
                          <tr key={attribute.id}>
                            <td>{index + 1}</td>
                            <td>{attribute.name}</td>
                            {/* <td>{attribute.child_category.name}</td> */}

                            <td>
                            {CheckPermisionAction(permissions,'Inventory','attributes_edit')?

                              <button className="btn btn-warning text-white" onClick={() => this.attributeEdit(attribute)}>
                                <i
                                  className="fas fa-edit"
                                >
                                </i>
                              </button>
                              :null}
                            {CheckPermisionAction(permissions,'Inventory','attributes_delete')?

                              <button
                                className="btn btn-danger"
                                onClick={() => this.opensweetalert(attribute)}
                              >
                                <i className="fas fa-trash"></i>
                              </button>
                              :null}

                            </td>
                          </tr>
                        ))
                      )}
                  </tbody>
                </table>
              </div>
              :null}
              {/* <!-- /.card-body --> */}
              <div className="card-footer clearfix">
                {page > 1 ? (
                  <ul className="pagination pagination-sm m-0 float-right">
                    <ReactPaginate
                      previousLabel={'previous'}
                      nextLabel={'next'}
                      breakLabel={'...'}
                      breakClassName={'break-me'}
                      pageCount={page}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={5}
                      onPageChange={this.handlePageClick}
                      containerClassName={'pagination'}
                      subContainerClassName={'pages pagination'}
                      activeClassName={'active'}
                    />
                  </ul>
                ) : null
                }
              </div>

              <Modal
                show={show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Add Attribute</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    placeholder="Attributes Name"
                    validate={required}
                    onChange={this.onChange}
                  />
                  {/* <div className="col-6">
                    <label>Child Category</label>
                    <span className="requiredField">*</span>
                    <select name="child_category" multiple={true} type="select-multiple" component="select" onChange={this.onChange} className="form-control"
                      validate={required}>

                      {this.props.childcategory.map(attribute => (
                        <option key={attribute.id} value={attribute.id}>{attribute.name}</option>
                      ))}
                    </select>
                  </div> */}
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.saveAttribute}>
                    Save
                  </Button>
                </Modal.Footer>
              </Modal>

              <Modal
                show={showedit}
                onHide={this.handleEditClose}
                backdrop="static"
                keyboard={false}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Update Attribute</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <input
                    name="id"
                    type="hidden"
                    value={this.state.id}
                    className="form-control"
                    placeholder="Attributes id"
                    validate={required}
                    onChange={this.onChange}
                  />
                  <input
                    name="attribute_name"
                    type="text"
                    value={this.state.attribute_name}
                    className="form-control"
                    placeholder="Attributes Name"
                    validate={required}
                    onChange={this.onChange}
                  />
                  {/* <div className="col-6">
                    <label>Child Category</label>
                    <span className="requiredField">*</span>
                    <select name="child_category" multiple={true} type="select-multiple" component="select" onChange={this.onChange} className="form-control"
                      validate={required}>

                      {this.props.childcategory.map(attribute => (
                        <option key={attribute.id} value={attribute.id}>{attribute.name}</option>
                      ))}
                    </select>
                  </div> */}
                </Modal.Body>

                <Modal.Footer>
                  <Button variant="secondary" onClick={this.handleEditClose}>
                    Close
                  </Button>
                  <Button variant="primary" onClick={this.updateAttribute}>
                    Update
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
            <div></div>
          </div>
        </div>
        {/* <!-- /.row --> */}
      </div>
    );
  }
}

AttributesList.propTypes = {
  getAttribute: PropTypes.func.isRequired,
  deleteAttribute: PropTypes.func.isRequired,
  addAttribute: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  childcategory: state.childcategory.child_categories,
  attributes: state.attributes.attributes,
  count: state.attributes.count,
  next: state.attributes.next,
  is_loading: state.attributes.is_loading,
  auth: state.auth,
  permissions:state.userpermissions.userpermissions
});
AttributesList = reduxForm({
  form: "AddAttributes",
})(AttributesList);

export default connect(mapStateToProps, {
  getChildCategory,
  getAttribute,
  deleteAttribute,
  addAttribute,
  updateAttribute
})(AttributesList);

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, phoneNumber,match } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { updateData } from '../../../actions/ProductAction';
import { getDistrictsForSelect, fetchDistrict } from "../../../actions/DistrictsActions"
import {fetchCuisine,RestaurantDetail} from "../../../actions/RestaurantActions"
import Select from "react-select";
import { RESTAURANT_URL, CUISINE_URL,CUISINE_ALL_URL, GET_ATTRIBUTE_OPTION_LIST_URL, FOOD_NAME_URL,FOOD_NAME_ALL_URL, BASE_URL } from "../../../constants/api";

import { toast } from "react-toastify";




momentLocalizer(moment);


// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class RestaurantUpdate extends Component {

    state={
        payment_type:"1",
        verified: false,
        food_names:[],
        cuisines_values:[],
        details:'',
        foodNameDetails: [
            {
                index: Math.random(),
                selected_option: [],
                cuisine:"",
                selected:[],
                cuisines: [],
                cuisines_values:[],
                foodnames: [],
                // quantity: "",
                // size_price: "",
            },
            ],
    }

    // this.props.getRides(0)
    componentDidMount() {
        
        let id = this.props.match.params.id
        this.props.RestaurantDetail(id)
        this.props.fetchDistrict()
        this.props.fetchCuisine()
       
    }

    allowVerfied = () => {
        this.setState({ verified: !this.state.verified });
      };

    
      handletextChild = index => (e) => {
        // alert("ajh")
         let attribute_list = [...this.state.food_names];
         for (var i =0;i<attribute_list.length;i++){
          
           let attributes = attribute_list[i]
         }
         //let addedItem = child_products[index].findIndex(item=> item[e.target.name])
         let cuisines_values = [...this.state.cuisines_values];
         cuisines_values[index][e.target.name]=e.target.value
         // alert(e.target.name)
         if( e.target.name=='image'){
           // alert("jsdhj")
           cuisines_values[index][e.target.name]=e.target.files[0]
         }
         
         
          this.setState({cuisines_values:cuisines_values})
       
       
       }


       getCombinations(arr, n)
  {
 
      // if(n == 1)
      // {
          var ret = [];
          for(var i = 0; i < arr.length; i++)
          {
           
            if(arr[i]!==null){

                for(var j = 0; j < arr[i].length; j++)
                {
                    ret.push([arr[i][j]]);
                }

            }  
          }
          return ret;
      // }
      // else
      // {
      //     var ret = [];
      //     for(var i = 0; i < arr.length; i++)
      //     {
      //         var elem = arr.shift();
      //         for(var j = 0; j < elem.length; j++)
      //         {
      //             var childperm = this.getCombinations(arr.slice(), n-1);
      //             for(var k = 0; k < childperm.length; k++)
      //             {
                      
      //                 ret.push([elem[j]].concat(childperm[k]));
      //             }
      //         }
      //     }
      //     return ret;
      // }
  }

  attributeOptionChange = index => (selected_data) => {
    // alert(JSON.stringify(selected_data))
    var foodNameDetails = [...this.state.foodNameDetails]
    
    foodNameDetails[index]["selected_option"] = selected_data
    if (selected_data !==null){

      foodNameDetails[index]["selected"] = selected_data
    }else{
      foodNameDetails[index]["selected"] = []
    }
    this.setState({ foodNameDetails })
    var attr_options=[]
    for (let i =0;i<this.state.foodNameDetails.length;i++){

      attr_options.push(this.state.foodNameDetails[i].selected_option)

    }
    var atr_opt =this.getCombinations(attr_options,attr_options.length)
    var cuisines_values = [...this.state.cuisines_values]  
    this.setState({ food_names:atr_opt })
    if(cuisines_values.length<atr_opt.length){
      var dif_length=atr_opt.length-cuisines_values.length
      for (let i =0;i<dif_length;i++){
          let attr={
            'discount_price':'',
            'food_name':'',
            'price':'',
            'description':'',
            'discount_type':'',
            
            'image':''
          }
          cuisines_values.push(attr)


      }
      this.setState({ cuisines_values:cuisines_values })
    }

  
  };
  addNewRow = (e) => {
    this.setState((prevState) => ({
      foodNameDetails: [
        ...prevState.foodNameDetails,
        {
          index: Math.random(),
          cuisines: [],
          selected:[],
          selected_option: [],
          foodnames: [],
        },
      ],
    }));
  };

  deteteRow(record) {
    this.setState({
      foodNameDetails: this.state.foodNameDetails.filter((r) => r !== record),
    });
  }

  handleSelectedValues = index => (e) => {
    
    var foodNameDetails = [...this.state.foodNameDetails]
    let id = e.target.value
    foodNameDetails[index]["cuisine"] = id;
    foodNameDetails[index]["cuisines"]=[{'id':id,'name':e.target.options[e.target.selectedIndex].text}];
    var token=localStorage.getItem('token')
    // const requestOptions = {
    //   method: 'GET',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     "Authorization": `Token ${token}`
    //   },


    // };
    foodNameDetails[index]["selected_option"] = null

    fetch(FOOD_NAME_ALL_URL + '?cuisine__id=' + id)
      .then(async response => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        
        var results = []
        for (var i = 0; i < data.length; i++) {
          // alert(data.results)
          results.push({ label: data[i].name, value: data[i].id })
        }
        foodNameDetails[index]["foodnames"] = results
        this.setState({ foodNameDetails })

      })
      .catch(error => {
        this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
    
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    // alert(JSON.stringify(nextProps.restaurants.restaurant_foods))
    if (nextProps.is_getting === true) {
      if (nextProps.is_getting !== prevState.details) {
        if (nextProps.restaurants.length == undefined) {
        
        var  foodNameDetails= [
            {
              index: Math.random(),
              selected_option: [],
              selected:[],
              cuisine: '',
              cuisines: [],
              cuisines_values:[],
              foodnames: [],
              // quantity: "",
              // size_price: "",
            },
          ]

          var restaurants = nextProps.restaurants
          

          
          
          
          
          
          var foodNameDetails = []
          var data=[]
          for (var i = 0; i <restaurants.restaurant_food_items.length; i++) {
            var foodname = restaurants.restaurant_food_items[i].food_name
            var foodnames = []
            for (var j = 0; j < foodname.length; j++) {
                foodnames.push({ label: foodname[j].name, value: foodname[j].id })
            }
            data.push(foodnames)
            
            foodNameDetails.push({ 'cuisine': restaurants.restaurant_food_items[i].cuisine,'selected':foodnames,  cuisines:[restaurants.restaurant_food_items[i].cuisines] ,'selected_option': foodnames })
          }
          if(foodNameDetails.length>0){
            foodNameDetails.reverse()
          }
          if(foodNameDetails.length==0){
             foodNameDetails.push(
              {
                index: Math.random(),
                selected_option: [],
                cuisine: '',
                cuisines: [],
                selected:[],
                cuisines_values:[],
                foodnames: [],
                // quantity: "",
                // size_price: "",
              }
             )
  
          }
         
         var restaurant =new RestaurantUpdate()
          var combination =restaurant.getCombinations(data,data.length)

          foodNameDetails["cuisines_values"]=restaurants.restaurant_foods
          // alert(JSON.stringify(combination))
          var cuisines_values=restaurants.restaurant_foods
          // alert(JSON.stringify(attributeDetails[2]))
          //alert(JSON.stringify(products.up_sell_product))
          return {
            details:true,
            food_names:combination,
            foodNameDetails: foodNameDetails,
            cuisines_values:cuisines_values,
            restaurant_food_items: restaurants.restaurant_food_items,

          }

      
        }
      }
      return null
    }
    return null

  }

    submit(data) {
        const {cuisines_values,foodNameDetails,food_names}=this.state
            var form_data = new FormData();
            // Object.keys(data).forEach(key => form_data.append(key, data[key]));
            form_data.append('restaurant_name', data.restaurant_name)
            form_data.append('address', data.address)
            form_data.append('contact_number', data.contact_number)
            form_data.append('email', data.email)
            form_data.append('district', data.district)
            form_data.append('first_name', data.first_name)
            form_data.append('last_name', data.last_name)
            form_data.append('post_code', data.post_code)
            form_data.append('delivery_charge', data.delivery_charge)


            
            if (data.logos !==null && data.logos !=='' && data.logos !==undefined) {
                form_data.append('logo', data.logos, data.logos.name);
            } 
            if (data.banners !==null && data.banners !=='' && data.banners !==undefined) {
                form_data.append('banner', data.banners, data.banners.name);
            } 
            if (data.menu_item_images !==null && data.menu_item_images !=='' && data.menu_item_images !==undefined) {
                form_data.append('menu_item_image', data.menu_item_images, data.menu_item_images.name);
            } 

            form_data.append("restaurant_foods_item", JSON.stringify(foodNameDetails));
            // console.log("Files",cuisine_list)
            var child_attr=[]
            if(cuisines_values.length>0){
                for (var i =0;i<food_names.length;i++){
                  let attr=[]
                    for (let j=0;j<food_names[i].length;j++){
                    
                      attr.push(food_names[i][j].value)
                    }
                  
                    if (attr !==null)
                      var attr_options= Object.assign(cuisines_values[i], {'food_name':attr});
                      child_attr.push(attr_options)
                      if(cuisines_values[i].image!=undefined && cuisines_values[i].image!=''){
                       if(typeof(cuisines_values[i].image)!=='string'){
                        form_data.append(`food_image`+i, cuisines_values[i].image, cuisines_values[i].image.name);
                       }
                      
                      }
                }
             
                
                
            }

            form_data.append("restaurants_food_data", JSON.stringify(child_attr));
            
            let id = this.props.match.params.id;       
            this.props.updateData(form_data, RESTAURANT_URL+ id+'/', "Restaurant")

    }
    onChangePaymentType = (e) => {

        this.setState({[e.target.name]:e.target.value})
        // alert(e.target.value)
    
    }
    render() {

        const {allowProductSize,foodNameDetails,food_names,cuisines_values} = this.state
        const { handleSubmit, submitting,attributes,cuisines,foodnames } = this.props;
       
        if (this.props.is_updated) {
            setTimeout(function () {
                window.location.href = '/restaurant-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark"> Restaurant update</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active"> Restaurant update</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Restaurant Information</h3>
                        <div className="float-right">
                            <Link to="/restaurant-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="restaurant_name"
                                        type="text"
                                        component={renderField}
                                        label="Restaurant Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="address"
                                        type="text"
                                        component={renderField}
                                        label="Address"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="contact_number"
                                        type="text"
                                        component={renderField}
                                        label="Contact Number"
                                        validate={phoneNumber}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                     //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="first_name"
                                        type="text"
                                        component={renderField}
                                        label="First Name"
                                    // validate={required}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="last_name"
                                        type="text"
                                        component={renderField}
                                        label="Last Name"
                                        // validate={[required, match("password1")]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="post_code"
                                        type="text"
                                        component={renderField}
                                        label="Post Code"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="delivery_charge"
                                        type="text"
                                        component={renderField}
                                        label="Delivery Charge"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Select District</label>
                                    <span className="requiredField">*</span>
                                    <Field name="district" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select District</option>
                                        {this.props.districts.map(shipping_cls => (
                                            <option key={shipping_cls.id} value={shipping_cls.id}>{shipping_cls.name}</option>
                                        ))}
                                    </Field>
                                </div>
                                {/* <div className="col-md-6">
                                    <label>Cuisine </label>
                                    <span className="requiredField">*</span>
                                    <Field name="cuisine" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select Cuisine</option>
                                        {this.props.cuisines.map(shipping_cls => (
                                            <option key={shipping_cls.id} value={shipping_cls.id}>{shipping_cls.name}</option>
                                        ))}
                                    </Field>
                                </div> */}
                                <div className="col-md-6">
                                    <img src={this.props.restaurants.logo} style={{width:150,height:150}}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Restaurant Logo</label>
                                    <Field
                                        name="logos"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Restaurant Logo"
                                    // validate={[required]}
                                    />

                                </div>
                                <div className="col-md-6">
                                    <img src={this.props.restaurants.banner} style={{width:150,height:150}}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Restaurant Banner</label>
                                    <Field
                                        name="banners"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Upload Restaurant Banner"
                                   //  validate={[required]}
                                    />

                                </div>
                                <div className="col-md-6">
                                    <img src={this.props.restaurants.menu_item_image} style={{width:150,height:150}}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Restaurant Menu Or Item</label>
                                    <Field
                                        name="menu_item_images"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Upload Restaurant Menu Or Item"
                                   //  validate={[required]}
                                    />

                                </div>

                                <div className="col-md-12">
                      <table className="table">
                        <tr>
                          <th>Cuisine</th>
                          <th>Food Name</th>
                          {/* <th>Quantity</th>
                          <th>Price</th> */}

                          <th>Action</th>
                        </tr>
                        <tbody>
                          {foodNameDetails.map((colors, idx) => (
                            <tr key={colors.index}>
                              <td>

                                <select
                                  name="cuisine"
                                  component="select"
                                  className="form-control"
                                  data-id={idx}
                                  id={"size" + idx}
                                  onChange={this.handleSelectedValues(idx)}
                                >
                                  <option value="">--Select Cuisine--</option>
                                  {cuisines.map((cuisine, index) => (
                                    <option key={index} selected={colors.cuisine === cuisine.id ? true : false} value={cuisine.id}>{cuisine.name}</option>

                                  ))}
                                </select>
                              </td>
                              <td>

                                <Select
                                  value={colors.selected_option}
                                  onChange={this.attributeOptionChange(idx)}
                                  required={true}
                                  options={colors.foodnames}
                                  placeholder="Select Attribute Option"
                                  isMulti
                                  isClearable
                                  id={"food_name" + idx}
                                // onChange={this.handleSelectedValues(idx)}
                                />


                              </td>

                            

                              
                              <td>
                                {idx === 0 ? (
                                  <button
                                    onClick={() => this.addNewRow()}
                                    type="button"
                                    className="btn btn-primary text-center"
                                  >
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => this.deteteRow(colors)}
                                  >
                                    <i
                                      className="fa fa-minus"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* {food_names.map((attr_options,optindex)=>( */}
                      <div>
                        <div className="row">  
                      
                       
                        {foodNameDetails.length>0 && cuisines_values.length>0 ? 
                          <>
                         
                            {foodNameDetails.map((colors,index)=>(
                              
                            <>
                           
                             {colors.selected_option !==null && colors.selected_option.length>0 ?
                             <>
                             {colors[index] !==undefined && colors[index] !=null?

                               <div className="col-md-3 mt-2 mb-2">
                                <label>Food Name</label>
                                    <select
                                        name="food_name"
                                        component="select"
                                        className="form-control"
                                        data-id={index}
                                        id={"size1" + index}
                                       //onChange={this.handleSelectedValues(idx)}
                                      >
                                         <option  key={index} value={colors[index] ? colors[index].value :''  }>{colors[index] ? colors[index].label :'' }</option>

                                        {/* {
                                        colors.selected_option.map((option, optindex) => (
                                          <option selected={attr_options[index] && attr_options[index].value===option.value ? true :false } key={optindex} value={option.value}>{option.label}</option>

                                        ))} */}
                                      </select>
                                  </div>
                                  :null}
                               </>  
                              :null
                              }
                          
                        
                       {cuisines_values.length>0?
                       <>
                       <div className='row'>
                       <div className="col-md-3 mt-2 mb-2">
                            <label>Cuisine name</label>
                            <p>{colors.cuisines.length>0? colors.cuisines[0].name:''}</p>
                        </div>
                        <div className="col-md-3 mt-2 mb-2">
                            <label>Food Name</label>
                            <p>{colors.selected.length>0? colors.selected[0].label:''}</p>
                        </div>
                        <div className="col-md-6">
                          
                            <img src={cuisines_values[index]!==undefined && cuisines_values[index] !==null?BASE_URL+ cuisines_values[index].image :''} style={{width:50,height:50}}/>
                                   
                        </div>
                            <div className="col-md-3 mt-2 mb-2">
                            <label>Image</label>
                            <input
                              type="file"
                              onChange={this.handletextChild(index)}
                              data-id={index}
                              id={"file" + index}
                              name="image"
                              
                          />
                            </div>
                             
                            <div className="col-md-3 mt-2 mb-2">
                            <label> Price</label>
                              <input className="form-control" type="number" onChange={this.handletextChild(index)} value={cuisines_values[index]!==undefined && cuisines_values[index] !==null?cuisines_values[index].price :''}  name="price" placeholder="Price" required/>
                            </div> 
                            <div className="col-md-3 mt-2 mb-2">
                            <label> Description</label>
                              <input className="form-control" type="text" onChange={this.handletextChild(index)} value={cuisines_values[index]!==undefined && cuisines_values[index] !==null?cuisines_values[index].description:''}  name="description" placeholder="Description" />
                            </div> 
                            <div className="col-md-3 mt-2 mb-2">
                            <label>Discount Type</label>
                            {/* <span className="requiredField">*</span> */}
                            <select name="discount_type" onChange={this.handletextChild(index)} value={cuisines_values[index]!==undefined && cuisines_values[index] !==null?cuisines_values[index].discount_type:''}  className="form-control"
                            
                            >
                              <option value="">Select Discount Type</option>
                              <option value="1">Percentage (%)</option>
                              <option value="2">Flat Discount (BDT)</option>
                              <option value="3">No Discount</option>

                            </select>
                          </div>
                          <div className="col-md-3 mt-2 mb-2">
                          <label>Discount Amount</label>
                            <input name="discount_price" type="number" onChange={this.handletextChild(index)} value={cuisines_values[index]!==undefined && cuisines_values[index] !==null?cuisines_values[index].discount_price:''} placeholder="Discount Amount" className="form-control"
                              label="Discount Amount"  />
                          </div>
                          </div>
                          </>
                          :null}
                            </>  
                           ))}     
                          </>
                         :null
                        } 
                          </div> 
                      </div>
                      {/* ))} */}
                    </div>
                                
                                
                            </div>
                            

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


RestaurantUpdate.propTypes = {
    // getRides:PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    is_added: state.products.is_added,
    is_updated:state.products.is_updated,
    
    districts: state.districts.districts,
    cuisines:state.cuisines.cuisines,
    restaurants:state.restaurants.restaurant,
    initialValues: state.restaurants.restaurant,
    foodnames: state.foodnames.foodnames,
    is_getting: state.restaurants.is_getting,


});


RestaurantUpdate = reduxForm({
    form: 'RestaurantUpdate',
    enableReinitialize: true

}
)(RestaurantUpdate);


export default connect(mapStateToProps, { updateData,fetchDistrict, fetchCuisine,RestaurantDetail})(RestaurantUpdate);

import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { connect } from "react-redux";

import { loadUser } from "./actions/auth";
import { getUserPermissionAction} from "./actions/UserPermissionsAction"
import {GET_USER_PERMISSION} from './constants/api'

import Dashboard from "./components/dashboard";
import Notfound from "./components/notfound";
// import Register from "./components/Register";
import Login from "./components/Login";
import Layout from "./hoc/Layout/layout";
import UserList from "./components/Users/UserList";
import UserLists from "./components/user/user/UserList";

import ResetPassword from "./components/ResetPassword";
import ResetPasswordConfirm from "./components/ResetPasswordConfirm"

import AddProduct from "./components/product/AddProduct";
import ProductList from "./components/product/ProductList";
import ProductUpdate from './components/product/ProductUpdate';

import AddPrimaryCategory from './components/category/primarycategory/AddPrimaryCategory'
import PrimaryCategoryList from './components/category/primarycategory/PrimaryCategoryList'
import PrimaryCategoryDetails from './components/category/primarycategory/PrimaryCategoryDetails';
import PrimaryCategoryUpdate from './components/category/primarycategory/PrimaryCategoryUpdate';

import AddSecondaryCategory from './components/category/secondarycategory/AddSecondaryCategory';
import SecondaryCategoryList from './components/category/secondarycategory/SecondaryCategoryList';
import SecondaryCategoryDetail from './components/category/secondarycategory/SecondaryCategoryDetail'
import SecondaryCategoryUpdate from './components/category/secondarycategory/SecondaryCategoryUpdate';

import AddChildCategory from './components/category/childcategory/AddChildCategory';
import ChildCategoryList from './components/category/childcategory/ChildCategoryList';
import ChildCategoryDetail from './components/category/childcategory/ChildCategoryDetail';
import ChildCategoryUpdate from './components/category/childcategory/ChildCategoryUpdate';

import AddAttributes from './components/attributes/AddAttributes'
import AttributesList from './components/attributes/AttributesList';
import AttributesDetail from './components/attributes/AttributeDetails';
import AttributesUpdate from './components/attributes/AttributeUpdate';

import AddTags from './components/tags/AddTags';
import TagsList from './components/tags/TagsList';
import TagsDetail from './components/tags/TagsDetail';
import TagsUpdate from './components/tags/TagsUpdate';

import AddBrands from './components/brands/AddBrands';
import BrandsList from './components/brands/BrandsList'
import BrandsDetail from './components/brands/BrandsDetail';
import BrandsUpdate from './components/brands/BrandsUpdate';

import RatingsReviewList from './components/ratings/RatingsReviewList';
import OrderList from './components/order/OrderList';
import OrderDetail from './components/order/OrderDetails';
import OrderUpdate from './components/order/OrderUpdate';
import BundleProduct from './components/product/BundleProduct';

import AddAttributesOption from './components/attributesoption/AddAttributesOption';
import AttributesOptionList from './components/attributesoption/AttributesOptionList';
import AttributesOptionDetail from './components/attributesoption/AttributeOptionDetail';
import AttributesOptionUpdate from './components/attributesoption/AttributeOptionUpdate';

import PrivilegeContainer from "./containers/privilege/PrivilegeContainer";
import { isPristine } from 'redux-form';

import AddSeller from './components/seller/seller/AddSeller';
import SellerList from './components/seller/seller/SellerList';
import SellerUpdate from './components/seller/seller/SellerUpdate';
import CustomerList from './components/customer/customer/CustomerList';
import SubscriberList from './components/customer/subscriber/SubscriberList';
import SupportList from './components/customer/support/SupportTicketList';
import WithdrawList from './components/seller/withdraw/WithdrawList';
import UserRoleList from './components/user/userrole/UserRoleList';
import AddCustomer from './components/customer/customer/AddCustomer';
import CustomerDetail from './components/customer/customer/CustomerDetail';
import CustomerUpdate from './components/customer/customer/CustomerUpdate';
import SupportTicketList from './components/customer/support/SupportTicketList';
import AddSupportTicket from './components/customer/support/AddSupportTicket';
import SupportTicketDetails from './components/customer/support/SupportTicketDetails';
import SupportTicketUpdate from './components/customer/support/SupportTicketUpdate';
import AddUser from './components/user/user/AddUser';
import UserUpdate from './components/user/user/UserUpdate';


import AddOrder from './components/order/AddOrder';
import { orderDetail } from './actions/orderActions';
import AddWithdraw from './components/seller/withdraw/AddWithdraw';
import CouponList from './components/campaign/coupon/CouponList';
import AddCoupon from './components/campaign/coupon/AddCoupon';
import AddCampaign from './components/campaign/campaign/AddCampaign';
import CampaignList from './components/campaign/campaign/CampaignList';
import TaxList from './components/tax/TaxList';
import { addTags } from './actions/TagsActions';
import AddTax from './components/tax/AddTax';
import ShippingZoneList from './components/shipping/shippingzone/ShippingZoneList';
import AddShippingZone from './components/shipping/shippingzone/AddShippingZone';
import AddShippingClass from './components/shipping/shippingclass/AddShippingClass';
import ShippingClassList from './components/shipping/shippingclass/ShippingClassList';
import TaxUpdate from './components/tax/TaxUpdate';
import AddReturn from './components/order/return/AddReturn';
import ReturnList from './components/order/return/ReturnList';

import ShippingClassUpdate from './components/shipping/shippingclass/ShippingClassUpdate';
import ShippingZoneUpdate from './components/shipping/shippingzone/ShippingZoneUpdate';
import CampaignUpdate from './components/campaign/campaign/CampaignUpdate';
import CouponUpdate from './components/campaign/coupon/CouponUpdate';
import BundleProductList from './components/product/BundleProductList';
import AddDigitalProduct from './components/product/AddDigitalProduct';
import ProductUnitList from './components/productunit/ProductUnitList';
import AddDistricts from './components/districts/AddDistricts';
import DistrictsList from './components/districts/DistrictsList';
import UpdateDistricts from './components/districts/UpdateDistricts';
import AddPaymentMethod from './components/order/payment/AddPaymentMethod';
import PaymentMethodList from './components/order/payment/PaymentMethodList';
import UpdatePaymentMethod from './components/order/payment/UpdatePaymentMethod';
import AddSellerProduct from './components/seller/seller/AddSellerProduct';
import SellerProductUpdate from './components/seller/seller/SellerProductUpdate'
import AddSellerProductList from './components/seller/seller/AddSellerProductList';
import SellerOrderList from './components/seller/seller/SellerOrderList';
import AddSellerVirtualProduct from './components/seller/seller/AddSellerVirtualProduct';
import BundleProductUpdate from './components/product/BundleProductUpdate';

import SellerShopSettings from './components/seller/seller/SellerShopSettings';
import SellerProfileSettings from './components/seller/seller/SellerProfileSettings';
import SellerWithdrawList from './components/seller/seller/SellerWithdrawList'
import AddSellerWithdraw from './components/seller/seller/AddSellerWithdraw'
import AddContactInfo from './components/appearance/contactinfo/AddContactInfo';
import ContactInfoList from './components/appearance/contactinfo/ContactInfoList';
import AddCopyRight from './components/appearance/copyright/AddCopyRight';
import CopyRightList from './components/appearance/copyright/CopyRightList';
import LogoList from './components/appearance/logo/LogoList';
import SlideSettingList from './components/appearance/slidesetting/SlideSettingList';
import AddSlideSetting from './components/appearance/slidesetting/AddSlideSetting';
import SlideRightSideSettingList from './components/appearance/sliderightsidesetting/SlideRightSideSettingList';
import AddSlideRightSideSetting from './components/appearance/sliderightsidesetting/AddSlideRightSideSetting';
import SlideRightSideSettingUpdate from './components/appearance/sliderightsidesetting/SlideRightSideSettingUpdate'
import BannerSettingList from './components/appearance/bannersetting/BannerSettingList';
import AddBannerSetting from './components/appearance/bannersetting/AddBannerSetting';
import SocialMediaList from './components/appearance/socialmedia/SocialMediaList';
import FooterLeftSection from './components/appearance/footerleftsection/FooterLeftSection';

import AddBlog from './components/blog/blog/AddBlog';
import BlogList from './components/blog/blog/BlogList';
import BlogCategoryList from './components/blog/blogcategory/BlogCategoryList';
import BlogTagList from './components/blog/blogtag/BlogTagList';
import AddBlogCategory from './components/blog/blogcategory/AddBlogCategory';
import AddBlogTag from './components/blog/blogtag/AddBlogTag';
import Uploader from './components/medialibrary/UploaderPreview';
import BlogUpdate from './components/blog/blog/BlogUpdate';
import SlideSettingUpdate from './components/appearance/slidesetting/SlideSettingUpdate';
import AddSocialMedia from './components/appearance/socialmedia/AddSocialMedia';
import SocialMediaUpdate from './components/appearance/socialmedia/SocialMediaUpdate';
import BannerSettingUpdate from './components/appearance/bannersetting/BannerSettingUpdate';
import ContactInfoUpdate from './components/appearance/contactinfo/ContactInfoUpdate';
import Favicon from './components/appearance/favicon/Favicon';
import Preloader from './components/appearance/preloader/Preloader';
import BlogCategoryUpdate from './components/blog/blogcategory/BlogCategoryUpdate';
import BlogTagUpdate from './components/blog/blogtag/BlogTagUpdate';
import ReturnUpdate from './components/order/return/ReturnUpdate';
import UserPermissionList from './components/user/userpermissions/UserPermissionsList';
import UserPermissionsContainer from './containers/User/UserPermissionsContainer';
import TermsCondition from './components/appearance/pagesetting/termscondition/TermsCondition';
import ShippingPolicy from './components/appearance/pagesetting/shippingpolicy/ShippingPolicy';
import PrivacyPolicy from './components/appearance/pagesetting/privacypolicy/PrivacyPolicy';
import ReturnRefundPolicy from './components/appearance/pagesetting/returnrefundpolicy/ReturnRefundPolicy';
import AboutUs from './components/appearance/pagesetting/aboutus/AboutUs';
import Faq from './components/appearance/pagesetting/faq/Faq';
import RestaurantList from './components/restaurant/restaurant/RestaurantList';
import AddRestaurant from './components/restaurant/restaurant/AddRestaurant';
import RestaurantUpdate from './components/restaurant/restaurant/RestaurantUpdate';
import AddCuisine from './components/restaurant/cuisine/AddCuisine';
import CuisineList from './components/restaurant/cuisine/CuisineList';
import CuisineUpdate from './components/restaurant/cuisine/CuisineUpdate'
import ChangePassword from './components/appearance/changepassword/ChangePassword';
import DigitalProductUpdate from './components/product/DigitalProductUpdate';
import FoodNameList from './components/restaurant/foodname/FoodNameList';
import RestaurantSliderList from './components/restaurant/restaurantslider/RestaurantSliderList';
import MedicineDeliveryList from './components/medicinedelivery/medicinedelivery/MedicineDeliveryList';
import RiderCarTripList from './components/ridercartrip/ridercartrip/RiderCarTripList';
import PercelExpressList from './components/percelexpress/percelexpress/PercelExpressList';
import { FooterPaymentImageReducer } from './reducers/CopyRightReducer';
import FooterPaymentImage from './components/appearance/footerpaymentimage/FooterPaymentImage';
import AddFoodName from './components/restaurant/foodname/AddFoodName';
import FoodNameUpdate from './components/restaurant/foodname/FoodNameUpdate';
import AddRestaurantSlider from './components/restaurant/restaurantslider/AddRestaurantSlider';
import RestaurantSliderUpdate from './components/restaurant/restaurantslider/RestaurantSliderUpdate';
import FoodOrderList from './components/restaurant/foodorder/FoodOrderList';
import RiderCarTypeList from './components/ridercartrip/ridercartype/RiderCarTypeList';
import AddRiderCarType from './components/ridercartrip/ridercartype/AddRiderCarType';
import RiderCarTypeUpdate from './components/ridercartrip/ridercartype/RiderCarTypeUpdate';
import PercelTypeList from './components/percelexpress/perceltype/PercelTypeList';
import AddPercelType from './components/percelexpress/perceltype/AddPercelType';
import PercelTypeUpdate from './components/percelexpress/perceltype/PercelTypeUpdate';
import PercelPlanList from './components/percelexpress/percelplan/PercelPlanList';
import AddPercelPlan from './components/percelexpress/percelplan/AddPercelPlan';
import PercelPlanUpdate from './components/percelexpress/percelplan/PercelPlanUpdate';
import SupportMessagesList from './components/customer/supportmessages/SupportMessagesList';
import ContactMessagesList from './components/customer/contactmessages/ContactMessagesList';
import FoodOrderUpdate from './components/restaurant/foodorder/FoodOrderUpdate';
import FoodOrderDetails from './components/restaurant/foodorder/FoodOrderDetails';
import MobileAppSliderList from './components/restaurant/mobileappslider/MobileAppSliderList';
import AddMobileAppSlider from './components/restaurant/mobileappslider/AddMobileAppSlider';
import GeneratedPDF from './services/GeneratedPDF';
import MobileAppSliderUpdate from './components/restaurant/mobileappslider/MobileAppSliderUpdate';
import AddAgent from './components/agent/AddAgent';
import AgentList from './components/agent/AgentList';
import AgentUpdate from './components/agent/AgentUpdate';
import AddStock from './components/stock/AddStock';
import StockUpdate from './components/stock/StockUpdate';
import AddWarehouse from './components/warehouse/AddWarehouse';
import WarehouseUpdate from './components/warehouse/WarehouseUpdate';
import AddTransaction from './components/transaction/AddTransaction';
import TransactionUpdate from './components/transaction/TransactionUpdate';
import StockList from './components/stock/StockList';
import TransactionList from './components/transaction/TransactionList';
import WarehouseList from './components/warehouse/WarehouseList';
import Reports from './components/reports/AgentWiseReports';
// import Exact from './components/reports/AgentWiseReports';
import AgentProfileSettings from './components/agent/AgentProfileSettings';
import AgentWiseReports from './components/reports/AgentWiseReports';
import ProductStockReports from './components/reports/ProductStockReports';
import SalesReports from './components/reports/SalesReports';
import AddAgentProductStock from './components/agent/agentstock/AddAgentProductStock';
import AgentProductStockList from './components/agent/agentstock/AgentProductStockList';
import AgentProductStockUpdate from './components/agent/agentstock/AgentProductStockUpdate';
import AddAgentProductStockSale from './components/agent/agentproductsale/AddAgentProductStockSale'
import AgentProductStockSaleList from './components/agent/agentproductsale/AgentProductStockSaleList'
import AgentProductStockSaleUpdate from './components/agent/agentproductsale/AgentProductStockSaleUpdate';
import AgentProductStockReports from './components/agent/agentsalereports/AgentProductStockINReports';
import AgentProductSalesReports from './components/agent/agentsalereports/AgentProductSalesReports';
import AgentProductStockINReports from './components/agent/agentsalereports/AgentProductStockINReports';
import AgentProductStockOutReports from './components/agent/agentsalereports/AgentProductStockOutReports';
import AddAgentCustomer from './components/agent/agentcustomer/AddAgentCustomer';
import AgentCustomerList from './components/agent/agentcustomer/AgentCustomerList';
import AgentCustomerUpdate from './components/agent/agentcustomer/AgentCustomerUpdate';
import { AgentProductSaleDetail } from './actions/AgentActions';
import AgentProductStockSaleDetails from './components/agent/agentproductsale/AgentProductStockSaleDetails';

class App extends Component {

    componentDidMount() {
        this.props.loadUser();
        this.props.permission(GET_USER_PERMISSION)

    }

    PrivateRoute = ({ component: ChildComponent, ...rest }) => {
        return <Route {...rest} render={props => {
            if (this.props.auth.isLoading) {
                return <em>Loading...</em>;
            } else if (!this.props.auth.isAuthenticated) {
                return <Redirect to="/login" />;
            } else if(this.props.auth.isAuthenticated && this.props.auth.user.group!==3){
                return <><ChildComponent {...props} />
                </>
            }else{
                return <Redirect to="/login" />; 
            }
        }} />
    };

    render() {
        let { PrivateRoute } = this;
        return (
            <div>
                <Switch>

                    <Route exact path="/login" component={Login} />
                    <Route exact path='/forgot-password' component={ResetPassword} />
                    <Route exact path='/password/reset/confirm/:uid/:token' component={ResetPasswordConfirm} />
                    <Layout>
                        <PrivateRoute exact path="/" component={Dashboard} />
                        {/*<Route exact path="/register" component={Register}/>*/}
                        {/*<Route exact path="/login" component={Login} />*/}
                        <PrivateRoute exact path="/user-list" component={UserList} />
                        <PrivateRoute exact path="/user-privileges" component={PrivilegeContainer} />
                        <PrivateRoute exact path="/user-permissions" component={UserPermissionsContainer}/>

                        <PrivateRoute exact path="/primary-category" component={AddPrimaryCategory} />
                        <PrivateRoute exact path="/primary-category-list" component={PrimaryCategoryList} />
                        <PrivateRoute exact path="/primary-category-detail/:id" component={PrimaryCategoryDetails} />
                        <PrivateRoute exact path="/primary-category-update/:id" component={PrimaryCategoryUpdate} />

                        <PrivateRoute exact path="/add-secondary-category" component={AddSecondaryCategory} />
                        <PrivateRoute exact path="/secondary-category-list" component={SecondaryCategoryList} />
                        <PrivateRoute exact path="/secondary-category-detail/:id" component={SecondaryCategoryDetail} />
                        <PrivateRoute exact path="/secondary-category-update/:id" component={SecondaryCategoryUpdate} />

                        <PrivateRoute exact path="/add-child-category" component={AddChildCategory} />
                        <PrivateRoute exact path="/child-category-list" component={ChildCategoryList} />
                        <PrivateRoute exact path="/child-category-detail/:id" component={ChildCategoryDetail} />
                        <PrivateRoute exact path="/child-category-update/:id" component={ChildCategoryUpdate} />

                        <PrivateRoute exact path="/add-attributes" component={AddAttributes} />
                        <PrivateRoute exact path="/attributes-list" component={AttributesList} />
                        <PrivateRoute exact path="/attributes-detail/:id" component={AttributesDetail} />
                        <PrivateRoute exact path="/attributes-update/:id" component={AttributesUpdate} />

                        <PrivateRoute exact path="/add-attribute-option" component={AddAttributesOption} />
                        <PrivateRoute exact path="/attribute-option-list" component={AttributesOptionList} />
                        <PrivateRoute exact path="/attribute-option-detail/:id" component={AttributesOptionDetail} />
                        <PrivateRoute exact path="/attribute-option-update/:id" component={AttributesOptionUpdate} />


                        <PrivateRoute exact path="/add-tags" component={AddTags} />
                        <PrivateRoute exact path="/tags-list" component={TagsList} />
                        <PrivateRoute exact path="/tags-detail/:id" component={TagsDetail} />
                        <PrivateRoute exact path="/tags-update/:id" component={TagsUpdate} />

                        <PrivateRoute exact path="/add-brands" component={AddBrands} />
                        <PrivateRoute exact path="/brands-list" component={BrandsList} />
                        <PrivateRoute exact path="/brands-detail/:id" component={BrandsDetail} />
                        <PrivateRoute exact path="/brands-update/:id" component={BrandsUpdate} />

                        <PrivateRoute exact path="/rating-review-list" component={RatingsReviewList} />
                        <PrivateRoute exact path="/order-list" component={OrderList} />
                        <PrivateRoute exact path="/add-order" component={AddOrder} />
                        <PrivateRoute exact path="/view-order/:id" component={OrderDetail} />
                        <PrivateRoute exact path="/update-order/:id" component={OrderUpdate} />

                        <PrivateRoute exact path="/add-return" component={AddReturn} />
                        <PrivateRoute exact path="/return-list" component={ReturnList} />
                        <PrivateRoute exact path="/return-update/:id" component={ReturnUpdate}/>
                        <PrivateRoute exact path="/add-bundle-product" component={BundleProduct} />
                        <PrivateRoute exact path="/bundle-product-update/:id" component={BundleProductUpdate} />

                        <PrivateRoute exact path="/bundle-product-list" component={BundleProductList} />
                        <PrivateRoute exact path="/product-unit-list" component={ProductUnitList} />
                        <PrivateRoute exact path="/add-digital-product" component={AddDigitalProduct} />
                        <PrivateRoute exact path="/digital-product-update/:id" component={DigitalProductUpdate}/>
                        <PrivateRoute exact path="/add-seller" component={AddSeller} />
                        <PrivateRoute exact path="/add-seller-product" component={AddSellerProduct} />
                        <PrivateRoute exact path="/seller-product-update/:id" component={SellerProductUpdate} />
                        <PrivateRoute exact path="/seller-product-list" component={AddSellerProductList} />
                        <PrivateRoute exact path="/seller-order-list" component={SellerOrderList} />
                        <PrivateRoute exact path="/add-seller-virtual-product" component={AddSellerVirtualProduct} />
                        <PrivateRoute exact path="/seller-withdraw-list" component={SellerWithdrawList} />



                        <PrivateRoute exact path="/seller-list" component={SellerList} />
                        <PrivateRoute exact path="/seller-update/:id" component={SellerUpdate} />
                        <PrivateRoute exact path="/withdraw-list" component={WithdrawList} />

                        <PrivateRoute exact path="/add-customer" component={AddCustomer} />
                        <PrivateRoute exact path="/customer-list" component={CustomerList} />
                        <PrivateRoute exact path="/customer-detail/:id" component={CustomerDetail} />
                        <PrivateRoute exact path="/customer-update/:id" component={CustomerUpdate} />

                        <PrivateRoute exact path="/subscriber-list" component={SubscriberList} />
                        <PrivateRoute exact path="/support-ticket-list" component={SupportTicketList} />
                        <PrivateRoute exact path="/add-support-ticket" component={AddSupportTicket} />
                        <PrivateRoute exact path="/support-ticket-detail" component={SupportTicketDetails} />
                        <PrivateRoute exact path="/support-ticket-update" component={SupportTicketUpdate} />

                        <PrivateRoute exact path='/add-districts' component={AddDistricts} />
                        <PrivateRoute exact path='/districts-list' component={DistrictsList} />
                        <PrivateRoute exact path='/districts-update/:id' component={UpdateDistricts} />

                        <PrivateRoute exact path="/add-payment-method" component={AddPaymentMethod} />
                        <PrivateRoute exact path="/payment-method-list" component={PaymentMethodList} />
                        <PrivateRoute exact path="/payment-method-update/:id" component={UpdatePaymentMethod} />




                        {/* <Route component={Notfound}/> */}
                        <PrivateRoute exact path="/user-list" component={UserList} />
                        <PrivateRoute exact path="/users-list" component={UserLists} />
                        <PrivateRoute exact path="/user-edit/:id" component={UserUpdate} />
                        <PrivateRoute exact path="/add-user" component={AddUser} />
                        <PrivateRoute exact path="/user-roles-list" component={UserRoleList} />


                        <PrivateRoute exact path="/user-privileges" component={PrivilegeContainer} />

                        <PrivateRoute exact path="/product-list" component={ProductList} />
                        <PrivateRoute exact path="/add-product" component={AddProduct} />
                        <PrivateRoute exact path="/product-edit/:id" component={ProductUpdate} />
                        <PrivateRoute exact path="/add-withdraw" component={AddWithdraw} />

                        <PrivateRoute exact path="/add-coupon" component={AddCoupon} />
                        <PrivateRoute exact path="/coupon-list" component={CouponList} />
                        <PrivateRoute exact path="/coupon-update/:id" component={CouponUpdate} />

                        <PrivateRoute exact path="/add-campaign" component={AddCampaign} />
                        <PrivateRoute exact path="/campaign-list" component={CampaignList} />
                        <PrivateRoute exact path="/campaign-update/:id" component={CampaignUpdate} />

                        <PrivateRoute exact path="/add-tax" component={AddTax} />
                        <PrivateRoute exact path="/tax-list" component={TaxList} />
                        <PrivateRoute exact path="/tax-update/:id" component={TaxUpdate} />


                        <PrivateRoute exact path="/add-shipping-zone" component={AddShippingZone} />
                        <PrivateRoute exact path="/shipping-zone-list" component={ShippingZoneList} />
                        <PrivateRoute exact path="/shipping-zone-update/:id" component={ShippingZoneUpdate} />

                        <PrivateRoute exact path="/add-shipping-class" component={AddShippingClass} />
                        <PrivateRoute exact path="/shipping-class-list" component={ShippingClassList} />
                        <PrivateRoute exact path="/shipping-class-update/:id" component={ShippingClassUpdate} />
                        <PrivateRoute exact path="/shop-settings" component={SellerShopSettings} />
                        <PrivateRoute exact path="/profile-settings" component={SellerProfileSettings} />
                        <PrivateRoute exact path="/add-seller-withdraw" component={AddSellerWithdraw} />

                        <PrivateRoute exact path="/add-contact-info" component={AddContactInfo} />
                        <PrivateRoute exact path="/contact-info-list" component={ContactInfoList} />
                        <PrivateRoute exact path="/contact-info-update/:id" component={ContactInfoUpdate}/>

                        <PrivateRoute exact path="/add-copyright" component={AddCopyRight} />
                        <PrivateRoute exact path="/copyright-list" component={CopyRightList} />
                        <PrivateRoute exact path="/logo-list" component={LogoList} />
                        <PrivateRoute exact path="/favicon" component={Favicon}/>
                        <PrivateRoute exact path="/pre-loader" component={Preloader}/>

                        <PrivateRoute exact path="/add-slide-setting" component={AddSlideSetting} />
                        <PrivateRoute exact path="/slide-setting-list" component={SlideSettingList} />
                        <PrivateRoute exact path="/slide-setting-update/:id" component={SlideSettingUpdate}/>

                        <PrivateRoute exact path="/add-slide-right-side-setting" component={AddSlideRightSideSetting} />
                        <PrivateRoute exact path="/slide-right-side-setting-list" component={SlideRightSideSettingList} />
                        <PrivateRoute exact path="/slide-right-side-setting-update/:id" component={SlideRightSideSettingUpdate}/>
                        <PrivateRoute exact path="/add-banner-setting" component={AddBannerSetting} />
                        <PrivateRoute exact path="/banner-setting-list" component={BannerSettingList} />
                        <PrivateRoute exact path="/banner-setting-update/:id" component={BannerSettingUpdate}/>

                        <PrivateRoute exact path="/add-social-media" component={AddSocialMedia}/>
                        <PrivateRoute exact path="/social-media-list" component={SocialMediaList} />
                        <PrivateRoute exact path="/social-media-setting-update/:id" component={SocialMediaUpdate}/>
                        <PrivateRoute exact path="/footer-left-section" component={FooterLeftSection} />
                        <PrivateRoute exact path="/add-blog" component={AddBlog} />
                        <PrivateRoute exact path="/blog-update/:id" component={BlogUpdate}/>
                        <PrivateRoute exact path="/blog-list" component={BlogList} />

                        <PrivateRoute exact path="/add-blog-category" component={AddBlogCategory} />
                        <PrivateRoute exact path="/blog-category-list" component={BlogCategoryList} />
                        <PrivateRoute exact path="/blog-category-update/:id" component={BlogCategoryUpdate}/>
                        <PrivateRoute exact path="/add-blog-tag" component={AddBlogTag} />
                        <PrivateRoute exact path="/blog-tags-list" component={BlogTagList} />
                        <PrivateRoute exact path="/blog-tag-update/:id" component={BlogTagUpdate}/>
                        <PrivateRoute exact path="/media-library-list" component={Uploader}/>
                        <PrivateRoute exact path="/terms-condition" component={TermsCondition}/>
                        <PrivateRoute exact path="/shipping-policy" component={ShippingPolicy}/>
                        <PrivateRoute exact path="/privacy-policy" component={PrivacyPolicy}/>
                        <PrivateRoute exact path="/return-refund-policy" component={ReturnRefundPolicy}/>
                        <PrivateRoute exact path="/about-us" component={AboutUs}/>
                        <PrivateRoute exact path="/faq" component={Faq}/>
                        <PrivateRoute exact path="/add-restaurant" component={AddRestaurant}/>
                        <PrivateRoute exact path="/restaurant-list" component={RestaurantList}/>
                        <PrivateRoute exact path="/restaurant-edit/:id" component={RestaurantUpdate}/>
                        <PrivateRoute exact path="/add-cuisine" component={AddCuisine}/>
                        <PrivateRoute exact path="/cuisine-list" component={CuisineList}/>
                        <PrivateRoute exact path="/cuisine-update/:id" component={CuisineUpdate}/>
                        <PrivateRoute exact path="/change-password" component={ChangePassword}/>

                        <PrivateRoute exact path="/food-name-list" component={FoodNameList}/>
                        <PrivateRoute exact path="/add-food-name" component={AddFoodName}/>
                        <PrivateRoute exact path="/food-name-update/:id" component={FoodNameUpdate}/>
                        <PrivateRoute exact path="/restaurant-slider-list" component={RestaurantSliderList}/>
                        <PrivateRoute exact path="/add-restaurant-slider" component={AddRestaurantSlider}/>
                        <PrivateRoute exact path="/restaurant-slider-update/:id" component={RestaurantSliderUpdate}/>
                        <PrivateRoute exact path="/medicine-delivery-list" component={MedicineDeliveryList}/>
                        <PrivateRoute exact path="/rider-car-trip-list" component={RiderCarTripList}/>
                        <PrivateRoute exact path="/percel-express-list" component={PercelExpressList}/>
                        <PrivateRoute exact path="/footer-payment-image" component={FooterPaymentImage}/>
                        <PrivateRoute exact path="/food-order-list" component={FoodOrderList}/>
                        <PrivateRoute exact path="/food-order-detail/:id" component={FoodOrderDetails}/>
                        {/* <PrivateRoute exact path="/food-order-update/:id" component={FoodOrderUpdate}/> */}
                        <PrivateRoute exact path="/rider-car-type-list" component={RiderCarTypeList}/>
                        <PrivateRoute exact path="/add-rider-car-type" component={AddRiderCarType}/>
                        <PrivateRoute exact path="/rider-car-type-update/:id" component={RiderCarTypeUpdate}/>
                        <PrivateRoute exact path="/percel-type-list" component={PercelTypeList}/>
                        <PrivateRoute exact path="/add-percel-type" component={AddPercelType}/>
                        <PrivateRoute exact path="/percel-type-update/:id" component={PercelTypeUpdate}/>
                        <PrivateRoute exact path="/percel-plan-list" component={PercelPlanList}/>
                        <PrivateRoute exact path="/add-percel-plan" component={AddPercelPlan}/>
                        <PrivateRoute exact path="/percel-plan-update/:id" component={PercelPlanUpdate}/>
                        <PrivateRoute exact path="/support-messages" component={SupportMessagesList}/>
                        <PrivateRoute exact path="/contact-messages" component={ContactMessagesList}/>
                        <PrivateRoute exact path="/add-mobile-app-slider" component={AddMobileAppSlider}/>
                        <PrivateRoute exact path="/mobile-app-slider-list" component={MobileAppSliderList}/>
                        <PrivateRoute exact path="/mobile-app-slider-update/:id" component={MobileAppSliderUpdate}/>
                        <PrivateRoute exact path="/generate-pdf" component={GeneratedPDF}/>
                        <PrivateRoute exact path="/add-agent" component={AddAgent}/>
                        <PrivateRoute exact path="/agent-list" component={AgentList}/>
                        <PrivateRoute exact path="/agent-update/:id" component={AgentUpdate}/>
                        <PrivateRoute exact path="/add-stock" component={AddStock}/>
                        <PrivateRoute exact path="/stock-list" component={StockList}/>
                        <PrivateRoute exact path="/stock-update/:id" component={StockUpdate}/>
                        <PrivateRoute exact path="/add-warehouse" component={AddWarehouse}/>
                        <PrivateRoute exact path="/warehouse-list" component={WarehouseList}/>
                        <PrivateRoute exact path="/warehouse-update/:id" component={WarehouseUpdate}/>
                        <PrivateRoute exact path="/add-transaction" component={AddTransaction}/>
                        <PrivateRoute exact path="/transaction-list" component={TransactionList}/>
                        <PrivateRoute exact path="/transaction-update/:id" component={TransactionUpdate}/>
                        <PrivateRoute exact path="/agent-wise-report" component={AgentWiseReports}/>
                        <PrivateRoute exact path="/product-stock-report" component={ProductStockReports}/>
                        <PrivateRoute exact path="/sales-report" component={SalesReports}/>
                        <PrivateRoute exact path="/agent-profile-settings" component={AgentProfileSettings} />
                        <PrivateRoute exact path="/add-agent-stock" component={AddAgentProductStock}/>
                        <PrivateRoute exact path="/agent-stock-list" component={AgentProductStockList}/>
                        <PrivateRoute exact path='/agent-stock-edit/:id' component={AgentProductStockUpdate}/>
                        <PrivateRoute exact path='/add-agent-product-sale' component={AddAgentProductStockSale}/>
                        <PrivateRoute exact path='/agent-product-sale-list' component={AgentProductStockSaleList}/>
                        <PrivateRoute exact path='/agent-product-sale-edit/:id' component={AgentProductStockSaleUpdate}/>
                        <PrivateRoute exact path='/agent-product-stock-in-report' component={AgentProductStockINReports}/>
                        <PrivateRoute exact path='/agent-product-stock-out-report' component={AgentProductStockOutReports}/>
                        <PrivateRoute exact path='/agent-product-sale-reports' component={AgentProductSalesReports}/>
                        <PrivateRoute exact path='/add-agent-customer' component={AddAgentCustomer}/>
                        <PrivateRoute exact path='/agent-customer-list' component={AgentCustomerList}/>
                        <PrivateRoute exact path='/agent-customer-update/:id' component={AgentCustomerUpdate}/>
                        <PrivateRoute exact path='/agent-product-sale-view/:id' component={AgentProductStockSaleDetails}/>
                    </Layout>
                    <Route path="/" component={Notfound} />
                    {/* <Redirect to="/"/> */}
                </Switch>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadUser: () => {
            return dispatch(loadUser());
        },
        permission: (url) => {dispatch(getUserPermissionAction(url))},
    
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(App);



import {
    LOADING_SELLER,
    FETCH_SELLER,
    DETAIL_SELLER,
    UPDATE_SELLER,
    DELETE_SELLER,
    NOT_FOUND_SELLER,
    UPDATE_SELLER_STATUS
} from '../actions/types';

const initialState = {
    sellers: [],
    seller: {},
    count: null,
    next: null,
    is_added: false,
    is_getting:false,
    is_loading: false,
    is_list:false,
    is_details:false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_SELLER:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_SELLER:
            return {
                ...state,
                sellers: action.payload,
                count: action.count,
                next: action.next,
                is_getting:true,
                is_loading: false,
                is_list:true,
            };
        case UPDATE_SELLER:
            return {
                ...state,
                sellers: action.payload,
                is_loading: false
            };
        case DETAIL_SELLER:
            return {
                ...state,
                is_details:true,
                sellers: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_SELLER:
            return {
                ...state,
                sellers: state.sellers.filter(seller => seller.id !== action.payload),
                is_updated: true
            };
        case UPDATE_SELLER_STATUS:
            let index =action.payload.index;
            let allsellers = [...state.sellers];
            allsellers[index] = {...allsellers[index], 'status': action.payload.status};
            return {
                ...state,         
                sellers: allsellers

            };
        case NOT_FOUND_SELLER:
            return {
                ...state,
                sellers: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

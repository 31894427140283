import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required } from '../../constants/Validate'
import { connect } from "react-redux";
import { fetchDistrict } from "../../actions/DistrictsActions"
import {  addData } from "../../actions/CustomerActions"

import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';


import { toast } from "react-toastify";
import { BASE_URL, DISTRICT_URL } from "../../constants/api";


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

const submitToServer = (data) => {
    // console.log('data', data);


    var form_data = { "name": data.name };

    console.log('data', form_data);


    let url = DISTRICT_URL;

    let token = localStorage.getItem('token');
    // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
    console.log(token);
    axios.post(url, form_data,
        {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Token ${token}`
            }

        })
        .then(res => {
            console.log(res.data);
            // dispatch(reset('PartnerAddForm'))
            toast.success('Successfully added a new Districts.')
        })
        .catch(err => console.log(err))
};


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddDistrict extends Component {

    componentDidMount() {
        // this.props.getRides(0)
        this.props.fetchDistrict(0)
    }


    submit(values) {


        var form_data = {
            name: values.name
        }
        this.props.addData(form_data, DISTRICT_URL, "District")

    }

    render() {
        if (this.props.is_added) {
            setTimeout(() => {
                window.location.href = "/districts-list"
            }, 3000);
        }

        const { handleSubmit, submitting } = this.props;
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add District/Area </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">District/Area add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">District/Area Information</h3>
                        <div className="float-right">
                            <Link to="/districts-list" className="btn btn-info float-right text-white"><b>
                                Back</b>
                            </Link>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="District Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddDistrict.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchDistrict: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

    districts: state.districts.districts,
    is_added: state.customers.is_added


});


AddDistrict = reduxForm({
    form: 'AddDistrict',
}
)(AddDistrict);


export default connect(mapStateToProps, { fetchDistrict, addData })(AddDistrict);
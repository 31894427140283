import {
    LOADING_ORDERS,
    FETCH_ORDERS,
    DELETE_ORDER,
    UPDATE_ORDER,
    NOT_FOUND_ORDER,
    DETAIL_ORDER, FETCH_PAYMENT_METHODS,
    EDIT_PAYMENT_METHOD, DETAIL_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD, LOADING_PAYMENT_METHOD,
    ADD_PAYMENT,
    ADD_ORDER,
    NOT_FOUND_PRIMARY_CATEGORY,
    NOT_FOUND_PAYMENT

} from './types';

import {
    GET_PAYMENT_METHOD_URL, GET_ORDER_URL
} from '../constants/api'
import { SearchURLGenerate } from '../constants/CustomMethod'

import { toast } from 'react-toastify'

import axios from 'axios';

export const fetchOrders = (pageNumber, parameters = {},searchurl=false) => (dispatch, getState) => {
    dispatch({ type: LOADING_ORDERS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    var search_key = ["order_no","order_date_from", "order_date_to", 'customer__phone_no',"order_status"]
    var search_url = SearchURLGenerate(parameters, search_key);
    if (searchurl===true){
        search_url=''
    }

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_ORDER_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            //alert(JSON.stringify(parameters))
            var search_url = SearchURLGenerate(parameters, search_key);
//             alert(search_url)
            url = url + '?page=' + pageNumber+'&'+search_url
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_ORDERS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_ORDER });

    }
};


// Search Order 
export const searchOrderNo = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_ORDERS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }


   

        axios.get(GET_ORDER_URL +'?search='+ value, { headers, })
            .then(response => {
                  // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_ORDERS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
   
};

export const fetchPaymentMethods = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_PAYMENT_METHOD });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_PAYMENT_METHOD_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_PAYMENT_METHODS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_PAYMENT });

    }
};


// Delete Payment Method
export const deletePaymentMethod = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(GET_PAYMENT_METHOD_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_PAYMENT_METHOD,
                payload: id,
            });
            toast.success('Payment Method has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

// Payment Method Detail
export const paymentMethodDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_PAYMENT_METHOD_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_PAYMENT_METHOD,
                payload: response.data,
            })
        })
};

// export const rideCategoryDetail = id => (dispatch, getState) => {
//     // alert(id)
//     let headers = {"Content-Type": "application/json"};

//     let {token} = getState().auth;
//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//     }

//     let url = GET_RIDE_CATEGORY_URL + `${id}/`;

//     axios.get(url, {headers})
//         .then(response => {
//             //  alert(JSON.stringify(response.data.districts.name))
//             dispatch({
//                 type: DETAIL_RIDE_CATEGORY,
//                 payload: response.data,
//             })

//         })
// };

// Order Detail
export const orderDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_ORDER_URL + `${id}/`;
    axios.get(url, { headers })
        .then(response => {

            // console.log('partner detail', response.data)
            dispatch({
                type: DETAIL_ORDER,
                payload: response.data,
            })

        })
};


// Delete Order
export const deleteOrder = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(GET_ORDER_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_ORDER,
                payload: id,
            });
            toast.success('Order has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

// // Ride Category Update

// export const updateRideCategory = (id, data) => (dispatch, getState) => {
//     // console.log('clint side partner-data', partner_id, partner_data);
//     let headers = {"Content-Type": "application/json"};

//     let {token} = getState().auth;
//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//     }

//     let form_data = {
//         'districts':data.districts,
//         'name':data.name,
//         'base_fare':data.base_fare,
//         'km_price':data.km_price,
//         'min_fare':data.min_fare,
//         'waiting_charge':data.waiting_charge
// };



//     axios.put(BASE_URL + `api/v1/ride-category/${id}/`, form_data, {headers})
//         .then(response => {
//             // alert(JSON.stringify(response.data))
//             // console.log('update',response.data)
//             dispatch({

//                 type: UPDATE_RIDE_CATEGORY,
//                 payload: response.data
//             });
//             alert("Ride Category data updated successfully");
//             // toast.success('Ride Category data updated successfully')
//             // console.log('Success')
//         })
//         .catch(error => {
//             console.log(error);
//             toast.error('Please try again')
//         })
// };


// // Delete Ride Category

// export const deleteRideCategory = id => (dispatch, getState) => {
//     let headers = {"Content-Type": "application/json"};

//     let {token} = getState().auth;
//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//     }

//     axios.delete(BASE_URL + `api/v1/ride-category/${id}/`, {headers})
//         .then(response => {
//             dispatch({
//                 type: DELETE_RIDE_CATEGORY,
//                 payload: id,
//             });
//             toast.success('Ride Category deleted successfully');
//             console.log(response.data)
//         })
//         .catch(error => {
//             console.log(error)
//         })
// };



// // Delete Partner
// export const deletePartner = id => (dispatch, getState) => {
//     let headers = {"Content-Type": "application/json"};

//     let {token} = getState().auth;
//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//     }

//     axios.delete(BASE_URL + `api/v1/partners/${id}/`, {headers})
//         .then(response => {
//             dispatch({
//                 type: DELETE_PARTNER,
//                 payload: id,
//             });
//             toast.success('Partner deleted successfully');
//             console.log(response.data)
//         })
//         .catch(error => {
//             console.log(error)
//         })
// };

// // ADD Partner
// export const addPartner = partner_data => (dispatch, getState) => {

//     // console.log('clint side partner-data', partner_data);
//     let headers = {"Content-Type": "multipart/form-data"};

//     let {token} = getState().auth;
//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//         // headers["content-type"] = 'multipart/form-data';
//     }

//     let tax_expire_date = convert(partner_data.tax_expire_date);
//     let fitness_expire_date = convert(partner_data.fitness_expire_date);

//     let form_data = new FormData();

//     if (partner_data.driving_license){
//         form_data.append('driving_license', partner_data.driving_license,partner_data.driving_license.name)
//     }
//     if (partner_data.tax_registration) {
//         form_data.append('tax_registration', partner_data.tax_registration, partner_data.tax_registration.name)
//     }
//     if (partner_data.vehicle_photo) {
//         form_data.append('vehicle_photo', partner_data.vehicle_photo, partner_data.vehicle_photo.name)
//     }
//     if (partner_data.fitness_registration) {
//         form_data.append('fitness_registration', partner_data.fitness_registration, partner_data.fitness_registration.name)
//     }
//     if (partner_data.vehicle_insurance) {
//         form_data.append('vehicle_insurance', partner_data.vehicle_insurance, partner_data.vehicle_insurance.name)
//     }

//     form_data.append('city', partner_data.city);
//     form_data.append('agent', partner_data.agent);
//     form_data.append('driving_licenseno', partner_data.driving_licenseno);
//     form_data.append('invitation_code', partner_data.invitation_code);
//     form_data.append('fitness_expire_date', fitness_expire_date);
//     form_data.append('tax_expire_date', tax_expire_date);
//     form_data.append('year_of_manufacture_engine_no', partner_data.year_of_manufacture_engine_no);
//     form_data.append('vehicle_registration_no', partner_data.vehicle_registration_no);
//     form_data.append('total_amount', partner_data.total_amount);
//     form_data.append('partner_id', partner_data.partner_id);
//     form_data.append('average_rating', partner_data.average_rating);
//     form_data.append('present_address', partner_data.present_address);
//     form_data.append('user', JSON.stringify(partner_data.user));

//     axios.post(BASE_URL + "api/v1/partner/partner-create/", form_data, {headers})
//         .then(response => {
//             toast.success("Partner Created Successfully")
//             console.log(response.data)
//             // dispatch({
//             //     type: ADD_PARTNER,
//             //     payload: response.data
//             // })
//         })
//         .catch(error =>{
//             toast.error("Please try again")
//             console.log(error)
//         }

//         )
// };


// // Partner Update
// export const updatePartner = (partner_id, partner_data) => (dispatch, getState) => {
//     // console.log('clint side partner-data', partner_id, partner_data);
//     let headers = {"Content-Type": "multipart/form-data"};

//     let {token} = getState().auth;
//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//     }

//     let tax_expire_date = convert(partner_data.tax_expire_date);
//     let fitness_expire_date = convert(partner_data.fitness_expire_date);

//     let form_data = new FormData();
//     // if (partner_data.driving_license){
//     //     form_data.append('driving_license', partner_data.driving_license, partner_data.driving_license.name)
//     // }
//     // if (partner_data.tax_registration) {
//     //     form_data.append('tax_registration', partner_data.tax_registration, partner_data.tax_registration.name)
//     // }
//     // if (partner_data.vehicle_photo) {
//     //     form_data.append('vehicle_photo', partner_data.vehicle_photo, partner_data.vehicle_photo.name)
//     // }
//     // if (partner_data.fitness_registration) {
//     //     form_data.append('fitness_registration', partner_data.fitness_registration, partner_data.fitness_registration.name)
//     // }
//     // if (partner_data.vehicle_insurance) {
//     //     form_data.append('vehicle_insurance', partner_data.vehicle_insurance, partner_data.vehicle_insurance.name)
//     // }
//     form_data.append('city', partner_data.city);
//     form_data.append('agent', partner_data.agent);
//     form_data.append('driving_licenseno', partner_data.driving_licenseno);
//     form_data.append('invitation_code', partner_data.invitation_code);
//     form_data.append('fitness_expire_date', fitness_expire_date);
//     form_data.append('tax_expire_date', tax_expire_date);
//     form_data.append('year_of_manufacture_engine_no', partner_data.year_of_manufacture_engine_no);
//     form_data.append('vehicle_registration_no', partner_data.vehicle_registration_no);
//     form_data.append('total_amount', partner_data.total_amount);
//     form_data.append('partner_id', partner_data.partner_id);
//     form_data.append('present_address', partner_data.present_address);
//     form_data.append('average_rating', partner_data.average_rating);


//     // if (partner_data.driving_license){
//     //     form_data.append('driving_license', partner_data.driving_license)
//     // }
//     // if (partner_data.tax_registration) {
//     //     form_data.append('tax_registration', partner_data.tax_registration, partner_data.tax_registration.name)
//     // }
//     // if (partner_data.vehicle_photo) {
//     //     form_data.append('vehicle_photo', partner_data.vehicle_photo, partner_data.vehicle_photo.name)
//     // }
//     // if (partner_data.fitness_registration) {
//     //     form_data.append('fitness_registration', partner_data.fitness_registration, partner_data.fitness_registration.name)
//     // }
//     // if (partner_data.vehicle_insurance) {
//     //     form_data.append('vehicle_insurance', partner_data.vehicle_insurance, partner_data.vehicle_insurance.name)
//     // }
//     // form_data.append('driving_license', data.driving_license, data.driving_license.name);
//     // form_data.append('tax_registration', data.tax_registration, data.tax_registration.name);
//     // form_data.append('vehicle_photo', data.vehicle_photo, data.vehicle_photo.name);
//     // form_data.append('fitness_registration', data.fitness_registration, data.fitness_registration.name);
//     // form_data.append('vehicle_insurance', data.vehicle_insurance, data.vehicle_insurance.name);

//     form_data.append('user', JSON.stringify(partner_data.user));


//     axios.put(BASE_URL + `api/v1/partner/partner-update/${partner_id}/`, form_data, {headers})
//         .then(response => {

//             // console.log(response.data)
//             dispatch({
//                 type: UPDATE_PARTNER,
//                 payload: response.data
//             });
//             toast.success('Partner data updated successfully')
//             console.log('Success')
//         })
//         .catch(error => {
//             console.log(error);
//             toast.error('Please try again')
//         })
// };
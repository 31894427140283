import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { addData } from '../../../actions/CustomerActions';

import { fetchContactInfo } from '../../../actions/ContactInfoAction';

import { toast } from "react-toastify";
import { CONTACT_INFO_URL } from "../../../constants/api";


momentLocalizer(moment);



const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddContactInfo extends Component {
    state = {

        id: 0,
        phone_no: '',
        first_name: '',
        sellers: [],
        last_name: '',
        email: '',
        get_seller: false,
    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_getting) {
            if (nextProps.is_getting !== prevState.get_seller) {
                var seller = nextProps.sellers[0]
                return {
                    first_name: seller.user.first_name,
                    last_name: seller.user.last_name,
                    email: seller.user.email,
                    id: seller.id,
                    phone_no: seller.user.phone_no,
                    sellers: seller,
                    get_seller: true
                }
            }
        }
        return null
    }
    onChangeText = (e) => {

        this.setState({ [e.target.name]: e.target.value })

    }
    componentDidMount() {
        this.props.fetchContactInfo(1)
    }


    submit(event) {
        console.log(event)
        let data = {
            'phone_no': event.phone_no,
            'email': event.email,
            'address': event.address
        }


        this.props.addData(data, CONTACT_INFO_URL, "Contact Info")



    }

    render() {

        const { handleSubmit, submitting } = this.props;
        if (this.props.is_added) {
            setTimeout(function () {
                window.location.href = '/contact-info-list'
            },
                2000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Contact Information </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Add Contact Information</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Add Contact Information</h3>
                        {/* <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div> */}
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-md-6">
                                    <Field
                                        name="phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Phone No"
                                    // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                    //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="address"
                                        type="text"
                                        component={renderTextArea}
                                        label="Address"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>







                            </div>


                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddContactInfo.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchContactInfo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    contacts_info: state.contacts_info.contacts_info,
    contacts_info: state.contacts_info.is_added



});


AddContactInfo = reduxForm({
    form: 'AddContactInfo',
    // enableReinitialize: true

}
)(AddContactInfo);


export default connect(mapStateToProps, { fetchContactInfo, addData })(AddContactInfo);

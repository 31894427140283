import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2'
import {CheckPermisionAction} from "../../constants/CustomMethod"
import { Field, reduxForm } from "redux-form";
import { fetchBundleProduct, deleteBundleProduct } from "../../actions/BundleAction"
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import actions from "redux-form/lib/actions";
import {  BulkAction } from '../../actions/ProductAction';
import { BASE_URL, BUNDLE_PRODUCT_BULK_URL, TAX_URL } from '../../constants/api'

class BundleProductList extends Component {

    state = {
        get_product: false,
        bundle_products: []
    }
    componentDidMount() {
        this.props.fetchBundleProduct()
        //  this.props.directorDetail(id);
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_getting) {

            if (nextProps.is_getting !== prevState.get_product) {
                for (var i = 0; i < nextProps.bundles.length; i++) {
                    console.log(nextProps.bundles[i])
                }
                return {
                    bundle_products: nextProps.bundles,
                    get_product: true
                }
            }
        }
        return null
    }
    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.title + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteBundleProduct(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.title + '.',
                        'success'
                    )
                }
            }
        })
    }

    openDeclinealert(message = 'Decline', data) {

        Swal.fire({
            text: 'Are you want to ' + message + ' ' + data.title + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, ' + message + ' it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.BulkAction(
                        { 'id': data.id, 'action': message }, BUNDLE_PRODUCT_BULK_URL
                    )
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        message + '!',
                        'Your Data has been  ' + message + data.title + '.',
                        'success'
                    )
                }
            }
        })
    }
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchBundleProduct(pageNo)
    }

    render() {
        const { bundle_products } = this.state
        const {permissions} = this.props
        const page = Math.ceil(this.props.count / 10)
        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Bundle Product List</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link className="text-info" to="/">
                                            Dashboard
                    </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Bundle Product List
                  </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Create Bundle Product</h3>
                                <div className="float-right">
                                {CheckPermisionAction(permissions,'Inventory','add_bundle_product')?

                                    <Link to="/add-bundle-product" className="btn btn-success text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Bundle Product</b>
                                    </Link>
                                    :null}

                                </div>
                            </div>
                            {CheckPermisionAction(permissions,'Inventory','view_bundle_product_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Product Title</th>
                                            <th>Total Quantity</th>
                                            <th>Total Regular Price</th>
                                            <th>Total Price</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ?
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>
                                            : bundle_products.length > 0 && bundle_products.map((bundle, index) => (
                                                <tr key={bundle.id}>

                                                    <td>{bundle.title} </td>
                                                    <td>{bundle.total_quantity} </td>
                                                    <td>{bundle.total_regular_price} </td> 
                                                    <td>{bundle.total_price} </td>
                                                    <td>
                                                        <div class="btn-group">
                                                    {CheckPermisionAction(permissions,'Inventory','bundle_options')?

                                                            <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                    :null}
                                                           <div class="dropdown-menu">
                                                                {/* {'/bundle-product-update/' + bundle.id} */}
                                                                <a href={'/bundle-product-update/' + bundle.id} class="dropdown-item">Edit</a>
                                                                <div class="dropdown-divider"></div>

                                                                
                                                                {bundle.status !==true?
                                                                <>
                                                                <a href="#" onClick={() => this.openDeclinealert("Approve", bundle)} className="dropdown-item">Approve</a>
                                                                <div class="dropdown-divider"></div>
                                                                </>
                                                                :
                                                                <>
                                                                <a href="#" onClick={() => this.openDeclinealert("Decline", bundle)} className="dropdown-item">Decline</a>
                                                                <div class="dropdown-divider"></div>
                                                                </>
                                                                }
                                                                <a href="#" onClick={() => this.opensweetalert(bundle)} class="dropdown-item">Delete</a>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {/* <td>{product.product_sku}</td>
                                                    <td>{ product.vendor ? product.vendor.user.first_name +' '+ product.vendor.user.last_name : '' }</td>
                                                    <td>{product.is_feature_product ? 'Yes' : 'No' }</td>
                                                    <td>{product.product_type==1 ? 'Physical' : 'Virtual' }</td> */}



                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            :null}
                            <div className="card-footer clearfix">
                                {page ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>
                            {/* <div className="card-footer">
                                <Link
                                    to="/add-digital-product"
                                // className="btn btn-primary float-right text-white"
                                >
                                    <button
                                        type="submit"
                                        className="btn btn-success float-right"
                                    //disabled={submitting}
                                    >
                                        <i className="fa fa-save "></i>Create Digital Product
                                </button>
                                </Link>
                            </div> */}
                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
            </div>
        );
    }
}

BundleProductList.propTypes = {
    fetchBundleProduct: PropTypes.func.isRequired,
    deleteBundleProduct: PropTypes.func.isRequired,
    BulkAction: PropTypes.func.isRequired,

    // director: PropTypes.isRequired,
};

const mapStateToProps = state => ({
    bundles: state.bundles.bundles,
    is_loading: state.bundles.is_loading,
    is_getting: state.bundles.is_getting,
    permissions:state.userpermissions.userpermissions


});


BundleProductList = reduxForm({
    form: "AddBundleProductForm",
})(BundleProductList);
export default connect(mapStateToProps, { fetchBundleProduct, deleteBundleProduct,BulkAction })(BundleProductList);

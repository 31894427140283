import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { shippingClassDetail, updateData } from "../../../actions/ShippingClassActions"
import {fetchShippingZones} from '../../../actions/ShippingZoneActions'

import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import { toast } from "react-toastify";
import { SHIPPING_CLASS_URL, GET_PRIMARY_CATEGORY_URL } from "../../../constants/api";


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

// const submitToServer = (data) => {

//     console.log('data', data);


//     var form_data = { "name": data.name };

//     console.log('data', form_data);


//     let url = CUSTOMER_URL;

//     let token = localStorage.getItem('token');
//     // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
//     console.log(token);
//     axios.post(url, form_data,
//         {
//             headers: {
//                 'content-type': 'application/json',
//                 'Authorization': `Token ${token}`
//             }

//         })
//         .then(res => {
//             console.log(res.data);
//             // dispatch(reset('PartnerAddForm'))
//             toast.success('Successfully added a new Primary Category.')
//         })
//         .catch(err => console.log(err))
// };

// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
    <div>
        <label className="text-sm">{label}</label>
        <DateTimePicker
            onChange={onChange}
            format="DD-MM-YYYY"
            time={showTime}
            value={!value ? null : new Date(value)}
            placeholder={label}
        />
    </div>;


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class ShippingClassUpdate extends Component {

    componentDidMount() {
        this.props.fetchShippingZones(0)
        // console.log(this.props.campaignDetail(id))
        let id = this.props.match.params.id
        this.props.shippingClassDetail(id)

    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }

    submit(event) {
        // const { reset } = this.props;
        // return sleep(1000)
        //     .then(() => {
        //         submitToServer(values);
        //         reset()
        //     })
        var form_data = {
            name: event.name,
            slug: event.slug,
            description: event.description,
            rate_amount: event.rate_amount
        }
        // var form_data = new FormData();

        // form_data.append("tax_class_name", event.tax_class_name ? event.tax_class_name : '');
        // form_data.append("description", event.tax_class_name ? event.tax_class_name : '');
        // form_data.append("rate", event.tax_class_name ? event.tax_class_name : '');
        // form_data.append("till_date", event.till_date ? event.till_date : '');
        // if (event.user_photo != '') {
        //     form_data.append("user_photo", event.user_photo ? event.user_photo : '');
        // }
        let id = this.props.match.params.id;
        this.props.updateData(event, SHIPPING_CLASS_URL + id + '/', "Shipping Class")




    }

    render() {


        const { handleSubmit, submitting, } = this.props;
        if (this.props.is_updated) {
            setTimeout(() => {
                window.location.href = "/shipping-class-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Shipping Class Update </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Shipping Class Update</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Shipping Class Information</h3>
                        <div className="float-right">
                            <Link to="/shipping-class-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                
                                <div className="col-md-6">
                                <label>Shipping Zone</label>
                                    <Field
                                        name="shipping_zone"
                                        component="select"
                                        className="form-control"

                                    // onChange={this.handleSelectedValues(idx)}
                                    >
                                        <option value="">--Select Shipping Zone--</option>

                                        {this.props.shipping_zones.map((shipping_zone, index) => (
                                        <option key={index} value={shipping_zone.id}>{shipping_zone.zone_name}</option>

                                        ))}
                                    </Field>
                                </div>

                                <div className="col-md-6">
                                    <Field
                                        name="rate_amount"
                                        type="text"
                                        component={renderField}
                                        label="Shipping Rate"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Status</label>
                                    <Field
                                        name="status"
                                        component="select"
                                        className="form-control" 
                                    >
                                        <option value="1" selected={this.props.shipping_classes.status===true ? true :false}>Active</option>
                                        <option value="0" selected={this.props.shipping_classes.status===false ? true :false}>Inactive</option>
                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="min_weight"
                                        type="text"
                                        component={renderField}
                                        label="Product Minimum Weight"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="max_weight"
                                        type="text"
                                        component={renderField}
                                        label="Product Maximum Weight"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>


                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Update
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


ShippingClassUpdate.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchShippingZones: PropTypes.func.isRequired,

    shippingClassDetail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({


    shipping_classes: state.shipping_classes.shipping_classes,
    is_added: state.shipping_classes.is_added,
    shipping_zones: state.shipping_zones.shipping_zones,

    is_updated: state.shipping_classes.is_updated,
    initialValues: state.shipping_classes.shipping_classes

});


ShippingClassUpdate = reduxForm({
    form: 'ShippingClassUpdate',
    enableReinitialize: true
}
)(ShippingClassUpdate);


export default connect(mapStateToProps, { fetchShippingZones,updateData, shippingClassDetail })(ShippingClassUpdate);


import {
    FETCH_GROUPS,
    LOADING_GROUP,
    NOT_FOUND_GROUP,
    LOADING_PERMISSIONS,
    LOADING_USER_PERMISSIONS,
    FETCH_USER_PERMISSIONS,
    FETCH_PERMISSIONS
} from '../actions/types';


const groupInitiate={is_fetching: false, groups: [],
    count: null,
    next: null,
    is_loading: false 

}
const permissionInitiate={is_fetching: false, permissions: [],
    count: null,
    next: null,
    is_loading: false 

}

export const getGroupInfo = (state =groupInitiate , action) => {
    switch (action.type) {
       
        case LOADING_GROUP:
            return {
                ...state,
                groups:[],
                is_fetching: true
            };
        case FETCH_GROUPS:
            return {
                ...state,
                groups: action.payload,
                is_fetching: true
            };
        default:
            return state;
    }
}

export const getAllPermissionReducer = (state =permissionInitiate , action) => {
    switch (action.type) {
       
        case LOADING_PERMISSIONS:
            return {
                ...state,
                permissions:[],
                is_fetching: false
            };
        case FETCH_PERMISSIONS:
          
            return {
                ...state,
                permissions: action.payload,
                is_fetching: true
            };
        default:
            return state;
    }
}

export const getUserPermissionReducer = (state ={userpermissions:[],
    is_fetching_permission: false} , action) => {
    switch (action.type) {
       
        case LOADING_USER_PERMISSIONS:
            return {
                ...state,
                userpermissions:[],
                is_fetching_permission: false
            };
        case FETCH_USER_PERMISSIONS:
            return {
                ...state,
                userpermissions: action.payload,
                is_fetching_permission: true
            };
        default:
            return state;
    }
}
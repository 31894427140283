import React from 'react'
import { connect } from "react-redux";
import { reduxForm } from 'redux-form';

import PrivilegeList from '../../components/Privileges/PrivilegeList'

class Privileges extends React.Component {
  render() {
    return (
      <PrivilegeList />
    );
  }
  }



  Privileges.propTypes = {
   // otp: PropTypes.object,
  
  };
  const PrivilegesContainer = reduxForm({
    form: "privilegelist"
  })(Privileges);
  
  const mapStateToProps = state => ({
  //  auth: state.auth,
  
  });
  
  const mapDispatchToProps = dispatch => ({
    // userOTPLogin: (mobile_no) => dispatch(userOTPLogin(
    //   mobile_no
    // )),
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(PrivilegesContainer);

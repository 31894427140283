import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { getNumberToMonth, exportToCSV } from "../../../constants/CustomMethod"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";

import { BulkAction } from '../../../actions/ProductAction';
import { fetchSalesReports } from '../../../actions/ReportsActions';
import { Button } from "react-bootstrap";
import { reduxForm } from "redux-form";
import ReactPaginate from 'react-paginate';
import {

    dateYMD
} from "../../../constants/Validate";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
    required = false,
}) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            {required ? <span className="requiredField">*</span> : ""}
            <div>
                <input
                    {...input}
                    placeholder={label}
                    type={type}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};

// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
    <div>
        <label className="text-sm">{label}</label>
        <DateTimePicker
            onChange={onChange}
            format="DD-MM-YYYY hh:mm A"
            time={showTime}
            value={!value ? null : new Date(value)}
            placeholder={label}
        />
    </div>;




class AgentProductSaleReports extends Component {
    constructor() {
        super();
        this.handleModalShow = this.handleModalShow.bind(this)
        this.handleClose = this.handleClose.bind(this)

        this.handleDiscountClose = this.handleDiscountClose.bind(this)
        this.handleDiscountModalShow = this.handleDiscountModalShow.bind(this)
        this.handleDateChange = this.handleDateChange.bind(this)


    }


    state = {
        show: false,
        discountshow: false,
        product_name: "",
        product_sku:'',
        stock_quantity:'',
        sales_reports: [],
        from_date:'',
        to_date:'',
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
        bulk: false,
        allchecked: false,
        get_sales_report: false,
        checkedvalues: [],
        allchecked: false,
        bulk: false,
        load: false


    };

    exportExcel = () => {
        let filename = "Product Stock Report"
        let heading = [
            {
                product_name: "Product Name",
                product_sku: "Product SKU",
                stock_quantity: "Stock Quantity",
                
            }
        ];
        let header = [
            'product_name',
            'product_sku',
            'stock_quantity',
          

        ];
        var product_stock_report_data = this.state.product_stock_reports
        if (product_stock_report_data.length > 0) {
            // var data = []
            // for (let i = 0; i < product_stock_report_data.length; i++) {
            //     product_stock_report_data[i] = product_stock_report_data[i]
            //     data.push(product_stock_report_data)
            // }
            
            exportToCSV(product_stock_report_data, filename, [], heading, header)
        }
    }

    handleClose() {
        this.setState({ show: false });
    }
    handleModalShow() {

        this.setState({ show: true });
    }

    handleDiscountClose() {
        this.setState({ discountshow: false });
        window.location.reload()
    }
    handleDiscountModalShow() {

        this.setState({ discountshow: true });
    }
    handleDateChange(value, e) {
        this.setState({ discount_from_date: value });
    }
    handleToDateChange(value, e) {
        this.setState({ discount_to_date: value });
    }
    componentDidMount() {
        // alert(JSON.stringify(this.state.product_stock_reports))
        // this.props.fetchSalesReports(1);
    }









    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.get_sales_reports) {
            if (nextProps.get_sales_reports !== prevState.get_sales_report) {
                var sales_reports = []
                for (var i = 0; i < nextProps.sales_reports.length; i++) {
                    Object.assign(nextProps.sales_reports[i], { 'checked': false });
                    sales_reports.push(nextProps.sales_reports[i])
                }
                console.log(sales_reports)
                return {
                    sales_reports: sales_reports,
                    get_sales_report: true
                }
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.sales_reports != this.props.sales_reports) {
            this.setState({ sales_reports: this.props.sales_reports })
        }

    }







    onChange = e => this.setState({ [e.target.name]: e.target.value });
    onChangeDateTo = (date) => {

        this.setState({ to_date: dateYMD(date) })

    }
    onChangeDateFrom = (date) => {

        this.setState({ from_date: dateYMD(date) })

    }



    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchSalesReports(pageNo)
    }
    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };
    handleUploadFile = (event) => {
        this.setState({
            [event.target.name]: event.target.files[0],

        })



    }
    handleInputText = (event) => {
        this.setState({
            [event.target.name]: event.target.value,

        })
    }
    closeModalHandler = () => {
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { product_sku, product_name } = this.state;

        this.props.fetchSalesReports('', this.state);
        this.setState({ get_sales_report: false, hideButton: true })

    };

    render() {
        // alert(JSON.stringify(this.state.products))
        const { permissions } = this.props
        const { show, bulk, allchecked, checkedvalues, sales_reports,discountshow } = this.state;
        const page = Math.ceil(this.props.count / 10)



        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/product-stock-report'
            },
                1000);
        }

        if (this.props.is_uploaded) {
            window.location.href = '/product-stock-report'
        }


        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Reports </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Agent Product Sales Report</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">



                    <div className="col-12">
                        <div className="card">

                            <div className="card-header">
                                <h3 className="card-title">Agent Product Sales Report</h3>

                                {/* <div className="float-right">
                                    {CheckPermisionAction(permissions,'Restaurant','add_restaurant')?
                                        <>
                                    <Link to="/add-transaction" className="btn btn-success text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Transaction</b></Link>&nbsp;
                                    </>
                                     :null
                                    } 
                                </div> */}
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-3" name="product_name" type="text"
                                            onChange={this.onChange} placeholder="Product Name"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-3" name="product_sku" type="text"
                                            onChange={this.onChange} placeholder="Product SKU"
                                            aria-label="Search" />
                                       
                                        <DateTimePicker
                                            // onChange={this.onChangeDate}
                                            onChange={date => this.onChangeDateFrom(date)}
                                            name="from_date"
                                            format="DD-MM-YYYY"
                                            time={false}
                                            // value={this.state.order_search_date}
                                            placeholder="Date from"
                                        />

                                        <DateTimePicker
                                            // onChange={this.onChangeDate}
                                            onChange={date => this.onChangeDateTo(date)}
                                            name="to_date"
                                            format="DD-MM-YYYY"
                                            time={false}
                                            // value={this.state.order_search_date}
                                            placeholder="Date to"
                                        />




                                        {/* {CheckPermisionAction(permissions,'Restaurant','search')? */}

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>

                                        <Button
                                            variant="warning"
                                            onClick={e => this.exportExcel()}
                                        >
                                            Export XLSX
                                        </Button>
                                        {/* :null
                                        }  */}
                                    </form>
                                </div>


                            </div>
                            <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Action--</option>
                                            <option value="1" >Activated</option>
                                            <option value="0">Deactivated</option>
                                            {/* <option value="2">Delete</option> */}


                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>

                            {/* {CheckPermisionAction(permissions,'Restaurant','view_restaurant_list')? */}
                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                         

                                            {/* <th>Customer Name</th>
                                            <th> Order complete</th> */}
                                            {/* <th> Quantity</th> */}
                                           

                                           
                                        </tr>
                                    </thead>
                                    <tbody>
                                      
                                        {this.props.is_loading ?
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>
                                            : sales_reports.length != undefined && sales_reports.map((sales_report, index) => (
                                                <tr key={sales_report.id}>


                                                    <td>{sales_report.product_name}</td>
                                                    <td>{sales_report.product_sku}</td>
                                                    {/* <td>{sales_report.stock_quantity}</td> */}
                                                    

                                                  
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                         
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">

                                {/*<span className="page-link" onClick={() => this.props.fetchstaffs(this.props.next)}>&laquo;</span>*/}
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }

                            </div>




                        </div>

                    </div>
                </div>

            </div>
        )
    }
}

AgentProductSaleReports.propTypes = {
    fetchSalesReports: PropTypes.func.isRequired,
    BulkAction: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    sales_reports: state.sales_reports.sales_reports,
    count: state.sales_reports.count,
    next: state.sales_reports.next,
    is_loading: state.sales_reports.is_loading,
    is_uploaded: state.sales_reports.is_uploaded,
    bulk: state.sales_reports.bulk,
    bulk: state.products.bulk,
    get_sales_reports: state.sales_reports.get_sales_reports,
    auth: state.auth,
    permissions: state.userpermissions.userpermissions
});

AgentProductSaleReports = reduxForm({
    form: "AgentProductSaleReports",
})(AgentProductSaleReports);
export default connect(mapStateToProps, { fetchSalesReports, BulkAction })(AgentProductSaleReports);

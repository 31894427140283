import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";
import { required } from '../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { updateData } from '../../actions/ProductAction';
import { fetchCuisine, RestaurantDetail } from "../../actions/RestaurantActions"
import { GET_STOCK, BASE_URL } from "../../constants/api";
import { sumTotalAmount, sumTotalQuantity, sumTotalProductWeigth } from '../../constants/CustomMethod'
import { fetchWarehouse } from '../../actions/WarehouseAction';
import { searchProducts } from '../../actions/ProductAction';
import Autosuggest from 'react-autosuggest';
import { fetchBrandsAll } from '../../actions/BrandsActions';
import { fetchSellers } from '../../actions/SellerActions';
import { fetchProductUnit } from '../../actions/ProductUnitAction'



momentLocalizer(moment);


// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
  const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
  return (
    <div>
      <label className="text-sm">{label}</label>
      <div>
        <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
        {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
          <span>{warning}</span>))}
      </div>
    </div>
  )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
  const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
  return (
    <div className="form-group">
      <label className="text-sm">{label}</label>
      <div>
        <input {...input} placeholder={label} type={type} className={className} />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
  input: { value: omitValue, onChange, label, onBlur, ...inputProps },
  meta: { omitMeta, touched, error, warning },
  ...props
}) => {
  const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
  return (
    <div>
      <div className="form-group">
        <input
          onChange={adaptFileEventToValue(onChange)}
          onBlur={adaptFileEventToValue(onBlur)}
          type="file"
          {...props.input}
          {...props}
          className={className}
        />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>

  );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class StockUpdate extends Component {

  state = {
    value: '',
    oderList: [],
    totalAmount: 0,
    totalQuantity: 0,
    totalProductWeigth: 0,
    discount_amount: 0,
    warehouse_options: [],
    warehouse_list: []

  }

  // this.props.getRides(0)
  componentDidMount() {

    let id = this.props.match.params.id
    this.props.RestaurantDetail(id)
    this.props.fetchDistrict()
    this.props.fetchCuisine()
    this.props.fetchBrandsAll(1);
    this.props.fetchSellers(1);

  }

  // static getDerivedStateFromProps(nextProps, prevState) {
  //   // alert(JSON.stringify(nextProps))
  //   if (nextProps.detail == true) {

  //     if (nextProps.detail !== prevState.loadOrder) {
  //       //  alert(JSON.stringify( "Test",nextProps.order.payment_method))
  //       //console.log(nextProps.order)
  //       var product_lists = []
  //       if (nextProps.order.order_product !== undefined) {
  //         var totalQuantity = 0
  //         var totalPrice = 0
  //         var totalProductWeigth = 0

  //         for (var i = 0; i < nextProps.order.order_product.length; i++) {
  //           var data = nextProps.order.order_product[i].product

  //           data['feature_image'] = BASE_URL + nextProps.order.order_product[i].product.feature_image
  //           data['quantity'] = nextProps.order.order_product[i].quantity
  //           data['subtotal'] = nextProps.order.order_product[i].subtotal
  //           data['unit_amount'] = nextProps.order.order_product[i].product_weight
  //           data['new_price'] = nextProps.order.order_product[i].price
  //           totalProductWeigth += nextProps.order.order_product[i].unit_amount
  //           totalQuantity += nextProps.order.order_product[i].quantity
  //           totalPrice += nextProps.order.order_product[i].subtotal
  //           product_lists.push(data)
  //         }
  //         //  alert(JSON.stringify(nextProps.order.payment_method.name))
  //       }
  //       return {
  //         payment_method: nextProps.order.payment_method !== undefined ? nextProps.order.payment_method.name : '',
  //         customer_name: nextProps.order.customer ? nextProps.order.customer.phone_no : '',
  //         oderList: nextProps.order.order_product ? product_lists : [],
  //         totalQuantity: totalQuantity,
  //         totalAmount: totalPrice,
  //         totalProductWeigth: totalProductWeigth,
  //         order_date: nextProps.order.order_date,
  //         payment_status: nextProps.order.payment_status,
  //         loadOrder: true

  //       }
  //     }

  //   }
  //   return null

  // }
  componentWillReceiveProps(nextProps) {
    console.log('componentWillReceiveProps', nextProps);
    if (this.props !== nextProps) {
      this.setState(nextProps);
    }
  }
  onChanges = (index, e) => {
    let orders = [...this.state.oderList]
    // alert(e.target.value)
    orders[index][e.target.name] = e.target.value
    this.setState({ oderList: orders })
  }
  onChangesSubtotal = (index, e) => {
    let orders = [...this.state.oderList]
    orders[index].subtotal = e.target.value;
    // orders[index].subtotal = (e.target.value - orders[index].discount_price) * orders[index].quantity;


    this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders), totalProductWeigth: sumTotalProductWeigth(orders) })
  }
  onChangesProductWeight = (index, e) => {
    let orders = [...this.state.oderList]
    orders[index].unit_amount = e.target.value;
    // orders[index].subtotal = (e.target.value - orders[index].discount_price) * orders[index].quantity;


    this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders), totalProductWeigth: sumTotalProductWeigth(orders) })
  }
  deteteRow = (product) => {
    var products = this.state.oderList.filter((r) => r !== product)
    this.setState({
      oderList: products,
      totalQuantity: sumTotalQuantity(products),
      totalAmount: sumTotalAmount(products),
      totalProductWeigth: sumTotalProductWeigth(products)

    });
  }

  manageQuantity = (index, type = "plus") => {
    let orders = [...this.state.oderList]
    if (type == "plus") {
      orders[index].quantity = orders[index].quantity + 1;
      orders[index].subtotal = orders[index].new_price * orders[index].quantity;

    } else {
      if (orders[index].quantity > 1) {
        orders[index].quantity = orders[index].quantity - 1;
        orders[index].subtotal = orders[index].new_price * orders[index].quantity;

      }
    }
    this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders) })
  }

  componentDidMount() {
    this.props.fetchBrandsAll(1);
    this.props.fetchWarehouse(1);
    this.props.fetchSellers(1);
    this.props.fetchProductUnit(1)

  }
  componentDidUpdate(prevProps, prevState) {
    setTimeout(() => {

    }, 2000)
  }

  // Filter logic
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    this.props.searchProducts(value);
    return inputLength === 0 ? [] : this.props.products.filter(product =>
      product.product_name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  // Suggestion rerender when user types
  onSuggestionsFetchRequested = ({ value }) => {
    //alert(value)
    this.setState({
      products: this.getSuggestions(value)
    });
  };
  // Triggered on clear
  onSuggestionsClearRequested = () => {
    this.setState({
      products: []
    });
  };
  // Trigger suggestions
  getSuggestionValue = product => '';
  // Render Each Option
  renderSuggestion = product => (
    <div>
      {product.product_name}
    </div>
  );
  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

    let { oderList } = this.state;
    suggestion.quantity = 1
    suggestion.subtotal = Number(suggestion.new_price)
    var index = oderList.findIndex(x => x.id == suggestion.id);

    // console.log(suggestion);
    if (index === -1) {
      oderList.push(suggestion)
      var pro_index = oderList.findIndex(x => x.id == suggestion.id);
      oderList[pro_index].product_id = suggestion.id
      oderList[pro_index].price = oderList[pro_index].regular_price
      oderList[pro_index].quantity = oderList[pro_index].quantity

    }
    // else {
    //   oderList[index].quantity = oderList[index].quantity + 1;
    //   oderList[index].subtotal += suggestion.new_price;
    // }
    this.setState({ totalAmount: sumTotalAmount(oderList), totalQuantity: sumTotalQuantity(oderList) })
  };




  // OnChange event handler
  onChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };

  allowVerfied = () => {
    this.setState({ verified: !this.state.verified });
  };


  // OnChange event handler
  onChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };


  submit(event) {
    const { cuisines_values, foodNameDetails, food_names, oderList } = this.state
    var form_data = new FormData();
    // Object.keys(data).forEach(key => form_data.append(key, data[key]));
    form_data.append('stock_details', JSON.stringify(oderList));
    form_data.append('total_price', event.total_price);
    form_data.append('total_quantity', event.total_quantity);
    form_data.append('warehouse_id', event.warehouse_id);
    form_data.append('brand', event.brand);
    form_data.append('unit_id', event.unit_id);
    form_data.append('product_id', event.product_id);
    form_data.append('price', event.regular_price)

    if (event.memo_file) {
      form_data.append('memo_file', event.memo_file, event.memo_file.name)
    }






    let id = this.props.match.params.id;
    this.props.updateData(form_data, GET_STOCK + id + '/', "Stock")

  }
  onChangePaymentType = (e) => {

    this.setState({ [e.target.name]: e.target.value })
    // alert(e.target.value)

  }
  render() {

    const { allowProductSize, foodNameDetails, food_names, cuisines_values, value, oderList } = this.state
    const { handleSubmit, submitting, attributes, cuisines, foodnames } = this.props;
    // Option props
    const inputProps = {
      placeholder: 'Type Product Name or Product Sku Here',
      value,
      type: "search",
      onChange: this.onChange
    };
    if (this.props.is_updated) {
      setTimeout(function () {
        window.location.href = '/stock-list'
      },
        3000);
    }
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark"> Stock update</h1>
              </div>
              {/*<!-- /.col --> */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                  <li className="breadcrumb-item active"> Stock update</li>
                </ol>
              </div>
              {/*<!-- /.col --> */}
            </div>
            {/*<!-- /.row --> */}
          </div>
          {/*<!-- /.container-fluid --> */}
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Stock Information</h3>
            <div className="float-right">
              <Link to="/stock-list" className="btn btn-primary float-right text-white"><b>
                <i className="fa fa-backward"></i>
                Back To List</b>
              </Link>

            </div>
          </div>
          <form onSubmit={handleSubmit(this.submit.bind(this))}>
            <div className="card-body">
              <div className="row">

                {/* <div className="col-md-6">
                                    <Field
                                        name="brand"
                                        type="text"
                                        component={renderField}
                                        label="brand"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                <div className="col-md-6">

                <img src={BASE_URL+ this.props.warehouses.memo_file} height="100px" width="100px" alt="No Image" />
                </div>

                <div className="col-md-6">
                  <label className="text-sm">Upload Memo File</label>
                  <Field
                    name="memo_file"
                    type="file"
                    component={FileInput}
                    label="Memo File"
                    // validate={required}

                  // warn={alphaNumeric}
                  />
                </div>
                <div className="col-md-6">
                    <label>Select Brand</label>
                    <span className="requiredField">*</span>
                    <Field name="brand" component="select" className="form-control"
                        validate={required}>
                        <option value="">Select Brand</option>
                        {this.props.brands.map(brand => (
                            <option key={brand.id} value={brand.id}>{brand.name}</option>
                        ))}
                    </Field>
                </div>
                {/* <div className="col-md-6">
                                    <label>Select Seller</label>
                                    <span className="requiredField">*</span>
                                    <Field name="seller" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select Seller</option>
                                        {this.props.sellers.map(shipping_cls => (
                                            <option key={shipping_cls.id} value={shipping_cls.id}>{shipping_cls.shop_name}</option>
                                        ))}
                                    </Field>
                                </div> */}
                <div className="col-md-6">
                  <Field
                    name="total_quantity"
                    type="number"
                    // InputProps={{ inputProps: { min: 0, max: 10 } }}
                    component={renderField}
                    label="Quantity"
                    validate={required}
                    // mandatory={true}

                  // warn={alphaNumeric}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="total_price"
                    type="number"
                    component={renderField}
                    label="Price"
                  // validate={required}

                  // warn={alphaNumeric}
                  />
                </div>




                <div className="col-md-12">
                  <label>Type Your Product</label>
                  <span className="requiredField">*</span>
                  <Autosuggest
                    suggestions={this.props.products ? this.props.products : []}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    onSuggestionSelected={this.onSuggestionSelected}
                  />
                </div><br />
                {oderList.length > 0 ? (
                  <>
                    <div className="col-md-12">
                      <strong>Product List</strong>
                    </div>
                    <div className="col-md-12">
                      <table className="table orderlist">
                        <tr>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Product Sku</th>
                          <th>Vendor/Seller</th>
                          <th>Product Weight</th>
                          <th className="text-center">Quantity</th>
                          <th>Price</th>
                          <th>Warehouse Name</th>
                          <th>Subtotal</th>
                          <th>Action</th>
                        </tr>
                        <tbody>
                          {this.state.oderList.map((product, index) => (

                            <tr id={product.id} key={index}>
                              <td>
                                <img src={BASE_URL + product.feature_image} height="100px" width="100px" alt="No Image" />
                              </td>
                              <td>
                                {product.product_name}
                              </td>
                              <td>
                                {product.product_sku}
                              </td>
                              <td>
                                {product.vendor ? product.vendor.shop_name : ''}
                              </td>
                              <td>

                                <div class="input-group plus-minus-input">

                                  <input class="input-group-field form-control" type="number" name="unit_amount" value={product.unit_amount} onChange={(e) => this.onChangesProductWeight(index, e)} />

                                </div>
                              </td>
                              <td>

                                <div class="input-group plus-minus-input">
                                  <div class="input-group-button">
                                    <button type="button" class="btn btn-success" onClick={() => this.manageQuantity(index, "minus")}>
                                      <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                  <input class="input-group-field form-control" type="number" name="quantity" value={product.quantity} />
                                  <div class="input-group-button">
                                    <button type="button" class="btn btn-primary" onClick={() => this.manageQuantity(index, "plus")}>
                                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>

                                <div class="input-group plus-minus-input">

                                  <input class="input-group-field form-control" type="number" name="regular_price" value={product.regular_price} onChange={(e) => this.onChanges(index, e)} />

                                </div>
                              </td>

                              <td>

                                <div class="input-group plus-minus-input">

                                  <input class="input-group-field form-control" type="number" name="regular_price" value={product.regular_price} onChange={(e) => this.onChanges(index, e)} />

                                </div>
                              </td>

                              <td>

                                <select name="warehouse_id" onChange={(e) => this.onChanges(index, e)} className="form-control"
                                >
                                  <option value="">--Select Warehouse--</option>
                                  {this.props.warehouses.map((warehouse, index) => (
                                    <option key={index} value={warehouse.id}>{warehouse.warehouse_name}</option>

                                  ))}
                                </select>


                                {/* <Field name="warehouse" component="select" className="form-control"
                                            validate={required}>
                                            <option value="">Select Warehouse</option>
                                            {this.props.warehouses.map((warehouse,index )=> (
                                                <option key={index} onChange={} value={warehouse.id}>{warehouse.warehouse_name}</option>
                                            ))}
                                        </Field> */}



                              </td>
                              <td>
                                <select name="unit_id" onChange={(e) => this.onChanges(index, e)} className="form-control">
                                  <option value="">--Select Product unit--</option>
                                  {this.props.product_units.map((warehouse, index) => (
                                    <option key={index} value={warehouse.id}>{warehouse.name}</option>

                                  ))}
                                </select>
                              </td>
                              <td colSpan="1">
                                <div class="input-group plus-minus-input">

                                  <input class="input-group-field form-control" type="number" name="subtotal" value={product.subtotal ? product.subtotal : 0} onChange={(e) => this.onChangesSubtotal(index, e)} />

                                </div>
                                {/* {product.subtotal ? product.subtotal : 0} */}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger" id={product.id}
                                  onClick={() => this.deteteRow(product)}
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  />
                                </button>

                              </td>
                            </tr>
                          ))}
                          {/* <tr>
                           
                            <th colSpan="3" className="">Discount</th>
                            <th></th>
                            <th colSpan="4" className="text-center"></th>
                            <th colSpan="3" className="center">{this.state.discount_amount !==null? (this.state.discount_amount).toFixed(2):0}</th>

                          </tr>
                          <tr>
                            <th colSpan="3" className="">Delivery Charge</th>
                            <th></th>
                            <th colSpan="4" className="text-center"></th>
                            <th colSpan="3" className="center">{this.state.shipping_charge !==null? this.state.shipping_charge:0}</th>

                          </tr>
                          <tr>
                            <th colSpan="3" className="">Total</th>
                            <th></th>
                            <th colSpan="4" className="text-center">{totalQuantity}</th>
                            <th colSpan="3" className="center">{this.state.discount_amount !==null && this.state.shipping_charge !==null ?totalAmount+this.state.shipping_charge-this.state.discount_amount:totalAmount+this.state.shipping_charge}</th>

                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null
                }


              </div>


            </div>
            <div className="card-footer text-right">
              <div>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={submitting}>
                  Save
                </button>

              </div>
            </div>
          </form>
        </div>
      </div>

    )
  }
}


StockUpdate.propTypes = {
  updateData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  is_added: state.products.is_added,
  is_updated: state.products.is_updated,
  products: state.products.products,
  brands: state.brands.brands,
  sellers: state.sellers.sellers,
  initialValues: state.stocks.stock,
  stocks:state.stocks.stock,
  is_getting_stock: state.stocks.is_getting_stock,
  warehouses: state.warehouses.warehouses,
  product_units:state.product_units.product_units


});


StockUpdate = reduxForm({
  form: 'StockUpdate',
  enableReinitialize: true

}
)(StockUpdate);


export default connect(mapStateToProps, { updateData, fetchCuisine, RestaurantDetail, searchProducts, fetchBrandsAll, fetchSellers, fetchWarehouse, fetchProductUnit })(StockUpdate);

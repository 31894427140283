import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import {CheckPermisionAction} from "../../constants/CustomMethod"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import { BASE_URL, PRODUCT_BULK_URL, TAX_URL } from '../../constants/api'
// import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'
import Autosuggest from 'react-autosuggest';
import { sumTotalAmount, sumTotalQuantity } from '../../constants/CustomMethod'
import { CSVLink } from "react-csv";
import { ProductCSVData } from '../../constants/Datas'
import { fetchProducts, deleteProduct, uploadProductCSV,updateProductsList, BulkAction } from '../../actions/ProductAction';
import { Modal, Button } from "react-bootstrap";
import { Field, reduxForm } from "redux-form";
import ReactPaginate from 'react-paginate';
import { searchProducts } from '../../actions/ProductAction';

import {
    dateTime,
}from "../../constants/Validate";


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
    required = false,
  }) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
      <div className="form-group">
        <label className="text-sm">{label}</label>
        {required ? <span className="requiredField">*</span> : ""}
        <div>
          <input
            {...input}
            placeholder={label}
            type={type}
            className={className}
          />
          {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  };
  
  // Date picker
  const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
    <div>
      <label className="text-sm">{label}</label>
      <DateTimePicker
        onChange={onChange}
        format="DD-MM-YYYY hh:mm A"
        time={showTime}
        value={!value ? null : new Date(value)}
        placeholder={label}
      />
    </div>;
  
 
const headers = [
    {label:"Image",key:"feature_image"},
    {label:"Product Name",key:"product_name"},
    {label:"Product SKU",key:"product_sku"},
    // {label:"Seller Shop Name",key:"vendor.shop_name"},
    {label:"Featured",key:"is_feature_product"},
    {label:"Stock Quantity",key:"stock_quantity"},
    {label:"Regular Price",key:"regular_price"},
    {label:"Status",key:"status"}


]  



class ProductList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
        // this.getCSVData=this.getCSVData.bind(this)
        this.handleModalShow = this.handleModalShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleStatusValues = this.handleStatusValues.bind(this);
        this.toggleAllChange = this.toggleAllChange.bind(this)
        this.handleDiscountClose = this.handleDiscountClose.bind(this)
        this.handleDiscountModalShow = this.handleDiscountModalShow.bind(this)
        this.handleDateChange=this.handleDateChange.bind(this)


    }




    state = {
        // search: " ",
        // phone_no: "",
        show: false,
        vendor__shop_name:"",
        discountshow:false,
        product_name: "",
        csv_data: [],
        product_sku: "",
        products: [],
        status: '',
        discount_price:'',
        discount_type:'',
        discount_from_date:'',
        discount_to_date:'',
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
        csv_file: '',
        bulk: false,
        allchecked: false,
        get_product: false,
        checkedvalues: [],
        value:'',
        found:false

        

    };


    // CsvReport ={
    //     data:this.state.products,
    //     headers:headers,
    //     filename:'productlist.csv'

    // }


    handleClose() {
        this.setState({ show: false });
    }
    handleModalShow() {

        this.setState({ show: true });
    }

    handleDiscountClose() {
        this.setState({ discountshow: false });
        window.location.reload()
    }
    handleDiscountModalShow() {

        this.setState({ discountshow: true });
    }
    handleDateChange(value, e) {
        this.setState({ discount_from_date: value });
    }
    handleToDateChange(value, e) {
        this.setState({ discount_to_date: value });
    }
    componentDidMount() {
        // alert(JSON.stringify(this.state.products))
        // alert(JSON.stringify(this.props.products))
        this.props.fetchProducts(1);
    }
    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = []

            for (var i = 0; i < this.state.products.length; i++) {
                if (this.state.products[i].checked) {
                    results.push(this.state.products[i].id)
                }
            }

            if (value === "1") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, PRODUCT_BULK_URL
                )
            } else if (value === "0") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, PRODUCT_BULK_URL
                )
            } else if (value === "3") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, PRODUCT_BULK_URL
                )
            
            } else if (value === "4") {
                this.handleDiscountModalShow()


                // this.props.BulkAction(
                //     { 'id': results, 'action': value }, PRODUCT_BULK_URL
                // )
            } else if (value === "5") {
                
                this.props.BulkAction(
                    { 'id': results, 'action': value }, PRODUCT_BULK_URL
                )
            }else {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, PRODUCT_BULK_URL
                )
            }
        }
    }
    handleStatusValues = i => e => {
        // alert(e.target.value)
        let products = [...this.props.products]
        // alert(products[i]['id'])
        // colorDetails[i][e.target.name] = e.target.value
        // console.log(colorDetails[i])
        // this.setState({
        //   colorDetails
        // })


    }

    toggleAllChange(e) {
        const { value, checked } = e.target;
        let products = [...this.props.products]
        products.forEach(product => product.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, products, bulk: true })
        } else {
            this.setState({ allchecked: checked, products, bulk: false })
        }
    }

    getCSVData = e => {
        e.preventDefault();
        var form_data = new FormData();
        form_data.append('csv_file', this.state.csv_file, this.state.csv_file.name)
        this.props.uploadProductCSV(form_data)
        //  console.log(this.state.csv_file)  

    }
    getDiscountData = e => {
        e.preventDefault(); 
        var results = []
        for (var i = 0; i < this.state.products.length; i++) {
            if (this.state.products[i].checked) {
                results.push(this.state.products[i].id)
            }
        }
        let data={
            'discount_from_date':dateTime(this.state.discount_from_date),
            'discount_to_date':dateTime(this.state.discount_to_date),
            'discount_type':this.state.discount_type,
            'discount_amount':this.state.discount_price,
            'results':results
            
        }    

        this.props.updateProductsList(data)
        // form_data.append("discount_from_date", dateTime(event.discount_from_date));
        // alert()
        // // var form_data = new FormData();
        // //form_data.append('csv_file', this.state.csv_file, this.state.csv_file.name)
        // this.props.uploadProductCSV(form_data)
        //  console.log(this.state.csv_file)  

    }
    onSingleChange = index => (e) => {
        let products = [...this.state.products]
        products[index]["checked"] = e.target.checked
        let recent_length = products.filter(product => product.checked).length;

        if (products.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        this.setState({ products })

    };


    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.get_products) {
            if (nextProps.get_products !== prevState.get_product) {
                var products = []
                for (var i = 0; i < nextProps.products.length; i++) {
                    Object.assign(nextProps.products[i], { 'checked': false });
                    products.push(nextProps.products[i])
                }
                console.log(products)
                return {
                    products: products,
                    get_product: true
                }
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.products !== this.props.products){
            this.setState({products:this.props.products})
        }
        if(prevState.products !== this.state.products){
             this.setState({products:this.props.products})
          }
       
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete  ' + data.product_sku + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteProduct(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.product_sku + '.',
                        'success'
                    )
                }
            }
        })
    }

    openDeclinealert(message = 'Decline', data) {

        Swal.fire({
            text: 'Are you want to ' + message + ' ' + data.product_sku + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, ' + message + ' it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.BulkAction(
                        { 'id': data.id, 'action': message }, PRODUCT_BULK_URL
                    )
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        message + '!',
                        'Your Data has been  ' + message + data.product_sku + '.',
                        'success'
                    )
                }
            }
        })
    }

    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchProducts(pageNo)
    }
    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };
    handleUploadFile = (event) => {
        this.setState({
            [event.target.name]: event.target.files[0],

        })



    }
    handleInputText = (event) => {
        this.setState({
            [event.target.name]: event.target.value,

        })
    }
    closeModalHandler = () => {
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { product_sku,vendor__shop_name, product_name, status } = this.state;

        this.props.fetchProducts('', this.state);
        this.setState({get_product:false, hideButton: true })

    };
// Filter logic
getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    this.props.searchProducts(value);
    return inputLength === 0 ? [] : this.props.products.filter(product =>
      product.product_name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  // Trigger suggestions
  getSuggestionValue = product => product.product_name;

  // Render Each Option
  renderSuggestion = product => (
    <div>
      {product.product_name}
    </div>
  );

  // OnChange event handler
  onChanges = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };

 

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    
        
    
    
  };


  // Suggestion rerender when user types
  onSuggestionsFetchRequested = ({ value }) => {
    //alert(value)
    this.setState({
      products: this.getSuggestions(value)
    });
  };




  
  // Triggered on clear
  onSuggestionsClearRequested = () => {
    this.setState({
      products: []
    });
  };


    

    render() {
        // alert(JSON.stringify(this.state.products))
        const {permissions} = this.props
        const { show, bulk, allchecked, checkedvalues, products,discountshow,value } = this.state;
        const page = Math.ceil(this.props.count / 10)

    // Option props
    const inputProps = {
        placeholder: 'Type Product Name ',
        value,
        type: "search",
        onChange: this.onChanges
    };

        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/product-list'
            },
                1000);
        }

        if (this.props.is_uploaded) {
            window.location.href = '/product-list'
        }


        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Product List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Product list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">



                    <div className="col-12">
                        <div className="card">

                            <div className="card-header">
                                <h3 className="card-title">Product List</h3>

                                <div className="float-right">
                                {CheckPermisionAction(permissions,'Inventory','bulk_import_product')?
                                    <>
                                    <button onClick={this.handleModalShow} className="btn btn-info text-white"><b><i className="fa fa-upload"></i>
                                        Bulk Import</b></button>&nbsp;

                                        <CSVLink data ={this.props.products} headers={headers} className="btn btn-info text-white"><b><i className="fa fa-download"></i>
                                        Export Excel</b></CSVLink>&nbsp;
                                        </>
                                :null
                                }

                                    <CSVLink
                                        data={ProductCSVData}
                                        asyncOnClick={true}
                                        filename={"product-upload-format.csv"}
                                        className="btn btn-primary"
                                        // onClick={this.getCSVData}
                                        target="_self"
                                    >
                                        <i className="fa fa-download"></i><strong>Download CSV</strong>
                                    </CSVLink>&nbsp;
                                    {CheckPermisionAction(permissions,'Inventory','add_virtual_product')?
                                        <>
                                    <Link to="/add-digital-product" className="btn btn-success text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                       Virtual Product</b></Link>&nbsp;
                                    </>
                                    :null
                                    }
                                    {CheckPermisionAction(permissions,'Inventory','add_product')?

                                    <Link to="/add-product" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Product</b>
                                    </Link>
                                    :null
                                    }


                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        {/* <input className="form-control mr-sm-3" name="product_name" type="text"
                                            onChange={this.onChange} placeholder="Product Name"
                                            aria-label="Search" /> */}
                                        <div className="mr-sm-3">
                                        {/* <label>Type Your Product Name</label>
                                        <span className="requiredField">*</span> */}
                                        <Autosuggest
                                            suggestions={this.props.products ? this.props.products : []}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            inputProps={inputProps}
                                            onSuggestionSelected={this.onSuggestionSelected}
                                        />
                                        </div><br />
                                        <input className="form-control mr-sm-3" name="vendor__shop_name" type="text"
                                            onChange={this.onChange} placeholder="Seller Shop Name"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-3" name="product_sku" type="text"
                                            onChange={this.onChange} placeholder="Product SKU"
                                            aria-label="Search" />
                                        {/* <input className="form-control mr-sm-3" name="primary_categories__name" type="text"
                                            onChange={this.onChange} placeholder="Primary Category"
                                            aria-label="Search" />
                                         <input className="form-control mr-sm-3" name="secondary_categories__name" type="text"
                                            onChange={this.onChange} placeholder="Secondary Category"
                                            aria-label="Search" /><br/>
                                             <input className="form-control mr-sm-3" name="child_categories__name" type="text"
                                            onChange={this.onChange} placeholder="Child Category"
                                            aria-label="Search" /> */}
                                        <select
                                            name="product_type"
                                            component="select"
                                            className="form-control mr-sm-4"

                                            onChange={this.onChange}
                                        >
                                            <option value="">--Product Type-</option>
                                            <option value="1" >Physical</option>
                                            <option value="2">Virtaul</option>

                                        </select>
                                        <select
                                            name="status"
                                            component="select"
                                            className="form-control mr-sm-4"

                                            onChange={this.onChange}
                                        >
                                            <option value="">--Status-</option>
                                            <option value="1" >Activated</option>
                                            <option value="0">Deactivated</option>

                                        </select>

                                        <select
                                            name="stock_type"
                                            component="select"
                                            className="form-control mr-sm-4"

                                            onChange={this.onChange}
                                        >
                                            <option value="">--Stock Type-</option>
                                            <option value="1" >In Stock</option>
                                            <option value="2">Out Of Stock</option>
                                            <option value="3">Low Stock</option>

                                        </select>
                                        {CheckPermisionAction(permissions,'Inventory','search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                        :null
                                        }
                                    </form>
                                </div>


                            </div>
                            <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Bulk Action--</option>
                                            <option value="1" >Activated</option>
                                            <option value="0">Deactivated</option>
                                            <option value="2">Delete</option>
                                            <option value="3">Set As Popular</option> 
                                            {/* Featured Product replace */}
                                            <option value="4">Set As Discount</option> 
                                            {/* <option value="5">Remove As Popular</option> */}

                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'Inventory','view_product_list')?
                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input type="checkbox"
                                                    checked={allchecked}
                                                    onChange={this.toggleAllChange}
                                                />
                                            </th>
                                            <th>Image</th>
                                            <th>Product Name</th>
                                            <th>Product SKU</th>
                                            {/* <th>Seller Shop Name</th> */}
                                            <th>Popular</th>{/* Featured Repalce Popular */}
                                            
                                            {/* <th>Product Type</th> */}
                                            <th>Stock Quantity</th>
                                            <th>Regular Price</th>
                                            <th>Discount Price</th>
                                            <th>Status</th>
                                            <th>Options</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/product-list" onClick={() => { window.location.href = "/product-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ?
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>
                                            : products.length >0 && products.length != undefined && products.map((product, index) => (
                                                <tr key={product.id}>

                                                    <td>
                                                        <input type="checkbox" onChange={this.onSingleChange(index)} checked={product.checked ? true : false} value={product.id} />
                                                    </td>
                                                    <td>
                                                        {
                                                            product.feature_image != null ? (
                                                                <img src={BASE_URL + product.feature_image} height="100px" width="100px" alt="No Image" />
                                                            ) : null
                                                        }
                                                    </td>
                                                    <td>{product.product_name} </td>
                                                    <td>{product.product_sku}</td>
                                                    {/* <td>{product.vendor ? product.vendor.shop_name  : ''}</td> */}
                                                    <td>{product.is_feature_product ? 'Yes' : 'No'}</td>
                                                    {/* <td>{product.product_type == 1 ? 'Physical' : 'Virtual'}</td> */}


                                                    <td>{product.stock_quantity}</td>
                                                    <td>{product.regular_price}</td>
                                                    <td>{product.discount_price}</td>

                                                    <td>

                                                        <select key={index}
                                                            name="status"
                                                            component="select"
                                                            className="form-control"

                                                            onChange={this.handleStatusValues(index)}
                                                        >


                                                            <option value="1" selected={product.is_active == 1 ? true : false} >Activated</option>
                                                            <option value="0" selected={product.is_active == 0 ? true : false}>Deactivated</option>

                                                        </select>
                                                    </td>
                                                    <td className="btn-group btn-group-sm">

                                                        <div className="btn-group">
                                                    {CheckPermisionAction(permissions,'Inventory','product_options')?

                                                            <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                    :null}
                                                            <div className="dropdown-menu">
                                                                <a href={product.product_type == 1 ? '/product-edit/' + product.id: '/digital-product-update/'+product.id} className="dropdown-item">Edit</a>
                                                                <div className="dropdown-divider"></div>
                                                                {product.is_feature_product !==true?                        
                                                                <a href="#" onClick={() => this.openDeclinealert("Set As Featured", product)} className="dropdown-item">Set As Popular</a> 
                                                                :
                                                                <a href="#" onClick={()=> this.openDeclinealert("Remove As Featured", product)} className="dropdown-item">Remove As Popular</a>
                                                                }{/* Featured Repalce Popular */}
                                                                {product.is_active !==true?
                                                                <>
                                                                <a href="#" onClick={() => this.openDeclinealert("Approve", product)} className="dropdown-item">Approve</a>
                                                                
                                                                
                                                                <a href="#" onClick={() => this.openDeclinealert("Decline", product)} className="dropdown-item">Decline</a>
                                                                </>
                                                                :null}
                                                                <a href="#" onClick={() => this.opensweetalert(product)} className="dropdown-item">Delete</a>

                                                            </div>
                                                        </div>




                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">

                                {/*<span className="page-link" onClick={() => this.props.fetchstaffs(this.props.next)}>&laquo;</span>*/}
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }

                            </div>
                            <Modal
                                show={show}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Upload File(CSV)</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Please Upload CSV from Downloaded format</p>
                                    <input
                                        name="csv_file"
                                        type="file"
                                        className="form-control"
                                        //placeholder="Attributes Name"
                                        // validate={required}
                                        onChange={this.handleUploadFile}
                                    />

                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.getCSVData}>
                                        <i className="fa fa-upload"></i>Upload
                                    </Button>
                                </Modal.Footer>

                            </Modal>

                            <Modal
                                show={discountshow}
                                onHide={this.handleDiscountClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Bulk Product Discount</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                <div className="col-md-6">
                            <label>Discount Type</label>
                            <span className="requiredField">*</span>
                            <select name="discount_type" component="select" onChange={this.handleInputText} className="form-control"
                            >
                                <option value="">Select Discount Type</option>
                                <option value="1">Percentage (%)</option>
                                <option value="2">Flat Discount (BDT)</option>

                            </select>
                            </div>
                            <div className="col-md-6">
                            <label>Discount Amount</label>
                            <span className="requiredField">*</span>   
                            <input name="discount_price" placeholder="Discount Amount" onChange={this.handleInputText} component={renderField} className="form-control"
                                label="Discount Amount" />
                            </div>

                        <div className="col-md-6">
                        <label className="text-sm">Discount From Date</label>
                        <DateTimePicker
                            onChange={(value, e) => this.handleDateChange(value, e)}
                            format="DD-MM-YYYY hh:mm A"
                            time={false}
                            name="discount_from_date"
                            // value={ this.state.discount_from_date}
                            placeholder="Discount From Date"
                        />    
                        </div>
                        <div className="col-md-6">
                        <label className="text-sm">Discount To Date</label>   
                        <DateTimePicker
                            onChange={(value, e) => this.handleToDateChange(value, e)}
                            format="DD-MM-YYYY hh:mm A"
                            time={false}
                            name="discount_to_date"
                            // value={ this.state.discount_from_date}
                            placeholder="Discount To Date"
                        />   
                        </div>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleDiscountClose}>
                                        Close
                  </Button>
                                    <Button variant="primary" onClick={this.getDiscountData}>
                                        <i className="fa fa-upload"></i>Save
                  </Button>
                                </Modal.Footer>

                            </Modal>

                        </div>
                     
                    </div>
                </div>

            </div>
        )
    }
}

ProductList.propTypes = {
    fetchProducts: PropTypes.func.isRequired,
    deleteProduct: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    BulkAction: PropTypes.func.isRequired,
    updateProductsList: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    products: state.products.products,
    count: state.products.count,
    next: state.products.next,
    is_loading: state.products.is_loading,
    is_uploaded: state.products.is_uploaded,
    get_products: state.products.get_products,
    bulk: state.products.bulk,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});

ProductList = reduxForm({
    form: "ProductListForm",
})(ProductList);
export default connect(mapStateToProps, {searchProducts, fetchProducts, deleteProduct, updateProductsList,uploadProductCSV, BulkAction })(ProductList);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';

// import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

// import { fetchRd, deleteRd } from '../../actions/ProductAction';
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class RatingReviewList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        rgd_id: "",
        user__phone_no: "",
        user__email: "",
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
    };



    componentDidMount() {
        // this.props.fetchRd(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.user.first_name + ' ' + data.user.last_name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteRd(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.user.first_name + ' ' + data.user.last_name + '.',
                        'success'
                    )
                }
            }
        })
    }


    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { rgd_id, user__phone_no, user__email } = this.state;
        console.log([rgd_id, user__phone_no, user__email]);

        this.props.fetchRd('', this.state);
        this.setState({ hideButton: true })

    };
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchRd(pageNo) //handle page fetchRd use for testing not valid this page
    }
    render() {
        const page = Math.ceil(this.props.count / 10)


        const isAdmin = this.props.auth.user.is_admin;

        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Rating Review List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Rating Review List</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Rating Review List</h3>
                                <div className="float-right">

                                    {/* <Link to="/add-tags" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Rating Review </b></Link> */}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="name" type="text"
                                            onChange={this.onChange} placeholder="Name"
                                            aria-label="Search" />
                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Product Name</th>
                                            <th>Seller Name</th>
                                            <th>Customer Name</th>
                                            <th>Rating</th>
                                            <th>Review</th>
                                            <th>Option</th>
                                            {this.state.hideButton ?
                                                <th><Link to="/" onClick={() => { window.location.href = "/product-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>1</td>
                                            <td>Jhon</td>
                                            <td>4</td>
                                            {/* <td>Abraham</td> */}
                                            <td>5</td>
                                            <td></td>
                                            <td></td>

                                            <td>
                                                {/* <button className="btn btn-warning text-white">
                                                    <i className="fas fa-edit">
                                                    </i>
                                                </button> */}
                                                <button className="btn btn-danger">
                                                    <i className="fas fa-trash"></i>
                                                </button></td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

RatingReviewList.propTypes = {
    fetchRd: PropTypes.func.isRequired,
    deleteRd: PropTypes.func.isRequired,
    regionals: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    attributes: state.attributes.attributes,
    count: state.attributes.count,
    next: state.attributes.next,
    is_loading: state.attributes.is_loading,
    auth: state.auth
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, {})(RatingReviewList);

import {
    LOADING_CAMPAIGN,
    FETCH_CAMPAIGN,
    DETAIL_CAMPAIGN,
    DELETE_CAMPAIGN,
    ADD_DATA,
    UPDATE_CAMPAIGN,
    NOT_FOUND_CAMPAIGN,
    UPDATED_DATA,
    LOADING_CAMPAIGN_GROUP,
    FETCH_CAMPAIGN_GROUP,
    DETAIL_CAMPAIGN_GROUP,
    UPDATE_CAMPAIGN_GROUP,
    DELETE_CAMPAIGN_GROUP,
    NOT_FOUND_CAMPAIGN_GROUP,



} from '../actions/types';

const initialState = {
    campaigns: [],
    campaign: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
};

export const CampaignReducer =(state = initialState, action)=> {
    switch (action.type) {
        case LOADING_CAMPAIGN:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_CAMPAIGN:
            return {
                ...state,
                campaigns: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case UPDATE_CAMPAIGN:
            return {
                ...state,
                campaigns: action.payload,
                is_loading: false
            };
        case DETAIL_CAMPAIGN:
            return {
                ...state,
                is_getting: true,
                campaigns: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_CAMPAIGN:
            return {
                ...state,
                campaigns: state.campaigns.filter(campaign => campaign.id !== action.payload)
            };
        case ADD_DATA:
            return {
                ...state,
                is_added: true,
                campaigns: [action.payload]
            };
        case UPDATED_DATA:
            return {
                ...state,
                is_updated: true,
                // products: [action.payload]
            };
        case NOT_FOUND_CAMPAIGN:
            return {
                ...state,
                campaigns: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}





// CAMPAING GROUP REDUCER


export const CampaignGroupReducer= (state = {
    campaign_groups: [],
    campaign_group: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
}, action) =>{
    switch (action.type) {
        case LOADING_CAMPAIGN_GROUP:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_CAMPAIGN_GROUP:
            return {
                ...state,
                campaign_groups: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case UPDATE_CAMPAIGN_GROUP:
            // alert(JSON.stringify(action.payload))
            return {

                ...state,
                campaign_groups: action.payload,
                is_loading: true
            };
        case DETAIL_CAMPAIGN_GROUP:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                campaign_groups: action.payload,

            };
        case DELETE_CAMPAIGN_GROUP:
            return {
                ...state,
                campaign_groups: state.campaign_groups.filter(campaign_group => campaign_group.id !== action.payload)
            };
        

        case NOT_FOUND_CAMPAIGN_GROUP:
            return {
                ...state,
                campaign_groups: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { toast } from "react-toastify";
// import { Withdraw_URL } from "../../constants/api";
import { Link } from "react-router-dom";
import Select from "react-select";
import { fetchPaymentMethods } from '../../../actions/orderActions';
import {searchSeller,addData} from '../../../actions/CustomerActions'

import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
 import {WITHDRAW_URL} from '../../../constants/api'
import {
  WithdrawName,
  WithdrawSKU,
  purchasePrice,
  regularPrice,
  dateTime,
  SingleIdSelect
} from "../../../constants/Validate";




const required = value => (value || typeof value === 'number' ? undefined : 'Required');

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  required = false,
}) => {
  const className = `form-control ${error && touched ? "is-invalid" : ""}`;
  return (
    <div className="form-group">
      <label className="text-sm">{label}</label>
      {required ? <span className="requiredField">*</span> : ""}
      <div>
        <input
          {...input}
          placeholder={label}
          type={type}
          className={className}
        />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

// Text area type
const renderTextArea = ({
  input,
  label,
  meta: { touched, error, warning },
}) => {
  const className = `form-control ${error && touched ? "is-invalid" : ""}`;
  return (
    <div>
      <label className="text-sm">{label}</label>
      <div>
        <textarea
          {...input}
          placeholder={label}
          rows="3"
          cols="40"
          className={className}
        />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

// File Input type

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class AddWithdrawForm extends Component {



  state = {
    customer_name: '',
    seller_info:''
  };








  componentDidMount() {
    this.props.fetchPaymentMethods(1)
    // this.onProductAutoSuggest(this.state.value)

  }


  









  static getDerivedStateFromProps(nextProps, prevState) {

    return null

  }

  submit(event) {
    const {

     customer_name,
    

    } = this.state;
    // alert(JSON.stringify(oderList))
    var data ={
    //   'oder_list':JSON.stringify(oderList),
    //   'total_Withdraw_quantity':totalQuantity,
    //   'total_Withdraw_price':totalAmount,
       'customer':customer_name,
    //   'Withdraw_status':event.Withdraw_status,
       'payment_method':event.payment_method,
       'amount':event.amount

    }
    // alert(JSON.stringify(data))

     this.props.addData(data, WITHDRAW_URL,"Withdraw ") 
    
  }


  onChangeCustomer = (event, { newValue }) => {
    // alert(JSON.stringify(newValue))
    this.setState({
      customer_name: newValue
    });
    // alert(this.state.customer_name)
  };

  




  getCustomerSuggestions = customer_name => {
    if (customer_name !== undefined) {
      const inputValue = customer_name.trim().toLowerCase();
      const inputLength = inputValue.length;
      this.props.searchSeller(customer_name);
      return inputLength === 0 ? [] : this.props.customers.filter(customer =>
        customer.user.phone_no.toLowerCase().slice(0, inputLength) === inputValue
      );
    }
  };

  onSuggestionsCustomerClearRequested = () => {
    this.setState({
      customers: []
    });
  };
  getSuggestionCustomerValue = customer => customer.user.phone_no;

  onSuggestionsFetchRequestedCustomer = ({ value }) => {
   
    this.setState({
      customers: this.getCustomerSuggestions(value)
    });
  };

  onSuggestionSelectedCustomer = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
  
    this.setState({seller_info:suggestion})
   
  };
  renderSuggestionCustomer = customer => (
    // alert(JSON.stringify(customer))
    <div>
      {customer.user.phone_no}
    </div>
  );
  // Triggered on clear
 

  render() {
    const { handleSubmit, pristine, reset,payments , submitting, }
      = this.props;
    const {

      customer_name
    } = this.state;
    // alert(customer_name)
    const inputCustomerProps = {
      placeholder: 'Type Your Customer Mobile No or Email or Name',
      value: customer_name,
      type: "search",
      name: 'textBoxValue',
      onChange: this.onChangeCustomer
    };
    // Option props
    
    if (this.props.is_added) {
      setTimeout(function () {
        window.location.href = '/withdraw-list'
      },
        3000);
    }
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Add Withdraw</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Add Withdraw Form</h3>
            <div className="float-right">
              <Link
                to="/Withdraw-list"
                className="btn btn-primary float-right text-white"
              >
                <b>
                  <i className="fa fa-backward"></i>
                  Back To List
                </b>
              </Link>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(this.submit.bind(this))}
          // onChange={this.handleChangeForm}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label>Select Seller</label>
                  <span className="requiredField">*</span>
                  <Autosuggest
                    suggestions={this.props.customers ? this.props.customers : []}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCustomer}
                    onSuggestionsClearRequested={this.onSuggestionsCustomerClearRequested}
                    getSuggestionValue={this.getSuggestionCustomerValue}
                    renderSuggestion={this.renderSuggestionCustomer}
                    inputProps={inputCustomerProps}

                   onSuggestionSelected={this.onSuggestionSelectedCustomer}
                  />

                </div>
                <div className="col-md-6">
                  <label>Payment Method</label>
                  <Field
                    name="payment_method"
                    component="select"
                    className="form-control"
                    label="Payment Method"
                    validate={required}
                  >
                    <option value="">--Select Payment Method--</option>
                    <option value="1">Cash</option>
                    <option value="2">Bank Transfer</option>
                    <option value="3">Mobile Banking</option>

                  </Field>
                </div>
                <div className="col-md-6">
                 
                  <Field
                    name="amount"
                    component={renderField}
                    className="form-control"
                    label="Amount"
                    validate={required}
                  />
                   
                 
                </div>
                {

                this.state.seller_info ? (
                  <>
                  <div className="col-md-12">
                 
                  { 
                    this.state.seller_info.payment_type===1 ? (
                      <table>
                        <tr><td>Account Name:</td><td>{this.state.seller_info.account_name}</td></tr>
                        <tr><td>Account Number:</td><td>{this.state.seller_info.account_number}</td></tr>
                       
                        <tr><td>Bank Name:</td><td>{this.state.seller_info.bank_name}</td></tr>
                        <tr><td> Routing Name:</td><td>{this.state.seller_info.routing_number}</td></tr>

                      </table>  
                    ):   <table>
                      <tr>
                      <td>Mobile Banking:</td><td>
                      {this.state.seller_info.mobile_banking_type===1 ? "Bkash" : ''}
                      {this.state.seller_info.mobile_banking_type===2 ? "Nogod" : ''}
                      {this.state.seller_info.mobile_banking_type===3 ? "Rocket" : ''}
                      </td>
                      </tr>
                      <tr>
                        <td>Wallet number:</td>
                        <td>{this.state.seller_info.wallet_number}</td>
                      </tr>
                   
                   

                  </table>  
                  }  
                  
                
                  </div>
                  </>
                ):null
                }
              </div>

            </div>

            <div className="card-footer">
             
                <div>
                  <button
                    type="submit"
                    className="btn btn-success float-right"
                    disabled={submitting}
                  >
                    <i className="fa fa-save "></i>Submit
                </button>
                </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AddWithdrawForm.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  searchSeller: PropTypes.func.isRequired,
  addData: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
  products: state.products.products,
  payments: state.payments.payments,
  customers: state.customers.customers,
  is_added: state.customers.is_added,

});
AddWithdrawForm = connect(
  mapStateToProps,
  {
     fetchPaymentMethods,searchSeller,addData
  }
)(AddWithdrawForm)

export default reduxForm({
  form: "AddWithdrawForm",
  enableReinitialize: true
})(AddWithdrawForm);

import {
    LOADING_AGENT_REPORTS,
    FETCH_AGENT_REPORTS,
    DELETE_AGENT_REPORTS,
    NOT_FOUND_AGENT_REPORTS,
    DETAIL_AGENT_REPORTS,
    LOADING_PRODUCT_STOCK_REPORTS,
    FETCH_PRODUCT_STOCK_REPORTS,
    NOT_FOUND_PRODUCT_STOCK_REPORTS,
    LOADING_SALES_REPORTS,
    FETCH_SALES_REPORTS,
    NOT_FOUND_SALES_REPORTS,
    

} from './types';

import {
     AGENT_WISE_REPORTS_URL,PRODUCT_STOCK_REPORTS_URL
} from '../constants/api'
import { SearchURLGenerate } from '../constants/CustomMethod'

import { toast } from 'react-toastify'

import axios from 'axios';

export const fetchAgentWiseReports = (pageNumber, parameters = {},searchurl=false) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENT_REPORTS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    var search_key = ["agent_id","from_date", "to_date", 'user__phone_no']
    var search_url = SearchURLGenerate(parameters, search_key);
    if (searchurl===true){
        search_url=''
    }

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = AGENT_WISE_REPORTS_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            //alert(JSON.stringify(parameters))
            var search_url = SearchURLGenerate(parameters, search_key);
//             alert(search_url)
            url = url + '?page=' + pageNumber+'&'+search_url
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_AGENT_REPORTS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_AGENT_REPORTS });

    }
};






export const fetchProductStockReports = (pageNumber, parameters = {},searchurl=false) => (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT_STOCK_REPORTS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    var search_key = ["product_name","product_sku"]
    var search_url = SearchURLGenerate(parameters, search_key);
    if (searchurl===true){
        search_url=''
    }

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = PRODUCT_STOCK_REPORTS_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            //alert(JSON.stringify(parameters))
            var search_url = SearchURLGenerate(parameters, search_key);
//             alert(search_url)
            url = url + '?page=' + pageNumber+'&'+search_url
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_PRODUCT_STOCK_REPORTS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_PRODUCT_STOCK_REPORTS });

    }
};





export const fetchSalesReports = (pageNumber, parameters = {},searchurl=false) => (dispatch, getState) => {
    dispatch({ type: LOADING_SALES_REPORTS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    var search_key = ["product_name","product_sku"]
    var search_url = SearchURLGenerate(parameters, search_key);
    if (searchurl===true){
        search_url=''
    }

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = PRODUCT_STOCK_REPORTS_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            //alert(JSON.stringify(parameters))
            var search_url = SearchURLGenerate(parameters, search_key);
//             alert(search_url)
            url = url + '?page=' + pageNumber+'&'+search_url
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_SALES_REPORTS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SALES_REPORTS });

    }
};
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import Modal from 'react-modal';

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

import { fetchBlogPost, deleteBlogPost } from '../../../actions/BlogAction';
import { BLOG_POST_URL } from '../../../constants/api'

// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class BlogList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        // user__phone_no: "",
        // user__email: "",
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
    };

    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchBlogPost(pageNo)
    }

    componentDidMount() {
        this.props.fetchBlogPost(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.title + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteBlogPost(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.title + '.',
                        'danger'
                    )
                }
            }
        })
    }


    // handleStatusValues = i => e => {
    //     let customers = [...this.props.customers]
    //     var id = Number(customers[i].id)
    //     let data = {
    //         'is_active': e.target.value
    //     }
    //     this.props.updateDatas(data, USER_CREATE_URL + id + "/", "Status")

    // }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    // onSubmit = e => {
    //     e.preventDefault();
    //     const { name } = this.state;
    //     console.log([name]);

    //     this.props.fetchCustomers('', this.state);
    //     this.setState({ hideButton: true })

    // };

    render() {
        // alert(JSON.stringify(this.props.blog_posts))
        const page = Math.ceil(this.props.count / 10)
        const {permissions} = this.props

        const isAdmin = this.props.auth.user.is_admin;
        if (this.props.is_updated) {
            setTimeout(function () {
                window.location.href = '/blog-list'
            },
                1000);
        }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Blog List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Blog List</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Blog List</h3>
                                <div className="float-right">
                                    {/* <Link to="/add-customer" className="btn btn-info text-white"><b><i className="fa fa-upload"></i>
                                        Bulk Import</b></Link>&nbsp;
                                    <Link to="/add-customer" className="btn btn-warning text-white"><b><i className="fa fa-download"></i>
                                        Download CSV</b></Link>&nbsp; */}
                                    {CheckPermisionAction(permissions,'Blog','add_blog')?

                                    <Link to="/add-blog" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Blog</b></Link>
                                    :null}
                                </div>
                            </div>


                            {/* <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="user__phone_no" type="text"
                                            onChange={this.onChange} placeholder="Phone No"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-4" name="user__email" type="text"
                                            onChange={this.onChange} placeholder="Email"
                                            aria-label="Search" />
                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    </form>
                                </div>


                            </div> */}
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                        {CheckPermisionAction(permissions,'Blog','view_blog_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Title</th>
                                            <th>Author</th>
                                            <th>Blog Category</th>
                                            <th>Blog Tags</th>
                                            <th>Image</th>
                                            <th>Option</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/blog-list" onClick={() => { window.location.href = "/blog-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> : this.props.blog_posts.length != undefined && this.props.blog_posts.map((blog, index) => (
                                            <tr key={blog.id}>
                                                <td>{index + 1}</td>
                                                <td>{blog.title}</td>
                                                <td>{blog.author_name ? blog.author_name:''}</td>
                                                <td>{blog.category.name}</td>
                                                <td>{Object.values(blog.tag.map(blg => ' ' + blg.name))}</td>
                                                <td>{blog.image !== null ? (
                                                    <img src={blog.image} height="100px" width="100px" alt="No Image" />

                                                ) : null}</td>
                                                {/* <td>

                                                    <select key={index}
                                                        name="status"
                                                        component="select"
                                                        className="form-control"

                                                        onChange={this.handleStatusValues(index)}
                                                    >


                                                        <option value="1" selected={customer.is_active == 1 ? true : false} >Activated</option>
                                                        <option value="0" selected={customer.is_active == 0 ? true : false}>Deactivated</option>

                                                    </select>
                                                </td> */}
                                                <td className="btn-group btn-group-sm">

                                                    <div className="btn-group">
                                                {CheckPermisionAction(permissions,'Blog','blog_options')?

                                                        <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                :null}
                                                        <div className="dropdown-menu">
                                                            <a href={'/blog-update/' + blog.id} className="dropdown-item">Edit</a>
                                                            <div className="dropdown-divider"></div>

                                                            {/* <a href="#" onClick={() => this.openDeclinealert("Set As Featued", customer)} class="dropdown-item">Set As Featued</a>
                                                            <a href="#" onClick={() => this.openDeclinealert("Approve", customer)} class="dropdown-item">Approve</a>
                                                            <a href="#" onClick={() => this.openDeclinealert("Decline", customer)} class="dropdown-item">Decline</a> */}
                                                            <a href="#" onClick={() => this.opensweetalert(blog)} className="dropdown-item">Delete</a>

                                                        </div>
                                                    </div>




                                                </td>
                                                {/* <td className="btn-group btn-group-sm">


                                                    <Link to={'/customer-update/' + customer.id}>
                                                        <button className="btn btn-info text-white">
                                                            <i className="fas fa-edit text-white" ></i>
                                                        </button>
                                                    </Link>

                                                    <button className="btn btn-danger"
                                                        // onClick={this.props.RegDirectorConfirm.bind(this, rgd.id, rgd.is_active)}
                                                        onClick={() => this.opensweetalert(customer)}>
                                                        <i className="fas fa-trash" />
                                                    </button>

                                                </td> */}
                                                {/* <td>
                                                    <Link to={'/customer-detail/' + customer.id}>
                                                        <button className="btn btn-info">
                                                            <i className="fas fa-eye text-white" >View</i>
                                                        </button>
                                                    </Link>

                                                    <Link to={'/customer-update/' + customer.id}>
                                                        <button className="btn btn-warning text-white">
                                                            <i className="fas fa-edit text-white" >Edit</i>
                                                        </button>
                                                    </Link>
                                                    {isAdmin ?
                                                        <button className="btn btn-danger" onClick={() => this.opensweetalert()}><i className="fas fa-trash" >Delete</i>
                                                        </button> : " "}

                                                </td> */}
                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

BlogList.propTypes = {
    fetchBlogPost: PropTypes.func.isRequired,
    deleteBlogPost: PropTypes.func.isRequired,



};

const mapStateToProps = state => ({
    blog_posts: state.blog_posts.blog_posts,
    count: state.blog_posts.count,
    next: state.blog_posts.next,
    is_loading: state.blog_posts.is_loading,
    is_updated: state.blog_posts.is_updated,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchBlogPost, deleteBlogPost })(BlogList);

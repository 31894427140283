import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { toast } from "react-toastify";
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { Link } from "react-router-dom";
import Select from "react-select";
import { searchProducts } from '../../actions/ProductAction';
import { fetchPaymentMethods } from '../../actions/orderActions';
import { searchCustomer, addData } from '../../actions/CustomerActions'
import { sumTotalAmount, sumTotalQuantity,sumTotalProductWeigth,calculateCouponDiscount } from '../../constants/CustomMethod'
import { PaymentStatus, OrderStatus } from '../../constants/Datas'
import { connect } from 'react-redux';
import { getDistrictsForSelect } from '../../actions/DistrictsActions'
import { fetchSellersForSelect } from '../../actions/SellerActions';
import {fetchCoupons} from '../../actions/CouponActions'
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { GET_ORDER_URL,COUPON_CODE_URL,NO_TOKEN_COUPON_CODE_URL } from '../../constants/api'
import {
  OrderName,
  OrderSKU,
  purchasePrice,
  regularPrice,
  dateTime,
  SingleIdSelect,
  email, phoneNumber,
} from "../../constants/Validate";




const required = value => (value || typeof value === 'number' ? undefined : 'Required');

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  required = false,
}) => {
  const className = `form-control ${error && touched ? "is-invalid" : ""}`;
  return (
    <div className="form-group">
      <label className="text-sm">{label}</label>
      {required ? <span className="requiredField">*</span> : ""}
      <div>
        <input
          {...input}
          placeholder={label}
          type={type}
          className={className}
        />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};



// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
  <div>
    <label className="text-sm">{label}</label>
    <DateTimePicker
      onChange={onChange}
      format="DD-MM-YYYY hh:mm A"
      time={showTime}
      value={!value ? null : new Date(value)}
      placeholder={label}
    />
  </div>;

// Text area type
const renderTextArea = ({
  input,
  label,
  meta: { touched, error, warning },
}) => {
  const className = `form-control ${error && touched ? "is-invalid" : ""}`;
  return (
    <div>
      <label className="text-sm">{label}</label>
      <div>
        <textarea
          {...input}
          placeholder={label}
          rows="3"
          cols="40"
          className={className}
        />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

// File Input type

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class AddOrderForm extends Component {



  state = {
    product: '',
    allowOrderColor: false,
    allowOrderSize: false,
    errorvendor: false,
    allowShippingChange: false,
    allowOrderSeo: false,
    allowPreOrder: false,
    allowRecommendOrder: false,
    allowFreeDelivery: false,
    allowFastDelivery: false,
    value: '',
    seller: "",
    coupon:"",
    coupon_code:'',
    customer_name: '',
    customer_suggesion: '',
    totalAmount: 0,
    totalQuantity: 0,
    totalProductWeigth:0,
    discount_amount:0,
    products: [],
    oderList: [],
    customers: [],
    shipping_charge:0,
    coupon_id:'',
    coupon_type:'',
    discount_type:'',

    isShipping: false,
  };

  vendorChange = (seller) => {
    this.setState({ seller });
  };

  couponChange = (coupon) => {
    this.setState({ coupon });
  };
  isShippingChange = (e) => {
    this.setState({ isShipping: e.target.checked })
    // let products = [...this.state.products]
    // products[index]["checked"] = e.target.checked
    // let recent_length = products.filter(product => product.checked).length;

    // if (products.length === recent_length) {
    //     this.setState({ allchecked: true, bulk: true })
    // } else {
    //     this.setState({ allchecked: false })
    // }
    // if (recent_length < 1) {
    //     this.setState({ bulk: false })
    // } else {
    //     this.setState({ bulk: true })
    // }
    // this.setState({ products })

  };
  onChangeText = (e) => {
    // var keyArry = ["shipping_charge"];
    this.setState({
        [e.target.name]: e.target.value
    })
  //   if (keyArry.indexOf(e.target.name) > -1) {
  //     setTimeout(
  //         function () {
  //             this.getDeleveryCharge().then(() => {
  //             })
  //         }.bind(this),
  //         500
  //     );
  // }
}
  CouponDiscount = async () => {
    const token = localStorage.getItem('token');
        // alert(token)
        let headers = {
            "Content-Type": "application/json",
            
        };

    if (token) {

      headers["Authorization"] = `Token ${token}`;
    }
    if (this.state.coupon_code !== '') {
        fetch(NO_TOKEN_COUPON_CODE_URL + "?coupon_code=" + this.state.coupon_code+"&coupon_type=2", {"method": "GET",headers})
            .then(res => res.json())
            .then(result => {
                // alert(JSON.stringify(result))
                if (result.error) {
                    this.setState({discount_amount: 0, coupon_type: 2,discount_type:""})
                    
                    toast.warn("Invalid Coupon Code")
                } else {
                    if (result.amount <= this.state.totalAmount){
                        let totalAmount = this.state.totalAmount 
                        let totaldiscount = calculateCouponDiscount(result.discount_type,result.coupon_type, result.discount_amount, totalAmount)
                        
                        
                        this.setState({discount_amount: totaldiscount, coupon_type: result.coupon_type,discount_type:result.discount_type})
                        toast.success("Coupon Code has been applied successfully")

                    }else{
                        toast.warn('You have to purchase more than ' +
                        result.amount +
                        ' amount to apply coupon',)
                    }
                }
                
            })
    } else {
        toast.warn("Please enter coupon Code")
    }
    
    this.setState({coupon_code: ''})

}

  getDeleveryCharge = async () => {
    
    
    if (this.state.totalAmount<490) {
        this.setState({shipping_charge: 10});
    }else{
        this.setState({shipping_charge:0})
      }

}
  onChanges=(index,e)=>{
    let orders = [...this.state.oderList]
    // alert(e.target.value)
    
   if (e.target.value !==""){
      orders[index].regular_price =  e.target.value;
      orders[index].subtotal = (e.target.value - orders[index].discount_price) * orders[index].quantity;
   }
    
    this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders),totalProductWeigth:sumTotalProductWeigth(orders) })
  }

  onChangesSubtotal = (index,e) => {
    let orders = [...this.state.oderList]
    orders[index].subtotal =  e.target.value;
    // orders[index].subtotal = (e.target.value - orders[index].discount_price) * orders[index].quantity;

   
    this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders),totalProductWeigth:sumTotalProductWeigth(orders) })
  }
  onChangesProductWeight= (index,e) => {
    let orders = [...this.state.oderList]
    orders[index].unit_amount =  e.target.value;
    // orders[index].subtotal = (e.target.value - orders[index].discount_price) * orders[index].quantity;

   
    this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders),totalProductWeigth:sumTotalProductWeigth(orders) })
  }

  componentDidMount() {
    this.props.fetchPaymentMethods(1)
    this.props.getDistrictsForSelect(0)
    this.props.fetchSellersForSelect()
    this.props.fetchCoupons()
    // setTimeout(()=>{
    //   this.getDeleveryCharge().then(()=>{})
    // },2000)
  
    // this.onProductAutoSuggest(this.state.value)
 


  }

//   componentDidUpdate(prevProps, prevState){
//    setTimeout(()=>{
//       this.getDeleveryCharge().then(()=>{})
//     },2000)
// }

  deteteRow = (product) => {
    var products = this.state.oderList.filter((r) => r !== product)
    this.setState({
      oderList: products,
      totalQuantity: sumTotalQuantity(products),
      totalAmount: sumTotalAmount(products),
      totalProductWeigth:sumTotalProductWeigth(products)

    });
  }

  manageQuantity = (index, type = "plus") => {
    let orders = [...this.state.oderList]
    if (type == "plus") {
      orders[index].quantity = orders[index].quantity + 1;
      orders[index].subtotal = orders[index].new_price * orders[index].quantity;

    } else {
      if (orders[index].quantity > 1) {
        orders[index].quantity = orders[index].quantity - 1;
        orders[index].subtotal = orders[index].new_price * orders[index].quantity;

      }
    }
    this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders) })
  }









  static getDerivedStateFromProps(nextProps, prevState) {

    return null

  }

  submit(event) {
    const {

      value,
      customer_name,
      totalAmount,
      totalQuantity,
      oderList,
      isShipping,
      seller,
      coupon
    } = this.state;
    // alert(JSON.stringify(oderList))
    // var form_data = new FormData();

    // if (seller == null || seller == '') {
    //   this.setState({ errorvendor: true })
    //   return false
    // }
    // if (seller != null) {
    //   form_data.append("seller", SingleIdSelect(seller));
    //   this.setState({ errorvendor: false })
    // }
    var data = {
      'oder_list': JSON.stringify(oderList),
      'total_order_quantity': totalQuantity,
      'total_order_price': this.state.shipping_charge !==null && this.state.discount_amount !==null? totalAmount+this.state.shipping_charge - this.state.discount_amount:totalAmount,
      'customer': customer_name,
      'order_status': event.order_status,
      'payment_method': event.payment_method,
      'payment_status': event.payment_status,
      'is_shipping': isShipping,
      'order_date': dateTime(event.order_date),
      'first_name': event.first_name,
      'last_name': event.last_name,
      'district': event.district,
      'email': event.email,
      'coupon_code':this.state.coupon_code,
      // 'seller': SingleIdSelect(seller),
      'phone_no': event.phone_no,
      'post_code': event.post_code,
      'shipping_address': event.shipping_address,
      'shipping_charge':this.state.shipping_charge,
      'discount_amount':this.state.discount_amount,
      'product_weight':this.state.totalProductWeigth
      
    }

    // alert(JSON.stringify(data))

    this.props.addData(data, GET_ORDER_URL, "Order ")

  }

  // Filter logic
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    this.props.searchProducts(value);
    return inputLength === 0 ? [] : this.props.products.filter(product =>
      product.product_name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  // Trigger suggestions
  getSuggestionValue = product => '';

  // Render Each Option
  renderSuggestion = product => (
    <div>
      {product.product_name}
    </div>
  );

  // OnChange event handler
  onChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };

  onChangeCustomer = (event, { newValue }) => {
    // alert(JSON.stringify(newValue))
    this.setState({
      customer_name: newValue
    });
    // alert(this.state.customer_name)
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

    let { oderList } = this.state;
    suggestion.quantity = 1
    suggestion.subtotal = Number(suggestion.new_price)
    var index = oderList.findIndex(x => x.id == suggestion.id);

    // console.log(suggestion);
    if (index === -1) {
      oderList.push(suggestion)
    } else {
      oderList[index].quantity = oderList[index].quantity + 1;
      oderList[index].subtotal += suggestion.new_price;
    }
    this.setState({ totalAmount: sumTotalAmount(oderList), totalQuantity: sumTotalQuantity(oderList) })
  };


  // Suggestion rerender when user types
  onSuggestionsFetchRequested = ({ value }) => {
    //alert(value)
    this.setState({
      products: this.getSuggestions(value)
    });
  };



  getCustomerSuggestions = customer_name => {
    if (customer_name !== undefined) {
      const inputValue = customer_name.trim().toLowerCase();
      const inputLength = inputValue.length;
      this.props.searchCustomer(customer_name);
      return inputLength === 0 ? [] : this.props.customers.filter(customer =>
        customer.phone_no.toLowerCase().slice(0, inputLength) === inputValue
      );
    }
  };

  onSuggestionsCustomerClearRequested = () => {
    this.setState({
      customers: []
    });
  };
  getSuggestionCustomerValue = customer => customer.phone_no;

  onSuggestionsFetchRequestedCustomer = ({ value }) => {

    this.setState({
      customers: this.getCustomerSuggestions(value)
    });
  };

  onSuggestionSelectedCustomer = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

    this.setState({ customer_suggesion: suggestion })
  };
  renderSuggestionCustomer = customer => (
    // alert(JSON.stringify(customer))
    <div>
      {customer.phone_no}
    </div>
  );
  // Triggered on clear
  onSuggestionsClearRequested = () => {
    this.setState({
      products: []
    });
  };

  render() {
    const { handleSubmit, pristine, reset, payments,coupons, submitting, sellers }
      = this.props;
    const {
      isShipping,
      value,
      totalAmount,
      totalQuantity,
      oderList,
      customer_name,
      customer_suggesion,
      seller,
      errorvendor,
      coupon
    } = this.state;
    // alert(customer_name)
    const inputCustomerProps = {
      placeholder: 'Type Your Customer Mobile No or Email or Name',
      value: customer_name,
      type: "search",
      name: 'textBoxValue',
      onChange: this.onChangeCustomer
    };
    // Option props
    const inputProps = {
      placeholder: 'Type Product Name or Product Sku Here',
      value,
      type: "search",
      onChange: this.onChange
    };
    if (this.props.is_added) {
      setTimeout(function () {
        window.location.href = '/Order-list'
      },
        3000);
    }
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Add Order</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Add Order Form</h3>
            <div className="float-right">
              <Link
                to="/order-list"
                className="btn btn-primary float-right text-white"
              >
                <b>
                  <i className="fa fa-backward"></i>
                  Back To List
                </b>
              </Link>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(this.submit.bind(this))}
          // onChange={this.handleChangeForm}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label>Select Customer</label>
                  <span className="requiredField">*</span>
                  <Autosuggest
                    suggestions={this.props.customers ? this.props.customers : []}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCustomer}
                    onSuggestionsClearRequested={this.onSuggestionsCustomerClearRequested}
                    getSuggestionValue={this.getSuggestionCustomerValue}
                    renderSuggestion={this.renderSuggestionCustomer}
                    inputProps={inputCustomerProps}

                    onSuggestionSelected={this.onSuggestionSelectedCustomer}
                  />

                </div>
                {/* <div className="col-md-6">
                  <label>Merchent / vendor </label>
                  <span className="requiredField">*</span>
                  <Select
                    value={seller}
                    onChange={this.vendorChange}
                    options={sellers}
                    component={renderField}
                    placeholder="Merchent / vendor"
                  />{errorvendor ? <span className="text-danger">Select  Vendor</span> : ''}
                </div> */}
                {/* <div className="col-md-6">
                  <Field
                    name="coupon_code"
                    type="date"
                    showTime={true}
                    component={renderField}
                    label="Coupon Code"
                    // required={true}
                    // validate={[required]}

                  // warn={alphaNumeric}
                  />
                </div> */}
                {/* <div className="col-md-6">
                  <label>Coupon Code </label>
                  
                  <Select
                    value={coupon}
                    onChange={this.couponChange}
                    options={coupons}
                    component={renderField}
                    placeholder="Coupon Code"
                  />
                </div> */}
                {/* <div className="col-md-6">
                  <label>Coupon Code</label>
                  <Field
                    name="coupon_code"
                    component="select"
                    className="form-control"
                    label="Coupon Code"
                  >
                    <option value="">--Select Coupon code--</option>
                    {coupons.map((order, index) => (
                      <>
                      
                      <option key={index} value={order.id}>{order.coupon_type==2&& order.coupon_code}</option>
                      
                       </>
                    ))}
                  </Field>
                </div> */}
                <div className="col-md-6">
                  <label>Payment Method</label>
                  <Field
                    name="payment_method"
                    component="select"
                    className="form-control"
                    label="Payment Method"
                    validate={required}
                  >
                    <option value="">--Select Payment Method--</option>
                    {payments.map((payment, index) => (
                      <option key={index} value={payment.id}>{payment.payment_type===1? payment.account_number:payment.wallet_number}</option>

                    ))}
                  </Field>
                </div>
                <div className="col-md-6">
                  <label>Order Status</label>
                  <Field
                    name="order_status"
                    component="select"
                    className="form-control"
                    label="Order Status"
                  >
                    <option value="">--Select Order Status--</option>
                    {OrderStatus.map((order, index) => (
                      <option key={index} value={order.id}>{order.status}</option>

                    ))}
                  </Field>
                </div>

                <div className="col-md-6">
                  <label>Payment Status</label>
                  <Field
                    name="payment_status"
                    component="select"
                    className="form-control"
                    label="Payment Status"
                  // onChange={this.handleSelectedValues(idx)}
                  >
                    <option value="">--Select Payment Status--</option>
                    {PaymentStatus.map((payment_st, index) => (
                      <option key={index} value={payment_st.id}>{payment_st.status}</option>

                    ))}
                  </Field>
                </div>
                <div className="col-md-6">
                  <Field
                    name="order_date"
                    type="date"
                    showTime={true}
                    component={renderDateTimePicker}
                    label="Order Date"
                    required={true}
                    validate={[required]}

                  // warn={alphaNumeric}
                  />
                </div><br />
                {customer_suggesion != '' ? (
                  <div className="col-md-12">
                    <h6>Billing Address</h6>
                    <table class="table">

                      <tr>
                        <td>
                          <strong>Name </strong>
                        </td>
                        <td >
                          {customer_suggesion.first_name }
                        </td>
                        <td>
                          <strong> Phone No</strong>
                        </td>
                        <td >
                          {customer_suggesion.phone_no}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email</strong>
                        </td>
                        <td >
                          {customer_suggesion.email}
                        </td>
                        <td>
                          <strong>Billing Address</strong>
                        </td>
                        <td >
                          {customer_suggesion.address}
                        </td>


                      </tr>
                      <tr>
                        {/* <td>
                          <strong>Post Code</strong>
                        </td>
                        <td>
                          {customer_suggesion.post_code}
                        </td> */}

                        <td>
                          <strong>District Name</strong>
                        </td>
                        <td>
                          {customer_suggesion.districts ? customer_suggesion.districts.name:''}
                        </td>
                      </tr>
                    </table>
                  </div>
                ) : null
                }

                <div className="col-md-12">
                  <input type="checkbox" onChange={this.isShippingChange} />
                  <span>Delivery Address is different from Billing Address</span>
                </div>
                <br />
                {isShipping ? (
                  <>
                    <h4>Delivery Address</h4>
                    <div className="row">
                      <div className="col-md-3">
                        <Field
                          name="first_name"
                          type="text"
                          component={renderField}
                          label="Full Name"
                          validate={isShipping ? required : false}
                          required={true}

                        // warn={alphaNumeric}
                        />
                      </div>
                      {/* <div className="col-md-3">
                        <Field
                          name="last_name"
                          type="text"
                          component={renderField}
                          label="Last Name"
                          validate={isShipping ? required : false}
                          required={true}


                        // warn={alphaNumeric}
                        />
                      </div> */}
                      <div className="col-md-3">
                        <Field
                          name="phone_no"
                          type="text"
                          component={renderField}
                          label=" Phone No"
                          validate={isShipping ? [required, phoneNumber] : false}
                          required={true}

                        // warn={alphaNumeric}
                        />
                      </div>
                      <div className="col-md-3">
                        <Field
                          name="email"
                          type="text"
                          component={renderField}
                          label="Email"
                          validate={isShipping ? [email] : false}
                          // required={true}

                        // warn={alphaNumeric}
                        />
                      </div>

                      <div className="col-md-3">
                        <Field
                          name="post_code"
                          type="text"
                          component={renderField}
                          label="Post Code"

                        // validate={[required]}

                        // warn={alphaNumeric}
                        />
                      </div>
                      <div className="col-md-3">
                        <label>District</label>
                        <Field
                          name="district"
                          component="select"
                          className="form-control"

                          label="District"
                          validate={isShipping ? required : false}
                          required={true}

                        >
                          <option value=""  >--Select District--</option>
                          {this.props.districts.map((district, index) => (
                            <option key={index} value={district.value}>{district.label}</option>
                          ))}

                        </Field>
                      </div>
                      <div className="col-md-6">
                        <Field
                          name="shipping_address"
                          type="text"
                          component={renderTextArea}
                          label="Shipping Address"
                          validate={isShipping ? required : false}
                        // warn={alphaNumeric}
                        />
                      </div>


                    </div>
                  </>
                ) : null
                }
                {/* <div className="col-md-12">
                <div className="card-body input-wrapper input-wrapper-inline input-coupon">
                    <input type="text" onChange={this.onChangeText} name="coupon_code"
                            className="input-text form-control"
                            id="coupon_code" placeholder="Coupon code"/><br/>
                    <button onClick={this.CouponDiscount}
                      type="button"
                            className="btn btn-primary btn-order apply-coupon">Apply Coupon
                    </button>

                </div>
                </div> */}
                  {/* <div className="col-md-6">
                  
                  <Field
                    name="coupon_code"
                    component={renderField}
                    className="form-control"
                    label="Coupon Code"
                  >
                  
                  </Field>
                </div>
                <div className="col-md-6">
                  <label>Apply Coupon Code</label><br/>
                  <button onClick={this.CouponDiscount} className="col-md-4 btn btn-primary btn-order apply-coupon">Apply Coupon
                  </button>
                </div> */}
                <div className="col-md-12">
                  <label>Type Your Product</label>
                  <span className="requiredField">*</span>
                  <Autosuggest
                    suggestions={this.props.products ? this.props.products : []}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    onSuggestionSelected={this.onSuggestionSelected}
                  />
                </div><br />
                {oderList.length > 0 ? (
                  <>
                    <div className="col-md-12">
                      <strong>Order List</strong>
                    </div>
                    <div className="col-md-12">
                      <table className="table orderlist">
                        <tr>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Product Sku</th>
                          {/* <th>Vendor/Seller</th>
                         <th>Product Weight</th> */}
                          <th >Quantity</th>
                          <th>Price</th>
                          {/* <th>Discount</th> */}
                          <th>Subtotal</th>
                          <th>Action</th>
                        </tr>
                        <tbody>
                          {this.state.oderList.map((product, index) => (

                            <tr id={product.id} key={index}>
                              <td>
                                <img src={product.feature_image} height="100px" width="100px" alt="No Image" />
                              </td>
                              <td>
                                {product.product_name}
                              </td>
                              <td>
                                {product.product_sku}
                              </td>
                              {/* <td>
                                {product.vendor ? product.vendor.shop_name : ''}
                              </td>
                              <td>

                               <div class="input-group plus-minus-input">
                                 
                                 <input class="input-group-field form-control" type="number" name="unit_amount" value={product.unit_amount} onChange={(e)=>this.onChangesProductWeight(index,e)} />
                               
                               </div>
                             </td> */}
                              <td>

                                <div class="input-group plus-minus-input">
                                  <div class="input-group-button">
                                    <button type="button" class="btn btn-success" onClick={() => this.manageQuantity(index, "minus")}>
                                      <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                  <input class="input-group-field form-control" type="number" name="quantity" value={product.quantity} />
                                  <div class="input-group-button">
                                    <button type="button" class="btn btn-primary" onClick={() => this.manageQuantity(index, "plus")}>
                                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>

                               <div class="input-group plus-minus-input">
                              
                                 <input class="input-group-field form-control" type="number" name="regular_price" value={product.regular_price} onChange={(e)=>this.onChanges(index,e)} />
                                
                               </div>
                             </td>
                              {/* <td>
                                {product.regular_price}
                              </td> */}
                              {/* <td>
                                {product.discount_price ? product.discount_price : 0}
                              </td> */}
                              <td colSpan="1">
                              <div class="input-group plus-minus-input">
                              
                              <input class="input-group-field form-control" type="number" name="subtotal" value={product.subtotal ? product.subtotal : 0} onChange={(e)=>this.onChangesSubtotal(index,e)} />
                           
                            </div>
                                {/* {product.subtotal ? product.subtotal : 0} */}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger" id={product.id}
                                  onClick={() => this.deteteRow(product)}
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  />
                                </button>

                              </td>
                            </tr>
                          ))}
                          <tr>
                           <td>Discount</td>
                          <td></td>
                           <td></td>
                           <td></td>
                           <td></td>
                             {/* {order.discount_amount !==null? (order.discount_amount).toFixed(2):0} */}
                             <td>
                             <div class="input-group plus-minus-input">
                             
                             <input class="input-group-field form-control" type="number" name="discount_amount" value={this.state.discount_amount} onChange={this.onChangeText} />
                            
                             </div>
                             </td>
                             
                            
                      
                           
                         </tr>
                           {/* <tr>
                            <th colSpan="3" className="">Discount</th>
                            <th></th>
                            <th colSpan="4" className="text-center"></th>
                            <th colSpan="3" className="center">{this.state.discount_amount !==null? (this.state.discount_amount).toFixed(2):0}</th>

                          </tr>
                          <tr>
                            <th colSpan="3" className="">Delivery Charge</th>
                            <th></th>
                            <th colSpan="4" className="text-center"></th>
                            <th colSpan="3" className="center">{this.state.shipping_charge !==null? this.state.shipping_charge:0}</th>

                          </tr> */}
                          {/* <tr>
                            <th colSpan="3" className="">Total</th>
                            <th></th>
                            <th colSpan="4" className="text-center">{totalQuantity}</th>
                            <th colSpan="3" className="center">{this.state.discount_amount !==null && this.state.shipping_charge !==null ?totalAmount+this.state.shipping_charge-this.state.discount_amount:totalAmount+this.state.shipping_charge}</th>

                          </tr> */}
                          <tr>
                           <td>Total</td>
                          <td></td>
                           <td></td>
                           <td>{totalQuantity}</td>
                           <td></td>
                             {/* {order.discount_amount !==null? (order.discount_amount).toFixed(2):0} */}
                             <td>
                             {this.state.discount_amount !==null && this.state.shipping_charge !==null ?totalAmount+this.state.shipping_charge-this.state.discount_amount:totalAmount+this.state.shipping_charge}
                             </td>
                             
                            
                      
                           
                         </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null
                }
              </div>

            </div>

            <div className="card-footer">
              {oderList.length > 0 ? (
                <div>
                  <button
                    type="submit"
                    className="btn btn-success float-right"
                    disabled={submitting}
                  >
                    <i className="fa fa-save "></i>Create Order
                </button>


                </div>
              ) : null

              }
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AddOrderForm.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  searchCustomer: PropTypes.func.isRequired,
  addData: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
  products: state.products.products,
  payments: state.payments.payments,
  customers: state.customers.customers,
  is_added: state.customers.is_added,
  districts: state.districts.districts,
  sellers: state.sellers.sellers,
  coupons:state.coupons.coupons




});
AddOrderForm = connect(
  mapStateToProps,
  {
    searchProducts, fetchPaymentMethods,fetchCoupons, searchCustomer, addData, getDistrictsForSelect, fetchSellersForSelect
  }
)(AddOrderForm)

export default reduxForm({
  form: "AddOrderForm",
  enableReinitialize: true
})(AddOrderForm);

import React from "react";
import ReactDOM from "react-dom";
import Pdf from "react-to-pdf";
import { Page, Text, View, Document, StyleSheet,PDFViewer } from '@react-pdf/renderer';

// import "../../components/pdf/style.css";
const ref = React.createRef();

const styles = StyleSheet.create({
  page: { backgroundColor: 'tomato' },
  section: {
    width: 200,
    '@media max-width: 400': {
      width: 300,
    },
    '@media orientation: landscape': {
      width: 400,
    },
  }
});



const Report = (addr) => {
  const handleClick = () => {
    console.log("clcik");
  };
  return (
    <div className="App">
      <Pdf targetRef={ref} filename="example.pdf">
        {({ toPdf }) => (
          <button
            onClick={() => {
              toPdf();
              handleClick();
            }}
          >
            Generate Pdf
          </button>
        )}
      </Pdf>
   
    <Document>
    {/* <PDFViewer> */}
        <Page size="A4" style={styles.page}>
          <div  ref={ref} style={styles.section}>
            <div>
            <p style={{textAlign:"center"}}>Hello</p>
            </div>
          
          </div>  
          </Page>
    {/* </PDFViewer>   */}
    </Document>
    
 </div>  
  );
};

export default Report;
import {
    LOADING_USER,
    FETCH_USER,
    DETAIL_USER,
    UPDATE_USER,
    DELETE_USER,
    NOT_FOUND_USER,

} from '../actions/types';

const initialState = {
    users: [],
    user: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_list: false,
    is_updated:false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_USER:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_USER:
            return {
                ...state,
                users: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                // is_list: true,
            };
        case UPDATE_USER:
            return {
                ...state,
                users: action.payload,
                is_loading: false,
                is_updated:true
            };
        case DETAIL_USER:
            return {
                ...state,
                users: action.payload,
                is_loading:true
                //        director_detail: action.payload,
            };
        case DELETE_USER:
            return {
                ...state,
                users: state.users.filter(user => user.id !== action.payload)
            };

        case NOT_FOUND_USER:
            return {
                ...state,
                users: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { email, phoneNumber, required } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {  fetchSupportTickets } from "../../../actions/SupportTicketActions"
import {  getAllUsers } from "../../../actions/Users"

import {searchCustomer,addData} from '../../../actions/CustomerActions'



import Autosuggest from 'react-autosuggest';

import { toast } from "react-toastify";
import { SUPPORT_TICKET_URL ,LOAD_CUSTOMER_WITHOUT_USER_URL} from "../../../constants/api";
import { renderTextArea } from '../../../constants/FormInputType';





// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddSupportTicket extends Component {
    state = {
        customer_name: '',
      };
    componentDidMount() {

        this.props.fetchSupportTickets(0)
        this.props.getAllUsers(LOAD_CUSTOMER_WITHOUT_USER_URL)

    }
   


  
    submit(event) {
        const {
            customer_name,
           } = this.state;
        //var form_data={}
        //Object.keys(event).forEach(key => form_data.append(key, event[key]));
        event["user"] = customer_name  
        if (event.assign_to !== ''){
            event["status"] = 2  
        } 
        this.props.addData(event, SUPPORT_TICKET_URL, "Support Ticket")




    }
    onChangeCustomer = (event, { newValue }) => {
        // alert(JSON.stringify(newValue))
        this.setState({
          customer_name: newValue
        });
        // alert(this.state.customer_name)
      };
    
      
    
    
    
    
      getCustomerSuggestions = customer_name => {
        if (customer_name !== undefined) {
          const inputValue = customer_name.trim().toLowerCase();
          const inputLength = inputValue.length;
          this.props.searchCustomer(customer_name);
          return inputLength === 0 ? [] : this.props.customers.filter(customer =>
            customer.phone_no.toLowerCase().slice(0, inputLength) === inputValue
          );
        }
      };
    
      onSuggestionsCustomerClearRequested = () => {
        this.setState({
          customers: []
        });
      };
      getSuggestionCustomerValue = customer => customer.phone_no;
    
      onSuggestionsFetchRequestedCustomer = ({ value }) => {
       
        this.setState({
          customers: this.getCustomerSuggestions(value)
        });
      };
    
      onSuggestionSelectedCustomer = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    
       
      };
      renderSuggestionCustomer = customer => (
        // alert(JSON.stringify(customer))
        <div>
          {customer.phone_no}
        </div>
      );
      // Triggered on clear
     

    render() {

        const { handleSubmit, submitting,all_users } = this.props;
        const {

            customer_name
          } = this.state;
          // alert(customer_name)
          const inputCustomerProps = {
            placeholder: 'Type Your Customer Mobile No or Email or Name',
            value: customer_name,
            type: "search",
            name: 'textBoxValue',
            onChange: this.onChangeCustomer
          };
          // Option props
          
          if (this.props.is_added) {
            setTimeout(function () {
              window.location.href = '/support-ticket-list'
            },
              3000);
          }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Support Ticket </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Support Ticket add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Support Ticket Information</h3>
                        <div className="float-right">
                            <Link to="/support-ticket-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                               
                                <div className="col-md-6">
                                <label>Select Customer</label>
                                <span className="requiredField">*</span>
                                <Autosuggest
                                suggestions={this.props.customers ? this.props.customers : []}
                                onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCustomer}
                                onSuggestionsClearRequested={this.onSuggestionsCustomerClearRequested}
                                getSuggestionValue={this.getSuggestionCustomerValue}
                                renderSuggestion={this.renderSuggestionCustomer}
                                inputProps={inputCustomerProps}

                            //  onSuggestionSelected={this.onSuggestionCustomerSelected}
                            />                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="subject"
                                        type="text"
                                        component={renderField}
                                        label=" Subject"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="priority"
                                        type="text"
                                        component={renderField}
                                        label="Priority"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <label>Priority</label>
                                    <span className="requiredField">*</span>
                                    <Field name="priority" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select priority</option>
                                        <option key='1' value='1'>Low</option>
                                        <option key='2' value="2">Medium</option>
                                        <option key='3' value="3">High</option>

                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="description"
                                        type="text"
                                        component={renderTextArea}
                                        label="Message"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label>Assigned To</label>
                                    <span className="requiredField">*</span>
                                    <Field name="assigned_to" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select Assignee</option>
                                        {all_users.map((user, index) => (
                                            <option key={index} value={user.id}>{user.first_name} {user.last_name}</option>

                                         ))}
                                        

                                    </Field>
                                </div>
                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddSupportTicket.propTypes = {
    getAllUsers:PropTypes.func.isRequired,
    fetchSupportTickets: PropTypes.func.isRequired,
    addData: PropTypes.func.isRequired,
    searchCustomer: PropTypes.func.isRequired,


};

const mapStateToProps = state => ({

    support_tickets: state.customers.customers,
    customers: state.customers.customers,
    is_added: state.customers.is_added,
    all_users: state.all_users.all_users,


});


AddSupportTicket = reduxForm({
    form: 'AddSupportTicket',
}
)(AddSupportTicket);


export default connect(mapStateToProps, { addData, fetchSupportTickets,searchCustomer,getAllUsers })(AddSupportTicket);

import {
    LOADING_AGENT_REPORTS,
    FETCH_AGENT_REPORTS,
    DETAIL_AGENT_REPORTS,
    DELETE_AGENT_REPORTS,
    NOT_FOUND_AGENT_REPORTS,
    LOADING_PRODUCT_STOCK_REPORTS,
    FETCH_PRODUCT_STOCK_REPORTS,
    DETAIL_PRODUCT_STOCK_REPORTS,
    DELETE_PRODUCT_STOCK_REPORTS,
    NOT_FOUND_PRODUCT_STOCK_REPORTS,
    LOADING_SALES_REPORTS,
    FETCH_SALES_REPORTS,
    DETAIL_SALES_REPORTS,
    DELETE_SALES_REPORTS,
    NOT_FOUND_SALES_REPORTS

} from '../actions/types';


export const AgentWiseReportsReducer = (state = {
                agents_reports: [],
                agents_report: {},
                count: null,
                next: null,
                bulk: false,
                is_added: false,
                is_loading: false,
                is_getting: false,
                is_updated: false,
                get_agents_reports: false,
            }, action) => {
    switch (action.type) {
        case LOADING_AGENT_REPORTS:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_AGENT_REPORTS:
            return {
                ...state,
                agents_reports: action.payload,
                count: action.count,
                get_agents_reports: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_AGENT_REPORTS:
            return {
                ...state,
                is_getting: true,
                agents_report: action.payload,
                
            };
        case DELETE_AGENT_REPORTS:
            return {
                ...state,
                agents_reports: state.agents_reports.filter(agents_report => agents_report.id !== action.payload)
            };
        
        case NOT_FOUND_AGENT_REPORTS:
            return {
                ...state,
                agents_reports: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const ProductStockReportsReducer = (state = {
    product_stock_reports: [],
    product_stock_report: {},
    count: null,
    next: null,
    bulk: false,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    get_product_stock: false,
        }, action) => {
        switch (action.type) {
            case LOADING_PRODUCT_STOCK_REPORTS:
                return {
                ...state,
                is_loading: true
            };
            case FETCH_PRODUCT_STOCK_REPORTS:
                return {
                ...state,
                product_stock_reports: action.payload,
                count: action.count,
                get_agents_reports: true,
                next: action.next,
                is_loading: false
            };
            case DETAIL_PRODUCT_STOCK_REPORTS:
                return {
                ...state,
                is_getting: true,
                product_stock_reports: action.payload,
                
            };
            case DELETE_PRODUCT_STOCK_REPORTS:
                return {
                ...state,
                product_stock_reports: state.product_stock_reports.filter(product_stock_report => product_stock_report.id !== action.payload)
            };

            case NOT_FOUND_PRODUCT_STOCK_REPORTS:
                return {
                ...state,
                product_stock_reports: [],
                count: 0,
                next: 0,
                is_loading: false
            };
            default:
                return state;
            }
}





export const SalesReportReducer = (state = {
    sales_reports: [],
    sales_report: {},
    count: null,
    next: null,
    bulk: false,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    get_sales_reports: false,
        }, action) => {
        switch (action.type) {
            case LOADING_SALES_REPORTS:
                return {
                ...state,
                is_loading: true
            };
            case FETCH_SALES_REPORTS:
                return {
                ...state,
                sales_reports: action.payload,
                count: action.count,
                get_sales_reports: true,
                next: action.next,
                is_loading: false
            };
            case DETAIL_SALES_REPORTS:
                return {
                ...state,
                is_getting: true,
                sales_reports: action.payload,
                
            };
            case DELETE_SALES_REPORTS:
                return {
                ...state,
                sales_reports: state.sales_reports.filter(sales_report => sales_report.id !== action.payload)
            };

            case NOT_FOUND_SALES_REPORTS:
                return {
                ...state,
                sales_reports: [],
                count: 0,
                next: 0,
                is_loading: false
            };
            default:
                return state;
            }
}
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import {
    required
} from '../../../constants/Validate'
import { connect } from "react-redux";
import { PercelPlanDetail } from "../../../actions/RestaurantActions";
import { updateDatas } from "../../../actions/CustomerActions";


import PropTypes from "prop-types";
import moment from 'moment';
import { Link } from "react-router-dom";

import momentLocalizer from 'react-widgets-moment';

import { PERCEL_PLAN_URL } from '../../../constants/api';



momentLocalizer(moment);



// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};

// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class PercelPlanUpdate extends Component {

    componentDidMount() {
        // alert(rideCategoryDetail)
        let id = this.props.match.params.id;
        this.props.PercelPlanDetail(id)
       
    }


    submit(values) {
        // console.log(values)
        // return sleep(1000).then(() => {
        //     let id = this.props.match.params.id;
        //     this.props.updateData(id, values);
        // })

        let form_data = {
            'name':values.name,
           
        }
        
        
        let id = this.props.match.params.id;
        this.props.updateDatas(form_data, PERCEL_PLAN_URL + id + '/', "Percel Plan")
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        //console.log('ride_category_update_data', rideCategoryDetails);
        // console.log('update', rideCategoryDetails)
        if (this.props.is_updated) {
            setTimeout(() => {
                window.location.href = '/percel-plan-list'
            }, 3000)
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Percel Type Information</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            {/* <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item active">Dashboard v2</li>
                                    </ol>
                                </div><!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="">Update Percel Plan Details
                        {/* <Link to="/cuisine-list" className="btn btn-info float-right text-white"><b>
                                Back</b>
                        </Link> */}
                            </h3>

                    </div>
                    {/* /.card-header */}
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">

                                
                            <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="Percel Plan Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                


                            </div>
                            <div className="card-footer text-right">
                                <div>
                                    <button
                                        type="submit"
                                        className="btn btn-info"
                                        disabled={submitting}>
                                        Update
                                </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


PercelPlanUpdate.propTypes = {
    PercelPlanDetail: PropTypes.func.isRequired,
    updateDatas: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    initialValues: state.percel_plans.percel_plan,
    percel_plans:state.percel_plans.percel_plan,
    
    is_added: state.customers.is_added,
    is_updated: state.customers.is_updated

});
PercelPlanUpdate = reduxForm({
    form: 'PercelPlanUpdate',
    enableReinitialize: true,
}
)(PercelPlanUpdate);

export default connect(mapStateToProps, { PercelPlanDetail, updateDatas })(PercelPlanUpdate);
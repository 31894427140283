import {
    USER_LOADING,
    USER_LOADED,
    AUTHENTICATION_ERROR,
    LOGOUT_SUCCESSFUL,
    LOGIN_FAILED,
    LOGIN_SUCCESSFUL,
    REGISTRATION_SUCCESSFUL,
    REGISTRATION_FAILED,
    PASSWORD_RESET_SUCCESS,
    PASSWORD_RESET_FAIL,
    PASSWORD_RESET_CONFIRM_SUCCESS,
    PASSWORD_RESET_CONFIRM_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL
} from '../actions/types';

const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    isLoading: true,
    user: [],
};


export default function auth(state = initialState, action) {

    switch (action.type) {

        case USER_LOADING:
            return {
                ...state,
                isLoading: true
            };

        case USER_LOADED:
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                user: action.user
            };

        case LOGIN_SUCCESSFUL:
        case REGISTRATION_SUCCESSFUL:
            localStorage.setItem("token", action.data.token);
            return {
                ...state,
                ...action.data,
                isAuthenticated: true,
                isLoading: false,
                errors: null
            };

        case AUTHENTICATION_ERROR:
        case LOGIN_FAILED:
        case REGISTRATION_FAILED:
        case LOGOUT_SUCCESSFUL:
            // alert(action.data)
            localStorage.clear();
            return {
                ...state,
                errors: action.data,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false
            };
        case PASSWORD_RESET_SUCCESS:
        case PASSWORD_RESET_FAIL:
        case PASSWORD_RESET_CONFIRM_SUCCESS:
        case PASSWORD_RESET_CONFIRM_FAIL:
        case ACTIVATION_SUCCESS:
        case ACTIVATION_FAIL:
            return {
                ...state
            }


        default:
            return state;
    }
}

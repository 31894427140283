import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match, dateTime } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchCoupons, addData, deleteCoupon } from "../../../actions/CouponActions"
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'


import { toast } from "react-toastify";
import { COUPON_CODE_URL, GET_PRIMARY_CATEGORY_URL } from "../../../constants/api";


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

// const submitToServer = (data) => {

//     console.log('data', data);


//     var form_data = { "name": data.name };

//     console.log('data', form_data);


//     let url = CUSTOMER_URL;

//     let token = localStorage.getItem('token');
//     // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
//     console.log(token);
//     axios.post(url, form_data,
//         {
//             headers: {
//                 'content-type': 'application/json',
//                 'Authorization': `Token ${token}`
//             }

//         })
//         .then(res => {
//             console.log(res.data);
//             // dispatch(reset('PartnerAddForm'))
//             toast.success('Successfully added a new Primary Category.')
//         })
//         .catch(err => console.log(err))
// };

// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
    <div>
        <label className="text-sm">{label}</label>
        <DateTimePicker
            onChange={onChange}
            format="DD-MM-YYYY"
            time={showTime}
            value={!value ? null : new Date(value)}
            placeholder={label}
        />
    </div>;



// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddCoupon extends Component {


    state={
        is_free_delivery:false
    }

    componentDidMount() {
        console.log(this.props.fetchCoupons())
        this.props.fetchCoupons(0)
    }

    IsFreeDelivery = (e) => {
        // const { is_color } = this.state
        // alert(JSON.stringify(e.target.value))
        let value = e.target.value

        if (value === "1" || value === "2") {
            this.setState({ is_free_delivery: false })
        } else {
            this.setState({ is_free_delivery: true })
        }

    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }

    submit(event) {
        // const { reset } = this.props;
        // return sleep(1000)
        //     .then(() => {
        //         submitToServer(values);
        //         reset()
        //     })
        // let start_date = dateTime(event.start_date)
        // let end_date = dateTime(event.end_date)

        var form_data = {
            coupon_name: event.coupon_name,
            coupon_code: event.coupon_code,
            used: event.used,
            discount_type: event.discount_type,
            coupon_type: event.coupon_type,
            amount: event.amount,
            discount_amount: event.discount_amount?event.discount_amount:0,
            
        }
        if (event.start_date != '' && event.start_date != undefined) {
            form_data["start_date"]=dateTime(event.start_date ? event.start_date:'');
          }
          if (event.end_date != '' && event.end_date != undefined) {
            form_data["end_date"]= dateTime(event.end_date ? event.end_date:'');
          }

        // var form_data = new FormData();

        // form_data.append("coupon_code", event.coupon_code ? event.coupon_code : '');
        // form_data.append("coupon_value", event.coupon_value ? event.coupon_value : '');
        // form_data.append("coupon_type", event.coupon_type ? event.coupon_type : '');
        // form_data.append("cart_min_value", event.cart_min_value ? event.cart_min_value : '');
        // if (event.user_photo != '') {
        //     form_data.append("user_photo", event.user_photo ? event.user_photo : '');
        // }

        this.props.addData(form_data, COUPON_CODE_URL, "Coupon Code")




    }

    render() {


        const { handleSubmit, submitting, } = this.props;
        if (this.props.is_added) {
            setTimeout(() => {
                window.location.href = "/coupon-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Coupon </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Coupon add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Coupon Information</h3>
                        <div className="float-right">
                            <Link to="/coupon-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="coupon_name"
                                        type="text"
                                        component={renderField}
                                        label="Coupon Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="coupon_code"
                                        type="text"
                                        component={renderField}
                                        label="Coupon Code"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Coupon Type</label>
                                    <span className="requiredField">*</span>
                                    <Field name="coupon_type" component="select" className="form-control"
                                    validate={[required]}
                                    // onClick={this.IsFreeDelivery}
                                    >
                                        <option value="">Select Coupon Type</option>
                                        <option value="1">Food</option>
                                        <option value="2">Shop</option>
                                        


                                    </Field>
                                </div>

                                <div className="col-md-6">
                                    <label>Discount Type</label>
                                    <span className="requiredField">*</span>
                                    <Field name="discount_type" component="select" className="form-control"
                                    validate={[required]}
                                    onClick={this.IsFreeDelivery}
                                    >
                                        <option value="">Select Discount Type</option>
                                        <option value="1">Discount by Percentage(%)</option>
                                        <option value="2">Discount by Amount</option>
                                        <option value="3">Free Delivery</option>


                                    </Field>
                                </div>
                               

                                <div className="col-md-6">
                                    <Field
                                        name="used"
                                        type="text"
                                        component={renderField}
                                        label="Used Limit"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="amount"
                                        type="text"
                                        component={renderField}
                                        label="Minimum Purchase Amount"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {!this.state.is_free_delivery?
                                <div className="col-md-6">
                                    <Field
                                        name="discount_amount"
                                        type="text"
                                        showTime={false}
                                        component={renderField}
                                        label="Discount Amount"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                :''}

                                <div className="col-md-6">
                                    <Field
                                        showTime={false}
                                        name="start_date"
                                        type="date"
                                        component={renderDateTimePicker}
                                        label="Start Date"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        showTime={false}
                                        name="end_date"
                                        type="date"
                                        component={renderDateTimePicker}
                                        label="End Date"
                                    />
                                </div>



                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddCoupon.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchCoupons: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({


    coupons: state.coupons.coupons,
    is_added: state.coupons.is_added

});


AddCoupon = reduxForm({
    form: 'AddCoupon',
}
)(AddCoupon);


export default connect(mapStateToProps, { fetchCoupons, addData })(AddCoupon);

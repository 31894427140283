import {
    LOADING_WAREHOUSE,
    FETCH_WAREHOUSE,
    NOT_FOUND_WAREHOUSE,
    DELETE_WAREHOUSE,
    DETAIL_WAREHOUSE,
    LOADING_STOCK,
    FETCH_STOCK,
    NOT_FOUND_STOCK,
    DETAIL_STOCK,

} from './types';

import { GET_STOCK, GET_WAREHOUSE_LIST} from '../constants/api';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { SearchURLGenerate } from '../constants/CustomMethod'







// FETCH Warehouse

export const fetchWarehouse = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_WAREHOUSE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
         headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["warehouse_name",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_WAREHOUSE_LIST
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                dispatch({
                    type: FETCH_WAREHOUSE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_WAREHOUSE });

    }
};
//*****************End Warehouse Fetch Action ********************** */


// Warehouse Detail
export const WarehouseDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_WAREHOUSE_LIST + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_WAREHOUSE,
                payload: response.data,
            })
        })
};
//*****************End Warehouse Detail Action ********************** */



// // Delete Warehouse
export const deleteWarehouse = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(GET_WAREHOUSE_LIST + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_WAREHOUSE,
                payload: id,
            });
            toast.success('Warehouse has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//*****************End Warehouse Delete Action ********************** */





// FETCH Stock

export const fetchStock = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_STOCK });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
         headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["warehouse_name",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_STOCK
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                dispatch({
                    type: FETCH_STOCK,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_STOCK });

    }
};
//*****************End Stock Fetch Action ********************** */


// Stock Detail
export const StockDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_WAREHOUSE_LIST + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_STOCK,
                payload: response.data,
            })
        })
};
//*****************End Stock Detail Action ********************** */
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match } from '../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { updateData } from "../../actions/CampaignActions"
import { returnDetail, deleteReturn } from "../../actions/ReturnActions"
import { fetchOrders,searchOrderNo } from "../../actions/orderActions"
import Autosuggest from 'react-autosuggest';


import { ReasonStatus,TransactionStatus, PackageStatus, ResulotionStatus } from "../../constants/Datas"
// import { fetchGroups } from "../../actions/GroupAction"

import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import { toast } from "react-toastify";
import { RETURN_REFUND_URL, GET_PRIMARY_CATEGORY_URL } from "../../constants/api";
import { renderTextArea } from '../../constants/FormInputType';


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

// const submitToServer = (data) => {

//     console.log('data', data);


//     var form_data = { "name": data.name };

//     console.log('data', form_data);


//     let url = CUSTOMER_URL;

//     let token = localStorage.getItem('token');
//     // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
//     console.log(token);
//     axios.post(url, form_data,
//         {
//             headers: {
//                 'content-type': 'application/json',
//                 'Authorization': `Token ${token}`
//             }

//         })
//         .then(res => {
//             console.log(res.data);
//             // dispatch(reset('PartnerAddForm'))
//             toast.success('Successfully added a new Primary Category.')
//         })
//         .catch(err => console.log(err))
// };

// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
    <div>
        <label className="text-sm">{label}</label>
        <DateTimePicker
            onChange={onChange}
            format="DD-MM-YYYY"
            time={showTime}
            value={!value ? null : new Date(value)}
            placeholder={label}
        />
    </div>;


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class TransactionUpdate extends Component {
    constructor(){
        super()
    }
    state ={
        value:"",
        orders:[],
        loading:false
    }

    componentDidMount() {
       
        let id = this.props.match.params.id
        this.props.returnDetail(id)
        this.props.searchOrderNo(1)
    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }
    static getDerivedStateFromProps(nextProps, prevState) {
        // alert(JSON.stringify(nextProps.initialValues.order.order_no))
        if (nextProps.is_loading ===true && nextProps.initialValues.order !==undefined){
          return{
              
              value:nextProps.initialValues.order.order_no,
            
          }
  
        }   
        return null;
      }
// Filter logic
getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    this.props.searchOrderNo(value);
    return inputLength === 0 ? [] : this.props.orders.filter(order =>
      order.order_no.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  // Trigger suggestions
  getSuggestionValue = order => order.order_no;

  // Render Each Option
  renderSuggestion = order => (
    <div>
      {order.order_no}
    </div>
  );

  // OnChange event handler
  onChanges = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };

 

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    
    
  };


  // Suggestion rerender when user types
  onSuggestionsFetchRequested = ({ value }) => {
    //alert(value)
    this.setState({
      orders: this.getSuggestions(value)
    });
  };




  
  // Triggered on clear
  onSuggestionsClearRequested = () => {
    this.setState({
      orders: []
    });
  };

    submit(event) {
        // const { reset } = this.props;
        // return sleep(1000)
        //     .then(() => {
        //         submitToServer(values);
        //         reset()
        //     })
        var form_data = {

            // order: event.order,
            requested_reason: event.requested_reason,
            package_condition: event.package_condition,
            requested_resolution: event.requested_resolution,
            policy_information: event.policy_information,

            // adjudgement_fee: event.adjudgement_fee,
        }
        form_data['order']=this.state.value
        // var form_data = new FormData();

        // form_data.append("tax_class_name", event.tax_class_name ? event.tax_class_name : '');
        // form_data.append("description", event.tax_class_name ? event.tax_class_name : '');
        // form_data.append("rate", event.tax_class_name ? event.tax_class_name : '');
        // form_data.append("till_date", event.till_date ? event.till_date : '');
        // if (event.user_photo != '') {
        //     form_data.append("user_photo", event.user_photo ? event.user_photo : '');
        // }
        let id= this.props.match.params.id
        this.props.updateData(form_data, RETURN_REFUND_URL+id+'/', "Return Refund")




    }

    render() {


        const { handleSubmit, submitting, } = this.props;
        const {value,orders}=this.state

        if (this.props.is_updated) {
            setTimeout(() => {
                window.location.href = "/return-list"
            }, 3000);
        }
        const inputProps = {
            placeholder: 'Type Order No',
            value,
            type: "search",
            onChange: this.onChanges
        };
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark"> Transaction update</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Transaction update</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Transaction Information</h3>
                        <div className="float-right">
                            <Link to="/transaction-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                        <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="transaction_id"
                                        type="text"
                                        component={renderField}
                                        label="Transactoin ID"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                        <label> Order No</label>
                                        <span className="requiredField">*</span>
                                        <Autosuggest
                                            name='order'
                                            suggestions={this.props.orders ? this.props.orders : []}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            inputProps={inputProps}
                                            // onSuggestionSelected={this.onSuggestionSelected}
                                        />
                                </div>
                                 <div className="col-md-6">
                                    <Field
                                        name="amount"
                                        type="text"
                                        component={renderField}
                                        label="Amount"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label>Transaction Status</label>
                                    <Field
                                        name="transaction_status"
                                        component="select"
                                        className="form-control"
                                        label="Transaction Status"
                                    >
                                        <option value="">--Select Transaction Status--</option>
                                        {TransactionStatus.map((reason, index) => (
                                            
                                            <option key={index} value={reason.id}>{reason.status}</option>

                                        ))}
                                    </Field>
                                </div>
                                
                                
                                <div className="col-md-6">
                                    <Field
                                        name="transaction_date"
                                        type="date"
                                        component={renderDateTimePicker}
                                        label="Transaction Date"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="adjudgement_fee"
                                        type="text"
                                        component={renderField}
                                        label="Adjudgement Fee"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}



                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


TransactionUpdate.propTypes = {

    returnDetail: PropTypes.func.isRequired,
    searchOrderNo:PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    initialValues:state.return_refunds.return_refunds,
    orders: state.orders.orders,
    return_refunds: state.return_refunds.return_refunds,
    is_updated: state.return_refunds.is_updated,
    is_getting:state.return_refunds.is_getting,
    is_loading:state.return_refunds.is_loading,


});


TransactionUpdate = reduxForm({
    form: 'TransactionUpdate',
    enableReinitialize: true

}
)(TransactionUpdate);


export default connect(mapStateToProps, {searchOrderNo, returnDetail, updateData, fetchOrders })(TransactionUpdate);

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import InputColor from 'react-input-color';
import RichTextEditor from 'react-rte';
import { getAttributeOption, postAttributeOption } from '../../actions/AttributeOptionActions'
import { fetchAttributeAll } from '../../actions/AttributeActions'



import { connect } from 'react-redux';



const required = value => (value || typeof value === 'number' ? undefined : 'Required');



const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined;


const renderField = ({ input, label, type, meta: { touched, error, warning }, required = false }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>{required ? <span className='requiredField'>*</span> : ""}
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};



// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// File Input type
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));


class AddAttributesOption extends Component {
    state = {
        options:
            [
                { label: "Electronics", value: 1 },
                { label: "Fashion", value: 2 },
            ],
        selectedOption: null,
        secondary: null,
        child: null,
        color: {},
        attribute: "",
        is_color: false,
        attribute_id:'',
        text_edit: RichTextEditor.createEmptyValue()

    }

    onChange = (text_edit) => {
        this.setState({ text_edit });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChange(
                text_edit.toString('html')
            );
        }
    };
    colorChange = (e) => {
        let index = e.nativeEvent.target.selectedIndex;

        let value = e.nativeEvent.target[index]? e.nativeEvent.target[index].text :'';
        
        this.setState({attribute_id:e.target.value})
        if (value.toLowerCase() === "color" || value.toLowerCase() === "colour") {
            this.setState({ is_color: true })
        } else {
            this.setState({ is_color: false })
        }

    }
    setColor = (e) => {
        // alert(JSON.stringify(e.hex))
        this.setState({ color: e.hex })
    }
    handleChange = selectedOption => {
        //  alert(selectedOption)
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };
    secondaryChange = secondary => {
        //  alert(selectedOption)
        this.setState({ secondary });
        //   console.log(`Option selected:`, selectedOption);
    };
    childChange = child => {
        //  alert(selectedOption)
        this.setState({ child });
        //   console.log(`Option selected:`, selectedOption);
    };

    componentDidMount() {
        this.props.getAttributeOption(0)
        this.props.fetchAttributeAll()

    }

    submit(values) {
        // alert(JSON.stringify(this.state.color))
        const { reset } = this.props;
        values["color_code"] = this.state.color
        values["attribute"] = this.state.attribute_id
        
        return sleep(1000).then(() => {
            // console.log(values);
            postAttributeOption(values);
            reset();
        });
    }

    render() {
        // alert(this.props.childcategory.name)
        const { handleSubmit, pristine, reset, submitting } = this.props;

        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Attribute Option</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Add Attribute Option</h3>
                        <div className="float-right">
                            <Link to="/attribute-option-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-md-6">
                                    <label>Attribute Name</label>
                                    <span className="requiredField">*</span>
                                    <select name="attribute" component="select" className="form-control"
                                        validate={required} onClick={this.colorChange}>
                                        <option value="" >Select attribute name</option>
                                        {this.props.attributes.map(attribute => (
                                            <option key={attribute.id} value={attribute.id} >{attribute.name}</option>
                                        ))}
                                    </select>
                                    {this.state.is_color ?
                                        <div>
                                            <InputColor
                                                name="color_code"
                                                initialValue="#5e72e4"
                                                onChange={this.setColor}
                                                placement="right"
                                            />
                                            <div
                                                style={{
                                                    width: 50,
                                                    height: 50,
                                                    marginTop: 20,
                                                    backgroundColor: this.state.color.hex
                                                }}
                                            />
                                        </div>
                                        : ''}
                                </div>

                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label=" Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-12">
                                    <RichTextEditor
                                        value={this.state.text_edit}
                                        onChange={this.onChange}
                                    />
                                </div> */}
                            </div>
                        </div>

                        <div className="card-footer">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info float-right"
                                    disabled={submitting}>
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    disabled={pristine || submitting}
                                    onClick={reset}>
                                    Clear
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


AddAttributesOption.propTypes = {
    fetchAttributeAll: PropTypes.func.isRequired,
    onChange: PropTypes.func,

    getAttributeOption: PropTypes.func.isRequired,

}

const mapStateToProps = state => ({
    attribute_options: state.attribute_options.attribute_options,
    attributes: state.attributes.attributes

})

AddAttributesOption = reduxForm({
    form: 'AddAttributesOption',
})(AddAttributesOption);


export default connect(mapStateToProps, { postAttributeOption, getAttributeOption, fetchAttributeAll })(AddAttributesOption);


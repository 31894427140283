import React, { Component } from 'react';
import PropTypes, { func } from 'prop-types';
import { required } from "../../../constants/Validate";
import { Field, reduxForm } from 'redux-form';
import axios from 'axios'
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate'
// import Modal from 'react-modal';
import { Modal, Button } from 'react-bootstrap'
import { BulkAction } from '../../../actions/ProductAction';
import { USER_CREATE_URL,PRODUCT_BULK_URL,SECONDARY_CATEGORY_BULK_URL } from '../../../constants/api'
import isEqual from "react-fast-compare";

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

import { getSecondaryCategory, addSecondaryCategory, updateSecondaryCategory, deleteSecondaryCategory } from '../../../actions/SecondaryCategoryAction';
import { getPrimaryCategoryForSelect } from '../../../actions/PrimaryCategoryAction'
import { GET_SECONDARY_CATEGORY_URL } from '../../../constants/api';
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};
const renderField = ({ input, label, type, meta: { touched, error, warning }, required = false }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>{required ? <span className='requiredField'>*</span> : ""}
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
// Modals.setAppElement('#modalRender');

class SecondaryCategoryList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
        this.handleModalShow = this.handleModalShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleEditClose = this.handleEditClose.bind(this)
        this.saveSecondaryCategory = this.saveSecondaryCategory.bind(this)
        this.secondaryCategoryEdit = this.secondaryCategoryEdit.bind(this)
        this.updateSecondaryCategory = this.updateSecondaryCategory.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        secondary_name: "",
        primary_category: "",
        prim_category: "",
        name: "",
        image: null,
        secondary_category_image: null,
        id: "",
        show: false,
        showedit: false,
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
        allchecked: false,
        bulk:false,
        secondcategories:[],
        load:false
    };

    handleModalShow() {
        this.setState({ show: true })
    }
    handleClose() {
        this.setState({ show: false })
    }
    handleEditClose() {
        this.setState({ showedit: false })
    }
    updateSecondaryCategory() {
        var data = {
            name: this.state.secondary_name,
            image: this.state.image,
            primary_category: this.state.prim_category
        }
        // alert(JSON.stringify(data))
        this.props.updateSecondaryCategory(this.state.id, data)
        this.setState({ showedit: false })
    }
    onChangeEdit() {
        this.setState({ showedit: true })
    }
    componentDidMount() {

        this.props.getSecondaryCategory(1)
        this.props.getPrimaryCategoryForSelect()
        // this.props.fetchRd(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteSecondaryCategory(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.name + '.',
                        'success'
                    )
                }
            }
        })
    }

    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = []

            for (var i = 0; i < this.state.secondcategories.length; i++) {
                if (this.state.secondcategories[i].checked) {
                    results.push(this.state.secondcategories[i].id)
                }
            }

            if (value === "1") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, SECONDARY_CATEGORY_BULK_URL
                )
            } else if (value === "0") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, SECONDARY_CATEGORY_BULK_URL
                )
            
            }else {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, SECONDARY_CATEGORY_BULK_URL
                )
            }
        }
    }


    toggleAllChange=(e)=> {
        const { value, checked } = e.target;
        let secondcategories = [...this.props.secondarycategory]
        secondcategories.forEach(product => product.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, secondcategories, bulk: true })
        } else {
            this.setState({ allchecked: checked, secondcategories, bulk: false })
        }
    }

    onSingleChange = index => (e) => {
        let secondcategories = [...this.state.secondcategories]
        // alert(primcategories)
        secondcategories[index]["checked"] = e.target.checked
        let recent_length = secondcategories.filter(product => product.checked).length;

        if (secondcategories.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        // this.setState({ primcategories })

    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.is_list) {
    //  alert(JSON.stringify(nextProps.is_list))
            if (nextProps.is_list !== prevState.load) {
                var secondcategories = []
                
                for (var i = 0; i < nextProps.secondarycategory.length; i++) {
                    Object.assign(nextProps.secondarycategory[i], { 'checked': false });
                    secondcategories.push(nextProps.secondarycategory[i])
                    // alert(JSON.stringify(primcategories))
                }
                if (!isEqual(nextProps.secondarycategory, prevState.secondcategories)) {
                    return {

                        secondcategories:[...secondcategories],
                        load: true
                    }
                  }
                

            }
        }else return null;

    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { name, primary_category } = this.state;
        console.log([name, primary_category]);

        this.props.getSecondaryCategory('', this.state);
        this.setState({ hideButton: true })

    };
    secondaryCategoryEdit(data) {
        this.setState({ showedit: true, secondary_name: data.name, image: data.image, primary_category: data.primary_category, id: data.id })
    }
    saveSecondaryCategory(e) {

        const { name, prim_category, secondary_category_image } = this.state
        var data = {
            name: name,
            secondary_category_image: secondary_category_image,
            primary_category: prim_category
        }
        // alert(JSON.stringify(data))
        this.props.addSecondaryCategory(data)
        this.setState({ show: false, name: "", secondary_category_image: "", primary_category: "" })
        // this.props.getSecondaryCategory(1)

    }
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.getSecondaryCategory(pageNo)
    }

    primaryChange = (e) => {
        this.setState({ prim_category: e.target.value })
    }

    handleImageChange = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }
    handleImageSubmit = (e) => {
        e.preventDefault()
        console.log(this.state)
        let id = this.props.match.params.id;
        let form_data = new FormData()
        form_data.append('image', this.state.image, this.state.image.name)
        let token = localStorage.getItem('token')
        let url = GET_SECONDARY_CATEGORY_URL
        axios.post(url, form_data, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }
        })
            .then(res => {
                toast.success("Successfully updated")
                console.log(res.data)
                window.location.reload()
            })
            .catech(err => console.log(err))
    }
    render() {
        const { show, showedit, hideButton,allchecked,bulk } = this.state
        const { secondarycategory, is_loading, count,permissions } = this.props
        const page = Math.ceil(count / 10)

        const isAdmin = this.props.auth.user.is_admin;
        if (this.props.is_updated) {
            setTimeout(function () {

                window.location.href = "/secondary-category-list"
            }, 3000)
        }
        if (this.props.is_added) {
            setTimeout(function () {

                window.location.href = "/secondary-category-list"
            }, 3000)
        }
        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/secondary-category-list'
            },
                1000);
        }
        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Secondary Category List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Secondary Category list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Secondary Category List</h3>
                                <div className="float-right">
                                {CheckPermisionAction(permissions,'Category','add_secondary_category')?

                                    <Link to="/add-secondary-category">
                                        <button className="btn btn-primary text-white" onClick={this.handleModalShow}>
                                            <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Secondary Category</b></button>
                                    </Link>
                                :null}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="name" type="text"
                                            onChange={this.onChange} placeholder="Name"
                                            aria-label="Search" />
                                {CheckPermisionAction(permissions,'Category','secondary_category_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    :null
                                    }
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Action--</option>
                                            <option value="1" >Activated</option>
                                            <option value="0">Deactivated</option>
                                            {/* <option value="2">Delete</option> */}
                                            

                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>
                            {CheckPermisionAction(permissions,'Category','view_secondary_category_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input type="checkbox"
                                                    checked={allchecked}
                                                    onChange={this.toggleAllChange}
                                                />
                                            </th>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th>Image</th>
                                            <th>Primary Category Name</th>
                                            <th>Slug</th>
                                            <th>Status</th>
                                            <th>Action</th>

                                            {hideButton ?
                                                <th><Link to="/secondary-category-list" onClick={() => { window.location.href = "/secondary-category-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> : secondarycategory.length !== undefined && secondarycategory.map((secondarycategory, index) => (
                                            <tr key={secondarycategory.id} value={secondarycategory.id}>
                                                <td>
                                                    <input type="checkbox" onChange={this.onSingleChange(index)} checked={secondarycategory.checked ? true : false} value={secondarycategory.id} />
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{secondarycategory.name}</td>
                                                <td>{
                                                    secondarycategory.image != null ? (
                                                        <img src={secondarycategory.image} height="100px" width="100px" alt="No Image" />
                                                    ) : null
                                                }</td>
                                                <td>{secondarycategory.primary_categories !=null ?secondarycategory.primary_categories.name:''}</td>
                                                <td>{secondarycategory.slug}</td>
                                                <td>{secondarycategory.is_active ===true? "Active":"Inactive"} </td>

                                                <td className="btn-group btn-group-sm">

                                                <div className="btn-group">


                                                    <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>

                                                    <div className="dropdown-menu">
                                                        <a href={ "/secondary-category-update/"+secondarycategory.id} className="dropdown-item">Edit</a>
                                                        <div className="dropdown-divider"></div>
                                                        
                                                        <a href="#" onClick={() => this.opensweetalert(secondarycategory)} className="dropdown-item">Delete</a>

                                                    </div>
                                                </div>
                                                </td>
                                                {/* <td>
                                                    
                                                {CheckPermisionAction(permissions,'Category','secondary_category_edit')?
                                                    
                                                    <button className="btn btn-warning text-white" onClick={() => this.secondaryCategoryEdit(secondarycategory)}>
                                                        <i className="fas fa-edit" >Edit</i>
                                                    </button>
                                                :null}
                                                    
                                                {CheckPermisionAction(permissions,'Category','secondary_category_delete')?
                                                    
                                                    <button className="btn btn-danger text-white" onClick={() => this.opensweetalert(secondarycategory)}>
                                                        <i className="fas fa-trash" >Delete</i>
                                                    </button>
                                                :null}
                                                    

                                                </td> */}
                                            </tr>))}
                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>
                            <Modal
                                show={show}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Secondary Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>


                                    <div className="col-6">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            placeholder="Secondary Category Name"
                                            validate={required}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label>Primary Category</label>
                                        <span className="requiredField">*</span>
                                        <select name="prim_category" component="select" className="form-control" onClick={this.primaryChange}
                                            validate={required}>
                                            <option value="">Select primary category</option>
                                            {this.props.primarycategory.map(pri_category => (
                                                <option key={pri_category.id} value={pri_category.value}>{pri_category.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.saveSecondaryCategory}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={showedit}
                                onHide={this.handleEditClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Update Secondary Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='col-6'>
                                        <input
                                            name="id"
                                            type="hidden"
                                            value={this.state.id}
                                            className="form-control"
                                            placeholder="Secondary Category id"
                                            validate={required}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <input
                                            name="secondary_name"
                                            type="text"
                                            value={this.state.secondary_name}
                                            className="form-control"
                                            placeholder="Secondary Category Name"
                                            validate={required}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        {/* <button type="button" className="btn btn-info" data-toggle="modal"
                                            data-target="#UploadDrivingLicense">
                                            Upload Driving License
                                        </button> */}
                                        <br />
                                        <br />
                                        <img src={this.state.image}
                                            style={{ width: "250px" }} alt="Not Found" />
                                        <p>
                                            <a href={this.props.secondarycategory.image}>{this.props.secondarycategory.image}</a>
                                        </p>
                                    </div>
                                    <form onSubmit={this.handleImageSubmit}>
                                        <div className="modal-body">
                                            <p>
                                                <input type="file"
                                                    id="image"
                                                    onChange={this.handleImageChange}
                                                    required />
                                            </p>
                                        </div>

                                    </form>
                                    <div className="col-6">
                                        <label>Primary Category</label>
                                        <span className="requiredField">*</span>
                                        <select name="prim_category" component="select" className="form-control" onClick={this.primaryChange}
                                            validate={required}>
                                            <option value="">Select primary category</option>
                                            {this.props.primarycategory.map(pri_category => (
                                                <option key={pri_category.id} value={pri_category.value}>{pri_category.label}</option>
                                            ))}
                                        </select>
                                    </div>
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleEditClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.updateSecondaryCategory}>
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

SecondaryCategoryList.propTypes = {
    getSecondaryCategory: PropTypes.func.isRequired,
    deleteSecondaryCategory: PropTypes.func.isRequired,
    BulkAction:PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    primarycategory: state.primarycategory.primary_categories,
    secondarycategory: state.secondarycategory.secondary_categories,
    count: state.secondarycategory.count,
    next: state.secondarycategory.next,
    is_loading: state.secondarycategory.is_loading,
    is_added: state.secondarycategory.is_added,
    is_updated: state.secondarycategory.is_updated,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions,
    bulk: state.products.bulk,
    is_list:state.secondarycategory.is_list,
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { getSecondaryCategory, addSecondaryCategory, deleteSecondaryCategory, getPrimaryCategoryForSelect, updateSecondaryCategory,BulkAction })(SecondaryCategoryList);

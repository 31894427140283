import { combineReducers } from 'redux';
import auth from "./auth";
import ProductReducer from "./ProductReducer";

import DashboardReducer from "./DashboardReducer";
import UserReducers from "./UserReducers";
// eslint-disable-next-line
import GroupReducer from './GroupReducer';
import { reducer as formReducer } from 'redux-form';
import PrimaryCategoryReducer from './PrimaryCategoryReducer';
import SecondaryCategoryReducer from './SecondaryCategoryReducer';
import ChildCategoryReducer from './ChildCategoryReducer';
import AttributeReducer from './AttributeReducer';
import AttributesOptionReducer from './AttributesOptionReducer';
import TagsReducer from './TagsReducer';
import BrandsReducer from './BrandsReducer';
import SellerReducer from "./SellerReducer";
import CustomerReducer from './CustomerReducer';
import UserCreateReducer from './UserCreateReducer';
import CouponReducer from './CouponReducer';
import {getAllPermissionReducer,getUserPermissionReducer} from "./UserPermissionReducer"
import {SupportTicketReducer,SupportMessagesReducer,ContactMessagesReducer} from './SupportTicketReducer';
import WithdrawReducer from './WithdrawReducer';
import { PaymentReducer, OrderReducer } from "./OrderReducer"
import {CampaignReducer,CampaignGroupReducer} from './CampaignReducer';
import TaxReducer from './TaxReducer';
import ShippingClassReducer from './ShippingClassReducer';
import ShippingZoneReducer from './ShippingZoneReducer';
import ReturnReducer from './ReturnReducer';
import SubscriberReducer from './SubscriberReducer';
import ProductUnitReducer from './ProductUnitReducer';
import BundleReducer from './BundleReducer';
import DistrictsReducer from './DistrictsReducer';
import ContactInfoReducer from './ContactInfoReducer';
import {AboutUsReducer, CopyRightReducer,FaqReducer,FooterPaymentImageReducer,PrivacyPolicyReducer,ReturnRefundPolicyReducer,ShippingPolicyReducer,TermsConditionReducer} from './CopyRightReducer';
import { UpsellProductReducer, CrossSellProductReducer } from './ProductSearchReducer'
import {LogoReducer,FaviconReducer, PreLoaderReducer, FooterLogoReducer} from './LogoReducer';
import { SlideSettingReducer, SlideRightSideSettingReducer, BannerSettingReducer, SocialMediaReducer, FooterLeftSectionReducer } from './SlideSettingReducer';
import { BlogCategoryReducer, BlogPostReducer, BlogTagReducer } from './BlogReducer';
import { MediaLibraryReducer } from './MediaLibraryReducer';
import { CuisineReducer,RestaurantReducer,FoodNameReducer, RestaurantSliderReducer, MedicineDeliveryReducer, RiderCarTripReducer, PercelExpressReducer, FoodOrderReducer, RiderCarTypeReducer, PercelTypeReducer, PercelPlanReducer, MobileAppSliderReducer } from './RestaurantReducer';
import {WarehouseReducer,StockReducer} from './WarehouseReducer';
import { AgentCustomerReducer, AgentProductSaleReducer, AgentProductStockReducer, AgentReducer,AgentProductStockReportsReducer, AgentProductSaleReportsReducer } from './AgentReducer';
import {AgentWiseReportsReducer,ProductStockReportsReducer, SalesReportReducer} from './ReportsReducer'

const rootReducer = combineReducers({
    auth,
    dashboard: DashboardReducer,
    products: ProductReducer,
    all_users: UserReducers,
    primarycategory: PrimaryCategoryReducer,
    secondarycategory: SecondaryCategoryReducer,
    childcategory: ChildCategoryReducer,
    attributes: AttributeReducer,
    attribute_options: AttributesOptionReducer,
    tags: TagsReducer,
    brands: BrandsReducer,
    sellers: SellerReducer,
    // groups: GroupReducer,
    customers: CustomerReducer,
    users: UserCreateReducer,
    groups: GroupReducer,
    permissions:getAllPermissionReducer,
    userpermissions:getUserPermissionReducer,
    payments: PaymentReducer,
    support_tickets: SupportTicketReducer,
    withdraws: WithdrawReducer,
    orders: OrderReducer,
    coupons: CouponReducer,
    campaigns: CampaignReducer,
    campaign_groups:CampaignGroupReducer,
    taxs: TaxReducer,
    shipping_classes: ShippingClassReducer,
    shipping_zones: ShippingZoneReducer,
    return_refunds: ReturnReducer,
    subscribers: SubscriberReducer,
    product_units: ProductUnitReducer,
    bundles: BundleReducer,
    districts: DistrictsReducer,
    contacts_info: ContactInfoReducer,
    copy_rights: CopyRightReducer,
    footer_payment_images:FooterPaymentImageReducer,
    terms_conditions:TermsConditionReducer,
    shipping_policies:ShippingPolicyReducer,
    privacy_policies:PrivacyPolicyReducer,
    return_refund_policies:ReturnRefundPolicyReducer,
    abouts_us:AboutUsReducer,
    faqs:FaqReducer,
    upsellProducts: UpsellProductReducer,
    crossSellProducts: CrossSellProductReducer,

    logos: LogoReducer,
    footer_logos:FooterLogoReducer,
    favicons:FaviconReducer,
    preloaders:PreLoaderReducer,
    slide_settings: SlideSettingReducer,
    slide_right_side_settings: SlideRightSideSettingReducer,
    banner_settings: BannerSettingReducer,
    social_medias: SocialMediaReducer,
    footer_left_sections: FooterLeftSectionReducer,
    blog_posts: BlogPostReducer,
    blog_categories: BlogCategoryReducer,
    blog_tags: BlogTagReducer,
    media_libraries:MediaLibraryReducer,
    restaurants:RestaurantReducer,
    cuisines:CuisineReducer,
    foodnames:FoodNameReducer,
    restaurantsliders:RestaurantSliderReducer,
    medicinedeliveries:MedicineDeliveryReducer,
    ridercartrips:RiderCarTripReducer,
    percelexpresses:PercelExpressReducer,
    foodorders:FoodOrderReducer,
    rider_car_types:RiderCarTypeReducer,
    percel_types:PercelTypeReducer,
    percel_plans:PercelPlanReducer,
    support_messages:SupportMessagesReducer,
    contact_messages:ContactMessagesReducer,
    mobile_app_sliders:MobileAppSliderReducer,
    warehouses:WarehouseReducer,
    stocks:StockReducer,
    agents:AgentReducer,
    agents_reports:AgentWiseReportsReducer,
    product_stock_reports:ProductStockReportsReducer,
    sales_reports:SalesReportReducer,
    agent_customers:AgentCustomerReducer,
    agent_product_stocks:AgentProductStockReducer,
    agent_product_sales:AgentProductSaleReducer,
    agent_product_stock_reports:AgentProductStockReportsReducer,
    agent_product_sale_reports:AgentProductSaleReportsReducer,
    form: formReducer,
});

export default rootReducer;

import {
    LOADING_COPYRIGHT,
    FETCH_COPYRIGHT,
    DETAIL_COPYRIGHT,
    DELETE_COPYRIGHT,
    NOT_FOUND_COPYRIGHT,
    LOADING_TERMS_CONDITION,
    FETCH_TERMS_CONDITION,
    NOT_FOUND_TERMS_CONDITION,
    LOADING_SHIPPING_POLICY,
    FETCH_SHIPPING_POLICY,
    NOT_FOUND_SHIPPING_POLICY,
    LOADING_PRIVACY_POLICY,
    FETCH_PRIVACY_POLICY,
    NOT_FOUND_PRIVACY_POLICY,
    LOADING_RETURN_REFUND_POLICY,
    FETCH_RETURN_REFUND_POLICY,
    NOT_FOUND_RETURN_REFUND_POLICY,
    LOADING_FAQ,
    FETCH_FAQ,
    NOT_FOUND_FAQ,
    NOT_FOUND_ABOUT_US,
    FETCH_ABOUT_US,
    LOADING_ABOUT_US,
    LOADING_FOOTER_PAYMENT_IMAGE,
    FETCH_FOOTER_PAYMENT_IMAGE,
    NOT_FOUND_FOOTER_PAYMENT_IMAGE,


} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { COPYRIGHT_URL,TERMS_CONDITION_URL, SELLER_URL, SHIPPING_POLICY_URL,PRIVACY_POLICY_URL, RETURN_REFUND_POLICY_URL,ABOUT_US_URL,FAQ_URL,FOOTER_PAYMENT_IMAGE_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';





export const fetchFooterPaymentImage = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_FOOTER_PAYMENT_IMAGE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["image"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = FOOTER_PAYMENT_IMAGE_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_FOOTER_PAYMENT_IMAGE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_FOOTER_PAYMENT_IMAGE });

    }
};










export const fetchCopyright = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_COPYRIGHT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["copy_right"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = COPYRIGHT_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_COPYRIGHT,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_COPYRIGHT });

    }
};

// Copy Right Detail
export const copyRightDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = COPYRIGHT_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_COPYRIGHT,
                payload: response.data,
            })
        })
};

// // Delete Copy Right
export const deleteCopyright = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(COPYRIGHT_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_COPYRIGHT,
                payload: id,
            });
            toast.success('Copyright has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};



export const searchCopyRight = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_COPYRIGHT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(COPYRIGHT_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_COPYRIGHT,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};



// **************** TERMS CONDITIONS  *******************

export const fetchTermsCondtion = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_TERMS_CONDITION });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["terms_condition"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = TERMS_CONDITION_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_TERMS_CONDITION,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_TERMS_CONDITION });

    }
};




// **************** SHIPPING POLICY  *******************

export const fetchShippingPolicy = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SHIPPING_POLICY });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["shipping_policy"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = SHIPPING_POLICY_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_SHIPPING_POLICY,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SHIPPING_POLICY });

    }
};





// **************** PRIVACY POLICY  *******************

export const fetchPrivacyPolicy = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_PRIVACY_POLICY });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["privacy_policy"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = PRIVACY_POLICY_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_PRIVACY_POLICY,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_PRIVACY_POLICY });

    }
};



// **************** RUTURN REFUND POLICY  *******************

export const fetchReturnRefundPolicy = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_RETURN_REFUND_POLICY });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["return_refund_policy"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = RETURN_REFUND_POLICY_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_RETURN_REFUND_POLICY,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_RETURN_REFUND_POLICY });

    }
};



// **************** ABOUT US  *******************

export const fetchAboutUs = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_ABOUT_US });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["about_us"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = ABOUT_US_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_ABOUT_US,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_ABOUT_US });

    }
};



// **************** FAQ  *******************

export const fetchFaq = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_FAQ });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["faq"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = FAQ_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                //   alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_FAQ,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_FAQ });

    }
};
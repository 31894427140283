import {
    LOADING_COPYRIGHT,
    FETCH_COPYRIGHT,
    DETAIL_COPYRIGHT,
    DELETE_COPYRIGHT,
    UPDATE_COPYRIGHT,
    NOT_FOUND_COPYRIGHT,
    LOADING_TERMS_CONDITION,
    FETCH_TERMS_CONDITION,
    DETAIL_TERMS_CONDITION,
    DELETE_TERMS_CONDITION,
    UPDATE_TERMS_CONDITION,
    NOT_FOUND_TERMS_CONDITION,
    LOADING_SHIPPING_POLICY,
    FETCH_SHIPPING_POLICY,
    DETAIL_SHIPPING_POLICY,
    DELETE_SHIPPING_POLICY,
    UPDATE_SHIPPING_POLICY,
    NOT_FOUND_SHIPPING_POLICY,
    LOADING_PRIVACY_POLICY,
    FETCH_PRIVACY_POLICY,
    DETAIL_PRIVACY_POLICY,
    DELETE_PRIVACY_POLICY,
    UPDATE_PRIVACY_POLICY,
    NOT_FOUND_PRIVACY_POLICY,
    
    LOADING_RETURN_REFUND_POLICY,
    FETCH_RETURN_REFUND_POLICY,
    DETAIL_RETURN_REFUND_POLICY,
    DELETE_RETURN_REFUND_POLICY,
    UPDATE_RETURN_REFUND_POLICY,
    NOT_FOUND_RETURN_REFUND_POLICY,

    LOADING_ABOUT_US,
    FETCH_ABOUT_US,
    DETAIL_ABOUT_US,
    DELETE_ABOUT_US,
    UPDATE_ABOUT_US,
    NOT_FOUND_ABOUT_US,

    LOADING_FAQ,
    FETCH_FAQ,
    DETAIL_FAQ,
    DELETE_FAQ,
    UPDATE_FAQ,
    NOT_FOUND_FAQ,
    LOADING_FOOTER_PAYMENT_IMAGE,
    FETCH_FOOTER_PAYMENT_IMAGE,
    DETAIL_FOOTER_PAYMENT_IMAGE,
    DELETE_FOOTER_PAYMENT_IMAGE,
    UPDATE_FOOTER_PAYMENT_IMAGE,
    NOT_FOUND_FOOTER_PAYMENT_IMAGE


} from '../actions/types';







export const FooterPaymentImageReducer = (state = {
    footer_payment_images: [],
    footer_payment_images: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,

}, action)=> {
    switch (action.type) {
        case LOADING_FOOTER_PAYMENT_IMAGE:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_FOOTER_PAYMENT_IMAGE:
            // alert(JSON.stringify(action.payload))
            return {
                ...state,
                footer_payment_images: action.payload,
                count: action.count,
                next: action.next,
                is_getting:true,
                is_loading: false
            };
        case UPDATE_FOOTER_PAYMENT_IMAGE:
            return {
                ...state,
                footer_payment_images: action.payload,
                is_loading: false
            };
        case DETAIL_FOOTER_PAYMENT_IMAGE:
            return {
                ...state,
                is_getting: true,
                footer_payment_images: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_FOOTER_PAYMENT_IMAGE:
            return {
                ...state,
                footer_payment_images: state.footer_payment_images.filter(footer_payment_image => footer_payment_image.id !== action.payload)
            };


        case NOT_FOUND_FOOTER_PAYMENT_IMAGE:
            return {
                ...state,
                footer_payment_images: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}













const initialState = {
    copy_rights: [],
    copy_right: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
};

export const CopyRightReducer = (state = initialState, action)=> {
    switch (action.type) {
        case LOADING_COPYRIGHT:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_COPYRIGHT:
            // alert(JSON.stringify(action.payload))
            return {
                ...state,
                copy_rights: action.payload,
                count: action.count,
                next: action.next,
                is_getting:true,
                is_loading: false
            };
        case UPDATE_COPYRIGHT:
            return {
                ...state,
                copy_rights: action.payload,
                is_loading: false
            };
        case DETAIL_COPYRIGHT:
            return {
                ...state,
                is_getting: true,
                copy_rights: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_COPYRIGHT:
            return {
                ...state,
                copy_rights: state.copy_rights.filter(copy_right => copy_right.id !== action.payload)
            };


        case NOT_FOUND_COPYRIGHT:
            return {
                ...state,
                copy_rights: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}


export const TermsConditionReducer = (state = 
    {
    terms_conditions: [],
    terms_condition: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    }
    , action)=> {
    switch (action.type) {
        case LOADING_TERMS_CONDITION:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_TERMS_CONDITION:
            // alert(JSON.stringify(action.payload))
            return {
                ...state,
                terms_conditions: action.payload,
                count: action.count,
                next: action.next,
                is_getting:true,
                is_loading: false
            };
        case UPDATE_TERMS_CONDITION:
            return {
                ...state,
                terms_conditions: action.payload,
                is_loading: false
            };
        case DETAIL_TERMS_CONDITION:
            return {
                ...state,
                is_getting: true,
                terms_conditions: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_TERMS_CONDITION:
            return {
                ...state,
                terms_conditions: state.terms_conditions.filter(terms_condition => terms_condition.id !== action.payload)
            };


        case NOT_FOUND_TERMS_CONDITION:
            return {
                ...state,
                terms_conditions: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}





export const ShippingPolicyReducer = (state = 
    {
    shipping_policies: [],
    shipping_policy: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    }
    , action)=> {
    switch (action.type) {
        case LOADING_SHIPPING_POLICY:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_SHIPPING_POLICY:
            // alert(JSON.stringify(action.payload))
            return {
                ...state,
                shipping_policies: action.payload,
                count: action.count,
                next: action.next,
                is_getting:true,
                is_loading: false
            };
        case UPDATE_SHIPPING_POLICY:
            return {
                ...state,
                shipping_policies: action.payload,
                is_loading: false
            };
        case DETAIL_SHIPPING_POLICY:
            return {
                ...state,
                is_getting: true,
                shipping_policies: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_SHIPPING_POLICY:
            return {
                ...state,
                shipping_policies: state.shipping_policies.filter(shipping_policy => shipping_policy.id !== action.payload)
            };


        case NOT_FOUND_SHIPPING_POLICY:
            return {
                ...state,
                shipping_policies: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const PrivacyPolicyReducer = (state = 
    {
    privacy_policies: [],
    privacy_policy: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    }
    , action)=> {
    switch (action.type) {
        case LOADING_PRIVACY_POLICY:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_PRIVACY_POLICY:
            // alert(JSON.stringify(action.payload))
            return {
                ...state,
                privacy_policies: action.payload,
                count: action.count,
                next: action.next,
                is_getting:true,
                is_loading: false
            };
        case UPDATE_PRIVACY_POLICY:
            return {
                ...state,
                privacy_policies: action.payload,
                is_loading: false
            };
        case DETAIL_PRIVACY_POLICY:
            return {
                ...state,
                is_getting: true,
                privacy_policies: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_PRIVACY_POLICY:
            return {
                ...state,
                privacy_policies: state.privacy_policies.filter(privacy_policy => privacy_policy.id !== action.payload)
            };


        case NOT_FOUND_PRIVACY_POLICY:
            return {
                ...state,
                privacy_policies: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}



export const ReturnRefundPolicyReducer = (state = 
    {
    return_refund_policies: [],
    return_refund_policy: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    }
    , action)=> {
    switch (action.type) {
        case LOADING_RETURN_REFUND_POLICY:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_RETURN_REFUND_POLICY:
            // alert(JSON.stringify(action.payload))
            return {
                ...state,
                return_refund_policies: action.payload,
                count: action.count,
                next: action.next,
                is_getting:true,
                is_loading: false
            };
        case UPDATE_RETURN_REFUND_POLICY:
            return {
                ...state,
                return_refund_policies: action.payload,
                is_loading: false
            };
        case DETAIL_RETURN_REFUND_POLICY:
            return {
                ...state,
                is_getting: true,
                return_refund_policies: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_RETURN_REFUND_POLICY:
            return {
                ...state,
                return_refund_policies: state.return_refund_policies.filter(return_refund_policy => return_refund_policy.id !== action.payload)
            };


        case NOT_FOUND_RETURN_REFUND_POLICY:
            return {
                ...state,
                return_refund_policies: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}



export const AboutUsReducer = (state = 
    {
    abouts_us: [],
    about_us: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    }
    , action)=> {
    switch (action.type) {
        case LOADING_ABOUT_US:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_ABOUT_US:
            // alert(JSON.stringify(action.payload))
            return {
                ...state,
                abouts_us: action.payload,
                count: action.count,
                next: action.next,
                is_getting:true,
                is_loading: false
            };
        case UPDATE_ABOUT_US:
            return {
                ...state,
                abouts_us: action.payload,
                is_loading: false
            };
        case DETAIL_ABOUT_US:
            return {
                ...state,
                is_getting: true,
                abouts_us: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_ABOUT_US:
            return {
                ...state,
                abouts_us: state.abouts_us.filter(about_us => about_us.id !== action.payload)
            };


        case NOT_FOUND_ABOUT_US:
            return {
                ...state,
                abouts_us: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}


export const FaqReducer = (state = 
    {
    faqs: [],
    faq: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    }
    , action)=> {
    switch (action.type) {
        case LOADING_FAQ:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_FAQ:
            // alert(JSON.stringify(action.payload))
            return {
                ...state,
                faqs: action.payload,
                count: action.count,
                next: action.next,
                is_getting:true,
                is_loading: false
            };
        case UPDATE_FAQ:
            return {
                ...state,
                faqs: action.payload,
                is_loading: false
            };
        case DETAIL_FAQ:
            return {
                ...state,
                is_getting: true,
                faqs: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_FAQ:
            return {
                ...state,
                faqs: state.faqs.filter(faq => faq.id !== action.payload)
            };


        case NOT_FOUND_FAQ:
            return {
                ...state,
                faqs: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}
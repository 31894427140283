import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import Modal from 'react-modal';
import { updateDatas } from '../../../actions/CustomerActions';

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

import { fetchCoupons, deleteCoupon } from '../../../actions/CouponActions';
import { COUPON_CODE_URL } from "../../../constants/api";

// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class CouponList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        coupon_code: "",
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
    };

    handleStatusValues = i => e => {

        let coupons = [...this.props.coupons]
        var id = Number(coupons[i].id)
        let data ={
            'status':e.target.value
        }
        this.props.updateDatas(data, COUPON_CODE_URL+id+"/",  "Status")

    }


    componentDidMount() {
        this.props.fetchCoupons(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.coupon_code + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteCoupon(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.coupon_code + '.',
                        'success'
                    )
                }
            }
        })
    }

    


   

    
   

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { coupon_code } = this.state;
        console.log([coupon_code]);

        this.props.fetchCoupons('', this.state);
        this.setState({ hideButton: true })

    };
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchCoupons(pageNo)
    }
    render() {
        const page = Math.ceil(this.props.count / 10)
        const {permissions} = this.props
        const isAdmin = this.props.auth.user.is_admin;

        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Coupon List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Coupon list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Coupon List</h3>
                                <div className="float-right">

                                {CheckPermisionAction(permissions,'Campaign','add_coupon')?

                                    <Link to="/add-coupon" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Coupon</b></Link>
                                :null}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="coupon_code" type="text"
                                            onChange={this.onChange} placeholder="Coupon Code"
                                            aria-label="Search" />
                                {CheckPermisionAction(permissions,'Campaign','coupon_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                :null}
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'Campaign','view_coupon_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Coupon Title</th>
                                            <th>Coupon Code</th>
                                            <th>Coupon Type</th>
                                            <th>Discount Type</th>
                                            <th>Amount</th>
                                            <th>Used Limit</th>
                                            <th>Status</th>
                                            <th>Option</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/coupon-list" onClick={() => { window.location.href = "/coupon-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> :  this.props.coupons.length !=undefined && this.props.coupons.map((coupon, index) => (
                                            <tr key={coupon.id}>
                                                <td>{index + 1}</td>
                                                <td>{coupon.coupon_name}</td>
                                                <td>{coupon.coupon_code}</td>
                                                <td>{coupon.coupon_type==1 ? 'Food' : 'Shop'}</td>
                                                <td>{coupon.discount_type==1 ? 'Discount by Percentage' : 'Discount by Amount'}</td>
                                                <td>{coupon.amount}</td>
                                                <td>{coupon.used}</td>
                                                <td>

                                                    <select key={index}
                                                        name="status"
                                                        component="select"
                                                        className="form-control"

                                                        onChange={this.handleStatusValues(index)}
                                                    >


                                                        <option value="1" selected={coupon.status == 1 ? true : false} >Activated</option>
                                                        <option value="0" selected={coupon.status == 0 ? true : false}>Deactivated</option>

                                                    </select>
                                                </td>
                                                {/* <td className="btn-group btn-group-sm">


                                                    <Link to={'/coupon-update/' + coupon.id}>
                                                        <button className="btn btn-info text-white">
                                                            <i className="fas fa-edit text-white" ></i>
                                                        </button>
                                                    </Link>

                                                    <button className="btn btn-danger"
                                                        // onClick={this.props.RegDirectorConfirm.bind(this, rgd.id, rgd.is_active)}
                                                        onClick={() => this.opensweetalert(coupon)}>
                                                        <i className="fas fa-trash" />
                                                    </button>

                                                </td> */}
                                                <td>

                                                    <div class="btn-group">
                                                {CheckPermisionAction(permissions,'Campaign','coupon_options')?

                                                        <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                :null}
                                                        <div class="dropdown-menu">
                                                            <a href={'/coupon-update/' + coupon.id} class="dropdown-item">Edit</a>
                                                            <div class="dropdown-divider"></div>

                                                            {/* <a href="#" onClick={() => this.openDeclinealert("Set As Featued", product)} class="dropdown-item">Set As Featued</a>
                                                            <a href="#" onClick={() => this.openDeclinealert("Approve", product)} class="dropdown-item">Approve</a>
                                                            <a href="#" onClick={() => this.openDeclinealert("Decline", product)} class="dropdown-item">Decline</a> */}
                                                            <a href="#" onClick={() => this.opensweetalert(coupon)} class="dropdown-item">Delete</a>

                                                        </div>
                                                    </div>




                                                </td>
                                                {/* <td>
                                                    <Link to={'/customer-detail/' + customer.id}>
                                                        <button className="btn btn-info">
                                                            <i className="fas fa-eye text-white" >View</i>
                                                        </button>
                                                    </Link>

                                                    <Link to={'/customer-update/' + customer.id}>
                                                        <button className="btn btn-warning text-white">
                                                            <i className="fas fa-edit text-white" >Edit</i>
                                                        </button>
                                                    </Link>
                                                    {isAdmin ?
                                                        <button className="btn btn-danger" onClick={() => this.opensweetalert()}><i className="fas fa-trash" >Delete</i>
                                                        </button> : " "}

                                                </td> */}
                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

CouponList.propTypes = {
    fetchCoupons: PropTypes.func.isRequired,
    deleteCoupon: PropTypes.func.isRequired,
    updateDatas: PropTypes.func.isRequired,

    
};

const mapStateToProps = state => ({
    coupons: state.coupons.coupons,
    is_updated: state.customers.is_updated,
    count: state.coupons.count,
    next: state.coupons.next,
    is_loading: state.coupons.is_loading,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchCoupons, deleteCoupon,updateDatas })(CouponList);

import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { AgentProductSaleDetail } from '../../../actions/AgentActions';
import moment from "moment";
import 'moment-timezone';
import { PaymentStatus, OrderStatus } from '../../../constants/Datas'
import { Field, reduxForm } from "redux-form";
import { sumTotalAmount, sumTotalQuantity } from '../../../constants/CustomMethod'
import { fetchPaymentMethods } from '../../../actions/orderActions';
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import {  BulkAction } from '../../../actions/ProductAction';
import { ORDER_BULK_URL } from '../../../constants/api'
import OrderPdf from "../../../services/OrderGeneratePdf";

// Text area type
const renderTextArea = ({
    input,
    label,
    meta: { touched, error, warning },
  }) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
      <div>
        <label className="text-sm">{label}</label>
        <div>
          <textarea
            {...input}
            placeholder={label}
            rows="3"
            cols="40"
            className={className}
          />
          {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  };

  const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
    required = false,
  }) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
      <div className="form-group">
        <label className="text-sm">{label}</label>
        {required ? <span className="requiredField">*</span> : ""}
        <div>
          <input
            {...input}
            placeholder={label}
            type={type}
            className={className}
          />
          {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  };

class AgentProductStockSaleDetails extends Component {
    state ={
        order_status:'',
        order:[],
        get_order:false,
        allchecked: false, 
        bulk: false,
        unit_amount:'',
        oderList: [],
        price:'',
        sub_total:0

    }

    componentDidMount() {
    //    alert(JSON.stringify(this.props.AgentProductSaleDetail))
        let id = this.props.match.params.id;
        this.props.AgentProductSaleDetail(id);
        this.props.fetchPaymentMethods()

    }
    handleChange=(event)=> {    this.setState({unit_amount: event.target.value});  }
    onChange = e => {this.setState({ unit_amount: e.target.value })};
    static getDerivedStateFromProps(nextProps, prevState) {
        
        if (nextProps.detail) {
            
            // alert(JSON.stringify(nextProps.initialValues.order_product.unit_amount))
            if (nextProps.detail !== prevState.get_order) {
                var order = []
                let sub_total=0
                for (var i = 0; i < nextProps.initialValues.order_product.length; i++) {
                    sub_total +=nextProps.initialValues.order_product[i].subtotal
                    // order.push(nextProps.order[i])
                }
                // alert(JSON.stringify(nextProps.initialValues.order_product))
                return {
                    order: order,
                    // orderproduct.product.unit_amount
                    unit_amount:nextProps.initialValues.order_product.product !=undefined?nextProps.initialValues.order_product.product.unit_amount:'',
                    price:nextProps.initialValues.order_product !=undefined?nextProps.initialValues.order_product.price:0,
                    // unit_amount:nextProps.initialValues.order_product !=undefined?nextProps.initialValues.order_product.unit_amount:0,
                    sub_total:sub_total,
                    get_order: true
                }
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
       
        if (prevProps.order != this.props.order){
            this.setState({order:this.props.order})
        }

    }

    manageQuantity = (index, type = "plus") => {
        let orders = [...this.state.oderList]
        if (type == "plus") {
          orders[index].unit_amount = orders[index].unit_amount + 1;
          orders[index].subtotal = (orders[index].regular_price - orders[index].discount_price) * orders[index].unit_amount;
    
        } else {
          if (orders[index].unit_amount > 1) {
            orders[index].unit_amount = orders[index].unit_amount - 1;
            orders[index].subtotal = (orders[index].regular_price - orders[index].discount_price) * orders[index].unit_amount;
    
          }
        }
        this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders) })
      }


    onOrderStatusChange = (e)=>{
      
        let id = this.props.match.params.id;
          if(this.props.initialValues.order_status !== 5){
            this.props.BulkAction(
                { 'id': [id], 'action': e.target.value }, ORDER_BULK_URL
            )
          }else{
            alert("You are not able to change completed order") 
          }
        
           
        
    }


    onPaymentStatusChange = (e)=>{
      
        let id = this.props.match.params.id;
          if(this.props.initialValues.order_status !== 5){
            this.props.BulkAction(
                { 'id': [id], 'action': e.target.value }, ORDER_BULK_URL
            )
          }else{
            alert("You are not able to change completed order") 
          }
        
           
        
    }
  

    PrintOption=()=>{
        window.print()
    }

    render() {
    //    alert(JSON.stringify(this.props.orders))
        const { agent_product_sales } = this.props
        if (this.props.bulk) {
            setTimeout(function () {
              window.location.reload()
            },
                1000);
        }

        
            const productSaleDetails = agent_product_sales !== undefined ? (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Product Sale Information</h3>
                    <div className="float-right">
                        
                        <Link to="/agent-product-sale-list" className="btn btn-success float-right text-white"><b>
                            <i className="fa fa-backward"></i>
                                Back To List</b>
                        </Link>&nbsp;&nbsp;
                        {/* <button onClick={this.PrintOption} className="btn btn-primary float-right text-white mr-2"><b>
                            <i className="fa fa-print"></i>
                                Print</b>
                        </button> */}
{/* 
                        <button
                            className="btn btn-primary mr-2"
                            onClick={() => OrderPdf(order)}
                            formTarget="_blank"
                            >
                                Generate Order PDF
                        </button> */}
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {/* <div className="col-3">

                        </div> */}
                        <div className="col-12">
                            <ul className="list-group list-group-flush">
                                
                                 <li className="list-group-item">
                                    <span className="text-bold mr-3">Invocie No :</span><strong> #{agent_product_sales.invoice_no}</strong>
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3"> Date :</span> {agent_product_sales.created_at ? moment(agent_product_sales.created_at).tz('Asia/Dhaka').format('llll') : ''}
                                </li>
                                {/* <li className="list-group-item">
                                    <span className="text-bold mr-3">Delivery Time :</span> {order.delivery_date_time ? moment(order.delivery_date_time).tz('Asia/Dhaka').format('llll') : ''}
                                </li> */}
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Customer Name :</span> {agent_product_sales.customers ? agent_product_sales.customers.customer_name  : ''}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Customer Phone No :</span> {agent_product_sales.customers ? agent_product_sales.customers.customer_phone_no : ''}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Address :</span> 
                                    
                                        {Object.keys(agent_product_sales).length > 0 ? <div
                                        dangerouslySetInnerHTML={{__html: agent_product_sales.customers !==undefined? agent_product_sales.customers.address:''}}/>:null}
                                    

                                        
                                   
                                    </li>
                                {/* <li className="list-group-item">
                                    <span className="text-bold mr-3">Shop Name :</span> {order.seller ? order.seller.shop_name : ''}
                                </li> */}
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Total Quantity :</span> {agent_product_sales.total_quantity}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Total Price :</span> {agent_product_sales.total_price}
                                </li>
                                
                              
                                
                                {/* <li className="list-group-item">
                                    <span className="text-bold mr-3">Order Status :</span> 
                                    <Field
                                        name="order_status"
                                        component="select"
                                        className="form-control"
                                        label="Order Status"
                                        onChange={this.onOrderStatusChange}
                                    >
                                        <option value="">--Select Order Status--</option>
                                        {OrderStatus.map((order, index) => (
                                        <option key={index} value={order.id}> {order.status}</option>

                                        ))}
                                    </Field>
                                    </li> */}
                                {/* <li className="list-group-item">
                                    <span className="text-bold mr-3">Payment Method :</span> {order.payment_methods ? order.payment_methods.name : ''}
                                </li> */}
                                {/* <li className="list-group-item">
                                    <span className="text-bold mr-3">Payment Method :</span> 
                                    <Field
                                        name="payment_method"
                                        component="select"
                                        className="form-control"
                                        label="Payment Method"
                                        onChange={this.onPaymentMethodChange}
                                    >
                                        <option value="">--Select Payment Method--</option>
                                        {this.props.payments.map((payment, index) => (
                                        <option key={index} value={payment.id}> {payment.payment_type===1?payment.payment_types:payment.mobile_banking_types}</option>

                                        ))}
                                    </Field>
                                    </li> */}
                                {/* <li className="list-group-item">
                                    <span className="text-bold mr-3">Payment Status :</span> 
                                    <Field
                                        name="payment_status"
                                        component="select"
                                        className="form-control"
                                        label="Payment Status"
                                        onChange={this.onPaymentStatusChange}
                                    >
                                        <option value="">--Select Payment Status--</option>
                                        {PaymentStatus.map((order, index) => (
                                        <option key={index} value={order.id}> {order.status}</option>

                                        ))}
                                    </Field>
                                    </li>
                                    <li className="list-group-item">
                                    <span className="text-bold mr-3">Payment Details :</span> 
                                    
                                        {Object.keys(order).length > 0 ? <div
                                        dangerouslySetInnerHTML={{__html: order.payment_methods !==undefined? order.payment_methods.description:''}}/>:null}
                                    

                                        
                                   
                                    </li> */}
                           

                                {/* <li className="list-group-item">
                                    <h4>Billing Address</h4>
                                    {order.billing_address ? 
                                    <>
                                    <span className="text-bold mr-3">Name </span> {order.billing_address.first_name +' ' +order.billing_address.last_name  }<br/>
                                    <span className="text-bold mr-3">Address </span> {order.billing_address.address  }<br/>
                                    <span className="text-bold mr-3">Email </span> {order.billing_address.email  }<br/>
                                    <span className="text-bold mr-3">Phone No </span> {order.billing_address.phone_no   }<br/>
                                    <span className="text-bold mr-3">District </span> {order.billing_address.districts ? order.billing_address.districts.name : ''   }<br/>
                                    <span className="text-bold mr-3">Reference Name </span> {order.billing_address.reference_name}<br/>
                                    <span className="text-bold mr-3">Reference Phone No </span> {order.billing_address.reference_phone_no}<br/>
                                    
                                    </>:
                                    null
                                    }

                                </li>
                                <li className="list-group-item">
                                    {order.shipping_address ? 
                                    <>
                                    <h4>Delivery Address</h4>
                                    <span className="text-bold mr-3">Name </span> {order.shipping_address.first_name +' ' +order.shipping_address.last_name  }<br/>
                                    <span className="text-bold mr-3">Address </span> {order.shipping_address.shipping_address  }<br/>
                                    <span className="text-bold mr-3">Email </span> {order.shipping_address.email  }<br/>
                                    <span className="text-bold mr-3">Phone No </span> {order.shipping_address.phone_no   }<br/>
                                    <span className="text-bold mr-3">District </span> {order.shipping_address.districts ? order.shipping_address.districts.name : ''   }<br/>
                                    </>:
                                    null
                                    }
                                </li> */}

                                



                            </ul>
                            <div className="col-md-12">

                            <strong>Product List</strong>
                            {/* <Link to={"/update-order/"+order.id }  className="btn btn-primary float-right text-white">
                                <b>Update Order</b>
                            </Link> */}
                            </div>
                            <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Product Name</th>
                                            <th>Product CODE</th>
                                            <th> Quantity</th>
                                            {/* <th>Seller Name</th> */}
                                            {/* <th>Product Unit</th> */}
                                            <th>Price</th>
                                            <th>Sub Total</th>
                                        </tr>
                                      {  agent_product_sales.product_order !=undefined &&  agent_product_sales.product_order.map((orderproduct,index) => (
                                        <tr>
                                            <td>
                                                {orderproduct.product.product_name}
                                            </td>
                                            <td>
                                            {orderproduct.product.product_sku}
                                            </td>
                                            <td >
                                            {orderproduct.quantity}
                                            </td>
                                            {/* <td >
                                            {orderproduct.product.vendor ? orderproduct.product.vendor.user.first_name + ' '+ orderproduct.product.vendor.user.last_name : '' }
                                            </td> */}
                                            
                                            
                                            <td >
                                            {(orderproduct.price).toFixed(2)}
                                            {/* <input class="input-group-field form-control" type="number" name="price" value={orderproduct.price} onChange={this.handleChange}/> */}

                                            {/* <div className="col-md-6">
                                                <Field
                                                name="price"
                                                type="text"
                                                component={renderField}
                                                // label="Shipping Address"
                                                // validate={isShipping ? required : false}
                                                // warn={alphaNumeric}
                                                />
                                            </div> */}
                                            {/* {orderproduct.quantity*orderproduct.price} */}
                                            </td>
                                            <td>
                                            {(orderproduct.quantity*orderproduct.price).toFixed(2)}
                                            </td>
                                            {/* <td>
                                                    {orderproduct.product.is_pre_order===true? orderproduct.product.pre_order_shipping_estimate:''}
                                                </td> */}
                                        </tr>
                                          ))}
                                           {/* {  order.bundle_list !=undefined ? 
                                           <>
                                           {order.bundle_list && order.bundle_list.map(orderproduct => (
                                            <tr>
                                                <td>
                                                    {orderproduct.title}
                                                </td>
                                                <td>
                                                
                                                </td>
                                                <td >
                                                {orderproduct.quantity}
                                                </td>
                                                <td >
                                                
                                                </td>
                                                
                                                <td >
                                                {(orderproduct.subtotal !==null?orderproduct.subtotal:0).toFixed(2)}
                                                </td>
                                                
                                            </tr>
                                       
                                          ))}
                                           </>
                                        :null
                                        } */}
                                         
                                        {/* <tr>
                                        <th colSpan='2' className="text-center">G.Sub Total</th>
                                        
                                           
                                            <td></td>
                                            <td></td>
                                            <td>{(this.state.sub_total).toFixed(2)}</td>
                                           
                                        </tr>  */}
                                        {/* {
                                            order.discount_amount>0?
                                            <tr>
                                            <th colSpan='2' className="text-center">Discount</th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{order.discount_amount?(order.discount_amount).toFixed(2):0}</td>
                                            
                                        </tr>
                                        :null
                                        }
                                        {
                                            order.tax>0?
                                            <tr>
                                            <th colSpan='2' className="text-center">Tax</th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{order.tax?order.tax:0}</td>
                                            
                                        </tr> 
                                        :null
                                        }
                                       {
                                           order.shipping_charge>0?
                                           <tr>
                                            <th colSpan='2' className="text-center">Delivery Charge</th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>{order.shipping_charge?order.shipping_charge:0}</td>
                                           
                                        </tr>
                                        :null
                                       }
                                        
                                        {order.total_due>0 ?
                                        <tr>
                                            <th colSpan='2' className="text-center">Due</th>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>(-){order.total_due}</td>
                                           
                                        </tr> 
                                        :null
                                        } */}
                                        
                                          {/* <tr>
                                            <td colSpan='2' className="text-center" style={{fontWeight:'bold'}}>Gross Total</td>
                                            <td>{agent_product_sales.total_quantity}</td>
                                            <td></td>
                                            <td></td>
                                            <td>{agent_product_sales.total_price}</td>
                                        </tr>      */}
                                        <tr></tr>  
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>    
                        </div>
                    </div>
                </div>
            </div>

        ) : (
                <div className="center"><CircularProgress /></div>
            );
        return (
            <div>
                <h1>Product Sale Detail</h1>
                {productSaleDetails}

            </div>
        );
    }
}

AgentProductStockSaleDetails.propTypes = {
    AgentProductSaleDetail: PropTypes.func.isRequired,
    BulkAction: PropTypes.array.isRequired,

    // partner: PropTypes.isRequired,
};

AgentProductStockSaleDetails = reduxForm({
    form: 'AgentProductStockSaleDetails',
    enableReinitialize: true
  }
  )(AgentProductStockSaleDetails);

const mapStateToProps = (state) => ({
    agent_product_sales: state.agent_product_sales.agent_product_sale,
    initialValues: state.agent_product_sales.agent_product_sale,
    // orders: state.orders.orders,
    is_loading: state.agent_product_sales.is_loading,
    detail:state.agent_product_sales.detail,
    bulk: state.products.bulk,
    payments:state.payments.payments

});

   
export default connect(mapStateToProps, { AgentProductSaleDetail,BulkAction,fetchPaymentMethods })(AgentProductStockSaleDetails);
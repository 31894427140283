import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import Select from "react-select";
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match, dateTime } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchCampaigns,fetchCampaignGroup, addData } from "../../../actions/CampaignActions"
import { searchSelectProducts,getSearchProducts } from '../../../actions/ProductAction';
import { searchSellersForSelect } from "../../../actions/SellerActions"
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
// import AsyncSelect from 'react-select/async'
// import Select from "react-select";
import { sumTotalAmount, sumTotalQuantity } from '../../../constants/CustomMethod'
import Autosuggest from 'react-autosuggest';

import { BASE_URL, CAMPAIGN_URL,PRODUCT_URL,CAMPAIGN_PRODUCT_URL } from "../../../constants/api";
import "react-widgets/dist/css/react-widgets.css";
import { toast } from 'react-toastify';



const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};

// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
    <div>
        <label className="text-sm">{label}</label>
        <DateTimePicker
            onChange={onChange}
            format="DD-MM-YYYY hh:mm A"
            time={showTime}
            value={!value ? null : new Date(value)}
            placeholder={label}
        />
    </div>;


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddCampaign extends Component {
    state = {
        product: '',
        value: '',
        sellerProductDetails: [
          {
            index: Math.random(),
            selected_seller: '',
            product_list: [],
            searched_product:null,
            discount_type:'',
            discount_price:0,
           // attribute_options: [],
          },
        ],
        primary: null,
        secondary: null,
        child: null,
        // product_list:[],
        selected_seller:'',
        selected_product:''
        
      };
    componentDidMount() {
      this.props.fetchCampaignGroup()
      this.props.searchSelectProducts()
      this.props.searchSellersForSelect()
    
    }


    //
  addSellerRow = (e) => {
    this.setState((prevState) => ({
      sellerProductDetails: [
        ...prevState.sellerProductDetails,
        {
          index: Math.random(),
          selected_seller: [],
          product_list: [],
          searched_product:null,
          discount_type:'',
          discount_price:0,
        },
      ],
    }));
  };

  deteteSellerProductRow(record) {
    this.setState({
      sellerProductDetails: this.state.sellerProductDetails.filter((r) => r !== record),
    });
  }
  onChangeType = index => (e) => {
    var sellerProductDetails = [...this.state.sellerProductDetails]
    sellerProductDetails[index][e.target.name]=e.target.value
    this.setState(sellerProductDetails);
  }  
  getSellersSuggestion  = (value) => { 
   this.props.searchSellersForSelect(value);
  };
  getSeller = index =>  (selected_seller) => {   
   var sellerProductDetails = [...this.state.sellerProductDetails]
   sellerProductDetails[index]["selected_seller"] = selected_seller;
   var url = CAMPAIGN_PRODUCT_URL + '?vendor__shop_name=' + selected_seller.label
   let headers = { "Content-Type": "application/json" };
   let  token  = localStorage.getItem('token')
   if (token) {
       headers["Authorization"] = `Token ${token}`;
   }
  
   axios.get(url, { headers, })
       .then(response => {
           var results = []
           for (var i = 0; i < response.data.length; i++) {
               results.push({ label: response.data[i].product_name, value: response.data[i].id,data: response.data[i] })
           }
           sellerProductDetails[index]["searched_product"] = results;
           this.setState({ sellerProductDetails });
       })  
  }; 

  getSuggestions = value => {
   
   this.props.searchSelectProducts(value);
  };
  getProduct = index => (selected_product) => {   
    // alert(JSON.stringify(selected_product.value))
    var sellerProductDetails = [...this.state.sellerProductDetails]
    var checkProduct =  sellerProductDetails[index]["product_list"].findIndex(x => x.id == selected_product.value);
    if (checkProduct === -1) {
      sellerProductDetails[index]["product_list"].push(selected_product.data);
      this.setState({ sellerProductDetails });
    }else{
      toast.warning("Product already exists in this list")
    }
   
   
  }; 

   // Filter logic
  

  removeProdcutRow = (index,product) => {
   // event.preventDefault();
    var sellerProductDetails = [...this.state.sellerProductDetails]
    var products = sellerProductDetails[index]["product_list"].filter((r) => r !== product)
    sellerProductDetails[index]["product_list"]=products
    this.setState({
      sellerProductDetails
    });

  }

  onChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };


  




 


    
    submit(event) {
        const {
            sellerProductDetails
         
          } = this.state;
       
        var form_data = new FormData();
        Object.keys(event).forEach(key => form_data.append(key, event[key]));
        form_data.append("campaign_group", event.campaign_group)
        form_data.append("to_date", dateTime(event.to_date));
        form_data.append("from_date", dateTime(event.from_date));
        var seller_product = []
        for (var i = 0; i < sellerProductDetails.length; i++) {
          let product_list=[]
         
           for(var j = 0; j < sellerProductDetails[i].product_list.length;j++){
             product_list.push(sellerProductDetails[i].product_list[j].id)
           } 
          let sellers_obj={
            'seller_id':sellerProductDetails[i].selected_seller ? sellerProductDetails[i].selected_seller.value:'',
            'discount_type':sellerProductDetails[i].discount_type!=='' ? sellerProductDetails[i].discount_type:1,
            'discount_price':sellerProductDetails[i].discount_price!=='' ? sellerProductDetails[i].discount_price:0,
            'product_ids':[]
          }
         
          sellers_obj['product_ids']=product_list
          seller_product.push(sellers_obj)
        }  

        form_data.append("seller_products", JSON.stringify(seller_product));     
        this.props.addData(form_data, CAMPAIGN_URL, "Campaign")




    }

    render() {


        const { handleSubmit, submitting,primarycategory, sellers,products, secondarycategory } = this.props;
        const { 
            value,
            selected_primary,
            selected_seller,
            primary,
            selected_sceondary,
            secondary,
            selected_child,
            // product_list,
            totalAmount,
            totalQuantity,
            child, 
            sellerProductDetails
        } = this.state;

        const inputProps = {
            placeholder: 'Type Product Name or Product Sku Here',
            value,
            type: "search",
            onChange: this.onChange
        };

        if (this.props.is_added) {
            setTimeout(() => {
                window.location.href = "/campaign-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Campaign </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Campaign add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Campaign Information</h3>
                        <div className="float-right">
                            <Link to="/campaign-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="campaign_title"
                                        type="text"
                                        component={renderField}
                                        label="Campaign Title"
                                        validate={required}
                                        required={true}
                                    // warn={alphaNumeric}
                                    />
                                </div>     
                                <div className="col-md-6">
                                    <Field
                                        name="campaign_description"
                                        type="text"
                                        component={renderField}
                                        label="Campaign Desciption"
                                        // validate={required}
                                        // required={true}
                                    // warn={alphaNumeric}
                                    />
                                </div>                
                                {/* <div className="col-md-6">
                                <label>Select Your Product</label>

                                    <AsyncSelect
                                        onChange={this.productChange}
                                        loadOptions={this.loadOptions}
                                        isMulti={true}
                                        placeholder="Select Your Product"
                                    />
                                </div>
                                       */}

                                <div className="col-md-6">
                                    <Field
                                        name="from_date"
                                        type="date"
                                        component={renderDateTimePicker}                                        label=" From Date"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="to_date"
                                        type="date"
                                        showTime={true}
                                        component={renderDateTimePicker}                                        label="To Date"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                              
                              <div className="col-md-6">
                                    <label className="text-sm">Campaign Banner</label>
                                    <Field
                                        name="banner"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Campaign banner"
                                      //  validate={[required]}
                                    />

                              </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Campaign Featured Photo</label>
                                    <Field
                                        name="feature_photo"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Campaign Featured Photo"
                                    // validate={[required]}
                                    />

                                </div>

                                <div className="col-md-6">
                                    <label>Campaign Group</label>
                                    <Field
                                        name="campaign_group"
                                        component="select"
                                        className="form-control"

                                        label="Campaign Group"
                                        validate={required}
                                        required={true}

                                    >
                                        <option value=""  >--Select Campaign Group--</option>
                                        {this.props.campaign_groups.map((camgroup, index) => (
                                            <option key={index} value={camgroup.id}>{camgroup.name}</option>
                                        ))}

                                    </Field>
                                </div>

                                {/* <div className="col-md-6"></div> */}
                                {sellerProductDetails.map((sellerdata, idx) => (
                                <>
                                <div className="col-md-3">
                                  <label>Select Seller</label>
                               
                                  <Select
                                    value={sellerdata.selected_seller}
                                   
                                    onInputChange={this.getSellersSuggestion}
                                    required={true}
                                    onChange={this.getSeller(idx)}
                                   
                                    options={sellers}
                                    placeholder="Select Seller"
                                    // isClearable
                                  />

                              </div>
                                <div className="col-md-3">
                                  <label>Type Your Product</label>
                               
                                  <Select
                                   // value={selected_primary}
                                   onInputChange={this.getSuggestions}
                                    required={true}
                                    onChange={this.getProduct(idx)}
                                    options={sellerdata.searched_product}
                                    placeholder="Type Your Product"
                                    // isClearable
                                  />

                              </div>
                              <div className="col-md-2">
                                <label>Discount Type</label>
                                {/* <span className="requiredField">*</span> */}
                                <select className="form-control" onChange={this.onChangeType(idx)} name="discount_type" component="select" validate={required} className="form-control"
                                >
                                  <option value="">Select Discount Type</option>
                                  <option value="1">Percentage (%)</option>
                                  <option value="2">Flat Discount (BDT)</option>
                                 

                                </select>
                              </div>
                              <div className="col-md-2">
                              <label>Discount Amount</label>
                                <input name="discount_price" onChange={this.onChangeType(idx)}  validate={required}  className="form-control"
                                  label="Discount Amount" 
                                  placeholder="Discount Amount"
                                  className="form-control"/>
                              </div>
                              <div className="col-md-2">
                              
                             
                              {idx === 0 ? (
                              <button
                                    onClick={() => this.addSellerRow()}
                                    type="button"
                                    className="btn btn-primary text-center"
                                  >
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    />
                                  </button>
                                   ) : (
                                    <button
                                    className="btn btn-danger"
                                     onClick={() => this.deteteSellerProductRow(sellerdata)}
                                  >
                                    <i
                                      className="fa fa-minus"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                              </div>  
                
                {sellerdata.product_list.length > 0 ? (
                  <>
                    <div className="col-md-12">
                      <strong>Product List</strong>
                    </div>
                    <div className="col-md-12">
                      <table className="table orderlist">
                        <tr>
                        <th>SL</th> 
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Product Sku</th>
                          {/* <th>Vendor/Seller</th> */}
                          <th>Product Type</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                        <tbody>
                          {sellerdata.product_list.map((product, index) => (

                            <tr id={product.id} key={index}>
                                <td>
                               {index+1}
                              </td>
                              <td>
                                <img src={BASE_URL+ product.feature_image} height="100px" width="100px" alt="No Image" />
                              </td>
                              <td>
                                {product.product_name}
                              </td>
                              <td>
                                {product.product_sku}
                              </td>
                              {/* <td>
                                {product.vendor ? product.vendor.shop_name : ''}
                              </td> */}
                              <td>{product.product_type == 1 ? 'Physical' : 'Virtual'}</td>

                              <td>
                                {product.regular_price}
                              </td>
                             
                             
                              <td>
                                <button
                                  className="btn btn-danger" id={product.id}
                                  onClick={() => this.removeProdcutRow(idx,product)}
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  />
                                </button>

                              </td>
                            </tr>
                          ))}
                         
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null
                }
                          
                              </>
                              ))}
                            </div>
                         
                 
                        </div>
                        <div className="card-footer text-right">
                            {/* {product_list.length>0 ? (  */}
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Create Campaign
                                </button>
                            
                            </div>
                                {/* ):null
                            } */}
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddCampaign.propTypes = {
    searchCustomer: PropTypes.func.isRequired,
    searchSelectProducts: PropTypes.func.isRequired,
    getChildCategoryForSelect: PropTypes.func.isRequired,
    getSecondaryCategoryForSelect: PropTypes.func.isRequired,
    searchSellersForSelect: PropTypes.func.isRequired,
    fetchCampaignGroup:PropTypes.func.isRequired,
    
};

const mapStateToProps = state => ({

    is_added: state.campaigns.is_added,
    products: state.products.products,
    campaign_groups:state.campaign_groups.campaign_groups,
    primarycategory: state.primarycategory.primary_categories,
    childcategory: state.childcategory.child_categories,
    secondarycategory: state.secondarycategory.secondary_categories,
    sellers:state.sellers.sellers
});


AddCampaign = reduxForm({
    form: 'AddCampaign',
}
)(AddCampaign);


export default connect(mapStateToProps, { searchSelectProducts, addData,searchSellersForSelect,fetchCampaignGroup })(AddCampaign);

import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FoodorderDetail } from '../../../actions/RestaurantActions';
import moment from "moment";
import 'moment-timezone';

import {  OrderStatus } from '../../../constants/Datas'
import { Field, reduxForm } from "redux-form";
import { sumTotalAmount, sumTotalQuantity } from '../../../constants/CustomMethod'

import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import {  BulkAction } from '../../../actions/ProductAction';
import { ORDER_BULK_URL,FOOD_ORDER_BULK_URL } from '../../../constants/api'
import OrderPdf from "../../../services/OrderGeneratePdf";

// Text area type
const renderTextArea = ({
    input,
    label,
    meta: { touched, error, warning },
  }) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
      <div>
        <label className="text-sm">{label}</label>
        <div>
          <textarea
            {...input}
            placeholder={label}
            rows="3"
            cols="40"
            className={className}
          />
          {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  };

  const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
    required = false,
  }) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
      <div className="form-group">
        <label className="text-sm">{label}</label>
        {required ? <span className="requiredField">*</span> : ""}
        <div>
          <input
            {...input}
            placeholder={label}
            type={type}
            className={className}
          />
          {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  };

class FoodOrderDetail extends Component {
    state ={
        order_status:'',
        foodorder:[],
        get_order:false,
        allchecked: false, 
        bulk: false,
        unit_amount:'',
        oderList: [],
        price:'',
        subtotal:0,

    }

    componentDidMount() {
       
        let id = this.props.match.params.id;
        this.props.FoodorderDetail(id);

    }
    handleChange=(event)=> {    this.setState({unit_amount: event.target.value});  }
    onChange = e => {this.setState({ unit_amount: e.target.value })};
    static getDerivedStateFromProps(nextProps, prevState) {
        
        if (nextProps.is_getting) {
            // alert(JSON.stringify(nextProps.initialValues.order_product.unit_amount))
            if (nextProps.is_getting !== prevState.get_order) {
                var order = []
                var subtotal=0
                var foods=nextProps.initialValues.food_order_list
                for (var i = 0; i <foods.length; i++) {
                    // alert(foods[i].price)
                    subtotal +=foods[i].price*foods[i].quantity
                    order.push(nextProps.foodorder[i])
                }
                return {
                    foodorder: order,
                    subtotal:subtotal,
                    // orderproduct.product.unit_amount
                    new_price:nextProps.initialValues.food_order_list.order_restaurant_food !=undefined?nextProps.initialValues.food_order_list.order_restaurant_food.new_price:'',
                    price:nextProps.initialValues.food_order_list !=undefined?nextProps.initialValues.food_order_list.price:0,
                    // unit_amount:nextProps.initialValues.order_product !=undefined?nextProps.initialValues.order_product.unit_amount:0,
                    
                    get_order: true
                }
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
       
        if (prevProps.order != this.props.order){
            this.setState({order:this.props.order})
        }

    }

    manageQuantity = (index, type = "plus") => {
        let orders = [...this.state.oderList]
        if (type == "plus") {
          orders[index].unit_amount = orders[index].unit_amount + 1;
          orders[index].subtotal = (orders[index].regular_price - orders[index].discount_price) * orders[index].unit_amount;
    
        } else {
          if (orders[index].unit_amount > 1) {
            orders[index].unit_amount = orders[index].unit_amount - 1;
            orders[index].subtotal = (orders[index].regular_price - orders[index].discount_price) * orders[index].unit_amount;
    
          }
        }
        this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders) })
      }


    onOrderStatusChange = (e)=>{
      
        let id = this.props.match.params.id;
          if(this.props.initialValues.order_status !== 5){
            this.props.BulkAction(
                { 'id': [id], 'action': e.target.value }, FOOD_ORDER_BULK_URL
            )
          }else{
            alert("You are not able to change completed order") 
          }
        
           
        
    }

  

    PrintOption=()=>{
        window.print()
    }

    render() {
    //    alert(JSON.stringify(this.props.orders))
        const { foodorder } = this.props
        if (this.props.bulk) {
            setTimeout(function () {
              window.location.reload()
            },
                1000);
        }

        
            const orderDetails = foodorder !== undefined ? (
            <div className="card">
                <div className="card-header">
                    <h3 className="card-title">Food Order Information</h3>
                    <div className="float-right">
                        
                        <Link to="/food-order-list" className="btn btn-success float-right text-white"><b>
                            <i className="fa fa-backward"></i>
                                Back To List</b>
                        </Link>&nbsp;&nbsp;
                        {/* <button onClick={this.PrintOption} className="btn btn-primary float-right text-white mr-2"><b>
                            <i className="fa fa-print"></i>
                                Print</b>
                        </button> */}

                        {/* <button
                            className="btn btn-primary mr-2"
                            onClick={() => OrderPdf(order)}
                            formTarget="_blank"
                            >
                                Generate Order PDF
                            </button> */}
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {/* <div className="col-3">

                        </div> */}
                        <div className="col-12">
                            <ul className="list-group list-group-flush">
                                
                                 <li className="list-group-item">
                                    <span className="text-bold mr-3">Order No :</span><strong> #{foodorder.order_no}</strong>
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Order Date :</span> {foodorder.order_date ? moment(foodorder.order_date).tz('Asia/Dhaka').format('llll') : ''}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Delivery Time :</span> {foodorder.delivery_date_time ? moment(foodorder.delivery_date_time).tz('Asia/Dhaka').format('llll') : ''}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Delivery To :</span> {foodorder.delivery_to}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Customer Name :</span> {foodorder.customer ? foodorder.customer.first_name  : ''}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Customer Account Phone No :</span> {foodorder.customer ? foodorder.customer.phone_no : ''}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Restaurant Name :</span> {foodorder.restaurant !==undefined && foodorder.restaurant !==null  ? foodorder.restaurant.restaurant_name : ''}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Total Quantity :</span> {foodorder.total_order_quantity}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Total Price :</span> {foodorder.total_order_price}
                                </li>
                                
                              
                                
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Order Status :</span> 
                                    <Field
                                        name="order_status"
                                        component="select"
                                        className="form-control"
                                        label="Order Status"
                                        onChange={this.onOrderStatusChange}
                                    >
                                        <option value="">--Select Order Status--</option>
                                        {OrderStatus.map((order, index) => (
                                        <option key={index} value={order.id}> {order.status}</option>

                                        ))}
                                    </Field>
                                    </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Payment Method :</span> {foodorder.payment_method ? foodorder.payment_method.name : ''}
                                </li>
                                <li className="list-group-item">
                                    <span className="text-bold mr-3">Payment Status :</span> 
                                    {foodorder.payment_status }
                                </li>

                                <li className="list-group-item">
                                    <h4>Billing Address</h4>
                                    {foodorder.billing_address ? 
                                    <>
                                    <span className="text-bold mr-3">Name </span> {foodorder.billing_address.first_name +' ' +foodorder.billing_address.last_name  }<br/>
                                    <span className="text-bold mr-3">Address </span> {foodorder.billing_address.address  }<br/>
                                    <span className="text-bold mr-3">Email </span> {foodorder.billing_address.email  }<br/>
                                    <span className="text-bold mr-3">Phone No </span> {foodorder.billing_address.phone_no   }<br/>
                                    <span className="text-bold mr-3">District </span> {foodorder.billing_address.districts ? foodorder.billing_address.districts.name : ''   }<br/>
                                    
                                    <span className="text-bold mr-3">Post code </span> {foodorder.billing_address.post_code  }<br/>
                                    </>:
                                    null
                                    }

                                </li>
                                <li className="list-group-item">
                                   
                                    {foodorder.shipping_address ? 
                                    <>
                                    <h4>Shipping Address</h4>
                                    <span className="text-bold mr-3">Name </span> {foodorder.shipping_address.first_name +' ' +foodorder.shipping_address.last_name  }<br/>
                                    <span className="text-bold mr-3">Address </span> {foodorder.shipping_address.shipping_address  }<br/>
                                    <span className="text-bold mr-3">Email </span> {foodorder.shipping_address.email  }<br/>
                                    <span className="text-bold mr-3">Phone No </span> {foodorder.shipping_address.phone_no   }<br/>
                                    <span className="text-bold mr-3">District </span> {foodorder.shipping_address.districts ? foodorder.shipping_address.districts.name : ''   }<br/>
                                    
                                    <span className="text-bold mr-3">Post code </span> {foodorder.shipping_address.post_code  }<br/>
                                    </>:
                                    null
                                    }

                                </li>

                                



                            </ul>
                            <strong>Food Order List</strong>
                            <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Food Name</th>
                                            <th></th>
                                            <th>Ordered Quantity</th>
                                            {/* <th>Seller Name</th> */}
                                            <th></th>
                                            <th>Price</th>
                                            <th>Sub Total</th>
                                        </tr>
                                      {  foodorder.food_order_list !=undefined &&  foodorder.food_order_list.map((orderproduct,index) => (
                                        <tr>
                                            <td>
                                                {orderproduct.order_restaurant_food.food_names.map(dd=> dd.name !==undefined? dd.name :'')}
                                            </td>
                                            <td>
                                            {/* {orderproduct.order_restaurant_food.price} */}
                                            </td>
                                            <td >
                                            {orderproduct.quantity}
                                            </td>
                                            {/* <td >
                                            {orderproduct.product.vendor ? orderproduct.product.vendor.user.first_name + ' '+ orderproduct.product.vendor.user.last_name : '' }
                                            </td> */}
                                            <td >
                                            <div class="input-group plus-minus-input">
                                            
                                            {/* <input class="input-group-field form-control" type="number" name="unit_amount" value={orderproduct.order_restaurant_food.price} onChange={this.handleChange} /> */}
                                            
                                            </div>
                                            {/* <div className="col-md-6">
                                                <Field
                                                name="unit_amount"
                                                type="text"
                                                component={renderField}
                                                // label="Shipping Address"
                                                // validate={isShipping ? required : false}
                                                // warn={alphaNumeric}
                                                />
                                            </div> */}
                                            {/* {orderproduct.product.unit_amount} */}
                                            </td>
                                            
                                            <td >
                                            {orderproduct.order_restaurant_food.new_price}
                                            {/* <input class="input-group-field form-control" type="number" name="price" value={orderproduct.price} onChange={this.handleChange}/> */}

                                            {/* <div className="col-md-6">
                                                <Field
                                                name="price"
                                                type="text"
                                                component={renderField}
                                                // label="Shipping Address"
                                                // validate={isShipping ? required : false}
                                                // warn={alphaNumeric}
                                                />
                                            </div> */}
                                            {/* {orderproduct.quantity*orderproduct.price} */}
                                            </td>
                                            <td>{(orderproduct.quantity*orderproduct.order_restaurant_food.price).toFixed(2)}</td>
                                            {/* <td>
                                                    {orderproduct.product.is_pre_order===true? orderproduct.product.pre_order_shipping_estimate:''}
                                                </td> */}
                                        </tr>
                                          ))}
                                           {/* {  foodorder.bundle_list !=undefined ? 
                                           <>
                                           {foodorder.bundle_list && foodorder.bundle_list.map(orderproduct => (
                                            <tr>
                                                <td>
                                                    {orderproduct.title}
                                                </td>
                                                <td>
                                                
                                                </td>
                                                <td >
                                                {orderproduct.quantity}
                                                </td>
                                                <td >
                                                
                                                </td>
                                                
                                                <td >
                                                {orderproduct.quantity*orderproduct.total_price}
                                                </td>
                                                
                                            </tr>
                                       
                                          ))}
                                           </>
                                        :null
                                        } */}
                                        <tr>
                                        <th colSpan='2' className="text-center">Sub Total</th>
                                        <th colSpan='3'></th>
                                        <th >{this.state.subtotal.toFixed(2)}</th>
                                          
                                           
                                        </tr> 
                                        <tr>
                                            <th colSpan='2' className="text-center">Discount</th>
                                            <th colSpan='3'></th>
                                          
                                            <th>{foodorder.discount_amount?(foodorder.discount_amount).toFixed(2):0}</th>
                                        </tr>
                                         <tr>
                                            <th colSpan='2' className="text-center">Tax</th>
                                            <th colSpan='3'></th>
                                          
                                            <th>{foodorder.tax?foodorder.tax:0}</th>
                                        </tr>  
                                        <tr>
                                            <th colSpan='2' className="text-center">Delivery Charge</th>
                                            <th colSpan='3'></th>
                                          
                                            <th>{foodorder.delivery_charge?foodorder.delivery_charge:0}</th>
                                        </tr> 
                                        {foodorder.total_due>0 ?
                                        <tr>
                                            <th colSpan='2' className="text-center">Due</th>
                                            <th colSpan='3'></th>
                                          
                                            <th>(-){foodorder.total_due}</th>
                                        </tr> 
                                        :null
                                        }
                                          <tr>
                                            <th colSpan='2' className="text-center">Total</th>
                                          
                                            <th colSpan='3'>{foodorder.total_order_quantity}</th>
                                            <th>
                                            {foodorder.tax !='' ? (foodorder.tax+foodorder.total_order_price).toFixed(2) : (foodorder.total_order_price).toFixed(2)}
                                            {/* <input class="input-group-field form-control" type="number" name="quantity" value={foodorder.tax !='' ? foodorder.tax+foodorder.delivery_charge+foodorder.total_order_price : foodorder.total_order_price} onChange={this.handleChange} /> */}

                                            {/* <div className="col-md-6">
                                                <Field
                                                name="price"
                                                type="text"
                                                component={renderField}
                                                // label="Shipping Address"
                                                // validate={isShipping ? required : false}
                                                // warn={alphaNumeric}
                                                />
                                            </div> */}
                                            </th>
                                            {/* <th>{order.tax !='' ? order.tax+order.total_order_price : order.total_order_price}</th> */}
                                        </tr>       
                                    </thead>
                                    <tbody>
                                    </tbody>
                                </table>    
                        </div>
                    </div>
                </div>
            </div>

        ) : (
                <div className="center"><CircularProgress /></div>
            );
        return (
            <div>
                <h1>Food Order Detail</h1>
                {orderDetails}

            </div>
        );
    }
}

FoodOrderDetail.propTypes = {
    FoodorderDetail: PropTypes.func.isRequired,
    BulkAction: PropTypes.array.isRequired,

    // partner: PropTypes.isRequired,
};

FoodOrderDetail = reduxForm({
    form: 'FoodOrderDetail',
    enableReinitialize: true
  }
  )(FoodOrderDetail);

const mapStateToProps = (state) => ({
    foodorder: state.foodorders.foodorder,
    initialValues: state.foodorders.foodorder,
    // orders: state.orders.orders,
    is_loading: state.foodorders.is_loading,
    is_getting:state.foodorders.is_getting,
    bulk: state.products.bulk,

});

   
export default connect(mapStateToProps, { FoodorderDetail,BulkAction })(FoodOrderDetail);
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import {
    required
} from '../../constants/Validate'
import { connect } from "react-redux";
import { DistrictDetail } from "../../actions/DistrictsActions";
import { updateDatas } from "../../actions/CustomerActions";


import PropTypes from "prop-types";
import moment from 'moment';
import { Link } from "react-router-dom";

import momentLocalizer from 'react-widgets-moment';
import { DISTRICT_URL } from '../../constants/api';



momentLocalizer(moment);



// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class DistrictsUpdate extends Component {

    componentDidMount() {
        // alert(rideCategoryDetail)
        let id = this.props.match.params.id;
        this.props.DistrictDetail(id)
    }


    submit(values) {
        // console.log(values)
        // return sleep(1000).then(() => {
        //     let id = this.props.match.params.id;
        //     this.props.updateData(id, values);
        // })

        var form_data = {
            name: values.name
        }
        let id = this.props.match.params.id;
        this.props.updateDatas(form_data, DISTRICT_URL + id + '/', "District")
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        //console.log('ride_category_update_data', rideCategoryDetails);
        // console.log('update', rideCategoryDetails)
        if (this.props.is_updated) {
            setTimeout(() => {
                window.location.href = '/districts-list'
            }, 3000)
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update District/Area Information</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            {/* <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item active">Dashboard v2</li>
                                    </ol>
                                </div><!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="">Update District/Area Details
                        {/* <Link to="/districts-list" className="btn btn-info float-right text-white"><b>
                                Back</b>
                            </Link> */}
                            </h3>

                    </div>
                    {/* /.card-header */}
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="District/Area"
                                        validate={required}

                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>


                            </div>
                            <div className="card-footer text-right">
                                <div>
                                    <button
                                        type="submit"
                                        className="btn btn-info"
                                        disabled={submitting}>
                                        Update
                                </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


DistrictsUpdate.propTypes = {
    DistrictDetail: PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    initialValues: state.districts.district,
    districts: state.districts.district,
    is_added: state.customers.is_added,
    is_updated: state.customers.is_updated

});

DistrictsUpdate = reduxForm({
    form: 'DistrictsUpdate',
    enableReinitialize: true,
}
)(DistrictsUpdate);

export default connect(mapStateToProps, { DistrictDetail, updateDatas })(DistrictsUpdate);
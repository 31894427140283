import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import {
    required
} from '../../../constants/Validate'
import { connect } from "react-redux";
import { secondaryCategoryDetail, updateSecondaryCategory } from "../../../actions/SecondaryCategoryAction";
import { getPrimaryCategoryForSelect,getPrimaryCategory } from "../../../actions/PrimaryCategoryAction";

import PropTypes from "prop-types";
import moment from 'moment';
import { Link } from "react-router-dom";

import momentLocalizer from 'react-widgets-moment';



momentLocalizer(moment);

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class SecondaryCategoryUpdate extends Component {

    state = {
        district_id: 0
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.secondaryCategoryDetail(id)
        // this.props.getPrimaryCategoryForSelect(1)
        this.props.getPrimaryCategory()

    }


    submit(values) {
        
        // console.log(values)
        return sleep(1000).then(() => {
            let id = this.props.match.params.id;
            this.props.updateSecondaryCategory(id, values);
        })
    }

    render() {
        
        const { handleSubmit, submitting,secondarycategory } = this.props;
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-12">
                                <h1 className="m-0 text-dark">Update Secondary Category Information</h1>
                            </div>
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="">Update Secondary Category Details
                            <Link to="/secondary-category-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>
                        </h3>

                    </div>
                    {/* /.card-header */}
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">


                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="Name"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                        <Field
                                            name="class_name"
                                            type="text"
                                            component={renderField}
                                            label="Class Name"
                                            validate={required}
                                        // value={this.state.name}
                                        // onChange={this.handleChange}
                                        // warn={alphaNumeric}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="serial_no"
                                            type="text"
                                            component={renderField}
                                            label="Serial No"
                                            validate={required}
                                        // value={this.state.name}
                                        // onChange={this.handleChange}
                                        // warn={alphaNumeric}
                                        />
                                    </div>
                                <div className="col-md-6">
                                    <label>Primary Category</label>
                                    <span className="requiredField">*</span>
                                    <Field name="primary_category" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select primary category</option>
                                        {this.props.primarycategory.map(pri_category => (
                                            <option key={pri_category.id} value={pri_category.id}>{pri_category.name}</option>
                                        ))}
                                    </Field>
                                </div>
                                
                                <div className="col-md-6">
                                    <img src={secondarycategory.image} style={{width:150,height:150}}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Secondary Category Image</label>
                                    <Field
                                        name="images"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Secondary Category"
                                    // validate={[required]}
                                    />

                                </div>








                            </div>
                            <div className="row">
                                <div className="card-footer float-right">
                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-info"
                                            disabled={submitting}>
                                            Update
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>


        )
    }
}


SecondaryCategoryUpdate.propTypes = {

    updateSecondaryCategory: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    initialValues: state.secondarycategory.secondary_category,
    secondarycategory: state.secondarycategory.secondary_category,
    primarycategory: state.primarycategory.primary_categories


});

SecondaryCategoryUpdate = reduxForm({
    form: 'SecondaryCategoryUpdate',
    enableReinitialize: true,
}
)(SecondaryCategoryUpdate);

export default connect(mapStateToProps, { getPrimaryCategory, secondaryCategoryDetail, updateSecondaryCategory })(SecondaryCategoryUpdate);

import {
    LOADING_CHILD_CATEGORY,
    GETTING_CHILD_CATEGORY,
    DETAIL_CHILD_CATEGORY,
    UPDATE_CHILD_CATEGORY,
    DELETE_CHILD_CATEGORY,
    NOT_FOUND_CHILD_CATEGORY,
    ADD_CHILD_CATEGORY,

} from './types';

import { GET_CHILD_CATEGORY_URL, GET_CHILD_CATEGORY_CREATE_URL, GET_ALL_CHILD_CATEGORY_URL } from '../constants/api';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { SearchURLGenerate } from '../constants/CustomMethod'
import axios from 'axios';


// Post Child CATEGORY
export const addChildCategory = (data) => (dispatch, getState) => {

    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let form_data = new FormData()
    form_data.append('name', data.name)
    form_data.append('secondary_category', data.secondary_category,)
    if (data.image) {
        form_data.append('image', data.image, data.image.name)
    }

    axios
        .post(GET_CHILD_CATEGORY_CREATE_URL, data, { headers })
        .then(response => {
            console.log(response.data)

            dispatch({
                type: ADD_CHILD_CATEGORY,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success('Child Category added successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })

};



// FETCH Child CATEGORY
export const getChildCategory = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_CHILD_CATEGORY })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }


    let search_key = ["name",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_CHILD_CATEGORY_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: GETTING_CHILD_CATEGORY,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_CHILD_CATEGORY });

    }



};



// Child Category Detail
export const childCategoryDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_CHILD_CATEGORY_URL + `${id}/`;

    axios.get(url, { headers })
        .then(response => {

            dispatch({
                type: DETAIL_CHILD_CATEGORY,
                payload: response.data,
            })
        })
};



// Update Child Category
export const updateChildCategory = (id, data) => (dispatch, getState) => {

    let headers = { "Content-Type": "multipart/form-data" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let form_data = new FormData();
    form_data.append('name', data.name);
    form_data.append('class_name', data.class_name);
    form_data.append('serial_no', data.serial_no);
    form_data.append('secondary_category', data.secondary_category,)
    if (data.images !=null) {
        form_data.append('image', data.images, data.images.name);
    }
    // let form_data = {
    //     'name': data.name,
    //     'image': data.image,
    //     'secondary_category': data.secondary_category,

    // }

    let url = GET_CHILD_CATEGORY_CREATE_URL + `${id}/`;

    axios.put(url, form_data, { headers })
        .then(response => {
            console.log(response.data)
            dispatch({
                type: UPDATE_CHILD_CATEGORY,
                payload: response.data
            });

            toast.success("Child Category successfully updated");
            console.log('Success');
        })
        .catch(err => {
            // dispatch();
            toast.error('Please try again', err);
            console.log(err);
        })
};


// Delete Child Category
export const deleteChildCategory = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(GET_CHILD_CATEGORY_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_CHILD_CATEGORY,
                payload: id,
            });
            toast.success('Child Category deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};


export const getChildCategoryForSelect = (pageNumber, id) => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    dispatch({ type: LOADING_CHILD_CATEGORY });
    var url = GET_ALL_CHILD_CATEGORY_URL

    if (id) {
        url = GET_ALL_CHILD_CATEGORY_URL + `${id}/`;
    } else if (pageNumber) {
        url = GET_ALL_CHILD_CATEGORY_URL + '?page=' + pageNumber
    }
    axios.get(url, { headers, })
        .then(response => {
            var results = []
            for (var i = 0; i < response.data.length; i++) {
                results.push({ label: response.data[i].name, value: response.data[i].id })
            }
            dispatch({
                type: GETTING_CHILD_CATEGORY,
                payload: response.data ? results : [],
                count: response.data.count,
                next: response.data.next
            })
        })




};
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { addData } from '../../../actions/ProductAction';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import { fetchFavicon } from '../../../actions/LogoAction';

import { toast } from "react-toastify";
import { FAVICON_URL, SELLER_URL } from "../../../constants/api";
import { getLuminance } from '@material-ui/core';


momentLocalizer(moment);



const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class Favicon extends Component {
    state = {

        id: 0,
        favicon: null,
        get_favicon: false,
    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_getting === true) {
            if (nextProps.is_getting !== prevState.get_favicon) {
                
                return {

                    id: nextProps.favicons[0] ? nextProps.favicons[0].id : '',
                    favicon:nextProps.favicons[0] ? nextProps.favicons[0].favicon :'',
                    get_favicon: true
                }
            }
        }
        return null
    }
    onChangeText = (e) => {

        this.setState({ [e.target.name]: e.target.value })

    }
    componentDidMount() {
        // alert(JSON.stringify(this.props.favicons.favicon))
        this.props.fetchFavicon(1)

    }


    submit(event) {
        console.log(event)
        var form_data = new FormData();
        if (event.images != null && event.images !=='') {

            form_data.append('favicon', event.images, event.images.name)
        }
        // alert(JSON.stringify(event))
        this.props.addData(form_data, FAVICON_URL , "Favicon")
        // this.props.fetchLogo(1)



    }

    handleImageChange = (e) => {
        let favicon = e.target.files[0]
        // let get_favicon = URL.createObjectURL(favicon)
        this.setState({
            favicon: favicon,
            // get_favicon: get_favicon
        })
    };
    render() {
        const { handleSubmit, submitting,permissions } = this.props;
        if (this.props.is_added) {
            setTimeout(function () {

                window.location.href = '/favicon'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Favicon </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Favicon</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                {CheckPermisionAction(permissions,'Appearance','view_favicon_list')?

                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Favicon</h3>
                        {/* <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div> */}
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">


                                
                                <div className="col-md-12">
                                    <img src={this.state.favicon ? this.state.favicon : ''} height="24px" width="24px" alt='' />



                                    <br /><br />
                                    {/* <div className="col-md-6">
                                        <label>Favicon</label>
                                        <input
                                            name='favico'
                                            type="file"
                                            accept="image/*"
                                            className="form-control"
                                            label="Favicon "
                                            validate={required}
                                            onChange={this.handleImageChange}
                                        />

                                    </div> */}
                                </div>
                                <div className="col-md-6">
                                    
                                    <Field
                                        name="images"
                                        type="file"
                                        component={FileInput}
                                        accept="image/*"
                                        label=" Favicon"
                                    // validate={[required]}
                                    />
                                </div>









                            </div>


                        </div>
                        <div className="card-footer text-right">
                            <div>
                        {CheckPermisionAction(permissions,'Appearance','favicon_save')?

                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>
                        :null}

                            </div>
                        </div>
                    </form>
                </div>
                :null}
            </div >

        )
    }
}


Favicon.propTypes = {
    // getRides:PropTypes.func.isRequired,
    addData: PropTypes.func.isRequired,
    fetchFavicon: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    favicons: state.favicons.favicons,
    is_added: state.favicons.is_added,
    is_getting: state.favicons.is_getting,
    permissions:state.userpermissions.userpermissions
    // initialValues: state.favicons.favicon,

});


Favicon = reduxForm({
    form: 'Favicon',
    // enableReinitialize: true

}
)(Favicon);


export default connect(mapStateToProps, { fetchFavicon, addData })(Favicon);

import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { toast } from "react-toastify";
// import { order_URL } from "../../constants/api";
import { Link } from "react-router-dom";
import Select from "react-select";
import { searchProducts } from '../../../actions/ProductAction';
import { fetchPaymentMethods,orderDetail } from '../../../actions/orderActions';
// import { updateData } from '../../actions/ProductAction';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import { FoodorderDetail } from "../../../actions/RestaurantActions";
import {searchCustomer,addData,updateDatas} from '../../../actions/CustomerActions'
import { sumTotalAmount, sumTotalQuantity } from '../../../constants/CustomMethod'
import { PaymentStatus, OrderStatus } from '../../../constants/Datas'
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import {GET_ORDER_URL,BASE_URL} from '../../../constants/api'
import {
  OrderName,
  OrderSKU,
  purchasePrice,
  regularPrice,
  dateTime,
  SingleIdSelect
} from "../../../constants/Validate";

// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
  <div>
    <label className="text-sm">{label}</label>
    <DateTimePicker
      onChange={onChange}
      format="DD-MM-YYYY hh:mm A"
      time={showTime}
      value={!value ? null : new Date(value)}
      placeholder={label}
    />
  </div>;



const required = value => (value || typeof value === 'number' ? undefined : 'Required');

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  required = false,
}) => {
  const className = `form-control ${error && touched ? "is-invalid" : ""}`;
  return (
    <div className="form-group">
      <label className="text-sm">{label}</label>
      {required ? <span className="requiredField">*</span> : ""}
      <div>
        <input
          {...input}
          placeholder={label}
          type={type}
          className={className}
        />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};
// Text area type
const renderTextArea = ({
  input,
  label,
  meta: { touched, error, warning },
}) => {
  const className = `form-control ${error && touched ? "is-invalid" : ""}`;
  return (
    <div>
      <label className="text-sm">{label}</label>
      <div>
        <textarea
          {...input}
          placeholder={label}
          rows="3"
          cols="40"
          className={className}
        />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

// File Input type

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class UpdateFoodOrderForm extends Component {



  state = {
    product: '',
    payment_method:'',
    payament_type:'',
    loadOrder: false,
    value: '',
    customer_name: '',
    totalAmount: 0,
    totalQuantity: 0,
    products: [],
    oderList: [],
    customers: [],
    order_date:'',
    payment_status:''
  };








  componentDidMount() {
    let id = this.props.match.params.id;
    this.props.FoodorderDetail(id);
    this.props.fetchPaymentMethods(1)
  }


  deteteRow = (product) => {
    var products = this.state.oderList.filter((r) => r !== product)
    this.setState({
      oderList: products,
      totalQuantity: sumTotalQuantity(products),
      totalAmount: sumTotalAmount(products)
    });
  }

  manageQuantity = (index, type = "plus") => {
    let orders = [...this.state.oderList]
    if (type == "plus") {
      orders[index].quantity = orders[index].quantity + 1;
      orders[index].subtotal = (orders[index].regular_price - orders[index].discount_price) * orders[index].quantity;

    } else {
      if (orders[index].quantity > 1) {
        orders[index].quantity = orders[index].quantity - 1;
        orders[index].subtotal = (orders[index].regular_price - orders[index].discount_price) * orders[index].quantity;

      }
    }
    this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders) })
  }

  







  static getDerivedStateFromProps(nextProps, prevState) {
      // alert(JSON.stringify(nextProps))
    if (nextProps.is_getting == true){
   
    if (nextProps.is_getting !==prevState.loadOrder){
    //  alert(JSON.stringify( "Test",nextProps.order.payment_method))
    //console.log(nextProps.order)
      var product_lists=[] 
        if (nextProps.order.food_order_list !==undefined){
          var totalQuantity=0
          var totalPrice=0
          for (var i=0; i<nextProps.order.food_order_list.length;i++ ){
            var data=nextProps.order.food_order_list[i].order_restaurant_food
          
            data['image']=BASE_URL + nextProps.order.food_order_list[i].order_restaurant_food.image
            data['quantity']=nextProps.order.food_order_list[i].quantity
            data['subtotal']=nextProps.order.food_order_list[i].price
          
            totalQuantity +=nextProps.order.food_order_list[i].quantity
            totalPrice +=nextProps.order.food_order_list[i].price
            product_lists.push(data)
          }
        //  alert(JSON.stringify(nextProps.order.payment_method.name))
        } 
        return {
          payment_method:nextProps.order.payment_method !==null ? nextProps.order.payment_method.name : '',
          customer_name:nextProps.order.customer ? nextProps.order.customer.phone_no : '',
          oderList:nextProps.order.food_order_list ? product_lists: [],
          totalQuantity:totalQuantity,
          totalAmount:totalPrice,
          order_date:nextProps.order.order_date,
          payment_status:nextProps.order.payment_status,
          loadOrder:true
        
        }
      }

    }
    return null

  }

  submit(event) {
    const {

     value,
     customer_name,
     totalAmount,
     totalQuantity,
     oderList,

    } = this.state;
    // alert(JSON.stringify(oderList))
    var data ={
      'oder_list':JSON.stringify(oderList),
      'total_order_quantity':totalQuantity,
      'total_order_price':totalAmount,
      'customer':customer_name,
      'order_status':event.order_status,
      'payment_method':event.payment_method,
      'payment_status':event.payment_status,
      'order_date': dateTime(event.order_date),


    }
    // alert(JSON.stringify(data))
    let id = this.props.match.params.id;
    this.props.updateDatas(data, GET_ORDER_URL+id+'/',"Order ") 
    
  }

  // Filter logic
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    this.props.searchProducts(value);
    return inputLength === 0 ? [] : this.props.products.filter(product =>
      product.product_name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  // Trigger suggestions
  getSuggestionValue = product => '';

  // Render Each Option
  renderSuggestion = product => (
    <div>
      {product.product_name}
    </div>
  );

  // OnChange event handler
  onChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };

  onChangeCustomer = (event, { newValue }) => {
    // alert(JSON.stringify(newValue))
    this.setState({
      customer_name: newValue
    });
    // alert(this.state.customer_name)
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

    let { oderList } = this.state;
    suggestion.quantity = 1
    suggestion.subtotal = Number(suggestion.regular_price) - Number(suggestion.discount_price)
    var index = oderList.findIndex(x => x.id == suggestion.id);

    // console.log(suggestion);
    if (index === -1) {
      oderList.push(suggestion)
    } else {
      oderList[index].quantity = oderList[index].quantity + 1;
      oderList[index].subtotal += suggestion.regular_price - suggestion.discount_price;
    }
    this.setState({ totalAmount: sumTotalAmount(oderList), totalQuantity: sumTotalQuantity(oderList) })
  };


  // Suggestion rerender when user types
  onSuggestionsFetchRequested = ({ value }) => {
    //alert(value)
    this.setState({
      products: this.getSuggestions(value)
    });
  };



  getCustomerSuggestions = customer_name => {
    if (customer_name !== undefined) {
      const inputValue = customer_name.trim().toLowerCase();
      const inputLength = inputValue.length;
      this.props.searchCustomer(customer_name);
      return inputLength === 0 ? [] : this.props.customers !==undefined &&this.props.customers.filter(customer =>
        customer!==undefined&& customer.phone_no.toLowerCase().slice(0, inputLength) === inputValue
      );
    }
  };

  onSuggestionsCustomerClearRequested = () => {
    this.setState({
      customers: []
    });
  };
  getSuggestionCustomerValue = customer => customer!==undefined ? customer.phone_no:'';

  onSuggestionsFetchRequestedCustomer = ({ value }) => {
   
    this.setState({
      customers: this.getCustomerSuggestions(value)
    });
  };

  onSuggestionSelectedCustomer = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

    // let { oderList } = this.state;
    // suggestion.quantity=1
    // suggestion.subtotal=Number(suggestion.regular_price)-Number(suggestion.discount_price)
    // var index = oderList.findIndex(x => x.id==suggestion.id); 

    // // console.log(suggestion);
    // if (index === -1) {
    //   oderList.push(suggestion) 
    // }else{
    //   oderList[index].quantity = oderList[index].quantity+1;
    //   oderList[index].subtotal +=suggestion.regular_price-suggestion.discount_price;
    // }
    // this.setState({totalAmount:sumTotalAmount(oderList),totalQuantity:sumTotalQuantity(oderList)})   
  };
  renderSuggestionCustomer = customer => (
    // alert(JSON.stringify(customer))
    <div>
      {customer !=undefined ?customer.phone_no:''}
    </div>
  );
  // Triggered on clear
  onSuggestionsClearRequested = () => {
    this.setState({
      products: []
    });
  };

  render() {
      console.log(this.state)
    const { handleSubmit, pristine, reset, payments, submitting,order }
      = this.props;
    const {

      value,
      totalAmount,
      totalQuantity,
      oderList,
      customer_name
    } = this.state;
    // alert(customer_name)
    const inputCustomerProps = {
      placeholder: 'Type Your Customer Mobile No or Email or Name',
      value: customer_name,
      type: "search",
      name: 'textBoxValue',
      onChange: this.onChangeCustomer
    };
    // Option props
    const inputProps = {
      placeholder: 'Type Product Name or Product Sku Here',
      value,
      type: "search",
      onChange: this.onChange
    };
    if (this.props.is_added) {
      setTimeout(function () {
        window.location.href = '/Order-list'
      },
        3000);
    }
    return (
      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Update Order</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Update Order Form</h3>
            <div className="float-right">
              <Link
                to="/order-list"
                className="btn btn-primary float-right text-white"
              >
                <b>
                  <i className="fa fa-backward"></i>
                  Back To List
                </b>
              </Link>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(this.submit.bind(this))}
          // onChange={this.handleChangeForm}
          >
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <label>Select Customer</label>
                  <span className="requiredField">*</span>
                  <Autosuggest
                    suggestions={this.props.customers ? this.props.customers : []}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCustomer}
                    onSuggestionsClearRequested={this.onSuggestionsCustomerClearRequested}
                    getSuggestionValue={this.getSuggestionCustomerValue}
                    renderSuggestion={this.renderSuggestionCustomer}
                    inputProps={inputCustomerProps}

                 //  onSuggestionSelected={this.onSuggestionCustomerSelected}
                  />

                </div>
                <div className="col-md-6">
                  <label>Payment Method</label>
                  <span className="requiredField">*</span>
                  <Field
                    name="payment_method"
                    component="select"
                    className="form-control"
                    label="Payment Method"
                    validate={[required]}
                    
                  >
                    <option value="">--Select Payment Method--</option>
                    {payments.map((payment, index) => (
                      <option selected ={payment.id ==payment.value ? true : false } id='payment_method'  key={index}  value={payment.id}>{payment.name}</option>

                    ))}
                    {/* {order.payment_method !==undefined? order.payment_method.name:''} */}
                  </Field>
                </div>
                <div className="col-md-6">
                <label>Order Status</label>
               
                <Field name="order_status" component="select" className="form-control" validate={required}>
                <option value="">--Select Order Status--</option>
                                        {OrderStatus.map((order, index) => (
                      <option  selected ={order.id !==undefined ? true : false } key={index} value={order.id}>{order.status}</option>

                    ))}
                                    </Field>
                 
                </div>

                <div className="col-md-6">
                  <label>Payment Status</label>
                  <Field
                    name="payment_status"
                    component="select"
                    className="form-control"
                    label="Payment Status"
                  // onChange={this.handleSelectedValues(idx)}
                  >
                    <option value="">--Select Payment Status--</option>
                    {PaymentStatus.map((payment_st, index) => (
                      <option selected={payment_st.id !==undefined ? true:false} key={index} value={payment_st.id}>{payment_st.status}</option>

                    ))}
                  </Field>
                </div>
                <div className="col-md-6">
                  <Field
                    name="order_date"
                    type="date"
                    showTime={true}
                    component={renderDateTimePicker}
                    label="Order Date"
                    required={true}
                    validate={[required]}

                  // warn={alphaNumeric}
                  />
                </div><br />
                <div className="col-md-12">
                  <label>Type Your Product</label>
                  <span className="requiredField">*</span>
                  <Autosuggest
                    suggestions={this.props.products ? this.props.products : []}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                    onSuggestionSelected={this.onSuggestionSelected}
                  />
                </div><br />
                {oderList.length > 0 ? (
                   <>
                   <div className="col-md-12">
                     <strong>Order List</strong>
                   </div>
                   <div className="col-md-12">
                     <table className="table orderlist">
                       <tr>
                         <th>Image</th>
                         <th>Product Name</th>
                         <th>Product Sku</th>
                         <th>Vendor/Seller</th>
                         <th className="text-center">Quantity</th>
                         <th>Price</th>
                         <th>Discount</th>
                         <th>Subtotal</th>
                         <th>Action</th>
                       </tr>
                       <tbody>
                         {this.state.oderList.map((product, index) => (

                           <tr id={product.id} key={index}>
                             <td>
                               <img src={product.feature_image} height="100px" width="100px" alt="No Image" />
                             </td>
                             <td>
                               {product.product_name}
                             </td>
                             <td>
                               {product.product_sku}
                             </td>
                             <td>
                               {product.vendor ? product.vendor.shop_name : ''}
                             </td>
                             <td>

                               <div class="input-group plus-minus-input">
                                 <div class="input-group-button">
                                   <button type="button" class="btn btn-success" onClick={() => this.manageQuantity(index, "minus")}>
                                     <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                   </button>
                                 </div>
                                 <input class="input-group-field form-control" type="number" name="quantity" value={product.quantity} />
                                 <div class="input-group-button">
                                   <button type="button" class="btn btn-primary" onClick={() => this.manageQuantity(index, "plus")}>
                                     <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                   </button>
                                 </div>
                               </div>
                             </td>
                             <td>
                               {product.regular_price}
                             </td>
                             <td>
                               {product.discount_price ? product.discount_price : 0}
                             </td>
                             <td colSpan="1">
                               {product.subtotal ? product.subtotal : 0}
                             </td>
                             <td>
                               <button
                                 className="btn btn-danger" id={product.id}
                                 onClick={() => this.deteteRow(product)}
                               >
                                 <i
                                   className="fa fa-trash"
                                   aria-hidden="true"
                                 />
                               </button>

                             </td>
                           </tr>
                         ))}
                         <tr>
                           <th colSpan="3" className="">Total</th>
                           <th colSpan="3" className="text-center">{totalQuantity}</th>
                           <th></th>
                           
                           <th colSpan="3" className="center">{totalAmount}</th>

                         </tr>
                       </tbody>
                     </table>
                   </div>
                 </>
                ) : null
                }
              </div>

            </div>

            <div className="card-footer">
              {oderList.length > 0 ? (
                <div>
                  <button
                    type="submit"
                    className="btn btn-success float-right"
                    disabled={submitting}
                  >
                    <i className="fa fa-save "></i>Update Order
                </button>


                </div>
              ) : null

              }
            </div>
          </form>
        </div>
      </div>
    );
  }
}

UpdateFoodOrderForm.propTypes = {
  fetchProducts: PropTypes.func.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  searchCustomer: PropTypes.func.isRequired,
  updateDatas: PropTypes.func.isRequired,
  FoodorderDetail: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
  products: state.products.products,
  products: state.products.products,
  payments: state.payments.payments,
  customers: state.customers.customers,
  is_added: state.customers.is_added,
  order: state.foodorders.foodorder,

  is_getting: state.foodorders.is_getting,
  initialValues: state.foodorders.foodorders,
});
UpdateFoodOrderForm =reduxForm({
  form: "UpdateFoodOrderForm",
  enableReinitialize: true
})(UpdateFoodOrderForm);


export default  connect(
  mapStateToProps,
  {
    searchProducts, fetchPaymentMethods,searchCustomer,updateDatas,FoodorderDetail
  }
)(UpdateFoodOrderForm)


import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import CircularProgress from "@material-ui/core/CircularProgress";
import {  deleteMedicineDelivery,fetchRiderCarTrip } from '../../../actions/RestaurantActions';
import Swal from 'sweetalert2'
import moment from "moment";
import 'moment-timezone';

import {  BulkAction } from '../../../actions/ProductAction';
import {findArrayElementByTitle} from '../../../constants/CustomMethod'
import {  RideStatus } from '../../../constants/Datas'
import { RIDER_CAR_TRIP_BULK_URL } from '../../../constants/api'

import ReactPaginate from "react-paginate"
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import {dateYMD} from "../../../constants/Validate";
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};



// Modals.setAppElement('#modalRender');

class RiderCarTripList extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            headers: [],
            fileName: 'ride-category-fare-list.xlsx',
            is_fetching: false,
            modalIsOpen: false,
            get_rider:false,
            allchecked: false, 
            bulk: false,
            show:false,
            riders:[],
            rider_name:'',
            rider_mobile_no:'',
            order_no:'',
            created_at_to:'',
            created_at_from:'',
            user__phone_no:'',
            ride_status:''
        };

        // this.opensweetalert = this.opensweetalert.bind(this)

    }




    componentDidMount() {
        // console.log(this.state.data)

       
        this.props.fetchRiderCarTrip()
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.receiver_name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteMedicineDelivery(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.receiver_name + '.',
                        'success'
                    )
                }
            }
        })
    }

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.get_rider_car_trip) {
            if (nextProps.get_rider_car_trip !== prevState.get_rider) {
                var ridercartrips = []
               
                for (var i = 0; i < nextProps.ridercartrips.length; i++) {
                    Object.assign(nextProps.ridercartrips[i], { 'checked': false });
                    ridercartrips.push(nextProps.ridercartrips[i])
                }
                return {
                    riders: ridercartrips,
                    get_rider: true
                }
            }
        }
        return null
    }


    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = [],insert=true
            for (var i = 0; i < this.state.riders.length; i++) {
                if (this.state.riders[i].checked) {
                    results.push(this.state.riders[i].id)
                    if(this.state.riders[i].ride_status===5){
                        insert=false
                        break;
                    }
                }
            }
            if(insert){
                this.props.BulkAction(
                    { 'id': results, 'action': value }, RIDER_CAR_TRIP_BULK_URL
                )
            }else{
                alert("You are not able to change completed ride")
            }
           
        }
    }

    onSingleChange = index => (e) => {
        console.log(index)
        let riders = [...this.state.riders]
        riders[index]["checked"] = e.target.checked
        let recent_length = riders.filter(order => order.checked).length;

        if (riders.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        this.setState({ riders })

    };
    toggleAllChange=(e)=> {
        const { value, checked } = e.target;
        let ridercartrips = [...this.props.ridercartrips]
        ridercartrips.forEach(order => order.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, ridercartrips, bulk: true })
        } else {
            this.setState({ allchecked: checked, ridercartrips, bulk: false })
        }
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            is_fetching: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            is_fetching: false
        })
    };
    onChangeDateTo = (date) => {
       
        this.setState({created_at_to:dateYMD(date)})

}
    onChangeDateFrom = (date) => {
        
        this.setState({created_at_from:dateYMD(date)})

}
    onSubmit = e => {
        
        e.preventDefault();
        
        this.props.fetchRiderCarTrip('', this.state);
        this.setState({ hideButton: true })

    };
    onChange = e => this.setState({ [e.target.name]: e.target.value });
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchRiderCarTrip(pageNo)
    }

    render() {
        const {allchecked, orders, bulk,show}=this.state

        const page = Math.ceil(this.props.count / 10)
        const {permissions} = this.props
        // console.log(this.props.rideCategory)



        // const isAdmin = this.props.auth.user.is_admin;

        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/rider-car-trip-list'
            },
                1000);
        }
        return (

            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Rider Car Trip List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Rider Car Trip List</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    {/*<!-- /.container-fluid -->*/}
                </section>

                {/* <!-- /.row --> */}
                <div className="row">
                    {/*    <Modals*/}
                    {/*    className="modal"*/}
                    {/*    show={this.state.isShowing}*/}
                    {/*    close={this.closeModalHandler}*/}
                    {/*    Some data*/}
                    {/*/>*/}
                    <ToastContainer />
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >

                        <h2 ref={subtitle => this.subtitle = subtitle}>Hello</h2>
                        <button onClick={this.closeModal}>close</button>
                        <div>I am a modal</div>
                        <form>
                            <input />
                            <button>tab navigation</button>
                            <button>stays</button>
                            <button>inside</button>
                            <button>the modal</button>
                        </form>
                    </Modal>

                    <div className="col-12">
                        
                        <div className="card">
                        <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Bulk Action--</option>
                                            {RideStatus.map((order, index) => (
                                                <option key={index} value={order.id}>{order.status}</option>

                                             ))}
                                            {/* <option value="delete">Delete</option> */}


                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>
                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                    <input className="form-control mr-sm-4" name="order_no" type="text"
                                            onChange={this.onChange} placeholder="Order No"
                                            aria-label="Search" />
                                        {/* <input className="form-control mr-sm-4" name="user__phone_no" type="text"
                                            onChange={this.onChange} placeholder="User Phone Number"
                                            aria-label="Search" /> */}

                                        <DateTimePicker
                                            // onChange={this.onChangeDate}
                                            onChange={date => this.onChangeDateFrom(date)}
                                            name="created_at_from"
                                            format="DD-MM-YYYY"
                                            time={false}
                                        // value={this.state.order_search_date}
                                            placeholder="Order date from"
                                        /> 

                                        <DateTimePicker
                                            // onChange={this.onChangeDate}
                                            onChange={date => this.onChangeDateTo(date)}
                                            name="created_at_to"
                                            format="DD-MM-YYYY"
                                            time={false}
                                        // value={this.state.order_search_date}
                                            placeholder="Order date to"
                                        />
                                        <input className="form-control mr-sm-4" name="rider_mobile_no" type="text"
                                            onChange={this.onChange} placeholder="Rider Mobile No"
                                            aria-label="Search" />
                                        {/* <input className="form-control mr-sm-4" name="rider_name" type="text"
                                            onChange={this.onChange} placeholder="Rider Name"
                                            aria-label="Search" /> */}
                                        <select 
                                            name="ride_status"
                                            component="select"
                                            className="form-control mr-sm-4"

                                            onChange={this.onChange}
                                            >
                                            <option value="select">--Select Status--</option>
                                            {RideStatus.map((order, index) => (
                                                <option key={index} value={order.id}>{order.status}</option>

                                             ))}
                                            {/* {groups.map(group=>(
                                                <option key={group.id} value={group.id} onChange={this.onChange}>{group.name}</option>
                                            ))} */}
                                        </select> 
                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    
                                    </form>
                                </div>


                            </div>
                            <div className="card-header">
                                <div className="float-right">
                                {/* {CheckPermisionAction(permissions,'Medicine Delivery','add_medicine_delivery')?

                                    <Link to="/add-medicine_delivery" className="btn btn-info float-right text-white"><b>
                                        + Add Medicine Delivery</b>
                                    </Link>
                                 :null}  */}
                                </div>
                            </div>
                            
                            {/* {CheckPermisionAction(permissions,'Rider Car Trip','view_car_rider_trip_list')? */}

                            <div className="card-body table-responsive p-0">
                                <table id="table-to-xls" className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                        <th>
                                            <input type="checkbox"
                                                checked={allchecked}
                                                onChange={this.toggleAllChange}
                                            />   
                                        </th>
                                            <th cope="col">SL</th>
                                            <th>Order Date</th>
                                            <th>Order No</th>
                                            <th>Rider Name</th>
                                            <th>Rider Mobile No</th>
                                            <th>Pickup Location</th>
                                            <th>Destination</th>
                                            <th>Car</th>
                                            <th>Reservation Date</th>
                                            <th>Return Date</th>
                                            <th>Ride Status</th>
                                            {/* <th>Action</th> */}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ?
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>

                                            : this.props.ridercartrips.map((ridercartrip, index) => (
                                                <tr key={ridercartrip.id}>
                                                    <td>
                                                        <input type="checkbox" onChange={this.onSingleChange(index)} checked={ridercartrip.checked ? true : false} value={ridercartrip.id} />
                                                    </td>
                                                    <td>{index + 1}</td>
                                                    <td>{ridercartrip.created_at !==undefined? moment(ridercartrip.created_at).tz('Asia/Dhaka').format('llll'):''}</td>
                                                    <td>#{ridercartrip.order_no}</td>

                                                    <td>{ridercartrip.rider_name}</td>
                                                    <td>{ridercartrip.rider_mobile_no}</td>
                                                    <td>{ridercartrip.pickup_location}</td>
                                                    <td>{ridercartrip.destination}</td>
                                                    <td>{ridercartrip.car_types?ridercartrip.car_types.name:''}</td>
                                                    <td>{ridercartrip.reservation_date !==undefined? moment(ridercartrip.reservation_date).tz('Asia/Dhaka').format('llll'):''}</td>
                                                    <td>{ridercartrip.return_date !==undefined ? moment(ridercartrip.return_date).tz('Asia/Dhaka').format('llll') :''}</td>
                                                    <td>
                                                     
                                                        
                                                            { findArrayElementByTitle(RideStatus,ridercartrip.ride_status) ?  findArrayElementByTitle(RideStatus,ridercartrip.ride_status).status : null }
                                                           
                                                    </td>
                                                   

                                                    {/* <td>{ridercartrip.image !==null?
                                                    <img src ={ridercartrip.image} style={{width:'100px',height:'100px'}}/>
                                                    :null
                                                     
                                                     }</td> */}
                                                    {/* <td >
                                                        <div className="btn-group btn-group-sm">
                                                            
                                                    {CheckPermisionAction(permissions,'Medicine Delivery','medicine_delivery_options')?
                                                    <>
                                                    <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                    <div className="dropdown-menu">
                                                        <a href={'/medicine-delivery-update/' + medicinedelivery.id} className="dropdown-item">Edit</a>
                                                        <div className="dropdown-divider"></div>

                                                        <a href="#" onClick={() => this.opensweetalert(medicinedelivery)} className="dropdown-item">Delete</a>

                                                    </div>
                                                </>

                                                            
                                                     :null} 
                                                        </div>
                                                   
                                                    </td> */}
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                             {/* :null
                             }  */}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}




RiderCarTripList.propTypes = {
    fetchRiderCarTrip: PropTypes.func.isRequired,
    BulkAction: PropTypes.array.isRequired,

};

const mapStateToProps = state => ({
    ridercartrips:state.ridercartrips.rider_car_trips,
    count: state.ridercartrips.count,
    next: state.ridercartrips.next,
    is_loading: state.ridercartrips.is_loading,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions,
    get_rider_car_trip:state.ridercartrips.get_rider_car_trip,
    bulk: state.products.bulk,
});
// const modalElement = document.getElementById('modalRender');
export default connect(mapStateToProps, {  deleteMedicineDelivery,fetchRiderCarTrip,BulkAction })(RiderCarTripList);



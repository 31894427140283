import {
    LOADING_PRIMARY_CATEGORY,
    GETTING_PRIMARY_CATEGORY,
    ADD_PRIMARY_CATEGORY,
    DETAIL_PRIMARY_CATEGORY,
    UPDATE_PRIMARY_CATEGORY,
    DELETE_PRIMARY_CATEGORY,
    NOT_FOUND_PRIMARY_CATEGORY,
} from '../actions/types';

const initialState = {
    primary_categories: [],
    primary_category: {},
    count: null,
    next: null,
    is_loading: false,
    is_added: false,
    is_updated: false,
    errors: '',
    is_list:false,
    bulk:false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_PRIMARY_CATEGORY:
            return {
                ...state,
                is_loading: true
            };
        case GETTING_PRIMARY_CATEGORY:
            return {
                ...state,
                primary_categories: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                is_list:true
            };

        case ADD_PRIMARY_CATEGORY:
            // let data=[]
            //  if(state.attributes.length>9){
            //     data = [...state.attributes.splice(-9)] 

            //  }else{
            //     data = [...state.attributes]   
            //  }   

            return {
                ...state,
                primary_categories: state.primary_categories,
                is_loading: false,
                is_added: true

            };
        case DETAIL_PRIMARY_CATEGORY:
            return {
                ...state,
                primary_category: action.payload,

            };
        case UPDATE_PRIMARY_CATEGORY:
            var filteredDataSource = state.primary_categories.filter((item) => {
                if (item.id === action.payload.id) {
                    item.name = action.payload.name;
                    item.image = action.payload.image
                }

                return item;
            });
            console.log(filteredDataSource)
            return {
                ...state,
                primary_categories: filteredDataSource,
                is_loading: false,
                is_updated: true
            };
        // return {
        //     ...state,
        //     primary_categories: action.payload,
        //     is_loading: true
        // };
        case DELETE_PRIMARY_CATEGORY:
            return {
                ...state,
                primary_categories: state.primary_categories.filter(primary_category => primary_category.id !== action.payload)
            };

        case NOT_FOUND_PRIMARY_CATEGORY:
            return {
                ...state,
                primary_categories: [],
                count: 0,
                next: 0,
                is_loading: false,

            };
        default:
            return state;
    }
}
import {
    FETCH_RETURN,
    DETAIL_RETURN,
    DELETE_RETURN,
    LOADING_RETURN,
    ADD_DATA,
    UPDATE_RETURN,
    UPDATED_DATA,
    NOT_FOUND_RETURN,
} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { RETURN_REFUND_URL, RETURN_REFUND_LIST_URL, GET_GALLERY_URL, PRODUCT_CSV_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


export const fetchReturns = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_RETURN });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["order__order_no"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = RETURN_REFUND_LIST_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_RETURN,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_RETURN });

    }
};
export const searchReturns = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_RETURN });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(RETURN_REFUND_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_RETURN,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};
// Return Refund Detail
export const returnDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = RETURN_REFUND_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_RETURN,
                payload: response.data.results ? response.data.results : response.data,
            })
        })
};

// // Delete Return Refund
export const deleteReturn = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(RETURN_REFUND_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_RETURN,
                payload: id,
            });
            toast.success('Return/Refund has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

// export const addData = (data, url, message = "Data") => (dispatch, getState) => {
//     let headers = { 'content-type': 'application/json' };

//     let { token } = getState().auth;
//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//     }
//     axios
//         .post(url, data, { headers })
//         .then(response => {
//             dispatch({
//                 type: ADD_DATA,
//                 payload: response.data.results ? response.data.results : response.data,
//             });
//             toast.success(message + " has been added successfully");
//         })
//         .catch(error => {
//             if (error.response) {
//                 console.log(error.response.data.error)
//             } else if (error.request) {
//                 // The request was made but no response was received
//                 // `error.request` is an instance of XMLHttpRequest in the 
//                 // browser and an instance of
//                 // http.ClientRequest in node.js
//                 toast.warn("Something Wrong ! Please try again");

//             } else {
//                 toast.warn("Something Wrong ! Please try again");
//                 // Something happened in setting up the request that triggered an Error
//                 // console.log('Error', error.message);
//             }

//         })
// };

// export const updateData = (data, url, message = "") => (dispatch, getState) => {

//     let headers = { 'content-type': 'application/json' };
//     let { token } = getState().auth;

//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//     }

//     axios
//         .put(url, data, { headers })
//         .then(response => {
//             dispatch({
//                 type: UPDATED_DATA,
//                 payload: response.data.results ? response.data.results : response.data,
//             });
//             toast.success(message + " has been updated successfully");
//         }).catch(error => {
//             console.log(error)
//             toast.warn(message + " has not been updated successfully");
//         })
// };





import {
    LOADING_MEDIA_LIBRARY,
    FETCH_MEDIA_LIBRARY,
    DETAIL_MEDIA_LIBRARY,
    DELETE_MEDIA_LIBRARY,
    NOT_FOUND_MEDIA_LIBRARY,
    

} from '../actions/types';







//*****************************START MEDIA LIBRARY REDUCER******************************** */

export const MediaLibraryReducer = (state =
    {
        media_libraries: [],
        media_library: {},
        count: null,
        next: null,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
    }
    , action) => {
    switch (action.type) {
        case LOADING_MEDIA_LIBRARY:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_MEDIA_LIBRARY:
            return {
                ...state,
                media_libraries: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case DETAIL_MEDIA_LIBRARY:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                media_libraries: action.payload,

            };
        case DELETE_MEDIA_LIBRARY:
            return {
                ...state,
                media_libraries: state.media_libraries.filter(media_library => media_library.id !== action.payload)
            };

        case NOT_FOUND_MEDIA_LIBRARY:
            return {
                ...state,
                media_libraries: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

//*****************************END MEDIA LIBRARY REDUCER******************************** */

import {
    GET_USERS,
    NOT_FOUND_USERS,
    FETCH_USER_GROUP,
    FETCH_USER_GROUP_SUCCESSFUL,
    FETCH_USER_GROUP_FAILED,
} from './types'

import axios from 'axios';
import { LOAD_USER_URL, LOAD_USER_GROUP_URL, LOAD_VENDOR_USER_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { toast } from 'react-toastify'


export const getUsers = (pageNumber, parameters = {}) => (dispatch, getState) => {


    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["phone_no", "email"]
    // console.log()

    var search_url = SearchURLGenerate(parameters, search_key);
    // console.log(search_url)

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {

        var url = LOAD_VENDOR_USER_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {

                var results = []
                for (var i = 0; i < response.data.results.length; i++) {
                    results.push({ label: response.data.results[i].first_name, value: response.data.results[i].id })
                }
                dispatch({
                    type: GET_USERS,
                    payload: response.data.results ? results : [],
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {

        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_USERS });

    }
};



export const getUserGroup = () => (dispatch, getState) => {
    dispatch({
        type: FETCH_USER_GROUP
    })
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = LOAD_USER_GROUP_URL

    axios
        .get(url, { headers })
        .then(response => {
            dispatch({
                type: FETCH_USER_GROUP_SUCCESSFUL,
                payload: response.data.results,
            })
        })
        .catch(error => {
            dispatch({
                type: FETCH_USER_GROUP_FAILED,
                error: error.message
            })
        })
} 


export const getAllUsers = (url,pageNumber, parameters = {}) => (dispatch, getState) => {


    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["phone_no", "email"]
    // console.log()

    var search_url = SearchURLGenerate(parameters, search_key);
    // console.log(search_url)

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {

        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {

                dispatch({
                    type: GET_USERS,
                    payload: response.data ? response.data : [],
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {

        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_USERS });

    }
};

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match } from '../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchCampaigns, addData, deleteCampaign } from "../../actions/CampaignActions"
import { fetchGroups } from "../../actions/GroupAction"

import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import { toast } from "react-toastify";
import { TAX_URL, GET_PRIMARY_CATEGORY_URL } from "../../constants/api";


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

// const submitToServer = (data) => {

//     console.log('data', data);


//     var form_data = { "name": data.name };

//     console.log('data', form_data);


//     let url = CUSTOMER_URL;

//     let token = localStorage.getItem('token');
//     // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
//     console.log(token);
//     axios.post(url, form_data,
//         {
//             headers: {
//                 'content-type': 'application/json',
//                 'Authorization': `Token ${token}`
//             }

//         })
//         .then(res => {
//             console.log(res.data);
//             // dispatch(reset('PartnerAddForm'))
//             toast.success('Successfully added a new Primary Category.')
//         })
//         .catch(err => console.log(err))
// };

// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
    <div>
        <label className="text-sm">{label}</label>
        <DateTimePicker
            onChange={onChange}
            format="DD-MM-YYYY"
            time={showTime}
            value={!value ? null : new Date(value)}
            placeholder={label}
        />
    </div>;


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddTax extends Component {

    componentDidMount() {
        console.log(this.props.fetchCampaigns())
        this.props.fetchCampaigns(0)
        this.props.fetchGroups()
    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }

    submit(event) {
        // const { reset } = this.props;
        // return sleep(1000)
        //     .then(() => {
        //         submitToServer(values);
        //         reset()
        //     })
        var form_data = {
            tax_class_name: event.tax_class_name,
            description: event.description,
            rate: event.rate
        }
        // var form_data = new FormData();

        // form_data.append("tax_class_name", event.tax_class_name ? event.tax_class_name : '');
        // form_data.append("description", event.tax_class_name ? event.tax_class_name : '');
        // form_data.append("rate", event.tax_class_name ? event.tax_class_name : '');
        // form_data.append("till_date", event.till_date ? event.till_date : '');
        // if (event.user_photo != '') {
        //     form_data.append("user_photo", event.user_photo ? event.user_photo : '');
        // }

        this.props.addData(form_data, TAX_URL, "Tax")




    }

    render() {


        const { handleSubmit, submitting, } = this.props;
        if (this.props.is_added) {
            setTimeout(() => {
                window.location.href = "/tax-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Tax </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Tax add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Tax Information</h3>
                        <div className="float-right">
                            <Link to="/tax-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="tax_class_name"
                                        type="text"
                                        component={renderField}
                                        label="Tax Class Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="description"
                                        type="text"
                                        component={renderField}
                                        label=" Description"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="rate"
                                        type="text"
                                        component={renderField}
                                        label="Rate"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddTax.propTypes = {
    // getRides:PropTypes.func.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    fetchCampaigns: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

    groups: state.groups.groups,
    taxs: state.taxs.taxs,
    is_added: state.taxs.is_added

});


AddTax = reduxForm({
    form: 'AddTax',
}
)(AddTax);


export default connect(mapStateToProps, { fetchCampaigns, addData, fetchGroups })(AddTax);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";
import { AGENT_BULK_URL} from '../../../constants/api'
// import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

import { BulkAction } from '../../../actions/ProductAction';

import { getAgentsCustomer,deleteAgentCustomer } from '../../../actions/AgentActions';

import { Field, reduxForm } from "redux-form";
import ReactPaginate from 'react-paginate';





class AgentCustomerList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
        // this.getCSVData=this.getCSVData.bind(this)
        this.handleModalShow = this.handleModalShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        
        this.handleDiscountClose = this.handleDiscountClose.bind(this)
        this.handleDiscountModalShow = this.handleDiscountModalShow.bind(this)
        this.handleDateChange=this.handleDateChange.bind(this)


    }


    state = {
        // search: " ",
        // phone_no: "",
        show: false,
        discountshow:false,
        // restaurant_name: "",
        customer_phone_no:'',
        agent_customers: [],
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,

        bulk: false,
        allchecked: false,
        get_agent_customer: false,
        checkedvalues: [],
        allchecked: false,
        bulk:false,
        load:false


    };

    handleClose() {
        this.setState({ show: false });
    }
    handleModalShow() {

        this.setState({ show: true });
    }

    handleDiscountClose() {
        this.setState({ discountshow: false });
        window.location.reload()
    }
    handleDiscountModalShow() {

        this.setState({ discountshow: true });
    }
    handleDateChange(value, e) {
        this.setState({ discount_from_date: value });
    }
    handleToDateChange(value, e) {
        this.setState({ discount_to_date: value });
    }
    componentDidMount() {
        // alert(JSON.stringify(this.props.agent_customers))
        this.props.getAgentsCustomer(1);
    }
   

    

    
   



    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.get_agent_customer) {
            if (nextProps.get_agent_customer !== prevState.get_agent_customer) {
                var agent_customers = []
                for (var i = 0; i < nextProps.agent_customers.length; i++) {
                    Object.assign(nextProps.agent_customers[i], { 'checked': false });
                    agent_customers.push(nextProps.agent_customers[i])
                }
                console.log(agent_customers)
                return {
                    agent_customers: agent_customers,
                    get_agent_customer: true
                }
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.agent_customers != this.props.agent_customers){
            this.setState({agent_customers:this.props.agent_customers})
        }

    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete  ' + data.customer_phone_no + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteAgentCustomer(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.customer_phone_no + '.',
                        'success'
                    )
                }
            }
        })
    }

    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = []

            for (var i = 0; i < this.state.agents.length; i++) {
                if (this.state.agents[i].checked) {
                    results.push(this.state.agents[i].id)
                }
            }

            if (value === "1") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, AGENT_BULK_URL
                )
            } else if (value === "0") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, AGENT_BULK_URL
                )
            
            }else {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, AGENT_BULK_URL
                )
            }
        }
    }


    toggleAllChange=(e)=> {
        const { value, checked } = e.target;
        let agents = [...this.props.agents]
        agents.forEach(product => product.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, agents, bulk: true })
        } else {
            this.setState({ allchecked: checked, agents, bulk: false })
        }
    }

    onSingleChange = index => (e) => {
        let agents = [...this.state.agents]
        // alert(primcategories)
        agents[index]["checked"] = e.target.checked
        let recent_length = agents.filter(agent => agent.checked).length;

        if (agents.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        // this.setState({ primcategories })

    };

  

    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.getAgentsCustomer(pageNo)
    }
    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };
    handleUploadFile = (event) => {
        this.setState({
            [event.target.name]: event.target.files[0],

        })



    }
    handleInputText = (event) => {
        this.setState({
            [event.target.name]: event.target.value,

        })
    }
    closeModalHandler = () => {
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { product_sku,vendor__shop_name, product_name, status } = this.state;

        this.props.getAgentsCustomer('', this.state);
        this.setState({get_product:false, hideButton: true })

    };

    render() {
        // alert(JSON.stringify(this.state.products))
        const {permissions} = this.props
        const { show, bulk, allchecked, checkedvalues, agent_customers,discountshow } = this.state;
        const page = Math.ceil(this.props.count / 10)



        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/agent-customer-list'
            },
                1000);
        }

        if (this.props.is_uploaded) {
            window.location.href = '/agent-customer-list'
        }


        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Agent Customer List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Agent Customer list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">



                    <div className="col-12">
                        <div className="card">

                            <div className="card-header">
                                <h3 className="card-title">Agent Customer List</h3>

                                <div className="float-right">
                                

                                    
                                    {/* {CheckPermisionAction(permissions,'Agent','add_agent')? */}
                                        <>
                                {/* <a class="btn btn-primary" href={BASE_URL+'/api/v1/export-agent-wise-report/'} role="button"><i className='fa fa-download'></i>Download</a> */}

                                    <Link to="/add-agent-customer" className="btn btn-success text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                       Agent Customer</b></Link>&nbsp;
                                    </>
                                     {/* :null
                                    }  */}
                                    


                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-3" name="customer_phone_no" type="text"
                                            onChange={this.onChange} placeholder="Customer Phone No"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-3" name="customer_name" type="text"
                                            onChange={this.onChange} placeholder="Customer Name"
                                            aria-label="Search" />
                                        {/* <input className="form-control mr-sm-3" name="user__email" type="text"
                                            onChange={this.onChange} placeholder="Email"
                                            aria-label="Search" /> */}
                                        
                                        
                                        

                                      
                                        {/* {CheckPermisionAction(permissions,'Agent','search')? */}

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                         {/* :null
                                        }  */}
                                    </form>
                                </div>


                            </div>
                            <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Action--</option>
                                            <option value="1" >Activated</option>
                                            <option value="0">Deactivated</option>
                                            {/* <option value="2">Delete</option> */}
                                            

                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>
                            
                            {/* {CheckPermisionAction(permissions,'Agent','view_agent_list')? */}
                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                        {/* <th>
                                                <input type="checkbox"
                                                    checked={allchecked}
                                                    onChange={this.toggleAllChange}
                                                />
                                            </th> */}
                                            <th>SL</th>
                                            <th>Customer Name</th>
                                            <th>Customer Phone No</th>    
                                            <th>Address</th>
                                            <th>Status</th>
                                            <th>Options</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/agent-customer-list" onClick={() => { window.location.href = "/agent-customer-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ?
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>
                                            
                                            : agent_customers.length != undefined && agent_customers.map((customer, index) => (
                                                <tr key={customer.id}>

                                                {/* <td>
                                                    <input type="checkbox" onChange={this.onSingleChange(index)} checked={agent.checked ? true : false} value={agent.id} />
                                                </td> */}
                                                    
                                                    <td>{index +1}</td>
                                                   
                                                    <td>{customer.customer_name} </td>
                                                    <td>{customer.customer_phone_no}</td>
                                                    <td>{customer.address}</td>   
                                                    <td>{customer.is_active ===true? "Activated":"Deactivated"} </td>


                                                   
                                                    <td className="btn-group btn-group-sm">

                                                        <div className="btn-group">
                                                    {/* {CheckPermisionAction(permissions,'Agent','agent_options')? */}

                                                     <>
                                                            <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                            <div className="dropdown-menu">
                                                                <a href={'/agent-customer-update/' + customer.id} className="dropdown-item">Edit</a>
                                                                <div className="dropdown-divider"></div>

                                                                <a href="#" onClick={() => this.opensweetalert(customer)} className="dropdown-item">Delete</a>

                                                            </div>
                                                        </>
                                                             {/* :null}  */}
                                                        </div>




                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                             {/* :null}  */}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">

                                {/*<span className="page-link" onClick={() => this.props.fetchstaffs(this.props.next)}>&laquo;</span>*/}
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }

                            </div>
                            

                            

                        </div>
                     
                    </div>
                </div>

            </div>
        )
    }
}

AgentCustomerList.propTypes = {
    getAgentsCustomer: PropTypes.func.isRequired,
    BulkAction: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    agent_customers: state.agent_customers.agent_customers,
    count: state.agent_customers.count,
    next: state.agent_customers.next,
    is_loading: state.agent_customers.is_loading,
    bulk: state.products.bulk,
    get_agent_customer:state.agent_customers.get_agent_customer,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});

AgentCustomerList = reduxForm({
    form: "AgentCustomerList",
})(AgentCustomerList);
export default connect(mapStateToProps, { getAgentsCustomer, BulkAction,deleteAgentCustomer})(AgentCustomerList);

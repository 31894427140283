import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { required, match } from '../../../constants/Validate'
import { Field, reduxForm } from 'redux-form';

import Modal from 'react-modal';

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

import { fetchCopyright, deleteCopyright } from '../../../actions/CopyRightActions';
import { updateDatas } from '../../../actions/CustomerActions';

import { COPYRIGHT_URL } from '../../../constants/api'

// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Main form
class CopyRightList extends Component {
    state = {

        id: 1,
        copy_right: '',
        // first_name: '',
        copy_rights: [],
        // last_name: '',
        // email: '',
        get_copy_right: false,
    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_getting) {
            if (nextProps.is_getting !== prevState.get_copy_right) {
                var copy_rt = nextProps.copy_rights[1]
                return {

                    id: copy_rt.id,
                    copy_right: copy_rt.copy_right,
                    copy_rights: copy_rt,
                    get_copy_right: true
                }
            }
        }
        return null
    }
    onChangeText = (e) => {

        this.setState({ [e.target.name]: e.target.value })

    }
    componentDidMount() {
        this.props.fetchCopyright(1)
    }


    submit(event) {
        console.log(event)
        let form_data = {
            'copy_right': this.state.copy_right
        }
        // var form_data = new FormData();
        // form_data.append('copy_right', this.state.copy_right)

        alert(JSON.stringify(event))
        this.props.updateDatas(form_data, COPYRIGHT_URL + this.state.id + "/", "Copy Right")



    }

    render() {

        const { handleSubmit, submitting } = this.props;

        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Copy Right </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Copy Right</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Copy Right</h3>
                        {/* <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div> */}
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-md-6">
                                    <label>Copy Right</label>
                                    <input
                                        name="copy_right"
                                        type="text"
                                        className="form-control"
                                        value={this.state.copy_right}
                                        label="Copy Right"
                                        // readOnly={true}
                                        validate={required}
                                        onChange={this.onChangeText}

                                    // warn={alphaNumeric}
                                    />
                                </div>





                            </div>


                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save Settings
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


CopyRightList.propTypes = {
    updateDatas: PropTypes.func.isRequired,
    fetchCopyright: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    copy_rights: state.copy_rights.copy_rights,
    is_updated: state.copy_rights.is_updated,
    is_getting: state.copy_rights.is_getting,
    initialValues: state.copy_rights.copy_rights,

});


CopyRightList = reduxForm({
    form: 'CopyRightList',
    enableReinitialize: true

}
)(CopyRightList);


export default connect(mapStateToProps, { fetchCopyright, updateDatas })(CopyRightList);

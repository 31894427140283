import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required,phoneNumber, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { addData } from '../../../actions/ProductAction';
import { getDistrictsForSelect, fetchDistrict } from "../../../actions/DistrictsActions"
import {fetchCuisine,FoodNameByURL,CuisineByURL} from "../../../actions/RestaurantActions"
import { RESTAURANT_URL, CUISINE_URL,CUISINE_ALL_URL,FOOD_NAME_ALL_URL, GET_ATTRIBUTE_OPTION_LIST_URL, FOOD_NAME_URL } from "../../../constants/api";
import Select from "react-select";
import { getAttributeOptionByURL } from '../../../actions/AttributeOptionActions';
import { getAttributeOptionByChildURL } from '../../../actions/AttributeActions';
import { toast } from "react-toastify";


import { FoodNameReducer } from '../../../reducers/RestaurantReducer';


momentLocalizer(moment);


// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddRestaurant extends Component {
  deteteRow = this.deteteRow.bind(this);
//   deteteGalleryImage = this.deteteGalleryImage.bind(this);
//   handleChangeColoImage = this.handleChangeColoImage.bind(this);
  static propTypes = {
    onChangeDetails: PropTypes.func
  };

    state={
        payment_type:"1",
        verified: false,
        totalAmount: 0,
        totalQuantity: 0,
        allowProductSize: false,
        selected_list: [
        ],
        load_again_attribute: false,
        cuisine_list: [],
        child_details:[],
        food_names:[],
        colorDetails: [
        {
            index: Math.random(),
            selected_option: [],
            selected:[],
            cuisines: [],
            foodnames: [],
            // quantity: "",
            // size_price: "",
        },
        ],
        child_products:[
        {
            index: Math.random(),
            image:'',
            price:'',
            description:'',
            discount_price:'',
            discount_type:'',
            food_name:[]

        }
        ],

    }

    // this.props.getRides(0)
    componentDidMount() {
        
        
        this.props.fetchDistrict()
        // this.props.fetchCuisine()
        this.props.FoodNameByURL(FOOD_NAME_ALL_URL)
    this.props.CuisineByURL(CUISINE_ALL_URL);
       
    }

    getCombinations(arr, n)
    {
        // if(n == 1)
        // {
            var ret = [];
            for(var i = 0; i < arr.length; i++)
            {
             
              if(arr[i]!==null){
  
                  for(var j = 0; j < arr[i].length; j++)
                  {
                      ret.push([arr[i][j]]);
                  }
  
              }  
            }
            return ret;
        // }
        // else
        // {
        //     var ret = [];
        //     for(var i = 0; i < arr.length; i++)
        //     {
        //         var elem = arr.shift();
        //         for(var j = 0; j < elem.length; j++)
        //         {
        //             var childperm = this.getCombinations(arr.slice(), n-1);
        //             for(var k = 0; k < childperm.length; k++)
        //             {
                        
        //                 ret.push([elem[j]].concat(childperm[k]));
        //             }
        //         }
        //     }
        //     return ret;
        // }
    }


    handletextChild = index => (e) => {
    
        let child_products = [...this.state.food_names];
        let addedItem = child_products[index].findIndex(item=> item[e.target.name])
        
        if(addedItem !==-1){
         
           var key =  e.target.name;
           var data =child_products[index]
           
             for (let i=0;i<data.length;i++){
                    if(data[i][key]!==undefined){
                      if(data[i][key]==="image"){
                        data[i][key]=e.target.files[0]
                      }else{
                        data[i][key]=e.target.value
                      }
                      
                    }
    
            
          }
          }else{
            if(e.target.name==='image'){
              child_products[index].push({[e.target.name]:e.target.files[0]})
               
            }else{
              child_products[index].push({[e.target.name]:e.target.value})
            }
           
          }
        
         this.setState({cuisine_list:child_products})
        //  console.log(this.state.attribute_list)
        // let child_product = [...this.state.attributeoptions];
        // alert(JSON.stringify(child_product))
        // child_products[index][e.target.name]=e.target.value
          // this.setState(child_products)
          
        // if (child_products[index][e.target.name] !== undefined){
        //   child_products[index][e.target.name]=e.target.vlaue
         
        //     //child_products[index][e.target.name]=e.target.vlaue
        // }else{
        //      let obj ={
        //       [e.target.name]:e.target.vlaue
        //     }
        //     alert(JSON.stringify(obj)) 
        // }
      
      }

      addNewRow = (e) => {
        this.setState((prevState) => ({
          colorDetails: [
            ...prevState.colorDetails,
            {
              index: Math.random(),
              cuisines: [],
              selected:[],
              selected_option: [],
              foodnames: [],
            },
          ],
        }));
      };
    
      deteteRow(record) {
        this.setState({
          colorDetails: this.state.colorDetails.filter((r) => r !== record),
        });
      }


      allowSizeChange = () => {
        this.setState({ allowProductSize: !this.state.allowProductSize });
      };


      foodOptionChange = index => (selected_data) => {
    
        var colorDetails = [...this.state.colorDetails]
        colorDetails[index]["selected_option"] = selected_data
        // colorDetails[index]["selected"] = selected_data
        if (selected_data !==null){

          colorDetails[index]["selected"] = selected_data
        }else{
          colorDetails[index]["selected"] = []
        }

        this.setState({ colorDetails })
        var attr_options=[]
        for (let i =0;i<this.state.colorDetails.length;i++){
    
          attr_options.push(this.state.colorDetails[i].selected_option)
    
        }
        var atr_opt =this.getCombinations(attr_options,attr_options.length)
        this.setState({ food_names:atr_opt })    
      };

      childChange = (selected_child) => {
        this.setState({ selected_child });
      };

      handleSelectedValues = index => (e) => {
    
        var colorDetails = [...this.state.colorDetails]
        let id = e.target.value
        colorDetails[index]["cuisine"] = id;
        colorDetails[index]["cuisines"]=[{'id':id,'name':e.target.options[e.target.selectedIndex].text}];

        var token=localStorage.getItem('token')
        // const requestOptions = {
        //   method: 'GET',
        //   headers: {
        //     'Content-Type': 'application/json',
        //     "Authorization": `Token ${token}`
        //   },
    
    
        // };
        colorDetails[index]["selected_option"] = null
    
        fetch(FOOD_NAME_ALL_URL + '?cuisine__id=' + id)
          .then(async response => {
            const data = await response.json();
    
            // check for error response
            if (!response.ok) {
              // get error message from body or default to response status
              const error = (data && data.message) || response.status;
              return Promise.reject(error);
            }
            
            var results = []
            for (var i = 0; i < data.length; i++) {
              results.push({ label: data[i].name, value: data[i].id })
            }
            colorDetails[index]["foodnames"] = results
            this.setState({ colorDetails })
    
          })
          .catch(error => {
            this.setState({ errorMessage: error.toString() });
            console.error('There was an error!', error);
          });
        
      }


    allowVerfied = () => {
        this.setState({ verified: !this.state.verified });
      };

    submit(event) {
            const {allowProductSize,cuisine_list,attributes,colorDetails} = this.state
            var form_data = new FormData();
            Object.keys(event).forEach(key => form_data.append(key, event[key]));
            form_data.append("restaurant_foods_item", JSON.stringify(colorDetails));
            console.log("Files",cuisine_list)
            var child_attr=[]
            for (var i =0;i<cuisine_list.length;i++){
            
            let cuisines = cuisine_list[i]
            var attr=[],food_name=event.food_name 
            var  target={}
            for(var j=0;j<cuisines.length;j++){
                    //let key =attributes[i].value
                    if(cuisines[j].value){
                    attr.push(cuisines[j].value)
                    food_name += " "+cuisines[j].label
                    }else if(cuisines[j].image){
                    form_data.append(`food_image`+i, cuisines[j].image, cuisines[j].image.name);
                    // let  key  = Object.keys(attributes[j])
                    // let source={
                    //   [key]:attributes[j][key]
                    // }
                    // Object.assign(target, source);
        
                }else{
                    let  key  = Object.keys(cuisines[j])
                    let source={
                    [key]:cuisines[j][key]
                    }
                    Object.assign(target, source);
                }
                    
            }
            // alert(JSON.stringify(target))
            Object.assign(target, {'food_name':food_name});
            Object.assign(target, {'food_name':attr});
            child_attr.push(target)
            
            }

            form_data.append("restaurants_food", JSON.stringify(child_attr));
                
            this.props.addData(form_data, RESTAURANT_URL, "Restaurant")

            }
    onChangePaymentType = (e) => {

        this.setState({[e.target.name]:e.target.value})
        // alert(e.target.value)
    
    }
    render() {
        const {allowProductSize,colorDetails,attributeoptions,food_names} = this.state
        const { handleSubmit, submitting,attributes,cuisines,foodnames } = this.props;
       
        if (this.props.is_added) {
            setTimeout(function () {
                window.location.href = '/restaurant-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Restaurant </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Add Restaurant</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Restaurant Information</h3>
                        <div className="float-right">
                            <Link to="/restaurant-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="restaurant_name"
                                        type="text"
                                        component={renderField}
                                        label="Restaurant Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="address"
                                        type="text"
                                        component={renderField}
                                        label="Address"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="contact_number"
                                        type="text"
                                        component={renderField}
                                        label="Contact Number"
                                        validate={phoneNumber}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                     //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="first_name"
                                        type="text"
                                        component={renderField}
                                        label="First Name"
                                    // validate={required}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="last_name"
                                        type="text"
                                        component={renderField}
                                        label="Last Name"
                                        // validate={[required, match("password1")]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="post_code"
                                        type="text"
                                        component={renderField}
                                        label="Post Code"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="delivery_charge"
                                        type="text"
                                        component={renderField}
                                        label="Delivery Charge"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Select District</label>
                                    <span className="requiredField">*</span>
                                    <Field name="district" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select District</option>
                                        {this.props.districts.map(shipping_cls => (
                                            <option key={shipping_cls.id} value={shipping_cls.id}>{shipping_cls.name}</option>
                                        ))}
                                    </Field>
                                </div>
                                
                                
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Restaurant Logo</label>
                                    <Field
                                        name="logo"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Restaurant Logo"
                                    // validate={[required]}
                                    />

                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Restaurant Banner</label>
                                    <Field
                                        name="banner"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Upload Restaurant Banner"
                                   //  validate={[required]}
                                    />

                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Restaurant Menu Or Item</label>
                                    <Field
                                        name="menu_item_image"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Upload Restaurant Menu Or Item"
                                   //  validate={[required]}
                                    />

                                </div>

                                {/* <div className="col-md-6">
                                    <label>Cuisine </label>
                                    <span className="requiredField">*</span>
                                    <Field name="cuisine" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select Cuisine</option>
                                        {this.props.cuisines.map(shipping_cls => (
                                            <option key={shipping_cls.id} value={shipping_cls.id}>{shipping_cls.name}</option>
                                        ))}
                                    </Field>
                                </div> */}


                    
                    <div className="col-md-12">
                      <table className="table">
                        <tr>
                          <th>Cuisine</th>
                          <th>Food Name</th>
                          {/* <th>Quantity</th>
                          <th>Price</th> */}

                          <th>Action</th>
                        </tr>
                        <tbody>
                          {colorDetails.map((colors, idx) => (
                            <tr key={colors.index}>
                              <td>

                                <select
                                  name="cuisine"
                                  component="select"
                                  className="form-control"
                                  data-id={idx}
                                  id={"size" + idx}
                                  onChange={this.handleSelectedValues(idx)}
                                >
                                  <option value="">--Select Cuisine--</option>
                                  {cuisines.map((attribute, index) => (
                                    <option key={index} value={attribute.id}>{attribute.name}</option>

                                  ))}
                                </select>
                              </td>
                              <td>

                                <Select
                                  value={colors.selected_option}
                                  onChange={this.foodOptionChange(idx)}
                                  required={true}
                                  options={colors.foodnames}
                                  placeholder="Selected Food"
                                  isMulti
                                  isClearable
                                  id={"food_name" + idx}
                                // onChange={this.handleSelectedValues(idx)}
                                />


                              </td>

                            

                              {/* <td>
                                <input
                                  name="quantity"
                                  type="number"
                                  placeholder="Quantity"
                                  data-id={idx}
                                  className="form-control"
                                  id={"quantity" + idx}
                                  onChange={this.handleSelectedValues(idx)}
                                />
                              </td> */}
                              {/* <td>
                                <input
                                  name="size_price"
                                  type="number"
                                  placeholder="Price"
                                  data-id={idx}
                                  className="form-control"
                                  id={"price" + idx}
                                  onChange={this.handleSelectedValues(idx)}
                                />
                              </td> */}
                              {/* <td>
                              <div>
                                <input
                                  type="file"
                                  onChange={this.handleChangeColoImage}
                                  data-id={idx}
                                  id={"file" + idx}
                                  name="file"
                                />
                                {colors.file ? (
                                  <img
                                    src={colors.file}
                                    height="40px"
                                    width="40px"
                                    data-id={idx}
                                    id={"img" + idx}
                                  />
                                ) : null}
                              </div>
                            </td> */}
                              <td>
                                {idx === 0 ? (
                                  <button
                                    onClick={() => this.addNewRow()}
                                    type="button"
                                    className="btn btn-primary text-center"
                                  >
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    />
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-danger"
                                    onClick={() => this.deteteRow(colors)}
                                  >
                                    <i
                                      className="fa fa-minus"
                                      aria-hidden="true"
                                    />
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {/* {food_names.map((attr_options,optindex)=>( */}
                      <div>
                        <div className="row">  
                      
                       
                        {colorDetails.length>0 ? 
                          <>
                         
                            {colorDetails.map((colors,index)=>(
                              
                            <>
                           
                             
                          
                          <div className='row'>
                       <div className="col-md-3 mt-2 mb-2">
                            <label>Cuisine name</label>
                            <p>{colors.cuisines.length>0? colors.cuisines[0].name:''}</p>
                        </div>
                        <div className="col-md-3 mt-2 mb-2">
                            <label>Food Name</label>
                            <p>{colors.selected.length>0? colors.selected[0].label:''}</p>
                        </div>
                            <div className="col-md-3 mt-2 mb-2">
                            <label>Image</label>
                            <input
                              type="file"
                              onChange={this.handletextChild(index)}
                              data-id={index}
                              id={"file" + index}
                              name="image"
                          />
                            </div>
                             
                            <div className="col-md-3 mt-2 mb-2">
                            <label> Price</label>
                              <input className="form-control" type="number" onChange={this.handletextChild(index)}  name="price" placeholder="Price" required />
                            </div> 
                            <div className="col-md-3 mt-2 mb-2">
                            <label> Description</label>
                              <input className="form-control" type="text" onChange={this.handletextChild(index)}  name="description" placeholder="Description"/>
                            </div> 
                            <div className="col-md-3 mt-2 mb-2">
                            <label>Discount Type</label>
                            {/* <span className="requiredField">*</span> */}
                            <select name="discount_type" onChange={this.handletextChild(index)}  className="form-control"
                            >
                              <option value="">Select Discount Type</option>
                              <option value="1">Percentage (%)</option>
                              <option value="2">Flat Discount (BDT)</option>
                              <option value="3">No Discount</option>

                            </select>
                          </div>
                          <div className="col-md-3 mt-2 mb-2">
                          <label>Discount Amount</label>
                            <input name="discount_price" type="number" onChange={this.handletextChild(index)}  placeholder="Discount Amount" className="form-control"
                              label="Discount Amount" />
                          </div>
                          </div>
                          </>  
                           ))}     
                          </>
                         :null
                        } 
                          </div> 
                          
                      </div>
                      {/* ))} */}
                    </div>
                  
                                


                                
                            </div>
                            

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddRestaurant.propTypes = {
    // getRides:PropTypes.func.isRequired,
    addData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    is_added: state.products.is_added,
    
    districts: state.districts.districts,
    cuisines:state.cuisines.cuisines,
    foodnames: state.foodnames.foodnames,


});


AddRestaurant = reduxForm({
    form: 'AddRestaurant',
}
)(AddRestaurant);


export default connect(mapStateToProps, { addData,fetchDistrict, fetchCuisine,FoodNameByURL,CuisineByURL})(AddRestaurant);

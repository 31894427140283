import {
    LOADING_LOGO,
    FETCH_LOGO,
    DETAIL_LOGO,
    DELETE_LOGO,
    NOT_FOUND_LOGO,
    LOADING_FOOTER_LOGO,
    FETCH_FOOTER_LOGO,
    NOT_FOUND_FOOTER_LOGO,
    LOADING_FAVICON,
    FETCH_FAVICON,
    DETAIL_FAVICON,
    DELETE_FAVICON,
    NOT_FOUND_FAVICON,
    LOADING_PRELOADER,
    FETCH_PRELOADER,
    DETAIL_PRELOADER,
    DELETE_PRELOADER,
    NOT_FOUND_PRELOADER,


} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { FAVICON_URL, LOGO_URL,FOOTER_LOGO_URL, PRELOADER_URL, SELLER_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


export const fetchLogo = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_LOGO });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["logo"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = LOGO_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data))
                dispatch({
                    type: FETCH_LOGO,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_LOGO });

    }
};


//Footer Logo
export const fetchFooterLogo = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_FOOTER_LOGO });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["logo"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = FOOTER_LOGO_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data))
                dispatch({
                    type: FETCH_FOOTER_LOGO,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_FOOTER_LOGO });

    }
};




// Logo Detail
export const LogoDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = LOGO_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            // alert(JSON.stringify(response.data))
            dispatch({
                type: DETAIL_LOGO,
                payload: response.data,
            })
        })
};

// // Delete Logo
export const deleteLogo = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(LOGO_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_LOGO,
                payload: id,
            });
            toast.success('Logo has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};



export const searchLogo = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_LOGO });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(LOGO_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_LOGO,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};





export const fetchFavicon = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_FAVICON });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["favicon"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = FAVICON_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data))
                dispatch({
                    type: FETCH_FAVICON,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_FAVICON });

    }
};




export const fetchPreloader = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_PRELOADER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["preloader"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = PRELOADER_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data))
                dispatch({
                    type: FETCH_PRELOADER,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_PRELOADER });

    }
};

import {
    FETCH_GROUPS,
    DELETE_GROUP,
    LOADING_GROUP,
    NOT_FOUND_GROUP,
    UPDATE_GROUP,
    ADD_GROUP
} from '../actions/types';

const initialState = {
    groups: [],
    group: {},
    count: null,
    next: null,
    is_loading: false,
    is_fetching:false,
    errors: ''
};


export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_GROUP:
            return {
                ...state,
                groups:[],
                is_loading: true
            };
        case FETCH_GROUPS:
            return {
                ...state,
                groups: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case DELETE_GROUP:
            return {
                ...state,
                groups: action.payload,

            };
        case UPDATE_GROUP:
            var filteredDataSource = state.groups.filter((item) => {
                if (item.id === action.payload.id) {
                    item.name =  action.payload.name;
                 }
         
                 return item;
             });
             console.log(filteredDataSource)
                return {
                    ...state,
                    groups:filteredDataSource,
                    is_loading: false
                };
        case DELETE_GROUP:
            return {
                ...state,
                groups: state.groups.filter(child_category => child_category.id !== action.payload)
            };
            case ADD_GROUP:
               
                return {
                    ...state,
                    groups:state.groups,
                    is_loading: false
                };    
        case NOT_FOUND_GROUP:
            return {
                ...state,
                groups: [],
                count: 0,
                next: 0,
                is_loading: false,

            };
        default:
            return state;
    }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactPaginate from 'react-paginate';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
// import Modal from 'react-modal';
import { CSVLink } from "react-csv";
import RichTextEditor from 'react-rte';
import { Modal, Button } from "react-bootstrap";
import { required } from "../../../constants/Validate";

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'
import { USER_EMAIL_SEND_URL } from '../../../constants/api'

import { fetchSubscribers } from '../../../actions/SubscriberActions';
import { sentMail } from '../../../actions/CustomerActions';

// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');


const headers = [
    
    {label:"Subscriber Email",key:"email"},
   

]  

class SubscriberList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
        this.toggleAllChange = this.toggleAllChange.bind(this)

    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        name: "",
        email: "",
        subject:'',
        subscribers:[],
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
        allchecked: false,
        bulk:false,
        get_subscriber:false,
        showedit:false,
        message: RichTextEditor.createEmptyValue(),

    };

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_fetching) {
            if (nextProps.is_fetching !== prevState.get_subscriber) {
                var subscribers = []
               
                for (var i = 0; i < nextProps.subscribers.length; i++) {
                    Object.assign(nextProps.subscribers[i], { 'checked': false });
                    subscribers.push(nextProps.subscribers[i])
                }
                return {
                    subscribers: subscribers,
                    get_subscriber: true
                }
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.subscribers != this.props.subscribers){
            this.setState({subscribers:this.props.subscribers})
        }

    }

    handlePageClick = (data)=>{
        var pageNo= Number(data.selected)+1
        this.props.fetchSubscribers(pageNo)
         
    }

    componentDidMount() {
        // alert(JSON.stringify(this.props.subscribers.email))
        this.props.fetchSubscribers(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteBrands(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.name + '.',
                        'success'
                    )
                }
            }
        })
    }

    handleEditClose=()=> {
        this.setState({ showedit: false, })
    }
    updatePrimaryCategory=()=> {
        var data = {
            subject: this.state.subject,
            message:this.state.message.toString('html')
            
        }
        var subscriberList = []

        for (var i = 0; i < this.state.subscribers.length; i++) {
            if (this.state.subscribers[i].checked) {
                if(this.state.subscribers[i].email !== null && this.state.subscribers[i].email!=='' && this.state.subscribers[i].email!==undefined){
                    subscriberList.push(this.state.subscribers[i].email)
                }
            }
        }
        // alert(JSON.stringify(subscriberList))
       this.props.sentMail( data,USER_EMAIL_SEND_URL,subscriberList,"Email")
       this.setState({ showedit: false })
    }

    onChangeDetails = (message) => {
        this.setState({ message });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChangeDetails(
                message.toString('html')
            );
        }
    };
    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        this.props.fetchSubscribers('', this.state);
        this.setState({ hideButton: true })

    };


    toggleAllChange(e) {
        const { value, checked } = e.target;
        let subscribers = [...this.props.subscribers]
        this.setState({showedit:true})
        subscribers.forEach(subscriber => subscriber.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, subscribers, bulk: true })
        } else {
            this.setState({ allchecked: checked, subscribers, bulk: false })
        }
    }

    onSingleChange = index => (e) => {
        
        console.log(index)
        let subscribers = [...this.state.subscribers]
        subscribers[index]["checked"] = e.target.checked
        let recent_length = subscribers.filter(subscriber => subscriber.checked).length;
        this.setState({showedit:true})
        if (subscribers.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        this.setState({ subscribers })

    };
    render() {
        
        const {subscribers,is_loading,count,permissions} =this.props
        const {allchecked,showedit} = this.state
        const  page = Math.ceil(count / 10)

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Subscriber List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Subscriber list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Subscriber List</h3>

                               
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="email" type="text"
                                            onChange={this.onChange} placeholder="Email"
                                            aria-label="Search" />
                                    {CheckPermisionAction(permissions,'Customer','subscriber_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    :null}
                                    </form>
                                </div>&nbsp;
                                <CSVLink data ={this.props.subscribers} headers={headers}  className="btn btn-info text-white"><b><i className="fa fa-download"></i>
                                    Export CSV</b></CSVLink>&nbsp;

                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'Customer','view_subscriber_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                        <th>
                                            <input type="checkbox"
                                                checked={allchecked}
                                                onChange={this.toggleAllChange}
                                            />   
                                            </th>
                                            {/* <th>SL</th> */}
                                            <th>Email</th>
                                          


                                            {this.state.hideButton ?
                                                <th><Link to="/subscriber-list" onClick={() => { window.location.href = "/subscriber-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null} 
                                            
                                        </tr>
                                    </thead>
                                    <tbody>

                                    {is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> : subscribers.length != undefined && subscribers.map((subscriber, index) => (
                                            
                                            <tr key={index.id}>
                                                <td>
                                                    <input type="checkbox" onChange={this.onSingleChange(index)} checked={subscriber.checked ? true : false} value={subscriber.id} />
                                                </td>
                                            {/* <td>{index+1}</td> */}

                                            <td>{subscriber.email}</td>


                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            :null} 
                            <Modal
                                show={showedit}
                                onHide={this.handleEditClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Send Message</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                   
                                    <label>Subject</label>
                                    <input
                                        name="subject"
                                        type="text"
                                        value={this.state.subject}
                                        className="form-control"
                                        placeholder="Subject"
                                        validate={required}
                                        onChange={this.onChange}
                                    />
                                    <label>Message</label>
                                    <RichTextEditor
                                        // toolbarConfig={toolbarConfig}
                                        value={this.state.message}
                                        onChange={this.onChangeDetails}
                                    />
                                    
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleEditClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.updatePrimaryCategory}>
                                        Send
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                             
                                { page>1 ? (
                                <ul className="pagination pagination-sm m-0 float-right">
                                <ReactPaginate
                                previousLabel={'previous'}
                                nextLabel={'next'}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={page}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={this.handlePageClick}
                                containerClassName={'pagination'}
                                subContainerClassName={'pages pagination'}
                                activeClassName={'active'}
                                />
                                </ul>
                                ):null
                                }
                                
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

SubscriberList.propTypes = {
    fetchSubscribers: PropTypes.func.isRequired,
    sentMail: PropTypes.func.isRequired,


};

const mapStateToProps = state => ({
    subscribers: state.subscribers.subscribers,
    count: state.subscribers.count,
    next: state.subscribers.next,
    is_loading: state.subscribers.is_loading,
    is_fetching:state.subscribers.is_fetching,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchSubscribers,sentMail })(SubscriberList);

import {
    LOADING_BRANDS,
    FETCH_BRANDS,
    ADD_BRANDS,
    DETAIL_BRANDS,
    UPDATE_BRANDS,
    DELETE_BRANDS,
    NOT_FOUND_BRANDS,
} from '../actions/types';

const initialState = {
    brands: [],
    brand: {},
    count: null,
    next: null,
    is_loading: false,
    is_added: false,
    is_updated: false,
    errors: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_BRANDS:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_BRANDS:
            return {
                ...state,
                brands: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case DETAIL_BRANDS:
            return {
                ...state,
                brand: action.payload,

            };
        case UPDATE_BRANDS:
            var filteredDataSource = state.brands.filter((item) => {
                if (item.id === action.payload.id) {
                    item.name = action.payload.name;
                    item.image = action.payload.image
                }

                return item;
            });
            console.log(filteredDataSource)
            return {
                ...state,
                brands: filteredDataSource,
                is_loading: false,
                is_updated: true
            };
        case ADD_BRANDS:
            return {
                ...state,
                brands: state.brands,
                is_loading: false,
                is_added: true
            };
        case DELETE_BRANDS:
            return {
                ...state,
                brands: state.brands.filter(brand => brand.id !== action.payload)
            };

        case NOT_FOUND_BRANDS:
            return {
                ...state,
                brands: [],
                count: 0,
                next: 0,
                is_loading: false,

            };
        default:
            return state;
    }
}
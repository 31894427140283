import {
    LOADING_ATTRIBUTE_OPTION,
    GETTING_ATTRIBUTE_OPTION,
    DETAIL_ATTRIBUTE_OPTION,
    UPDATE_ATTRIBUTE_OPTION,
    DELETE_ATTRIBUTE_OPTION,
    NOT_FOUND_ATTRIBUTE_OPTION,
    GETTING_ATTRIBUTE_OPTION_SIZE

} from './types';

import { GET_ATTRIBUTE_OPTION_URL, GET_ATTRIBUTE_OPTION_CREATE_URL, GET_ATTRIBUTE_OPTION_LIST_URL } from '../constants/api';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { SearchURLGenerate } from '../constants/CustomMethod'



// Post Attribute Option
export const postAttributeOption = (data) => {

    let form_data = {
        'name': data.name,
        'attribute': data.attribute,
        'color_code':Object.keys(data.color_code).length >0 ? data.color_code : ''

    }
    // alert(JSON.stringify(form_data))
    let url = GET_ATTRIBUTE_OPTION_CREATE_URL;
    let token = localStorage.getItem('token');
   //alert(JSON.stringify(form_data))
    // if (token) {
    //     headers["Authorization"] = `Token ${token}`;
    // }
    // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
    console.log(token);
    axios.post(url, form_data,
        {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Token ${token}`
            }

        })
        .then(res => {
            // alert(JSON.stringify(res.data))
            toast.success("New Attribute Option created successfully.");
            console.log(res.data);

        })
        .catch(err => {
            //toast.error("Please try again.");
            alert(JSON.stringify(err))
        })
};



// FETCH Attribute Option
export const getAttributeOption = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_ATTRIBUTE_OPTION })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }


    let search_key = ["name", 'attribute__id']

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_ATTRIBUTE_OPTION_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: GETTING_ATTRIBUTE_OPTION,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_ATTRIBUTE_OPTION });

    }



};
export const getAttributeOptionColor = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_ATTRIBUTE_OPTION })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }


    let search_key = ["name",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_ATTRIBUTE_OPTION_LIST_URL + '?attribute__name=Color'
        // if (search_url !== '' && search_url !== null) {
        //     url = url + "?" + search_url;
        // } else if (pageNumber) {
        //     url = url + '?page=' + pageNumber
        // }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: GETTING_ATTRIBUTE_OPTION,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_ATTRIBUTE_OPTION });

    }



};
export const getAttributeOptionSize = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_ATTRIBUTE_OPTION })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }


    let search_key = ["name",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_ATTRIBUTE_OPTION_LIST_URL + '?attribute__name=Size'
        // if (search_url !== '' && search_url !== null) {
        //     url = url + "?" + search_url;
        // } else if (pageNumber) {
        //     url = url + '?page=' + pageNumber
        // }
        axios.get(url, { headers, })
            .then(response => {
                //  alert(JSON.stringify(response))
                dispatch({
                    type: GETTING_ATTRIBUTE_OPTION_SIZE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_ATTRIBUTE_OPTION });

    }



};

// Attribute Option Detail
export const attributeOptionDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_ATTRIBUTE_OPTION_URL + `${id}/`;

    axios.get(url, { headers })
        .then(response => {

            dispatch({
                type: DETAIL_ATTRIBUTE_OPTION,
                payload: response.data,
            })
        })
};



// Update Attribute Option
export const updateAttributeOption = (id, data) => (dispatch, getState) => {

    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let form_data = {
        'name': data.name,
        'attribute': data.attribute,
        'color_code': Object.keys(data.color_code).length >0 ? data.color_code : ''
    }
    // alert(JSON.stringify(form_data))
    let url = GET_ATTRIBUTE_OPTION_CREATE_URL + `${id}/`;

    axios.put(url, form_data, { headers })
        .then(response => {
            console.log(response.data)
            dispatch({
                type: UPDATE_ATTRIBUTE_OPTION,
                payload: response.data
            });

            toast.success("Attribute Option successfully updated");
            console.log('Success');
        })
        .catch(err => {
            // dispatch();
            toast.error('Please try again', err);
            console.log(err);
        })
};


// Delete Child Category
export const deleteAttributeOption = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(GET_ATTRIBUTE_OPTION_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_ATTRIBUTE_OPTION,
                payload: id,
            });
            toast.success('Attribute Option deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};


export const getAttributeOptionByURL = (url) => (dispatch, getState) => {
    dispatch({ type: LOADING_ATTRIBUTE_OPTION })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }





    axios.get(url, { headers, })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: GETTING_ATTRIBUTE_OPTION,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })



};
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import {
    required
} from '../../../constants/Validate'
import { connect } from "react-redux";
import { paymentMethodDetail } from "../../../actions/orderActions";
import { updateDatas } from "../../../actions/CustomerActions"
import PropTypes from "prop-types";
import moment from 'moment';
import { Link } from "react-router-dom";

import momentLocalizer from 'react-widgets-moment';
import { GET_PAYMENT_METHOD_URL } from '../../../constants/api';
import RichTextEditor from 'react-rte';



momentLocalizer(moment);



// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Text area type
const renderTextArea = ({
    input,
    label,
    meta: { touched, error, warning },
  }) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
      <div>
        <label className="text-sm">{label}</label>
        <div>
          <textarea
            {...input}
            placeholder={label}
            rows="3"
            cols="40"
            className={className}
          />
          {touched &&
            ((error && <span className="text-danger">{error}</span>) ||
              (warning && <span>{warning}</span>))}
        </div>
      </div>
    );
  };

// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class PaymentMethodUpdate extends Component {
    state={
        payment_type:"",        
        is_show:false,
        description: RichTextEditor.createEmptyValue(),
    }
    static propTypes = {
        onChanges: PropTypes.func
      };
    
    componentDidMount() {
        // alert(this.props.paymentMethodDetail())
        let id = this.props.match.params.id;
        this.props.paymentMethodDetail(id)
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        // alert(JSON.stringify(nextProps.payments.description))
                if (nextProps.is_payment_getting === true) {
                  if (nextProps.is_payment_getting !== prevState.is_show) {
        
                        return{
                           
                            payment_type:nextProps.payments.payment_type,
                            description:RichTextEditor.createValueFromString(nextProps.payments.description,'html'),
                            
                            
                        }
        
                  }
                }     
                return null 
            }
            onChangePaymentType = (e) => {

                this.setState({[e.target.name]:e.target.value})
                // alert(e.target.value)
            
            }
            onChanges = (description) => {
                // alert(this.props.onChanges)
                this.setState({description});
                if (this.props.onChanges) {
                  // Send the changes up to the parent component as an HTML string.
                  // This is here to demonstrate using `.toString()` but in a real app it
                  // would be better to avoid generating a string on each change.
                  this.props.onChanges(
                    description.toString('html')
                  );
                }
              };
              
              onChange = (event, { newValue }) => {
                //alert(newValue)
                this.setState({
                  value: newValue
                });
              };
              onChange = (event, { newValue }) => {
                //alert(newValue)
                this.setState({
                  value: newValue
                });
              };
            
            
    submit(event) {
        // console.log(values)
        // return sleep(1000).then(() => {
        //     let id = this.props.match.params.id;
        //     this.props.updateData(id, values);
        // })
        var form_data = new FormData();

        if (this.props.payments.payment_type === 1) {
            form_data.append('payment_type',this.state.payment_type)
            form_data.append('account_name',event.account_name);
            form_data.append('account_number',event.account_number);
            form_data.append('bank_name',event.bank_name);
            form_data.append('branch_name',event.branch_name);
            form_data.append('routing_number',event.routing_number);
            form_data.append("description", this.state.description.toString('html') ? this.state.description.toString('html') : '');

            // form_data.append('description',event.description)


        } else{
            form_data.append('payment_type',this.state.payment_type);
            form_data.append('mobile_banking_type',event.mobile_banking_type);
            form_data.append('wallet_number',event.wallet_number);
            form_data.append("description", this.state.description.toString('html') ? this.state.description.toString('html') : '');

            // form_data.append('description',event.description);

        }
        let id = this.props.match.params.id;
        this.props.updateDatas(form_data, GET_PAYMENT_METHOD_URL + id + '/', "Payment Method")
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        //console.log('ride_category_update_data', rideCategoryDetails);
        // console.log('update', rideCategoryDetails)
        if (this.props.is_updated) {
            setTimeout(() => {
                window.location.href = '/payment-method-list'
            }, 3000)
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Payment Method Information</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            {/* <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item active">Dashboard v2</li>
                                    </ol>
                                </div><!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="">Update Payment Method
                        <Link to="/payment-method-list" className="btn btn-info float-right text-white"><b>
                                Back</b>
                            </Link></h3>

                    </div>
                    {/* /.card-header */}
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                        <div className="col-md-12 form-group clearfix" >
                                <div className="col-md-6 icheck-primary d-inline">
                                    <input type="radio" id="radioPrimary1" onChange={this.onChangePaymentType} value="1" name="payment_type" checked={this.props.payments.payment_type===1?true:false}/>
                                    <label for="radioPrimary1">Bank Account 
                                    </label>
                                </div>
                                <div className="col-md-6 icheck-primary d-inline">
                                    <input type="radio" id="radioPrimary2" value="2" onChange={this.onChangePaymentType} name="payment_type" checked={this.props.payments.payment_type===2?true:false}/>
                                    <label for="radioPrimary2">Mobile Wallet
                                    </label>
                                </div>
                                
                                </div>
                                {this.props.payments.payment_type===1 ? (
                               <>     
                                <div className="col-md-6">
                                    <Field
                                        name="account_name"
                                        type="text"
                                        component={renderField}
                                        label="Account Name"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="account_number"
                                        type="text"
                                        component={renderField}
                                        label="Account Number"
                                     //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="bank_name"
                                        type="text"
                                        component={renderField}
                                        label="Bank Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="branch_name"
                                        type="text"
                                        component={renderField}
                                        label="Branch Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="routing_number"
                                        type="number"
                                        component={renderField}
                                        label="Routing Number"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="description"
                                        type="text"
                                        component={renderTextArea}
                                        label="Description"
                                    />
                                    </div> */}
                                    {/* <div class="col-md-12">
                                    <label>Description</label>
                                    <RichTextEditor
                                        
                                        value={this.state.description}
                                        onChange={this.onChanges}
                                    />
                                    </div> */}
                                </>
                                ):
                                <>
                                <div className="col-md-6">
                                    <label>Mobile Banking</label>
                                    <Field name="mobile_banking_type" component="select" className="form-control"
                                    required={true}
                                    >
                                    <option value="">Select Mobile Banking</option>
                                    <option value="1">Bkash</option>
                                    <option value="2">Nogod</option>
                                    <option value="3">Rocket</option>

                                </Field>
                                </div>
                                  <div className="col-md-6">
                                    <Field
                                        name="wallet_number"
                                        type="text"
                                        component={renderField}
                                        label="Number"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                    </div>
                                    {/* <div className="col-md-6">
                                    <Field
                                        name="description"
                                        type="text"
                                        component={renderTextArea}
                                        label="Description"
                                    />
                                    </div> */}
                                    <div class="col-md-12">
                                    {/* <Field
                                            name="product_details"
                                            type="text"
                                            component={renderTextArea}
                                            label="Product Details"
                                        // required={true}
                                        /> */}
                                    <label>Description</label>
                                    <RichTextEditor
                                        // toolbarConfig={toolbarConfig}
                                        value={this.state.description}
                                        onChange={this.onChanges}
                                    />
                                    </div>
                                 </>   
                                }
                            <div className="card-footer text-right">
                                <div>
                                    <button
                                        type="submit"
                                        className="btn btn-info"
                                        disabled={submitting}>
                                        Update
                                </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


PaymentMethodUpdate.propTypes = {
    paymentMethodDetail: PropTypes.func.isRequired,
    updateDatas: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    initialValues: state.payments.payments,
    payments: state.payments.payments,
    is_added: state.payments.is_added,
    is_updated: state.customers.is_updated,
    is_payment_getting:state.payments.is_payment_getting

});

PaymentMethodUpdate = reduxForm({
    form: 'PaymentMethodUpdate',
    enableReinitialize: true,
}
)(PaymentMethodUpdate);

export default connect(mapStateToProps, { paymentMethodDetail, updateDatas })(PaymentMethodUpdate);
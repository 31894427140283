import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required } from '../../constants/Validate'
import { renderField } from '../../constants/FormInputType'
import { sumTotalAmount, sumTotalQuantity,sumTotalProductWeigth,calculateCouponDiscount } from '../../constants/CustomMethod'

import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { addData } from '../../actions/ProductAction';
import { BASE_URL, GET_WAREHOUSE_LIST,GET_STOCK } from "../../constants/api";
import { searchProducts } from '../../actions/ProductAction';
import Autosuggest from 'react-autosuggest';
import { fetchBrandsAll } from '../../actions/BrandsActions';
import { fetchWarehouse } from '../../actions/WarehouseAction';
import { fetchSellers } from '../../actions/SellerActions';
import {fetchProductUnit} from '../../actions/ProductUnitAction'

momentLocalizer(moment);


// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddStock extends Component {
  static propTypes = {
    onChangeDetails: PropTypes.func
  };

    state={
        value:'',
        oderList: [],
        totalAmount: 0,
        totalQuantity: 0,
        totalProductWeigth:0,
        discount_amount:0,
        warehouse_options:[],
        warehouse_list:[]
      
      
    }


    onChanges=(index,e)=>{
        let orders = [...this.state.oderList]
        orders[index][e.target.name]=e.target.value
        
      
        
        this.setState({ oderList: orders})
      }
      
      deteteRow = (product) => {
        var products = this.state.oderList.filter((r) => r !== product)
        this.setState({
          oderList: products,
          totalQuantity: sumTotalQuantity(products),
          totalAmount: sumTotalAmount(products),
          totalProductWeigth:sumTotalProductWeigth(products)
    
        });
      }
    
      manageQuantity = (index, type = "plus") => {
        let orders = [...this.state.oderList]
        if (type == "plus") {
          orders[index].quantity = orders[index].quantity + 1;
          orders[index].subtotal = orders[index].new_price * orders[index].quantity;
    
        } else {
          if (orders[index].quantity > 1) {
            orders[index].quantity = orders[index].quantity - 1;
            orders[index].subtotal = orders[index].new_price * orders[index].quantity;
    
          }
        }
        this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders) })
      }

    componentDidMount() {
    this.props.fetchBrandsAll(1);
    this.props.fetchWarehouse(1);
    this.props.fetchSellers(1);
    this.props.fetchProductUnit(1)

       
    }
    componentDidUpdate(prevProps, prevState){
        setTimeout(()=>{
      
         },2000)
     }

  // Filter logic
  getSuggestions = value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    this.props.searchProducts(value);
    return inputLength === 0 ? [] : this.props.products.filter(product =>
      product.product_name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

    // Suggestion rerender when user types
    onSuggestionsFetchRequested = ({ value }) => {
        //alert(value)
        this.setState({
        products: this.getSuggestions(value)
        });
    };
    // Triggered on clear
    onSuggestionsClearRequested = () => {
        this.setState({
        products: []
        });
    };
  // Trigger suggestions
  getSuggestionValue = product => '';
    // Render Each Option
    renderSuggestion = product => (
        <div>
        {product.product_name}
        </div>
    );
    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        let { oderList } = this.state;
        suggestion.quantity = 1
        suggestion.subtotal = Number(suggestion.new_price)
        var index = oderList.findIndex(x => x.id == suggestion.id);
        // alert(oderList.id)
        console.log(suggestion.id);
        if (index === -1) {
          oderList.push(suggestion)
          var pro_index = oderList.findIndex(x => x.id == suggestion.id);
          oderList[pro_index].product_id =suggestion.id
          oderList[pro_index].price =oderList[pro_index].regular_price
          oderList[pro_index].quantity =oderList[pro_index].quantity
          

          // alert(oderList)
          // oderList[index].['product_id']
        } 
        // else {
        //   oderList[index].quantity = oderList[index].quantity + 1;
        //   oderList[index].subtotal += suggestion.new_price;
        // }
        this.setState({ totalAmount: sumTotalAmount(oderList), totalQuantity: sumTotalQuantity(oderList) })
      };

  
    
    
      // OnChange event handler
  onChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };
    onChanged=(e)=>{
        
        [e.target.name]=e.target.value
}

handletextChild = index => (e) => {
  // alert(index)
  let child_products = [...this.state.warehouse_options];
  // let addedItem = child_products[index].findIndex(item=> item[e.target.name])
  
  // if(addedItem !==-1){
  //   child_products[index].push({[e.target.name]:e.target.value})
   
  //   //  var key =  e.target.name;
  //   //  var data =child_products[index]
     
  //   //    for (let i=0;i<data.length;i++){
  //   //           if(data[i][key]!==undefined){
  //   //             if(data[i][key]==="feature_image"){
  //   //               data[i][key]=e.target.files[0]
  //   //             }else{
  //   //               data[i][key]=e.target.value
  //   //             }
                
  //   //           }

      
  //   // }
  //   }else{
  //     // if(e.target.name==='feature_image'){
  //     //   child_products[index].push({[e.target.name]:e.target.files[0]})
         
  //     // }else{
  //       child_products[index].push({[e.target.name]:e.target.value})
     
     
  //   }
  
   this.setState({warehouse_list:child_products})


}     
     
     


    
    submit(event) {
      const {oderList} = this.state
      var form_data = new FormData();
      // Object.keys(event).forEach(key => form_data.append(key, event[key]));
      form_data.append('stock_details',JSON.stringify(oderList));
      form_data.append('total_price',event.total_price);
      form_data.append('total_quantity',event.total_quantity);
      form_data.append('warehouse_id',event.warehouse_id);
      form_data.append('brand',event.brand);
      form_data.append('unit_id',event.unit_id);
      form_data.append('product_id',event.product_id);
      form_data.append('price',event.regular_price)

      if (event.memo_file){
        form_data.append('memo_file',event.memo_file,event.memo_file.name)
      }
      // alert(form_data)
        this.props.addData(form_data, GET_STOCK, "Stock")

            }
    onChangePaymentType = (e) => {

        this.setState({[e.target.name]:e.target.value})
        // alert(e.target.value)
    
    }
    render() {
        const {value,oderList,totalAmount,totalQuantity} = this.state
        const { handleSubmit, submitting } = this.props;
       // Option props
        const inputProps = {
            placeholder: 'Type Product Name or Product Sku Here',
            value,
            type: "search",
            onChange: this.onChange
        };
        if (this.props.is_added) {
            setTimeout(function () {
                window.location.href = '/stock-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Stock </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Add Stock</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Stock Information</h3>
                        <div className="float-right">
                            <Link to="/stock-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Memo File</label>
                                        <Field
                                            name="memo_file"
                                            type="file"
                                            component={FileInput}
                                            label="Memo File"
                                            accept="image/*"
                                            // validate={required}

                                        // warn={alphaNumeric}
                                        />
                                </div>
                                <div className="col-md-6">
                                    <label>Select Brand</label>
                                    <span className="requiredField">*</span>
                                    <Field name="brand" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select Brand</option>
                                        {this.props.brands.map(brand => (
                                            <option key={brand.id} value={brand.id}>{brand.name}</option>
                                        ))}
                                    </Field>
                                </div>
                                {/* <div className="col-md-6">
                                    <label>Select Seller</label>
                                    <span className="requiredField">*</span>
                                    <Field name="seller" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select Seller</option>
                                        {this.props.sellers.map(shipping_cls => (
                                            <option key={shipping_cls.id} value={shipping_cls.id}>{shipping_cls.shop_name}</option>
                                        ))}
                                    </Field>
                                </div> */}
                                
                                <div className="col-md-6">
                                    <Field
                                        name="total_quantity"
                                        type="number"
                                        // InputProps={{ inputProps: { min: 0, max: 10 } }}
                                        component={renderField}
                                        label="Quantity"
                                        validate={required}
                                        // mandatory={true}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="total_price"
                                        type="number"
                                        component={renderField}
                                        label="Price"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                                <div className="col-md-12">
                                <label>Type Your Product</label>
                                <span className="requiredField">*</span>
                                <Autosuggest
                                    suggestions={this.props.products ? this.props.products : []}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                    getSuggestionValue={this.getSuggestionValue}
                                    renderSuggestion={this.renderSuggestion}
                                    inputProps={inputProps}
                                    onSuggestionSelected={this.onSuggestionSelected}
                                />
                            </div><br />
                            {oderList.length > 0 ? (
                  <>
                    <div className="col-md-12">
                      <strong>Product List</strong>
                    </div>
                    <div className="col-md-12">
                      <table className="table orderlist">
                        <tr>
                          <th>Image</th>
                          <th>Product Name</th>
                          <th>Product Sku</th>
                          <th>Vendor/Seller</th>
                         <th>Product Weight</th>
                          <th className="text-center">Quantity</th>
                          <th>Price</th>
                          <th>Warehouse Name</th>
                          <th>Product unit</th>
                          <th>Subtotal</th>
                          <th>Action</th>
                        </tr>
                        <tbody>
                          {this.state.oderList.map((product, index) => (

                            <tr id={product.id} key={index}>
                              <td>
                                <img src={BASE_URL+ product.feature_image} height="100px" width="100px" alt="No Image" />
                              </td>
                              <td>
                                {product.product_name}
                              </td>
                              <td>
                                {product.product_sku}
                              </td>
                              <td>
                                {product.vendor ? product.vendor.shop_name : ''}
                              </td>
                              <td>

                               <div class="input-group plus-minus-input">
                                 
                                 <input class="input-group-field form-control" type="number" name="unit_amount" value={product.unit_amount} onChange={(e)=>this.onChangesProductWeight(index,e)} />
                               
                               </div>
                             </td>
                              <td>

                                <div class="input-group plus-minus-input">
                                  <div class="input-group-button">
                                    <button type="button" class="btn btn-success" onClick={() => this.manageQuantity(index, "minus")}>
                                      <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                  <input class="input-group-field form-control" type="number" name="quantity" value={product.quantity} />
                                  <div class="input-group-button">
                                    <button type="button" class="btn btn-primary" onClick={() => this.manageQuantity(index, "plus")}>
                                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                              <td>

                               <div class="input-group plus-minus-input">
                              
                                 <input class="input-group-field form-control" type="number" name="regular_price" value={product.regular_price} onChange={(e)=>this.onChanges(index,e)} />
                                
                               </div>
                             </td>
                              
                              <td>
                              
                              <select name="warehouse_id" onChange={(e)=>this.onChanges(index,e)}   className="form-control"
                            >
                            <option value="">--Select Warehouse--</option>
                            {this.props.warehouses.map((warehouse,index )=> (
                              <option key={index}  value={warehouse.id}>{warehouse.warehouse_name}</option>
                              
                              ))}
                              </select>
                           
                              
                                    {/* <Field name="warehouse" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select Warehouse</option>
                                        {this.props.warehouses.map((warehouse,index )=> (
                                            <option key={index} onChange={} value={warehouse.id}>{warehouse.warehouse_name}</option>
                                        ))}
                                    </Field> */}
                           

                                
                              </td>
                              <td>
                              <select name="unit_id" onChange={(e)=>this.onChanges(index,e)}  className="form-control">
                              <option value="">--Select Product unit--</option>
                              {this.props.product_units.map((warehouse,index )=> (
                                <option key={index}  value={warehouse.id}>{warehouse.name}</option>
                                
                                ))}
                            </select>
                              </td>
                              <td colSpan="1">
                              <div class="input-group plus-minus-input">
                              
                              <input class="input-group-field form-control" type="number" name="subtotal" value={product.subtotal ? product.subtotal : 0} onChange={(e)=>this.onChangesSubtotal(index,e)} />
                           
                            </div>
                                {/* {product.subtotal ? product.subtotal : 0} */}
                              </td>
                              <td>
                                <button
                                  className="btn btn-danger" id={product.id}
                                  onClick={() => this.deteteRow(product)}
                                >
                                  <i
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  />
                                </button>

                              </td>
                            </tr>
                          ))}
                           {/* <tr>
                           
                            <th colSpan="3" className="">Discount</th>
                            <th></th>
                            <th colSpan="4" className="text-center"></th>
                            <th colSpan="3" className="center">{this.state.discount_amount !==null? (this.state.discount_amount).toFixed(2):0}</th>

                          </tr>
                          <tr>
                            <th colSpan="3" className="">Delivery Charge</th>
                            <th></th>
                            <th colSpan="4" className="text-center"></th>
                            <th colSpan="3" className="center">{this.state.shipping_charge !==null? this.state.shipping_charge:0}</th>

                          </tr>
                          <tr>
                            <th colSpan="3" className="">Total</th>
                            <th></th>
                            <th colSpan="4" className="text-center">{totalQuantity}</th>
                            <th colSpan="3" className="center">{this.state.discount_amount !==null && this.state.shipping_charge !==null ?totalAmount+this.state.shipping_charge-this.state.discount_amount:totalAmount+this.state.shipping_charge}</th>

                          </tr> */}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : null
                }
                          
                               
      
                            </div>
                            

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddStock.propTypes = {
    fetchWarehouse:PropTypes.func.isRequired,
    addData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    is_added: state.products.is_added,
    brands:state.brands.brands,
    warehouses: state.warehouses.warehouses,
    products:state.products.products,
    sellers:state.sellers.sellers,
    product_units:state.product_units.product_units


});


AddStock = reduxForm({
    form: 'AddStock',
}
)(AddStock);


export default connect(mapStateToProps, { addData, searchProducts,fetchBrandsAll,fetchWarehouse,fetchSellers,fetchProductUnit})(AddStock);

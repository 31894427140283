import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { searchProducts,deleteGallryImage } from '../../actions/ProductAction';
import { sumTotalAmount, sumTotalQuantity } from '../../constants/CustomMethod'
import { updateDatas } from '../../actions/CustomerActions';
import { BUNDLE_PRODUCT_URL,BASE_URL } from "../../constants/api";
import {BundleDetail} from '../../actions/BundleAction'
import Autosuggest from 'react-autosuggest';
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import ReactCrop from "react-image-crop";
import RichTextEditor from 'react-rte';

import CircularProgress from "@material-ui/core/CircularProgress";
import {
    title,
    youtubeUrl

} from "../../constants/Validate";
const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
    required = false,
}) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (


        <div className="form-group">
            <label className="text-sm">{label}</label>
            {required ? <span className="requiredField">*</span> : ""}
            <div>
                <input
                    {...input}
                    placeholder={label}
                    type={type}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};


class BundleProductUpdate extends Component {

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.BundleDetail(id);
    }

    state = {
        gallery_uploaded_images:[],
        featureFile:null,
        value: '',
        totalAmount: 0,
        totalQuantity: 0,
        products: [],
        oderList: [],
        regular_price: '',
        product_summary: RichTextEditor.createEmptyValue(),
        subtotal: 0,
        details:false,
        gallery_images: [
            {
              index: Math.random(),
              image: null,
            },
          ],
        galleries: [
        { image: null, }
        ],
        src: null,
        crop: {
        unit: "%",
        width: 40,
        aspect: 16/14
        },
    };

    deteteGalleryImage = this.deteteGalleryImage.bind(this);
    handleChangeColoImage = this.handleChangeColoImage.bind(this);
    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
          const reader = new FileReader();
          reader.addEventListener("load", () =>
            this.setState({ src: reader.result })
          );
          reader.readAsDataURL(e.target.files[0]);
        }
      };
      onImageLoaded = (image) => {
        this.imageRef = image;
      };
    
      onCropComplete = (crop) => {
        this.makeClientCrop(crop);
      };
      onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
      };
      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          console.log(crop)
          const croppedImageUrl = await this.getCroppedImg(
            this.imageRef,
            crop,
            "newFile.jpeg"
          );
          this.setState({ croppedImageUrl });
        }
      }
    
      getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error("Canvas is empty");
              return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.setState({ featureFile: blob })
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve(this.fileUrl);
          }, "image/jpeg");
        });
      }

      onChangeProductSummary = (product_summary) => {
        this.setState({ product_summary });
        if (this.props.onChange) {
          // Send the changes up to the parent component as an HTML string.
          // This is here to demonstrate using `.toString()` but in a real app it
          // would be better to avoid generating a string on each change.
          this.props.onChangeProductSummary(
            product_summary.toString('html')
          );
        }
      };

      handleChangeColoImage(e) {
        let gallery_images = [...this.state.gallery_images];
        gallery_images[e.target.dataset.id] = {
          ...gallery_images[e.target.dataset.id],
          image: URL.createObjectURL(e.target.files[0]),
        };
    
        let galleries = [...this.state.galleries]
    
        galleries[e.target.dataset.id] = {
          image: e.target.files[0]
        };
        this.setState({ gallery_images: gallery_images, galleries: galleries });
      }

      addGalleryImage = (e) => {
        this.setState((prevState) => ({
          gallery_images: [
            ...prevState.gallery_images,
            {
              index: Math.random(),
    
              file: "",
            },
          ],
        }));
      };
    static getDerivedStateFromProps(nextProps, prevState) {
    //  alert(JSON.stringify(nextProps.bundles))
        if (nextProps.is_getting === true) {
          if (nextProps.is_getting !== prevState.details) {
            return {
                oderList: nextProps.bundles.product_list ? nextProps.bundles.product_list : null,
                details: true,
                product_summary:RichTextEditor.createValueFromString(nextProps.bundles.product_summary,'html'),
                totalQuantity: nextProps.bundles.total_quantity,
                totalAmount: nextProps.bundles.total_price,
                gallery_uploaded_images:nextProps.bundles.gallery_images,
                

            }
          }
        }
    }          
    deteteRow = (product) => {
        var products = this.state.oderList.filter((r) => r !== product)
        this.setState({
            oderList: products,
            totalQuantity: sumTotalQuantity(products),
            totalAmount: sumTotalAmount(products)
        });
    }
    deteteGalleryImage(record) {
        this.setState({
          gallery_images: this.state.gallery_images.filter((r) => r !== record),
        });
      }
      deteteUploadGalleryImage(record) {

        this.setState({
          gallery_uploaded_images: this.state.gallery_uploaded_images.filter((r) => r !== record),
        });
        this.props.deleteGallryImage(record.id)
      }
    onChnagePrice = index => (e) => {
        let orders = [...this.state.oderList]
        orders[index].regular_price = e.target.value;
        orders[index].subtotal = orders[index].quantity * Number(e.target.value);
        this.setState({ [e.target.name]: e.target.value, oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders) })
    }

    manageQuantity = (index, type = "plus") => {
        let orders = [...this.state.oderList]
        if (type == "plus") {
            orders[index].quantity = orders[index].quantity + 1;
            orders[index].subtotal = orders[index].regular_price * orders[index].quantity;

        } else {
            if (orders[index].quantity > 1) {
                orders[index].quantity = orders[index].quantity - 1;
                orders[index].subtotal = orders[index].regular_price * orders[index].quantity;

            }
        }
        this.setState({ oderList: orders, totalAmount: sumTotalAmount(orders), totalQuantity: sumTotalQuantity(orders) })
    }




    onSuggestionsFetchRequested = ({ value }) => {
        //alert(value)
        this.setState({
            products: this.getSuggestions(value)
        });
    };
    onSuggestionsClearRequested = () => {
        this.setState({
            products: []
        });
    };
    getSuggestionValue = product => '';

    getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        this.props.searchProducts(value);
        return inputLength === 0 ? [] : this.props.products.filter(product =>
            product.product_name.toLowerCase().slice(0, inputLength) === inputValue
        );
    };
    renderSuggestion = product => (
        <div>
            {product.product_name}
        </div>
    );

    // OnChange event handler
    onChange = (event, { newValue }) => {
        //alert(newValue)
        this.setState({
            value: newValue
        });
    };

    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

        let { oderList } = this.state;
        suggestion.quantity = 1
        suggestion.subtotal = Number(suggestion.quantity) * Number(suggestion.regular_price)
        var index = oderList.findIndex(x => x.id == suggestion.id);

        // console.log(suggestion);
        if (index === -1) {
            oderList.push(suggestion)
        } else {
            oderList[index].quantity = oderList[index].quantity + 1;
            oderList[index].subtotal = Number(oderList[index].quantity) * Number(oderList[index].regular_price);
        }
        this.setState({ totalAmount: sumTotalAmount(oderList), totalQuantity: sumTotalQuantity(oderList) })
    };
    submit(event) {
        const {

            featureFile,
            totalAmount,
            totalQuantity,
            oderList,

        } = this.state;
        var form_data = new FormData();
        form_data.append('title',event.title);
        form_data.append("youtube_url", event.youtube_url ? event.youtube_url : '');
        form_data.append("product_summary", this.state.product_summary.toString('html') ? this.state.product_summary.toString('html') : '');
        form_data.append('product_list',JSON.stringify(oderList));
        form_data.append('total_quantity',totalQuantity);
        form_data.append('total_product_price',totalAmount);

        // var data = {
        //     'title': event.title,
        //     'product_list': JSON.stringify(oderList),
        //     'total_quantity': totalQuantity,
        //     'total_product_price': totalAmount,
        // }
        // alert(JSON.stringify(data))
        if (featureFile != null && featureFile !="" && featureFile !=undefined) {
            let newImage = new File([featureFile], featureFile.name, { type: featureFile.type });
            form_data.append("feature_image", newImage, newImage.name);
          }

        this.props.updateDatas(form_data, BUNDLE_PRODUCT_URL+ this.props.match.params.id+'/', "Bundle Product ")
        setTimeout(()=>{
            window.location.href="/bundle-product-list"
        },3000)
    }


    render() {
        const { handleSubmit, pristine, reset, payments, submitting, }
            = this.props;
        const {
            src,
            crop,
            gallery_images,
            croppedImageUrl,
            value,
            totalAmount,
            totalQuantity,
            oderList,
            regular_price,
        } = this.state;

        const inputProps = {
            placeholder: 'Type Product Name or Product Sku Here',
            value,
            type: "search",
            onChange: this.onChange
        };
        if (this.props.is_added) {
            setTimeout(function () {
                window.location.href = '/bundle-product-list'
            },
                3000);
        }
        // alert(JSON.stringify(this.props.bundles))

        return (

            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Bundle Product </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link className="text-info" to="/">
                                            Dashboard
                    </Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Update Bundle Product
                  </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="row">
                    <div className="col-12">
                        <form
                            onSubmit={handleSubmit(this.submit.bind(this))}
                        // onChange={this.handleChangeForm}
                        >
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Update Bundle Product</h3>
                                    <div className="float-right">
                                        <Link
                                            to="/bundle-product-list"
                                            className="btn btn-primary float-right text-white"
                                        >
                                            <b>
                                                <i className="fa fa-backward"></i>
                                                            Back To List
                                            </b>
                                        </Link>
                                    </div>
                                </div>

                                <div style={{ margin: "15px" }} className="card-tools">

                                    <div className="col-md-12">
                                        <Field
                                            name="title"
                                            type="text"
                                            component={renderField}
                                            label="Title"
                                            validate={title}
                                            // warn={alphaNumeric}
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <Field
                                        name="youtube_url"
                                        type="text"
                                        component={renderField}
                                        label="Youtube Url"
                                        validate={youtubeUrl}
                                        // warn={alphaNumeric}
                                        required={true}
                                        />
                                    </div>
                                    <div class="col-md-12">
                                    
                                    <label>Product Summary</label>
                                    <RichTextEditor
                                        // toolbarConfig={toolbarConfig}
                                        value={this.state.product_summary}
                                        onChange={this.onChangeProductSummary}
                                    />
                                    </div>
                                    <div className="col-md-12">

                                    <label>Feature Image</label>
                                    <br />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={this.onSelectFile}
                                    />
                                    </div>
                                    <div className="col-md-6">
                                    {src && (
                                        <ReactCrop
                                        src={src}
                                        crop={crop}
                                        ruleOfThirds
                                        onImageLoaded={this.onImageLoaded}
                                        onComplete={this.onCropComplete}
                                        onChange={this.onCropChange}
                                        />
                                    )}
                                    </div>
                                    <div className="col-md-6">
                                    {croppedImageUrl && (
                                        <img
                                        alt="Crop"
                                        style={{ maxWidth: "100%" }}
                                        src={croppedImageUrl}
                                        height="150px"
                                        width="150px"
                                        />
                                    )}
                                    </div>
                                    <div className="col-md-12">
                                    <strong>Uploaded Feature image</strong>
                                    <img
                                        alt="Uploaded Image"
                                        style={{ maxWidth: "100%" }}
                                        src={this.props.bundles.feature_image}
                                        height="100px"
                                        width="100px"
                                    />

                                    </div>

                                    <div className="col-md-12">
                                    <label>Gallery Images</label>
                                    <br />

                                    {gallery_images.map((images, idx) => (
                                        <div key={images.index}>
                                        <input
                                            type="file"
                                            onChange={this.handleChangeColoImage}
                                            data-id={idx}
                                            id={"file" + idx}
                                            name="file"
                                        />
                                        { images.image ? (
                                            <img
                                            src={images.image}
                                            height="40px"
                                            width="40px"
                                            data-id={idx}
                                            id={"img" + idx}
                                            alt="No Image"
                                            />
                                        ) : null
                                        }
                                            &nbsp;
                                        <span>
                                            {idx === 0 ? (
                                            <button
                                                onClick={() => this.addGalleryImage()}
                                                type="button"
                                                className="btn btn-primary text-center"
                                            >
                                                <i
                                                className="fa fa-plus-circle"
                                                aria-hidden="true"
                                                />
                                                    Add More Images
                                            </button>
                                            ) : (
                                                <button
                                                className="btn btn-danger"
                                                onClick={() => this.deteteGalleryImage(images)}
                                                >
                                                <i
                                                    className="fa fa-minus-circle"
                                                    aria-hidden="true"
                                                />
                                                    Remove Image
                                                </button>
                                            )}
                                        </span>
                                        </div>

                                    ))}
                                    <div>

                                        {this.state.gallery_uploaded_images ? (
                                        this.state.gallery_uploaded_images.map((gallery_image, idx) => (
                                            <div key={idx} className="col-md-6">
                                            <strong>Uploaded Gallery Images</strong>
                                            <div className="col-md-4">
                                                <img
                                                src={BASE_URL + gallery_image.image}
                                                height="100px"
                                                width="100px"
                                                data-id={idx}
                                                id={"img" + idx}
                                                alt="No Image"
                                                /><span> <button
                                                className="btn btn-danger"
                                                onClick={() => this.deteteUploadGalleryImage(gallery_image)}
                                                >
                                                <i
                                                    className="fa fa-minus-circle"
                                                    aria-hidden="true"
                                                />
                                            Remove Image
                                            </button></span>
                                            </div>
                                            </div>

                                        ))) : null
                                        }
                                    </div>
                                    </div>
                                </div>
                                <div>
                                    <div className="col-md-12">
                                        <label>Type Your Product</label>
                                        <span className="requiredField">*</span>
                                        <Autosuggest
                                            suggestions={this.props.products ? this.props.products : []}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                                            getSuggestionValue={this.getSuggestionValue}
                                            renderSuggestion={this.renderSuggestion}
                                            inputProps={inputProps}
                                            onSuggestionSelected={this.onSuggestionSelected}
                                        />
                                    </div><br />
                                    {oderList.length > 0 ? (
                                        <>
                                            <div className="col-md-12">
                                                <strong>Product List</strong>
                                            </div>
                                            <div className="col-md-12">
                                                <table className="table orderlist">
                                                    <tr>
                                                        <th>Image</th>
                                                        <th>Product Name</th>
                                                        <th>Product Sku</th>

                                                        <th className="text-center">Quantity</th>
                                                        <th>Price</th>
                                                        {/* <th>Discount</th> */}
                                                        <th>Subtotal</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tbody>
                                                        {this.state.oderList.map((product, index) => (

                                                            <tr id={product.id} key={index}>
                                                                <td>
                                                                    <img src={product.feature_image} height="100px" width="100px" alt="No Image" />
                                                                </td>
                                                                <td>
                                                                    {product.product_name}
                                                                </td>
                                                                <td>
                                                                    {product.product_sku}
                                                                </td>

                                                                <td>

                                                                    <div class="input-group plus-minus-input">
                                                                        <div class="input-group-button">
                                                                            <button type="button" class="btn btn-success" onClick={() => this.manageQuantity(index, "minus")}>
                                                                                <i class="fa fa-minus-circle" aria-hidden="true"></i>
                                                                            </button>
                                                                        </div>
                                                                        <input class="input-group-field form-control" type="number" name="quantity" value={product.quantity} />
                                                                        <div class="input-group-button">
                                                                            <button type="button" class="btn btn-primary" onClick={() => this.manageQuantity(index, "plus")}>
                                                                                <i class="fa fa-plus-circle" aria-hidden="true"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <input class="form-control" type="number" onChange={this.onChnagePrice(index)} name="regular_price" value={product.regular_price} />
                                                                </td>
                                                               
                                                                <td colSpan="1">
                                                                    {product.subtotal ? product.subtotal : 0}
                                                                </td>
                                                                <td>
                                                                    <button
                                                                        className="btn btn-danger" id={product.id}
                                                                        onClick={() => this.deteteRow(product)}
                                                                    >
                                                                        <i
                                                                            className="fa fa-trash"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </button>

                                                                </td>
                                                            </tr>
                                                        ))}
                                                        <tr>
                                                            <th colSpan="3" className="">Total</th>
                                                            <th colSpan="1" className="text-center">{totalQuantity}</th>
                                                            <th colSpan="2" className="text-right">{totalAmount}</th>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    ) : null
                                    }

                                </div>
                                {/* <!-- /.card-header --> */}
                                {/* <div id="modalRender">

                        </div> */}

                                <div className="card-footer">
                                    {oderList.length > 0 ? (
                                        <button
                                            type="submit"
                                            className="btn btn-success float-right"
                                            disabled={submitting}
                                        >
                                            <i className="fa fa-save "></i>Update Bundle Product
                                        </button>
                                    ) : null

                                    }
                                </div>
                            </div>
                        </form>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
            </div>
        );
    }
}

BundleProductUpdate.propTypes = {
    searchProducts: PropTypes.func.isRequired,
    updateDatas: PropTypes.func.isRequired,
    deleteGallryImage:PropTypes.func.isRequired,
    BundleDetail:PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    initialValues: state.products.products,
    bundles:state.bundles.bundles,
    products: state.products.products,
    is_getting: state.bundles.is_getting,
    is_updated: state.products.is_updated,
    initialValues: state.bundles.bundles,

});


BundleProductUpdate = reduxForm({
    form: "BundleUpdateProductForm",
    enableReinitialize: true

})(BundleProductUpdate);
export default connect(mapStateToProps, { searchProducts, updateDatas,BundleDetail,deleteGallryImage })(BundleProductUpdate);

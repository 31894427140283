import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { updateData,addData } from '../../../actions/ProductAction';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import { fetchLogo,fetchFooterLogo } from '../../../actions/LogoAction';

import { toast } from "react-toastify";
import { LOGO_URL,FOOTER_LOGO_URL, SELLER_URL } from "../../../constants/api";
import { getLuminance } from '@material-ui/core';


momentLocalizer(moment);



const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class LogoList extends Component {
    state = {

        id: 0,
        logo: null,
        get_logo: false,
        get_footer_logo:false,
        footer_logo:null,
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        // alert(JSON.stringify(nextProps.footer_logos))

        if (nextProps.is_getting === true) {
            // if (nextProps.is_getting !== prevState.get_logo && nextProps.is_getting !== prevState.get_footer_logo) {
                // var logo = nextProps.logos[0]
                return {

                    // id: nextProps.logos[0] ? nextProps.logos[0].id : '',
                    logo:nextProps.logos[0] ? nextProps.logos[0].logo :'',
                    footer_logo:nextProps.footer_logos[0]? nextProps.footer_logos[0].logo:'',
                    get_logo: true,
                    get_footer_logo:true
                }
            // }
        }
        return null
    }
    onChangeText = (e) => {

        this.setState({ [e.target.name]: e.target.value })

    }
    componentDidMount() {
        // alert(JSON.stringify(this.props.logos.logo))
        this.props.fetchLogo(1)
        this.props.fetchFooterLogo(1)

    }


    submit(event) {
        console.log(event)
        var form_data = new FormData();
        if (event.images != null && event.images !=='') {

            form_data.append('logo', event.images, event.images.name)
        }
        // alert(JSON.stringify(event))
        this.props.addData(form_data, LOGO_URL , "Logo")
        // this.props.fetchLogo(1)



    }

    Footersubmit(event) {
        console.log(event)
        var form_data = new FormData();
        if (event.images != null && event.images !=='') {

            form_data.append('logo', event.images, event.images.name)
        }
        // alert(JSON.stringify(event))
        this.props.addData(form_data, FOOTER_LOGO_URL , "Footer Logo")
        // this.props.fetchLogo(1)



    }

    handleImageChange = (e) => {
        let logo = e.target.files[0]
        // let get_logo = URL.createObjectURL(logo)
        this.setState({
            logo: logo,
            // get_logo: get_logo
        })
    };
    render() {
        const { handleSubmit, submitting,permissions } = this.props;
        if (this.props.is_added) {
            setTimeout(function () {

                window.location.href = '/logo-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Logo </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Logo</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
            {CheckPermisionAction(permissions,'Appearance','view_logo_list')?

                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Header Logo</h3>
                        {/* <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div> */}
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">


                                <div className="col-md-12">
                                    <img src={this.state.logo ? this.state.logo : ''} height="100px" width="100px" alt='' />



                                    <br /><br />
                                    {/* <div className="col-md-6">
                                        <label>Logo</label>
                                        <input
                                            // name='logo'
                                            type="file"
                                            accept="image/*"
                                            className="form-control"
                                            label="Logo Name"
                                            validate={required}
                                            onChange={this.handleImageChange}
                                        />

                                    </div> */}
                                </div>
                                <div className="col-md-6">
                                    
                                    <Field
                                        name="images"
                                        type="file"
                                        component={FileInput}
                                        accept="image/*"
                                        label=" Logo"
                                    // validate={[required]}
                                    />
                                </div>









                            </div>


                        </div>
                        <div className="card-footer text-right">
                            <div>
                        {CheckPermisionAction(permissions,'Appearance','logo_save')?

                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>
                        :null}

                            </div>
                        </div>
                    </form>
                </div>
                :null}

            {/* Start Footer Logo */}

            {CheckPermisionAction(permissions,'Appearance','view_footer_logo_list')?

                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Footer Logo</h3>
                        {/* <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div> */}
                    </div>
                    <form onSubmit={handleSubmit(this.Footersubmit.bind(this))}>
                        <div className="card-body">
                            <div className="row">


                                <div className="col-md-12">
                                    <img src={this.state.footer_logo ? this.state.footer_logo : ''} height="100px" width="100px" alt='' />



                                    <br /><br />
                                    {/* <div className="col-md-6">
                                        <label>Logo</label>
                                        <input
                                            // name='logo'
                                            type="file"
                                            accept="image/*"
                                            className="form-control"
                                            label="Logo Name"
                                            validate={required}
                                            onChange={this.handleImageChange}
                                        />

                                    </div> */}
                                </div>
                                <div className="col-md-6">
                                    
                                    <Field
                                        name="images"
                                        type="file"
                                        component={FileInput}
                                        accept="image/*"
                                        label=" Footer Logo"
                                    // validate={[required]}
                                    />
                                </div>









                            </div>


                        </div>
                        <div className="card-footer text-right">
                            <div>
                        {CheckPermisionAction(permissions,'Appearance','footer_logo_save')?

                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>
                        :null}

                            </div>
                        </div>
                    </form>
                </div>
                :null}

                {/* End Footer Logo */}


            </div>

        )
    }
}


LogoList.propTypes = {
    // getRides:PropTypes.func.isRequired,
    updateData: PropTypes.func.isRequired,
    fetchLogo: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    logos: state.logos.logos,
    footer_logos: state.footer_logos.footer_logos,

    is_added: state.products.is_added,
    is_getting: state.logos.is_getting,
    is_getting: state.footer_logos.is_getting,

    permissions:state.userpermissions.userpermissions
    // initialValues: state.logos.logo,

});


LogoList = reduxForm({
    form: 'LogoList',
    // enableReinitialize: true

}
)(LogoList);


export default connect(mapStateToProps, { fetchLogo,fetchFooterLogo, addData })(LogoList);

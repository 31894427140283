import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { updateDatas } from '../../../actions/CustomerActions';
import { CAMPAIGN_URL } from '../../../constants/api'
import 'react-toastify/dist/ReactToastify.css';
// import moment from 'react-moment'
import moment from 'moment'
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import Modal from 'react-modal';

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

import { fetchCampaigns, deleteCampaign } from '../../../actions/CampaignActions';
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class CampaignList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
        this.handleStatusValues = this.handleStatusValues.bind(this);

    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        campaign_title: "",
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
    };
    handleStatusValues = i => e => {
        let campaigns = [...this.props.campaigns]
        var id = Number(campaigns[i].id)
        let data ={
            'status':e.target.value
        }
        this.props.updateDatas(data, CAMPAIGN_URL+id+"/",  "Status")

    }


    componentDidMount() {
        this.props.fetchCampaigns(0);
    }

    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchCampaigns(pageNo)
    }
    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.campaign_title + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteCampaign(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.campaign_title + '.',
                        'success'
                    )
                }
            }
        })
    }


    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { campaign_title } = this.state;
        console.log([campaign_title]);

        this.props.fetchCampaigns('', this.state);
        this.setState({ hideButton: true })

    };

    render() {
        const page = Math.ceil(this.props.count / 10)
        const {permissions} = this.props
        const isAdmin = this.props.auth.user.is_admin;


        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Campaign List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Campaign list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Campaign List</h3>
                                <div className="float-right">

                                {CheckPermisionAction(permissions,'Campaign','add_campaign')?

                                    <Link to="/add-campaign" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Campaign</b></Link>
                                    
                                :null}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="campaign_title" type="text"
                                            onChange={this.onChange} placeholder="Campaign Title"
                                            aria-label="Search" />
                                {CheckPermisionAction(permissions,'Campaign','campaign_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                :null}
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'Campaign','view_campaign_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Campaign Title</th>
                                            <th>Total Sellers</th>
                                            <th>Total No of Products</th>
                                            <th>From Date</th>
                                            <th>To Date</th>
                                            <th>Status</th>
                                            <th>Option</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/campaign-list" onClick={() => { window.location.href = "/campaign-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> :this.props.campaigns.length != undefined && this.props.campaigns.map((campaign, index) => (
                                            <tr key={campaign.id}>
                                                <td>{index + 1}</td>
                                                <td>{campaign.campaign_title}</td>
                                                
                                                <td>{campaign.total_seller}</td>
                                                <td>{campaign.products_count}</td>
                                                
                                                <td>{moment(campaign.from_date).format("DD-MM-YYYY hh:mm A")}</td>
                                                <td>{moment(campaign.to_date).format("DD-MM-YYYY hh:mm A")}</td>
                                                <td>
                                                    <select key={index}
                                                        name="status"
                                                        component="select"
                                                        className="form-control"

                                                        onChange={this.handleStatusValues(index)}
                                                    >
                                                        <option value="1" selected={campaign.status=== 1 ? true : false} >Activated</option>
                                                        <option value="0" selected={campaign.status=== 0 ? true : false}>Deactivated</option>

                                                    </select>
                                                </td>
                                                {/* <td className="btn-group btn-group-sm">


                                                    <Link to={'/campaign-update/' + campaign.id}>
                                                        <button className="btn btn-info text-white">
                                                            <i className="fas fa-edit text-white" ></i>
                                                        </button>
                                                    </Link>

                                                    <button className="btn btn-danger"
                                                        // onClick={this.props.RegDirectorConfirm.bind(this, rgd.id, rgd.is_active)}
                                                        onClick={() => this.opensweetalert(campaign)}>
                                                        <i className="fas fa-trash" />
                                                    </button>

                                                </td> */}
                                                <td className="btn-group btn-group-sm">

                                                    <div class="btn-group">
                                                {CheckPermisionAction(permissions,'Campaign','campaign_options')?

                                                        <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>

                                                :null}
                                                        <div class="dropdown-menu">
                                                            <a href={'/campaign-update/' + campaign.id} class="dropdown-item">Edit</a>
                                                            <div class="dropdown-divider"></div>

                                                            <a href="#" onClick={() => this.opensweetalert(campaign)} class="dropdown-item">Delete</a>

                                                        </div>
                                                    </div>
                                                </td>

                                                {/* <td>
                                                    <Link to={'/customer-detail/' + customer.id}>
                                                        <button className="btn btn-info">
                                                            <i className="fas fa-eye text-white" >View</i>
                                                        </button>
                                                    </Link>

                                                    <Link to={'/customer-update/' + customer.id}>
                                                        <button className="btn btn-warning text-white">
                                                            <i className="fas fa-edit text-white" >Edit</i>
                                                        </button>
                                                    </Link>
                                                    {isAdmin ?
                                                        <button className="btn btn-danger" onClick={() => this.opensweetalert()}><i className="fas fa-trash" >Delete</i>
                                                        </button> : " "}

                                                </td> */}
                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

CampaignList.propTypes = {
    fetchCampaigns: PropTypes.func.isRequired,
    deleteCampaign: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    campaigns: state.campaigns.campaigns,
    count: state.campaigns.count,
    next: state.campaigns.next,
    is_loading: state.campaigns.is_loading,
    is_updated: state.customers.is_updated,

    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchCampaigns, deleteCampaign,updateDatas })(CampaignList);

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required } from '../../constants/Validate'
import { connect } from "react-redux";
import { addBrands, fetchBrands } from "../../actions/BrandsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';


import { toast } from "react-toastify";
import { GET_BRANDS_URL } from "../../constants/api";


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

const submitToServer = (data) => {

    console.log('data', data);

    let form_data = new FormData();

    form_data.append('name', data.name);
    if (data.image) {
        form_data.append('image', data.image, data.image.name);
    }

    // var form_data = { "name": data.name };

    // console.log('data', form_data);


    let url = GET_BRANDS_URL;

    let token = localStorage.getItem('token');
    // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
    console.log(token);
    axios.post(url, form_data,
        {
            headers: {
                'content-type': 'application/json',
                'Authorization': `Token ${token}`
            }

        })
        .then(res => {
            console.log(res.data);
            // dispatch(reset('PartnerAddForm'))
            toast.success('Successfully added a new Brands.')
        })
        .catch(err => console.log(err))
};


// Input Type
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};

// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddBrands extends Component {

    componentDidMount() {

        this.props.fetchBrands(0)
    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }

    submit(values) {
        const { reset } = this.props;
        return sleep(1000)
            .then(() => {
                submitToServer(values);
                reset()
            })
    }

    render() {

        // if (this.props.is_added) {
        //     setTimeout(() => {
        //         window.location.href = '/brands-list'

        //     }, 3000);
        // }

        const { handleSubmit, submitting } = this.props;
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Brands </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Add Brands</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Brands Information</h3>
                        <div className="float-right">
                            <Link to="/brands-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="Brands Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Band Logo</label>
                                    <Field
                                        name="image"
                                        type="file"
                                        component={FileInput}
                                        accept="image/*"
                                        label="Band Logo"
                                    // validate={[required]}
                                    />
                                </div>
                                {/* <div className="col-md-12">
                                    <label>Band Logo Image</label>
                                    <br />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={this.onSelectFile}
                                    />
                                </div> */}

                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddBrands.propTypes = {

    fetchBrands: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

    brands: state.brands.brands,
    // is_added: state.brands.is_added

});


AddBrands = reduxForm({
    form: 'AddBrands',
}
)(AddBrands);


export default connect(mapStateToProps, { addBrands, fetchBrands })(AddBrands);

import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { toast } from "react-toastify";
import { VIRTUAL_PRODUCT_URL } from "../../constants/api";
import { Link } from "react-router-dom";
import Select from "react-select";
// import InputColor from "react-input-color";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { addData } from '../../actions/ProductAction';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPrimaryCategoryForSelect } from "../../actions/PrimaryCategoryAction"
import { getChildCategoryForSelect } from "../../actions/ChildCategoryAction"
import { getSecondaryCategoryForSelect } from "../../actions/SecondaryCategoryAction"
import { getAttribute } from '../../actions/AttributeActions';
import { getAttributeOption, getAttributeOptionColor, getAttributeOptionSize } from '../../actions/AttributeOptionActions';
import { fetchSellersForSelect } from '../../actions/SellerActions';
import { getProductTagsForSelect } from '../../actions/TagsActions';
import { fetchBrandsAll } from '../../actions/BrandsActions';
import RichTextEditor from 'react-rte';
import { getProductTaxsForSelect } from "../../actions/TaxActions"


import {
    productName,
    productSKU,
    purchasePrice,
    regularPrice,
    dateTime,
    SingleIdSelect,
    required
} from "../../constants/Validate";

import DateTimePicker from 'react-widgets/lib/DateTimePicker'


const alphaNumeric = (value) =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? "Only alphanumeric characters"
        : undefined;


// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
  <div>
    <label className="text-sm">{label}</label>
    <DateTimePicker
      onChange={onChange}
      format="DD-MM-YYYY hh:mm A"
      time={showTime}
      value={!value ? null : new Date(value)}
      placeholder={label}
    />
  </div>;



const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
    required = false,
}) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            {required ? <span className="requiredField">*</span> : ""}
            <div>
                <input
                    {...input}
                    placeholder={label}
                    type={type}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};

// Text area type
const renderTextArea = ({
    input,
    label,
    meta: { touched, error, warning },
}) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea
                    {...input}
                    placeholder={label}
                    rows="3"
                    cols="40"
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class AddDigitalProduct extends Component {
    deteteRow = this.deteteRow.bind(this);
    deteteGalleryImage = this.deteteGalleryImage.bind(this);
    static propTypes = {
        onChangeDetails: PropTypes.func
    };
    state = {
        errorPrimary: false,
        errorSecondary: false,
        errorvendor: false,
        errorChild: false,
        errorTags: false,
        errorTaxs: false,
        allowProductSeo: false,
        featureFile: null,
        is_upload_file: false,
        selected_tags: null,
        selected_taxs: null,
        galleries: [
            { image: null, }
        ],
        src: null,
        crop: {
            unit: "%",
            width: 40,
            aspect: 16 / 14,
        },
        vendor: "",
        selected_primary: null,
        primary: null,
        selected_sceondary: null,
        secondary: null,
        selected_child: null,
        child: null,
        product_details: RichTextEditor.createEmptyValue(),
        // product_return_policy: RichTextEditor.createEmptyValue(),
        gallery_images: [
            {
                index: Math.random(),
                image: null,
            },
        ],
    };
    UploadFileChange = (e) => {
        // const { is_color } = this.state
        // alert(JSON.stringify(e.target.value))
        let value = e.target.value

        if (value === "1") {
            this.setState({ is_upload_file: false })
        } else {
            this.setState({ is_upload_file: true })
        }

    }

    onChangeDetails = (product_details) => {
        this.setState({ product_details });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChangeDetails(
                product_details.toString('html')
            );
        }
    };

    onTagsChange = (selected_tags) => {
        this.setState({ selected_tags });
    };
    onTaxsChange = (selected_taxs) => {
        this.setState({ selected_taxs });
    };
    // onChangePolicy = (product_return_policy) => {
    //     this.setState({ product_return_policy });
    //     if (this.props.onChange) {
    //         // Send the changes up to the parent component as an HTML string.
    //         // This is here to demonstrate using `.toString()` but in a real app it
    //         // would be better to avoid generating a string on each change.
    //         this.props.onChangePolicy(
    //             product_return_policy.toString('html')
    //         );
    //     }
    // };
    // Features Images Add Methods

    onSelectFile = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };
    onImageLoaded = (image) => {
        this.imageRef = image;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };
    componentDidMount() {

        this.props.getPrimaryCategoryForSelect()
        this.props.getChildCategoryForSelect()
        this.props.getSecondaryCategoryForSelect()
        this.props.getAttributeOptionSize(1);
        this.props.getAttributeOptionColor(1);
        this.props.fetchSellersForSelect(1);
        this.props.getProductTagsForSelect("")
        this.props.getProductTaxsForSelect("")

        this.props.fetchBrandsAll(1);


    }

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            console.log(crop)
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.setState({ featureFile: blob })
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, "image/jpeg");
        });
    }

    //For Fesature Images End Functions




    addGalleryImage = (e) => {
        this.setState((prevState) => ({
            gallery_images: [
                ...prevState.gallery_images,
                {
                    index: Math.random(),

                    file: "",
                },
            ],
        }));
    };

    addNewRow = (e) => {
        this.setState((prevState) => ({
            colorDetails: [
                ...prevState.colorDetails,
                {
                    index: Math.random(),
                    color_name: "",
                    size: "",
                    quantity: "",
                    size_price: "",
                },
            ],
        }));
    };

    deteteRow(record) {
        this.setState({
            colorDetails: this.state.colorDetails.filter((r) => r !== record),
        });
    }
    deteteGalleryImage(record) {
        this.setState({
            gallery_images: this.state.gallery_images.filter((r) => r !== record),
        });
    }



    allowproductSeoChange = () => {
        this.setState({ allowProductSeo: !this.state.allowProductSeo });
    };

    primaryChange = (selected_primary) => {
        this.setState({ selected_primary });
        console.log(selected_primary)
    };
    secondaryChange = (selected_sceondary) => {
        this.setState({ selected_sceondary });
    };
    childChange = (selected_child) => {
        this.setState({ selected_child });
    };
    vendorChange = (vendor) => {
        this.setState({ vendor });
    };



    static getDerivedStateFromProps(nextProps, prevState) {

        console.log(nextProps)

    }

    submit(event) {
        const {
            allowProductSeo,
            vendor,
            featureFile,
            selected_primary,
            selected_sceondary,
            selected_child,
            galleries,
            selected_tags,
            selected_taxs,


        } = this.state;


        var form_data = new FormData();

        if (selected_primary == null || selected_primary == '') {
            this.setState({ errorPrimary: true })
            return false
        }
        
        // if (selected_child == null || selected_child == '') {
        //     this.setState({ errorChild: true })
        //     return false
        // }
        // if (selected_sceondary == null || selected_sceondary == '') {
        //     this.setState({ errorSecondary: true })
        //     return false
        // }
        // if (selected_tags === null || selected_tags === '') {
        //     this.setState({ errorTags: true })
        //     return false
        // }
        // if (selected_taxs === null || selected_taxs === '') {
        //     this.setState({ errorTaxs: true })
        //     return false
        // }
        if (vendor == null || vendor == '') {
          this.setState({ errorvendor: true })
          return false
        }
        for (let i = 0; i < galleries.length; i++) {

            let image = galleries[i].image
            if (image != null) {
                form_data.append(`gallery_images[]`, image, image.name)
            }
        }
        if (selected_tags != null) {

            form_data.append("tags", JSON.stringify(selected_tags));

        }
        if (selected_taxs != null) {

            form_data.append("taxs", JSON.stringify(selected_taxs));

        }
        if (selected_primary != null) {

            form_data.append("primary_categories", JSON.stringify(selected_primary));
            this.setState({ errorPrimary: false })
        }
        if (selected_sceondary != null) {
            form_data.append("secondary_categories", JSON.stringify(selected_sceondary));
            this.setState({ errorSecondary: false })
        }
        if (selected_child != null) {
            form_data.append("child_categories", JSON.stringify(selected_child));
            this.setState({ errorChild: false })
        }
        if (vendor != null) {
          form_data.append("vendor", SingleIdSelect(vendor));
          this.setState({ errorvendor: false })
        }



        form_data.append("product_name", event.product_name ? event.product_name : '');
        form_data.append("product_sku", event.product_sku ? event.product_sku : '');
        form_data.append("upload_type", event.upload_type ? event.upload_type : '');
        form_data.append("virtual_file", event.virtual_file ? event.virtual_file : '');
        form_data.append("url_link", event.url_link ? event.url_link : '');
        form_data.append("youtube_url", event.youtube_url ? event.youtube_url : '');
        form_data.append("minimum_purchase_price", event.purchase_price ? event.purchase_price : 0);
        form_data.append('unit_amount', event.unit_amount?event.unit_amount:0);
        form_data.append("regular_price", event.regular_price ? event.regular_price : 0);
        form_data.append("discount_price", event.discount_price ? event.discount_price : 0);
        form_data.append("minimum_purchase_quantity", event.purchase_quantity ? event.purchase_quantity : 0);
        form_data.append("discount_type", event.discount_type ? event.discount_type : 3);
        if (event.discount_from_date != '' && event.discount_from_date != undefined) {
            form_data.append("discount_from_date", dateTime(event.discount_from_date));
          }
          if (event.discount_to_date != '' && event.discount_to_date != undefined) {
            form_data.append("discount_to_date", dateTime(event.discount_to_date));
          }


        form_data.append("alert_stock_quantity", event.alert_stock_quantity ? event.alert_stock_quantity : 0);
        form_data.append("is_feature_product", false);
        form_data.append("product_type", 2);
        // form_data.append("product_return_policy", this.state.product_return_policy.toString('html') ? this.state.product_return_policy.toString('html') : '');
        form_data.append("product_details", this.state.product_details.toString('html') ? this.state.product_details.toString('html') : '');

        form_data.append("brand", event.brand ? event.brand : '');
        form_data.append("stock_quantity", event.stock_quantity ? event.stock_quantity : 0);

        if (allowProductSeo) {
            form_data.append("is_product_seo", allowProductSeo);
            form_data.append("meta_tag", event.meta_tag ? event.meta_tag : '');
            form_data.append("meta_description", event.meta_description ? event.meta_description : '');
        }
        if (featureFile != null) {
            let newImage = new File([featureFile], featureFile.name, { type: featureFile.type });
            form_data.append("feature_image", newImage, newImage.name);
        }

        this.props.addData(form_data, VIRTUAL_PRODUCT_URL, "Virtual Product")


    }

    render() {

        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' }
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' }
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' }
            ]
        };
        const { handleSubmit, pristine, reset, tags, taxs, product_units, sellers, submitting, primarycategory, childcategory, secondarycategory, attributes, attribute_options }
            = this.props;
        const {
            vendor,
            allowProductSeo,
            src,
            crop,
            croppedImageUrl,
            selected_tags,
            gallery_images,
            selected_primary,
            selected_sceondary,
            selected_child,
            errorPrimary,
            errorSecondary,
            errorChild,
            errorvendor,
            selected_taxs,

        } = this.state;
        if (this.props.is_added) {
            setTimeout(function () {
                window.location.href = '/product-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Digital Product</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Add Digital Product</h3>
                        <div className="float-right">
                            <Link
                                to="/product-list"
                                className="btn btn-primary float-right text-white"
                            >
                                <b>
                                    <i className="fa fa-backward"></i>
                                            Back To List
                                    </b>
                            </Link>
                        </div>
                    </div>
                    <form
                        onSubmit={handleSubmit(this.submit.bind(this))}
                    // onChange={this.handleChangeForm}
                    >
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="product_name"
                                        type="text"
                                        component={renderField}
                                        label="Product Name"
                                        validate={productName}
                                        // warn={alphaNumeric}
                                        required={true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="product_sku"
                                        type="text"
                                        component={renderField}
                                        label="Product SKU"
                                        validate={productSKU}
                                        // warn={alphaNumeric}
                                        required={true}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label>Primary Category</label>
                                    <span className="requiredField">*</span>
                                    <Select
                                        value={selected_primary}
                                        onChange={this.primaryChange}
                                        required={true}
                                        options={primarycategory}
                                        placeholder="Select Primary Category"
                                        isMulti
                                        isClearable
                                    />{errorPrimary ? <span className="text-danger">Select Primary Category</span> : ''}

                                </div>
                                <div className="col-md-6">
                                    <label>Secondary Category</label>
                                    {/* <span className="requiredField">*</span> */}
                                    <Select
                                        value={selected_sceondary}
                                        onChange={this.secondaryChange}
                                        placeholder="Secondary Category"
                                        options={secondarycategory}
                                        required={false}
                                        isMulti
                                        isClearable
                                    />
                                    {/* {errorSecondary ? <span className="text-danger">Select Secondary Category</span> : ''} */}
                                </div>
                                <div className="col-md-6">
                                    <label>Child Category</label>
                                    {/* <span className="requiredField">*</span> */}
                                    <Select
                                        value={selected_child}
                                        onChange={this.childChange}
                                        options={childcategory}
                                        placeholder="Child Category"
                                        isMulti
                                        isClearable
                                    />
                                    {/* {errorChild ? <span className="text-danger">Select Child Category</span> : ''} */}
                                </div>
                                <div className="col-md-6">
                                    <label>Select Upload Type</label>
                                    <span className="requiredField">*</span>
                                    <Field name="upload_type" component="select" onClick={this.UploadFileChange} className="form-control"
                                    >
                                        <option value="1"  >Upload by File </option>
                                        <option value="2">Upload by Link</option>

                                    </Field>
                                </div>
                                {!this.state.is_upload_file ?
                                    <>
                                        <div className="col-md-6">
                                            <label>Select File</label>
                                            <Field
                                                name="virtual_file"
                                                type="file"
                                                component={FileInput}
                                                accept="image/*"
                                                label="Select File"
                                            // validate={[required]}
                                            />
                                        </div>

                                    </> : ''}
                                {this.state.is_upload_file ? <div className="col-md-6">
                                    <Field
                                        name="url_link"
                                        type="text"
                                        component={renderField}
                                        label="Link"
                                        validate={required}
                                        // warn={alphaNumeric}
                                        required={true}
                                    />
                                </div> : ''}
                                <div class="col-md-12">
                                    {/* <Field
                                        name="product_details"
                                        type="text"
                                        component={renderTextArea}
                                        label="Product Details"
                                    // required={true}
                                    /> */}
                                    <label>Product Details</label>
                                    <span className="requiredField">*</span>

                                    <RichTextEditor
                                        toolbarConfig={toolbarConfig}
                                        value={this.state.product_details}
                                        onChange={this.onChangeDetails}
                                    />
                                </div>

                                {/* <Field
                                        name="product_details"
                                        type="text"
                                        component={renderTextArea}
                                        label="Product Details"
                                        // required={true}
                                    /> */}
                                {/* <div class="col-md-12">
                                    <label>Product Return Policy</label>
                                    <span className="requiredField">*</span>

                                    <RichTextEditor
                                        toolbarConfig={toolbarConfig}
                                        value={this.state.product_return_policy}
                                        onChange={this.onChangePolicy}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <label>Merchent / vendor </label>
                                    <span className="requiredField">*</span>
                                    <Select
                                        value={vendor}
                                        onChange={this.vendorChange}
                                        options={sellers}
                                        component={renderField}
                                        placeholder="Merchent / vendor"
                                    />{errorvendor ? <span className="text-danger">Select  Vendor</span> : ''}
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="purchase_price"
                                        type="number"
                                        component={renderField}
                                        label="Purchase Price"
                                        // validate={purchasePrice}

                                        // required={true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="regular_price"
                                        type="number"
                                        component={renderField}
                                        label="Product Regular Price"
                                        // validate={regularPrice}
                                        // warn={alphaNumeric}
                                        // required={true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Discount Type</label>
                                    {/* <span className="requiredField">*</span> */}
                                    <Field name="discount_type" component="select" className="form-control"
                                    >
                                        <option value="">Select Discount Type</option>
                                        <option value="1">Percentage (%)</option>
                                        <option value="2">Flat Discount (BDT)</option>
                                        <option value="3">No Discount</option>

                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field name="discount_price" component={renderField} className="form-control"
                                        label="Discount Amount" />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="youtube_url"
                                        type="text"
                                        component={renderField}
                                        label="Youtube Video URL"
                                        // validate={required}
                                        // warn={alphaNumeric}
                                        // required={true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Product Tags</label>

                                    <Select
                                        value={selected_tags}
                                        onChange={this.onTagsChange}
                                        options={tags}
                                        placeholder="Product Tags"
                                        isMulti
                                        isClearable
                                    />
                                    {/* {this.state.errorTags ? <span className="text-danger">Select Product Tags</span> : ''} */}
                                </div>


                                <div className="col-md-6">
                                    <label>Product Brand</label>

                                    <Field
                                        name="brand"
                                        component="select"
                                        className="form-control"

                                    // onChange={this.handleSelectedValues(idx)}
                                    >
                                        <option value="">--Select Product Brand--</option>

                                        {this.props.brands.map((brand, index) => (
                                            <option key={index} value={brand.id}>{brand.name}</option>

                                        ))}
                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="unit_amount"
                                        type="number"
                                        component={renderField}
                                        label="Product Weight"
                                        warn={alphaNumeric}
                                    // required={true}
                                    />
                                    </div>
                                <div className="col-md-6">
                                    <label> Tax</label>

                                    <Select
                                        value={selected_taxs}
                                        onChange={this.onTaxsChange}
                                        options={taxs}
                                        placeholder="Tax"
                                        isMulti
                                        isClearable
                                    />
                                    {/* {this.state.errorTaxs ? <span className="text-danger">Select Product Taxs</span> : ''} */}
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="discount_price"
                                        type="number"
                                        component={renderField}
                                        label="Product Discount Price"
                                        warn={alphaNumeric}
                                    />
                                </div> */}

                                <div className="col-md-6">
                                    <Field
                                        showTime={false}
                                        
                                        name="discount_from_date"
                                        type="date"
                                        component={renderDateTimePicker}
                                        label="Product Discount From"
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        showTime={false}

                                        name="discount_to_date"
                                        type="date"
                                        component={renderDateTimePicker}
                                        label="Product Discount To"
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="purchase_quantity"
                                        type="number"
                                        component={renderField}
                                        label="Purchase Quantity"
                                        warn={alphaNumeric}
                                    // required={true}
                                    />
                                </div> */}
                                 <div className="col-md-6">
                                <Field
                                    name="stock_quantity"
                                    type="number"
                                    component={renderField}
                                    label="Stock Quantity"
                                    warn={alphaNumeric}
                                // required={true}
                                />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="alert_stock_quantity"
                                        type="number"
                                        component={renderField}
                                        label="Alert Stock Quantity"
                                        warn={alphaNumeric}
                                    // required={true}
                                    />
                                </div>
                               
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id="product-seo"
                                            value={allowProductSeo}
                                            onChange={this.allowproductSeoChange}
                                        />
                                        <label className="form-check-label" htmlFor="product-seo">
                                            <strong> Allow Product SEO </strong>
                                        </label>
                                        {allowProductSeo ? (
                                            <div className="col-md-12">
                                                <div className="col-md-6">
                                                    <Field
                                                        name="meta_tag"
                                                        type="text"
                                                        component={renderField}
                                                        label="Meta Tag"
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <Field
                                                        name="meta_description"
                                                        type="text"
                                                        component={renderTextArea}
                                                        label="Meta Description"
                                                    />
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <label>Feature Image</label>
                                    <br />
                                    <input
                                        type="file"
                                        accept="image/*"
                                        onChange={this.onSelectFile}
                                    />
                                </div>
                                <div className="col-md-6">
                                    {src && (
                                        <ReactCrop
                                            src={src}
                                            crop={crop}
                                            ruleOfThirds
                                            onImageLoaded={this.onImageLoaded}
                                            onComplete={this.onCropComplete}
                                            onChange={this.onCropChange}
                                        />
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {croppedImageUrl && (
                                        <img
                                            alt="Crop"
                                            style={{ maxWidth: "100%" }}
                                            src={croppedImageUrl}
                                            height="150px"
                                            width="150px"
                                        />
                                    )}
                                </div>
                                <div className="col-md-12">
                                    <label> Product Gallery Images</label>
                                    <br />

                                    {gallery_images.map((images, idx) => (
                                        <div key={images.index}>
                                            <input
                                                type="file"
                                                onChange={this.handleChangeColoImage}
                                                data-id={idx}
                                                id={"file" + idx}
                                                name="file"
                                            />
                                            { images.image ? (
                                                <img
                                                    src={images.image}
                                                    height="40px"
                                                    width="40px"
                                                    data-id={idx}
                                                    id={"img" + idx}
                                                    alt="No Image"
                                                />
                                            ) : null
                                            }
                      &nbsp;
                                            <span>
                                                {idx === 0 ? (
                                                    <button
                                                        onClick={() => this.addGalleryImage()}
                                                        type="button"
                                                        className="btn btn-primary text-center"
                                                    >
                                                        <i
                                                            className="fa fa-plus-circle"
                                                            aria-hidden="true"
                                                        />
                            Add More Images
                                                    </button>
                                                ) : (
                                                        <button
                                                            className="btn btn-danger"
                                                            onClick={() => this.deteteGalleryImage(images)}
                                                        >
                                                            <i
                                                                className="fa fa-minus-circle"
                                                                aria-hidden="true"
                                                            />
                                                            Remove Image
                                                        </button>
                                                    )}
                                            </span>
                                        </div>
                                    ))}
                                </div>

                            </div>
                            <div>

                            </div>
                        </div>

                        <div className="card-footer">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success float-right"
                                    disabled={submitting}
                                >
                                    <i className="fa fa-save "></i>Submit
                </button>
                                {/* <button
                  type="button"
                  className="btn btn-danger"
                  disabled={pristine || submitting}
                  onClick={reset}
                >
                  Clear
                </button> */}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

AddDigitalProduct.propTypes = {
    addProduct: PropTypes.func.isRequired,
    products: PropTypes.array.isRequired,
    getPrimaryCategoryForSelect: PropTypes.func.isRequired,
    getChildCategoryForSelect: PropTypes.func.isRequired,
    getSecondaryCategoryForSelect: PropTypes.func.isRequired,
    getAttribute: PropTypes.func.isRequired,
    fetchSellersForSelect: PropTypes.func.isRequired,
    getProductTagsForSelect: PropTypes.func.isRequired,
    fetchProductUnitALL: PropTypes.func.isRequired,
    fetchBrandsAll: PropTypes.func.isRequired,


};

const mapStateToProps = state => ({
    products: state.products.products,
    is_added: state.products.is_added,
    primarycategory: state.primarycategory.primary_categories,
    childcategory: state.childcategory.child_categories,
    secondarycategory: state.secondarycategory.secondary_categories,
    sizes: state.attribute_options.size,
    tags: state.tags.tags,
    taxs: state.taxs.taxs,
    sellers: state.sellers.sellers,
    brands: state.brands.brands,


});
AddDigitalProduct = connect(
    mapStateToProps,
    {
        addData,
        getPrimaryCategoryForSelect,
        getChildCategoryForSelect,
        getSecondaryCategoryForSelect,
        getAttribute,
        getAttributeOption,
        fetchSellersForSelect,
        getAttributeOptionColor,
        getAttributeOptionSize,
        getProductTagsForSelect,
        getProductTaxsForSelect,
        fetchBrandsAll,

    }
)(AddDigitalProduct)

export default reduxForm({
    form: "AddDigitalProduct",
})(AddDigitalProduct);

import {
    LOADING_LOGO,
    FETCH_LOGO,
    DETAIL_LOGO,
    DELETE_LOGO,
    UPDATE_LOGO,
    NOT_FOUND_LOGO,
    LOADING_FOOTER_LOGO,
    FETCH_FOOTER_LOGO,
    DETAIL_FOOTER_LOGO,
    DELETE_FOOTER_LOGO,
    UPDATE_FOOTER_LOGO,
    NOT_FOUND_FOOTER_LOGO,
    LOADING_FAVICON,
    FETCH_FAVICON,
    DETAIL_FAVICON,
    DELETE_FAVICON,
    UPDATE_FAVICON,
    NOT_FOUND_FAVICON,
    LOADING_PRELOADER,
    FETCH_PRELOADER,
    DETAIL_PRELOADER,
    DELETE_PRELOADER,
    UPDATE_PRELOADER,
    NOT_FOUND_PRELOADER,



} from '../actions/types';

const initialState = {
    logos: [],
    logo: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
};

export const LogoReducer = (state = initialState, action)=> {
    switch (action.type) {
        case LOADING_LOGO:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_LOGO:
            return {
                ...state,
                logos: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                is_getting: true
            };
        case UPDATE_LOGO:
            return {
                ...state,
                logos: action.payload,
                is_loading: false
            };
        case DETAIL_LOGO:
            return {
                ...state,
                is_getting: true,
                logos: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_LOGO:
            return {
                ...state,
                logos: state.logos.filter(logo => logo.id !== action.payload)
            };


        case NOT_FOUND_LOGO:
            return {
                ...state,
                logos: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const FooterLogoReducer = (state = {
    footer_logos: [],
    footer_logo: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
}, action)=> {
    switch (action.type) {
        case LOADING_FOOTER_LOGO:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_FOOTER_LOGO:
            return {
                ...state,
                footer_logos: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                is_getting: true
            };
        case UPDATE_FOOTER_LOGO:
            return {
                ...state,
                footer_logos: action.payload,
                is_loading: false
            };
        case DETAIL_FOOTER_LOGO:
            return {
                ...state,
                is_getting: true,
                footer_logos: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_FOOTER_LOGO:
            return {
                ...state,
                footer_logos: state.footer_logos.filter(logo => logo.id !== action.payload)
            };


        case NOT_FOUND_FOOTER_LOGO:
            return {
                ...state,
                footer_logos: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}




export const FaviconReducer =(state = {
            favicons: [],
            favicon: {},
            count: null,
            next: null,
            is_added: false,
            is_loading: false,
            is_getting: false,
            is_updated: false,


}
    
    , action)=> {
    switch (action.type) {
        case LOADING_FAVICON:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_FAVICON:
            return {
                ...state,
                favicons: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                is_getting: true
            };
        case UPDATE_FAVICON:
            return {
                ...state,
                favicons: action.payload,
                is_loading: false
            };
        case DETAIL_FAVICON:
            return {
                ...state,
                is_getting: true,
                favicons: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_FAVICON:
            return {
                ...state,
                favicons: state.favicons.filter(favicon => favicon.id !== action.payload)
            };


        case NOT_FOUND_FAVICON:
            return {
                ...state,
                favicons: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}





export const PreLoaderReducer =(state = {
            preloaders: [],
            preloader: {},
            count: null,
            next: null,
            is_added: false,
            is_loading: false,
            is_getting: false,
            is_updated: false,


        }

        , action)=> {
        switch (action.type) {
            case LOADING_PRELOADER:
                return {
                    ...state,
                    is_loading: true
                };
            case FETCH_PRELOADER:
                return {
                    ...state,
                    preloaders: action.payload,
                    count: action.count,
                    next: action.next,
                    is_loading: false,
                    is_getting: true
                };
            case UPDATE_PRELOADER:
                return {
                    ...state,
                    preloaders: action.payload,
                    is_loading: false
                };
            case DETAIL_PRELOADER:
                return {
                    ...state,
                    is_getting: true,
                    preloaders: action.payload,
                    //        director_detail: action.payload,
                };
            case DELETE_PRELOADER:
                return {
                    ...state,
                    preloaders: state.preloader.filter(preloader => preloader.id !== action.payload)
                };


            case NOT_FOUND_PRELOADER:
                return {
                    ...state,
                    preloaders: [],
                    count: 0,
                    next: 0,
                    is_loading: false
                };
            default:
                return state;
            }
            }
import {
    FETCH_SUBSCRIBER,
    LOADING_SUBSCRIBER,
    FETCH_SUBSCRIBER_FAILED,
NOT_FOUND_SUBSCRIBER,
} from '../actions/types';

const initialState = {
    subscribers: [],
    subscriber: {},
    count: null,
    next: null,
    is_loading: false,
    is_fetching:false,
    errors: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_SUBSCRIBER:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_SUBSCRIBER:
            return {
                ...state,
                subscribers: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                is_fetching:true
            };
        case FETCH_SUBSCRIBER_FAILED:
            return {
                ...state,
                subscribers: [],
                count: 0,
                next: 0,
                is_loading: false,

            };
        case NOT_FOUND_SUBSCRIBER:
                return {
                    ...state,
                    subscribers: [],
                    count: 0,
                    next: 0,
                    is_loading: false,
    
                };
        default:
            return state;
    }
}
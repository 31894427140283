import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";
import { required, match,phoneNumber } from '../../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { addData } from '../../../actions/ProductAction';
import { getAgents,searchAgent } from '../../../actions/AgentActions';
import Autosuggest from 'react-autosuggest';
import {renderField} from "../../../constants/FormInputType"

import { SELLER_URL } from "../../../constants/api";


momentLocalizer(moment);


// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
// const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
//     const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
//     return (
//         <div className="form-group">
//             <label className="text-sm">{label}</label>
//             <div>
//                 <input {...input} placeholder={label} type={type} className={className} />
//                 {touched &&
//                     ((error && <span className="text-danger">{error}</span>) ||
//                         (warning && <span>{warning}</span>))}
//             </div>
//         </div>
//     )
// };
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddSeller extends Component {

    state={
        payment_type:"1",
        agent_id:'',
        agent_suggesion:'',
        verified: false,
        agents:[]
    }

    // this.props.getRides(0)


    allowVerfied = () => {
        this.setState({ verified: !this.state.verified });
      };

    submit(event) {
            var form_data = new FormData();
            Object.keys(event).forEach(key => form_data.append(key, event[key]));
            form_data.append('payment_type',this.state.payment_type)
            form_data.append('agent_id',this.state.agent_suggesion)
            // event['agent_id']=this.state.agent_suggesion

        //    alert(event['agent_id']=this.state.agent_suggesion)

            if (this.state.verified){
                form_data.append('verified',this.state.verified)
            }         
            // alert(JSON.stringify(form_data))   
            // console.log('e',event)
            // console.log('fr',form_data)
            this.props.addData(form_data, SELLER_URL, "Seller",false)

    }
    onChangePaymentType = (e) => {

        this.setState({[e.target.name]:e.target.value})
        // alert(e.target.value)
    
    }

    componentDidMount() {
        
        
        this.props.getAgents()
        
       
    }
    onChangeAgent = (event, { newValue }) => {
        // alert(JSON.stringify(newValue))
        this.setState({
          agent_id: newValue
        });
        // alert(this.state.customer_name)
      };
    getAgentSuggestions = agent_id => {
        if (agent_id !== undefined) {
          const inputValue = agent_id.trim().toLowerCase();
          const inputLength = inputValue.length;
          this.props.searchAgent(agent_id);
          return inputLength === 0 ? [] : this.props.agents.filter(agent =>
            agent.agent_id.toLowerCase().slice(0, inputLength) === inputValue
          );
        }
      };
    
      onSuggestionsAgentClearRequested = () => {
        this.setState({
          agents: []
        });
      };
      getSuggestionAgentValue = agent => agent.agent_id;
    
      onSuggestionsFetchRequestedAgent = ({ value }) => {
    
        this.setState({
          agents: this.getAgentSuggestions(value)
        });
      };
    
      onSuggestionSelectedAgent = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
//    alert(suggestion.id)
        setTimeout(()=>{
                this.setState({ agent_suggesion: suggestion.id })
            // alert(this.state.agent_suggesion)
        },1000)
        
      };
      renderSuggestionAgent = agent => (
        // alert(JSON.stringify(customer))
        <div>
          {agent.agent_id}
        </div>
      );
    render() {

        const { handleSubmit, submitting } = this.props;
        const {verified,agent_id} = this.state
        
        if (this.props.is_added) {
            setTimeout(function () {
                window.location.href = '/seller-list'
            },
                3000);
        }
        const inputAgentProps = {
            placeholder: 'Type Your Agent ID',
            value: agent_id,
            type: "search",
            name: 'textBoxValue',
            onChange: this.onChangeAgent
          };
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Seller </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Add Seller</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Seller Information</h3>
                        <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="first_name"
                                        type="text"
                                        component={renderField}
                                        label="First Name"
                                       validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="last_name"
                                        type="text"
                                        component={renderField}
                                        label="Last Name"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Mobile No"
                                       validate={[required,phoneNumber]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                     //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="password1"
                                        type="password"
                                        component={renderField}
                                        label="Password"
                                    validate={required}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="password"
                                        type="password"
                                        component={renderField}
                                        label="Retype Password"
                                        validate={[required, match("password1")]}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Select Agent</label>
                                <span className="requiredField">*</span>
                                <Autosuggest
                                    suggestions={this.props.agents ? this.props.agents : []}
                                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedAgent}
                                    onSuggestionsClearRequested={this.onSuggestionsAgentClearRequested}
                                    getSuggestionValue={this.getSuggestionAgentValue}
                                    renderSuggestion={this.renderSuggestionAgent}
                                    inputProps={inputAgentProps}

                                    onSuggestionSelected={this.onSuggestionSelectedAgent}
                                    validate={required}
                                    required
                                />

                                </div>
                                
                                <div className="col-md-6">
                                    <Field
                                        name="shop_name"
                                        type="text"
                                        component={renderField}
                                        label="Shop Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                
                                {/* <div className="col-md-6">
                                    <Field
                                        name="commision"
                                        type="number"
                                        component={renderField}
                                        label="Commision"
                                       validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <Field
                                        name="shop_address"
                                        type="text"
                                        component={renderTextArea}
                                        label="Shop Address"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="shop_detail"
                                        type="text"
                                        component={renderTextArea}
                                        label="Shop Details"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="shop_trade_license"
                                        type="text"
                                        component={renderField}
                                        label="Shop Trade License No"
                                      //  validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Shop Logo</label>
                                    <Field
                                        name="shop_logo"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="pload Shop Logo"
                                    // validate={[required]}
                                    />

                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Cover Photo</label>
                                    <Field
                                        name="shop_image"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Upload Shop Image"
                                   //  validate={[required]}
                                    />

                                </div>
                                <div className="col-md-12 form-group clearfix" >
                                <div className="col-md-6 icheck-primary d-inline">
                                    <input type="radio" id="radioPrimary1" onChange={this.onChangePaymentType} value="1" name="payment_type" checked={this.state.payment_type==="1"}/>
                                    <label for="radioPrimary1">Bank Account 
                                    </label>
                                </div>
                                <div className="col-md-6 icheck-primary d-inline">
                                    <input type="radio" id="radioPrimary2" value="2" onChange={this.onChangePaymentType} name="payment_type" checked={this.state.payment_type==="2"}/>
                                    <label for="radioPrimary2">Mobile Wallet
                                    </label>
                                </div>
                                
                                </div>
                                {this.state.payment_type==="1" ? (
                               <>     
                                <div className="col-md-6">
                                    <Field
                                        name="account_name"
                                        type="text"
                                        component={renderField}
                                        label="Account Name"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="account_number"
                                        type="text"
                                        component={renderField}
                                        label="Account Number"
                                     //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="bank_name"
                                        type="text"
                                        component={renderField}
                                        label="Bank Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="branch_name"
                                        type="text"
                                        component={renderField}
                                        label="Branch Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="routing_number"
                                        type="number"
                                        component={renderField}
                                        label="Routing Number"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                </>
                                ):
                                <>
                                <div className="col-md-6">
                                    <label>Mobile Banking</label>
                                    <Field name="mobile_banking_type" component="select" className="form-control"
                                    >
                                    <option value="">Select Mobile Banking</option>
                                    <option value="1">Bkash</option>
                                    <option value="2">Nogod</option>
                                    <option value="3">Rocket</option>

                                </Field>
                                </div>
                                  <div className="col-md-6">
                                    <Field
                                        name="wallet_number"
                                        type="text"
                                        component={renderField}
                                        label="Number"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                    </div>
                                 </>   
                                }
                            </div>
                            <div className="form-check">
                                <input
                                type="checkbox"
                                className="form-check-input"
                                id="allow-product-color"
                                value={verified}
                                onChange={this.allowVerfied}
                                />
                            <label className="form-check-label" htmlFor="allow-product-size">
                            <b>Verified</b>
                            </label>
                        </div>

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddSeller.propTypes = {
    addData: PropTypes.func.isRequired,
    getAgents: PropTypes.func.isRequired,
    searchAgent: PropTypes.func.isRequired,


};

const mapStateToProps = state => ({
    is_added: state.products.is_added,
    tags: state.tags.tags,
    agents:state.agents.agents

});


AddSeller = reduxForm({
    form: 'AddSeller',
}
)(AddSeller);


export default connect(mapStateToProps, { addData,getAgents, addTags,searchAgent })(AddSeller);

import {
    LOADING_PRODUCT_UNIT,
    GETTING_PRODUCT_UNIT,
    DETAIL_PRODUCT_UNIT,
    UPDATE_PRODUCT_UNIT,
    DELETE_PRODUCT_UNIT,
    NOT_FOUND_PRODUCT_UNIT,
    ADD_PRODUCT_UNIT
} from '../actions/types';

const initialState = {
    product_units: [],
    product_unit: {},
    count: null,
    next: null,
    is_getting_product_unit: false,
    is_loading: false,
    is_added: false,
    is_updated: false,
    errors: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_PRODUCT_UNIT:
            return {
                ...state,
                is_getting_product_unit: false,
                is_loading: true
            };
        case GETTING_PRODUCT_UNIT:
            return {
                ...state,
                product_units: action.payload,
                count: action.count,
                next: action.next,
                is_getting_product_unit: true,
                is_loading: false
            };
        case DETAIL_PRODUCT_UNIT:
            return {
                ...state,
                is_getting_product_unit: false,
                product_unit: action.payload,

            };
        case UPDATE_PRODUCT_UNIT:

            var filteredDataSource = state.product_units.filter((item) => {
                if (item.id === action.payload.id) {
                    item.name = action.payload.name;
                }

                return item;
            });
            console.log(filteredDataSource)
            return {
                ...state,
                is_getting_product_unit: false,
                product_units: filteredDataSource,
                is_loading: false,
                is_updated: true
            };
        case ADD_PRODUCT_UNIT:


            return {
                ...state,
                is_getting_product_unit: false,
                product_units: state.product_units,
                is_loading: false,
                is_added: true
            };
        case DELETE_PRODUCT_UNIT:
            return {
                ...state,
                is_getting_product_unit: false,
                product_units: state.product_units.filter(product_unit => product_unit.id !== action.payload)
            };

        case NOT_FOUND_PRODUCT_UNIT:
            return {
                ...state,
                product_units: [],
                count: 0,
                next: 0,
                is_getting_product_unit: false,
                is_loading: false,

            };
        default:
            return state;
    }
}
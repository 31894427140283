import {
    LOADING_BUNDLE,
    FETCH_BUNDLE,
    DETAIL_BUNDLE,
    DELETE_BUNDLE,
    ADD_DATA,
    UPDATE_BUNDLE,
    NOT_FOUND_BUNDLE,
    UPDATED_DATA,

    
} from '../actions/types';

const initialState = {
    bundles: [],
    bundle: {},
    count: null,
    next: null,
    is_getting: false,
    is_added: false,
    is_loading: false,
    is_updated: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_BUNDLE:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_BUNDLE:
            return {
                ...state,
                bundles: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                is_getting:true
            };
        // case UPDATE_BUNDLE:
        //     return {
        //         ...state,
        //         bundles: action.payload,
        //         is_loading: false
        //     };
        case DETAIL_BUNDLE:
            return {
                ...state,
                is_getting: true,
                bundles: action.payload,
                //        director_detail: action.payload,
            };
        // case DELETE_BUNDLE:
        //     return {
        //         ...state,
        //         bundles: state.bundles.filter(bundle => bundle.id !== action.payload)
        //     };
        // case ADD_DATA:
        //     return {
        //         ...state,
        //         is_added: true,
        //         bundles: [action.payload]
        //     };
            
        // case UPDATED_DATA:
        //     return {
        //         ...state,
        //         is_updated: true,
               
        //     };
    
        case NOT_FOUND_BUNDLE:
            return {
                ...state,
                bundles: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

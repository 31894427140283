import {
    LOADING_CONTACT_INFO,
    FETCH_CONTACT_INFO,
    DETAIL_CONTACT_INFO,
    DELETE_CONTACT_INFO,
    UPDATE_CONTACT_INFO,
    NOT_FOUND_CONTACT_INFO,



} from '../actions/types';

const initialState = {
    contacts_info: [],
    contact_info: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_CONTACT_INFO:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_CONTACT_INFO:
            return {
                ...state,
                contacts_info: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case UPDATE_CONTACT_INFO:
            return {
                ...state,
                contacts_info: action.payload,
                is_loading: false,
                is_updated:true
            };
        case DETAIL_CONTACT_INFO:
            return {
                ...state,
                is_getting: true,
                contacts_info: action.payload,
                is_loading:true
                //        director_detail: action.payload,
            };
        case DELETE_CONTACT_INFO:
            return {
                ...state,
                contacts_info: state.contacts_info.filter(contact_info => contact_info.id !== action.payload)
            };


        case NOT_FOUND_CONTACT_INFO:
            return {
                ...state,
                contacts_info: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

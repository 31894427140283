import {
    LOADING_WITHDRAW,
    FETCH_WITHDRAW,
    DETAIL_WITHDRAW,
    DELETE_WITHDRAW,
    ADD_DATA,
    UPDATE_WITHDRAW,
    NOT_FOUND_WITHDRAW,
    UPDATED_DATA,


} from '../actions/types';

const initialState = {
    withdraws: [],
    withdraw: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_WITHDRAW:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_WITHDRAW:
            // alert(JSON.stringify(action.payload))
            return {
                ...state,
                withdraws: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case UPDATE_WITHDRAW:
            return {
                ...state,
                withdraws: action.payload,
                is_loading: false
            };
        case DETAIL_WITHDRAW:
            return {
                ...state,
                is_getting: true,
                withdraws: action.payload,
                //        director_detail: action.payload,
            };
        case DELETE_WITHDRAW:
            return {
                ...state,
                withdraws: state.withdraws.filter(withdraw => withdraw.id !== action.payload)
            };
        case ADD_DATA:
            return {
                ...state,
                is_added: true,
                withdraws: [action.payload]
            };

        case UPDATED_DATA:
            return {
                ...state,
                is_updated: true,
                // products: [action.payload]
            };

        case NOT_FOUND_WITHDRAW:
            return {
                ...state,
                withdraws: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

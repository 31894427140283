import {
    LOADING_BRANDS,
    FETCH_BRANDS,
    DETAIL_BRANDS,
    UPDATE_BRANDS,
    DELETE_BRANDS,
    NOT_FOUND_BRANDS,
    ADD_BRANDS,

} from './types';

import { GET_BRANDS_URL, GET_BRANDS_LOGO_URL, GET_BRANDS_ALL_URL } from '../constants/api';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { SearchURLGenerate } from '../constants/CustomMethod'


// Add Brands
export const addBrands = (data) => (dispatch, getState) => {

    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let form_data = new FormData();

    form_data.append('name', data.name);
    if (data.image) {
        form_data.append('image', data.image, data.image.name);
    }

    axios
        .post(GET_BRANDS_URL, data, { headers })
        .then(response => {
            alert(JSON.stringify(response.data))
            console.log(response.data)

            dispatch({
                type: ADD_BRANDS,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success('Brand added successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })

    // console.log('data', data);

    // // let form_data = {
    // //     'name': data.name,
    // //     'image': data.image

    // // }

    // let form_data = new FormData();
    // form_data.append('name', data.name);
    // if (data.image) {
    //     form_data.append('image', data.image, data.image.name);
    // }

    // let url = GET_BRANDS_URL;
    // let token = localStorage.getItem('token');
    // // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
    // console.log(token);
    // axios.post(url, form_data,
    //     {
    //         headers: {
    //             'content-type': 'multipart/form-data',
    //             'Authorization': `Token ${token}`
    //         }

    //     })
    //     .then(res => {
    //         toast.success("New Brands created successfully.");
    //         console.log(res.data);

    //     })
    //     .catch(err => {
    //         toast.error("Please try again.");
    //         console.log(err)
    //     })
};



// FETCH Brands

export const fetchBrands = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_BRANDS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        //  headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_BRANDS_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_BRANDS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_BRANDS });

    }
};


export const fetchBrandsAll = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_BRANDS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        //  headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name",]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_BRANDS_ALL_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
               
                dispatch({
                    type: FETCH_BRANDS,
                    payload: response.data ? response.data : [],
                    // count: response.data.count,
                    // next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_BRANDS });

    }
};
// export const fetchBrands = (pageNumber, id) => (dispatch, getState) => {
//     let headers = { "Content-Type": "application/json" };

//     let { token } = getState().auth;
//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//     }

//     dispatch({ type: LOADING_BRANDS });
//     var url = GET_BRANDS_URL

//     if (id) {
//         url = GET_BRANDS_URL + `${id}/`;
//     } else if (pageNumber) {
//         url = GET_BRANDS_URL + '?page=' + pageNumber
//     }
//     axios.get(url, { headers, })
//         .then(response => {

//             dispatch({
//                 type: FETCH_BRANDS,
//                 payload: response.data.results,
//                 count: response.data.count,
//                 next: response.data.next
//             })
//         })




// };



// Brands Detail
export const brandsDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_BRANDS_URL + `${id}/`;

    axios.get(url, { headers })
        .then(response => {

            dispatch({
                type: DETAIL_BRANDS,
                payload: response.data,
            })
        })
};



// Update Brands
export const updateBrands = (id, data) => (dispatch, getState) => {

    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    // let form_data = {
    //     'name': data.name,
    //     'image': data.image



    // }
    let form_data = new FormData();
    form_data.append('name', data.name);
    if (data.image) {
        form_data.append('image', data.image, data.image.name);
    }

    let url = GET_BRANDS_URL + `${id}/`;

    axios.put(url, form_data, { headers })
        .then(response => {
            console.log(response.data)
            dispatch({
                type: UPDATE_BRANDS,
                payload: response.data
            });

            toast.success("Brands successfully updated");
            console.log('Success');
        })
        .catch(err => {
            // dispatch();
            toast.error('Please try again', err);
            console.log(err);
        })
};


// Delete Brands
export const deleteBrands = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let url = GET_BRANDS_URL + `${id}/`
    axios
        .delete(url, { headers })
        .then(response => {
            dispatch({
                type: DELETE_BRANDS,
                payload: id,
            });
            toast.success('Brands deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import Modal from 'react-modal';
import isEqual from "react-fast-compare";
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'
import { fetchProducts, deleteProduct, uploadProductCSV,updateProductsList, BulkAction } from '../../../actions/ProductAction';

import { fetchSlideSetting, deleteSlideSetting } from '../../../actions/SlideSettingAction';
import { USER_CREATE_URL,PRODUCT_BULK_URL,SLIDE_SETTING_BULK_URL } from '../../../constants/api'

// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class SlideSettingList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        // user__phone_no: "",
        // user__email: "",
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
        allchecked: false,
        bulk:false,
        slide_settings:[],
        load:false
    };

    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchSlideSetting(pageNo)
    }

    componentDidMount() {
        this.props.fetchSlideSetting(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.title + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteSlideSetting(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.title + '.',
                        'danger'
                    )
                }
            }
        })
    }
    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = []

            for (var i = 0; i < this.state.slide_settings.length; i++) {
                if (this.state.slide_settings[i].checked) {
                    results.push(this.state.slide_settings[i].id)
                }
            }

            if (value === "1") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, SLIDE_SETTING_BULK_URL
                )
            } else if (value === "0") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, SLIDE_SETTING_BULK_URL
                )
            // } else if (value === "3") {
            //     this.props.BulkAction(
            //         { 'id': results, 'action': value }, PRODUCT_BULK_URL
            //     )
            // } else if (value === "4") {
            //     this.handleDiscountModalShow()


                // this.props.BulkAction(
                //     { 'id': results, 'action': value }, PRODUCT_BULK_URL
                // )
            }else {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, SLIDE_SETTING_BULK_URL
                )
            }
        }
    }

    // handleStatusValues = i => e => {
    //     let customers = [...this.props.customers]
    //     var id = Number(customers[i].id)
    //     let data = {
    //         'is_active': e.target.value
    //     }
    //     this.props.updateDatas(data, USER_CREATE_URL + id + "/", "Status")

    // }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    // onSubmit = e => {
    //     e.preventDefault();
    //     const { name } = this.state;
    //     console.log([name]);

    //     this.props.fetchCustomers('', this.state);
    //     this.setState({ hideButton: true })

    // };

    toggleAllChange=(e)=> {
        const { value, checked } = e.target;
        let slide_settings = [...this.props.slide_settings]
        slide_settings.forEach(product => product.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, slide_settings, bulk: true })
        } else {
            this.setState({ allchecked: checked, slide_settings, bulk: false })
        }
    }

    onSingleChange = index => (e) => {
        let slide_settings = [...this.state.slide_settings]
        // alert(slide_settings)
        slide_settings[index]["checked"] = e.target.checked
        let recent_length = slide_settings.filter(product => product.checked).length;

        if (slide_settings.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        this.setState({ slide_settings })

    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.is_list) {
     
            if (nextProps.is_list !== prevState.load) {
                var slide_settings = []
                for (var i = 0; i < nextProps.slide_settings.length; i++) {
                    Object.assign(nextProps.slide_settings[i], { 'checked': false });
                    slide_settings.push(nextProps.slide_settings[i])
                }
                if (!isEqual(nextProps.slide_settings, prevState.slide_settings)) {
                    return {

                        slide_settings:[...slide_settings],
                        load: true
                    }
                  }
                

            }
        }else return null;

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.sellers!==this.props.sellers){
          this.setState({sellers: this.props.sellers});
        }
    }
    render() {
        const page = Math.ceil(this.props.count / 10)
        const {permissions} = this.props
        const { show, bulk, allchecked, checkedvalues, products,discountshow } = this.state;


        const isAdmin = this.props.auth.user.is_admin;
        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/slide-setting-list'
            },
                1000);
        }
        
        if (this.props.is_updated) {
            setTimeout(function () {
                window.location.href = '/slide-setting-list'
            },
                1000);
        }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Slide Setting List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Slide Setting list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Slide Setting List</h3>
                                <div className="float-right">
                                    {/* <Link to="/add-customer" className="btn btn-info text-white"><b><i className="fa fa-upload"></i>
                                        Bulk Import</b></Link>&nbsp;
                                    <Link to="/add-customer" className="btn btn-warning text-white"><b><i className="fa fa-download"></i>
                                        Download CSV</b></Link>&nbsp; */}
                                    {CheckPermisionAction(permissions,'Appearance','add_slide_setting')?


                                    <Link to="/add-slide-setting" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Slide</b></Link>
                                    :null}
                                </div>
                            </div>
                            <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Bulk Action--</option>
                                            <option value="1" >Activated</option>
                                            <option value="0">Deactivated</option>
                                            <option value="2">Delete</option>
                                            

                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>

                            {/* <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="user__phone_no" type="text"
                                            onChange={this.onChange} placeholder="Phone No"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-4" name="user__email" type="text"
                                            onChange={this.onChange} placeholder="Email"
                                            aria-label="Search" />
                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    </form>
                                </div>


                            </div> */}
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'Appearance','view_slide_setting_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                        <th>
                                                <input type="checkbox"
                                                    checked={allchecked}
                                                    onChange={this.toggleAllChange}
                                                />
                                            </th>
                                            <th>SL</th>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Button Text</th>
                                            <th>Button Link</th>
                                            <th>Image</th>
                                            <th>Status</th>
                                            <th>Option</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/slide-setting-list" onClick={() => { window.location.href = "/slide-setting-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> : this.props.slide_settings.length != undefined && this.props.slide_settings.map((slide, index) => (
                                            <tr key={slide.id}>
                                                <td>
                                                        <input type="checkbox" onChange={this.onSingleChange(index)} checked={slide.checked ? true : false} value={slide.id} />
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{slide.title}</td>
                                                <td>{slide.description}</td>
                                                <td>{slide.button_text}</td>
                                                <td>{slide.button_link}</td>
                                                <td>{slide.image !== null ? (
                                                    <img src={slide.image} height="100px" width="100px" alt="No Image" />

                                                ) : null}</td>
                                                <td>{slide.is_active ? "Activated":"Deactivated"}</td>
                                                {/* <td>

                                                    <select key={index}
                                                        name="status"
                                                        component="select"
                                                        className="form-control"

                                                        onChange={this.handleStatusValues(index)}
                                                    >


                                                        <option value="1" selected={customer.is_active == 1 ? true : false} >Activated</option>
                                                        <option value="0" selected={customer.is_active == 0 ? true : false}>Deactivated</option>

                                                    </select>
                                                </td> */}
                                                <td className="btn-group btn-group-sm">

                                                    <div className="btn-group">
                                                    {CheckPermisionAction(permissions,'Appearance','slide_setting_options')?

                                                        <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                    :null}
                                                        <div className="dropdown-menu">
                                                            <a href={'/slide-setting-update/' + slide.id} className="dropdown-item">Edit</a>
                                                            <div className="dropdown-divider"></div>

                                                            {/* <a href="#" onClick={() => this.openDeclinealert("Set As Featued", customer)} class="dropdown-item">Set As Featued</a>
                                                            <a href="#" onClick={() => this.openDeclinealert("Approve", customer)} class="dropdown-item">Approve</a>
                                                            <a href="#" onClick={() => this.openDeclinealert("Decline", customer)} class="dropdown-item">Decline</a> */}
                                                            <a href="#" onClick={() => this.opensweetalert(slide)} className="dropdown-item">Delete</a>

                                                        </div>
                                                    </div>




                                                </td>
                                                {/* <td className="btn-group btn-group-sm">


                                                    <Link to={'/customer-update/' + customer.id}>
                                                        <button className="btn btn-info text-white">
                                                            <i className="fas fa-edit text-white" ></i>
                                                        </button>
                                                    </Link>

                                                    <button className="btn btn-danger"
                                                        // onClick={this.props.RegDirectorConfirm.bind(this, rgd.id, rgd.is_active)}
                                                        onClick={() => this.opensweetalert(customer)}>
                                                        <i className="fas fa-trash" />
                                                    </button>

                                                </td> */}
                                                {/* <td>
                                                    <Link to={'/customer-detail/' + customer.id}>
                                                        <button className="btn btn-info">
                                                            <i className="fas fa-eye text-white" >View</i>
                                                        </button>
                                                    </Link>

                                                    <Link to={'/customer-update/' + customer.id}>
                                                        <button className="btn btn-warning text-white">
                                                            <i className="fas fa-edit text-white" >Edit</i>
                                                        </button>
                                                    </Link>
                                                    {isAdmin ?
                                                        <button className="btn btn-danger" onClick={() => this.opensweetalert()}><i className="fas fa-trash" >Delete</i>
                                                        </button> : " "}

                                                </td> */}
                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

SlideSettingList.propTypes = {
    fetchSlideSetting: PropTypes.func.isRequired,
    deleteSlideSetting: PropTypes.func.isRequired,
    updateDatas: PropTypes.func.isRequired,
    BulkAction: PropTypes.func.isRequired,



};

const mapStateToProps = state => ({
    slide_settings: state.slide_settings.slide_settings,
    count: state.slide_settings.count,
    next: state.slide_settings.next,
    is_loading: state.slide_settings.is_loading,
    is_updated: state.slide_settings.is_updated,
    is_list:state.slide_settings.is_list,
    auth: state.auth,
    bulk: state.products.bulk,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchSlideSetting, deleteSlideSetting,BulkAction })(SlideSettingList);

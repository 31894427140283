import React, { Component } from "react";
// import { Field, reduxForm } from 'redux-form';
import ReactDOM from "react-dom";
import { Field, reduxForm } from "redux-form";
import {fetchGroups} from '../../../actions/GroupAction'
import { connect } from "react-redux";

// import axios from 'axios';

import { Link } from "react-router-dom";
import { ToastContainer ,toast} from 'react-toastify';
import {GROUP_WISE_PERMISSION} from '../../../constants/api'

class UserPermissionList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      allChecked: false,
      group: "",
      loadData: false,
      permissions: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.loadData !== props.is_fetching) {
      if (props.permissions.length > 0) {
        return {
          permissions: props.permissions,
          loadData: true,
        };
      }
    }

    return null;
  }

  onSubmit = () => {
    const { permissions, group } = this.state;
    if (group !== "") {
      var perm = [];
      for (var i = 0; i < permissions.length; i++) {
        for (var j = 0; j < permissions[i].names.length; j++) {
          if (permissions[i].names[j].checked) {
            perm.push(permissions[i].names[j].id);
          }
        }
      }
      if(perm.length>0){
            var data = { group_id: group, permissions: perm };
            this.props.createPermission(data)
      }else{
        toast.warn("Please set your pernmissions");
      } 
    } else {
      toast.warn("Select your group");
    }
  };

  onChangeText = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    this.props.onChangeGroup(GROUP_WISE_PERMISSION+e.target.value+'/',this.state.permissions)
    // alert(e.target.value)
  };

  handleChangePermission = (event, position) => {
    this.state.permissions[position].checked = event.target.checked;
    this.state.permissions[position].names.map(function (x) {
      x.checked = event.target.checked;
      return x;
    });
    if (event.target.checked) {
      let total_checked = this.state.permissions.filter(
        (item) => event.target.checked === item.checked
      ).length;
      if (total_checked === this.state.permissions.length) {
        this.setState({ allChecked: true });
      } else {
        this.setState({ allChecked: false });
      }
    } else {
      this.setState({ allChecked: false });
    }
    this.setState(this.state.permissions);
  };

  handleSingleChangePermission = (event, position, id) => {
    if (this.state.permissions[position].names.length > 0) {
      var total_length = this.state.permissions[position].names.length;
      var permissions = this.state.permissions[position].names;
      let existed_item = permissions.find((item) => id === item.id);
      existed_item.checked = event.target.checked;

      if (event.target.checked) {
        let total_checked = permissions.filter(
          (item) => event.target.checked === item.checked
        ).length;
        if (total_length == total_checked) {
          this.state.permissions[position].checked = event.target.checked;
        }
        let totalchecked = this.state.permissions.filter(
          (item) => event.target.checked === item.checked
        ).length;
        if (totalchecked === this.state.permissions.length) {
          this.setState({ allChecked: true });
        }
      } else {
        let total_unchecked = permissions.filter(
          (item) => event.target.checked === item.checked
        );
        this.state.permissions[position].checked = event.target.checked;
        this.setState({ allChecked: event.target.checked });
      }
    }
    this.setState(this.state.permissions);
  };

  handleAllPermission = (event) => {
    if (this.state.permissions.length > 0) {
      this.state.permissions.map(function (x) {
        x.checked = event.target.checked;
        x.names.map(function (y) {
          y.checked = event.target.checked;
          return y;
        });
        return x;
      });
    }
    this.setState({
      permissions: this.state.permissions,
      allChecked: event.target.checked,
    });
  };

  renderList = () => {
    return this.state.list.map((item) => (
      <div>
        <input
          key={item.id}
          type="checkbox"
          name={item.name}
          value={item.name}
          checked={item.isChecked}
          onChange={this.handleChange}
        />
        <label>{item.name}</label>
      </div>
    ));
  };
  
  render() {
    const { groups } = this.props;
    const { permissions } = this.state;
    return (
      <div>
        <ToastContainer />
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">User Role Permission</h1>
              </div>
              {/*<!-- /.col --> */}
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <a className="text-info" href="/">
                      Dashboard
                    </a>
                  </li>
                  <li className="breadcrumb-item active">User Role Permission</li>
                </ol>
              </div>
              {/*<!-- /.col --> */}
            </div>
            {/*<!-- /.row --> */}
          </div>
          {/*<!-- /.container-fluid --> */}
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">
              <div className="icheck-primary d-inline">
                <input
                  type="checkbox"
                  id="checkall"
                  name="checkAll"
                  checked={this.state.allChecked}
                  onChange={(event) => this.handleAllPermission(event)}
                />
                <label htmlFor="checkall">User Role Permission</label>
              </div>
            </h3>
            <div className="float-right">
              <Link to="/" className="btn btn-info float-right text-white">
                <b>
                  <i className="fas fa-arrow-left"></i> Back to Home
                </b>
              </Link>
            </div>
          </div>

          <div className="card-body">

          <div className="form-group clearfix">
            <div className="row">
              <div className="col-md-5">
                <strong>Select Required Permission</strong>
              </div>
              <div className="col-md-5">
                <Field
                  className="form-control"
                  component="select"
                  name="group"
                  type="select"
                  label="group"
                  onChange={this.onChangeText}
                >
                  <option value="">--Selected Your Group--</option>
                  {groups.map((group) => (
                    <>
                      {group.name.toLowerCase() !== "admin" ? (
                        <option value={group.id}>{group.name}</option>
                      ) : null}
                    </>
                  ))}
                </Field>
              </div>
            </div>
          </div>
          
        
          {permissions.map((permission, index) => (
            <>
            <div className="row" key={index}>
              <div className="col-md-4">
                <div className="icheck-primary d-inline">
                  <input
                    type="checkbox"
                    name={"permission" + index}
                    checked={permission.checked}
                    id={"admin" + index}
                    onChange={(event) =>
                      this.handleChangePermission(event, index)
                    }
                  />

                  <label htmlFor={"admin" + index} style={{ textTransform: 'capitalize'}}>
                    {permission.app_label}
                  </label>
                </div>
              </div>
           
             
            </div>
            <div  className="row" key={index}>
            {permission.names !==undefined && permission.names.map((code, codeindex) => (
                  <div className="col-md-4" key={codeindex}>
                    <div className="icheck-primary d-inline">
                      <input
                        type="checkbox"
                        checked={code.checked}
                        onChange={(event) =>
                          this.handleSingleChangePermission(
                            event,
                            index,
                            code.id
                          )
                        }
                        id={"admin" + code.id}
                        className="allcheck"
                      />
                      <label style={{ fontWeight: "normal" }} htmlFor={"admin" + code.id}>{code.name}</label>
                    </div>
                  </div>
                ))}
            </div>
            {/* <hr style={{height:"2px",borderWidth:"0",color:"gray",backgroundColor:"gray"}}/> */}
            <hr />
            </>
          ))}

          <div className="card-footer">
            <button
              type="submit"
              className=""
              onClick={this.onSubmit}
              className="btn btn-info float-right"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
      </div>
    );
  }
}

// UserPermissionList =reduxForm({
//   form:'UserPermissionList'}
// )(UserPermissionList)


// const mapStateToProps =state=>({
//   groups:state.groups.groups

// })

export default UserPermissionList;

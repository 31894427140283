import moment from 'moment'

export const required = value => (value || typeof value === 'number' ? undefined : 'Required');

export const firstName = value => (value || typeof value === 'number' ? undefined : 'First name must not be empty');


export const productName = value => (value || typeof value === 'number' ? undefined : 'Product name must not be empty');
export const title = value => (value || typeof value === 'number' ? undefined : 'Title name must not be empty');
export const youtubeUrl = value => (value || typeof value === 'number' ? undefined : 'Youtube Url name must not be empty');

export const lastName = value => (value || typeof value === 'number' ? undefined : 'Last name must not be empty');

export const productSKU = value => (value || typeof value === 'number' ? undefined : 'Product SKU must not be empty');

export const purchasePrice = value => (value || typeof value === 'number' ? undefined : 'Purchase Price must not be empty');
export const regularPrice = value => (value || typeof value === 'number' ? undefined : 'Regular Price must not be empty');


export const fatherName = value => (value || typeof value === 'number' ? undefined : 'Father\'s name must not be empty');

export const motherName = value => (value || typeof value === 'number' ? undefined : 'Mother\'s name must not be empty');

export const designation = value => (value || typeof value === 'number' ? undefined : 'Please enter a designation to the staff');

export const nidOrPassport = value => (value || typeof value === 'number' ? undefined : 'Nid or passport must not be empty');

export const presentAddress = value => (value || typeof value === 'number' ? undefined : 'Please enter the present address');

export const permanentAddress = value => (value || typeof value === 'number' ? undefined : 'Please enter the permanent address');

export const staffId = value => (value || typeof value === 'number' ? undefined : 'Please enter the staff ID');

export const agentId = value => (value || typeof value === 'number' ? undefined : 'Please enter the agent ID');

export const partnerId = value => (value || typeof value === 'number' ? undefined : 'Please enter the partner ID');

export const directorId = value => (value || typeof value === 'number' ? undefined : 'Please enter the director ID');

export const totalAmount = value => (value || typeof value === 'number' ? undefined : 'Please enter the amount');

export const maxLength = max => value =>
    value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const maxLength15 = maxLength(15);

export const minLength = min => value =>
    value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const minLength2 = minLength(2);

export const number = value =>
    value && isNaN(Number(value)) ? 'Must be a number' : undefined;

export const minValue = min => value =>
    value && value < min ? `Must be at least ${min}` : undefined;

export const minValue13 = minValue(13);

export const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
        ? 'Invalid email address'
        : undefined;

export const tooYoung = value =>
    value && value < 13
        ? 'You do not meet the minimum age requirement!'
        : undefined;

export const aol = value =>
    value && /.+@aol\.com/.test(value)
        ? 'Really? You still use AOL for your email?'
        : undefined;

export const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined;

export const phoneNumber = value =>
    //for bangladesh
    // /^(\+?880|0)1[13456789][0-9]{8}$/
    // value && !/^(0|[0-9][0-9]{10})$/i.test(value)
    value && !/^(\+?880|0)1[13456789][0-9]{8}$/i.test(value)
        ? 'Invalid phone number, must be 11 digits'
        : undefined;

export const match = matchName => (value, allValues, props) =>
    value !== allValues[matchName]
        ? 'Password is not match'
        : undefined;

export const phoneNo = value => (value || typeof value === 'number' ? undefined : 'Please enter a phone number');


export const dateTime = dateTime => (
    dateTime ? moment(dateTime).format("YYYY-MM-DD HH:mm:ss") : null
)

export const dateYMD = dateTime => (
    dateTime ? moment(dateTime).format("YYYY-MM-DD") : null
)

export const SingleIdSelect = values => (
    values != '' ? values.value : null
)


export const matched = matchName => (value, allValues, props) =>
    value !== allValues[matchName]
        ?` Password is not match ${matchName} `
        : undefined;
export const  strongRegex = new RegExp("^(?=.*[a-z])(?=.*[0-9])(?=.{8,})");
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { Link } from "react-router-dom";
import { required,phoneNumber, matched } from '../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { addData } from '../../actions/ProductAction';
import { AGENT_URL} from "../../constants/api";
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}


momentLocalizer(moment);
// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
  <div>
    <label className="text-sm">{label}</label>
    <DateTimePicker
      onChange={onChange}
      format="DD-MM-YYYY"
    //   time={showTime}
      time={false}
      value={!value ? null : new Date(value)}
      placeholder={label}
    />
  </div>;


// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddAgent extends Component {

  static propTypes = {
    onChangeDetails: PropTypes.func
  };

    state={
        payment_type:"1",
        verified: false,
        
        
        

    }

   
    componentDidMount() {
 
    }

    onChanged=(e)=>{
        
        [e.target.name]=e.target.value
}


    submit(event) {
            const {allowProductSize,cuisine_list,attributes,colorDetails} = this.state
            var form_data = new FormData();
            let date_of_birth = convert(event.date_of_birth);
            // Object.keys(event).forEach(key => form_data.append(key, event[key]));
            form_data.append('first_name',event.first_name);
            form_data.append('last_name',event.last_name !==undefined && event.last_name !==''? event.last_name:'');
            form_data.append('phone_no',event.phone_no);
            form_data.append('email',event.email !==undefined && event.email !==''? event.email:'');
            form_data.append('password',event.password);
            form_data.append('gender',event.gender !==undefined && event.gender !==''? event.gender:1);
            form_data.append('date_of_birth',date_of_birth);
            form_data.append('agent_type',event.agent_type);
            form_data.append('present_address',event.present_address);
            form_data.append('permanent_address',event.permanent_address);
            form_data.append('occupation',event.occupation !==undefined && event.occupation !==''? event.occupation:'');
            form_data.append('business_name',event.business_name !==undefined && event.business_name !==''? event.business_name:'');
            form_data.append('trade_license_no',event.trade_license_no !==undefined && event.trade_license_no !==''? event.trade_license_no:'');
            form_data.append('reference_name',event.reference_name);
            form_data.append('reference_phone_no',event.reference_phone_no);
            form_data.append('commission_type',event.commission_type !==undefined && event.commission_type !==''? event.commission_type:2);
            form_data.append('commission_amount',event.commission_amount !==undefined && event.commission_amount !==''? event.commission_amount:0);
            form_data.append('sales_target_amount',event.sales_target_amount !==undefined && event.sales_target_amount !==''? event.sales_target_amount:0);
            
            form_data.append('number_of_seller',event.number_of_seller);
            form_data.append('remarks',event.remarks !==undefined && event.remarks !==''? event.remarks:'');
            


            
            if (event.user_photo){
                form_data.append('user_photo',event.user_photo,event.user_photo.name)
                }
            if (event.nid_or_passport_file){
                form_data.append('nid_or_passport_file',event.nid_or_passport_file,event.nid_or_passport_file.name)
              }
            if (event.trade_license_file){
            form_data.append('trade_license_file',event.trade_license_file,event.trade_license_file.name)
            }
            //    alert(JSON.stringify(event))
            //    console.log(form_data)
            //    console.log(event)
            this.props.addData(form_data, AGENT_URL, "Agent")

            }
    
    render() {
        
        const { handleSubmit, submitting } = this.props;
       
        if (this.props.is_added) {
            setTimeout(function () {
                window.location.href = '/agent-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Agent </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Add Agent</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Agent Information</h3>
                        <div className="float-right">
                            <Link to="/agent-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="first_name"
                                        type="text"
                                        component={renderField}
                                        label="First Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="last_name"
                                        type="text"
                                        component={renderField}
                                        label="Last Name"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Phone No"
                                       validate={[required,phoneNumber]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                       // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="password1"
                                        type="password"
                                        component={renderField}
                                        label="Password"
                                        validate={required}
                                        required={true}

                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="password"
                                        type="password"
                                        component={renderField}
                                        label="Retype Password"
                                        validate={[required, matched("password1")]}
                                        required={true}

                                    />
                                </div>
                                <div className="col-md-6">
                                <label>Gender</label>
                                    {/* <span className="requiredField">*</span> */}
                                    <Field name="gender" component="select" className="form-control"
                                    >
                                    <option value="">Select Gender</option>
                                    <option value="1">Male</option>
                                    <option value="2">Female</option>

                                    </Field>
                                </div>
                                
                                <div className="col-md-6">
                                    <Field
                                        name="date_of_birth"
                                        type="date"
                                        time={false}
                                        // showTime={false}
                                        component={renderDateTimePicker}
                                        label="Date of Birth"
                                        required={true}
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div><br />
                                <div className="col-md-6">
                                <label className="text-sm">Agent Photo</label>
                                    <Field
                                        name="user_photo"
                                        type="file"
                                        // accept="image/*"
                                        component={FileInput}
                                        label="Agent Photo"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Agent Type</label>
                                    <span className="requiredField">*</span>
                                    <Field name="agent_type" component="select" className="form-control"
                                    validate={required}
                                    required={true}
                                    >
                                    <option value="">Select Agent Type</option>
                                    <option value="1">Free Agent</option>
                                    <option value="2">Premium Agent</option>

                                    </Field>
                                </div>
                                
                                <div className="col-md-6">
                                    <Field
                                        name="present_address"
                                        type="text"
                                        component={renderTextArea}
                                        label="Present Address"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="permanent_address"
                                        type="text"
                                        component={renderTextArea}
                                        label="Permanent Address"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="occupation"
                                        type="text"
                                        component={renderField}
                                        label="Occupation"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="business_name"
                                        type="text"
                                        component={renderField}
                                        label="Business Name"
                                     //   validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="reference_name"
                                        type="text"
                                        component={renderField}
                                        label="Reference Name"
                                        validate={required}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="reference_phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Reference Phone No"
                                       validate={[required,phoneNumber]}

                                        
                                    />
                                </div>
                                
                                <div className="col-md-6">
                                <label>Commission Type</label>
                                    {/* <span className="requiredField">*</span> */}
                                    <Field name="commission_type" component="select" className="form-control"
                                    >
                                    <option value="">Select Commission Type</option>
                                    <option value="1">Percentage</option>
                                    <option value="2">Flat</option>

                                    </Field>
                                </div>
                                
                                <div className="col-md-6">
                                    <Field
                                        name="commission_amount"
                                        type="text"
                                        component={renderField}
                                        label="Commission Amount"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="sales_target_amount"
                                        type="text"
                                        component={renderField}
                                        label="Sales Target Amount"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="number_of_seller"
                                        type="text"
                                        component={renderField}
                                        label="Number of Seller"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="total_balance"
                                        type="text"
                                        component={renderField}
                                        label="Total Balance"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                <label className="text-sm">Upload Nid Or Passport File</label>
                                    <Field
                                        name="nid_or_passport_file"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Nid or Passport File"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                <label className="text-sm">Upload Trade License File</label>
                                    <Field
                                        name="trade_license_file"
                                        type="file"
                                        // accept="image/*"
                                        component={FileInput}
                                        label="Trade License File"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="remarks"
                                        type="text"
                                        component={renderTextArea}
                                        label="Remarks"
                                        //validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                
      
                            </div>
                            

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddAgent.propTypes = {
    
    addData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    is_added: state.products.is_added,

});


AddAgent = reduxForm({
    form: 'AddAgent',
}
)(AddAgent);


export default connect(mapStateToProps, { addData})(AddAgent);

import { ExpansionPanel } from "@material-ui/core";

//local Url
// export const BASE_URL = 'http://160.202.144.196:8099';
// export const BASE_URL = 'http://192.168.0.112:8080';
//server url
export const BASE_URL = 'https://admin.niraapod.com';

//*******Mychoce base url***************
// export const BASE_URL = 'https://admin.niraapod.com';






var version = "/api/v1/"

export const GET_PRIMARY_CATEGORY_URL = BASE_URL + version + 'primary-category/';
export const GET_ALL_PRIMARY_CATEGORY_URL = BASE_URL + version + 'primary-category-all/';

export const GET_SECONDARY_CATEGORY_URL = BASE_URL + version + 'secondary-category/';
export const GET_ALL_SECONDARY_CATEGORY_URL = BASE_URL + version + 'secondary-category-all/';

export const GET_SECONDARY_CATEGORY_CREATE_URL = BASE_URL + version + 'secondary-category-create-view/';
export const GET_CHILD_CATEGORY_URL = BASE_URL + version + 'child-category/';
export const GET_ALL_CHILD_CATEGORY_URL = BASE_URL + version + 'child-category-all/';

export const GET_CHILD_CATEGORY_CREATE_URL = BASE_URL + version + 'child-category-create-view/';
export const GET_ATTRIBUTE_URL = BASE_URL + version + 'attribute/';
export const GET_ATTRIBUTE_ALL_URL = BASE_URL + version + 'attribute-all/';

export const GET_ATTRIBUTE_OPTION_URL = BASE_URL + version + 'attribute-options/';
export const GET_ATTRIBUTE_OPTION_LIST_URL = BASE_URL + version + 'attribute-option-list/';
export const GET_PRODUCT_UNIT_URL = BASE_URL + version + 'product-units/';
export const GET_PRODUCT_UNIT_ALL_URL = BASE_URL + version + 'product-unit-all/';

export const GET_ATTRIBUTE_OPTION_CREATE_URL = BASE_URL + version + 'attribute-options-create/'
export const GET_TAGS_URL = BASE_URL + version + 'tags/';
export const GET_BRANDS_URL = BASE_URL + version + 'brands/';
export const GET_BRANDS_ALL_URL = BASE_URL + version + 'brands-all/';
export const GET_TAGS_ALL_URL = BASE_URL + version + 'tags-all/';
export const GET_TAX_ALL_URL = BASE_URL + version + 'tax-all/'

export const RETURN_REFUND_URL = BASE_URL + version + 'return-refund/';
export const RETURN_REFUND_LIST_URL = BASE_URL + version + 'return-refund-list/';
export const LOGO_URL = BASE_URL + version + 'logo/'
export const FOOTER_LOGO_URL = BASE_URL + version + 'footer-logo/'
export const FAVICON_URL = BASE_URL + version + 'favicon/'
export const PRELOADER_URL = BASE_URL + version + 'pre-loader/'
export const FOOTER_PAYMENT_IMAGE_URL = BASE_URL + version + 'footer-payment-image/'
// export const GET_BRANDS_LOGO_URL = BASE_URL + version + 'brand-logo/';

export const GET_GALLERY_URL = BASE_URL + version + 'galleries/';
export const GET_ORDER_URL = BASE_URL + version + 'orders/';
export const GET_PAYMENT_METHOD_URL = BASE_URL + version + 'payment_methods/';
export const WITHDRAW_URL = BASE_URL + version + 'withdraws/';
export const CUSTOMERS_URL = BASE_URL + '/api/auth/customers/';

export const USER_CHANGE_PASSWORD = BASE_URL +'/api/auth/user-change-password/'
export const USER_EMAIL_SEND_URL = BASE_URL +'/api/v1/customer-email/'






// Staff URL
export const LOGIN_URL = BASE_URL + '/api/auth/login/';
export const LOAD_USER_URL = BASE_URL + '/api/auth/user/';
export const LOAD_CUSTOMER_WITHOUT_USER_URL = BASE_URL + '/api/auth/user-info/';

export const PRODUCT_URL = BASE_URL + version + 'products/';
export const CAMPAIGN_PRODUCT_URL = BASE_URL+version+'campaign-product-list/'
export const BUNDLE_PRODUCT_URL = BASE_URL + version + 'bundle-products/';

export const VIRTUAL_PRODUCT_URL = BASE_URL + version + 'virtual-products/';

export const RESTAURANT_URL = BASE_URL + version + 'restaurant/'
export const CUISINE_URL = BASE_URL + version + 'cuisine/'
export const CUISINE_ALL_URL = BASE_URL + version +'cuisine-all/'
export const FOOD_NAME_URL = BASE_URL + version + 'food-name/'
export const FOOD_NAME_ALL_URL = BASE_URL + version + 'food-name-all/'
export const RESTAURANT_SLIDER_URL = BASE_URL + version + 'restaurant-slider/'
export const FOOD_ORDER_URL = BASE_URL+ version +'customer-food-order/'
export const MEDICINE_DELIVERY_URL = BASE_URL + version+ 'medicine-delivery/'
export const RIDER_CAR_TRIP_URL = BASE_URL + version + 'rider-car-trip/'
export const PERCEL_EXPRESS_URL = BASE_URL + version + 'percel-express/'
export const RESTAURANT_BULK_URL = BASE_URL + '/api/v1/restaurant-bulk/'
export const FOOD_ORDER_BULK_URL = BASE_URL + version + 'food-order-bulk/'
export const RIDER_CAR_TRIP_BULK_URL = BASE_URL + version + 'rider-car-trip-bulk/'
export const MEDICINE_DELIVERY_BULK_URL =BASE_URL + version+'medicine-bulk/'
export const PERCEL_EXPRESS_BULK_URL = BASE_URL + version+'percel-express-bulk/'


export const RIDER_CAR_TYPE_URL = BASE_URL + version + 'car-type/'
export const PERCEL_TYPE_URL = BASE_URL + version + 'percel-type/'
export const PERCEL_PLAN_URL = BASE_URL + version + 'percel-plan/'
export const MOBILE_APP_SLIDER_URL = BASE_URL + version+'mobile-app-slider/'
export const PRODUCT_CSV_URL = BASE_URL + version + 'products-csv-upload/'
export const PRODUCT_BULK_URL = BASE_URL + version + 'products-bulk/'
export const BUNDLE_PRODUCT_BULK_URL = BASE_URL + version+ 'bundle-product-bulk/'
export const SELLER_BULK_URL = BASE_URL + version + 'sellers-bulk/'
export const ORDER_BULK_URL = BASE_URL + version + 'orders-bulk/'
export const PRODUCT_DISCOUNT_UPDATE_URL = BASE_URL + version + 'products-discount-update-list/'
export const LOAD_USER_GROUP_URL = BASE_URL + '/api/auth/groups/';
export const LOGOUT_URL = BASE_URL + '/auth/logout/';
export const GROUP_URL = BASE_URL + '/api/auth/groups/';

export const LOAD_VENDOR_USER_URL = BASE_URL + '/api/auth/vendor-list/';
export const SELLER_URL = BASE_URL + version + 'sellers/';
export const SELLER_ALL_URL = BASE_URL + version + 'seller-all/';

export const SELLER_STATUS_URL = BASE_URL + version + 'sellers-info-update/';
export const CUSTOMER_URL = BASE_URL + version + 'customer/';
export const TAX_URL = BASE_URL + version + 'tax/';
export const SHIPPING_CLASS_URL = BASE_URL + version + 'shipping-class/';
export const SHIPPING_ZONE_URL = BASE_URL + version + 'shipping-zone/';
export const SHIPPING_ZONES_URL = BASE_URL + version + 'shipping-zones/';

export const DISTRICT_URL = BASE_URL + version + 'district/';
export const DISTRICT_ALL_URL = BASE_URL + version + 'district-all/';
export const CAMPAIGN_URL = BASE_URL + version + 'campaign/';
export const COUPON_CODE_URL = BASE_URL + version + 'coupon-code/';
export const NO_TOKEN_COUPON_CODE_URL = BASE_URL+ version+'no-token-coupon-code/'
export const CAMPAIGN_GROUP_URL = BASE_URL + version + 'campaign-group/'

export const CONTACT_INFO_URL = BASE_URL + version + 'contact-info/'
export const COPYRIGHT_URL = BASE_URL + version + 'copy-right/'
export const TERMS_CONDITION_URL = BASE_URL + version + 'terms-condition/'
export const SHIPPING_POLICY_URL = BASE_URL + version + 'shipping-policy/'
export const PRIVACY_POLICY_URL = BASE_URL + version + 'privacy-policy/'
export const RETURN_REFUND_POLICY_URL = BASE_URL + version + 'return-refund-policy/'
export const ABOUT_US_URL = BASE_URL + version + 'about-us/'
export const FAQ_URL = BASE_URL + version + 'faq/'

export const USER_CREATE_URL = BASE_URL + '/api/auth/user-create/'
export const SUPPORT_TICKET_URL = BASE_URL + '/api/auth/support-ticket/'
export const SUPPORT_MESSAGES_URL = BASE_URL + '/api/auth/support-messages/'
export const CONTACT_MESSAGES_URL = BASE_URL + '/api/auth/contact-messages/'
export const SUBSCRIBER_URL = BASE_URL + '/api/auth/subscribers/';
export const SLIDE_SETTING_URL = BASE_URL + '/api/v1/slide-setting/';
export const SLIDE_SETTING_BULK_URL = BASE_URL + '/api/v1/slide-setting-bulk/'
export const SLIDE_RIGHT_SIDE_SETTING_URL = BASE_URL + '/api/v1/slide-right-side-setting/'
export const SLIDE_RIGHT_SIDE_SETTING_BULK_URL = BASE_URL + '/api/v1/slide-right-side-setting-bulk/'
export const BANNER_SETTING_URL = BASE_URL + '/api/v1/banner-setting/'
export const PRIMARY_CATEGORY_BULK_URL = BASE_URL + '/api/v1/primary-category-bulk/'
export const SECONDARY_CATEGORY_BULK_URL = BASE_URL + '/api/v1/secondary-category-bulk/'
export const CHILD_CATEGORY_BULK_URL = BASE_URL + '/api/v1/child-category-bulk/'

export const SOCIAL_MEDIA_URL = BASE_URL + '/api/v1/footer-social-media/'
export const FOOTER_LEFT_SECTION_URL = BASE_URL + '/api/v1/footer-left-section/'

export const BLOG_URL = BASE_URL + '/api/v1/post/'
export const BLOG_POST_URL = BASE_URL + '/api/v1/post-list/'
export const BLOG_CATEGORY_URL = BASE_URL + '/api/v1/blog-category/'
export const BLOG_TAG_ALL_URL = BASE_URL + '/api/v1/blog-tags-all/'
export const BLOG_TAG_URL = BASE_URL + '/api/v1/blog-tag/'
export const MEDIA_LIBRARY_URL = BASE_URL + '/api/v1/media-library/'
export const GROUP_WISE_PERMISSION = BASE_URL + '/api/auth/group-permissions/'; 
export const GET_PERMISSION_URL = BASE_URL + '/api/auth/get-permission/';
export const USER_PERMISSION = BASE_URL + '/api/auth/user-permissions-create/';
export const GET_USER_PERMISSION = BASE_URL + '/api/auth/user-permissions/'; 
export const GET_WAREHOUSE_LIST = BASE_URL + '/api/v1/warehouse-list/'; 
export const GET_STOCK = BASE_URL + '/api/v1/stock/'; 
export const AGENT_URL = BASE_URL + version + 'agent/';
export const PRIMARY_AGENT_URL = BASE_URL+version+'primary-agent/'
export const AGENT_BULK_URL = BASE_URL + version + 'agent-bulk/';
export const AGENT_WISE_REPORTS_URL = BASE_URL + version + 'agent-wise-report/';
export const PRODUCT_STOCK_REPORTS_URL = BASE_URL + version+ 'product-stock-report';
export const AGENT_STOCK_URL = BASE_URL + version +'agent-stock/'
export const AGENT_PRODUCT_STOCK_URL = BASE_URL+ version+'agent-products/'
export const AGENT_CUSTOMER_URL = BASE_URL+  version+'agent-customer/'
export const AGENT_PRODUCT_SALE_CUSTOMER_URL = BASE_URL+ version+'agent-product-sale-customer/'
export const AGENT_PRODUCT_STOCK_IN_REPORTS_URL = BASE_URL + version+'agent-product-stock-in-report/'
// export const AGENT_PRODUCT_STOCK_OUT_REPORTS_URL = BASE_URL + version+'agent-product-stock-out-report/'
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import {
    required
} from '../../constants/Validate'
import { connect } from "react-redux";
import { attributeOptionDetail, updateAttributeOption } from "../../actions/AttributeOptionActions";
import { fetchAttributeAll } from '../../actions/AttributeActions'
import PropTypes from "prop-types";
import moment from 'moment';
import { Link } from "react-router-dom";
import InputColor from 'react-input-color';

import momentLocalizer from 'react-widgets-moment';



momentLocalizer(moment);




// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AttributeOptionUpdate extends Component {

    state = {
        district_id: 0,
        color: {},
        attribute: "",
        is_color: false
    }
    colorChange = (e) => {
        // const { is_color } = this.state
        // alert(JSON.stringify(e.target.text))
        let value = e.target.text
        if (value.toLowerCase() === "color" || value.toLowerCase() === "colour") {
            this.setState({ is_color: true })
        } else {
            this.setState({ is_color: false })
        }

    }
    setColor = (e) => {
        // alert(JSON.stringify(e.hex))
        this.setState({ color: e.hex })
    }
    componentDidMount() {
        // console.log(this.props.getAttribute)
        let id = this.props.match.params.id;
        this.props.attributeOptionDetail(id)
        this.props.fetchAttributeAll()

    }


    submit(values) {
        // alert(JSON.stringify(this.state.color))
        // console.log(values)
        values['color_code'] = this.state.color
        return sleep(1000).then(() => {
            let id = this.props.match.params.id;
            this.props.updateAttributeOption(id, values);
        })
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Attribute Option Information</h1>
                            </div>
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="">Update Attribute Option Details
                            <Link to="/attribute-option-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>
                        </h3>

                    </div>
                    {/* /.card-header */}
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-md-6">
                                    <label>Attribute Name</label>
                                    <span className="requiredField">*</span>
                                    <Field name="attribute" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select attribute name</option>
                                        {this.props.attributes.map(attribute => (
                                            <option name={this.state.color} key={attribute.id} value={attribute.id} onClick={this.colorChange}>{attribute.name}</option>
                                        ))}
                                    </Field>
                                    {this.state.is_color ?
                                        <div>
                                            <InputColor
                                                name="color_code"
                                                initialValue="#5e72e4"
                                                onChange={this.setColor}
                                                placement="right"
                                            />
                                            <div
                                                style={{
                                                    width: 50,
                                                    height: 50,
                                                    marginTop: 20,
                                                    backgroundColor: this.state.color.hex
                                                }}
                                            />
                                        </div>
                                        : ''}
                                </div>

                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="Name"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>









                            </div>
                            <div className="row">
                                <div className="card-footer float-right">
                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-info"
                                            disabled={submitting}>
                                            Update
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>


        )
    }
}


AttributeOptionUpdate.propTypes = {

    updateAttributeOption: PropTypes.func.isRequired,
    fetchAttributeAll: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    initialValues: state.attribute_options.attribute_option,
    attribute_options: state.attribute_options.attribute_options,
    attributes: state.attributes.attributes

});

AttributeOptionUpdate = reduxForm({
    form: 'AttributeOptionUpdate',
    enableReinitialize: true,
}
)(AttributeOptionUpdate);

export default connect(mapStateToProps, { attributeOptionDetail, updateAttributeOption, fetchAttributeAll })(AttributeOptionUpdate);

import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required, match } from '../../../../constants/Validate'
import { connect } from "react-redux";
import { fetchTags, addTags } from "../../../../actions/TagsActions"
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { addData } from '../../../../actions/CustomerActions';
import RichTextEditor from 'react-rte';
import {CheckPermisionAction} from "../../../../constants/CustomMethod"
import { fetchFaq } from '../../../../actions/CopyRightActions';

import { toast } from "react-toastify";
import { FAQ_URL } from "../../../../constants/api";


momentLocalizer(moment);



const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class Faq extends Component {
    state = {

        id: 0,
        details: false,
        faq: RichTextEditor.createEmptyValue(),

    }
    static propTypes = {
        onChange: PropTypes.func
    };
    componentDidMount() {
        this.props.fetchFaq(1);
    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_getting === true) {
            if (nextProps.is_getting !== prevState.details) {

                return {
                    // id: nextProps.return_refund_policies[0] ? nextProps.return_refund_policies[0].id :'',
                    faq: RichTextEditor.createValueFromString(nextProps.faqs[0] ? nextProps.faqs[0].faq :'', 'html'),
                    details: true
                }

            }
        }
    }
    onChangeDetails = (faq) => {
        this.setState({ faq });
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChangeDetails(
                faq.toString('html')
            );
        }
    };

    onChangeText = (e) => {

        this.setState({ [e.target.name]: e.target.value })

    }
    // componentDidMount() {
    //     this.props.fetchReturnRefundPolicy(1)
    // }


    submit(event) {
        console.log(event)
        // if (this.state.copyright == 'undefined') {
        //     this.setState({ copyright: event.target.value })

        // }
        let data = {
            'faq': this.state.faq.toString('html') ? this.state.faq.toString('html') : ''

        }
        //alert(COPYRIGHT_URL+this.state.id+'/')

        // alert(JSON.stringify(event.data))
        this.props.addData(data, FAQ_URL , "FAQ")



    }

    render() {
        const toolbarConfig = {
            // Optionally specify the groups to display (displayed in the order listed).
            display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
            INLINE_STYLE_BUTTONS: [
                { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
                { label: 'Italic', style: 'ITALIC' },
                { label: 'Underline', style: 'UNDERLINE' }
            ],
            BLOCK_TYPE_DROPDOWN: [
                { label: 'Normal', style: 'unstyled' },
                { label: 'Heading Large', style: 'header-one' },
                { label: 'Heading Medium', style: 'header-two' },
                { label: 'Heading Small', style: 'header-three' }
            ],
            BLOCK_TYPE_BUTTONS: [
                { label: 'UL', style: 'unordered-list-item' },
                { label: 'OL', style: 'ordered-list-item' }
            ]
        };

        const { handleSubmit, submitting ,permissions} = this.props;

        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">FAQ Settings </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">FAQ Settings</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                {CheckPermisionAction(permissions,'Appearance','view_faq')?

                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">FAQ</h3>
                        {/* <div className="float-right">
                            <Link to="/seller-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div> */}
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                {/* <div className="col-md-6">
                                    <Field
                                        name="copyright"
                                        type="text"
                                        component={renderTextArea}
                                        label="Copyright"
                                    // validate={purchasePrice}

                                    // required={true}
                                    />
                                </div> */}

                                <div className="col-md-12">

                                    <label>FAQ</label>
                                    <RichTextEditor
                                        // toolbarConfig={toolbarConfig}
                                        value={this.state.faq}
                                        onChange={this.onChangeDetails}
                                    />
                                </div>









                            </div>


                        </div>
                        <div className="card-footer text-right">
                            <div>
                        {CheckPermisionAction(permissions,'Appearance','faq_save')?

                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>
                        :null}

                            </div>
                        </div>
                    </form>
                </div>
                :null}
            </div>

        )
    }
}


Faq.propTypes = {
    addData: PropTypes.func.isRequired,
    fetchFaq: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    faqs: state.faqs.faqs,
    is_updated: state.faqs.is_updated,
    is_getting: state.faqs.is_getting,
    permissions:state.userpermissions.userpermissions
});


Faq = reduxForm({
    form: 'Faq',
    // enableReinitialize: true

}
)(Faq);


export default connect(mapStateToProps, { fetchFaq, addData })(Faq);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Modal, Button } from "react-bootstrap";
import { ProductCSVData } from '../../constants/Datas'
import { CSVLink } from "react-csv";
import {  OrderStatus } from '../../constants/Datas'
import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'
import moment from "moment";
import 'moment-timezone';

import { fetchOrders ,deleteOrder} from '../../actions/orderActions';
import {  BulkAction } from '../../actions/ProductAction';
import { ORDER_BULK_URL,BASE_URL } from '../../constants/api'
import {findArrayElementByTitle} from '../../constants/CustomMethod'
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
// import { loadUser } from '../../actions/auth';
import {CheckPermisionAction} from "../../constants/CustomMethod"
import {
   dateYMD
  } from "../../constants/Validate";
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};


// Modals.setAppElement('#modalRender');


const headers = [
    {label:"Order Date",key:"order_date"},
    {label:"Order No",key:"order_no"},
    {label:"Customer Phone No",key:"customer.phone_no"},
    {label:"Total Quantity",key:"total_order_quantity"},
    {label:"Total Price",key:"total_order_price"},
    {label:"Order Status",key:"order_status"},
    // {label:"Payment Method",key:"payment_method" !==null && "payment_method" !==undefined?"payment_method.name" :"" },
    


]

class OrderList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
        this.ordersweetalert = this.ordersweetalert.bind(this)

        this.toggleAllChange = this.toggleAllChange.bind(this)

    }

    // constructor() {
    //     super();
    //     this.opensweetalert = this.opensweetalert.bind(this)
    //     // this.getCSVData=this.getCSVData.bind(this)
    //     this.handleModalShow = this.handleModalShow.bind(this)
    //     this.handleClose = this.handleClose.bind(this)
    //     this.handleStatusValues = this.handleStatusValues.bind(this);
       
    // }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        order_no: "",
        orders:[],
        customer__phone_no: "",
        order_date_to: "",
        order_date_from:"",
        order_status:"",
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
        get_order:false,
        allchecked: false, 
        bulk: false,
        show:false
    };

    
    
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.is_fetching) {
            if (nextProps.is_fetching !== prevState.get_order) {
                var orders = []
               
                for (var i = 0; i < nextProps.orders.length; i++) {
                    Object.assign(nextProps.orders[i], { 'checked': false });
                    orders.push(nextProps.orders[i])
                }
                return {
                    orders: orders,
                    get_order: true
                }
            }
        }
        return null
    }

    componentDidUpdate(prevProps, prevState){
        if (prevProps.orders != this.props.orders){
            this.setState({orders:this.props.orders})
        }

    }


    componentDidMount() {
        // alert(JSON.stringify(this.props.orders))
        this.props.fetchOrders(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.order_no  +' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteOrder(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted '+ data.order_no + '.',
                        'success'
                    )
                }
            }
        })
    }


    ordersweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + this.props.orders.order_status  +' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    alert(JSON.stringify(result))
                    this.handleBulkStatus(data)
                    Swal.fire(
                        '',
                        'Your Data has been '+ this.props.orders.order_status + '.',
                        'success'
                    )
                }
            }
        })
    }



    openModal() {
        this.setState({ modalIsOpen: true });
    }
    handleClose() {
        this.setState({ show: false });
    }
    handleModalShow=()=> {

        this.setState({ show: true });
    }
    handleUploadFile = (event) => {
        this.setState({
            [event.target.name]: event.target.files[0],

        })



    }
    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }
    toggleAllChange(e) {
        const { value, checked } = e.target;
        let orders = [...this.props.orders]
        orders.forEach(order => order.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, orders, bulk: true })
        } else {
            this.setState({ allchecked: checked, orders, bulk: false })
        }
    }
    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onSingleChange = index => (e) => {
        console.log(index)
        let orders = [...this.state.orders]
        orders[index]["checked"] = e.target.checked
        let recent_length = orders.filter(order => order.checked).length;

        if (orders.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        this.setState({ orders })

    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });
    onChangeDateTo = (date) => {
       
        this.setState({order_date_to:dateYMD(date)})

}
    onChangeDateFrom = (date) => {
        
        this.setState({order_date_from:dateYMD(date)})

}

    onSubmit = e => {
        
        e.preventDefault();
        
        this.props.fetchOrders('', this.state);
        this.setState({ hideButton: true })

    };
    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = [],insert=true
            for (var i = 0; i < this.state.orders.length; i++) {
                if (this.state.orders[i].checked) {
                    results.push(this.state.orders[i].id)
                    if(this.state.orders[i].order_status===5){
                        insert=false
                        break;
                    }
                }
            }
            if(insert){
               

                    Swal.fire({
                        text: 'Are you want to ' + OrderStatus.find(product =>  product.status).status  +' ?',
                        // text: "You won't be able to revert this!",
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes!'
                    }).then(() => {
                       
                        if (value) {
                            
                                this.props.BulkAction(
                                    { 'id': results, 'action': value }, ORDER_BULK_URL
                                )
                           
                                
                                // Swal.fire(
                                    
                                //     'Your Data has been '+ this.props.orders[0].orders_status +
                                //     ' successfully'
                                // )
                            
                        }
                    })
            
                
                
                
                    
                
                    
                
                
            }else{
                alert("You are not able to change completed order")
            }
           
        }
    }
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchOrders(pageNo,this.state,true)
    }
    render() {
        const {allchecked, orders, bulk,show}=this.state
        const page = Math.ceil(this.props.count / 10)
        const {permissions} = this.props
        const isAdmin = this.props.auth.user.is_admin;

        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/order-list'
            },
                3000);
        }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Order List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Order list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Order List</h3>
                                <div className="float-right">
                            {CheckPermisionAction(permissions,'Order','bulk_import_order')?
                                    <>
                                    {/* onClick={this.handleModalShow} */}
                                    <button  className="btn btn-info text-white"><b><i className="fa fa-upload"></i>
                                        Bulk Import</b></button>&nbsp;
                                    </>
                            :null}
                                <a class="btn btn-primary" href={BASE_URL+'/api/v1/export-order-report/'} role="button"><i className="fa fa-download"></i>Download </a>

                                    <CSVLink
                                        data={this.props.orders}
                                        headers={headers}
                                        // filename={"order-upload-format.csv"}
                                        className="btn btn-warning text-white"
                                        // onClick={false}
                                        // onClick={e => e.preventDefault()}
                                        // target="_self"
                                    >
                                        <i className="fa fa-download"></i><strong>Download CSV</strong>
                                    </CSVLink>&nbsp;
                            {CheckPermisionAction(permissions,'Order','add_order')?
                                    <Link to="/add-order" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Order</b></Link>
                            :null}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="order_no" type="text"
                                            onChange={this.onChange} placeholder="Order No"
                                            aria-label="Search" />
                                        <input className="form-control mr-sm-4" name="customer__phone_no" type="text"
                                            onChange={this.onChange} placeholder="Customer Phone Number"
                                            aria-label="Search" />

                                        <DateTimePicker
                                            // onChange={this.onChangeDate}
                                            onChange={date => this.onChangeDateFrom(date)}
                                            name="order_date_from"
                                            format="DD-MM-YYYY"
                                            time={false}
                                        // value={this.state.order_search_date}
                                            placeholder="Order date from"
                                        /> 

                                        <DateTimePicker
                                            // onChange={this.onChangeDate}
                                            onChange={date => this.onChangeDateTo(date)}
                                            name="order_date_to"
                                            format="DD-MM-YYYY"
                                            time={false}
                                        // value={this.state.order_search_date}
                                            placeholder="Order date to"
                                        />  
                                        <select 
                                            name="order_status"
                                            component="select"
                                            className="form-control mr-sm-4"

                                            onChange={this.onChange}
                                        >
                                            <option value="select">--Select Status--</option>
                                            {OrderStatus.map((order, index) => (
                                                <option key={index} value={order.id}>{order.status}</option>

                                             ))}
                                            {/* {groups.map(group=>(
                                                <option key={group.id} value={group.id} onChange={this.onChange}>{group.name}</option>
                                            ))} */}
                                        </select> 
                                        {/* <input className="form-control mr-sm-4" name="order_date" type="email"
                                            onChange={this.onChange} placeholder="Order date"
                                            aria-label="Search" /> */}
                                    {CheckPermisionAction(permissions,'Order','order_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    :null}
                                    </form>
                                </div>


                            </div>
                            <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"
                                            // onClick={() => this.ordersweetalert}
                                            onChange={(e)=>this.handleBulkStatus(e)}
                                        >
                                            <option value="">--Bulk Action--</option>
                                            {OrderStatus.map((order, index) => (
                                                <option key={index} value={order.id}>{order.status}</option>

                                             ))}
                                            <option value="delete">Delete</option>


                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>
                            {/* {CheckPermisionAction(permissions,'Order','view_order_list')? */}

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>
                                                <input type="checkbox"
                                                    checked={allchecked}
                                                    onChange={this.toggleAllChange}
                                                />   
                                                </th>                            
                                            <th>Order Date</th>
                                            <th>Order No</th>
                                            <th>Customer  Account Phone No</th>
                                            <th>Customer Name</th>
                                            <th>Total Quantity</th>
                                            <th>Total Price</th>
                                            <th>Order Status</th>
                                            <th>Payment Method</th>
                                            <th>Action</th>


                                            {this.state.hideButton ?
                                                <th><Link to="/order-list" onClick={() => { window.location.href = "/order-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ?
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>
                                            : orders.length != undefined && orders.map((order,index) => (

                                                <tr key={order.id}>
                                                    <td>
                                                        <input type="checkbox" onChange={this.onSingleChange(index)} checked={order.checked ? true : false} value={order.id} />
                                                    </td>
                                                    {/* <td>{moment(order.order_date).format('MMMM Do YYYY H:m A')}</td> */}
                                                    <td>{moment(order.order_date).tz('Asia/Dhaka').format("llll")}</td>
                                                    <td>#{order.order_no}</td>
                                                    <td>{order.customer!==null && order.customer !==undefined ? order.customer.phone_no:''}</td>
                                                    <td>{order.customer!==null && order.customer !==undefined ? order.customer.first_name:''}</td>

                                                    {/* <td>{order.seller ? order.seller.shop_name : ''}</td> */}
                                                    <td>{order.total_order_quantity}</td>
                                                    <td>{(order.total_order_price).toFixed(2)}</td>
                                                    <td>
                                                     
                                                        
                                                            { findArrayElementByTitle(OrderStatus,order.order_status) ?  findArrayElementByTitle(OrderStatus,order.order_status).status : null }
                                                           
                                                    </td>
                                                    <td>{order.payment_methods !==null && order.payment_methods !==undefined && order.payment_methods.payment_type ===1 ? order.payment_methods.payment_types:order.payment_methods.mobile_banking_types }</td>
                                                   
                                                        
                                            <td>
                                            { findArrayElementByTitle(OrderStatus,order.order_status).status  ?  (

                                                <div>
                                            {/* {CheckPermisionAction(permissions,'Order','order_options')? */}

                                                    <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                {/* :null} */}
                                                    <div class="dropdown-menu">
                                                        <a href={'/view-order/' + order.id} class="dropdown-item">View Order Details</a>
                                                        <div class="dropdown-divider"></div>
                                                        
                                                        <a href={'/update-order/' + order.id} class="dropdown-item">Order Update</a>
                                                        <div class="dropdown-divider"></div>
                                                        <a href="#" onClick={() => this.opensweetalert(order)} class="dropdown-item">Delete</a>

                                                    </div>
                                                </div>

                                            ):null
                                            
                                            }    


                                                        
                                                       
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            {/* :null} */}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>
                            <Modal
                                show={show}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Upload File(CSV)</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Please Upload CSV from Downloaded format</p>
                                    <input
                                        name="csv_file"
                                        type="file"
                                        className="form-control"
                                        //placeholder="Attributes Name"
                                        // validate={required}
                                        onChange={this.handleUploadFile}
                                    />

                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.getCSVData}>
                                        <i className="fa fa-upload"></i>Upload
                                    </Button>
                                </Modal.Footer>

                            </Modal>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

OrderList.propTypes = {
    fetchOrders: PropTypes.func.isRequired,
    BulkAction: PropTypes.array.isRequired,

    // deleteRd: PropTypes.func.isRequired,
    // regionals: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
    orders: state.orders.orders,
    count: state.orders.count,
    next: state.orders.next,
    is_loading: state.orders.is_loading,
    is_fetching:state.orders.is_fetching,
    bulk: state.products.bulk,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchOrders,BulkAction ,deleteOrder})(OrderList);

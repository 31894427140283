import React from 'react'
import { connect } from "react-redux";
import { reduxForm } from 'redux-form';
import { fetchGROUP,getAllPermissionAction ,createPermission,getGroupPermissionAction} from '../../actions/UserPermissionsAction';
import {fetchGroups} from '../../actions/GroupAction'
import PropTypes from 'prop-types';
import UserPermissionsList from '../../components/user/userpermissions/UserPermissionsList'

class UserPermissionContainer extends React.Component {

  componentDidMount() {
    
    this.props.getAllPermission();
    this.props.fetchGroups();
  }


  createPermission=(data)=>{
    this.props.createPermission(data)
  }

  
  onchangeGroup = (url,permissions) =>{
    this.props.getGroupPermission(url,permissions)
  }

  render() {

    return (
      <UserPermissionsList 
        createPermission={this.createPermission}
        onChangeGroup={this.onchangeGroup}
        groups={this.props.groups}
        permissions={this.props.permissions}
        is_fetching={this.props.is_fetching}
      />
    );
  }
  }



  UserPermissionContainer.propTypes = {
    groups: PropTypes.array.isRequired,
    permissions: PropTypes.array.isRequired,
    fetchGroups: PropTypes.func.isRequired,
    getAllPermission: PropTypes.func.isRequired,
    is_fetching:PropTypes.bool.isRequired

  };
  const UserPermission = reduxForm({
    form: "UserPermissionContainer"
  })(UserPermissionContainer);
  
  const mapStateToProps = state => ({
    groups: state.groups.groups,
    permissions: state.permissions.permissions,
    is_fetching: state.permissions.is_fetching,
  
  
  });
  
  const mapDispatchToProps = dispatch => ({
    fetchGroups: () => dispatch(fetchGroups(  
    )),
    getAllPermission: () => dispatch(getAllPermissionAction(  
      )),
    createPermission: (data) => dispatch(createPermission(data)),
    getGroupPermission: (url,permissions) => dispatch(getGroupPermissionAction(url,permissions)),
      
  });

  
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserPermission);


// USER ACTIONS
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const NOT_FOUND_USERS = 'NOT_FOUND_USERS'
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';
export const LOGIN_SUCCESSFUL = 'LOGIN_SUCCESSFUL';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const REGISTRATION_SUCCESSFUL = 'REGISTRATION_SUCCESSFUL';
export const REGISTRATION_FAILED = 'REGISTRATION_FAILED';
export const LOGOUT_SUCCESSFUL = 'LOGOUT_SUCCESSFUL';


// RESTAURANT ACTIONS

export const FETCH_RESTAURANT = 'FETCH_RESTAURANT' ;
export const DETAIL_RESTAURANT = 'DETAIL_RESTAURANT'; 
export const DELETE_RESTAURANT ='DELETE_RESTAURANT';
export const LOADING_RESTAURANT = 'LOADING_RESTAURANT';
export const NOT_FOUND_RESTAURANT = 'NOT_FOUND_RESTAURANT';


// CUISINE ACTIONS
export const LOADING_CUISINE ='LOADING_CUISINE';
export const FETCH_CUISINE = 'FETCH_CUISINE';
export const DETAIL_CUISINE ='DETAIL_CUISINE';
export const DELETE_CUISINE = 'DELETE_CUISINE';
export const NOT_FOUND_CUISINE = 'NOT_FOUND_CUISINE';



// FOOD NAME ACTIONS

export const LOADING_FOOD_NAME= 'LOADING_FOOD_NAME';
export const FETCH_FOOD_NAME= 'FETCH_FOOD_NAME';
export const DETAIL_FOOD_NAME='DETAIL_FOOD_NAME';
export const DELETE_FOOD_NAME ='DELETE_FOOD_NAME';
export const NOT_FOUND_FOOD_NAME ='NOT_FOUND_FOOD_NAME';


// RESTAURANT SLIDER ACTIONS

export const LOADING_RESTAURANT_SLIDER='LOADING_RESTAURANT_SLIDER';
export const FETCH_RESTAURANT_SLIDER='FETCH_RESTAURANT_SLIDER';
export const DETAIL_RESTAURANT_SLIDER='DETAIL_RESTAURANT_SLIDER';
export const DELETE_RESTAURANT_SLIDER='DELETE_RESTAURANT_SLIDER';
export const NOT_FOUND_RESTAURANT_SLIDER='NOT_FOUND_RESTAURANT_SLIDER';


// FOOD ORDER ACTIONS

export const LOADING_FOOD_ORDER = 'LOADING_FOOD_ORDER';
export const FETCH_FOOD_ORDER = 'FETCH_FOOD_ORDER';
export const NOT_FOUND_FOOD_ORDER = 'NOT_FOUND_FOOD_ORDER';
export const DELETE_FOOD_ORDER = 'DELETE_FOOD_ORDER';
export const DETAIL_FOOD_ORDER = 'DETAIL_FOOD_ORDER';

// MEDICINE DELIVERY ACTIONS

export const LOADING_MEDICINE_DELIVERY='LOADING_MEDICINE_DELIVERY';
export const FETCH_MEDICINE_DELIVERY='FETCH_MEDICINE_DELIVERY';
export const DETAIL_MEDICINE_DELIVERY='DETAIL_MEDICINE_DELIVERY';
export const DELETE_MEDICINE_DELIVERY='DELETE_MEDICINE_DELIVERY';
export const NOT_FOUND_MEDICINE_DELIVERY='NOT_FOUND_MEDICINE_DELIVERY';


// PERCEL TYPE ACTIONS 

export const  LOADING_PERCEL_TYPE = 'LOADING_PERCEL_TYPE';
export const  FETCH_PERCEL_TYPE = 'FETCH_PERCEL_TYPE';
export const  DETAIL_PERCEL_TYPE = 'DETAIL_PERCEL_TYPE';
export const  DELETE_PERCEL_TYPE = 'DELETE_PERCEL_TYPE';
export const  NOT_FOUND_PERCEL_TYPE = 'NOT_FOUND_PERCEL_TYPE';


// PERCEL PLAN ACTIONS 

export const  LOADING_PERCEL_PLAN = 'LOADING_PERCEL_PLAN';
export const  FETCH_PERCEL_PLAN = 'FETCH_PERCEL_PLAN';
export const  DETAIL_PERCEL_PLAN = 'DETAIL_PERCEL_PLAN';
export const  DELETE_PERCEL_PLAN = 'DELETE_PERCEL_PLAN';
export const  NOT_FOUND_PERCEL_PLAN = 'NOT_FOUND_PERCEL_PLAN';


// MOBILE APP SLIDER

   export const FETCH_MOBILE_APP_SLIDER = 'FETCH_MOBILE_APP_SLIDER';
   export const DETAIL_MOBILE_APP_SLIDER = 'DETAIL_MOBILE_APP_SLIDER';
   export const DELETE_MOBILE_APP_SLIDER = 'DELETE_MOBILE_APP_SLIDER';
   export const LOADING_MOBILE_APP_SLIDER = 'LOADING_MOBILE_APP_SLIDER';
   export const NOT_FOUND_MOBILE_APP_SLIDER = 'NOT_FOUND_MOBILE_APP_SLIDER';


// RIDER CAR TYPE ACTIONS

export const LOADING_RIDER_CAR_TYPE = 'LOADING_RIDER_CAR_TYPE';
export const FETCH_RIDER_CAR_TYPE = 'FETCH_RIDER_CAR_TYPE';
export const DETAIL_RIDER_CAR_TYPE = 'DETAIL_RIDER_CAR_TYPE';
export const DELETE_RIDER_CAR_TYPE = 'DELETE_RIDER_CAR_TYPE';
export const NOT_FOUND_RIDER_CAR_TYPE = 'NOT_FOUND_RIDER_CAR_TYPE';


// RIDER CAR TRIPS ACTIONS
export const LOADING_RIDER_CAR_TRIP='LOADING_RIDER_CAR_TRIP';
export const FETCH_RIDER_CAR_TRIP='FETCH_RIDER_CAR_TRIP';
export const DETAIL_RIDER_CAR_TRIP='DETAIL_RIDER_CAR_TRIP';
export const NOT_FOUND_RIDER_CAR_TRIP='NOT_FOUND_RIDER_CAR_TRIP';
export const DELETE_RIDER_CAR_TRIP='DELETE_RIDER_CAR_TRIP';


// PERCEL EXPRESS ACTIONS

export const LOADING_PERCEL_EXPRESS='LOADING_PERCEL_EXPRESS';
export const FETCH_PERCEL_EXPRESS='FETCH_PERCEL_EXPRESS';
export const DETAIL_PERCEL_EXPRESS='DETAIL_PERCEL_EXPRESS';
export const NOT_FOUND_PERCEL_EXPRESS='NOT_FOUND_PERCEL_EXPRESS';
export const DELETE_PERCEL_EXPRESS='DELETE_PERCEL_EXPRESS';




// USER PERMISSIONS

export const LOADING_PERMISSIONS = 'LOADING_PERMISSIONS';
export const LOADING_USER_PERMISSIONS = 'LOADING_USER_PERMISSIONS';
export const FETCH_USER_PERMISSIONS ='FETCH_USER_PERMISSIONS';
export const FETCH_PERMISSIONS ='FETCH_PERMISSIONS'

export const PASSWORD_RESET_FAIL = 'PASSWORD_RESET_FAIL';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_CONFIRM_FAIL = 'PASSWORD_RESET_CONFIRM_FAIL';
export const PASSWORD_RESET_CONFIRM_SUCCESS = 'PASSWORD_RESET_CONFIRM_SUCCESS';
export const ACTIVATION_FAIL = 'ACTIVATION_FAIL';
export const ACTIVATION_SUCCESS = 'ACTIVATION_SUCCESS';

//PROMO CODE ACTION

export const LOADING_PROMOCODE = 'LOADING_PROMOCODE';
export const GETTING_PROMOCODE = 'GETTING_PROMOCODE';
export const DETAIL_PROMOCODE = 'DETAIL_PROMOCODE';
export const UPDATE_PROMOCODE = 'UPDATE_PROMOCODE';
export const DELETE_PROMOCODE = 'DELETE_PROMOCODE';
export const NOT_FOUND_PROMOCODE = 'NOT_FOUND_PROMOCODE';

// USER GROUP ACTION
export const FETCH_USER_GROUP = 'FETCH_USER_GROUP'
export const FETCH_USER_GROUP_SUCCESSFUL = 'FETCH_USER_GROUP_SUCCESSFUL'
export const FETCH_USER_GROUP_FAILED = 'FETCH_USER_GROUP_FAILED'

// MODEL ACTIONS
export const SHOW_MODAL = 'SHOW_MODEL';
export const HIDE_MODAL = 'HIDE_MODEL';

// STAFF ACTIONS
export const FETCH_SATFFS = 'FETCH_SATFFS';
export const FETCH_STAFFS_FAILED = 'FETCH_STAFFS_FAILED';
export const NOT_FOUND_STAFF = 'NOT_FOUND_STAFF';
export const NOT_FOUND_PENDING_STAFF = 'NOT_FOUND_PENDING_STAFF';
export const STAFF_DETAIL = 'STAFF_DETAIL';
export const STAFF_DETAIL_FAILED = 'STAFF_DETAIL_FAILED';

export const DELETE_STAFF = 'DELETE_STAFF';

export const ADD_STAFF = 'ADD_STAFF';
export const ADD_STAFF_FAILED = 'ADD_STAFF_FAILED';

export const LOADING_STAFF = 'LOADING_STAFF';

export const UPDATE_STAFF = 'UPDATE_STAFF';
export const UPDATE_STAFF_FAILED = 'UPDATE_STAFF_FAILED';

// PENDING STAFF ACTIONS
export const FETCH_PENDING_STAFF = 'FETCH_PENDING_STAFF';
export const LOADING_PENDING_STAFF = 'LOADING_PENDING_STAFF';
export const PENDING_DETAIL_STAFF = 'PENDING_DETAIL_STAFF';
export const STAFF_CONFIRM = 'STAFF_CONFIRM';

// PRODUCT  ACTIONS
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';
export const DETAIL_PRODUCT = 'DETAIL_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const ADD_DATA = 'ADD_DATA';
export const LOADING_PRODUCT = 'LOADING_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_GALLERY = 'DELETE_GALLERY';
export const UPDATED_DATA = 'UPDATED_DATA';
export const UPLOAD_CSV = 'UPLOAD_CSV';
export const UPDATE_PRODUCT_LIST_DATA = 'UPDATE_PRODUCT_LIST_DATA';

export const BULK_PRODUCT_ACTION = 'BULK_PRODUCT_ACTION';

export const LOADING_UPSELL_PRODUCT = 'LOADING_UPSELL_PRODUCT';
export const UPSELL_FETCH_PRODUCT = 'UPSELL_FETCH_PRODUCT';
export const UPSELL_NOT_FOUND = 'UPSELL_NOT_FOUND';


export const LOADING_CROSSSELL_PRODUCT = 'LOADING_UPSELL_PRODUCT';
export const CROSSSELL_FETCH_PRODUCT = 'UPSELL_FETCH_PRODUCT';
export const CROSSSELL_NOT_FOUND = 'UPSELL_NOT_FOUND';

export const NOT_FOUND_PRODUCT = 'NOT_FOUND_PRODUCT';




// Order ACTIONS
export const FETCH_ORDERS = "FETCH_ORDERS";
export const DELETE_ORDER = "DELETE_ORDER";
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const NOT_FOUND_ORDER = 'NOT_FOUND_ORDER';
export const DETAIL_ORDER = 'DETAIL_ORDER';
export const FETCH_PAYMENT_METHODS = 'FETCH_PAYMENT_METHODS';
export const EDIT_PAYMENT_METHOD = 'EDIT_PAYMENT_METHOD';
export const DETAIL_PAYMENT_METHOD = 'DETAIL_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const LOADING_PAYMENT_METHOD = 'LOADING_PAYMENT_METHOD';
export const LOADING_ORDERS = 'LOADING_ORDERS';
export const ADD_ORDER = 'ADD_ORDER';
export const ADD_PAYMENT = 'ADD_PAYMENT';
export const NOT_FOUND_PAYMENT = 'NOT_FOUND_PAYMENT';






// GROUP ACTIONS
export const FETCH_GROUPS = 'FETCH_GROUPS';
export const DETAIL_GROUP = 'DETAIL_GROUP';
export const LOADING_GROUP = 'LOADING_GROUP'
export const ADD_GROUP = 'ADD_GROUP';
export const DELETE_GROUP = 'DELETE_GROUP';
export const UPDATE_GROUP = 'UPDATE_GROUP';
export const NOT_FOUND_GROUP = 'NOT_FOUND_GROUP';


//RIDE REQUEST
export const LOADING_RIDEREQUEST = 'LOADING_RIDEREQUEST'
export const FETCH_RIDEREQUEST = 'FETCH_RIDEREQUEST';

// MESSAGE ACTIONS
export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_MESSAGE = 'CREATE_MESSAGE';

// DASHBOARD ACTIONS
export const USER_STATISTICS = 'USER_STATISTICS';

// USERS ACTIONS
export const GET_USERS_BEGIN = 'GET_USERS_BEGIN';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';

// RIDE INFO ACTIONS
export const LOADING_RIDE_INFO = 'LOADING_RIDE_INFO';
export const GETING_RIDE_INFO = 'GETING_RIDE_INFO';

export const LOADING_RIDES_INFO = 'DELETE_SELLER';
export const GETING_RIDES_INFO = 'GETING_RIDES_INFO';

export const DELETE_SELLER = 'DELETE_SELLER';
export const FETCH_SELLER = 'FETCH_SELLER';
export const LOADING_SELLER = 'LOADING_SELLER';
export const NOT_FOUND_SELLER = 'NOT_FOUND_SELLER';
export const DETAIL_SELLER = 'DETAIL_SELLER';
export const UPDATE_SELLER = 'UPDATE_SELLER';
export const UPDATE_SELLER_STATUS = 'UPDATE_SELLER_STATUS';



export const LOADING_RIDE_CATEGORY_INFO = 'LOADING_RIDE_CATEGORY_INFO';
export const GETING_RIDE_CATEGORY_INFO = 'GETING_RIDE_CATEGORY_INFO';
export const FETCH_RIDE_CATEGORY = 'FETCH_RIDE_CATEGORY';
export const DETAIL_RIDE_CATEGORY = 'DETAIL_RIDE_CATEGORY';
export const UPDATE_RIDE_CATEGORY = 'UPDATE_RIDE_CATEGORY'
export const DELETE_RIDE_CATEGORY = 'DELETE_RIDE_CATEGORY';
// WALLET AMOUNT ACTIONS
export const LOADING_WALLET_AMOUNT = 'LOADING_WALLET_AMOUNT';
export const GET_WALLET_AMOUNT = 'GET_WALLET_AMOUNT';


// PRIMARY CATEGORY ACTIONS
export const LOADING_PRIMARY_CATEGORY = 'LOADING_PRIMARY_CATEGORY';
export const GETTING_PRIMARY_CATEGORY = 'GETTING_PRIMARY_CATEGORY';
export const ADD_PRIMARY_CATEGORY = 'ADD_PRIMARY_CATEGORY';
export const DETAIL_PRIMARY_CATEGORY = 'DETAIL_PRIMARY_CATEGORY';
export const UPDATE_PRIMARY_CATEGORY = 'UPDATE_PRIMARY_CATEGORY'
export const DELETE_PRIMARY_CATEGORY = 'DELETE_PRIMARY_CATEGORY';
export const NOT_FOUND_PRIMARY_CATEGORY = 'NOT_FOUND_PRIMARY_CATEGORY'

// SECONDARY CATEGORY ACTIONS
export const LOADING_SECONDARY_CATEGORY = 'LOADING_SECONDARY_CATEGORY';
export const GETTING_SECONDARY_CATEGORY = 'GETTING_SECONDARY_CATEGORY';
export const ADD_SECONDARY_CATEGORY = 'ADD_SECONDARY_CATEGORY';
export const DETAIL_SECONDARY_CATEGORY = 'DETAIL_SECONDARY_CATEGORY';
export const UPDATE_SECONDARY_CATEGORY = 'UPDATE_SECONDARY_CATEGORY'
export const DELETE_SECONDARY_CATEGORY = 'DELETE_SECONDARY_CATEGORY';
export const NOT_FOUND_SECONDARY_CATEGORY = 'NOT_FOUND_SECONDARY_CATEGORY'

// CHILD CATEGORY ACTIONS
export const LOADING_CHILD_CATEGORY = 'LOADING_CHILD_CATEGORY';
export const GETTING_CHILD_CATEGORY = 'GETTING_CHILD_CATEGORY';
export const ADD_CHILD_CATEGORY = 'ADD_CHILD_CATEGORY';
export const DETAIL_CHILD_CATEGORY = 'DETAIL_CHILD_CATEGORY';
export const UPDATE_CHILD_CATEGORY = 'UPDATE_CHILD_CATEGORY'
export const DELETE_CHILD_CATEGORY = 'DELETE_CHILD_CATEGORY';
export const NOT_FOUND_CHILD_CATEGORY = 'NOT_FOUND_CHILD_CATEGORY'



// ATTRIBUTE ACTIONS
export const LOADING_ATTRIBUTE = 'LOADING_ATTRIBUTE';
export const GETTING_ATTRIBUTE = 'GETTING_ATTRIBUTE';
export const DETAIL_ATTRIBUTE = 'DETAIL_ATTRIBUTE';
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE'
export const DELETE_ATTRIBUTE = 'DELETE_ATTRIBUTE';
export const NOT_FOUND_ATTRIBUTE = 'NOT_FOUND_ATTRIBUTE'
export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE'
export const GETTING_ATTRIBUTE_OPTION_SIZE = 'GETTING_ATTRIBUTE_OPTION_SIZE'



// ATTRIBUTE OPTION ACTIONS
export const LOADING_ATTRIBUTE_OPTION = 'LOADING_ATTRIBUTE_OPTION';
export const GETTING_ATTRIBUTE_OPTION = 'GETTING_ATTRIBUTE_OPTION';
export const DETAIL_ATTRIBUTE_OPTION = 'DETAIL_ATTRIBUTE_OPTION';
export const UPDATE_ATTRIBUTE_OPTION = 'UPDATE_ATTRIBUTE_OPTION'
export const DELETE_ATTRIBUTE_OPTION = 'DELETE_ATTRIBUTE_OPTION';
export const NOT_FOUND_ATTRIBUTE_OPTION = 'NOT_FOUND_ATTRIBUTE_OPTION'

// TAGS ACTIONS
export const LOADING_TAGS = 'LOADING_TAGS';
export const FETCH_TAGS = 'FETCH_TAGS';
export const ADD_TAGS = 'ADD_TAGS';
export const DETAIL_TAGS = 'DETAIL_TAGS';
export const UPDATE_TAGS = 'UPDATE_TAGS'
export const DELETE_TAGS = 'DELETE_TAGS';
export const NOT_FOUND_TAGS = 'NOT_FOUND_TAGS'

// BRANDS ACTIONS
export const LOADING_BRANDS = 'LOADING_BRANDS';
export const FETCH_BRANDS = 'FETCH_BRANDS';
export const ADD_BRANDS = 'ADD_BRANDS';
export const DETAIL_BRANDS = 'DETAIL_BRANDS';
export const UPDATE_BRANDS = 'UPDATE_BRANDS'
export const DELETE_BRANDS = 'DELETE_BRANDS';
export const NOT_FOUND_BRANDS = 'NOT_FOUND_BRANDS'

//CUSTOMER ACTIONS
export const LOADING_CUSTOMER = 'LOADING_CUSTOMER'
export const FETCH_CUSTOMER = 'FETCH_CUSTOMER'
export const ADD_DATA_CUSTOMER = 'ADD_DATA_CUSTOMER'
export const DETAIL_CUSTOMER = 'DETAIL_CUSTOMER'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER'
export const NOT_FOUND_CUSTOMER = 'NOT_FOUND_CUSTOMER'
export const UPDATED_DATA_CUSTOMER = 'UPDATED_DATA_CUSTOMER'
export const EMAIL_SENDING = 'EMAIL_SENDING'


//SUPPORT TICKET ACTIONS
export const LOADING_SUPPORT_TICKET = 'LOADING_SUPPORT_TICKET'
export const FETCH_SUPPORT_TICKET = 'FETCH_SUPPORT_TICKET'
export const ADD_DATA_TICKET = 'ADD_DATA_TICKET'
export const DETAIL_SUPPORT_TICKET = 'DETAIL_SUPPORT_TICKET'
export const UPDATE_SUPPORT_TICKET = 'UPDATE_SUPPORT_TICKET'
export const DELETE_SUPPORT_TICKET = 'DELETE_SUPPORT_TICKET'
export const NOT_FOUND_SUPPORT_TICKET = 'NOT_FOUND_SUPPORT_TICKET'
export const UPDATED_DATA_TICKET = 'UPDATED_DATA_TICKET'

export const LOADING_SUPPORT_MESSAGES='LOADING_SUPPORT_MESSAGES'
export const FETCH_SUPPORT_MESSAGES='FETCH_SUPPORT_MESSAGES'
export const NOT_FOUND_SUPPORT_MESSAGES='NOT_FOUND_SUPPORT_MESSAGES'
export const LOADING_CONTACT_MESSAGES='LOADING_CONTACT_MESSAGES'
export const FETCH_CONTACT_MESSAGES='FETCH_CONTACT_MESSAGES'
export const NOT_FOUND_CONTACT_MESSAGES='NOT_FOUND_CONTACT_MESSAGES'


// USER ACTIONS
export const LOADING_USER = 'LOADING_USER';
export const FETCH_USER = 'FETCH_USER';
export const DETAIL_USER = 'DETAIL_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER'
export const NOT_FOUND_USER = 'NOT_FOUND_USER';


// WITHDRAW ACTIONS
export const LOADING_WITHDRAW = 'LOADING_WITHDRAW';
export const FETCH_WITHDRAW = 'FETCH_WITHDRAW';
export const DETAIL_WITHDRAW = 'DETAIL_WITHDRAW';
export const DELETE_WITHDRAW = 'DELETE_WITHDRAW';
export const UPDATE_WITHDRAW = 'UPDATE_WITHDRAW'
export const NOT_FOUND_WITHDRAW = 'NOT_FOUND_WITHDRAW';

// COUPON ACTIONS
export const LOADING_COUPON = 'LOADING_COUPON';
export const FETCH_COUPON = 'FETCH_COUPON';
export const DETAIL_COUPON = 'DETAIL_COUPON';
export const DELETE_COUPON = 'DELETE_COUPON';
export const UPDATE_COUPON = 'UPDATE_COUPON'
export const NOT_FOUND_COUPON = 'NOT_FOUND_COUPON';


// CAMPAIGN ACTIONS
export const LOADING_CAMPAIGN = 'LOADING_CAMPAIGN';
export const FETCH_CAMPAIGN = 'FETCH_CAMPAIGN';
export const DETAIL_CAMPAIGN = 'DETAIL_CAMPAIGN';
export const DELETE_CAMPAIGN = 'DELETE_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN'
export const NOT_FOUND_CAMPAIGN = 'NOT_FOUND_CAMPAIGN';


// CAMPAIGN GROUP ACTIONS
export const LOADING_CAMPAIGN_GROUP ='LOADING_CAMPAIGN_GROUP';
export const FETCH_CAMPAIGN_GROUP ='FETCH_CAMPAIGN_GROUP';
export const DETAIL_CAMPAIGN_GROUP='DETAIL_CAMPAIGN_GROUP';
export const UPDATE_CAMPAIGN_GROUP='UPDATE_CAMPAIGN_GROUP';
export const DELETE_CAMPAIGN_GROUP='DELETE_CAMPAIGN_GROUP';
export const NOT_FOUND_CAMPAIGN_GROUP = 'NOT_FOUND_CAMPAIGN_GROUP';


// TAX ACTIONS
export const LOADING_TAX = 'LOADING_TAX';
export const FETCH_TAX = 'FETCH_TAX';
export const DETAIL_TAX = 'DETAIL_TAX';
export const DELETE_TAX = 'DELETE_TAX';
export const UPDATE_TAX = 'UPDATE_TAX'
export const NOT_FOUND_TAX = 'NOT_FOUND_TAX';

// SHIPPING CLASS ACTIONS
export const LOADING_SHIPPING_CLASS = 'LOADING_SHIPPING_CLASS';
export const FETCH_SHIPPING_CLASS = 'FETCH_SHIPPING_CLASS';
export const DETAIL_SHIPPING_CLASS = 'DETAIL_SHIPPING_CLASS';
export const DELETE_SHIPPING_CLASS = 'DELETE_SHIPPING_CLASS';
export const UPDATE_SHIPPING_CLASS = 'UPDATE_SHIPPING_CLASS'
export const NOT_FOUND_SHIPPING_CLASS = 'NOT_FOUND_SHIPPING_CLASS';

// SHIPPING ZONE ACTIONS
export const LOADING_SHIPPING_ZONE = 'LOADING_SHIPPING_ZONE';
export const FETCH_SHIPPING_ZONE = 'FETCH_SHIPPING_ZONE';
export const DETAIL_SHIPPING_ZONE = 'DETAIL_SHIPPING_ZONE';
export const DELETE_SHIPPING_ZONE = 'DELETE_SHIPPING_ZONE';
export const UPDATE_SHIPPING_ZONE = 'UPDATE_SHIPPING_ZONE'
export const NOT_FOUND_SHIPPING_ZONE = 'NOT_FOUND_SHIPPING_ZONE';

// RETURNS ACTIONS
export const LOADING_RETURN = 'LOADING_RETURN';
export const FETCH_RETURN = 'FETCH_RETURN';
export const DETAIL_RETURN = 'DETAIL_RETURN';
export const DELETE_RETURN = 'DELETE_RETURN';
export const UPDATE_RETURN = 'UPDATE_RETURN'
export const NOT_FOUND_RETURN = 'NOT_FOUND_RETURN';

export const LOADING_SUBSCRIBER = 'LOADING_SUBSCRIBER';
export const FETCH_SUBSCRIBER = 'FETCH_SUBSCRIBER'
export const FETCH_SUBSCRIBER_FAILED = 'FETCH_SUBSCRIBER_FAILED'
export const NOT_FOUND_SUBSCRIBER = 'NOT_FOUND_SUBSCRIBER';

// PRODUCT UNIT 

export const LOADING_PRODUCT_UNIT = 'LOADING_PRODUCT_UNIT';
export const GETTING_PRODUCT_UNIT = 'GETTING_PRODUCT_UNIT';
export const ADD_PRODUCT_UNIT = 'ADD_PRODUCT_UNIT';
export const DETAIL_PRODUCT_UNIT = 'DETAIL_PRODUCT_UNIT';
export const UPDATE_PRODUCT_UNIT = 'UPDATE_PRODUCT_UNIT'
export const DELETE_PRODUCT_UNIT = 'DELETE_PRODUCT_UNIT';
export const NOT_FOUND_PRODUCT_UNIT = 'NOT_FOUND_PRODUCT_UNIT';


//BUNDLE 

export const LOADING_BUNDLE = 'LOADING_BUNDLE';
export const FETCH_BUNDLE = 'FETCH_BUNDLE';
export const DETAIL_BUNDLE = 'DETAIL_BUNDLE';
export const DELETE_BUNDLE = 'DELETE_PRODUCT_UNIT';
export const NOT_FOUND_BUNDLE = 'NOT_FOUND_BUNDLE';

//DISTRICT
export const LOADING_DISTRICT = 'LOADING_DISTRICT';
export const FETCH_DISTRICT = 'FETCH_DISTRICT';
export const DETAIL_DISTRICT = 'DETAIL_DISTRICT';
export const UPDATE_DISTRICT = 'UPDATE_DISTRICT'
export const DELETE_DISTRICT = 'DELETE_DISTRICT';
export const NOT_FOUND_DISTRICT = 'NOT_FOUND_DISTRICT';

// CONTACT INFORMATION
export const LOADING_CONTACT_INFO = 'LOADING_CONTACT_INFO';
export const FETCH_CONTACT_INFO = 'FETCH_CONTACT_INFO';
export const DETAIL_CONTACT_INFO = 'DETAIL_CONTACT_INFO';
export const UPDATE_CONTACT_INFO = 'UPDATE_CONTACT_INFO'
export const DELETE_CONTACT_INFO = 'DELETE_CONTACT_INFO';
export const NOT_FOUND_CONTACT_INFO = 'NOT_FOUND_CONTACT_INFO';

// COPY RIGHT
export const LOADING_COPYRIGHT = 'LOADING_COPYRIGHT';
export const FETCH_COPYRIGHT = 'FETCH_COPYRIGHT';
export const DETAIL_COPYRIGHT = 'DETAIL_COPYRIGHT';
export const UPDATE_COPYRIGHT = 'UPDATE_COPYRIGHT'
export const DELETE_COPYRIGHT = 'DELETE_COPYRIGHT';
export const NOT_FOUND_COPYRIGHT = 'NOT_FOUND_COPYRIGHT';


// FOOTER PAYMENT IMAGE

export const LOADING_FOOTER_PAYMENT_IMAGE = 'LOADING_FOOTER_PAYMENT_IMAGE';
export const FETCH_FOOTER_PAYMENT_IMAGE = 'FETCH_FOOTER_PAYMENT_IMAGE';
export const DETAIL_FOOTER_PAYMENT_IMAGE = 'DETAIL_FOOTER_PAYMENT_IMAGE';
export const UPDATE_FOOTER_PAYMENT_IMAGE = 'UPDATE_FOOTER_PAYMENT_IMAGE'
export const DELETE_FOOTER_PAYMENT_IMAGE = 'DELETE_FOOTER_PAYMENT_IMAGE';
export const NOT_FOUND_FOOTER_PAYMENT_IMAGE = 'NOT_FOUND_FOOTER_PAYMENT_IMAGE';



// TERMS CONDITION

export const LOADING_TERMS_CONDITION = 'LOADING_TERMS_CONDITION';
export const FETCH_TERMS_CONDITION = 'FETCH_TERMS_CONDITION';
export const DETAIL_TERMS_CONDITION = 'DETAIL_TERMS_CONDITION';
export const UPDATE_TERMS_CONDITION = 'UPDATE_TERMS_CONDITION'
export const DELETE_TERMS_CONDITION = 'DELETE_TERMS_CONDITION';
export const NOT_FOUND_TERMS_CONDITION = 'NOT_FOUND_TERMS_CONDITION';


// SHIPPING POLICY
export const LOADING_SHIPPING_POLICY ='LOADING_SHIPPING_POLICY';
export const FETCH_SHIPPING_POLICY = 'FETCH_SHIPPING_POLICY';
export const DETAIL_SHIPPING_POLICY ='DETAIL_SHIPPING_POLICY';
export const DELETE_SHIPPING_POLICY ='DELETE_SHIPPING_POLICY';
export const UPDATE_SHIPPING_POLICY ='UPDATE_SHIPPING_POLICY';
export const NOT_FOUND_SHIPPING_POLICY ='NOT_FOUND_SHIPPING_POLICY';


// PRIVACY POLICY
export const LOADING_PRIVACY_POLICY ='LOADING_PRIVACY_POLICY';
export const FETCH_PRIVACY_POLICY = 'FETCH_PRIVACY_POLICY';
export const DETAIL_PRIVACY_POLICY ='DETAIL_PRIVACY_POLICY';
export const DELETE_PRIVACY_POLICY ='DELETE_PRIVACY_POLICY';
export const UPDATE_PRIVACY_POLICY ='UPDATE_PRIVACY_POLICY';
export const NOT_FOUND_PRIVACY_POLICY ='NOT_FOUND_PRIVACY_POLICY';


// RETURN REFUND POLICY
export const LOADING_RETURN_REFUND_POLICY ='LOADING_RETURN_REFUND_POLICY';
export const FETCH_RETURN_REFUND_POLICY = 'FETCH_RETURN_REFUND_POLICY';
export const DETAIL_RETURN_REFUND_POLICY ='DETAIL_RETURN_REFUND_POLICY';
export const DELETE_RETURN_REFUND_POLICY ='DELETE_RETURN_REFUND_POLICY';
export const UPDATE_RETURN_REFUND_POLICY ='UPDATE_RETURN_REFUND_POLICY';
export const NOT_FOUND_RETURN_REFUND_POLICY ='NOT_FOUND_RETURN_REFUND_POLICY';


// ABOUT US
export const LOADING_ABOUT_US ='LOADING_ABOUT_US';
export const FETCH_ABOUT_US ='FETCH_ABOUT_US';
export const DETAIL_ABOUT_US ='DETAIL_ABOUT_US';
export const DELETE_ABOUT_US ='DELETE_ABOUT_US';
export const UPDATE_ABOUT_US ='UPDATE_ABOUT_US';
export const NOT_FOUND_ABOUT_US ='NOT_FOUND_ABOUT_US';


// FAQ
export const LOADING_FAQ ='LOADING_FAQ';
export const FETCH_FAQ ='FETCH_FAQ';
export const DETAIL_FAQ ='DETAIL_FAQ';
export const DELETE_FAQ ='DELETE_FAQ';
export const UPDATE_FAQ ='UPDATE_FAQ';
export const NOT_FOUND_FAQ ='NOT_FOUND_FAQ';



// LOGO
export const LOADING_LOGO = 'LOADING_LOGO';
export const FETCH_LOGO = 'FETCH_LOGO';
export const DETAIL_LOGO = 'DETAIL_LOGO';
export const UPDATE_LOGO = 'UPDATE_LOGO'
export const DELETE_LOGO = 'DELETE_LOGO';
export const NOT_FOUND_LOGO = 'NOT_FOUND_LOGO';


// FOOTER LOGO
export const LOADING_FOOTER_LOGO ='LOADING_FOOTER_LOGO';
export const FETCH_FOOTER_LOGO ='FETCH_FOOTER_LOGO';
export const DETAIL_FOOTER_LOGO ='DETAIL_FOOTER_LOGO';
export const DELETE_FOOTER_LOGO ='DELETE_FOOTER_LOGO';
export const UPDATE_FOOTER_LOGO ='UPDATE_FOOTER_LOGO';
export const NOT_FOUND_FOOTER_LOGO ='NOT_FOUND_FOOTER_LOGO';



// FAVICON
export const LOADING_FAVICON = 'LOADING_FAVICON';
export const FETCH_FAVICON = 'FETCH_FAVICON';
export const DETAIL_FAVICON = 'DETAIL_FAVICON';
export const UPDATE_FAVICON = 'UPDATE_FAVICON'
export const DELETE_FAVICON = 'DELETE_FAVICON';
export const NOT_FOUND_FAVICON = 'NOT_FOUND_FAVICON';


// PRELOADER

export const LOADING_PRELOADER = 'LOADING_PRELOADER';
export const FETCH_PRELOADER = 'FETCH_PRELOADER';
export const DETAIL_PRELOADER = 'DETAIL_PRELOADER';
export const UPDATE_PRELOADER = 'UPDATE_PRELOADER'
export const DELETE_PRELOADER = 'DELETE_PRELOADER';
export const NOT_FOUND_PRELOADER = 'NOT_FOUND_PRELOADER';

//SLIDE SETTING
export const LOADING_SLIDE_SETTING = 'LOADING_SLIDE_SETTING';
export const FETCH_SLIDE_SETTING = 'FETCH_SLIDE_SETTING';
export const DETAIL_SLIDE_SETTING = 'DETAIL_SLIDE_SETTING';
export const UPDATE_SLIDE_SETTING = 'UPDATE_SLIDE_SETTING'
export const DELETE_SLIDE_SETTING = 'DELETE_SLIDE_SETTING';
export const NOT_FOUND_SLIDE_SETTING = 'NOT_FOUND_SLIDE_SETTING';


//SLIDE RIGHT SIDE SETTING
export const LOADING_SLIDE_RIGHT_SIDE_SETTING = 'LOADING_SLIDE_RIGHT_SIDE_SETTING';
export const FETCH_SLIDE_RIGHT_SIDE_SETTING = 'FETCH_SLIDE_RIGHT_SIDE_SETTING';
export const DETAIL_SLIDE_RIGHT_SIDE_SETTING = 'DETAIL_SLIDE_RIGHT_SIDE_SETTING';
export const UPDATE_SLIDE_RIGHT_SIDE_SETTING = 'UPDATE_SLIDE_RIGHT_SIDE_SETTING'
export const DELETE_SLIDE_RIGHT_SIDE_SETTING = 'DELETE_SLIDE_RIGHT_SIDE_SETTING';
export const NOT_FOUND_SLIDE_RIGHT_SIDE_SETTING = 'NOT_FOUND_SLIDE_RIGHT_SIDE_SETTING';


//BANNER SETTING
export const LOADING_BANNER_SETTING = 'LOADING_BANNER_SETTING';
export const FETCH_BANNER_SETTING = 'FETCH_BANNER_SETTING';
export const DETAIL_BANNER_SETTING = 'DETAIL_BANNER_SETTING';
export const UPDATE_BANNER_SETTING = 'UPDATE_BANNER_SETTING'
export const DELETE_BANNER_SETTING = 'DELETE_BANNER_SETTING';
export const NOT_FOUND_BANNER_SETTING = 'NOT_FOUND_BANNER_SETTING';


//SOCIAL MEDIA SETTING
export const LOADING_SOCIAL_MEDIA = 'LOADING_SOCIAL_MEDIA';
export const FETCH_SOCIAL_MEDIA = 'FETCH_SOCIAL_MEDIA';
export const DETAIL_SOCIAL_MEDIA = 'DETAIL_SOCIAL_MEDIA';
export const UPDATE_SOCIAL_MEDIA = 'UPDATE_SOCIAL_MEDIA'
export const DELETE_SOCIAL_MEDIA = 'DELETE_SOCIAL_MEDIA';
export const NOT_FOUND_SOCIAL_MEDIA = 'NOT_FOUND_SOCIAL_MEDIA';


//FOOTER LEFT SECTION SETTING
export const LOADING_FOOTER_LEFT_SECTION = 'LOADING_FOOTER_LEFT_SECTION';
export const FETCH_FOOTER_LEFT_SECTION = 'FETCH_FOOTER_LEFT_SECTION';
export const DETAIL_FOOTER_LEFT_SECTION = 'DETAIL_FOOTER_LEFT_SECTION';
export const UPDATE_FOOTER_LEFT_SECTION = 'UPDATE_FOOTER_LEFT_SECTION'
export const DELETE_FOOTER_LEFT_SECTION = 'DELETE_FOOTER_LEFT_SECTION';
export const NOT_FOUND_FOOTER_LEFT_SECTION = 'NOT_FOUND_FOOTER_LEFT_SECTION';


//BLOG MANAGEMENT
export const LOADING_BLOG_POST = 'LOADING_BLOG_POST';
export const FETCH_BLOG_POST = 'FETCH_BLOG_POST';
export const DETAIL_BLOG_POST = 'DETAIL_BLOG_POST';
export const UPDATE_BLOG_POST = 'UPDATE_BLOG_POST'
export const DELETE_BLOG_POST = 'DELETE_BLOG_POST';
export const NOT_FOUND_BLOG_POST = 'NOT_FOUND_BLOG_POST';


//BLOG CATEGORY
export const LOADING_BLOG_CATEGORY = 'LOADING_BLOG_CATEGORY';
export const FETCH_BLOG_CATEGORY = 'FETCH_BLOG_CATEGORY';
export const DETAIL_BLOG_CATEGORY = 'DETAIL_BLOG_CATEGORY';
export const UPDATE_BLOG_CATEGORY = 'UPDATE_BLOG_CATEGORY'
export const DELETE_BLOG_CATEGORY = 'DELETE_BLOG_CATEGORY';
export const NOT_FOUND_BLOG_CATEGORY = 'NOT_FOUND_BLOG_CATEGORY';


//BLOG TAG
export const LOADING_BLOG_TAG = 'LOADING_BLOG_TAG';
export const FETCH_BLOG_TAG = 'FETCH_BLOG_TAG';
export const DETAIL_BLOG_TAG = 'DETAIL_BLOG_TAG';
export const UPDATE_BLOG_TAG = 'UPDATE_BLOG_TAG'
export const DELETE_BLOG_TAG = 'DELETE_BLOG_TAG';
export const NOT_FOUND_BLOG_TAG = 'NOT_FOUND_BLOG_TAG';


//MEDIA LIBRARY
export const LOADING_MEDIA_LIBRARY = 'LOADING_MEDIA_LIBRARY';
export const FETCH_MEDIA_LIBRARY = 'FETCH_MEDIA_LIBRARY';
export const DETAIL_MEDIA_LIBRARY = 'DETAIL_MEDIA_LIBRARY';
export const ADD_MEDIA_LIBRARY = 'ADD_MEDIA_LIBRARY';

export const UPDATE_MEDIA_LIBRARY = 'UPDATE_MEDIA_LIBRARY'
export const DELETE_MEDIA_LIBRARY = 'DELETE_MEDIA_LIBRARY';
export const NOT_FOUND_MEDIA_LIBRARY = 'NOT_FOUND_MEDIA_LIBRARY';


// WAREHOUSE ACTIONS
export const LOADING_WAREHOUSE = 'LOADING_WAREHOUSE';
export const FETCH_WAREHOUSE = 'FETCH_WAREHOUSE';
export const NOT_FOUND_WAREHOUSE = 'NOT_FOUND_WAREHOUSE';
export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE';
export const DETAIL_WAREHOUSE ='DETAIL_WAREHOUSE';


export const FETCH_STOCK = 'FETCH_STOCK';
export const NOT_FOUND_STOCK = 'NOT_FOUND_STOCK';
export const DETAIL_STOCK = 'DETAIL_STOCK';
export const LOADING_STOCK = 'LOADING_STOCK';
export const DELETE_STOCK = 'DELETE_STOCK';


export const GET_AGENTS ='GET_AGENTS';
export const DETAIL_AGENTS ='DETAIL_AGENTS';
export const LOADING_AGENTS ='LOADING_AGENTS';
export const DELETE_AGENTS ='DELETE_AGENTS';
export const UPDATE_AGENTS ='UPDATE_AGENTS';
export const NOT_FOUND_AGENTS ='NOT_FOUND_AGENTS'; 


export const LOADING_AGENT_CUSTOMER='LOADING_AGENT_CUSTOMER';
export const GET_AGENT_CUSTOMER='GET_AGENT_CUSTOMER';
export const DETAIL_AGENT_CUSTOMER = 'DETAIL_AGENT_CUSTOMER';
export const DELETE_AGENT_CUSTOMER = 'DELETE_AGENT_CUSTOMER';
export const NOT_FOUND_AGENT_CUSTOMER = 'NOT_FOUND_AGENT_CUSTOMER';


// AGENT PRODUCT STOCK
export const LOADING_AGENT_PRODUCT_STOCK= 'LOADING_AGENT_PRODUCT_STOCK'
export const FETCH_AGENT_PRODUCT_STOCK= 'FETCH_AGENT_PRODUCT_STOCK'
export const DETAIL_AGENT_PRODUCT_STOCK='DETAIL_AGENT_PRODUCT_STOCK';
export const DELETE_AGENT_PRODUCT_STOCK='DELETE_AGENT_PRODUCT_STOCK';
export const NOT_FOUND_AGENT_PRODUCT_STOCK= 'NOT_FOUND_AGENT_PRODUCT_STOCK'


//AGENT PRODUCT STOCK REPORT 
export const LOADING_AGENT_PRODUCT_STOCK_REPORTS = 'LOADING_AGENT_PRODUCT_STOCK_REPORTS';
export const FETCH_AGENT_PRODUCT_STOCK_REPORTS = 'FETCH_AGENT_PRODUCT_STOCK_REPORTS';
export const NOT_FOUND_AGENT_PRODUCT_STOCK_REPORTS = 'NOT_FOUND_AGENT_PRODUCT_STOCK_REPORTS';
export const DETAIL_AGENT_PRODUCT_STOCK_REPORTS = 'DETAIL_AGENT_PRODUCT_STOCK_REPORTS';
export const DELETE_AGENT_PRODUCT_STOCK_REPORTS = 'DELETE_AGENT_PRODUCT_STOCK_REPORTS';


// AGENT PRODUCT SALE REPORT
export const LOADING_AGENT_PRODUCT_SALE_REPORTS = 'LOADING_AGENT_PRODUCT_SALE_REPORTS';
export const FETCH_AGENT_PRODUCT_SALE_REPORTS = 'FETCH_AGENT_PRODUCT_SALE_REPORTS';
export const DETAIL_AGENT_PRODUCT_SALE_REPORTS = 'DETAIL_AGENT_PRODUCT_SALE_REPORTS';
export const DELETE_AGENT_PRODUCT_SALE_REPORTS = 'DELETE_AGENT_PRODUCT_SALE_REPORTS';
export const NOT_FOUND_AGENT_PRODUCT_SALE_REPORTS = 'NOT_FOUND_AGENT_PRODUCT_SALE_REPORTS';

// AGENT PRODUCT SALE

export const LOADING_AGENT_PRODUCT_SALE ='LOADING_AGENT_PRODUCT_SALE';
export const FETCH_AGENT_PRODUCT_SALE ='FETCH_AGENT_PRODUCT_SALE';
export const DETAIL_AGENT_PRODUCT_SALE ='DETAIL_AGENT_PRODUCT_SALE';
export const DELETE_AGENT_PRODUCT_SALE ='DELETE_AGENT_PRODUCT_SALE';
export const NOT_FOUND_AGENT_PRODUCT_SALE ='NOT_FOUND_AGENT_PRODUCT_SALE';


// AGENT REPORTS
export const LOADING_AGENT_REPORTS ='LOADING_AGENT_REPORTS';
export const FETCH_AGENT_REPORTS ='FETCH_AGENT_REPORTS';
export const DETAIL_AGENT_REPORTS ='DETAIL_AGENT_REPORTS';
export const DELETE_AGENT_REPORTS ='DELETE_AGENT_REPORTS';
export const NOT_FOUND_AGENT_REPORTS ='NOT_FOUND_AGENT_REPORTS';


// PRODUCT STOCK REPORTS
export const  LOADING_PRODUCT_STOCK_REPORTS='LOADING_PRODUCT_STOCK_REPORTS';
export const  FETCH_PRODUCT_STOCK_REPORTS='FETCH_PRODUCT_STOCK_REPORTS';
export const  DETAIL_PRODUCT_STOCK_REPORTS='DETAIL_PRODUCT_STOCK_REPORTS';
export const  DELETE_PRODUCT_STOCK_REPORTS='DELETE_PRODUCT_STOCK_REPORTS';
export const  NOT_FOUND_PRODUCT_STOCK_REPORTS='NOT_FOUND_PRODUCT_STOCK_REPORTS';



// SALES REPORT
export const LOADING_SALES_REPORTS ='LOADING_SALES_REPORTS';
export const FETCH_SALES_REPORTS ='FETCH_SALES_REPORTS';
export const DETAIL_SALES_REPORTS ='DETAIL_SALES_REPORTS';
export const DELETE_SALES_REPORTS ='DELETE_SALES_REPORTS';
export const NOT_FOUND_SALES_REPORTS ='NOT_FOUND_SALES_REPORTS';
import {
    LOADING_BLOG_POST,
    FETCH_BLOG_POST,
    DETAIL_BLOG_POST,
    DELETE_BLOG_POST,
    UPDATE_BLOG_POST,
    NOT_FOUND_BLOG_POST,
    LOADING_BLOG_CATEGORY,
    FETCH_BLOG_CATEGORY,
    DETAIL_BLOG_CATEGORY,
    DELETE_BLOG_CATEGORY,
    UPDATE_BLOG_CATEGORY,
    NOT_FOUND_BLOG_CATEGORY,
    LOADING_BLOG_TAG,
    FETCH_BLOG_TAG,
    DETAIL_BLOG_TAG,
    DELETE_BLOG_TAG,
    UPDATE_BLOG_TAG,
    NOT_FOUND_BLOG_TAG,


} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { BLOG_POST_URL,BLOG_URL, BLOG_CATEGORY_URL, BLOG_TAG_URL,BLOG_TAG_ALL_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


//***************************** START BLOG POST ********************************** */

//Blog Post
export const fetchBlogPost = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_BLOG_POST });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["title"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = BLOG_POST_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_BLOG_POST,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_BLOG_POST });

    }
};


// // Blog Post Detail
export const blogPostDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = BLOG_POST_URL + `${id}/`;
    axios
        .get(url, { headers })
        .then(response => {
            // alert(JSON.stringify(response.data))
            dispatch({
                type: DETAIL_BLOG_POST,
                payload: response.data,
            })
        })
};



// Delete Blog
export const deleteBlogPost = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(BLOG_POST_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_BLOG_POST,
                payload: id,
            });
            toast.success('Blog Post has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//***************************** END BLOG POST********************************** */


//***************************** START BLOG CATEGORY ********************************** */

//Blog Category
export const fetchBlogCategory = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_BLOG_CATEGORY });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = BLOG_CATEGORY_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_BLOG_CATEGORY,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_BLOG_CATEGORY });

    }
};



// Blog Category Detail
export const blogCategoryDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = BLOG_CATEGORY_URL + `${id}/`;
    axios
        .get(url, { headers })
        .then(response => {
            // alert(JSON.stringify(response.data))
            dispatch({
                type: DETAIL_BLOG_CATEGORY,
                payload: response.data,
            })
        })
};



// Delete Blog Category
export const deleteBlogCategory = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(BLOG_CATEGORY_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_BLOG_CATEGORY,
                payload: id,
            });
            toast.success('Blog Category has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//***************************** END BLOG CATEGORY********************************** */


//***************************** START BLOG TAG ********************************** */

//Blog Tag
export const fetchBlogTag = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_BLOG_TAG });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = BLOG_TAG_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_BLOG_TAG,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_BLOG_TAG });

    }
};




// Blog Tag Detail
export const blogTagDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = BLOG_TAG_URL + `${id}/`;
    axios
        .get(url, { headers })
        .then(response => {
            // alert(JSON.stringify(response.data))
            dispatch({
                type: DETAIL_BLOG_TAG,
                payload: response.data,
            })
        })
};





// Select multi tag for blog
export const fetchBlogTagForSelect = (name) => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    dispatch({ type: LOADING_BLOG_TAG });
    var url = ''
    if (name != '') {
        url = BLOG_TAG_ALL_URL
    } else {
        url = BLOG_TAG_ALL_URL + '?search=' + name
    }


    axios.get(url, { headers, })
        .then(response => {
            //  alert(JSON.stringify(response.data.results.name))
            var results = []
            for (var i = 0; i < response.data.length; i++) {
                results.push({ label: response.data[i].name, value: response.data[i].id })
            }
            dispatch({
                type: FETCH_BLOG_TAG,
                payload: response.data ? results : [],
                count: response.data.count,
                next: response.data.next
            })
        })




};




// Delete Blog Tag
export const deleteBlogTag = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(BLOG_TAG_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_BLOG_TAG,
                payload: id,
            });
            toast.success('Blog Tag has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//***************************** END BLOG TAG********************************** */
import {
    LOADING_ORDERS,
    FETCH_ORDERS,
    DELETE_ORDER,
    UPDATE_ORDER,
    NOT_FOUND_ORDER, ADD_DATA, UPDATED_DATA,
    DETAIL_ORDER, FETCH_PAYMENT_METHODS,
    EDIT_PAYMENT_METHOD, DETAIL_PAYMENT_METHOD,
    DELETE_PAYMENT_METHOD, LOADING_PAYMENT_METHOD, ADD_PAYMENT, ADD_ORDER, NOT_FOUND_PAYMENT


} from '../actions/types';


export const OrderReducer = (state = {
    orders: [],
    order: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    detail: false,
    is_order_getting: false,
    is_updated: false,
}, action) => {
    switch (action.type) {
        case LOADING_ORDERS:
            return {
                ...state,
                is_loading: true
            };
        case DETAIL_ORDER:
            return {
                ...state,
                order: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                detail: true
            };
        case FETCH_ORDERS:
            return {
                ...state,
                orders: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                is_fetching:true,

            };
        case UPDATE_ORDER:
            var filteredDataSource = state.orders.filter((item) => {
                if (item.id === action.payload.id) {
                    item.name = action.payload.name;
                }

                return item;
            });

            return {
                ...state,
                order: filteredDataSource,
                is_loading: false
            };
        case NOT_FOUND_PAYMENT:
            return {
                ...state,
                orders: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}


export const PaymentReducer = (state = {
    payments: [],
    payment: {},
    count: null,
    next: null,
    is_added: false,
    is_fetching: false,
    is_loading: false,
    is_payment_getting: false,
    is_updated: false,
}, action) => {
    switch (action.type) {
        case LOADING_PAYMENT_METHOD:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_PAYMENT_METHODS:
            return {
                ...state,
                payments: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case EDIT_PAYMENT_METHOD:
            var filteredDataSource = state.payments.filter((item) => {
                if (item.id === action.payload.id) {
                    item.name = action.payload.name;
                }

                return item;
            });

            return {
                ...state,
                payment: filteredDataSource,
                is_loading: false
            };
        case DETAIL_PAYMENT_METHOD:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_payment_getting: true,
                payments: action.payload,

            };
        case DELETE_PAYMENT_METHOD:
            return {
                ...state,
                payments: state.payments.filter(payment => payment.id !== action.payload)
            };
        case ADD_PAYMENT:
            return {
                ...state,
                payments: action.payload,

            };
        case ADD_DATA:
            return {
                ...state,
                is_added: true,
                payments: [action.payload]
            };

        case UPDATED_DATA:
            return {
                ...state,
                is_updated: true,
                // customers: action.payload

            };

        case NOT_FOUND_PAYMENT:
            return {
                ...state,
                payments: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}
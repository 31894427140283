import {
    LOADING_RETURN,
    FETCH_RETURN,
    ADD_DATA,
    DETAIL_RETURN,
    UPDATE_RETURN,
    DELETE_RETURN,
    NOT_FOUND_RETURN,
    UPDATED_DATA,


} from '../actions/types';

const initialState = {
    return_refunds: [],
    return_refund: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_RETURN:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_RETURN:
            return {
                ...state,
                return_refunds: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case UPDATE_RETURN:
            // alert(JSON.stringify(action.payload))
            return {

                ...state,
                return_refunds: action.payload,
                is_updated: true
            };
        case DETAIL_RETURN:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                return_refunds: action.payload,
                is_loading:true

            };
        case DELETE_RETURN:
            return {
                ...state,
                return_refunds: state.return_refunds.filter(return_refund => return_refund.id !== action.payload)
            };
        case ADD_DATA:
            return {
                ...state,
                is_added: true,
                return_refunds: [action.payload]
            };

        case UPDATED_DATA:
            return {
                ...state,
                is_updated: true,
                // customers: action.payload

            };


        case NOT_FOUND_RETURN:
            return {
                ...state,
                return_refunds: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

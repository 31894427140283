import React, {Component} from 'react';
import {NavLink,Link} from "react-router-dom";
import {loadUser, logout} from "../actions/auth";
import {userStatistics} from '../actions/DashboardActions'
import { getAgents } from '../actions/AgentActions';
import {connect} from 'react-redux';


// import { Chart } from 'react-charts'

// import { Bar } from "react-chartjs-2";



class Dashboard extends Component {
    state = {
        dataBar: '',
        barChartOptions: {
            responsive: true,
            // maintainAspectRatio: false,
            scales: {
                xAxes: [
                    {
                      scaleLabel: {
                        display: true,
                        labelString: 'Total',
                        fontColor: 'Black',
                        fontSize: 20
                      }
                    }
                  ],
                  yAxes: [{
                    ticks: {
                      beginAtZero: true
                    }
                  }]
            
            }
          },
          todaysData: '',
          todaysChartOptions: {
              responsive: true,
              // maintainAspectRatio: false,
              scales: {
                  xAxes: [
                      {
                        scaleLabel: {
                          display: true,
                          labelString: 'Today',
                          fontColor: 'Black',
                          fontSize: 20
                        }
                      }
                    ],
                    yAxes: [{
                      ticks: {
                        beginAtZero: true,
                      }
                    }]
              
              }
            }
        }

    static getDerivedStateFromProps(nextProps, prevState) {


        if(nextProps.user_state  && typeof nextProps.user_state == 'object' && Object.keys(nextProps.user_state).length>0){

            // total_agent: 4
            // total_director: 2
            // total_investor: 0
            // total_partner: 13
            // total_staff: 0
            // total_user: 19
            let lebels=[],data=[],todaysLebel=[],todaysData=[]

           

            if(nextProps.user_state.total_user > 0){
                lebels.push("Riders");
                data.push(nextProps.user_state.total_user)
            }
            if(nextProps.user_state.total_partner > 0){
                lebels.push("Partners");
                data.push(nextProps.user_state.total_partner)
            }
            if(nextProps.user_state.total_director > 0){
                lebels.push("RD");
                data.push(nextProps.user_state.total_director)
            }
            if(nextProps.user_state.total_agent > 0){
                lebels.push("Agents");
                data.push(nextProps.user_state.total_agent)
            }
            if(nextProps.user_state.total_staff > 0){
                lebels.push("Employes");
                data.push(nextProps.user_state.total_staff)
            }
            if(nextProps.user_state.total_investor > 0){
                lebels.push("Investors");
                data.push(nextProps.user_state.total_investor)
            }
          
            if(nextProps.user_state.todays_user > 0){
                todaysLebel.push("Riders");
                todaysData.push(nextProps.user_state.todays_user)
            }
            if(nextProps.user_state.todays_partner > 0){
                todaysLebel.push("New Partners");
                todaysData.push(nextProps.user_state.total_partner)
            }
            if(nextProps.user_state.todays_agent > 0){
                todaysLebel.push("New Agents");
                todaysData.push(nextProps.user_state.todays_partner)
            }
            if(nextProps.user_state.todays_live_trip > 0){
                todaysLebel.push("Live Trip");
                todaysData.push(nextProps.user_state.todays_live_trip)
            }
            if(nextProps.user_state.todays_completed_trip > 0){
                todaysLebel.push("Completed Trip");
                todaysData.push(nextProps.user_state.todays_completed_trip)
            }

            if(nextProps.user_state.todays_cancel_trip > 0){
                todaysLebel.push("Todays Cancel Trip");
                todaysData.push(nextProps.user_state.todays_cancel_trip)
            }  
           
          

            return {
                dataBar:{
                    labels: lebels,
                    datasets: [
                      {
                        label: "",
                        data: data,
                        backgroundColor: [
                          "rgba(255, 134,159,0.4)",
                          "rgba(98,  182, 239,0.4)",
                          "rgba(255, 218, 128,0.4)",
                          "rgba(113, 205, 205,0.4)",
                          "rgba(170, 128, 252,0.4)",
                          "rgba(255, 177, 101,0.4)"
                        ],
                        borderWidth: 2,
                        borderColor: [
                          "rgba(255, 134, 159, 1)",
                          "rgba(98,  182, 239, 1)",
                          "rgba(255, 218, 128, 1)",
                          "rgba(113, 205, 205, 1)",
                          "rgba(170, 128, 252, 1)",
                          "rgba(255, 177, 101, 1)"
                        ]
                      }
                    ]
                  },
                  todaysData:{
                    labels: todaysLebel,
                    datasets: [
                      {
                        label: "",
                        data: todaysData,
                        backgroundColor: [
                          "rgba(255, 134,159,0.4)",
                          "rgba(98,  182, 239,0.4)",
                          "rgba(255, 218, 128,0.4)",
                          "rgba(113, 205, 205,0.4)",
                          "rgba(170, 128, 252,0.4)",
                          "rgba(255, 177, 101,0.4)"
                        ],
                        borderWidth: 2,
                        borderColor: [
                          "rgba(255, 134, 159, 1)",
                          "rgba(98,  182, 239, 1)",
                          "rgba(255, 218, 128, 1)",
                          "rgba(113, 205, 205, 1)",
                          "rgba(170, 128, 252, 1)",
                          "rgba(255, 177, 101, 1)"
                        ]
                      }
                    ]
                  }
               
                   
            
           }

            
        }
        // if (prevState.someMirroredValue !== nextProps.someValue) {
        //     return {
        //     derivedData: computeDerivedState(nextProps),
        //     someMirroredValue: nextProps.someValue
        //     };
        // }
    
        // Return null to indicate no change to state.
        return null;
        }    
    componentDidMount() {
        // alert(JSON.stringify(this.props.agents[0]))
        this.props.userStatistics()
        this.props.getAgents(0)
        // const {user_state} = this.props       
    }

    render() {
        
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Dashboard</h1>
                            </div>
                        </div>
                    </div>
                
                </div>   
                <div className="row">
                    { this.props.auth.user.is_admin|| this.props.auth.user.group==2 ? ( 
                    
                    
                    <div className="col-lg-2 col-6">
                        {/* small box */}

                        <div className="small-box bg-gradient-blue">
                            <div className="inner">
                                <h3>{this.props.user_state.total_order_pending}</h3>
                                <p><Link to="/order-list" style={{color:'white'}}> Total Orders Pending </Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag"/>
                            </div>
                            {/* <NavLink to="/user-list" href="#" className="small-box-footer">More info <i
                                className="fas fa-arrow-circle-right"/></NavLink> */}
                        </div>
                    </div>)
                : null    
                }

                { this.props.auth.user.is_admin || this.props.auth.user.group==2  ? ( 
                    <div className="col-lg-2 col-6">
                        {/* small box */}
                        <div className="small-box bg-info">
                            <div className="inner">
                                <h3>{this.props.user_state.total_order_completed}</h3>
                                <p><Link to="/order-list" style={{color:'white'}}> Total Orders Completed </Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-stats-bars"/>
                            </div>
                            {/* <NavLink to="/partner-list" href="#" className="small-box-footer">More info <i
                                className="fas fa-arrow-circle-right"/></NavLink> */}
                        </div>
                    </div>
                    )
                    :null
    }
    { this.props.auth.user.is_admin || this.props.auth.user.is_agent  || this.props.auth.user.group==2  ? ( 
                    <div className="col-lg-2 col-6">
                        {/* small box */}
                        <div className="small-box bg-info">
                            <div className="inner">
                                <h3>{this.props.user_state.total_sale_price !==undefined && this.props.user_state.total_sale_price.total>0 ? this.props.user_state.total_sale_price.total:0}</h3>
                                <p>Total Sale Amount</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-stats-bars"/>
                            </div>
                            {/* <NavLink to="/partner-list" href="#" className="small-box-footer">More info <i
                                className="fas fa-arrow-circle-right"/></NavLink> */}
                        </div>
                    </div>
                    )
                    :null
    }
    { this.props.auth.user.is_admin  ? ( 
                    <div className="col-lg-2 col-6">
                        {/* small box */}
                        <div className="small-box bg-success">
                            <div className="inner">
                                <h3>{this.props.user_state.total_product}</h3>

                                <p><Link to="/product-list" style={{color:'white'}}>Total Product </Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag"/>
                            </div>
                            {/* <NavLink to="/rd-list" href="#" className="small-box-footer">More info <i
                                className="fas fa-arrow-circle-right"/></NavLink> */}
                        </div>
                    </div>)
                    : null
                  }
                  { this.props.auth.user.is_admin ? ( 
                    <div className="col-lg-2 col-6">
                        {/* small box */}
                        <div className="small-box bg-warning">
                            <div className="inner">
                                <h3>{this.props.user_state.total_customer}</h3>

                                <p><Link to="/customer-list" style={{color:'white'}}> Total Customer </Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-man"/>
                            </div>
                            {/* <NavLink to="/rd-list" href="#" className="small-box-footer">More info <i
                                className="fas fa-arrow-circle-right"/></NavLink> */}
                        </div>
                    </div>)
                    : null
                  }
                   { this.props.auth.user.is_admin || this.props.auth.user.is_agent ? ( 
                    <div className="col-lg-2 col-6">
                        {/* small box */}
                        <div className="small-box bg-success">
                            <div className="inner">
                                <h3>{this.props.user_state.total_seller}</h3>

                                <p><Link to="/seller-list" style={{color:'white'}} disabled> Total Seller </Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag"/>
                            </div>
                            {/* <NavLink to="/rd-list" href="#" className="small-box-footer">More info <i
                                className="fas fa-arrow-circle-right"/></NavLink> */}
                        </div>
                    </div>)
                    : null
                  }
                  { this.props.auth.user.is_admin || this.props.auth.user.is_agent  ? ( 
                    <div className="col-lg-2 col-6">
                        {/* small box */}
                        <div className="small-box bg-info">
                            <div className="inner">
                                <h3>{this.props.user_state.agent_total_balance !==undefined && this.props.user_state.agent_total_balance.total>0 ? this.props.user_state.agent_total_balance.total:0}</h3>
                                <p>{this.props.auth.user.is_admin?"Agent":""} Total balance</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-stats-bars"/>
                            </div>
                            {/* <NavLink to="/partner-list" href="#" className="small-box-footer">More info <i
                                className="fas fa-arrow-circle-right"/></NavLink> */}
                        </div>
                    </div>
                    )
                    :null
    }

                { this.props.auth.user.is_admin ? ( 
                    <div className="col-lg-2 col-6">
                        {/* small box */}
                        <div className="small-box bg-success">
                            <div className="inner">
                                <h3>{this.props.user_state.total_agent}</h3>

                                <p><Link to="/agent-list" style={{color:'white'}}> Total Agent </Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-person-stalker"/>
                            </div>
                            {/* <NavLink to="/rd-list" href="#" className="small-box-footer">More info <i
                                className="fas fa-arrow-circle-right"/></NavLink> */}
                        </div>
                    </div>)
                    : null
                  }
            {  this.props.auth.user.is_agent  ? ( 
                    <div className="col-lg-2 col-6">
                        {/* small box */}
                        <div className="small-box bg-primary">
                            
                            <div className="inner">
                                <h3>{this.props.agents[0] !==undefined && this.props.agents[0] !==null ? this.props.agents[0].comissioin_prices_and_sales.total_month_sales:0}</h3>
                                <p>{this.props.auth.user.is_admin?"Agent":""} Total Montly Sales</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-stats-bars"/>
                            </div>
                            {/* <NavLink to="/partner-list" href="#" className="small-box-footer">More info <i
                                className="fas fa-arrow-circle-right"/></NavLink> */}
                        </div>
                    </div>
                    )
                    :null
                }

            {  this.props.auth.user.is_agent  ? ( 
                    <div className="col-lg-2 col-6">
                        {/* small box */}
                        <div className="small-box bg-secondary">
                            <div className="inner">
                                <h3>{this.props.agents[0] !==undefined && this.props.agents[0] !==null  ? this.props.agents[0].comissioin_prices_and_sales.total_month_comission:0}</h3>
                                <p>{this.props.auth.user.is_admin?"Agent":""} Total Montly Commission</p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-stats-bars"/>
                            </div>
                            {/* <NavLink to="/partner-list" href="#" className="small-box-footer">More info <i
                                className="fas fa-arrow-circle-right"/></NavLink> */}
                        </div>
                    </div>
                    )
                    :null
                }
                {/* { this.props.auth.user.is_admin ? ( 
                    <div className="col-lg-2 col-6">
                       
                        <div className="small-box bg-success">
                            <div className="inner">
                                <h3>{this.props.user_state.total_food_order_pending}</h3>
                                <p><Link to="/food-order-list"  style={{color:'white'}}> Total Food Order Pending </Link></p>

                                
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag"/>
                            </div>
                            
                        </div>
                    </div>)
                    : null
                  } */}

                {/* { this.props.auth.user.is_admin ? ( 
                    <div className="col-lg-2 col-6">
                        
                        <div className="small-box bg-success">
                            <div className="inner">
                                <h3>{this.props.user_state.total_food_order_completed}</h3>
                                <p><Link to="/food-order-list"  style={{color:'white'}}> Total Food Order Completed </Link></p>

                                
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag"/>
                            </div>
                            
                        </div>
                    </div>)
                    : null
                  } */}
                  {/* { this.props.auth.user.is_admin ? ( 
                    <div className="col-lg-2 col-6">
                        
                        <div className="small-box bg-primary">
                            <div className="inner">
                                <h3>{this.props.user_state.total_car_trip_pending}</h3>
                                <p><Link to="/rider-car-trip-list"  style={{color:'white'}}> Total Car Trip Pending </Link></p>

                                
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag"/>
                            </div>
                            
                        </div>
                    </div>)
                    : null
                  } */}
                  {/* { this.props.auth.user.is_admin ? ( 
                    <div className="col-lg-2 col-6">
                        
                        <div className="small-box bg-primary">
                            <div className="inner">
                                <h3>{this.props.user_state.total_car_trip_completed}</h3>

                                <p><Link to="/rider-car-trip-list"  style={{color:'white'}}> Total Car Trip Completed </Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag"/>
                            </div>
                            
                        </div>
                    </div>)
                    : null
                  } */}
                  {/* { this.props.auth.user.is_admin ? ( 
                    <div className="col-lg-2 col-6">
                        
                        <div className="small-box bg-secondary">
                            <div className="inner">
                                <h3>{this.props.user_state.total_medicine_pending}</h3>

                                
                               <p> <Link to="/medicine-delivery-list"  style={{color:'white'}}>Total Pending Medicine Delivery</Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag"/>
                            </div>
                            
                        </div>
                    </div>)
                    : null
                  } */}
                  {/* { this.props.auth.user.is_admin ? ( 
                    <div className="col-lg-2 col-6">
                        
                        <div className="small-box bg-secondary">
                            <div className="inner">
                                <h3>{this.props.user_state.total_medicine_completed}</h3>

                                
                               <p> <Link to="/medicine-delivery-list"  style={{color:'white'}}>Total Completed Medicine Delivery</Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-bag"/>
                            </div>
                            
                        </div>
                    </div>)
                    : null
                  } */}
                  {/* { this.props.auth.user.is_admin ? ( 
                    <div className="col-lg-2 col-6">
                       
                        <div className="small-box bg-danger">
                            <div className="inner">
                                <h3>{this.props.user_state.total_percel_express_pending}</h3>

                                <p><Link to="/percel-express-list" style={{color:'white'}}> Total Pending Percel Express </Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-stats-bars"/>
                            </div>
                            
                        </div>
                    </div>)
                    : null
                  } */}
                  {/* { this.props.auth.user.is_admin ? ( 
                    <div className="col-lg-2 col-6">
                        
                        <div className="small-box bg-danger">
                            <div className="inner">
                                <h3>{this.props.user_state.total_percel_express_completed}</h3>

                                <p><Link to="/percel-express-list" style={{color:'white'}}> Total Completed Percel Express </Link></p>
                            </div>
                            <div className="icon">
                                <i className="ion ion-stats-bars"/>
                            </div>
                            
                        </div>
                    </div>)
                    : null
                  } */}
                </div>       
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        user_state: state.dashboard.user_state,
        agents:state.agents.agents
    }
};

const mapDispatchToProps = dispatch => {
    return {
        loadUser: () => {
            return dispatch(loadUser());
        },
        userStatistics: () => {
            return dispatch(userStatistics());
        },
        getAgents: () => {
            return dispatch(getAgents());
        },
        logout: () => dispatch(logout()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
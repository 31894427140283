import {
    FETCH_PRODUCTS,
    DETAIL_PRODUCT,
    DELETE_PRODUCT,
    LOADING_PRODUCT,
    ADD_DATA,
    UPDATE_PRODUCT,
    UPDATED_DATA,
    DELETE_GALLERY,
    NOT_FOUND_PRODUCT,
    UPLOAD_CSV,
    UPDATE_PRODUCT_LIST_DATA,
    LOADING_UPSELL_PRODUCT,
    UPSELL_FETCH_PRODUCT,
    BULK_PRODUCT_ACTION,
    LOADING_CROSSSELL_PRODUCT,
    CROSSSELL_FETCH_PRODUCT
} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { PRODUCT_URL, GET_GALLERY_URL,PRODUCT_DISCOUNT_UPDATE_URL, PRODUCT_CSV_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


export const fetchProducts = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["product_name", "product_sku", "status", 'stock_type', 'product_type', 'primary_categories__name', 'child_categories__name', 'secondary_categories__name','vendor__shop_name']

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = PRODUCT_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                
                dispatch({
                    type: FETCH_PRODUCTS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_PRODUCT });

    }
};
export const searchProducts = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios.get(PRODUCT_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_PRODUCTS,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};

export const searchSelectProducts = (url) => (dispatch, getState) => {
    dispatch({ type: LOADING_PRODUCT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    // axios.get(PRODUCT_URL + '?search=' + value, { headers, })
    axios.get(url, { headers, })
        .then(response => {
            var results = []
            for (var i = 0; i < response.data.length; i++) {
                results.push({ label: response.data[i].product_name, value: response.data[i].id,data: response.data[i] })
            }
            dispatch({
                type: FETCH_PRODUCTS,   
                payload: results,
                count: response.data.count,
                next: response.data.next
            })
        })

};


export const UpSellSearchProducts = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_UPSELL_PRODUCT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios.get(PRODUCT_URL + '?search=' + value, { headers, })
        .then(response => {
             //alert(JSON.stringify(response))
            dispatch({
                type: UPSELL_FETCH_PRODUCT,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};

export const CrossSellSearchProducts = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_CROSSSELL_PRODUCT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios.get(PRODUCT_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: CROSSSELL_FETCH_PRODUCT,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};


export const getSearchProducts = async (value) => {
    let token = localStorage.getItem('token')
    let response = await fetch(PRODUCT_URL + '?search=' + value, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Token ${token}`
        },
        method: 'GET',
    });
    return await response.json();

};

// export const getSearchProducts = (value) => {
//     // dispatch({ type: LOADING_PRODUCT });
//      let headers = { "Content-Type": "application/json" };
//         let  token = localStorage.getItem('token')
//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//     }


//    var data=  axios.get(PRODUCT_URL + '?search=' + value, { headers, })
//         .then(response =>response.data.results)
//     // const dataPromise = promise.then((data) => data)
//     alert(JSON.stringify(data))

//    return null
// };

// Director Detail
export const productDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = PRODUCT_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_PRODUCT,
                payload: response.data,
            })
        })
};

// // Delete Product
export const deleteProduct = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(PRODUCT_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_PRODUCT,
                payload: id,
            });
            toast.success('Product has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

export const addData = (data, url, message = "Data", is_token=true) => (dispatch, getState) => {
    let headers = { 'content-type': 'multipart/form-data' };
    if (is_token){
        let { token } = getState().auth;
        if (token) {
            headers["Authorization"] = `Token ${token}`;
    }
    
    }
    axios
        .post(url, data, { headers })
        .then(response => {
            dispatch({
                type: ADD_DATA,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been added successfully");
        })
        .catch(error => {
          
            if (error.response) {
                let data =error.response.data
                console.log(Object.keys(data).length)
                if(Object.keys(data).length>0){
                    var msg=String(data[Object.keys(data)[0]]);
                    toast.warn(msg);
                }
                else
                    toast.warn("Network Error");
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                toast.warn("Something Wrong ! Please try again");

            } else {
                toast.warn("Something Wrong ! Please try again");
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }

        })
};

export const updateData = (data, url, message = "") => (dispatch, getState) => {

    let headers = { 'content-type': 'multipart/form-data' };
    let { token } = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .put(url, data, { headers })
        .then(response => {
            dispatch({
                type: UPDATED_DATA,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been updated successfully");
        }).catch(error => {
            console.log(error)
            toast.warn(message + " has not been updated successfully");
        })
};


export const deleteGallryImage = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(GET_GALLERY_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_GALLERY,
                payload: id,
            });
            //toast.success('Product has been deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};

export const uploadProductCSV = (data, csrfToken) => (dispatch, getState) => {

    let headers = { 'content-type': 'multipart/form-data', "X-CSRFToken": csrfToken };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .post(PRODUCT_CSV_URL, data, { headers })
        .then(response => {
            console.log(response)
            dispatch({
                type: UPLOAD_CSV,
                payload: response.data,
            });
            toast.success('Product has been deleted successfully');
            //  console.log(response.data)
        })
        .catch(error => {
            if (error.response) {
                toast.warn("Something Wrong ! Not Uploaded");
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                toast.warn("Something Wrong ! Please try again");

            } else {
                toast.warn("Something Wrong ! Please try again");
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }
        })
};


export const updateProductsList = (data, csrfToken) => (dispatch, getState) => {

    let headers = { 'content-type': 'application/json', "X-CSRFToken": csrfToken };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios
        .post(PRODUCT_DISCOUNT_UPDATE_URL, data, { headers })
        .then(response => {
            dispatch({
                type: UPDATE_PRODUCT_LIST_DATA,
                payload: response.data,
            });
            toast.success('Selected product has been update successfully');
            //  console.log(response.data)
        })
        .catch(error => {
            if (error.response) {
              
                toast.warn("Something Wrong ! Not Updated !!");
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                toast.warn("Something Wrong ! Please try again");

            } else {
                toast.warn("Something Wrong ! Please try again");
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }
        })
};


export const BulkAction = (data, url) => (dispatch, getState) => {
    var headers = {}
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios
        .post(url, data, { headers })
        .then(response => {
            dispatch({
                type: BULK_PRODUCT_ACTION,
                payload: response.data.results ? response.data.results : response.data,
            });

        })
        .catch(error => {
            if (error.response) {
                console.log(error.response.data.error)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                toast.warn("Something Wrong ! Please try again");

            } else {
                toast.warn("Something Wrong ! Please try again");
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }

        })
};

import {
    FETCH_DISTRICT,
    DETAIL_DISTRICT,
    DELETE_DISTRICT,
    LOADING_DISTRICT,
    ADD_DATA,
    UPDATE_DISTRICT,
    UPDATED_DATA,
    NOT_FOUND_DISTRICT,
} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { DISTRICT_URL, DISTRICT_ALL_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


export const fetchDistrict = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_DISTRICT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = DISTRICT_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_DISTRICT,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_DISTRICT });

    }
};
export const searchDistrict = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_DISTRICT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(DISTRICT_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_DISTRICT,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};
// District Detail
export const DistrictDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = DISTRICT_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_DISTRICT,
                payload: response.data,
            })
        })
};

// Delete District
export const deleteDistrict = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(DISTRICT_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_DISTRICT,
                payload: id,
            });
            toast.success('District has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};







export const getDistrictsForSelect = (pageNumber, id) => (dispatch, getState) => {

    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    dispatch({ type: LOADING_DISTRICT });
    var url = DISTRICT_ALL_URL

    if (id) {
        url = DISTRICT_ALL_URL + `${id}/`;
    } else if (pageNumber) {
        url = DISTRICT_ALL_URL + '?page=' + pageNumber
    }
    axios.get(url, { headers, })
        .then(response => {
            // alert(JSON.stringify(response.data.length))
            //    label: "Electronics", value: 1
            var results = []
            for (var i = 0; i < response.data.length; i++) {
                results.push({ label: response.data[i].name, value: response.data[i].id })
            }
            // console.log(results)
            dispatch({
                type: FETCH_DISTRICT,
                payload: response.data ? results : [],
                count: response.data.count,
                next: response.data.next
            })

        })




};
import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchCustomers, addData, deleteCustomer } from "../../../actions/CustomerActions"
import { getDistrictsForSelect } from '../../../actions/DistrictsActions'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { renderTextArea } from '../../../constants/FormInputType';


import { toast } from "react-toastify";
import { CUSTOMER_URL, GET_PRIMARY_CATEGORY_URL } from "../../../constants/api";







// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning }, required = false, }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            {required ? <span className="requiredField">*</span> : ""}
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddCustomer extends Component {

    componentDidMount() {
        console.log(this.props.fetchCustomers())
        this.props.fetchCustomers(0)
        this.props.getDistrictsForSelect(0)

    }
    // this.props.getRides(0)



    submit(event) {
        this.props.addData(event, CUSTOMER_URL, "Customer")
    }

    render() {


        const { handleSubmit, submitting, } = this.props;
        if (this.props.is_added) {
            setTimeout(() => {
                window.location.href = "/customer-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Customer </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Customer add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Customer Information</h3>
                        <div className="float-right">
                            <Link to="/customer-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="first_name"
                                        type="text"
                                        component={renderField}
                                        label="Full Name"
                                        validate={required}
                                        required={true}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="last_name"
                                        type="text"
                                        component={renderField}
                                        label="Last Name"
                                        validate={required}
                                        required={true}


                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <Field
                                        name="phone_no"
                                        type="text"
                                        component={renderField}
                                        label=" Phone No"
                                        validate={[required, phoneNumber]}
                                        required={true}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                        // validate={[required, email]}
                                        // required={true}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="password1"
                                        type="password"
                                        component={renderField}
                                        label="Password"
                                        required={true}

                                        validate={required}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="password"
                                        type="password"
                                        component={renderField}
                                        label="Retype Password"
                                        required={true}

                                        validate={[required, match("password1")]}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="post_code"
                                        type="text"
                                        component={renderField}
                                        label="Post Code"

                                    // validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <label>District</label>
                                    <Field
                                        name="district"
                                        component="select"
                                        className="form-control"

                                        label="District"
                                        validate={required}
                                        required={true}

                                    >
                                        <option value=""  >--Select District--</option>
                                        {this.props.districts.map((district, index) => (
                                            <option key={index} value={district.value}>{district.label}</option>
                                        ))}

                                    </Field>
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="address"
                                        type="text"
                                        component={renderTextArea}
                                        label="Billing Address"

                                    // warn={alphaNumeric}
                                    />
                                </div>


                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddCustomer.propTypes = {
    getDistrictsForSelect: PropTypes.func.isRequired,
    fetchCustomers: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({

    districts: state.districts.districts,
    customers: state.customers.customers,
    is_added: state.customers.is_added,

});


AddCustomer = reduxForm({
    form: 'AddCustomer',
}
)(AddCustomer);


export default connect(mapStateToProps, { fetchCustomers, addData, getDistrictsForSelect })(AddCustomer);

import {
    LOADING_SECONDARY_CATEGORY,
    GETTING_SECONDARY_CATEGORY,
    ADD_SECONDARY_CATEGORY,
    DETAIL_SECONDARY_CATEGORY,
    UPDATE_SECONDARY_CATEGORY,
    DELETE_SECONDARY_CATEGORY,
    NOT_FOUND_SECONDARY_CATEGORY,
} from '../actions/types';

const initialState = {
    secondary_categories: [],
    secondary_category: {},
    count: null,
    next: null,
    is_loading: false,
    is_updated: false,
    is_added: false,
    errors: '',
    is_list:false,
    bulk:false

};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_SECONDARY_CATEGORY:
            return {
                ...state,
                is_loading: true
            };
        case GETTING_SECONDARY_CATEGORY:
            return {
                ...state,
                secondary_categories: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                is_list:true
            };
        case ADD_SECONDARY_CATEGORY:
            return {
                ...state,
                secondary_categories: state.secondary_categories,
                is_loading: false,
                is_added: true
            };
        case DETAIL_SECONDARY_CATEGORY:
            return {
                ...state,
                secondary_category: action.payload,

            };
        case UPDATE_SECONDARY_CATEGORY:
            var filteredDataSource = state.secondary_categories.filter((item) => {
                if (item.id === action.payload.id) {
                    item.name = action.payload.name;
                    item.image = action.payload.image
                }

                return item;
            });
            console.log(filteredDataSource)
            return {
                ...state,
                secondary_categories: filteredDataSource,
                is_loading: false,
                is_updated: true
            };
        case DELETE_SECONDARY_CATEGORY:
            return {
                ...state,
                secondary_categories: state.secondary_categories.filter(secondary_category => secondary_category.id !== action.payload)
            };

        case NOT_FOUND_SECONDARY_CATEGORY:
            return {
                ...state,
                secondary_categories: [],
                count: 0,
                next: 0,
                is_loading: false,

            };
        default:
            return state;
    }
}
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
    logout
} from "../../../actions/auth";

class Toolbar extends Component {
    render() {
        return (
            <div>
                <nav className="main-header navbar navbar-expand navbar-dark navbar-info shadow">
                    {/* Left navbar links */}
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <a className="nav-link" data-widget="pushmenu" href="/"><i className="fas fa-bars" /></a>
                        </li>

                    </ul>

                    {/* Right navbar links */}
                    <ul className="navbar-nav ml-auto">

                        <li className="nav-item dropdown">
                            <a className="nav-link" data-toggle="dropdown" href="/">
                                <i className="far fa-user" />
                                {/*<span className="badge badge-warning navbar-badge">15</span>*/}
                            </a>
                            <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                                <div className="dropdown-divider" />

                                <a href="/" className="dropdown-item">
                                    {/* <i className="fas fa-file mr-2" />{this.props.auth.user.first_name} */}
                                    <i className="fas fa-file mr-2" />{this.props.auth.user ? this.props.auth.user.first_name : ""}
                                </a>
                                <div className="dropdown-divider" />
                                <button className="dropdown-item dropdown-footer bg-info" onClick={this.props.logout}>Logout</button>
                            </div>
                        </li>

                    </ul>
                </nav>
                {/* /.navbar */}

            </div>
        )
    }
}

// toolbar.propTypes = {
//     logout: PropTypes.func.isRequired,
// };

const mapStateToProps = state => {
    return {
        auth: state.auth,
    }
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(logout()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Toolbar);

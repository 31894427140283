import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import {
    required
} from '../../../constants/Validate'
import { connect } from "react-redux";
import { customerDetail, updateDatas } from "../../../actions/CustomerActions";
import PropTypes from "prop-types";
import moment from 'moment';
import { Link } from "react-router-dom";
import { CUSTOMER_URL } from "../../../constants/api";
import { renderTextArea } from '../../../constants/FormInputType';
import { getDistrictsForSelect } from '../../../actions/DistrictsActions'
import momentLocalizer from 'react-widgets-moment';



momentLocalizer(moment);




// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class CustomerUpdate extends Component {

    state = {
        district_id: 0
    }

    componentDidMount() {
        // console.log(this.props.updateDatas)
        let id = this.props.match.params.id;
        this.props.customerDetail(id)
        this.props.getDistrictsForSelect(0)


    }


    submit(event) {
        this.props.updateDatas(event, CUSTOMER_URL+ this.props.match.params.id+'/', "Customer")

    }

    render() {
        const { handleSubmit, submitting } = this.props;
        if (this.props.is_updated) {
            setTimeout(function () {

                window.location.href = '/customer-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Customer Information</h1>
                            </div>
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="">Customer Details
                            <Link to="/customer-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>
                        </h3>

                    </div>
                    {/* /.card-header */}
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">

                                {/* <div className="col-md-6 form-group"> */}
                                <div className="col-md-6">
                                    <Field
                                        name="first_name"
                                        type="text"
                                        component={renderField}
                                        label="Full Name"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="last_name"
                                        type="text"
                                        component={renderField}
                                        label="Last Name"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <Field
                                        name="phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Phone No"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                        // validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="post_code"
                                        type="text"
                                        component={renderField}
                                        label="Post Code"

                                    // validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}
                                <div className="col-md-6">
                                    <Field
                                        name="cash_back_balance"
                                        type="text"
                                        component={renderField}
                                        label="Cash Back Balance(Tk)"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>District</label>
                                    <Field
                                        name="district"
                                        component="select"
                                        className="form-control"

                                        label="District"
                                        // validate={required}
                                        // required={true}

                                    >
                                        <option value=""  >--Select District --</option>
                                        {this.props.districts.map((district, index) => (
                                            <option selected={this.props.customers.district===district.value ? true :false} key={index} value={district.value}>{district.label }</option>
                                        ))}

                                    </Field>
                                </div>

                                <div className="col-md-6">
                                    <Field
                                        name="address"
                                        type="text"
                                        component={renderTextArea}
                                        label="Billing Address"
                                        // validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="user.address"
                                        type="text"
                                        component={renderField}
                                        label="Address"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div> */}






                                {/* </div> */}


                            </div>
                            <div className="row">
                                <div className="card-footer float-right">
                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-info"
                                            disabled={submitting}>
                                            Update
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>


        )
    }
}


CustomerUpdate.propTypes = {

    updateDatas: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    initialValues: state.customers.customer,
    customers: state.customers.customer,
    is_added: state.customers.is_added,
    districts: state.districts.districts,
    is_updated: state.customers.is_updated

});

CustomerUpdate = reduxForm({
    form: 'CustomerUpdate',
    enableReinitialize: true,
}
)(CustomerUpdate);

export default connect(mapStateToProps, { customerDetail, updateDatas,getDistrictsForSelect })(CustomerUpdate);

import {
    FETCH_SHIPPING_ZONE,
    DETAIL_SHIPPING_ZONE,
    DELETE_SHIPPING_ZONE,
    LOADING_SHIPPING_ZONE,
    ADD_DATA,
    UPDATE_SHIPPING_ZONE,
    UPDATED_DATA,
    NOT_FOUND_SHIPPING_ZONE,
} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { SHIPPING_ZONE_URL, SHIPPING_ZONES_URL, GET_GALLERY_URL, PRODUCT_CSV_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


export const fetchShippingZone = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SHIPPING_ZONE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["zone"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = SHIPPING_ZONE_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_SHIPPING_ZONE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SHIPPING_ZONE });

    }
};
export const searchShippingZone = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_SHIPPING_ZONE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(SHIPPING_ZONE_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_SHIPPING_ZONE,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};
// Shipping Zone Detail
export const shippingZoneDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = SHIPPING_ZONE_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_SHIPPING_ZONE,
                payload: response.data,
            })
        })
};

// // Delete Shipping Zone
export const deleteShippingZone = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(SHIPPING_ZONE_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_SHIPPING_ZONE,
                payload: id,
            });
            toast.success('Shipping Zone has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

export const addData = (data, url, message = "Data") => (dispatch, getState) => {
    let headers = { 'content-type': 'application/json' };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios
        .post(url, data, { headers })
        .then(response => {
            dispatch({
                type: ADD_DATA,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been added successfully");
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response.data.error)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                toast.warn("Something Wrong ! Please try again");

            } else {
                toast.warn("Something Wrong ! Please try again");
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }

        })
};

export const updateData = (data, url, message = "") => (dispatch, getState) => {

    let headers = { 'content-type': 'application/json' };
    let { token } = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .put(url, data, { headers })
        .then(response => {
            dispatch({
                type: UPDATED_DATA,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been updated successfully");
        }).catch(error => {
            console.log(error)
            toast.warn(message + " has not been updated successfully");
        })
};


export const fetchShippingZones = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SHIPPING_ZONE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["zone"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = SHIPPING_ZONES_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_SHIPPING_ZONE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SHIPPING_ZONE });

    }
}


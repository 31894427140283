import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import {
    required
} from '../../../constants/Validate'
import { connect } from "react-redux";
import { updateData } from '../../../actions/ProductAction';
import { GET_PRIMARY_CATEGORY_URL } from '../../../constants/api';

import { primaryCategoryDetail, updatePrimaryCategory } from "../../../actions/PrimaryCategoryAction";
import PropTypes from "prop-types";
import moment from 'moment';
import { Link } from "react-router-dom";

import momentLocalizer from 'react-widgets-moment';



momentLocalizer(moment);


const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class PrimaryCategoryUpdate extends Component {

    state = {
        district_id: 0
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.primaryCategoryDetail(id)

    }


    submit(data) {
        // console.log(values)
        var form_data = new FormData();
           
        form_data.append('name', data.name)
        form_data.append('serial_no', data.serial_no)
        form_data.append('class_name', data.class_name)
        


        
        if (data.images !==null && data.images !=='' && data.images !==undefined) {
            form_data.append('image', data.images, data.images.name);
        } 
        return sleep(1000).then(() => {
            let id = this.props.match.params.id;
            // this.props.updatePrimaryCategory(id, values);
            this.props.updateData(form_data, GET_PRIMARY_CATEGORY_URL+ id+'/', "Primary Category")
        })
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        if (this.props.is_added) {
            setTimeout(function () {

                window.location.href = "/primary-category-list"
            }, 3000)
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Primary Category Information</h1>
                            </div>
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="">Update Primary Category Details
                            <Link to="/primary-category-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>
                        </h3>

                    </div>
                    {/* /.card-header */}
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">

                                {/* /<div className="col-md-6 form-group"> */}
                                    <div className="col-md-6">
                                        <Field
                                            name="name"
                                            type="text"
                                            component={renderField}
                                            label="Name"
                                            validate={required}
                                        // value={this.state.name}
                                        // onChange={this.handleChange}
                                        // warn={alphaNumeric}
                                        />
                                    </div>
                                {/* </div> */}
                                <div className="col-md-6">
                                        <Field
                                            name="class_name"
                                            type="text"
                                            component={renderField}
                                            label="Class Name"
                                            validate={required}
                                        // value={this.state.name}
                                        // onChange={this.handleChange}
                                        // warn={alphaNumeric}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        <Field
                                            name="serial_no"
                                            type="text"
                                            component={renderField}
                                            label="Serial No"
                                            validate={required}
                                        // value={this.state.name}
                                        // onChange={this.handleChange}
                                        // warn={alphaNumeric}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                    <img src={this.props.primarycategory.image} style={{width:150,height:150}}/>
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Upload Primary Category Image</label>
                                    <Field
                                        name="images"
                                        type="file"
                                        component={FileInput}
                                        // accept="image/*"
                                        label="Primary Category"
                                    // validate={[required]}
                                    />

                                </div>





                                


                            </div>
                            <div className="row">
                                <div className="card-footer float-right">
                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-info"
                                            disabled={submitting}>
                                            Update
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>


        )
    }
}


PrimaryCategoryUpdate.propTypes = {

    updatePrimaryCategory: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    initialValues: state.primarycategory.primary_category,
    primarycategory: state.primarycategory.primary_category,
    is_added: state.products.is_added,
    is_updated: state.products.is_updated,

});

PrimaryCategoryUpdate = reduxForm({
    form: 'PrimaryCategoryUpdate',
    enableReinitialize: true,
}
)(PrimaryCategoryUpdate);

export default connect(mapStateToProps, { primaryCategoryDetail,updateData, updatePrimaryCategory })(PrimaryCategoryUpdate);

import {
    GET_AGENTS,
    DETAIL_AGENTS,
    LOADING_AGENTS,
    DELETE_AGENTS,
    UPDATE_AGENTS,
    NOT_FOUND_AGENTS,
    LOADING_AGENT_CUSTOMER,
    GET_AGENT_CUSTOMER,
    DETAIL_AGENT_CUSTOMER,
    DELETE_AGENT_CUSTOMER,
    NOT_FOUND_AGENT_CUSTOMER,
    LOADING_AGENT_PRODUCT_STOCK,
    FETCH_AGENT_PRODUCT_STOCK,
    DETAIL_AGENT_PRODUCT_STOCK,
    DELETE_AGENT_PRODUCT_STOCK,
    NOT_FOUND_AGENT_PRODUCT_STOCK,
    LOADING_AGENT_PRODUCT_SALE,
    FETCH_AGENT_PRODUCT_SALE,
    DETAIL_AGENT_PRODUCT_SALE,
    DELETE_AGENT_PRODUCT_SALE,
    NOT_FOUND_AGENT_PRODUCT_SALE,
    LOADING_AGENT_PRODUCT_STOCK_REPORTS,
    FETCH_AGENT_PRODUCT_STOCK_REPORTS,
    NOT_FOUND_AGENT_PRODUCT_STOCK_REPORTS,
    DETAIL_AGENT_PRODUCT_STOCK_REPORTS,
    DELETE_AGENT_PRODUCT_STOCK_REPORTS,
    LOADING_AGENT_PRODUCT_SALE_REPORTS,
    FETCH_AGENT_PRODUCT_SALE_REPORTS,
    DETAIL_AGENT_PRODUCT_SALE_REPORTS,
    DELETE_AGENT_PRODUCT_SALE_REPORTS,
    NOT_FOUND_AGENT_PRODUCT_SALE_REPORTS,
} from '../actions/types';




const initialState = {
    agents: [],
    agent: {},
    count: null,
    next: null,
    bulk: false,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    get_agents: false,
};

export const AgentReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_AGENTS:
            return {
                ...state,
                is_loading: true
            };
        case GET_AGENTS:
            return {
                ...state,
                agents: action.payload,
                count: action.count,
                next: action.next,
                get_agents: true,
                is_loading: false
            };
        case DETAIL_AGENTS:
            return {
                ...state,
                is_getting: true,
                agent: action.payload,
                
            };
        case DELETE_AGENTS:
            return {
                ...state,
                agents: state.agents.filter(restaurant => restaurant.id !== action.payload)
            };
        
        case NOT_FOUND_AGENTS:
            return {
                ...state,
                agents: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}





export const AgentCustomerReducer = (state = {
                agent_customers: [],
                agent_customer: {},
                count: null,
                next: null,
                bulk: false,
                is_added: false,
                is_loading: false,
                is_getting: false,
                is_updated: false,
                get_agent_customer: false,
                    }, action) => {
        switch (action.type) {
            case LOADING_AGENT_CUSTOMER:
                return {
                    ...state,
                    is_loading: true
                };
            case GET_AGENT_CUSTOMER:
                return {
                    ...state,
                    agent_customers: action.payload,
                    count: action.count,
                    next: action.next,
                    get_agent_customer: true,
                    is_loading: false
                };
            case DETAIL_AGENT_CUSTOMER:
                return {
                    ...state,
                    is_getting: true,
                    agent_customer: action.payload,
                    
                };
            case DELETE_AGENT_CUSTOMER:
                return {
                    ...state,
                    agent_customers: state.agent_customers.filter(agent_customer => agent_customer.id !== action.payload)
                };
            
            case NOT_FOUND_AGENT_CUSTOMER:
                return {
                    ...state,
                    agent_customers: [],
                    count: 0,
                    next: 0,
                    is_loading: false
                };
            default:
                return state;
        }
    }



export const AgentProductStockReducer = (state = {
        agent_product_stocks: [],
        agent_product_stock: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_agent_product_stock: false,
            }, action) => {
switch (action.type) {
    case LOADING_AGENT_PRODUCT_STOCK:
        return {
            ...state,
            is_loading: true
        };
    case FETCH_AGENT_PRODUCT_STOCK:
        return {
            ...state,
            agent_product_stocks: action.payload,
            count: action.count,
            next: action.next,
            get_agent_product_stock: true,
            is_loading: false
        };
    case DETAIL_AGENT_PRODUCT_STOCK:
        return {
            ...state,
            is_getting: true,
            agent_product_stock: action.payload,
            
        };
    case DELETE_AGENT_PRODUCT_STOCK:
        return {
            ...state,
            agent_product_stocks: state.agent_product_stocks.filter(agent_product_stock => agent_product_stock.id !== action.payload)
        };
    
    case NOT_FOUND_AGENT_PRODUCT_STOCK:
        return {
            ...state,
            agent_product_stocks: [],
            count: 0,
            next: 0,
            is_loading: false
        };
    default:
        return state;
}
}





export const AgentProductSaleReducer = (state = {
    agent_product_sales: [],
    agent_product_sale: {},
    count: null,
    next: null,
    bulk: false,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
    get_agent_product_sale: false,
        }, action) => {
    switch (action.type) {
    case LOADING_AGENT_PRODUCT_SALE:
        return {
            ...state,
            is_loading: true
        };
    case FETCH_AGENT_PRODUCT_SALE:
        return {
            ...state,
            agent_product_sales: action.payload,
            count: action.count,
            next: action.next,
            get_agent_product_sale: true,
            is_loading: false
        };
    case DETAIL_AGENT_PRODUCT_SALE:
        return {
            ...state,
            is_getting: true,
            agent_product_sale: action.payload,
            
        };
    case DELETE_AGENT_PRODUCT_SALE:
        return {
            ...state,
            agent_product_sales: state.agent_product_sales.filter(agent_product_sale => agent_product_sale.id !== action.payload)
        };

    case NOT_FOUND_AGENT_PRODUCT_SALE:
        return {
            ...state,
            agent_product_sales: [],
            count: 0,
            next: 0,
            is_loading: false
        };
    default:
        return state;
    }
    }



export const AgentProductStockReportsReducer = (state = {
        agent_product_stock_reports: [],
        agent_product_stock_report: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_agent_product_stock_report: false,
            }, action) => {
    switch (action.type) {
        case LOADING_AGENT_PRODUCT_STOCK_REPORTS:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_AGENT_PRODUCT_STOCK_REPORTS:
            return {
                ...state,
                agent_product_stock_reports: action.payload,
                count: action.count,
                next: action.next,
                get_agent_product_stock_report: true,
                is_loading: false
            };
        case DETAIL_AGENT_PRODUCT_STOCK_REPORTS:
            return {
                ...state,
                is_getting: true,
                agent_product_stock_reports: action.payload,
                
            };
        case DELETE_AGENT_PRODUCT_STOCK_REPORTS:
            return {
                ...state,
                agent_product_stock_reports: state.agent_product_stock_reports.filter(agent_product_stock => agent_product_stock.id !== action.payload)
            };
        
        case NOT_FOUND_AGENT_PRODUCT_STOCK_REPORTS:
            return {
                ...state,
                agent_product_stock_reports: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
    }




export const AgentProductSaleReportsReducer = (state = {
        agent_product_sale_reports: [],
        agent_product_sale_report: {},
        count: null,
        next: null,
        bulk: false,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        get_agent_product_sale_report: false,
            }, action) => {
        switch (action.type) {
            case LOADING_AGENT_PRODUCT_SALE_REPORTS:
                return {
                    ...state,
                    is_loading: true
                };
            case FETCH_AGENT_PRODUCT_SALE_REPORTS:
                return {
                    ...state,
                    agent_product_sale_reports: action.payload,
                    count: action.count,
                    next: action.next,
                    get_agent_product_sale_report: true,
                    is_loading: false
                };
            case DETAIL_AGENT_PRODUCT_SALE_REPORTS:
                return {
                    ...state,
                    is_getting: true,
                    agent_product_sale_reports: action.payload,
                    
                };
            case DELETE_AGENT_PRODUCT_SALE_REPORTS:
                return {
                    ...state,
                    agent_product_sale_reports: state.agent_product_stock_reports.filter(agent_product_sale => agent_product_sale.id !== action.payload)
                };
            
            case NOT_FOUND_AGENT_PRODUCT_SALE_REPORTS:
                return {
                    ...state,
                    agent_product_sale_reports: [],
                    count: 0,
                    next: 0,
                    is_loading: false
                };
            default:
                return state;
        }
}
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from "react-paginate"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import Modal from 'react-modal';

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

import { fetchWithdraws, deleteWithdraws } from '../../../actions/WithdrawActions';
import { updateDatas } from '../../../actions/CustomerActions';

import { WITHDRAW_URL } from '../../../constants/api'


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class WithdrawList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        name: "",
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
    };

    handleStatusValues = i => e => {
        let withdraws = [...this.props.withdraws]
        var id = Number(withdraws[i]["id"])
        let data = {
            'withdraw_status': e.target.value
        }
        this.props.updateDatas(data, WITHDRAW_URL + id + "/", "Status")
    }

    componentDidMount() {
        this.props.fetchWithdraws(1);
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete withdraw id ' + data.withdraw_id + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteWithdraws(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.withdraw_id + '.',
                        'success'
                    )
                }
            }
        })
    }







    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();


        this.props.fetchWithdraws('', this.state);
        this.setState({ hideButton: true })

    };
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchWithdraws(pageNo)
    }

    render() {

        const page = Math.ceil(this.props.count / 10)

        const isAdmin = this.props.auth.user.is_admin;
        const {permissions} = this.props

        if (this.props.is_updated) {
            window.location.href = '/withdraw-list'


        }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Withdraw List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Withdraw list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Withdraw List</h3>

                                <div className="float-right">
                            {CheckPermisionAction(permissions,'Seller','add_withdraw')?

                                    <Link to="/add-withdraw" className="btn btn-primary text-white">
                                        <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Withdraw</b></Link>
                                :null}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="customer__phone_no" type="text"
                                            onChange={this.onChange} placeholder="Phone No"
                                            aria-label="Search" />
                            {CheckPermisionAction(permissions,'Seller','withdraw_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                :null}
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'Seller','view_withdraw_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>Withdraw Number</th>

                                            <th>Seller Name</th>
                                            <th>Phone No</th>
                                            <th>Amount</th>
                                            <th>Payment Method</th>
                                            <th>Status</th>
                                            <th>Option</th>





                                            {this.state.hideButton ?
                                                <th><Link to="/withdraw-list" onClick={() => { window.location.href = "/withdraw-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ?
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>
                                            : this.props.withdraws.length != undefined && this.props.withdraws.map((withdraw, index) => (

                                                <tr key={index}>
                                                    <td>{withdraw.withdraw_id}</td>

                                                    <td>{withdraw.customer.first_name} {withdraw.customer.last_name}</td>
                                                    <td>{withdraw.customer.phone_no}</td>
                                                    <td>{withdraw.amount}</td>
                                                    <td>
                                                        {withdraw.payment_method === 1 ? 'Cash' : ''}
                                                        {withdraw.payment_method === 2 ? 'Bank Transfer' : ''}
                                                        {withdraw.payment_method === 3 ? 'Mobile Bnaking' : ''}
                                                    </td>
                                                    <td>
                                                        {withdraw.withdraw_status !== 3 ? (
                                                            <select key={index}
                                                                name="status"
                                                                component="select"
                                                                className="form-control"
                                                                onChange={this.handleStatusValues(index)}
                                                            >


                                                                <option value="0" selected={withdraw.withdraw_status == 0 ? true : false}>Pending</option>
                                                                <option value="1" selected={withdraw.withdraw_status == 1 ? true : false} >Accepted</option>
                                                                <option value="2" selected={withdraw.withdraw_status == 2 ? true : false} >Declined</option>
                                                                <option value="3" selected={withdraw.withdraw_status == 3 ? true : false} >Paid</option>

                                                            </select>
                                                        ) :
                                                            <p>Paid</p>
                                                        }
                                                    </td>
                                                    <td className="btn-group btn-group-sm">
                                                        {withdraw.withdraw_status !== 3 ? (
                                                            <div className="btn-group">
                                                        {CheckPermisionAction(permissions,'Seller','withdraw_options')?

                                                                <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                        :null}
                                                                <div className="dropdown-menu">
                                                                    <a href={'/withdraw-edit/' + withdraw.id} className="dropdown-item">Edit</a>
                                                                    <div className="dropdown-divider"></div>
                                                                    <a href="#" onClick={() => this.opensweetalert(withdraw)} className="dropdown-item">Delete</a>

                                                                </div>
                                                            </div>
                                                        ) :
                                                            null
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

WithdrawList.propTypes = {
    fetchWithdraws: PropTypes.func.isRequired,
    deleteWithdraws: PropTypes.func.isRequired,
    updateDatas: PropTypes.func.isRequired,


};

const mapStateToProps = state => ({
    withdraws: state.withdraws.withdraws,
    count: state.withdraws.count,
    next: state.withdraws.next,
    is_loading: state.withdraws.is_loading,
    is_updated: state.customers.is_updated,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { fetchWithdraws, deleteWithdraws, updateDatas })(WithdrawList);

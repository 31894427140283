import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchShippingZone, addData, deleteShippingZone } from "../../../actions/ShippingZoneActions"
import { getDistrictsForSelect, fetchDistrict } from "../../../actions/DistrictsActions"
import { renderTextArea } from '../../../constants/FormInputType'
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'
import Select from "react-select";

import { toast } from "react-toastify";
import { GET_PRIMARY_CATEGORY_URL, SHIPPING_ZONE_URL } from "../../../constants/api";


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

// const submitToServer = (data) => {

//     console.log('data', data);


//     var form_data = { "name": data.name };

//     console.log('data', form_data);


//     let url = CUSTOMER_URL;

//     let token = localStorage.getItem('token');
//     // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
//     console.log(token);
//     axios.post(url, form_data,
//         {
//             headers: {
//                 'content-type': 'application/json',
//                 'Authorization': `Token ${token}`
//             }

//         })
//         .then(res => {
//             console.log(res.data);
//             // dispatch(reset('PartnerAddForm'))
//             toast.success('Successfully added a new Primary Category.')
//         })
//         .catch(err => console.log(err))
// };

// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
    <div>
        <label className="text-sm">{label}</label>
        <DateTimePicker
            onChange={onChange}
            format="DD-MM-YYYY"
            time={showTime}
            value={!value ? null : new Date(value)}
            placeholder={label}
        />
    </div>;


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddShippingZone extends Component {
    state = {
        errorZone: false,
        selected_zone: null,
        selected_post_code:null,
        errorCode:false
    }

    componentDidMount() {
        console.log(this.props.fetchShippingZone())
        this.props.fetchShippingZone(0)
        // this.props.fetchDistrict()
        this.props.getDistrictsForSelect()
    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }
    zoneChange = (selected_zone) => {
        this.setState({ selected_zone });
    };
    codeChange = (selected_post_code) => {
        this.setState({ selected_post_code });
    };
    submit(event) {
        const { selected_zone,selected_post_code } = this.state
        // const { reset } = this.props;
        // return sleep(1000)
        //     .then(() => {
        //         submitToServer(values);
        //         reset()
        //     })

        // var form_data = {
        //     "zone_name": event.zone_name,
        //     // "zone": event.zone,
        //     "days": event.days,
        // }

        var form_data = new FormData()
        form_data.append('zone_name', event.zone_name)
        form_data.append('days', event.days)
        form_data.append('max_days', event.max_days)
        form_data.append('post_code', event.post_code)
        if (selected_zone == null || selected_zone == '') {
            this.setState({ errorZone: true })
            return false
        }
        if (selected_zone != null) {
            form_data.append("zone", JSON.stringify(selected_zone))
            this.setState({ errorZone: false })

        }

        // if (selected_post_code == null || selected_post_code == '') {
        //     this.setState({ errorCode: true })
        //     return false
        // }
        // if (selected_post_code != null) {
        //     form_data.append("post_code", JSON.stringify(selected_post_code))
        //     this.setState({ errorZone: false })

        // }


        // alert(JSON.stringify(event))
        this.props.addData(form_data, SHIPPING_ZONE_URL, "Shipping Zone")




    }

    render() {

        const { selected_zone,selected_post_code, errorZone,errorCode } = this.state

        const { handleSubmit, submitting, districts,shipping_zones } = this.props;
        if (this.props.is_added) {
            setTimeout(() => {
                window.location.href = "/shipping-zone-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Shipping Zone </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Shipping Zone add</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Shipping Zone Information</h3>
                        <div className="float-right">
                            <Link to="/shipping-zone-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="zone_name"
                                        type="text"
                                        component={renderTextArea}
                                        label="Zone Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Select District</label>
                                    <span className="requiredField">*</span>
                                    <Select
                                        value={selected_zone}
                                        onChange={this.zoneChange}
                                        required={true}
                                        options={districts}
                                        placeholder="Select District"
                                        isMulti
                                        isClearable
                                    />
                                    {errorZone ? <span className="text-danger">Select Zone</span> : ''}

                                </div>

                                {/* <div className="col-md-6">
                                    <label>Select zone</label>
                                    <span className="requiredField">*</span>
                                    <Field name="zone" multiple={true} component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select zone</option>
                                        {this.props.districts.map(shipping_cls => (
                                            <option key={shipping_cls.id} value={shipping_cls.id}>{shipping_cls.name}</option>
                                        ))}
                                    </Field>
                                </div> */}

                                <div className="col-md-6">
                                    <Field
                                        name="days"
                                        type="text"
                                        component={renderField}
                                        label="Minimum Duration"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="max_days"
                                        type="text"
                                        component={renderField}
                                        label="Maximum Duration"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="post_code"
                                        type="text"
                                        component={renderField}
                                        label="Post Code(Ex:1230,1205,1210)"
                                        // validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>

                                {/* <div className="col-md-6">
                                    <label>Select Post Code</label>
                                    <span className="requiredField">*</span>
                                    <Select
                                        value={selected_post_code}
                                        onChange={this.codeChange}
                                        required={true}
                                        options={shipping_zones}
                                        placeholder="Select Post Code"
                                        isMulti
                                        isClearable
                                    />
                                    {errorCode ? <span className="text-danger">Select Post Code</span> : ''}

                                </div> */}

                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddShippingZone.propTypes = {
    // getRides:PropTypes.func.isRequired,
    // fetchShippingClass: PropTypes.func.isRequired,

    fetchShippingZone: PropTypes.func.isRequired,
    getDistrictsForSelect: PropTypes.func.isRequired,
    // fetchDistrict: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    districts: state.districts.districts,
    // shipping_classes: state.shipping_classes.shipping_classes,
    shipping_zones: state.shipping_zones.shipping_zones,
    is_added: state.shipping_zones.is_added

});


AddShippingZone = reduxForm({
    form: 'AddShippingZone',
}
)(AddShippingZone);


export default connect(mapStateToProps, { fetchShippingZone, addData, getDistrictsForSelect, fetchDistrict })(AddShippingZone);

import {
    FETCH_GROUPS,
    DELETE_GROUP,
    LOADING_GROUP,
    NOT_FOUND_GROUP,
    DETAIL_GROUP, UPDATE_GROUP, ADD_GROUP,
} from './types';

import {
    GROUP_URL
} from '../constants/api'
import { SearchURLGenerate } from '../constants/CustomMethod'

import { toast } from 'react-toastify'

import axios from 'axios';


// FETCH Partner
export const fetchGroups = (pageNumber, parameters={}) => (dispatch, getState) => {
    dispatch({type: LOADING_GROUP});
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let search_key = [ "name", ]
    
    var  search_url=SearchURLGenerate(parameters, search_key);
    
    if((search_url !== '' && search_url !== null) || pageNumber !== '' ){
       var url =GROUP_URL
        if(search_url !== '' && search_url !== null){  
            url = url + "?" +search_url;
        }else if (pageNumber){
            url=url + '?page=' + pageNumber
        }
    axios.get(url, {headers,})
        .then(response => {
            dispatch({
                type: FETCH_GROUPS,
                payload: response.data.results,
                count: response.data.count,
                next: response.data.next
            })
        })
    }else{
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_GROUP });
       
    }
};



export const groupDetail = id => (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};

    let {token} = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GROUP_URL + `${id}/`;
    axios.get(url, {headers})
        .then(response => {
            // console.log('partner detail', response.data)
            dispatch({
                type: DETAIL_GROUP,
                payload: response.data,
            })
            
        })
};



export const updateGroup = (id, data) => (dispatch, getState) => {
    // console.log('clint side partner-data', partner_id, partner_data);
    let headers = {"Content-Type": "application/json"};

    let {token} = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }


    axios.put(GROUP_URL+ `${id}/`, data, {headers})
        .then(response => {
          
            dispatch({
                
                type: UPDATE_GROUP,
                payload: response.data
            });
             toast.success('Group updated successfully')
        })
        .catch(error => {
            console.log(error);
            toast.error('Please try again')
        })
};


// Delete Ride Category

export const deleteGroup = id => (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};

    let {token} = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios.delete(GROUP_URL + `${id}/`, {headers})
        .then(response => {
            dispatch({
                type: DELETE_GROUP,
                payload: id,
            });
            toast.success('Group deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};

export const addGroup = (data) => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .post(GROUP_URL,data, { headers })
        .then(response => {
          
            dispatch({
                type: ADD_GROUP,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success('Role added successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};



import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required } from '../../../constants/Validate'
import { connect } from "react-redux";
import { blogTagDetail } from "../../../actions/BlogAction"
import { updateDatas } from "../../../actions/CustomerActions"

import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';


import { toast } from "react-toastify";
import { BLOG_TAG_URL } from "../../../constants/api";


momentLocalizer(moment);

export function convert(str) {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}

// const submitToServer = (data) => {
//     alert(GET_PRIMARY_CATEGORY_URL)
//     console.log('data', data);


//     var form_data = { "name": data.primarycategory.name };

//     console.log('data', form_data);


//     let url = GET_PRIMARY_CATEGORY_URL;

//     let token = localStorage.getItem('token');
//     // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
//     console.log(token);
//     axios.post(url, form_data,
//         {
//             headers: {
//                 'content-type': 'application/json',
//                 'Authorization': `Token ${token}`
//             }

//         })
//         .then(res => {
//             console.log(res.data);
//             // dispatch(reset('PartnerAddForm'))
//             toast.success('Successfully added a new Brands.')
//         })
//         .catch(err => console.log(err))
// };


// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class BlogTagUpdate extends Component {

    componentDidMount() {
        let id = this.props.match.params.id
        this.props.blogTagDetail(id)
    }
    // this.props.getRides(0)


    // handlePeriodChange(selVal){

    //     if(selVal!=''){
    //             this.props.getRideInfo(selVal)
    //     }else{
    //         this.props.getRideInfo(0)
    //     }

    // }

    // submit(values) {
    //     const { reset } = this.props;
    //     return sleep(1000)
    //         .then(() => {
    //             addTags(values);
    //             reset()
    //         })
    // }
    submit(event) {
        let id = this.props.match.params.id
        this.props.updateDatas(event, BLOG_TAG_URL+id+'/', "Blog Tag")
    }

    render() {

        const { handleSubmit, submitting } = this.props;

        if (this.props.is_updated) {
            setTimeout(() => {
                window.location.href = "/blog-tags-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Blog Tag </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Update Blog Tag</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Blog Tag Information</h3>
                        <div className="float-right">
                            <Link to="/blog-tags-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="Blog Tag"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                {/* <div className="col-md-6">
                                    <Field
                                        name="slug"
                                        type="text"
                                        component={renderField}
                                        label="Blog Category Slug"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div> */}

                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


BlogTagUpdate.propTypes = {
    // getRides:PropTypes.func.isRequired,
    blogTagDetail: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    initialValues:state.blog_tags.blog_tags,
    blog_tags: state.blog_tags.blog_tags,
    is_added: state.blog_tags.is_added

});


BlogTagUpdate = reduxForm({
    form: 'BlogTagUpdate',
    enableReinitialize: true

}
)(BlogTagUpdate);


export default connect(mapStateToProps, { blogTagDetail, updateDatas })(BlogTagUpdate);

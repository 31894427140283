import {
    GET_AGENTS,
    DETAIL_AGENTS,
    LOADING_AGENTS,
    DELETE_AGENTS,
    UPDATE_AGENTS,
    NOT_FOUND_AGENTS,
    LOADING_AGENT_CUSTOMER,
    GET_AGENT_CUSTOMER,
    LOADING_AGENT_PRODUCT_STOCK,
    FETCH_AGENT_PRODUCT_STOCK,
    NOT_FOUND_AGENT_PRODUCT_STOCK,
    DELETE_AGENT_PRODUCT_STOCK,
    DETAIL_AGENT_PRODUCT_STOCK,
    LOADING_AGENT_PRODUCT_SALE,
    FETCH_AGENT_PRODUCT_SALE,
    NOT_FOUND_AGENT_PRODUCT_SALE,
    DELETE_AGENT_PRODUCT_SALE,
    DETAIL_AGENT_PRODUCT_SALE,
    LOADING_AGENT_PRODUCT_STOCK_REPORTS,
    FETCH_AGENT_PRODUCT_STOCK_REPORTS,
    NOT_FOUND_AGENT_PRODUCT_STOCK_REPORTS,
    LOADING_CUSTOMER,
    DELETE_AGENT_CUSTOMER,
    DETAIL_AGENT_CUSTOMER,
} from './types';

import {
    BASE_URL,
    GET_ATTRIBUTE_URL,
    AGENT_URL,
    AGENT_CUSTOMER_URL,
    AGENT_STOCK_URL,
    AGENT_PRODUCT_SALE_CUSTOMER_URL,
    PRIMARY_AGENT_URL,
    AGENT_PRODUCT_STOCK_URL,
    AGENT_PRODUCT_STOCK_IN_REPORTS_URL
} from '../constants/api';

import { SearchURLGenerate } from '../constants/CustomMethod'

// import { createMessage, returnErrors } from './messages';
import { toast } from 'react-toastify'

import axios from 'axios';
// import actions from "redux-form/lib/actions";



export const searchAgent = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENTS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(AGENT_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: GET_AGENTS,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};




// FETCH AGENT
export const getAgents = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENTS });
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let search_key = ["agent_id", "user__phone_no", "user__email"]


    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = AGENT_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // console.log('agent data', response.data)
                dispatch({
                    type: GET_AGENTS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })


    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_AGENTS });

    }

};





export const getPrimaryAgents = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENTS });
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let search_key = ["agent_id", "user__phone_no", "user__email"]


    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = PRIMARY_AGENT_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // console.log('agent data', response.data)
                dispatch({
                    type: GET_AGENTS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })


    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_AGENTS });

    }

};




// export const getAllAgents = () => (dispatch, getState) => {
//     let headers = {"Content-Type": "application/json"};

//     let {token} = getState().auth;
//     if (token) {
//         headers["Authorization"] = `Token ${token}`;
//     }

//     let url;
//     url = BASE_URL + 'api/v1/agents/';

//     axios
//         .get(url, {headers,})
//         .then(response => {
//             dispatch({
//                 type: GET_AGENTS,
//                 payload: response.data.results,
//                 count: response.data.count,
//                 next: response.data.next
//             })
//         })
// };

// Agent Detail
export const agentDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = AGENT_URL + `${id}/`;
    axios
        .get(url, { headers })
        .then(response => {
            console.log('agent detail', response.data);
            dispatch({
                type: DETAIL_AGENTS,
                payload: response.data.results ? response.data.results : response.data,
            })
        })
};


// Delete agent
export const deleteAgent = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(AGENT_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_AGENTS,
                payload: id,
            });
            toast.success('Agent deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};



//**********************************/ Agent Product Stock **********************************


export const searchAgentCustomer = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENT_CUSTOMER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(AGENT_CUSTOMER_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: GET_AGENT_CUSTOMER,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};


// FETCH AGENT CUSTOMER
export const getAgentsCustomer = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENT_CUSTOMER });
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let search_key = ["customer_name", "customer_phone_no", "is_active"]


    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = AGENT_CUSTOMER_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                // console.log('agent data', response.data)
                dispatch({
                    type: GET_AGENT_CUSTOMER,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })


    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_AGENTS });

    }

};



// Agent Customer Detail
export const agentCustomerDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = AGENT_CUSTOMER_URL + `${id}/`;
    axios
        .get(url, { headers })
        .then(response => {
            // console.log('agent detail', response.data);
            dispatch({
                type: DETAIL_AGENT_CUSTOMER,
                payload: response.data.results ? response.data.results : response.data,
            })
        })
};




// Delete agent customer
export const deleteAgentCustomer = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(AGENT_CUSTOMER_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_AGENT_CUSTOMER,
                payload: id,
            });
            toast.success('Agent deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};




export const fetchAgentProductStock = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENT_PRODUCT_STOCK });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
         headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["created_by__phone_no",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = AGENT_STOCK_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                dispatch({
                    type: FETCH_AGENT_PRODUCT_STOCK,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_AGENT_PRODUCT_STOCK });

    }
};




export const searchAgentProductsStock = (value,agent_id) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENT_PRODUCT_STOCK });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios.get(AGENT_PRODUCT_STOCK_URL + '?product_name=' + value+'&agent_id='+agent_id, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_AGENT_PRODUCT_STOCK,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};




// Agent product stock Detail
export const AgentProductStockDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = AGENT_STOCK_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_AGENT_PRODUCT_STOCK,
                payload: response.data.results ? response.data.results : response.data,
            })
        })
};
//*****************End Agent Stock Detail Action ********************** */



// // Agent Product Stock
export const deleteAgentProductStock = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(AGENT_STOCK_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_AGENT_PRODUCT_STOCK,
                payload: id,
            });
            toast.success('Agent product stock has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//*****************End Agent Stock Delete Action ********************** */



//*****************Agent Product Sale Action ********************** */


export const fetchAgentProductSale = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENT_PRODUCT_SALE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
         headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["customer__customer_phone_no","customer__customer_name","invoice_no"]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = AGENT_PRODUCT_SALE_CUSTOMER_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                dispatch({
                    type: FETCH_AGENT_PRODUCT_SALE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_AGENT_PRODUCT_SALE });

    }
};



export const deleteAgentProductSale = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(AGENT_PRODUCT_SALE_CUSTOMER_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_AGENT_PRODUCT_SALE,
                payload: id,
            });
            toast.success('Agent product Sale has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};


// Agent product stock Detail
export const AgentProductSaleDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = AGENT_PRODUCT_SALE_CUSTOMER_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_AGENT_PRODUCT_SALE,
                payload: response.data.results ? response.data.results : response.data,
            })
        })
};



export const fetchAgentProductStockINReports = (pageNumber, parameters = {},searchurl=false) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENT_PRODUCT_STOCK_REPORTS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    var search_key = ["product__product_name","product__product_sku","agent__agent_id",'stock_type','to_date','from_date']
    var search_url = SearchURLGenerate(parameters, search_key);
    if (searchurl===true){
        search_url=''
    }

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = AGENT_PRODUCT_STOCK_IN_REPORTS_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url+'&stock_type=1';
        } else if (pageNumber) {
            //alert(JSON.stringify(parameters))
            var search_url = SearchURLGenerate(parameters, search_key);
//             alert(search_url)
            url = url + '?page=' + pageNumber+'&'+search_url+'&stock_type=1'
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_AGENT_PRODUCT_STOCK_REPORTS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_AGENT_PRODUCT_STOCK_REPORTS });

    }
};



export const fetchAgentProductStockOUTReports = (pageNumber, parameters = {},searchurl=false) => (dispatch, getState) => {
    dispatch({ type: LOADING_AGENT_PRODUCT_STOCK_REPORTS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    var search_key = ["product__product_name","product__product_sku","agent__agent_id",'stock_type','customer__customer_phone_no','customer__customer_name','to_date','from_date']
    var search_url = SearchURLGenerate(parameters, search_key);
    if (searchurl===true){
        search_url=''
    }

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = AGENT_PRODUCT_STOCK_IN_REPORTS_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url+'&stock_type=2';
        } else if (pageNumber) {
            //alert(JSON.stringify(parameters))
            var search_url = SearchURLGenerate(parameters, search_key);
//             alert(search_url)
            url = url + '?page=' + pageNumber+'&'+search_url+'&stock_type=2'
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_AGENT_PRODUCT_STOCK_REPORTS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_AGENT_PRODUCT_STOCK_REPORTS });

    }
};
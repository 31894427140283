import React, { Component } from "react";
import PropTypes from "prop-types";
import { Field, reduxForm } from "redux-form";
import { required } from "../../constants/Validate";
import ReactPaginate from 'react-paginate';
import {CheckPermisionAction} from "../../constants/CustomMethod"
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from "sweetalert2";
import { Modal, Button } from "react-bootstrap";

import {
    fetchTags,
    deleteTags,
    addTags,
    updateTags
} from "../../actions/TagsActions";
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
    },
};

const renderField = ({
    input,
    label,
    type,
    meta: { touched, error, warning },
}) => {
    const className = `form-control ${error && touched ? "is-invalid" : ""}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input
                    {...input}
                    placeholder={label}
                    type={type}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    );
};

class TagsList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this);
        this.handleModalShow = this.handleModalShow.bind(this);
        this.saveTags = this.saveTags.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.EditTags = this.EditTags.bind(this);
        this.handleEditClose = this.handleEditClose.bind(this)
        this.updateTag = this.updateTag.bind(this)
    }

    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        tags_name: "",
        name: "",
        id: '',
        show: false,
        showedit: false,
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
    };
    handleModalShow(data) {
        this.setState({ show: true, tags_name: data.name });
    }
    handleClose() {
        this.setState({ show: false });
    }

    handleEditClose() {
        this.setState({ showedit: false, });
    }
    updateTag() {
        var data = {
            name: this.state.tags_name
        }
        this.props.updateTags(this.state.id, data);
        this.setState({ showedit: false, });
    }
    onChangeedit() {
        this.setState({ showedit: true });
    }
    componentDidMount() {
        this.props.fetchTags(1);
    }
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchTags(pageNo)
    }

    opensweetalert(data) {
        Swal.fire({
            text: "Are you want to delete " + data.name + " ?",
            // text: "You won't be able to revert this!",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, delete it!",
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteTags(data.id);
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        "Deleted!",
                        "Your Data has been deleted " + data.name + ".",
                        "success"
                    );
                }
            }
        });
    }

    onChange = (e) => this.setState({ [e.target.name]: e.target.value });

    onSubmit = (e) => {
        e.preventDefault();
        const { name } = this.state;
        console.log([name]);

        this.props.fetchTags("", this.state);
        this.setState({ hideButton: true });
    };
    EditTags(data) {
        this.setState({ showedit: true, tags_name: data.name, id: data.id })
    }
    saveTags() {
        //alert(this.state.name)
        var data = {
            name: this.state.name,
        };
        this.props.addTags(data);
        this.setState({ show: false, name: "" });
        this.props.fetchTags(1);
        // values={name:this.state.attribute_name}
    }

    render() {
        const page = Math.ceil(this.props.count / 10)


        const isAdmin = this.props.auth.user.is_admin;
        const { show, showedit } = this.state;
        const {permissions} =this.props
        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Tags List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link className="text-info" to="/">
                                            Dashboard
                    </Link>
                                    </li>
                                    <li className="breadcrumb-item active">Tags list</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="row">
                    <ToastContainer />

                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Tags List</h3>
                                <div className="float-right">
                            {CheckPermisionAction(permissions,'Inventory','add_tags')?
                                    
                                    <button
                                        className="btn btn-primary text-white"
                                        onClick={this.handleModalShow}
                                    >
                                        <b>
                                            {" "}
                                            <i className="fa fa-plus-circle"></i>
                                        Add Tags
                                        </b>
                                    </button>
                                    :null}
                                </div>
                            </div>

                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form
                                        className="form-inline my-2 my-lg-0"
                                        onSubmit={this.onSubmit}
                                    >
                                        <input
                                            className="form-control mr-sm-4"
                                            name="name"
                                            type="text"
                                            onChange={this.onChange}
                                            placeholder="Tags Name"
                                            aria-label="Search"
                                        />
                                    {CheckPermisionAction(permissions,'Inventory','tags_search')?

                                        <button
                                            onClick={this.onSubmit}
                                            className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"
                                        >
                                            <i className="fas fa-search"></i> Search
                                         </button>
                                         :null}
                                    </form>
                                </div>
                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}
                            {CheckPermisionAction(permissions,'Inventory','view_tags_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th>Action</th>

                                            {this.state.hideButton ? (
                                                <th>
                                                    <Link
                                                        to="/tags-list"
                                                        onClick={() => {
                                                            window.location.href = "/tags-list";
                                                        }}
                                                        className="btn btn-info float-right text-white"
                                                    >
                                                        {" "}
                                                        <i className="fa fa-arrow-left"> List </i>
                                                    </Link>
                                                </th>
                                            ) : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ? (
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>
                                        ) : (
                                                this.props.tags.map((tag, index) => (
                                                    <tr key={tag.id}>
                                                        <td>{index + 1}</td>
                                                        <td>{tag.name}</td>
                                                        <td>
                                                    {CheckPermisionAction(permissions,'Inventory','tags_edit')?

                                                            <button className="btn btn-warning text-white" onClick={() => this.EditTags(tag)}>
                                                                <i
                                                                    className="fas fa-edit"
                                                                >
                                                                </i>
                                                            </button>
                                                            :null}
                                                     {CheckPermisionAction(permissions,'Inventory','tags_delete')?

                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={() => this.opensweetalert(tag)}
                                                            >
                                                                <i className="fas fa-trash"></i>
                                                            </button>
                                                        :null}

                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                            <Modal
                                show={show}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Tags</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <input
                                        name="name"
                                        type="text"
                                        className="form-control"
                                        placeholder="Tags Name"
                                        validate={required}
                                        onChange={this.onChange}
                                    />
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                  </Button>
                                    <Button variant="primary" onClick={this.saveTags}>
                                        Save
                  </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={showedit}
                                onHide={this.handleEditClose}
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Update Tags</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <input
                                        name="id"
                                        type="hidden"
                                        value={this.state.id}
                                        className="form-control"
                                        placeholder="Tags id"
                                        validate={required}
                                        onChange={this.onChange}
                                    />
                                    <input
                                        name="tags_name"
                                        type="text"
                                        value={this.state.tags_name}
                                        className="form-control"
                                        placeholder="Tags Name"
                                        validate={required}
                                        onChange={this.onChange}
                                    />
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleEditClose}>
                                        Close
                  </Button>
                                    <Button variant="primary" onClick={this.updateTag}>
                                        Update
                  </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        <div></div>
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        );
    }
}

TagsList.propTypes = {
    fetchTags: PropTypes.func.isRequired,
    deleteTags: PropTypes.func.isRequired,
    addTags: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    tags: state.tags.tags,
    count: state.tags.count,
    next: state.tags.next,
    is_loading: state.tags.is_loading,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions
});
TagsList = reduxForm({
    form: "TagsList",
})(TagsList);

export default connect(mapStateToProps, {
    fetchTags,
    deleteTags,
    addTags,
    updateTags
})(TagsList);

import {
    FETCH_CAMPAIGN,
    DETAIL_CAMPAIGN,
    DELETE_CAMPAIGN,
    LOADING_CAMPAIGN,
    ADD_DATA,
    UPDATE_CAMPAIGN,
    UPDATED_DATA,
    NOT_FOUND_CAMPAIGN,
    LOADING_CAMPAIGN_GROUP,
    FETCH_CAMPAIGN_GROUP,
    NOT_FOUND_CAMPAIGN_GROUP
} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { CAMPAIGN_URL,CAMPAIGN_GROUP_URL, GET_GALLERY_URL, PRODUCT_CSV_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


export const fetchCampaigns = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_CAMPAIGN });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["campaign_title"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = CAMPAIGN_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                //  alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_CAMPAIGN,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_CAMPAIGN });

    }
};
export const searchCampaigns = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_CAMPAIGN });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }




    axios.get(CAMPAIGN_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_CAMPAIGN,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};
// Coupon Detail
export const campaignDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = CAMPAIGN_URL + `${id}/`;
    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_CAMPAIGN,
                payload: response.data !=undefined?response.data:'',
            })
        })
};

// // Delete Coupon
export const deleteCampaign = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(CAMPAIGN_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_CAMPAIGN,
                payload: id,
            });
            toast.success('Campaign has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

export const addData = (data, url, message = "Data") => (dispatch, getState) => {
    let headers = { 'content-type': 'application/json' };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios
        .post(url, data, { headers })
        .then(response => {
            dispatch({
                type: ADD_DATA,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been added successfully");
        })
        .catch(error => {
            if (error.response) {
                console.log(error.response.data.error)
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the 
                // browser and an instance of
                // http.ClientRequest in node.js
                toast.warn("Something Wrong ! Please try again");

            } else {
                toast.warn("Something Wrong ! Please try again");
                // Something happened in setting up the request that triggered an Error
                // console.log('Error', error.message);
            }

        })
};

export const updateData = (data, url, message = "") => (dispatch, getState) => {

    let headers = { 'content-type': 'application/json' };
    let { token } = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .put(url, data, { headers })
        .then(response => {
            dispatch({
                type: UPDATED_DATA,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success(message + " has been updated successfully");
        }).catch(error => {
            console.log(error)
            toast.warn(message + " has not been updated successfully");
        })
};



// Campaign Group
export const fetchCampaignGroup = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_CAMPAIGN_GROUP });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = CAMPAIGN_GROUP_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                //  alert(JSON.stringify(response))
                dispatch({
                    type: FETCH_CAMPAIGN_GROUP,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_CAMPAIGN_GROUP });

    }
};
import React, { useEffect, useState } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import axios from 'axios'
import {MEDIA_LIBRARY_URL} from '../../constants/api'
import { addMediaLibrary  } from "../../actions/MediaLibraryAction"
import { toast } from "react-toastify";
import {CheckPermisionAction} from "../../constants/CustomMethod"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { connect } from 'react-redux';

const Uploader =(props)=> {
  const [images, setImages] = useState([]);
  const [copied, setCopy] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
        const result = await axios(
            MEDIA_LIBRARY_URL
        );
        // alert(JSON.stringify(result))
        setImages(result.data.results);
    };

    fetchData();
}, []);
  const handleChangeStatus = ({ meta }, status) => {
    console.log('test',status, meta);
  };

  const getCopy = () => {
    setCopy(true)
    toast.success("Image Path has been copied !!")
  };

  // const saveBase64AsFile = (base64, fileName) => {
  //   var link = document.createElement("a");

  //   link.setAttribute("href", base64);
  //   link.setAttribute("download", fileName);
  //   link.click();
  // };

  const handleSubmit = (files, allFiles) => {
    // alert(JSON.stringify(files))
    
    // console.log(files.map(f => f.meta));
    // const reader = new FileReader();
    // reader.onload = e => {
    //   // saveBase64AsFile(e.target.result, files[0].meta.name);
      // setImages(addMediaLibrary(files));
    // };
    // reader.readAsDataURL(files[0].file);
    // // console.log(files)
    var file =[]
    let form_data = new FormData()

    for (let i=0; i<files.length;i++){
        // const form_data = new FormData()
        form_data.append('media_images[]', files[i].file, files[i].file.name)     
    }
    let headers = { "Content-Type": "multipart/form-data" };
    axios
        .post(MEDIA_LIBRARY_URL, form_data, { headers })
        .then(response => {
            toast.success('Media Library added successfully');

            // dispatch({
            //     type: ADD_MEDIA_LIBRARY,
            //     payload: response.data.results ? response.data.results : response.data,
            // });
            // toast.success('Media Library added successfully');
            // console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
    
    // allFiles.forEach(f => f.remove());
  };

  return (
    <>
    <div>
    {CheckPermisionAction(props.permissions,'Media Library','upload_media_file')?
     
      <Dropzone
        inputContent={"Upload your media files"}
        inputWithFilesContent={"Upload more"}
        submitButtonContent={"Upload"}
        // getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        styles={{ dropzone: { minHeight: 250, maxHeight: 300 } }}
      />
      :null}
      {/* <img style={{height:100,width:100}} id="blah" src={images} alt="preview" /> */}
    </div>
    <div>
    {CheckPermisionAction(props.permissions,'Media Library','view_media_library_list')?

    <div className="col-12">
            <div className="card card-primary">
              <div className="card-header">
                <h4 className="card-title">Media Library</h4>
              </div>
              <div className="card-body">
                <div className="row">
                  {images.map(img=>(
                    <div className="col-sm-2">
                      <CopyToClipboard text={img.image}
           onCopy={() => getCopy()}
          >
            <div><input type="text" className="form-control" value={img.image}/><span style={{cursor:"pointer"}}><i class="fas fa-copy"></i></span>
            {/* {copied ? <span style={{color: 'red'}}>Copied.</span> : null} */}
            </div>
          
        </CopyToClipboard>
                    <a href={img.image} data-toggle="lightbox" data-title={img.name} data-gallery="gallery">
                      
                      
                      <img src={img.image} className="img-fluid mb-2" alt="white sample"/>
                    </a>
                  </div>

                  ))}
                  
                  
                </div>
              </div>
            </div>
          </div>
          :null}
    </div>
    </>
  );
}

const mapStateToProps =state=>({
  permissions:state.userpermissions.userpermissions
})
export default connect(mapStateToProps)(Uploader)
import {
    LOADING_SLIDE_SETTING,
    FETCH_SLIDE_SETTING,
    DETAIL_SLIDE_SETTING,
    DELETE_SLIDE_SETTING,
    NOT_FOUND_SLIDE_SETTING,
    LOADING_SLIDE_RIGHT_SIDE_SETTING,
    FETCH_SLIDE_RIGHT_SIDE_SETTING,
    DETAIL_SLIDE_RIGHT_SIDE_SETTING,
    DELETE_SLIDE_RIGHT_SIDE_SETTING,
    NOT_FOUND_SLIDE_RIGHT_SIDE_SETTING,
    LOADING_BANNER_SETTING,
    FETCH_BANNER_SETTING,
    DETAIL_BANNER_SETTING,
    DELETE_BANNER_SETTING,
    NOT_FOUND_BANNER_SETTING,
    LOADING_SOCIAL_MEDIA,
    FETCH_SOCIAL_MEDIA,
    DETAIL_SOCIAL_MEDIA,
    DELETE_SOCIAL_MEDIA,
    NOT_FOUND_SOCIAL_MEDIA,
    LOADING_FOOTER_LEFT_SECTION,
    FETCH_FOOTER_LEFT_SECTION,
    DETAIL_FOOTER_LEFT_SECTION,
    DELETE_FOOTER_LEFT_SECTION,
    NOT_FOUND_FOOTER_LEFT_SECTION

} from '../actions/types';



//*****************************START SLIDE SETTING REDUCER******************************** */

export const SlideSettingReducer = (state =
    {
        slide_settings: [],
        slide_setting: {},
        count: null,
        next: null,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        is_list:false
    }
    , action) => {
    switch (action.type) {
        case LOADING_SLIDE_SETTING:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_SLIDE_SETTING:
            return {
                ...state,
                slide_settings: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                is_list:true
            };
        case DETAIL_SLIDE_SETTING:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                slide_settings: action.payload,

            };
        case DELETE_SLIDE_SETTING:
            return {
                ...state,
                slide_settings: state.slide_settings.filter(slide_setting => slide_setting.id !== action.payload)
            };

        case NOT_FOUND_SLIDE_SETTING:
            return {
                ...state,
                slide_settings: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

//*****************************END SLIDE SETTING REDUCER******************************** */




//*****************************START SLIDE RIGHT SIDE SETTING REDUCER******************************** */

export const SlideRightSideSettingReducer = (state =
    {
        slide_right_side_settings: [],
        slide_right_side_setting: {},
        count: null,
        next: null,
        is_added: false,
        is_loading: false,
        is_getting: false,
        is_updated: false,
        is_list:false
    }
    , action) => {
    switch (action.type) {
        case LOADING_SLIDE_RIGHT_SIDE_SETTING:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_SLIDE_RIGHT_SIDE_SETTING:
            return {
                ...state,
                slide_right_side_settings: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false,
                is_list:true
            };
        case DETAIL_SLIDE_RIGHT_SIDE_SETTING:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                slide_right_side_settings: action.payload,

            };
        case DELETE_SLIDE_RIGHT_SIDE_SETTING:
            return {
                ...state,
                slide_right_side_settings: state.slide_right_side_settings.filter(slide_right_side_setting => slide_right_side_setting.id !== action.payload)
            };

        case NOT_FOUND_SLIDE_RIGHT_SIDE_SETTING:
            return {
                ...state,
                slide_right_side_settings: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

//*****************************END SLIDE RIGHT SIDE SETTING REDUCER******************************** */




//*****************************START BANNER SETTING REDUCER******************************** */

export const BannerSettingReducer = (
    state =
        {
            banner_settings: [],
            banner_setting: {},
            count: null,
            next: null,
            is_added: false,
            is_loading: false,
            is_getting: false,
            is_updated: false,
        }
    , action) => {
    switch (action.type) {
        case LOADING_BANNER_SETTING:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_BANNER_SETTING:
            return {
                ...state,
                banner_settings: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case DETAIL_BANNER_SETTING:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                banner_settings: action.payload,

            };
        case DELETE_BANNER_SETTING:
            return {
                ...state,
                banner_settings: state.banner_settings.filter(banner_setting => banner_setting.id !== action.payload)
            };

        case NOT_FOUND_BANNER_SETTING:
            return {
                ...state,
                banner_settings: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}


//*****************************END BANNER SETTING REDUCER******************************** */




//*****************************START SOCIAL MEDIA SETTING REDUCER******************************** */

export const SocialMediaReducer = (
    state =
        {
            social_medias: [],
            social_media: {},
            count: null,
            next: null,
            is_added: false,
            is_loading: false,
            is_getting: false,
            is_updated: false,
        }
    , action) => {
    switch (action.type) {
        case LOADING_SOCIAL_MEDIA:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_SOCIAL_MEDIA:
            return {
                ...state,
                social_medias: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case DETAIL_SOCIAL_MEDIA:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                social_medias: action.payload,

            };
        case DELETE_SOCIAL_MEDIA:
            return {
                ...state,
                social_medias: state.social_medias.filter(social_media => social_media.id !== action.payload)
            };

        case NOT_FOUND_SOCIAL_MEDIA:
            return {
                ...state,
                social_medias: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

//*****************************END SOCIAL MEDIA SETTING REDUCER******************************** */



//*****************************START FOOTER LEFT SECTION SETTING REDUCER******************************** */

export const FooterLeftSectionReducer = (
    state =
        {
            footer_left_sections: [],
            footer_left_section: {},
            count: null,
            next: null,
            is_added: false,
            is_loading: false,
            is_getting: false,
            is_updated: false,
        }
    , action) => {
    switch (action.type) {
        case LOADING_FOOTER_LEFT_SECTION:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_FOOTER_LEFT_SECTION:
            return {
                ...state,
                footer_left_sections: action.payload,
                count: action.count,
                next: action.next,
                is_getting: true,
                is_loading: false
            };
        case DETAIL_FOOTER_LEFT_SECTION:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                footer_left_sections: action.payload,

            };
        case DELETE_FOOTER_LEFT_SECTION:
            return {
                ...state,
                footer_left_sections: state.footer_left_sections.filter(footer_left_section => footer_left_section.id !== action.payload)
            };

        case NOT_FOUND_FOOTER_LEFT_SECTION:
            return {
                ...state,
                footer_left_sections: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

//*****************************END FOOTER LEFT SECTION SETTING REDUCER******************************** */

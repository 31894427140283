import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { email, phoneNumber, required, match } from '../../../constants/Validate'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { shippingZoneDetail, updateData } from "../../../actions/ShippingZoneActions"
import { getDistrictsForSelect, fetchDistrict } from "../../../actions/DistrictsActions"
import Select from "react-select";


import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import DateTimePicker from 'react-widgets/lib/DateTimePicker'

import { toast } from "react-toastify";
import { SHIPPING_ZONE_URL, GET_PRIMARY_CATEGORY_URL } from "../../../constants/api";


// Date picker

// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class ShippingZoneUpdate extends Component {
    state = {
        errorZone: false,
        selected_zone: null,
        selected_post_code:null,
        errorCode:false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
     
        if(nextProps.initialValues !== [] && nextProps.initialValues !== null && nextProps.initialValues !== ''){
            var zonedatas = []
          
            if (nextProps.initialValues.zone !==undefined && nextProps.initialValues.zone.length > 0) {
                var zones = nextProps.initialValues.zone
               
                for (var i = 0; i < zones.length; i++) {
    
                    zonedatas.push({ label: zones[i].name, value: zones[i].id })
                }
            }
            return{
                selected_zone: zonedatas.length>0 && zonedatas!==null && zonedatas!==undefined ? zonedatas.reverse(): ''  
            }
        }

        return null
    
    }
    componentDidMount() {
        // console.log(this.props.campaignDetail(id))
        let id = this.props.match.params.id
        this.props.shippingZoneDetail(id)
        // this.props.fetchDistrict()
        this.props.getDistrictsForSelect()
    }
    // this.props.getRides(0)


   

    submit(event) {

  

      
        var form_data = new FormData()
        form_data.append('zone_name', event.zone_name)
        form_data.append('days', event.days)
        form_data.append('max_days', event.max_days)

        form_data.append('post_code', event.post_code)
        if (this.state.selected_zone == null || this.state.selected_zone == '') {
            this.setState({ errorZone: true })
            return false
        }
        if (this.state.selected_zone != null) {
            form_data.append("zone", JSON.stringify(this.state.selected_zone))
            this.setState({ errorZone: false })

        }
        let id = this.props.match.params.id;
        this.props.updateData(form_data, SHIPPING_ZONE_URL + id + '/', "Shipping Zone")




    }
    zoneChange = (selected_zone) => {
        this.setState({ selected_zone })
    }

    render() {

        const { errorZone, selected_zone } = this.state
        const { handleSubmit, submitting, districts } = this.props;
        if (this.props.is_updated) {
            setTimeout(() => {
                window.location.href = "/shipping-zone-list"
            }, 3000);
        }
        // console.log(primarycategory)
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Shipping Zone Update </h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Shipping Zone Update</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Shipping Zone Information</h3>
                        <div className="float-right">
                            <Link to="/shipping-zone-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="zone_name"
                                        type="text"
                                        component={renderField}
                                        label="Zone Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Select District</label>
                                    <span className="requiredField">*</span>
                                    <Select
                                        value={selected_zone}
                                        onChange={this.zoneChange}
                                        required={true}
                                        options={districts}
                                        placeholder="Select District"
                                        isMulti
                                        isClearable
                                    />{errorZone ? <span className="text-danger">Select Zone</span> : ''}

                                </div>

                                {/* <div className="col-md-6">
                                    <label>Select Zone</label>
                                    <span className="requiredField">*</span>
                                    <Field name="zone" multiple={true} component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select zone</option>
                                        {this.props.districts.map(shipping_cls => (
                                            <option key={shipping_cls.id} value={shipping_cls.id}>{shipping_cls.name}</option>
                                        ))}
                                    </Field>
                                </div> */}

                                <div className="col-md-6">
                                    <Field
                                        name="days"
                                        type="text"
                                        component={renderField}
                                        label="Days"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="max_days"
                                        type="text"
                                        component={renderField}
                                        label="Maximum Duration"
                                        validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="post_code"
                                        type="text"
                                        component={renderField}
                                        label="Post Code"
                                        // validate={[required]}

                                    // warn={alphaNumeric}
                                    />
                                </div>


                            </div>

                            <div className="row">

                            </div>
                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info"
                                    disabled={submitting}>
                                    Update
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


ShippingZoneUpdate.propTypes = {
    // getRides:PropTypes.func.isRequired,

    shippingClassDetail: PropTypes.func.isRequired,
    // fetchDistrict: PropTypes.func.isRequired,
    getDistrictsForSelect: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({


    districts: state.districts.districts,
    shipping_zones: state.shipping_zones.shipping_zones,
    is_added: state.shipping_zones.is_added,
    is_updated: state.shipping_zones.is_updated,
    initialValues: state.shipping_zones.shipping_zones

});


ShippingZoneUpdate = reduxForm({
    form: 'ShippingZoneUpdate',
    enableReinitialize: true
}
)(ShippingZoneUpdate);


export default connect(mapStateToProps, { updateData, shippingZoneDetail, getDistrictsForSelect, fetchDistrict })(ShippingZoneUpdate);

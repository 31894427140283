import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import {
    required
} from '../../constants/Validate'
import { connect } from "react-redux";
import { brandsDetail, updateBrands } from "../../actions/BrandsActions";
import PropTypes from "prop-types";
import moment from 'moment';
import { Link } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import momentLocalizer from 'react-widgets-moment';
import { GET_BRANDS_URL } from '../../constants/api';



momentLocalizer(moment);




// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};
// Input Type
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);


const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class BrandsUpdate extends Component {

    state = {
        image: null,
        district_id: 0
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.brandsDetail(id)

    }
    handleImageChange = (e) => {
        this.setState({
            image: e.target.files[0],

        })
    };

    handleImageSubmit = (e) => {
        e.preventDefault();
        console.log(this.state);
        let id = this.props.match.params.id;
        let form_data = new FormData();
        form_data.append('image', this.state.image, this.state.image.name);
        // form_data.append('title', this.state.title);
        // form_data.append('content', this.state.content);
        let token = localStorage.getItem('token');
        let url = GET_BRANDS_URL;
        axios.post(url, form_data, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }
        })
            .then(res => {
                toast.success("Succesfully updated.");
                console.log(res.data);
                window.location.reload();
            })
            .catch(err => console.log(err))
    };

    submit(values) {
        // console.log(values)
        return sleep(1000).then(() => {
            let id = this.props.match.params.id;
            this.props.updateBrands(id, values);
        })
    }


    render() {
        const { handleSubmit, submitting } = this.props;
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Brands Information</h1>
                            </div>
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="">Update Brands Details
                            <Link to="/brands-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>
                        </h3>

                    </div>
                    {/* /.card-header */}
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">

                                <div className="col-md-6 form-group">
                                    <div className="col-md-6">
                                        <Field
                                            name="name"
                                            type="text"
                                            component={renderField}
                                            label="Name"
                                            validate={required}
                                        // value={this.state.name}
                                        // onChange={this.handleChange}
                                        // warn={alphaNumeric}
                                        />
                                    </div>
                                    {/* <div className="col-md-12">
                                        <label>Band Logo Image</label>
                                        <br />
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={this.onSelectFile}
                                        />
                                    </div> */}

                                    <div className="col-md-6">
                                        <label className="text-sm">Band Logo Image</label>
                                        <Field
                                            name="image"
                                            type="file"
                                            component={FileInput}
                                            accept="image/*"
                                            label="Band Logo Image"
                                        // validate={[required]}
                                        />
                                    </div>




                                </div>


                            </div>
                            <div className="row">
                                <div className="card-footer float-right">
                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-info"
                                            disabled={submitting}>
                                            Update
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>


        )
    }
}


BrandsUpdate.propTypes = {

    updateBrands: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    initialValues: state.brands.brand,
    brands: state.brands.brands

});

BrandsUpdate = reduxForm({
    form: 'BrandsUpdate',
    enableReinitialize: true,
}
)(BrandsUpdate);

export default connect(mapStateToProps, { brandsDetail, updateBrands })(BrandsUpdate);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Modal from 'react-modal';
import CircularProgress from "@material-ui/core/CircularProgress";
import {  deleteMobileAppSlider,fetchMobileAppSlider } from '../../../actions/RestaurantActions';
import Swal from 'sweetalert2'
import ReactPaginate from "react-paginate"
import {CheckPermisionAction} from "../../../constants/CustomMethod"

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};



// Modals.setAppElement('#modalRender');

class MobileAppSliderList extends Component {
    constructor() {
        super();
        this.state = {
            data: [],
            headers: [],
            fileName: 'ride-category-fare-list.xlsx',
            is_fetching: false,
            modalIsOpen: false,
        };

        // this.opensweetalert = this.opensweetalert.bind(this)

    }




    componentDidMount() {
        // console.log(this.state.data)

       
        this.props.fetchMobileAppSlider()
    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.banner_image + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteMobileAppSlider(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.banner_image + '.',
                        'success'
                    )
                }
            }
        })
    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            is_fetching: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            is_fetching: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchMobileAppSlider(pageNo)
    }

    render() {
        const page = Math.ceil(this.props.count / 10)
        const {permissions} = this.props
        // console.log(this.props.rideCategory)



        // const isAdmin = this.props.auth.user.is_admin;

        // let delete_button;
        // if (isAdmin) {
        //     delete_button = <p>News bulletin</p>
        // }

        return (

            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Mobile App Slider List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Mobile App Slider List</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                    {/*<!-- /.container-fluid -->*/}
                </section>

                {/* <!-- /.row --> */}
                <div className="row">
                    {/*    <Modals*/}
                    {/*    className="modal"*/}
                    {/*    show={this.state.isShowing}*/}
                    {/*    close={this.closeModalHandler}*/}
                    {/*    Some data*/}
                    {/*/>*/}
                    <ToastContainer />
                    <Modal
                        isOpen={this.state.modalIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >

                        <h2 ref={subtitle => this.subtitle = subtitle}>Hello</h2>
                        <button onClick={this.closeModal}>close</button>
                        <div>I am a modal</div>
                        <form>
                            <input />
                            <button>tab navigation</button>
                            <button>stays</button>
                            <button>inside</button>
                            <button>the modal</button>
                        </form>
                    </Modal>

                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <div className="float-right">
                                {/* {CheckPermisionAction(permissions,'Restaurant','add_restaurant_slider')? */}

                                    <Link to="/add-mobile-app-slider" className="btn btn-info float-right text-white"><b>
                                        + Add Mobile App Slider</b>
                                    </Link>
                                 {/* :null}  */}
                                </div>
                            </div>

                            {/* {CheckPermisionAction(permissions,'Restaurant','view_restaurant_slider_list')? */}

                            <div className="card-body table-responsive p-0">
                                <table id="table-to-xls" className="table table-hover table-bordered">
                                    <thead>
                                        <tr>
                                            <th cope="col">SL</th>
                                            
                                            <th>Image</th>
                                            {/* <th>Status</th> */}
                                            <th>Option</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ?
                                            <tr className="text-center">
                                                <td>
                                                    <CircularProgress />
                                                </td>
                                            </tr>

                                            : this.props.mobile_app_sliders.map((restaurantslider, index) => (
                                                <tr key={restaurantslider.id}>
                                                    <td>{index + 1}</td>
                                                    
                                                    <td>{restaurantslider.banner_image !== null ? (
                                                    <img src={restaurantslider.banner_image} height="100px" width="100px" alt="No Image" />

                                                ) : null}</td>
                                                {/* <td>{restaurantslider.is_active ? "Activated":"Deactivated"}</td> */}

                                                    <td >
                                                        <div className="btn-group btn-group-sm">
                                                            
                                                    {/* {CheckPermisionAction(permissions,'Restaurant','restaurant_slider_options')? */}
                                                    <>
                                                    <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                    <div className="dropdown-menu">
                                                        <a href={'/mobile-app-slider-update/' + restaurantslider.id} className="dropdown-item">Edit</a>
                                                        <div className="dropdown-divider"></div>

                                                        <a href="#" onClick={() => this.opensweetalert(restaurantslider)} className="dropdown-item">Delete</a>

                                                    </div>
                                                </>

                                                            
                                                     {/* :null}  */}
                                                        </div>
                                                   
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                             {/* :null
                             }  */}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>

                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div>
        )
    }
}




MobileAppSliderList.propTypes = {
    fetchMobileAppSlider: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    mobile_app_sliders:state.mobile_app_sliders.mobile_app_sliders,
    // cuisines: state.cuisines.cuisines,
    count: state.mobile_app_sliders.count,
    next: state.mobile_app_sliders.next,
    is_loading: state.mobile_app_sliders.is_loading,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions

});
// const modalElement = document.getElementById('modalRender');
export default connect(mapStateToProps, {  deleteMobileAppSlider,fetchMobileAppSlider })(MobileAppSliderList);



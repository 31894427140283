import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import {
    required
} from '../../constants/Validate'
import { connect } from "react-redux";
import { tagsDetail, updateTags } from "../../actions/TagsActions";
import { fetchBrands } from '../../actions/BrandsActions'
import PropTypes from "prop-types";
import moment from 'moment';
import { Link } from "react-router-dom";

import momentLocalizer from 'react-widgets-moment';



momentLocalizer(moment);




// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class TagsUpdate extends Component {

    state = {
        district_id: 0
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.tagsDetail(id)
        this.props.fetchBrands()

    }


    submit(values) {
        // console.log(values)
        return sleep(1000).then(() => {
            let id = this.props.match.params.id;
            this.props.updateTags(id, values);
        })
    }

    render() {
        const { handleSubmit, submitting } = this.props;
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update Tags Information</h1>
                            </div>
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="">Update Tags Details
                            <Link to="/tags-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>
                        </h3>

                    </div>
                    {/* /.card-header */}
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">


                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="Name"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Brands Name</label>
                                    <span className="requiredField">*</span>
                                    <Field name="brand" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select brands name</option>
                                        {this.props.brands.map(brand_name => (
                                            <option key={brand_name.id} value={brand_name.id}>{brand_name.name}</option>
                                        ))}
                                    </Field>
                                </div>








                            </div>
                            <div className="row">
                                <div className="card-footer float-right">
                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-info"
                                            disabled={submitting}>
                                            Update
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>


        )
    }
}


TagsUpdate.propTypes = {

    updateTags: PropTypes.func.isRequired,
    fetchBrands: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    initialValues: state.tags.tag,
    tags: state.tags.tags,
    brands: state.brands.brands

});

TagsUpdate = reduxForm({
    form: 'TagsUpdate',
    enableReinitialize: true,
}
)(TagsUpdate);

export default connect(mapStateToProps, { tagsDetail, updateTags, fetchBrands })(TagsUpdate);

import {
    FETCH_RESTAURANT,
    DETAIL_RESTAURANT,
    DELETE_RESTAURANT,
    LOADING_RESTAURANT,
    NOT_FOUND_RESTAURANT,
    LOADING_CUISINE,
    FETCH_CUISINE,
    NOT_FOUND_CUISINE,
    DETAIL_CUISINE,
    DELETE_CUISINE,
    LOADING_FOOD_NAME,
    FETCH_FOOD_NAME,
    NOT_FOUND_FOOD_NAME,
    DELETE_FOOD_NAME,
    LOADING_RESTAURANT_SLIDER,
    FETCH_RESTAURANT_SLIDER,
    NOT_FOUND_RESTAURANT_SLIDER,
    DELETE_RESTAURANT_SLIDER,
    LOADING_MEDICINE_DELIVERY,
    FETCH_MEDICINE_DELIVERY,
    NOT_FOUND_MEDICINE_DELIVERY,
    DELETE_MEDICINE_DELIVERY,
    LOADING_RIDER_CAR_TRIP,
    FETCH_RIDER_CAR_TRIP,
    NOT_FOUND_RIDER_CAR_TRIP,
    DELETE_RIDER_CAR_TRIP,
    LOADING_PERCEL_EXPRESS,
    FETCH_PERCEL_EXPRESS,
    NOT_FOUND_PERCEL_EXPRESS,
    DELETE_PERCEL_EXPRESS,
    DETAIL_FOOD_NAME,
    DETAIL_RESTAURANT_SLIDER,
    LOADING_FOOD_ORDER,
    FETCH_FOOD_ORDER,
    NOT_FOUND_FOOD_ORDER,
    DELETE_FOOD_ORDER,
    DETAIL_FOOD_ORDER,
    LOADING_RIDER_CAR_TYPE,
    FETCH_RIDER_CAR_TYPE,
    NOT_FOUND_RIDER_CAR_TYPE,
    DETAIL_RIDER_CAR_TYPE,
    DELETE_RIDER_CAR_TYPE,
    LOADING_PERCEL_TYPE,
    FETCH_PERCEL_TYPE,
    NOT_FOUND_PERCEL_TYPE,
    DETAIL_PERCEL_TYPE,
    DELETE_PERCEL_TYPE,
    DELETE_PERCEL_PLAN,
    DETAIL_PERCEL_PLAN,
    NOT_FOUND_PERCEL_PLAN,
    FETCH_PERCEL_PLAN,
    LOADING_PERCEL_PLAN,
    
    FETCH_MOBILE_APP_SLIDER,
    DETAIL_MOBILE_APP_SLIDER,
    DELETE_MOBILE_APP_SLIDER,
    LOADING_MOBILE_APP_SLIDER,
    NOT_FOUND_MOBILE_APP_SLIDER,


    
} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { RESTAURANT_URL, CUISINE_URL,CUISINE_ALL_URL,RIDER_CAR_TRIP_URL,PERCEL_EXPRESS_URL,PRODUCT_DISCOUNT_UPDATE_URL, PRODUCT_CSV_URL, FOOD_NAME_URL, RESTAURANT_SLIDER_URL, MEDICINE_DELIVERY_URL,FOOD_ORDER_URL, PERCEL_PLAN_URL, PERCEL_TYPE_URL, CAR_TYPE_URL, RIDER_CAR_TYPE_URL,MOBILE_APP_SLIDER_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


//*****************Start Restaurant Action ********************** */
export const fetchRestaurant = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_RESTAURANT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["restaurant_name", "contact_number", "email"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = RESTAURANT_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                
                dispatch({
                    type: FETCH_RESTAURANT,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_RESTAURANT });

    }
};


export const searchRestaurant = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_RESTAURANT });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios.get(RESTAURANT_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_RESTAURANT,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};





// Restaurant Detail
export const RestaurantDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = RESTAURANT_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_RESTAURANT,
                payload: response.data,
            })
        })
};

// // Delete Restaurant
export const deleteRestaurant = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(RESTAURANT_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_RESTAURANT,
                payload: id,
            });
            toast.success('Restaurant has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

//*****************End Restaurant Action ********************** */



//*****************Start Cuisine Action ********************** */


export const fetchCuisine = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_CUISINE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = CUISINE_ALL_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                
                dispatch({
                    type: FETCH_CUISINE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_CUISINE });

    }
};


export const searchCuisine = (value) => (dispatch, getState) => {
    dispatch({ type: LOADING_CUISINE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    axios.get(CUISINE_ALL_URL + '?search=' + value, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_CUISINE,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })

};





// Cuisine Detail
export const CuisineDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = CUISINE_ALL_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_CUISINE,
                payload: response.data,
            })
        })
};

//  Delete Cuisine
export const deleteCuisine = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(CUISINE_ALL_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_CUISINE,
                payload: id,
            });
            toast.success('Cuisine has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};


// Cuisine using By URL
export const CuisineByURL = (url) => (dispatch, getState) => {
    dispatch({ type: LOADING_CUISINE })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;

    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios.get(url, { headers, })
        .then(response => {
            // alert(JSON.stringify(response))
            dispatch({
                type: FETCH_CUISINE,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })



};


//*****************End Cuisine Action ********************** */




//*****************Start Food Name Action ********************** */

// Food Name using By URL

export const FoodNameByURL = (url) => (dispatch, getState) => {
    dispatch({ type: LOADING_FOOD_NAME })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }





    axios.get(url, { headers, })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: FETCH_FOOD_NAME,
                payload: response.data.results ? response.data.results : response.data,
                count: response.data.count,
                next: response.data.next
            })
        })



};



export const fetchFoodName = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_FOOD_NAME });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name", "cuisine__name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = FOOD_NAME_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                
                dispatch({
                    type: FETCH_FOOD_NAME,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_FOOD_NAME });

    }
};



// Food Name Detail
export const FoodNameDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = FOOD_NAME_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_FOOD_NAME,
                payload: response.data,
            })
        })
};




//  Delete Food Name
export const deleteFoodName = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(FOOD_NAME_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_FOOD_NAME,
                payload: id,
            });
            toast.success('Food Name has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};


//*****************End Food Name Action ********************** */



//*****************Start Restaurant Slider Action ********************** */
export const fetchRestaurantSlider = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_RESTAURANT_SLIDER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["title"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = RESTAURANT_SLIDER_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                
                dispatch({
                    type: FETCH_RESTAURANT_SLIDER,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_RESTAURANT_SLIDER });

    }
};




// Restaurant Slider Detail
export const RestaurantSliderDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = RESTAURANT_SLIDER_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_RESTAURANT_SLIDER,
                payload: response.data,
            })
        })
};





//  Delete Restaurant Slider
export const deleteRestaurantSlider = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(RESTAURANT_SLIDER_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_RESTAURANT_SLIDER,
                payload: id,
            });
            toast.success('Restaurant Slider has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};






//*****************Start Medicine Delivery Action ********************** */
export const fetchMedicineDelivery = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_MEDICINE_DELIVERY });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["receiver_mobile_no","receiver_name","order_no","status","created_at_from","created_at_to","user__phone_no"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = MEDICINE_DELIVERY_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                
                dispatch({
                    type: FETCH_MEDICINE_DELIVERY,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_MEDICINE_DELIVERY });

    }
};





//  Delete Restaurant Slider
export const deleteMedicineDelivery = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(MEDICINE_DELIVERY_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_MEDICINE_DELIVERY,
                payload: id,
            });
            toast.success('Medicine Delivery has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};





export const fetchRiderCarTrip = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_RIDER_CAR_TRIP });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["rider_name","rider_mobile_no","order_no","ride_status","created_at_from","created_at_to","user__phone_no"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = RIDER_CAR_TRIP_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                
                dispatch({
                    type: FETCH_RIDER_CAR_TRIP,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_RIDER_CAR_TRIP });

    }
};






export const fetchPercelExpress = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_PERCEL_EXPRESS });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["receiver_mobile_no","sender_mobile_no","order_no","status","created_at_from","created_at_to","user__phone_no"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = PERCEL_EXPRESS_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                
                dispatch({
                    type: FETCH_PERCEL_EXPRESS,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_PERCEL_EXPRESS });

    }
};





//*****************Start Food Order Action ********************** */
export const fetchFoodOrder = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_FOOD_ORDER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["order_no","order_date_from", "order_date_to","customer__phone_no","order_status"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = FOOD_ORDER_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                
                dispatch({
                    type: FETCH_FOOD_ORDER,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_FOOD_ORDER });

    }
};


// Food Order Detail
export const FoodorderDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = FOOD_ORDER_URL + `${id}/`;
    axios.get(url, { headers })
        .then(response => {

            // console.log('partner detail', response.data)
            dispatch({
                type: DETAIL_FOOD_ORDER,
                payload: response.data,
            })

        })
};



//*****************Start Rider Car Type Action ********************** */

export const fetchRiderCarType = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_RIDER_CAR_TYPE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = RIDER_CAR_TYPE_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                
                dispatch({
                    type: FETCH_RIDER_CAR_TYPE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_RIDER_CAR_TYPE });

    }
};



// Rider Car Type Detail
export const RiderCarTypeDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = RIDER_CAR_TYPE_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_RIDER_CAR_TYPE,
                payload: response.data,
            })
        })
};




//  Delete Rider Car Type
export const deleteRiderCarType = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(RIDER_CAR_TYPE_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_RIDER_CAR_TYPE,
                payload: id,
            });
            toast.success('Rider Car Type has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};


//*****************End Rider Car Type Action ********************** */




//*****************Start Percel Type Action ********************** */

export const fetchPercelType = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_PERCEL_TYPE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = PERCEL_TYPE_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                
                dispatch({
                    type: FETCH_PERCEL_TYPE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_PERCEL_TYPE });

    }
};



// Percel Type Detail
export const PercelTypeDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = PERCEL_TYPE_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_PERCEL_TYPE,
                payload: response.data,
            })
        })
};




//  Delete Percel Type
export const deletePercelType = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(PERCEL_TYPE_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_PERCEL_TYPE,
                payload: id,
            });
            toast.success('Percel Type has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};


//*****************End Percel Type Action ********************** */



//*****************Start Percel Plan Action ********************** */

export const fetchPercelPlan = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_PERCEL_PLAN });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["name"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = PERCEL_PLAN_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                
                dispatch({
                    type: FETCH_PERCEL_PLAN,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_PERCEL_PLAN });

    }
};



// Percel Plan Detail
export const PercelPlanDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = PERCEL_PLAN_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_PERCEL_PLAN,
                payload: response.data,
            })
        })
};




//  Delete Percel Plan
export const deletePercelPlan = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(PERCEL_PLAN_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_PERCEL_PLAN,
                payload: id,
            });
            toast.success('Percel Plan has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};


//*****************End Percel Plan Action ********************** */



//*****************Start Mobile App Slider Action ********************** */
export const fetchMobileAppSlider = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_MOBILE_APP_SLIDER });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["title"]

    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = MOBILE_APP_SLIDER_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                
                dispatch({
                    type: FETCH_MOBILE_APP_SLIDER,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_MOBILE_APP_SLIDER });

    }
};




// Mobile App Slider Detail
export const MobileAppSliderDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = MOBILE_APP_SLIDER_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_MOBILE_APP_SLIDER,
                payload: response.data,
            })
        })
};





//  Delete Mobile App Slider
export const deleteMobileAppSlider = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(MOBILE_APP_SLIDER_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_MOBILE_APP_SLIDER,
                payload: id,
            });
            toast.success('Mobile App Slider has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2'
import isEqual from "react-fast-compare";
import {BASE_URL, SELLER_BULK_URL } from '../../../constants/api'
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import Autosuggest from 'react-autosuggest';
import { Field, reduxForm } from "redux-form";

import ReactPaginate from "react-paginate"
import CircularProgress from "@material-ui/core/CircularProgress";

import { fetchSellers, deleteSeller, SelllerStatusChange } from '../../../actions/SellerActions';
import { BulkAction } from '../../../actions/ProductAction';
import {searchSeller,addData} from '../../../actions/CustomerActions'

class SellerList extends Component {
    constructor() {
        super();
        this.toggleAllChange = this.toggleAllChange.bind(this)
        this.handleStatusValues = this.handleStatusValues.bind(this);
        this.opensweetalert = this.opensweetalert.bind(this);
        this.state = {
            // search: " ",
    
            sellers: [],
            load: false,
            user__phone_no: "",
            user__email: "",
            shop_name:"",
            shop_trade_license:'',
            hideButton: false,
            customer_name: '',
            seller_info:''
    
        };
    

    }

   
    onSingleChange = index => (e) => {
        let sellers = [...this.state.sellers]
        // alert(sellers)
        
        sellers[index]["checked"] = e.target.checked
        let recent_length = sellers.filter(seller => seller.checked).length;

        if (sellers.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        this.setState({ sellers })

    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.is_list) {
     
            if (nextProps.is_list !== prevState.load) {
                var sellers = []
                for (var i = 0; i < nextProps.sellers.length; i++) {
                    Object.assign(nextProps.sellers[i], { 'checked': false });
                    sellers.push(nextProps.sellers[i])
                }
                if (!isEqual(nextProps.sellers, prevState.sellers)) {
                    return {

                        sellers:[...sellers],
                        load: true
                    }
                  }
                

            }
        }else return null;

    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.sellers!==this.props.sellers){
          this.setState({sellers: this.props.sellers});
        }
    }
    

    componentDidMount() {
        // alert(JSON.stringify(this.props.sellers))
        this.props.fetchSellers(1);

    }

    
    

    toggleAllChange(e) {
        const { value, checked } = e.target;
        let sellers = [...this.props.sellers]
        sellers.forEach(seller => seller.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, sellers, bulk: true })
        } else {
            this.setState({ allchecked: checked, sellers, bulk: false })
        }
    }
    handleStatusValues = i => e => {
        let sellers = [...this.state.sellers]
        // alert(sellers[i]['id'])



    }
    onChange = e => this.setState({ [e.target.name]: e.target.value });

    openDeclinealert(message = 'Decline', data) {

        Swal.fire({
            text: 'Are you want to ' + message + ' ' + data.user.first_name + ' ' + data.user.last_name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, ' + message + ' it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.BulkAction(
                        { 'id': data.id, 'action': message }, SELLER_BULK_URL
                    )
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        message + '!',
                        'Your Data has been  ' + message + data.user.first_name + ' ' + data.user.last_name + '.',
                        'success'
                    )
                }
            }
        })
    }
    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to  Delete ' + data.user.first_name + ' ' + data.user.last_name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteSeller(data.id)
                    // this.setState({sellers: this.state.sellers.filter((r) => r !== data)})

                    Swal.fire(
                        'Deleted!',
                        'Seller has been Deleted ' + data.user.first_name + ' ' + data.user.last_name,
                        'success'
                    )
                }
            }
        })
    }


    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = []

            for (var i = 0; i < this.state.sellers.length; i++) {
                if (this.state.sellers[i].checked) {
                    results.push(this.state.sellers[i].user.id)
                }
            }

            if (value === "1") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, SELLER_BULK_URL
                )
            } else if (value === "0") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, SELLER_BULK_URL
                )
            } else if (value === "3") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, SELLER_BULK_URL
                )
            } else {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, SELLER_BULK_URL
                )
            }
        }
    }

    onSubmit = e => {

        e.preventDefault();
        // alert("hjdsha")
        this.setState({ load:false,hideButton: true,sellers:[] })
        this.props.fetchSellers('',this.state);
        // this.props.getAgents('', '', phone_no);
        // this.props.getAgents('', '', '', email);


        // this.setState({ hideButton: true })
    };
    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.fetchSellers(pageNo)
    }


  onChangeCustomer = (event, { newValue }) => {
    // alert(JSON.stringify(newValue))
    this.setState({
      customer_name: newValue
    });
    // alert(this.state.customer_name)
  };
    getCustomerSuggestions = customer_name => {
        if (customer_name !== undefined) {
          const inputValue = customer_name.trim().toLowerCase();
          const inputLength = inputValue.length;
          this.props.fetchSellers(customer_name);
          return inputLength === 0 ? [] : this.props.sellers.filter(customer =>
            customer.shop_name.toLowerCase().slice(0, inputLength) === inputValue
          );
        }
      };
    
      onSuggestionsCustomerClearRequested = () => {
        this.setState({
          sellers: []
        });
      };
      getSuggestionCustomerValue = customer => customer.user.phone_no;
    
      onSuggestionsFetchRequestedCustomer = ({ value }) => {
       
        this.setState({
          sellers: this.getCustomerSuggestions(value)
        });
      };
    
      onSuggestionSelectedCustomer = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
      
        this.setState({seller_info:suggestion})
       
      };
      renderSuggestionCustomer = customer => (
        // alert(JSON.stringify(customer))
        <div>
          {customer.user.phone_no}
        </div>
      );
      // Triggered on clear
     
    

    render() {
        const page = Math.ceil(this.props.count / 10)


        const isAdmin = this.props.auth.user.is_admin;
        const { sellers, bulk, allchecked } = this.state
        const {permissions} =this.props
        const {customer_name} = this.state;
        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/seller-list'
            },
                1000);
        }
        if (this.props.is_updated) {
            setTimeout(function () {

                window.location.href = "/seller-list"
            }, 3000)
        }

        const inputCustomerProps = {
            placeholder: 'Type Your Customer Mobile No or Email or Name',
            value: customer_name,
            type: "search",
            name: 'textBoxValue',
            onChange: this.onChangeCustomer
          };
        return (
            <div>
                {/* <!-- /.row --> */}

                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Seller List</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            {/* <div class="col-sm-6">
                                    <ol class="breadcrumb float-sm-right">
                                        <li class="breadcrumb-item"><a href="#">Home</a></li>
                                        <li class="breadcrumb-item active">Dashboard v2</li>
                                    </ol>
                                </div><!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                {/*<div className="row">*/}
                <div className="col-12">
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title">Seller Information</h3>
                            <div className="float-right">
                            {CheckPermisionAction(permissions,'Seller','add_seller')?
                                <>
                                {this.props.auth.user.is_admin?
                                <>
                                <a class="btn btn-primary" href={BASE_URL+'/api/v1/export-seller-report/'} role="button"><i className='fa fa-download'></i> Download</a>

                                <Link to="/add-seller" className="btn btn-primary text-white float-right"> <b><i className="fa fa-plus-circle"></i>Add
                                    Seller</b></Link>&nbsp;
                                </>
                                :null}
                                </>
                            :null}
                            </div>


                        </div>

                        <div style={{ margin: "15px" }} className="card-tools">
                            <div className="float-left">
                                <form className="form-inline my-2 my-lg-0" >

                                    <input className="form-control mr-sm-4" name="user__phone_no" type="text"
                                        onChange={this.onChange} placeholder="Seller Phone No"
                                        aria-label="Search" />
                                        {/* <Autosuggest
                                            suggestions={this.props.sellers ? this.props.sellers : []}
                                            onSuggestionsFetchRequested={this.onSuggestionsFetchRequestedCustomer}
                                            onSuggestionsClearRequested={this.onSuggestionsCustomerClearRequested}
                                            getSuggestionValue={this.getSuggestionCustomerValue}
                                            renderSuggestion={this.renderSuggestionCustomer}
                                            inputProps={inputCustomerProps}

                                        onSuggestionSelected={this.onSuggestionSelectedCustomer}
                                        /> */}
                                    {/* <input className="form-control mr-sm-4" name="shop_trade_license" type="text"
                                        onChange={this.onChange} placeholder="Shop Trade License No"
                                        aria-label="Search" /> */}
                            {CheckPermisionAction(permissions,'Seller','seller_search')?

                                    <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                        type="submit"><i className="fas fa-search"></i> Search
                                    </button>
                            :null}
                                </form>
                            </div>


                        </div>

                        {/* <!-- /.card-header --> */}


                        <div>
                            <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Bulk Action--</option>
                                            <option value="1" >Activated</option>
                                            <option value="0">Deactivated</option>
                                            <option value="2">Delete</option>

                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>
                            {CheckPermisionAction(permissions,'Seller','view_seller_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                            {this.props.auth.user.is_admin?
                                            <th>
                                                <input type="checkbox"
                                                    checked={allchecked}
                                                    onChange={this.toggleAllChange}
                                                />
                                            </th>
                                            :null}
                                            <th>ID</th>
                                            <th>Seller Name</th>
                                            <th>Mobile Number</th>

                                            <th>Shop Name</th>
                                            <th>Total Sales Amount</th>
                                            <th>Status</th>
                                            <th>Action</th>

                                            {this.state.hideButton ?
                                                <th><Link to="/seller-list" onClick={() => { window.location.href = "/seller-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.props.is_loading ?
                                         <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> 
                                        : sellers.length != undefined && sellers.map((seller, index) => (
                                            <tr key={seller.id}>
                                                {this.props.auth.user.is_admin || this.props.auth.user.is_agent?
                                                <td>
                                                    <input type="checkbox" onChange={this.onSingleChange(index)} checked={seller.checked ? true : false} value={seller.id} />
                                                </td>
                                                :null}
                                                <td>{index + 1}</td>
                                                <td>{seller.user ? seller.user.first_name + ' ' + seller.user.last_name : ''}</td>
                                                <td>{seller.user ? seller.user.phone_no : ''}</td>

                                                <td>{seller.shop_name}</td>
                                                <td>{seller.total_sales}</td>

                                                <td>
                                                    <select key={index}
                                                        name="status"
                                                        component="select"
                                                        className="form-control"

                                                        onChange={this.handleStatusValues(index)}
                                                    >
                                                        <option value="1" selected={seller.user && seller.user.is_active == 1 ? true : false} >Activated</option>
                                                        <option value="0" selected={seller.user && seller.user.is_active == 0 ? true : false}>Deactivated</option>

                                                    </select>
                                                </td>
                                                <td className="btn-group btn-group-sm">
                                                
                                                    <div class="btn-group">
                                                {CheckPermisionAction(permissions,'Seller','seller_options')?
                                                    <>
                                                    {this.props.auth.user.is_admin || this.props.auth.user.is_agent?
                                                    <>
                                                        <button type="button" class="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>
                                                
                                                        <div class="dropdown-menu">
                                                            <a href={'/seller-update/' + seller.id} class="dropdown-item">Edit</a>
                                                            <div class="dropdown-divider"></div>

                                                            <a href="#" onClick={() => this.openDeclinealert("Approve", seller)} class="dropdown-item">Approve</a>
                                                            <a href="#" onClick={() => this.openDeclinealert("Decline", seller)} class="dropdown-item">Decline</a>
                                                            <a href="#" onClick={() => this.opensweetalert(seller)} class="dropdown-item">Delete</a>

                                                        </div>
                                                        </>
                                                        :null}
                                                    </>
                                                        :null}
                                                    </div>
                                                </td>


                                            </tr>
                                        ))}


                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}
                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>


                        </div>

                    </div>
                    {/* <!-- /.card --> */}
                </div>
                {/*</div>*/}
                {/* <!-- /.row --> */}
            </div>
        )
    }
}

SellerList.propTypes = {
    fetchSellers: PropTypes.func.isRequired,
    deleteSeller: PropTypes.func.isRequired,
    BulkAction: PropTypes.array.isRequired,

};

const mapStateToProps = state => ({
    sellers: state.sellers.sellers,
    is_loading: state.sellers.is_loading,
    is_list: state.sellers.is_list,
    auth: state.auth,
    bulk: state.products.bulk,
    is_updated: state.sellers.is_updated,
    permissions:state.userpermissions.userpermissions,
    count: state.sellers.count,
    next: state.sellers.next,

});
SellerList = reduxForm({
    form: "SellerList",
})(SellerList);
export default connect(mapStateToProps, { fetchSellers,searchSeller, deleteSeller, SelllerStatusChange, BulkAction })(SellerList);
import {
    FETCH_BUNDLE,
    DETAIL_BUNDLE,
    DELETE_BUNDLE,
    LOADING_BUNDLE,
    ADD_DATA,
    UPDATED_DATA,
    NOT_FOUND_BUNDLE,

} from './types';

import { toast, } from 'react-toastify'
// import {push} from 'react-router-redux';

import axios from 'axios';
import { BUNDLE_PRODUCT_URL } from "../constants/api";
import { SearchURLGenerate } from '../constants/CustomMethod'
import { relativeTimeThreshold } from 'moment';


export const fetchBundleProduct = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_BUNDLE });
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let search_key = ["title"]
    var search_url = SearchURLGenerate(parameters, search_key);
    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = BUNDLE_PRODUCT_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }

        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response.data.results))
                dispatch({
                    type: FETCH_BUNDLE,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_BUNDLE });

    }
};

export const BundleDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = BUNDLE_PRODUCT_URL + `${id}/`;

    axios
        .get(url, { headers })
        .then(response => {
            //  alert(JSON.stringify(response))
            dispatch({
                type: DETAIL_BUNDLE,
                payload: response.data,
            })
        })
};


// // Delete Bundle Product
export const deleteBundleProduct = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(BUNDLE_PRODUCT_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_BUNDLE,
                payload: id,
            });
            toast.success('Bundle Product has been deleted successfully');

        })
        .catch(error => {
            console.log(error)
        })
};
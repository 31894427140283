import {
    LOADING_TAX,
    FETCH_TAX,
    ADD_DATA,
    DETAIL_TAX,
    UPDATE_TAX,
    DELETE_TAX,
    NOT_FOUND_TAX,
    UPDATED_DATA,


} from '../actions/types';

const initialState = {
    taxs: [],
    tax: {},
    count: null,
    next: null,
    is_added: false,
    is_loading: false,
    is_getting: false,
    is_updated: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_TAX:
            return {
                ...state,
                is_loading: true
            };
        case FETCH_TAX:
            return {
                ...state,
                taxs: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case UPDATE_TAX:
            // alert(JSON.stringify(action.payload))
            return {

                ...state,
                taxs: action.payload,
                is_loading: true
            };
        case DETAIL_TAX:
            // alert('text' + JSON.stringify(action.payload))
            return {
                ...state,
                is_getting: true,
                taxs: action.payload,

            };
        case DELETE_TAX:
            return {
                ...state,
                taxs: state.taxs.filter(tax => tax.id !== action.payload)
            };
        case ADD_DATA:
            return {
                ...state,
                is_added: true,
                taxs: [action.payload]
            };

        case UPDATED_DATA:
            return {
                ...state,
                is_updated: true,
                // customers: action.payload

            };


        case NOT_FOUND_TAX:
            return {
                ...state,
                taxs: [],
                count: 0,
                next: 0,
                is_loading: false
            };
        default:
            return state;
    }
}

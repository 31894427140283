import {
    GET_USERS, NOT_FOUND_USERS,
} from '../actions/types';

const initialState = {
    all_users: [],
    // staff: {},
    count: null,
    next: null,
    is_loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {

        case GET_USERS:
            return {
                ...state,
                all_users: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case NOT_FOUND_USERS:
            return{
                ...state,
                all_users:[],
                count:0,
                next:0,
                is_loading: false,

            };
        default:
            return state;
    }
}
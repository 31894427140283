import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import { Link } from "react-router-dom";
import { required,phoneNumber, match } from '../../constants/Validate'
import { renderField } from '../../constants/FormInputType'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import momentLocalizer from 'react-widgets-moment';
import { addData } from '../../actions/ProductAction';
import { GET_WAREHOUSE_LIST } from "../../constants/api";
import Select from "react-select";
import { toast } from "react-toastify";
import { searchProducts } from '../../actions/ProductAction';
import { fetchBrandsAll } from '../../actions/BrandsActions';
import { fetchSellers } from '../../actions/SellerActions';




momentLocalizer(moment);


// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// Input Type






// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class AddWarehouse extends Component {

  static propTypes = {
    onChangeDetails: PropTypes.func
  };

    state={
        value:'',
        payment_type:"1",
        verified: false,
        totalAmount: 0,
        totalQuantity: 0,
        allowProductSize: false,

        selected_list: [
        ],
        load_again_attribute: false,
        cuisine_list: [],
        child_details:[],
        food_names:[],
       

    }

    // this.props.getRides(0)
    componentDidMount() {
        
        
       
       
    }
    

      // OnChange event handler
  onChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };
    onChanged=(e)=>{
        
        [e.target.name]=e.target.value
}







    submit(event) {
            var form_data = new FormData();
            Object.keys(event).forEach(key => form_data.append(key, event[key]));       
            this.props.addData(form_data, GET_WAREHOUSE_LIST, "Warhouse")

            }
  
    render() {
        const { handleSubmit, submitting } = this.props;
     
        if (this.props.is_added) {
            setTimeout(function () {
                window.location.href = '/warehouse-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add  Warehouse</h1>
                            </div>
                            {/*<!-- /.col --> */}
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Add Warehouse</li>
                                </ol>
                            </div>
                            {/*<!-- /.col --> */}
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Warehouse Information</h3>
                        <div className="float-right">
                            <Link to="/warehouse-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>

                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                            
                               
                                <div className="col-md-6">
                                    <Field
                                        name="warehouse_name"
                                        type="text"
                                        component={renderField}
                                        label="Warehouse Name"
                                        validate={required}
                                        mandatory={true}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                               
                                <div className="col-md-6">
                                    <Field
                                        name="address"
                                        type="text"
                                        component={renderField}
                                        label="Address"
                                        validate={required}
                                        mandatory={true}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                            
                                
                                

                                
                               
      
                            </div>
                            

                        </div>
                        <div className="card-footer text-right">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-success"
                                    disabled={submitting}>
                                    Save
                                </button>

                            </div>
                        </div>
                    </form>
                </div>
            </div>

        )
    }
}


AddWarehouse.propTypes = {
    // getRides:PropTypes.func.isRequired,
    addData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
    is_added: state.products.is_added,
    products: state.products.products,
    brands:state.brands.brands,
    sellers:state.sellers.sellers,
    districts: state.districts.districts,
    cuisines:state.cuisines.cuisines,
    foodnames: state.foodnames.foodnames,


});


AddWarehouse = reduxForm({
    form: 'AddWarehouse',
}
)(AddWarehouse);


export default connect(mapStateToProps, { addData})(AddWarehouse);

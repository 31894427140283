import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import { toast, } from 'react-toastify'

import {
    required,match
} from '../../../constants/Validate'
import { connect } from "react-redux";
import { userDetail, updateData } from "../../../actions/UserCreateActions";
import { fetchGroups } from "../../../actions/GroupAction";

import PropTypes from "prop-types";
import moment from 'moment';
import { Link } from "react-router-dom";
import { USER_CREATE_URL } from "../../../constants/api";

import momentLocalizer from 'react-widgets-moment';



momentLocalizer(moment);




// Input Type
const renderField = ({ input, label, type, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};


// Submit data
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

// Main form
class UserUpdate extends Component {

    state = {
        district_id: 0
    }

    componentDidMount() {

        let id = this.props.match.params.id;
        this.props.userDetail(id)
        this.props.fetchGroups()

    }


    submit(event) {
        // console.log(values)
        // return sleep(1000).then(() => {
        //     let id = this.props.match.params.id;
        //     this.props.updateData(id, values);
        // })
        var form_data = new FormData();

        form_data.append("first_name", event.first_name ? event.first_name : '');
        form_data.append("last_name", event.last_name ? event.last_name : '');
        form_data.append("email", event.email ? event.email : '');
        form_data.append("phone_no", event.phone_no ? event.phone_no : '');
        form_data.append('group', event.group ? event.group : '')

        if(event.group===1){
            form_data.append('is_admin',1)
        }else if(event.group>1){
          
            form_data.append('is_admin',0)
        }
        if (event.new_password !== '' && event.new_password!==undefined) {
            if(event.new_password===event.conf_password){
                form_data.append("password", event.new_password);
            }else{
                toast.warning("Password did not match !!");
                return false
            }
           
        }
        if (event.user_photo != '') {
            form_data.append("user_photo", event.user_photo ? event.user_photo : '');
        }

        this.props.updateData(form_data, USER_CREATE_URL+this.props.match.params.id+'/', "User")


    }

    render() {
        const { handleSubmit, submitting } = this.props;
        if (this.props.is_updated) {
            setTimeout(function () {

                window.location.href = '/users-list'
            },
                3000);
        }
        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Update User Information</h1>
                            </div>
                        </div>
                        {/*<!-- /.row --> */}
                    </div>
                    {/*<!-- /.container-fluid --> */}
                </div>
                <div className="card card-info">
                    <div className="card-header">
                        <h3 className="">User Details
                            <Link to="/users-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>
                        </h3>

                    </div>
                    {/* /.card-header */}
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">

                                {/* <div className="col-md-6 form-group"> */}
                                <div className="col-md-6">
                                    <Field
                                        name="first_name"
                                        type="text"
                                        component={renderField}
                                        label="First Name"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="last_name"
                                        type="text"
                                        component={renderField}
                                        label="Last Name"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="phone_no"
                                        type="text"
                                        component={renderField}
                                        label="Phone No"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="email"
                                        type="text"
                                        component={renderField}
                                        label="Email"
                                        validate={required}
                                    // value={this.state.name}
                                    // onChange={this.handleChange}
                                    // warn={alphaNumeric}
                                    />
                                </div>

                                <div className="col-md-6">
                                    <label>User Role

                                    </label>
                                    <span className="requiredField">*</span>
                                    <Field name="group" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select Role</option>
                                        {this.props.groups.map(group => (
                                            <option key={group.id}  value={group.id}>{group.name}</option>
                                        ))}
                                    </Field>
                                </div>

                                <div className="col-md-6">
                                    <Field
                                        name="new_password"
                                        type="password"
                                        component={renderField}
                                        label="Password"
                                    // validate={required}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="conf_password"
                                        type="password"
                                        component={renderField}
                                        label="Retype Password"
                                        // validate={[ match("new_password")]}
                                    />
                                </div>







                                {/* </div> */}


                            </div>
                            <div className="row">
                                <div className="card-footer float-right">
                                    <div>
                                        <button
                                            type="submit"
                                            className="btn btn-info"
                                            disabled={submitting}>
                                            Update
                                        </button>

                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>

            </div>


        )
    }
}


UserUpdate.propTypes = {

    updateData: PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    initialValues: state.users.users,
    users: state.users.users,
    groups: state.groups.groups,
    is_added: state.users.is_added,

    is_updated: state.users.is_updated

});

UserUpdate = reduxForm({
    form: 'UserUpdate',
    enableReinitialize: true,
}
)(UserUpdate);

export default connect(mapStateToProps, { userDetail, updateData, fetchGroups })(UserUpdate);

// import React, { Component } from "react";
// import { Field, reduxForm } from "redux-form";
// import { ToastContainer } from "react-toastify";
// import { PRODUCT_URL } from "../../constants/api";
// import { Link, Redirect } from "react-router-dom";
// import Select from "react-select";
// import RichTextEditor from 'react-rte';
// import Autosuggest from 'react-autosuggest';
// import { UpSellSearchProducts, CrossSellSearchProducts } from '../../actions/ProductAction';

// import { sumTotalAmount, sumTotalQuantity } from '../../constants/CustomMethod'
// import ReactCrop from "react-image-crop";
// import "react-image-crop/dist/ReactCrop.css";
// import { updateData, productDetail, deleteGallryImage } from '../../actions/ProductAction';
// import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
// import { getPrimaryCategoryForSelect } from "../../actions/PrimaryCategoryAction"
// import { getChildCategoryForSelect } from "../../actions/ChildCategoryAction"
// import { getSecondaryCategoryForSelect } from "../../actions/SecondaryCategoryAction"
// import { getAttribute } from '../../actions/AttributeActions';
// import { getAttributeOption, getAttributeOptionColor, getAttributeOptionSize } from '../../actions/AttributeOptionActions';
// import { fetchSellersForSelect } from '../../actions/SellerActions';
// import { BASE_URL, GET_ATTRIBUTE_OPTION_LIST_URL } from '../../constants/api'
// import { getProductTagsForSelect } from '../../actions/TagsActions';
// import { fetchBrandsAll } from '../../actions/BrandsActions';
// import { fetchProductUnitALL } from '../../actions/ProductUnitAction';
// import { getProductTaxsForSelect } from "../../actions/TaxActions"

// import DateTimePicker from 'react-widgets/lib/DateTimePicker'



// import {
//   productName,
//   productSKU,
//   purchasePrice,
//   regularPrice,
//   dateTime,
//   SingleIdSelect
// } from "../../constants/Validate";



// const alphaNumeric = (value) =>
//   value && /[^a-zA-Z0-9 ]/i.test(value)
//     ? "Only alphanumeric characters"
//     : undefined;

// const renderField = ({
//   input,
//   label,
//   type,
//   meta: { touched, error, warning },
//   required = false,
// }) => {
//   const className = `form-control ${error && touched ? "is-invalid" : ""}`;
//   return (
//     <div className="form-group">
//       <label className="text-sm">{label}</label>
//       {required ? <span className="requiredField">*</span> : ""}
//       <div>
//         <input
//           {...input}
//           placeholder={label}
//           type={type}
//           className={className}
//         />
//         {touched &&
//           ((error && <span className="text-danger">{error}</span>) ||
//             (warning && <span>{warning}</span>))}
//       </div>
//     </div>
//   );
// };

// // Text area type
// const renderTextArea = ({
//   input,
//   label,
//   meta: { touched, error, warning },
// }) => {
//   const className = `form-control ${error && touched ? "is-invalid" : ""}`;
//   return (
//     <div>
//       <label className="text-sm">{label}</label>
//       <div>
//         <textarea
//           {...input}
//           placeholder={label}
//           rows="3"
//           cols="40"
//           className={className}
//         />
//         {touched &&
//           ((error && <span className="text-danger">{error}</span>) ||
//             (warning && <span>{warning}</span>))}
//       </div>
//     </div>
//   );
// };


// // Date picker
// const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
//   <div>
//     <label className="text-sm">{label}</label>
//     <DateTimePicker
//       onChange={onChange}
//       format="DD-MM-YYYY hh:mm A"
//       time={showTime}
//       value={!value ? null : new Date(value)}
//       placeholder={label}
//     />
//   </div>;

// // File Input type

// const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// class ProductUpdate extends Component {
//   deteteRow = this.deteteRow.bind(this);
//   deteteGalleryImage = this.deteteGalleryImage.bind(this);
//   handleChangeColoImage = this.handleChangeColoImage.bind(this);

//   state = {
//     gallery_uploaded_images: [],
//     UpsellList: [],
//     product_detail: "",
//     crossSellList: [],
//     crossproduct: '',
//     errorPrimary: false,
//     errorSecondary: false,
//     errorvendor: false,
//     errorChild: false,
//     allowProductColor: false,
//     allowProductSize: false,
//     upsellsproducts: [],
//     crosssellsproducts: [],
//     allowShippingChange: false,
//     allowProductSeo: false,
//     allowPreOrder: false,
//     allowRecommendProduct: false,
//     allowFreeDelivery: false,
//     allowFastDelivery: false,
//     allowUpsell: false,
//     allowCrossSell: false,
//     allowWholeSell: false,
//     featureFile: null,
//     product_details: RichTextEditor.createEmptyValue(),
//     product_summary: RichTextEditor.createEmptyValue(),
//     attributeoptions:[],
//     value: '',
//     totalAmount: 0,
//     totalQuantity: 0,
//     galleries: [
//       { image: null, }
//     ],
//     src: null,
//     crop: {
//       unit: "%",
//       width: 40,
//       aspect: 16/14
//     },
//     vendor: "",
//     selected_primary: null,
//     primary: null,
//     selected_sceondary: null,
//     secondary: null,
//     selected_child: null,
//     selected_tags: null,
//     selected_taxs: null,
//     child: null,
//     details: '',
//     colorDetails: [
//       {
//         index: Math.random(),
//         selected_option: [],
//         attribute: '',
//         attributes: [],
//         attribute_options: [],
//         // quantity: "",
//         // size_price: "",
//       },
//     ],
//     gallery_images: [
//       {
//         index: Math.random(),
//         image: null,
//       },
//     ],


//   };

//   static propTypes = {
//     onChange: PropTypes.func
//   };

//   onChangeDetails = (product_details) => {
//     this.setState({ product_details });
//     if (this.props.onChange) {
//       // Send the changes up to the parent component as an HTML string.
//       // This is here to demonstrate using `.toString()` but in a real app it
//       // would be better to avoid generating a string on each change.
//       this.props.onChangeDetails(
//         product_details.toString('html')
//       );
//     }
//   };

//   onChangeProductSummary = (product_summary) => {
//     this.setState({ product_summary });
//     if (this.props.onChange) {
//       // Send the changes up to the parent component as an HTML string.
//       // This is here to demonstrate using `.toString()` but in a real app it
//       // would be better to avoid generating a string on each change.
//       this.props.onChangeDetails(
//         product_summary.toString('html')
//       );
//     }
//   };
//   handletextChild = index => (e) => {
    
//     let child_products = [...this.state.attributeoptions];
//     let addedItem = child_products[index].findIndex(item=> item[e.target.name])
    
//     if(addedItem !==-1){
     
//        var key =  e.target.name;
//        var data =child_products[index]
       
//          for (let i=0;i<data.length;i++){
//                 if(data[i][key]!==undefined){
//                   if(data[i][key]==="feature_image"){
//                     data[i][key]=e.target.files[0]
//                   }else{
//                     data[i][key]=e.target.value
//                   }
                  
//                 }

        
//       }
//       }else{
//         if(e.target.name==='feature_image'){
//           child_products[index].push({[e.target.name]:e.target.files[0]})
           
//         }else{
//           child_products[index].push({[e.target.name]:e.target.value})
//         }
       
//       }
    
//      this.setState({attribute_list:child_products})
//     //  console.log(this.state.attribute_list)
//     // let child_product = [...this.state.attributeoptions];
//     // alert(JSON.stringify(child_product))
//     // child_products[index][e.target.name]=e.target.value
//       // this.setState(child_products)
      
//     // if (child_products[index][e.target.name] !== undefined){
//     //   child_products[index][e.target.name]=e.target.vlaue
     
//     //     //child_products[index][e.target.name]=e.target.vlaue
//     // }else{
//     //      let obj ={
//     //       [e.target.name]:e.target.vlaue
//     //     }
//     //     alert(JSON.stringify(obj)) 
//     // }
  
//   }

//   onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

//     let { UpsellList } = this.state;
//     suggestion.quantity = 1
//     suggestion.subtotal = Number(suggestion.regular_price) - Number(suggestion.discount_price)
//     var index = UpsellList.findIndex(x => x.id == suggestion.id);

//     // console.log(suggestion);
//     if (index === -1) {
//       UpsellList.push(suggestion)
//     } else {
//       UpsellList[index].quantity = UpsellList[index].quantity + 1;
//       UpsellList[index].subtotal += suggestion.regular_price - suggestion.discount_price;
//     }
//     this.setState({ totalAmount: sumTotalAmount(UpsellList), totalQuantity: sumTotalQuantity(UpsellList) })
//   };

//   getCombinations(arr, n)
//   {
//       if(n == 1)
//       {
//           var ret = [];
//           for(var i = 0; i < arr.length; i++)
//           {
           
//             if(arr[i]!==null){

//                 for(var j = 0; j < arr[i].length; j++)
//                 {
//                     ret.push([arr[i][j]]);
//                 }

//             }  
//           }
//           return ret;
//       }
//       else
//       {
//           var ret = [];
//           for(var i = 0; i < arr.length; i++)
//           {
//               var elem = arr.shift();
//               for(var j = 0; j < elem.length; j++)
//               {
//                   var childperm = this.getCombinations(arr.slice(), n-1);
//                   for(var k = 0; k < childperm.length; k++)
//                   {
                      
//                       ret.push([elem[j]].concat(childperm[k]));
//                   }
//               }
//           }
//           return ret;
//       }
//   }


//   // Features Images Add Methods

//   onSelectFile = (e) => {
//     if (e.target.files && e.target.files.length > 0) {
//       const reader = new FileReader();
//       reader.addEventListener("load", () =>
//         this.setState({ src: reader.result })
//       );
//       reader.readAsDataURL(e.target.files[0]);
//     }
//   };
//   onImageLoaded = (image) => {
//     this.imageRef = image;
//   };

//   onCropComplete = (crop) => {
//     this.makeClientCrop(crop);
//   };
//   attributeOptionChange = index => (selected_data) => {
//     var colorDetails = [...this.state.colorDetails]
//     colorDetails[index]["selected_option"] = selected_data
//     this.setState({ colorDetails })
//     var attr_options=[]
//     for (let i =0;i<this.state.colorDetails.length;i++){

//       attr_options.push(this.state.colorDetails[i].selected_option)

//     }
//     var atr_opt =this.getCombinations(attr_options,attr_options.length)
//     this.setState({ attributeoptions:atr_opt })
//   };
//   onTagsChange = (selected_tags) => {
//     this.setState({ selected_tags });
//   };
//   onTaxsChange = (selected_taxs) => {
//     this.setState({ selected_taxs });
//   };

//   onCropChange = (crop, percentCrop) => {
//     // You could also use percentCrop:
//     // this.setState({ crop: percentCrop });
//     this.setState({ crop });
//   };
//   componentDidMount() {
//     let id = this.props.match.params.id;

//     this.props.productDetail(id)
//     this.props.getPrimaryCategoryForSelect()
//     this.props.getChildCategoryForSelect()
//     this.props.getSecondaryCategoryForSelect()
//     this.props.getAttributeOptionSize(1);
//     this.props.getAttributeOptionColor(1);
//     this.props.fetchSellersForSelect(1);
//     this.props.getAttribute()
//     this.props.fetchBrandsAll(1);
//     this.props.fetchProductUnitALL(1);
//     this.props.getProductTagsForSelect("")
//     this.props.getProductTaxsForSelect("")
//     // alert(JSON.stringify(this.state.product_detail))
//     //this.setState({product_details:RichTextEditor.createValueFromString(this.state.product_detail, 'html')})
//   }

//   async makeClientCrop(crop) {
//     if (this.imageRef && crop.width && crop.height) {
//       console.log(crop)
//       const croppedImageUrl = await this.getCroppedImg(
//         this.imageRef,
//         crop,
//         "newFile.jpeg"
//       );
//       this.setState({ croppedImageUrl });
//     }
//   }

//   getCroppedImg(image, crop, fileName) {
//     const canvas = document.createElement("canvas");
//     const scaleX = image.naturalWidth / image.width;
//     const scaleY = image.naturalHeight / image.height;
//     canvas.width = crop.width;
//     canvas.height = crop.height;
//     const ctx = canvas.getContext("2d");

//     ctx.drawImage(
//       image,
//       crop.x * scaleX,
//       crop.y * scaleY,
//       crop.width * scaleX,
//       crop.height * scaleY,
//       0,
//       0,
//       crop.width,
//       crop.height
//     );

//     return new Promise((resolve, reject) => {
//       canvas.toBlob((blob) => {
//         if (!blob) {
//           //reject(new Error('Canvas is empty'));
//           console.error("Canvas is empty");
//           return;
//         }
//         blob.name = fileName;
//         window.URL.revokeObjectURL(this.fileUrl);
//         this.setState({ featureFile: blob })
//         this.fileUrl = window.URL.createObjectURL(blob);
//         resolve(this.fileUrl);
//       }, "image/jpeg");
//     });
//   }

//   //For Fesature Images End Functions



//   handleChangeColoImage(e) {
//     let gallery_images = [...this.state.gallery_images];
//     gallery_images[e.target.dataset.id] = {
//       ...gallery_images[e.target.dataset.id],
//       image: URL.createObjectURL(e.target.files[0]),
//     };

//     let galleries = [...this.state.galleries]

//     galleries[e.target.dataset.id] = {
//       image: e.target.files[0]
//     };
//     this.setState({ gallery_images: gallery_images, galleries: galleries });
//   }
//   addGalleryImage = (e) => {
//     this.setState((prevState) => ({
//       gallery_images: [
//         ...prevState.gallery_images,
//         {
//           index: Math.random(),

//           file: "",
//         },
//       ],
//     }));
//   };

//   addNewRow = (e) => {
//     this.setState((prevState) => ({
//       colorDetails: [
//         ...prevState.colorDetails,
//         {
//           index: Math.random(),
//           attributes: [],
//           selected_option: [],
//           attribute_options: [],
//         },
//       ],
//     }));
//   };

//   deteteRow  (record)  {
//     // event.preventDefault()
//     this.setState({
//       colorDetails: this.state.colorDetails.filter((r) => r !== record),
//     });
//   }
//   deteteCrossSellRow = (record) => (event) => {
//     event.preventDefault()
//     this.setState({
//       colorDetails: this.state.crossSellList.filter((r) => r !== record),
//     });
//   }
//   deteteUpSellsRow = (record) => (event) => {
//     event.preventDefault()
//     this.setState({
//       colorDetails: this.state.UpsellList.filter((r) => r !== record),
//     });
//   }
//   deteteGalleryImage(record) {
//     this.setState({
//       gallery_images: this.state.gallery_images.filter((r) => r !== record),
//     });
//   }
//   deteteUploadGalleryImage(record) {

//     this.setState({
//       gallery_uploaded_images: this.state.gallery_uploaded_images.filter((r) => r !== record),
//     });
//     this.props.deleteGallryImage(record.id)
//   }




//   allowColorChange = () => {
//     this.setState({ allowProductColor: !this.state.allowProductColor });
//   };
//   allowRecommendChange = () => {
//     this.setState({ allowRecommendProduct: !this.state.allowRecommendProduct });
//   };
//   allowUpsellsChnage = () => {
//     this.setState({ allowUpsell: !this.state.allowUpsell });
//   };
//   allowCrossSellsChnage = () => {
//     this.setState({ allowCrossSell: !this.state.allowCrossSell });
//   };
//   allowWholeSellsChnage = () => {
//     this.setState({ allowWholeSell: !this.state.allowWholeSell });
//   };
//   allowRecommendChange = () => {
//     this.setState({ allowRecommendProduct: !this.state.allowRecommendProduct });
//   };

//   allowFreeDeliveryChange = () => {
//     this.setState({ allowFreeDelivery: !this.state.allowFreeDelivery });
//   };
//   allowFastDeliveryChange = () => {
//     this.setState({ allowFastDelivery: !this.state.allowFastDelivery });
//   };
//   allowRecommendChange = () => {
//     this.setState({ allowRecommendProduct: !this.state.allowRecommendProduct });
//   };

//   allowproductSeoChange = () => {
//     this.setState({ allowProductSeo: !this.state.allowProductSeo });
//   };
//   allowPreorderChnage = () => {
//     this.setState({ allowPreOrder: !this.state.allowPreOrder });
//   };

//   allowWholeSellChange = () => {
//     this.setState({ allowWholeSell: !this.state.allowWholeSell });
//   };

//   allowShippingChange = () => {
//     this.setState({ allowShippingTime: !this.state.allowShippingTime });
//   };

//   allowSizeChange = () => {
//     this.setState({ allowProductSize: !this.state.allowProductSize });
//   };
//   primaryChange = (selected_primary) => {
//     this.setState({ selected_primary });
//     console.log(selected_primary)
//   };
//   secondaryChange = (selected_sceondary) => {
//     this.setState({ selected_sceondary });
//   };
//   childChange = (selected_child) => {
//     this.setState({ selected_child });
//   };
//   vendorChange = (vendor) => {
//     this.setState({ vendor });
//   };
//   setColor = (setColor) => {
//     this.setState({ setColor });
//   };

//   getSuggestions = value => {
//     // crossSellProducts
//     const inputValue = value.trim().toLowerCase();
//     const inputLength = inputValue.length;
//     this.props.UpSellSearchProducts(value);
//     // alert(JSON.stringify(this.props.upsellProducts))
//     return inputLength === 0 ? [] : this.props.upsellProducts.filter(product =>
//       product.product_name.toLowerCase().slice(0, inputLength) === inputValue
//     );
//   };

//   // Suggestion rerender when user types
//   onSuggestionsFetchRequested = ({ value }) => {
//     //alert(value)
//     this.setState({
//       upsellsproducts: this.getSuggestions(value)
//     });
//   };
//   // Trigger suggestions
//   getSuggestionValue = product => '';

//   // Render Each Option
//   renderSuggestion = product => (
//     <div>
//       {product.product_name}
//     </div>
//   );
//   onSuggestionsClearRequested = () => {
//     this.setState({
//       upsellsproducts: []
//     });
//   };
//   // OnChange event handler
//   onChange = (event, { newValue }) => {
//     //alert(newValue)
//     this.setState({
//       value: newValue
//     });
//   };


//   getCrossSuggestions = value => {
//     // crossSellProducts
//     const inputValue = value.trim().toLowerCase();
//     const inputLength = inputValue.length;
//     this.props.CrossSellSearchProducts(value);
//     //  alert(JSON.stringify(this.props.crossSellProducts))
//     return inputLength === 0 ? [] : this.props.crossSellProducts.filter(product =>
//       product.product_name.toLowerCase().slice(0, inputLength) === inputValue
//     );
//   };

//   // Suggestion rerender when user types
//   onCrossSuggestionsFetchRequested = ({ value }) => {
//     //alert(value)
//     this.setState({
//       crosssellsproducts: this.getCrossSuggestions(value)
//     });
//   };
//   // Trigger suggestions
//   getCrossSuggestionValue = product => '';

//   // Render Each Option
//   renderCrossSuggestion = product => (
//     <div>
//       {product.product_name}
//     </div>
//   );
//   onCrossSuggestionsClearRequested = () => {
//     this.setState({
//       crosssellsproducts: []
//     });
//   };
//   // OnChange event handler
//   onCrossChange = (event, { newValue }) => {
//     //alert(newValue)
//     this.setState({
//       crossproduct: newValue
//     });
//   };

//   onCrossSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

//     let { crossSellList } = this.state;
//     suggestion.quantity = 1
//     suggestion.subtotal = Number(suggestion.regular_price) - Number(suggestion.discount_price)
//     var index = crossSellList.findIndex(x => x.id == suggestion.id);

//     // console.log(suggestion);
//     if (index === -1) {
//       crossSellList.push(suggestion)
//     } else {
//       crossSellList[index].quantity = crossSellList[index].quantity + 1;
//       crossSellList[index].subtotal += suggestion.regular_price - suggestion.discount_price;
//     }
//     // alert(JSON.stringify(crossSellList))
//     this.setState({ totalAmount: sumTotalAmount(crossSellList), totalQuantity: sumTotalQuantity(crossSellList) })
//   };


//   handleSelectedValues = index => e => {
//     var colorDetails = [...this.state.colorDetails]
//     //alert(e.target.value)
//     let id = e.target.value
//     colorDetails[index]["attribute"] = id;
//     const requestOptions = {
//       method: 'GET',
//       headers: { 'Content-Type': 'application/json' },

//     };
//     colorDetails[index]["selected_option"] = null

//     fetch(GET_ATTRIBUTE_OPTION_LIST_URL + '?attribute__id=' + id, requestOptions)
//       .then(async response => {
//         const data = await response.json();

//         // check for error response
//         if (!response.ok) {
//           // get error message from body or default to response status
//           const error = (data && data.message) || response.status;
//           return Promise.reject(error);
//         }
//         console.log(data.results)
//         var results = []
//         for (var i = 0; i < data.results.length; i++) {
//           results.push({ label: data.results[i].name, value: data.results[i].id })
//         }
//         colorDetails[index]["attribute_options"] = results
//         this.setState({ colorDetails })

//       })
//       .catch(error => {
//         this.setState({ errorMessage: error.toString() });
//         console.error('There was an error!', error);
//       });
//   }
//   static getDerivedStateFromProps(nextProps, prevState) {
//     if (nextProps.is_getting === true) {
//       if (nextProps.is_getting !== prevState.details) {
//         if (nextProps.products.length == undefined) {
//         var  colorDetails= [
//             {
//               index: Math.random(),
//               selected_option: [],
//               attribute: '',
//               attributes: [],
//               attribute_options: [],
//               // quantity: "",
//               // size_price: "",
//             },
//           ]

         
         
//           var products = nextProps.products
//           // alert(JSON.stringify( products.childproductdetails))

//           for (i = 0; i < products.childproductdetails.length; i++) {
//             var attroption = products.product_details_info[i].attributeoption
//             var attributeoptions = []
//             for (var j = 0; j < attroption.length; j++) {
//               attributeoptions.push({ label: attroption[j].name, value: attroption[j].id })
//             }
//             colorDetails.push({ 'attribute': products.product_details_info[i].attribute, 'selected_option': attributeoptions })
//           }
//           if (products.primary_categories.length > 0) {
//             var primaries = products.primary_categories
//             var primarydatas = []
//             for (var i = 0; i < primaries.length; i++) {

//               primarydatas.push({ label: primaries[i].name, value: primaries[i].id })
//             }
//           }
//           if (products.secondary_categories.length > 0) {
//             var secondries = products.secondary_categories
//             var secondriesdatas = []
//             for (var i = 0; i < secondries.length; i++) {
//               secondriesdatas.push({ label: secondries[i].name, value: secondries[i].id })
//             }
//           }
//           if (products.child_categories.length > 0) {
//             var childs = products.child_categories
//             var childsdatas = []
//             for (var i = 0; i < childs.length; i++) {

//               childsdatas.push({ label: childs[i].name, value: childs[i].id })
//             }
//           }
//           if (products.tags.length > 0) {
//             var tags = products.tags
//             var tagsdatas = []
//             for (var i = 0; i < tags.length; i++) {
//               tagsdatas.push({ label: tags[i].name, value: tags[i].id })
//             }
//           }
//           if (products.taxs.length > 0) {
//             var taxs = products.taxs
//             var taxsdatas = []
//             for (var i = 0; i < taxs.length; i++) {
//               taxsdatas.push({ label: taxs[i].tax_class_name, value: taxs[i].id })
//             }
//           }

//           var colorDetails = []
//           for (i = 0; i < products.product_details_info.length; i++) {
//             var attroption = products.product_details_info[i].attributeoption
//             var attributeoptions = []
//             for (var j = 0; j < attroption.length; j++) {
//               attributeoptions.push({ label: attroption[j].name, value: attroption[j].id })
//             }
//             colorDetails.push({ 'attribute': products.product_details_info[i].attribute, 'selected_option': attributeoptions })
//           }

//           // alert(JSON.stringify(products.product_details))
//           //alert(JSON.stringify(products.up_sell_product))
//           return {
        
//             vendor: products.vendor ? { value: products.vendor.id, label: products.vendor.shop_name } : null,
//             details: true,
//             allowProductSize:products.is_attribue,
//             selected_primary: primarydatas.reverse(),
//             selected_sceondary: secondriesdatas!==undefined && secondriesdatas!==null ? secondriesdatas.reverse() :'',
//             selected_child: childsdatas!==undefined && childsdatas!==null ? childsdatas.reverse() :'',
//             selected_tags: tagsdatas !==undefined && tagsdatas !==null ? tagsdatas.reverse():"",
//             selected_taxs: taxsdatas !==undefined && taxsdatas !==null ? taxsdatas.reverse():"",
//             // colorDetails: colorDetails,
//             allowProductSeo: products.is_product_seo,
//             allowUpsell: products.is_up_sell,
//             allowCrossSell: products.is_cross_sell,
//             allowWholeSell: products.is_whole_sell,
//             allowPreOrder: products.is_pre_order,
//             allowFastDelivery: products.is_fast_delivery,
//             allowFreeDelivery: products.is_free_delivery,
//             product_summary:RichTextEditor.createValueFromString(products.product_summary,'html'),
//             product_details: RichTextEditor.createValueFromString(products.product_details, 'html'),
//             product_details_info: products.product_details_info,
//             UpsellList: products.up_sell_product !== null && products.up_sell_product !== '' ? products.up_sell_product : [],
//             crossSellList: products.cross_sell_product !== null && products.cross_sell_product !== '' ? products.cross_sell_product : [],
//             // allowShippingTime: products.is_estimated_shipping,
//             allowRecommendProduct: products.is_feature_product,
//             gallery_uploaded_images: products.gallery_images,

//           }

//         }
//       }
//       return null
//     }
//     return null

//   }

//   submit(event) {
//     const {
//       allowRecommendProduct,
//       allowProductSeo,
//       colorDetails,
//       allowProductSize,
//       allowShippingTime,
//       vendor,
//       featureFile,
//       allowPreOrder,
//       gallery_images,
//       selected_primary,
//       selected_sceondary,
//       selected_child,
//       allowFreeDelivery,
//       allowFastDelivery,
//       galleries,
//       allowUpsell,
//       allowCrossSell,
//       allowWholeSell,
//       selected_tags,
//       selected_taxs,
//       attributeoptions,

//     } = this.state;
//     var form_data = new FormData();

//     form_data.append("other_products_info", JSON.stringify(colorDetails));

//     if (selected_primary == null || selected_primary == '') {
//       this.setState({ errorPrimary: true })
//       return false
//     }
//     // if (selected_child == null || selected_child == '') {
//     //   this.setState({ errorChild: true })
//     //   return false
//     // }
//     // if (selected_sceondary == null || selected_sceondary == '') {
//     //   this.setState({ errorSecondary: true })
//     //   return false
//     // }
//     if (vendor == null || vendor == '') {
//       this.setState({ errorvendor: true })
//       return false
//     }
//     // if (selected_tags === null || selected_tags === '') {
//     //   this.setState({ errorTags: true })
//     //   return false
//     // }
//     // if (selected_taxs === null || selected_taxs === '') {
//     //   this.setState({ errorTaxs: true })
//     //   return false
//     // }

//     for (let i = 0; i < galleries.length; i++) {

//       let image = galleries[i].image
//       if (image != null) {
//         form_data.append(`gallery_images[]`, image, image.name)
//       }
//     }

//     if (selected_primary != null) {

//       form_data.append("primary_categories", JSON.stringify(selected_primary));
//       this.setState({ errorPrimary: false })
//     }
//     if (selected_sceondary != null) {
//       form_data.append("secondary_categories", JSON.stringify(selected_sceondary));
//       this.setState({ errorSecondary: false })
//     }
//     if (selected_child != null) {
//       form_data.append("child_categories", JSON.stringify(selected_child));
//       this.setState({ errorChild: false })
//     }
//     if (vendor != null) {
//       form_data.append("vendor", SingleIdSelect(vendor));
//       this.setState({ errorvendor: false })
//     }
//     if (selected_tags != null) {

//       form_data.append("tags", JSON.stringify(selected_tags));

//     }
//     if (selected_taxs != null) {

//       form_data.append("taxs", JSON.stringify(selected_taxs));

//     }
//     form_data.append("product_name", event.product_name ? event.product_name : '');
//     form_data.append("product_sku", event.product_sku ? event.product_sku : '');

//     form_data.append("minimum_purchase_price", event.minimum_purchase_price ? event.minimum_purchase_price : 0);
//     form_data.append("regular_price", event.regular_price ? event.regular_price : 0);
//     form_data.append("discount_price", event.discount_price ? event.discount_price : 0);
//     form_data.append("discount_type", event.discount_type ? event.discount_type : 1);
//     form_data.append("product_unit", event.product_unit ? event.product_unit : '');
//     form_data.append("unit_amount", event.unit_amount ? event.unit_amount : 0);
//     if (event.discount_from_date != '' && event.discount_from_date != undefined) {
//       form_data.append("discount_from_date", dateTime(event.discount_from_date));
//     }
//     if (event.discount_to_date != '' && event.discount_to_date != undefined) {
//       form_data.append("discount_to_date", dateTime(event.discount_to_date));
//     }

//     form_data.append("alert_stock_quantity", event.alert_stock_quantity ? event.alert_stock_quantity : 0);
//     if (allowProductSize) {
//       form_data.append("is_attribute", allowProductSize);
//       form_data.append("other_products_info", JSON.stringify(colorDetails));
//     }
//     form_data.append("is_free_delivery", allowFreeDelivery);
//     form_data.append("is_fast_delivery", allowFastDelivery);
//     form_data.append("is_feature_product", allowRecommendProduct);
//     form_data.append("product_summary", this.state.product_summary.toString('html') ? this.state.product_summary.toString('html') : '');
//     form_data.append("product_details", this.state.product_details.toString('html') ? this.state.product_details.toString('html') : '');
//     form_data.append("brand", event.brand ? event.brand : '');
//     form_data.append("stock_quantity", event.stock_quantity ? event.stock_quantity : 0);

//     if (allowProductSeo) {
//       form_data.append("is_product_seo", allowProductSeo);
//       form_data.append("meta_tag", event.meta_tag ? event.meta_tag : '');
//       form_data.append("meta_description", event.meta_description ? event.meta_description : '');
//     }
//     if (!allowProductSeo) {
//       form_data.append("meta_tag", '');
//       form_data.append("meta_description", '');
//     }
//     if (!allowUpsell) {
//       form_data.append("up_sell_product", '');
//     }
//     if (!allowCrossSell) {
//       form_data.append("cross_sell_product", '');
//     }
//     if (!allowWholeSell) {
//       form_data.append("whole_sell_price", '');
//       form_data.append("minimum_purchase_quantity", '');
//     }


//     form_data.append("youtube_url", event.youtube_url ? event.youtube_url : '');

//     if (allowWholeSell) {
//       form_data.append("is_whole_sell", allowWholeSell);
//       form_data.append("whole_sell_price", event.whole_sell_price ? event.whole_sell_price : 0);
//       form_data.append("minimum_purchase_quantity", event.purchase_quantity ? event.purchase_quantity : 0);
//     }

//     if (allowCrossSell) {
//       var cross_sell_product = []
//       for (var i = 0; i < this.state.crossSellList.length; i++) {

//         cross_sell_product.push(this.state.crossSellList[i].id)
//       }
//       form_data.append("is_cross_sell", allowCrossSell);
//       form_data.append("cross_sell_product", JSON.stringify(cross_sell_product));
//     }
//     if (allowUpsell) {
//       var upsells_product = []
//       for (var i = 0; i < this.state.UpsellList.length; i++) {

//         upsells_product.push(this.state.UpsellList[i].id)
//       }
//       form_data.append("is_up_sell", allowUpsell);
//       form_data.append("up_sell_product", JSON.stringify(upsells_product));
//     }
//     if (allowPreOrder) {
//       form_data.append("is_pre_order", allowPreOrder);
//       form_data.append("pre_order_shipping_estimate", event.pre_order_shipping_estimate ? event.pre_order_shipping_estimate : '');
//       form_data.append("pre_order_policy", event.pre_order_policy ? event.pre_order_policy : '');

//     }
//     if (!allowPreOrder) {

//       form_data.append("pre_order_shipping_estimate", '');
//       form_data.append("pre_order_policy", '');

//     }
//     if (allowShippingTime) {
//       form_data.append("is_estimated_shipping", allowShippingTime);
//       form_data.append("estimated_shipping_time", event.estimated_shipping_time ? event.estimated_shipping_time : '');
//     }
//     if (!allowShippingTime) {

//       form_data.append("estimated_shipping_time", '');
//     }
//     if (featureFile != null) {
//       let newImage = new File([featureFile], featureFile.name, { type: featureFile.type });
//       form_data.append("feature_image", newImage, newImage.name);
//     }
//     let id = this.props.match.params.id;
//     //alert(PRODUCT_URL+id)
//     this.props.updateData(form_data, PRODUCT_URL + id + '/', "Product")
//   }


//   render() {

//     const toolbarConfig = {
//       // Optionally specify the groups to display (displayed in the order listed).
//       display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
//       INLINE_STYLE_BUTTONS: [
//         { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
//         { label: 'Italic', style: 'ITALIC' },
//         { label: 'Underline', style: 'UNDERLINE' }
//       ],
//       BLOCK_TYPE_DROPDOWN: [
//         { label: 'Normal', style: 'unstyled' },
//         { label: 'Heading Large', style: 'header-one' },
//         { label: 'Heading Medium', style: 'header-two' },
//         { label: 'Heading Small', style: 'header-three' }
//       ],
//       BLOCK_TYPE_BUTTONS: [
//         { label: 'UL', style: 'unordered-list-item' },
//         { label: 'OL', style: 'ordered-list-item' }
//       ]
//     };

//     const { handleSubmit,
//       product_units,
//       tags, taxs, pristine, reset, sellers, submitting, primarycategory, childcategory, secondarycategory, sizes, attributes, attribute_options }
//       = this.props;

//     const {
//       allowRecommendProduct,
//       allowProductSeo,
//       colorDetails,
//       allowProductSize,
//       allowShippingTime,
//       allowUpsell,
//       allowCrossSell,
//       allowWholeSell,
//       vendor,
//       vendor_lists,
//       selected_tags,
//       selected_taxs,
//       src,
//       crop,
//       croppedImageUrl,
//       allowPreOrder,
//       gallery_images,
//       selected_primary,
//       selected_sceondary,
//       secondary_categories,
//       selected_child,
//       product_details,
//       product_summary,
//       allowFreeDelivery,
//       allowFastDelivery,
//       errorPrimary,
//       errorSecondary,
//       errorChild,
//       errorvendor,
//       value,
//       crossproduct,
//       totalAmount,
//       totalQuantity,
//       attributeoptions,

//     } = this.state;

//     const inputProps = {
//       placeholder: 'Type Upsells Product Name or Product Sku Here',
//       value,
//       type: "search",
//       onChange: this.onChange
//     };
//     const inputCrossProps = {
//       placeholder: 'Type Cross Sell Product Name or Product Sku Here',
//       value: crossproduct,
//       type: "search",
//       onChange: this.onCrossChange
//     };
//     if (this.props.is_updated) {
//       setTimeout(function () {

//         window.location.href = '/product-list'
//       },
//         3000);
//     }
//     return (


//       <div>
//         <div className="content-header">
//           <div className="container-fluid">
//             <div className="row mb-2">
//               <div className="col-sm-6">
//                 <h1 className="m-0 text-dark">Product Update</h1>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="card">
//           <div className="card-header">
//             <h3 className="card-title">Product Update Form</h3>
//             <div className="float-right">
//               <Link
//                 to="/product-list"
//                 className="btn btn-primary float-right text-white"
//               >
//                 <b>
//                   <i className="fa fa-backward"></i>
//                       Back To List
//                     </b>
//               </Link>
//             </div>
//           </div>
//           <form
//             onSubmit={handleSubmit(this.submit.bind(this))}
//           // onChange={this.handleChangeForm}
//           >
//             <ToastContainer autoClose={3000} />
//             <div className="card-body">
//               <div className="row">
//                 <div className="col-md-6">
//                   <Field
//                     name="product_name"
//                     type="text"
//                     component={renderField}
//                     label="Product Name"
//                     validate={productName}
//                     // warn={alphaNumeric}
//                     required={true}
//                   />
//                 </div>
//                 {/* <div className="col-md-6">
//                   <Field
//                     name="product_sku"
//                     type="text"
//                     component={renderField}
//                     label="Product SKU"
//                     validate={productSKU}
//                     // warn={alphaNumeric}
//                     required={true}
//                   />
//                 </div> */}

//                 <div className="col-md-6">
//                   <label>Primary Category</label>
//                   <span className="requiredField">*</span>
//                   <Select
//                     value={selected_primary}
//                     onChange={this.primaryChange}
//                     required={true}
//                     options={primarycategory}
//                     placeholder="Select Primary Category"
//                     isMulti
//                     isClearable
//                   />{errorPrimary ? <span className="text-danger">Select Primary Category</span> : ''}

//                 </div>
//                 <div className="col-md-6">
//                   <label>Secondary Category</label>
                  
//                   <Select
//                     value={selected_sceondary}
//                     onChange={this.secondaryChange}
//                     placeholder="Secondary Category"
//                     options={secondarycategory}
//                     required={false}
//                     isMulti
//                     isClearable
//                   />
//                 </div>
//                 <div className="col-md-6">
//                   <label>Child Category</label>
                
//                   <Select
//                     value={selected_child}
//                     onChange={this.childChange}
//                     options={childcategory}
//                     placeholder="Child Category"
//                     isMulti
//                     isClearable
//                   />
//                 </div>
//                 <div className="col-md-6">
//                   <label>Merchent / vendor </label>
//                   <span className="requiredField">*</span>
//                   <Select
//                     value={vendor}
//                     onChange={this.vendorChange}
//                     options={sellers}
//                     component={renderField}
//                     placeholder="Merchent / vendor"
//                   />{errorvendor ? <span className="text-danger">Select  Vendor</span> : ''}
//                 </div>
//                 {/* <div className="col-md-6">
//                   <Field
//                     name="minimum_purchase_price"
//                     type="number"
//                     component={renderField}
//                     label="Minimum Purchase Price"
//                     validate={purchasePrice}

//                     required={true}
//                   />
//                 </div> */}
//                 <div className="col-md-6">
//                   <Field
//                     name="regular_price"
//                     type="number"
//                     component={renderField}
//                     label="Product Regular Price"
//                     validate={regularPrice}
//                     // warn={alphaNumeric}
//                     required={true}
//                   />
//                 </div>
//                 <div className="col-md-6">
//                   <label>Discount Type</label>
//                   <span className="requiredField">*</span>
//                   <Field name="discount_type" component="select" className="form-control"
//                   >
//                     <option value="">Select Discount Type</option>
//                     <option value="1">Percentage (%)</option>
//                     <option value="2">Flat Discount (BDT)</option>
//                     <option value="3">No Discount</option>


//                   </Field>
//                 </div>
//                 <div className="col-md-6">
//                   <Field
//                     name="discount_price"
//                     type="number"
//                     component={renderField}
//                     label="Product Discount Price"
//                     warn={alphaNumeric}
//                   />
//                 </div>

//                 <div className="col-md-6">
//                   <Field
//                     showTime={false}
//                     name="discount_from_date"
//                     type="date"
//                     component={renderDateTimePicker}
//                     label="Product Discount From"
//                   />
//                 </div>
//                 <div className="col-md-6">
//                   <Field
//                     showTime={false}
//                     name="discount_to_date"
//                     type="date"
//                     component={renderDateTimePicker}
//                     label="Product Discount To"
//                   />
//                 </div>

//                 <div className="col-md-6">
//                   <Field
//                     name="alert_stock_quantity"
//                     type="number"
//                     component={renderField}
//                     label="Alert Stock Quantity"
//                     warn={alphaNumeric}
//                   // required={true}
//                   />
//                 </div>
//                 <div className="col-md-6">
//                   <Field
//                     name="stock_quantity"
//                     type="number"
//                     component={renderField}
//                     label="Stock Quantity"
//                     warn={alphaNumeric}
//                   // required={true}
//                   />
//                 </div>

//                 <div className="col-md-6">
//                   <label>Product Tags</label>

//                   <Select
//                     value={selected_tags}
//                     onChange={this.onTagsChange}
//                     options={tags}
//                     placeholder="Product Tags"
//                     isMulti
//                     isClearable
//                   />
//                   {/* {this.state.errorTags ? <span className="text-danger">Select Product Tags</span> : ''} */}
//                 </div>
//                 <div className="col-md-6">
//                   <label>Unit</label>

//                   <Field
//                     name="product_unit"
//                     component="select"
//                     className="form-control"

//                   // onChange={this.handleSelectedValues(idx)}
//                   >
//                     <option value="">--Select Unit--</option>

//                     {product_units.map((unit, index) => (
//                       <option key={index} value={unit.id}>{unit.name}</option>

//                     ))}
//                   </Field>
//                 </div>
//                 <div className="col-md-6">
//                   <Field
//                     name="unit_amount"
//                     type="number"
//                     component={renderField}
//                     label="Product Weight (KG)"
//                     warn={alphaNumeric}
//                   // required={true}
//                   />
//                 </div>
//                 <div className="col-md-6">
//                   <label>Product Brand</label>

//                   <Field
//                     name="brand"
//                     component="select"
//                     className="form-control"

//                   // onChange={this.handleSelectedValues(idx)}
//                   >
//                     <option value="">--Select Product Brand--</option>

//                     {this.props.brands.map((brand, index) => (
//                       <option key={index} value={brand.id}>{brand.name}</option>

//                     ))}
//                   </Field>
//                 </div>
//                 <div className="col-md-6">
//                   <label> Tax</label>

//                   <Select
//                     value={selected_taxs}
//                     onChange={this.onTaxsChange}
//                     options={taxs}
//                     placeholder="Tax"
//                     isMulti
//                     isClearable
//                   />
//                   {/* {this.state.errorTaxs ? <span className="text-danger">Select Product Taxs</span> : ''} */}
//                 </div>
//                 <div className="col-md-6">
//                   <Field
//                     name="youtube_url"
//                     type="text"
//                     component={renderField}
//                     label="Product Video URL"
//                     warn={alphaNumeric}
//                   // required={true}
//                   />
//                 </div>
//                 {/* <div class="col-md-6">
//                   <Field
//                     name="product_summary"
//                     type="text"
//                     component={renderTextArea}
//                     label="Product Summary"
//                   // required={true}
//                   />
//                 </div> */}
//                 <div class="col-md-12">
//                   <label>Product Summary</label>
//                   <RichTextEditor
//                     // defaultContentState="test"
//                     //deavalue={product_details}
//                     //  toolbarConfig={toolbarConfig}
//                     value={product_summary}
//                     // setValue="ajdkajkdjak"

//                     onChange={this.onChangeProductSummary}
//                   />
//                 </div>
//                 <div class="col-md-12">
//                   <label>Product Details</label>
//                   <RichTextEditor
//                     // defaultContentState="test"
//                     //deavalue={product_details}
//                     //  toolbarConfig={toolbarConfig}
//                     value={product_details}
//                     // setValue="ajdkajkdjak"

//                     onChange={this.onChangeDetails}
//                   />
//                 </div>

//                 <div className="col-md-12">
//                   <div className="form-check">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="allow-product-color"
//                       checked={allowProductSize ? true : false}
//                       value={allowProductSize}
//                       onChange={this.allowSizeChange}
//                     />
//                     <label className="form-check-label" htmlFor="allow-product-size">
//                       <b>Allow Attributes</b>
//                     </label>
//                   </div>

//                   {allowProductSize ? (
//                     <div className="col-md-12">
//                       <table className="table">
//                         <tr>
//                           <th>Attribute</th>
//                           <th>Attribute Option</th>
//                           <th>Action</th>
//                         </tr>
//                         <tbody>
//                           {colorDetails.map((colors, idx) => (

//                             <tr key={colors.index}>
//                               <td>

//                                 <select
//                                   name="attribute"
//                                   component="select"
//                                   className="form-control"
//                                   data-id={idx}
//                                   id={"size" + idx}
//                                   onChange={this.handleSelectedValues(idx)}
//                                 >
//                                   <option value="">--Select Attribute--</option>
//                                   {attributes.map((attribute, index) => (
//                                     <option key={index} selected={colors.attribute === attribute.id ? true : false} value={attribute.id}>{attribute.name}</option>

//                                   ))}
//                                 </select>
//                               </td>

//                               <td>
//                                 <Select
//                                   value={colors.selected_option}
//                                   onChange={this.attributeOptionChange(idx)}
//                                   required={true}
//                                   options={colors.attribute_options}
//                                   placeholder="Select Attribute Option"
//                                   isMulti
//                                   isClearable
//                                   id={"attributeoption" + idx}
//                                 // onChange={this.handleSelectedValues(idx)}
//                                 />
//                               </td>


//                               {/* <td>
//                                   <div>
//                                     <input
//                                       type="file"
//                                       onChange={this.handleChangeColoImage}
//                                       data-id={idx}
//                                       id={"file" + idx}
//                                       name="file"
//                                     />
//                                     {colors.file ? (
//                                       <img
//                                         src={colors.file}
//                                         height="40px"
//                                         width="40px"
//                                         data-id={idx}
//                                         id={"img" + idx}
//                                       />
//                                     ) : null}
//                                   </div>
//                                 </td> */}
//                               <td>
//                                 {idx === 0 ? (
//                                   <button
//                                     onClick={() => this.addNewRow()}
//                                     type="button"
//                                     className="btn btn-primary text-center"
//                                   >
//                                     <i
//                                       className="fa fa-plus-circle"
//                                       aria-hidden="true"
//                                     />
//                                   </button>
//                                 ) : (
//                                     <button
//                                       className="btn btn-danger"
//                                       onClick={() => this.deteteRow(colors)}
//                                     >
//                                       <i
//                                         className="fa fa-minus"
//                                         aria-hidden="true"
//                                       />
//                                     </button>
//                                   )}
//                               </td>
//                             </tr>
//                           ))}
//                         </tbody>
//                       </table>
//                       {attributeoptions.map((attr_options,optindex)=>(
//                       <div>
//                         <div className="row">  
                      
                       
//                         {colorDetails.length>0 ? 
//                           <>
                         
//                             {colorDetails.map((colors,index)=>(
                              
//                             <>
                           
//                              {colors.selected_option !==null && colors.selected_option.length>0 ?
//                              <>
//                                <div className="col-md-3 mt-2 mb-2">
//                                 <label>Attribute Option</label>
//                                     <select
//                                         name="attribute_option"
//                                         component="select"
//                                         className="form-control"
//                                         data-id={index}
//                                         id={"size1" + index}
//                                        //onChange={this.handleSelectedValues(idx)}
//                                       >
//                                          <option  key={optindex} value={attr_options[index] ? attr_options[index].value :''  }>{attr_options[index] ? attr_options[index].label :'' }</option>

//                                         {/* {
//                                         colors.selected_option.map((option, optindex) => (
//                                           <option selected={attr_options[index] && attr_options[index].value===option.value ? true :false } key={optindex} value={option.value}>{option.label}</option>

//                                         ))} */}
//                                       </select>
//                                   </div>
//                                </>  
//                               :null
//                               }
//                             </>  
//                            ))}     
//                           </>
//                          :null
//                         } 
                       
//                             <div className="col-md-3 mt-2 mb-2">
//                             <label>Product Image</label>
//                             <input
//                               type="file"
//                               onChange={this.handletextChild(optindex)}
//                               data-id={optindex}
//                               id={"file" + optindex}
//                               name="feature_image"
//                           />
//                             </div>
//                             <div className="col-md-3 mt-2 mb-2">  
//                             <label>Product SKU</label>
//                               <input className="form-control"  onChange={this.handletextChild(optindex)} type="text" name="product_sku" placeholder="Product SKU"/>
//                             </div> 
//                             <div className="col-md-3 mt-2 mb-2">
//                             <label>Product Regular Price</label>
//                               <input className="form-control" type="number" onChange={this.handletextChild(optindex)}  name="regular_price" placeholder="Product Regular Price"/>
//                             </div> 
//                             <div className="col-md-3 mt-2 mb-2">
//                             <label>Stock Quantity</label>
//                               <input className="form-control" type="number" onChange={this.handletextChild(optindex)}  name="stock_quantity" placeholder="Stock Quantity"/>
//                             </div> 
//                             <div className="col-md-3 mt-2 mb-2">
//                             <label>Discount Type</label>
//                             {/* <span className="requiredField">*</span> */}
//                             <select name="discount_type" onChange={this.handletextChild(optindex)}  className="form-control"
//                             >
//                               <option value="">Select Discount Type</option>
//                               <option value="1">Percentage (%)</option>
//                               <option value="2">Flat Discount (BDT)</option>
//                               <option value="3">No Discount</option>

//                             </select>
//                           </div>
//                           <div className="col-md-3 mt-2 mb-2">
//                           <label>Discount Amount</label>
//                             <input name="discount_price" type="number" onChange={this.handletextChild(optindex)}  placeholder="Discount Amount" className="form-control"
//                               label="Discount Amount" />
//                           </div>
//                           </div> 
//                       </div>
//                       ))}
//                     </div>
//                   ) : null}
//                 </div>
//                 {/* <div className="col-md-12">
//                   <div className="form-check">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="allow-free-delivery"
//                       checked={allowFreeDelivery ? true : false}
//                       value={allowFreeDelivery}
//                       onChange={this.allowFreeDeliveryChange}
//                     />
//                     <label className="form-check-label" htmlFor="allow-free-delivery">
//                       <strong>Allow Free Delivery</strong>
//                     </label>
//                   </div>
//                 </div> */}
//                 <div className="col-md-12">
//                   <div className="form-check">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="allow-fast-delivery"
//                       checked={allowFastDelivery ? true : false}
//                       value={allowFastDelivery}
//                       onChange={this.allowFastDeliveryChange}
//                     />
//                     <label className="form-check-label" htmlFor="allow-fast-delivery">
//                       <strong> Allow Fast delivery</strong>
//                     </label>
//                   </div>
//                 </div>

//                 <div className="col-md-12">
//                   <div className="form-check">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="recommend-product"
//                       checked={allowRecommendProduct ? true : false}
//                       value={allowRecommendProduct}
//                       onChange={this.allowRecommendChange}
//                     />
//                     <label className="form-check-label" htmlFor="recommend-product">
//                       <strong> Set as Featured Product </strong>
//                     </label>
//                   </div>
//                 </div>
//                 <div className="col-md-12">
//                   <div className="form-check">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="product-seo"
//                       checked={allowProductSeo ? true : false}
//                       value={allowProductSeo}
//                       onChange={this.allowproductSeoChange}
//                     />
//                     <label className="form-check-label" htmlFor="product-seo">
//                       <strong> Allow Product SEO </strong>
//                     </label>
//                     {allowProductSeo ? (
//                       <div className="col-md-12">
//                         <div className="col-md-6">
//                           <Field
//                             name="meta_tag"
//                             type="text"
//                             component={renderField}
//                             label="Meta Tag"
//                           />
//                         </div>
//                         <div className="col-md-6">
//                           <Field
//                             name="meta_description"
//                             type="text"
//                             component={renderTextArea}
//                             label="Meta Description"
//                           />
//                         </div>
//                       </div>
//                     ) : null}
//                   </div>
//                 </div>
//                 <div className="col-md-12">
//                   <div className="form-check">
//                     <input
//                       type="checkbox"
//                       className="form-check-input"
//                       id="product-seo"
//                       checked={allowPreOrder ? true : false}
//                       value={allowPreOrder}
//                       onChange={this.allowPreorderChnage}
//                     />
//                     <label className="form-check-label" htmlFor="product-seo">
//                       <strong> Allow Pre Order </strong>
//                     </label>
//                     {allowPreOrder ? (
//                       <div className="col-md-12">
//                         <div className="col-md-6">
//                           <Field
//                             name="pre_order_shipping_estimate"
//                             type="text"
//                             component={renderField}
//                             label="Pre Order Shipping Estimate"
//                             value="15-45 Days"
//                           />
//                         </div>
//                         <div className="col-md-6">
//                           <Field
//                             name="pre_order_policy"
//                             type="text"
//                             component={renderTextArea}
//                             label="Pre Order Policy"
//                           />
//                         </div>
//                       </div>
//                     ) : null}
//                   </div>
//                   <div className="col-md-12">
//                     < div className="form-check">
//                       <input
//                         type="checkbox"
//                         className="form-check-input"
//                         id="is_whole_sell"
//                         checked={allowWholeSell ? true : false}
//                         value={allowWholeSell}
//                         onChange={this.allowWholeSellChange}
//                       />
//                       <label className="form-check-label" htmlFor="product-seo">
//                         <strong> Allow Whole Sell </strong>
//                       </label>
//                       {allowWholeSell ? (
//                         <div className="col-md-12">
//                           <div className="col-md-6">
//                             <Field
//                               name="whole_sell_price"
//                               type="number"
//                               component={renderField}
//                               label="Whole Sell Price"

//                             />
//                           </div>
//                           <div className="col-md-6">
//                             <Field
//                               name="purchase_quantity"
//                               type="number"
//                               component={renderField}
//                               label="Minimum Purchase Quantity"
//                               warn={alphaNumeric}
//                             // required={true}
//                             />
//                           </div>
//                         </div>
//                       ) : null}
//                     </div>
//                   </div>
//                   <div className="col-md-12">
//                     <div className="form-check">
//                       <input
//                         type="checkbox"
//                         className="form-check-input"
//                         id="is_up_sell"
//                         checked={allowUpsell ? true : false}
//                         value={allowUpsell}
//                         onChange={this.allowUpsellsChnage}
//                       />
//                       <label className="form-check-label" htmlFor="product-seo">
//                         <strong> Allow Up Sells </strong>
//                       </label>
//                       {allowUpsell ? (
//                         <>

//                           <div className="col-md-12">

//                             <label>Type Your Upsells Product</label>
//                             <Autosuggest
//                               suggestions={this.props.upsellProducts ? this.props.upsellProducts : []}
//                               onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
//                               onSuggestionsClearRequested={this.onSuggestionsClearRequested}
//                               getSuggestionValue={this.getSuggestionValue}
//                               renderSuggestion={this.renderSuggestion}
//                               inputProps={inputProps}
//                               onSuggestionSelected={this.onSuggestionSelected}
//                             />
//                           </div>
//                           {this.state.UpsellList.length > 0 ? (
//                             <>
//                               <div className="col-md-12">
//                                 <strong>Upsells Product List</strong>
//                               </div>

//                               <div className="col-md-12">
//                                 <table className="table orderlist">
//                                   <tr>
//                                     <th>Image</th>
//                                     <th>Product Name</th>
//                                     <th>Product Sku</th>
//                                     <th>Vendor/Seller</th>
//                                     <th>Price</th>
//                                     <th>Action</th>
//                                   </tr>
//                                   <tbody>
//                                     {this.state.UpsellList.map((product, index) => (

//                                       <tr id={product.id} key={index}>
//                                         <td>
//                                           <img src={product.feature_image} height="100px" width="100px" alt="No Image" />
//                                         </td>
//                                         <td>
//                                           {product.product_name}
//                                         </td>
//                                         <td>
//                                           {product.product_sku}
//                                         </td>
//                                         <td>
//                                           {product.vendor ? product.vendor.shop_name : ''}
//                                         </td>

//                                         <td>
//                                           {product.regular_price}
//                                         </td>
//                                         <td>
//                                           <button
//                                             className="btn btn-danger" id={product.id}
//                                             onClick={() => this.deteteUpSellsRow(product)}
//                                           >
//                                             <i
//                                               className="fa fa-trash"
//                                               aria-hidden="true"
//                                             />
//                                           </button>

//                                         </td>
//                                       </tr>
//                                     ))}
//                                     {/* <tr>
//                               <th colSpan="3" className="">Total</th>
//                               <th colSpan="4" className="text-center">{totalQuantity}</th>
                            
//                             </tr> */}
//                                   </tbody>
//                                 </table>
//                               </div>
//                             </>
//                           ) : null}
//                         </>


//                       ) : null}
//                     </div>
//                   </div>
//                   <div className="col-md-12">
//                     <div className="form-check">
//                       <input
//                         type="checkbox"
//                         className="form-check-input"
//                         id="is_cross_sell"
//                         checked={allowCrossSell ? true : false}
//                         value={allowCrossSell}
//                         onChange={this.allowCrossSellsChnage}
//                       />
//                       <label className="form-check-label" htmlFor="product-seo">
//                         <strong> Allow Cross sells </strong>
//                       </label>
//                       {allowCrossSell ? (
//                         <>

//                           <div className="col-md-12">

//                             <label>Type Your Cross sells Product</label>
//                             <Autosuggest
//                               suggestions={this.props.upsellProducts ? this.props.upsellProducts : []}
//                               onSuggestionsFetchRequested={this.onCrossSuggestionsFetchRequested}
//                               onSuggestionsClearRequested={this.onCrossSuggestionsClearRequested}
//                               getSuggestionValue={this.getCrossSuggestionValue}
//                               renderSuggestion={this.renderCrossSuggestion}
//                               inputProps={inputCrossProps}
//                               onSuggestionSelected={this.onCrossSuggestionSelected}
//                             />
//                           </div>
//                           {this.state.crossSellList.length > 0 ? (
//                             <>
//                               <div className="col-md-12">
//                                 <strong>Cross Sells Product List</strong>
//                               </div>

//                               <div className="col-md-12">
//                                 <table className="table orderlist">
//                                   <tr>
//                                     <th>Image</th>
//                                     <th>Product Name</th>
//                                     <th>Product Sku</th>
//                                     <th>Vendor/Seller</th>
//                                     <th>Price</th>
//                                     <th>Action</th>
//                                   </tr>
//                                   <tbody>
//                                     {this.state.crossSellList.map((product, index) => (

//                                       <tr id={product.id} key={index}>
//                                         <td>
//                                           <img src={product.feature_image} height="100px" width="100px" alt="No Image" />
//                                         </td>
//                                         <td>
//                                           {product.product_name}
//                                         </td>
//                                         <td>
//                                           {product.product_sku}
//                                         </td>
//                                         <td>
//                                           {product.vendor ? product.vendor.shop_name : ''}
//                                         </td>

//                                         <td>
//                                           {product.regular_price}
//                                         </td>
//                                         <td>
//                                           <button
//                                             className="btn btn-danger" id={product.id}
//                                             onClick={() => this.deteteCrossSellRow(product)}
//                                           >
//                                             <i
//                                               className="fa fa-trash"
//                                               aria-hidden="true"
//                                             />
//                                           </button>

//                                         </td>
//                                       </tr>
//                                     ))}
//                                     {/* <tr>
//                               <th colSpan="3" className="">Total</th>
//                               <th colSpan="4" className="text-center">{totalQuantity}</th>
                            
//                             </tr> */}
//                                   </tbody>
//                                 </table>
//                               </div>
//                             </>
//                           ) : null}
//                         </>


//                       ) : null}
//                     </div>
//                   </div>
//                 </div>


//                 <div className="col-md-12">

//                   <label>Feature Image</label>
//                   <br />
//                   <input
//                     type="file"
//                     accept="image/*"
//                     onChange={this.onSelectFile}
//                   />
//                 </div>
//                 <div className="col-md-6">
//                   {src && (
//                     <ReactCrop
//                       src={src}
//                       crop={crop}
//                       ruleOfThirds
//                       onImageLoaded={this.onImageLoaded}
//                       onComplete={this.onCropComplete}
//                       onChange={this.onCropChange}
//                     />
//                   )}
//                 </div>
//                 <div className="col-md-6">
//                   {croppedImageUrl && (
//                     <img
//                       alt="Crop"
//                       style={{ maxWidth: "100%" }}
//                       src={croppedImageUrl}
//                       height="150px"
//                       width="150px"
//                     />
//                   )}
//                 </div>
//                 <div className="col-md-12">
//                   <strong>Uploaded Feature image</strong>
//                   <img
//                     alt="Uploaded Image"
//                     style={{ maxWidth: "100%" }}
//                     src={this.props.products.feature_image}
//                     height="100px"
//                     width="100px"
//                   />

//                 </div>

//                 <div className="col-md-12">
//                   <label>Gallery Images</label>
//                   <br />

//                   {gallery_images.map((images, idx) => (
//                     <div key={images.index}>
//                       <input
//                         type="file"
//                         onChange={this.handleChangeColoImage}
//                         data-id={idx}
//                         id={"file" + idx}
//                         name="file"
//                       />
//                       { images.image ? (
//                         <img
//                           src={images.image}
//                           height="40px"
//                           width="40px"
//                           data-id={idx}
//                           id={"img" + idx}
//                           alt="No Image"
//                         />
//                       ) : null
//                       }
//                           &nbsp;
//                       <span>
//                         {idx === 0 ? (
//                           <button
//                             onClick={() => this.addGalleryImage()}
//                             type="button"
//                             className="btn btn-primary text-center"
//                           >
//                             <i
//                               className="fa fa-plus-circle"
//                               aria-hidden="true"
//                             />
//                                 Add More Images
//                           </button>
//                         ) : (
//                             <button
//                               className="btn btn-danger"
//                               onClick={() => this.deteteGalleryImage(images)}
//                             >
//                               <i
//                                 className="fa fa-minus-circle"
//                                 aria-hidden="true"
//                               />
//                                 Remove Image
//                             </button>
//                           )}
//                       </span>
//                     </div>

//                   ))}
//                   <div>

//                     {this.state.gallery_uploaded_images ? (
//                       this.state.gallery_uploaded_images.map((gallery_image, idx) => (
//                         <div key={idx} className="col-md-6">
//                           <strong>Uploaded Gallery Images</strong>
//                           <div className="col-md-4">
//                             <img
//                               src={BASE_URL + gallery_image.image}
//                               height="100px"
//                               width="100px"
//                               data-id={idx}
//                               id={"img" + idx}
//                               alt="No Image"
//                             /><span> <button
//                               className="btn btn-danger"
//                               onClick={() => this.deteteUploadGalleryImage(gallery_image)}
//                             >
//                               <i
//                                 className="fa fa-minus-circle"
//                                 aria-hidden="true"
//                               />
//                           Remove Image
//                           </button></span>
//                           </div>
//                         </div>

//                       ))) : null
//                     }
//                   </div>
//                 </div>
//               </div>
//               <div></div>
//             </div>

//             <div className="card-footer">
//               <div>
//                 <button
//                   type="submit"
//                   className="btn btn-success float-right"
//                   disabled={submitting}
//                 >
//                   <i className="fa fa-edit "></i>Update
//                     </button>
//                 {/* <button
//                       type="button"
//                       className="btn btn-danger"
//                       disabled={pristine || submitting}
//                       onClick={reset}
//                     >
//                       Clear
//                     </button> */}
//               </div>
//             </div>
//           </form>
//         </div>
//       </div>
//     );
//   }
// }


// ProductUpdate.propTypes = {
//   addProduct: PropTypes.func.isRequired,
//   deleteGallryImage: PropTypes.func.isRequired,
//   products: PropTypes.array.isRequired,
//   getPrimaryCategoryForSelect: PropTypes.func.isRequired,
//   getChildCategoryForSelect: PropTypes.func.isRequired,
//   getSecondaryCategoryForSelect: PropTypes.func.isRequired,
//   getAttribute: PropTypes.func.isRequired,
//   fetchSellersForSelect: PropTypes.func.isRequired,
//   getAttributeOptionColor: PropTypes.func.isRequired,
//   getAttributeOptionSize: PropTypes.func.isRequired,
//   UpSellSearchProducts: PropTypes.func.isRequired,
//   CrossSellSearchProducts: PropTypes.func.isRequired,
// };

// const mapStateToProps = state => ({
//   products: state.products.product,
//   upsellProducts: state.upsellProducts.upsellProducts,
//   crossSellProducts: state.crossSellProducts.crossSellProducts,
//   is_added: state.products.is_added,
//   is_updated: state.products.is_updated,
//   sizes: state.attribute_options.size,
//   product_units: state.product_units.product_units,
//   primarycategory: state.primarycategory.primary_categories,
//   childcategory: state.childcategory.child_categories,
//   secondarycategory: state.secondarycategory.secondary_categories,
//   attributes: state.attributes.attributes,
//   attribute_options: state.attribute_options.attribute_options,
//   sellers: state.sellers.sellers,
//   tags: state.tags.tags,
//   taxs: state.taxs.taxs,
//   is_getting: state.products.is_getting,
//   brands: state.brands.brands,

//   initialValues: state.products.product,


// });

// ProductUpdate = reduxForm({
//   form: 'ProductUpdateForm',
//   enableReinitialize: true
// }
// )(ProductUpdate);

// export default connect(mapStateToProps, {
//   updateData,
//   getPrimaryCategoryForSelect,
//   getChildCategoryForSelect,
//   getSecondaryCategoryForSelect,
//   getAttribute,
//   getAttributeOption,
//   fetchSellersForSelect,
//   productDetail,
//   deleteGallryImage,
//   getAttributeOptionColor,
//   getAttributeOptionSize,
//   getProductTagsForSelect,
//   fetchBrandsAll,
//   fetchProductUnitALL,
//   getProductTaxsForSelect,
//   UpSellSearchProducts,
//   CrossSellSearchProducts,

// })(ProductUpdate);


import React, { Component } from "react";
import { Field, reduxForm } from "redux-form";
import { ToastContainer } from "react-toastify";
import { PRODUCT_URL } from "../../constants/api";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import RichTextEditor from 'react-rte';
import Autosuggest from 'react-autosuggest';
import { UpSellSearchProducts, CrossSellSearchProducts } from '../../actions/ProductAction';

import { sumTotalAmount, sumTotalQuantity } from '../../constants/CustomMethod'
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { updateData, productDetail, deleteGallryImage } from '../../actions/ProductAction';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPrimaryCategoryForSelect } from "../../actions/PrimaryCategoryAction"
import { getChildCategoryForSelect } from "../../actions/ChildCategoryAction"
import { getSecondaryCategoryForSelect } from "../../actions/SecondaryCategoryAction"
import { getAttribute } from '../../actions/AttributeActions';
import { getAttributeOption, getAttributeOptionColor, getAttributeOptionSize } from '../../actions/AttributeOptionActions';
import { fetchSellersForSelect } from '../../actions/SellerActions';
import { BASE_URL, GET_ATTRIBUTE_OPTION_LIST_URL } from '../../constants/api'
import { getProductTagsForSelect } from '../../actions/TagsActions';
import { fetchBrandsAll } from '../../actions/BrandsActions';
import { fetchProductUnitALL } from '../../actions/ProductUnitAction';
import { getProductTaxsForSelect } from "../../actions/TaxActions"

import DateTimePicker from 'react-widgets/lib/DateTimePicker'



import {
  productName,
  productSKU,
  purchasePrice,
  regularPrice,
  dateTime,
  SingleIdSelect
} from "../../constants/Validate";



const alphaNumeric = (value) =>
  value && /[^a-zA-Z0-9 ]/i.test(value)
    ? "Only alphanumeric characters"
    : undefined;

const renderField = ({
  input,
  label,
  type,
  meta: { touched, error, warning },
  required = false,
}) => {
  const className = `form-control ${error && touched ? "is-invalid" : ""}`;
  return (
    <div className="form-group">
      <label className="text-sm">{label}</label>
      {required ? <span className="requiredField">*</span> : ""}
      <div>
        <input
          {...input}
          placeholder={label}
          type={type}
          className={className}
        />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};

// Text area type
const renderTextArea = ({
  input,
  label,
  meta: { touched, error, warning },
}) => {
  const className = `form-control ${error && touched ? "is-invalid" : ""}`;
  return (
    <div>
      <label className="text-sm">{label}</label>
      <div>
        <textarea
          {...input}
          placeholder={label}
          rows="3"
          cols="40"
          className={className}
        />
        {touched &&
          ((error && <span className="text-danger">{error}</span>) ||
            (warning && <span>{warning}</span>))}
      </div>
    </div>
  );
};


// Date picker
const renderDateTimePicker = ({ label, input: { onChange, value }, showTime }) =>
  <div>
    <label className="text-sm">{label}</label>
    <DateTimePicker
      onChange={onChange}
      format="DD-MM-YYYY hh:mm A"
      time={showTime}
      value={!value ? null : new Date(value)}
      placeholder={label}
    />
  </div>;

// File Input type

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class ProductUpdate extends Component {
  deteteRow = this.deteteRow.bind(this);
  deteteGalleryImage = this.deteteGalleryImage.bind(this);
  handleChangeColoImage = this.handleChangeColoImage.bind(this);

  state = {
    gallery_uploaded_images: [],
    UpsellList: [],
    product_detail: "",
    crossSellList: [],
    crossproduct: '',
    errorPrimary: false,
    errorSecondary: false,
    errorvendor: false,
    errorChild: false,
    attributes_values:[],
    allowProductColor: false,
    allowProductSize: false,
    upsellsproducts: [],
    crosssellsproducts: [],
    allowShippingChange: false,
    allowProductSeo: false,
    allowPriceRange:false,
    price_range:[
      {
      minimum: '',
      maximum: '',
      price: '',
    }
    ],
    allowPreOrder: false,
    allowRecommendProduct: false,
    allowFreeDelivery: false,
    allowFastDelivery: false,
    allowUpsell: false,
    allowCrossSell: false,
    allowWholeSell: false,
    featureFile: null,
    allowMandatoryAdvance:false,
    product_details: RichTextEditor.createEmptyValue(),
    product_summary: RichTextEditor.createEmptyValue(),
    attributeoptions:[],
    value: '',
    totalAmount: 0,
    totalQuantity: 0,
    galleries: [
      { image: null, }
    ],
    src: null,
    crop: {
      unit: "%",
      width: 40,
      aspect: 16/14
    },
    vendor: "",
    selected_primary: null,
    primary: null,
    selected_sceondary: null,
    secondary: null,
    selected_child: null,
    selected_tags: null,
    selected_taxs: null,
    child: null,
    details: '',
    attr_images:[],
    attributeDetails: [
      {
        index: Math.random(),
        selected_option: [],
        attribute: '',
        attributes: [],
        attributes_values:[],
        attribute_options: [],
        // quantity: "",
        // size_price: "",
      },
    ],
    gallery_images: [
      {
        index: Math.random(),
        image: null,
      },
    ],


  };

  static propTypes = {
    onChange: PropTypes.func
  };

  onChangeDetails = (product_details) => {
    this.setState({ product_details });
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChangeDetails(
        product_details.toString('html')
      );
    }
  };

  onChangeProductSummary = (product_summary) => {
    this.setState({ product_summary });
    if (this.props.onChange) {
      // Send the changes up to the parent component as an HTML string.
      // This is here to demonstrate using `.toString()` but in a real app it
      // would be better to avoid generating a string on each change.
      this.props.onChangeDetails(
        product_summary.toString('html')
      );
    }
  };
  handletextChild = index => (e) => {
   // alert("ajh")
    let attribute_list = [...this.state.attributeoptions];
    for (var i =0;i<attribute_list.length;i++){
     
      let attributes = attribute_list[i]
    }
    //let addedItem = child_products[index].findIndex(item=> item[e.target.name])
    let attributes_values = [...this.state.attributes_values];
    attributes_values[index][e.target.name]=e.target.value
    // alert(e.target.name)
    if( e.target.name=='feature_image'){
      // alert("jsdhj")
      attributes_values[index][e.target.name]=e.target.files[0]
    }
    // attributes_values[index]
    // if(addedItem !==-1){
     
    //    var key =  e.target.name;
    //    var data =child_products[index]
       
    //      for (let i=0;i<data.length;i++){
    //             if(data[i][key]!==undefined){
    //               if(data[i][key]==="feature_image"){
    //                 data[i][key]=e.target.files[0]
    //               }else{
    //                 data[i][key]=e.target.value
    //               }
                  
    //             }

        
    //   }
    //   }else{
    //     if(e.target.name==='feature_image'){
    //       child_products[index].push({[e.target.name]:e.target.files[0]})
           
    //     }else{
    //       child_products[index].push({[e.target.name]:e.target.value})
    //     }
       
    //   }
    
     this.setState({attributes_values:attributes_values})
    //  console.log(this.state.attribute_list)
    // let child_product = [...this.state.attributeoptions];
    // alert(JSON.stringify(child_product))
    // child_products[index][e.target.name]=e.target.value
      // this.setState(child_products)
      
    // if (child_products[index][e.target.name] !== undefined){
    //   child_products[index][e.target.name]=e.target.vlaue
     
    //     //child_products[index][e.target.name]=e.target.vlaue
    // }else{
    //      let obj ={
    //       [e.target.name]:e.target.vlaue
    //     }
    //     alert(JSON.stringify(obj)) 
    // }
  
  }

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

    let { UpsellList } = this.state;
    suggestion.quantity = 1
    suggestion.subtotal = Number(suggestion.regular_price) - Number(suggestion.discount_price)
    var index = UpsellList.findIndex(x => x.id == suggestion.id);

    // console.log(suggestion);
    if (index === -1) {
      UpsellList.push(suggestion)
    } else {
      UpsellList[index].quantity = UpsellList[index].quantity + 1;
      UpsellList[index].subtotal += suggestion.regular_price - suggestion.discount_price;
    }
    this.setState({ totalAmount: sumTotalAmount(UpsellList), totalQuantity: sumTotalQuantity(UpsellList) })
  };

  getCombinations(arr, n)
  {
 
      if(n == 1)
      {
          var ret = [];
          for(var i = 0; i < arr.length; i++)
          {
           
            if(arr[i]!==null){

                for(var j = 0; j < arr[i].length; j++)
                {
                    ret.push([arr[i][j]]);
                }

            }  
          }
          return ret;
      }
      else
      {
          var ret = [];
          for(var i = 0; i < arr.length; i++)
          {
              var elem = arr.shift();
              for(var j = 0; j < elem.length; j++)
              {
                  var childperm = this.getCombinations(arr.slice(), n-1);
                  for(var k = 0; k < childperm.length; k++)
                  {
                      
                      ret.push([elem[j]].concat(childperm[k]));
                  }
              }
          }
          return ret;
      }
  }


  // Features Images Add Methods

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };
  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    this.makeClientCrop(crop);
  };
  attributeOptionChange = index => (selected_data) => {
    var attributeDetails = [...this.state.attributeDetails]
    attributeDetails[index]["selected_option"] = selected_data
    this.setState({ attributeDetails })
    var attr_options=[]
    for (let i =0;i<this.state.attributeDetails.length;i++){

      attr_options.push(this.state.attributeDetails[i].selected_option)

    }
    var atr_opt =this.getCombinations(attr_options,attr_options.length)
    var attributes_values = [...this.state.attributes_values]  
    this.setState({ attributeoptions:atr_opt })
    if(attributes_values.length<atr_opt.length){
      var dif_length=atr_opt.length-attributes_values.length
      for (let i =0;i<dif_length;i++){
          let attr={
            'discount_price':'',
            'product_name':'',
            'regular_price':'',
            'discount_type':'',
            'stock_quantity':'',
            'feature_image':''
          }
          attributes_values.push(attr)


      }
      this.setState({ attributes_values:attributes_values })
    }

  
  };
  onTagsChange = (selected_tags) => {
    this.setState({ selected_tags });
  };
  onTaxsChange = (selected_taxs) => {
    this.setState({ selected_taxs });
  };

  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };
  componentDidMount() {
    let id = this.props.match.params.id;

    this.props.productDetail(id)
    this.props.getPrimaryCategoryForSelect()
    this.props.getChildCategoryForSelect()
    this.props.getSecondaryCategoryForSelect()
    this.props.getAttributeOptionSize(1);
    this.props.getAttributeOptionColor(1);
    this.props.fetchSellersForSelect(1);
    this.props.getAttribute()
    this.props.fetchBrandsAll(1);
    this.props.fetchProductUnitALL(1);
    this.props.getProductTagsForSelect("")
    this.props.getProductTaxsForSelect("")
    // alert(JSON.stringify(this.state.product_detail))
    //this.setState({product_details:RichTextEditor.createValueFromString(this.state.product_detail, 'html')})
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      console.log(crop)
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.setState({ featureFile: blob })
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  //For Fesature Images End Functions



  handleChangeColoImage(e) {
    let gallery_images = [...this.state.gallery_images];
    gallery_images[e.target.dataset.id] = {
      ...gallery_images[e.target.dataset.id],
      image: URL.createObjectURL(e.target.files[0]),
    };

    let galleries = [...this.state.galleries]

    galleries[e.target.dataset.id] = {
      image: e.target.files[0]
    };
    this.setState({ gallery_images: gallery_images, galleries: galleries });
  }
  addGalleryImage = (e) => {
    this.setState((prevState) => ({
      gallery_images: [
        ...prevState.gallery_images,
        {
          index: Math.random(),

          file: "",
        },
      ],
    }));
  };


  addNewRowPriceRange = (e) => {
    this.setState((prevState) => ({
      price_range: [
        ...prevState.price_range,
        {
          
          minimum: '',
          maximum: '',
          price: '',
        },
      ],
    }));
  };

  deteteRowPriceRange(record) {
    this.setState({
      price_range: this.state.price_range.filter((r) => r !== record),
    });
  }

  addNewRow = (e) => {

    this.setState((prevState) => ({
      attributeDetails: [
        ...prevState.attributeDetails,
        {
          index: Math.random(),
          attributes: [],
          selected_option: [],
          attribute_options: [],
        },
      ],
    }));
    // this.set
  };

  deteteRow  (record)  {
    // event.preventDefault()
    this.setState({
      attributeDetails: this.state.attributeDetails.filter((r) => r !== record),
    });
  }
  deteteCrossSellRow = (record) => (event) => {
    event.preventDefault()
    this.setState({
      attributeDetails: this.state.crossSellList.filter((r) => r !== record),
    });
  }
  deteteUpSellsRow = (record) => (event) => {
    event.preventDefault()
    this.setState({
      attributeDetails: this.state.UpsellList.filter((r) => r !== record),
    });
  }
  deteteGalleryImage(record) {
    this.setState({
      gallery_images: this.state.gallery_images.filter((r) => r !== record),
    });
  }
  deteteUploadGalleryImage(record) {

    this.setState({
      gallery_uploaded_images: this.state.gallery_uploaded_images.filter((r) => r !== record),
    });
    this.props.deleteGallryImage(record.id)
  }




  allowColorChange = () => {
    this.setState({ allowProductColor: !this.state.allowProductColor });
  };
  allowRecommendChange = () => {
    this.setState({ allowRecommendProduct: !this.state.allowRecommendProduct });
  };
  allowUpsellsChnage = () => {
    this.setState({ allowUpsell: !this.state.allowUpsell });
  };
  allowCrossSellsChnage = () => {
    this.setState({ allowCrossSell: !this.state.allowCrossSell });
  };
  allowWholeSellsChnage = () => {
    this.setState({ allowWholeSell: !this.state.allowWholeSell });
  };
  allowRecommendChange = () => {
    this.setState({ allowRecommendProduct: !this.state.allowRecommendProduct });
  };
  allowMandatoryAdvanceChange = () => {
    this.setState({ allowMandatoryAdvance: !this.state.allowMandatoryAdvance });
  };
  allowFreeDeliveryChange = () => {
    this.setState({ allowFreeDelivery: !this.state.allowFreeDelivery });
  };
  allowFastDeliveryChange = () => {
    this.setState({ allowFastDelivery: !this.state.allowFastDelivery });
  };
  allowRecommendChange = () => {
    this.setState({ allowRecommendProduct: !this.state.allowRecommendProduct });
  };

  allowproductSeoChange = () => {
    this.setState({ allowProductSeo: !this.state.allowProductSeo });
  };
  allowPriceRangeChange = () => {
    this.setState({ allowPriceRange: !this.state.allowPriceRange });
  };
  allowPreorderChnage = () => {
    this.setState({ allowPreOrder: !this.state.allowPreOrder });
  };

  allowWholeSellChange = () => {
    this.setState({ allowWholeSell: !this.state.allowWholeSell });
  };

  allowShippingChange = () => {
    this.setState({ allowShippingTime: !this.state.allowShippingTime });
  };

  allowSizeChange = () => {
    this.setState({ allowProductSize: !this.state.allowProductSize });
  };
  primaryChange = (selected_primary) => {
    this.setState({ selected_primary });
    console.log(selected_primary)
  };
  secondaryChange = (selected_sceondary) => {
    this.setState({ selected_sceondary });
  };
  childChange = (selected_child) => {
    this.setState({ selected_child });
  };
  vendorChange = (vendor) => {
    this.setState({ vendor });
  };
  setColor = (setColor) => {
    this.setState({ setColor });
  };

  getSuggestions = value => {
    // crossSellProducts
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    this.props.UpSellSearchProducts(value);
    // alert(JSON.stringify(this.props.upsellProducts))
    return inputLength === 0 ? [] : this.props.upsellProducts.filter(product =>
      product.product_name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  // Suggestion rerender when user types
  onSuggestionsFetchRequested = ({ value }) => {
    //alert(value)
    this.setState({
      upsellsproducts: this.getSuggestions(value)
    });
  };
  // Trigger suggestions
  getSuggestionValue = product => '';

  // Render Each Option
  renderSuggestion = product => (
    <div>
      {product.product_name}
    </div>
  );
  onSuggestionsClearRequested = () => {
    this.setState({
      upsellsproducts: []
    });
  };
  // OnChange event handler
  onChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      value: newValue
    });
  };


  getCrossSuggestions = value => {
    // crossSellProducts
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    this.props.CrossSellSearchProducts(value);
    //  alert(JSON.stringify(this.props.crossSellProducts))
    return inputLength === 0 ? [] : this.props.crossSellProducts.filter(product =>
      product.product_name.toLowerCase().slice(0, inputLength) === inputValue
    );
  };

  // Suggestion rerender when user types
  onCrossSuggestionsFetchRequested = ({ value }) => {
    //alert(value)
    this.setState({
      crosssellsproducts: this.getCrossSuggestions(value)
    });
  };
  // Trigger suggestions
  getCrossSuggestionValue = product => '';

  // Render Each Option
  renderCrossSuggestion = product => (
    <div>
      {product.product_name}
    </div>
  );
  onCrossSuggestionsClearRequested = () => {
    this.setState({
      crosssellsproducts: []
    });
  };
  // OnChange event handler
  onCrossChange = (event, { newValue }) => {
    //alert(newValue)
    this.setState({
      crossproduct: newValue
    });
  };

  onCrossSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {

    let { crossSellList } = this.state;
    suggestion.quantity = 1
    suggestion.subtotal = Number(suggestion.regular_price) - Number(suggestion.discount_price)
    var index = crossSellList.findIndex(x => x.id == suggestion.id);

    // console.log(suggestion);
    if (index === -1) {
      crossSellList.push(suggestion)
    } else {
      crossSellList[index].quantity = crossSellList[index].quantity + 1;
      crossSellList[index].subtotal += suggestion.regular_price - suggestion.discount_price;
    }
    // alert(JSON.stringify(crossSellList))
    this.setState({ totalAmount: sumTotalAmount(crossSellList), totalQuantity: sumTotalQuantity(crossSellList) })
  };


  handleSelectedValues = index => e => {
    var attributeDetails = [...this.state.attributeDetails]
    //alert(e.target.value)
    let id = e.target.value
    attributeDetails[index]["attribute"] = id;
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },

    };
    attributeDetails[index]["selected_option"] = null

    fetch(GET_ATTRIBUTE_OPTION_LIST_URL + '?attribute__id=' + id, requestOptions)
      .then(async response => {
        const data = await response.json();

        // check for error response
        if (!response.ok) {
          // get error message from body or default to response status
          const error = (data && data.message) || response.status;
          return Promise.reject(error);
        }
        console.log(data.results)
        var results = []
        for (var i = 0; i < data.results.length; i++) {
          results.push({ label: data.results[i].name, value: data.results[i].id })
        }
        attributeDetails[index]["attribute_options"] = results
        this.setState({ attributeDetails })

      })
      .catch(error => {
        this.setState({ errorMessage: error.toString() });
        console.error('There was an error!', error);
      });
  }


  handlePriceRangeValues = index => (e) => {
    // const {price_range} = this.state
    let price_range = [...this.state.price_range]
    price_range[index][e.target.name] = e.target.value;
    this.setState({price_range:price_range})
     
  }


  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.is_getting === true) {
      if (nextProps.is_getting !== prevState.details) {
        if (nextProps.products.length == undefined) {
        var  attributeDetails= [
            {
              index: Math.random(),
              selected_option: [],
              attribute: '',
              attributes: [],
              attributes_values:[],
              attribute_options: [],
              // quantity: "",
              // size_price: "",
            },
          ]

         
         
          var products = nextProps.products
          // alert(JSON.stringify( nextProps.products.product_details_info))
          // for (i = 0; i < products.product_details_info.length; i++) {
          //   var attroption = products.product_details_info[i].attributeoption
          //     for (var j = 0; j < attroption.length; j++) {
          //       // alert(attroption[j].attribute)
          //       attributeoptions.push({ label: attroption[j].attribute, value: attroption[j].id })
          //    }
          // }
          //alert(JSON.stringify(products.childproductdetails))
          //for (i = 0; i < products.childproductdetails.length; i++) {

            //  var attroption = products.childproductdetails[i]
            // alert(JSON.stringi)
            // alert(JSON.stringify(attroption))
            //   // alert(JSON.stringify(attroption.attribute))
            // //   var attributeoptions = []
            // //   for (var j = 0; j < attroption.length; j++) {
            // //     attributeoptions.push({ label: attroption[j].name, value: attroption[j].id })
            // //   }
            // //   attributeDetails.push({ 'attribute': products.product_details_info[i].attribute, 'selected_option': attributeoptions })
          //}

          if (products.primary_categories.length > 0) {
            var primaries = products.primary_categories
            var primarydatas = []
            for (var i = 0; i < primaries.length; i++) {

              primarydatas.push({ label: primaries[i].name, value: primaries[i].id })
            }
          }
          if (products.secondary_categories.length > 0) {
            var secondries = products.secondary_categories
            var secondriesdatas = []
            for (var i = 0; i < secondries.length; i++) {
              secondriesdatas.push({ label: secondries[i].name, value: secondries[i].id })
            }
          }
          if (products.child_categories.length > 0) {
            var childs = products.child_categories
            var childsdatas = []
            for (var i = 0; i < childs.length; i++) {

              childsdatas.push({ label: childs[i].name, value: childs[i].id })
            }
          }
          if (products.tags.length > 0) {
            var tags = products.tags
            var tagsdatas = []
            for (var i = 0; i < tags.length; i++) {
              tagsdatas.push({ label: tags[i].name, value: tags[i].id })
            }
          }
          if (products.taxs.length > 0) {
            var taxs = products.taxs
            var taxsdatas = []
            for (var i = 0; i < taxs.length; i++) {
              taxsdatas.push({ label: taxs[i].tax_class_name, value: taxs[i].id })
            }
          }

          var attributeDetails = []
           var data=[]
          for (i = 0; i < products.product_details_info.length; i++) {
            var attroption = products.product_details_info[i].attributeoption
            var attributeoptions = []
            for (var j = 0; j < attroption.length; j++) {
              attributeoptions.push({ label: attroption[j].name, value: attroption[j].id })
            }
            data.push(attributeoptions)
         
            
            attributeDetails.push({ 'attribute': products.product_details_info[i].attribute, 'selected_option': attributeoptions })
          }
          if(attributeDetails.length==0){
             attributeDetails.push(
              {
                index: Math.random(),
                selected_option: [],
                attribute: '',
                attributes: [],
                attributes_values:[],
                attribute_options: [],
                // quantity: "",
                // size_price: "",
              }
             )
  
          }
         
           var product =new ProductUpdate()
          var combination =product.getCombinations(data,data.length)

          attributeDetails["attributes_values"]=products.childproductdetails
          // alert(JSON.stringify(combination))
          var attributes_values=products.childproductdetails
          // alert(JSON.stringify(attributeDetails[2]))
          // alert(JSON.stringify(nextProps.products.price_range))
          return {
        
            vendor: products.vendor ? { value: products.vendor.id, label: products.vendor.shop_name } : null,
            details: true,
            attributeoptions:combination,
            allowProductSize:products.is_attribue,
            selected_primary: primarydatas!==undefined && primarydatas!==null ? primarydatas.reverse() :'',
            selected_sceondary: secondriesdatas!==undefined && secondriesdatas!==null ? secondriesdatas.reverse() :'',
            selected_child: childsdatas!==undefined && childsdatas!==null ? childsdatas.reverse() :'',
            selected_tags: tagsdatas !==undefined && tagsdatas !==null ? tagsdatas.reverse():"",
            selected_taxs: taxsdatas !==undefined && taxsdatas !==null ? taxsdatas.reverse():"",
            attributeDetails: attributeDetails,
            attributes_values:attributes_values,
            allowProductSeo: products.is_product_seo,
            allowUpsell: products.is_up_sell,
            allowCrossSell: products.is_cross_sell,
            allowWholeSell: products.is_whole_sell,
            allowPreOrder: products.is_pre_order,
            allowFastDelivery: products.is_fast_delivery,
            allowFreeDelivery: products.is_free_delivery,
            product_summary:RichTextEditor.createValueFromString(products.product_summary,'html'),
            product_details: RichTextEditor.createValueFromString(products.product_details, 'html'),
            product_details_info: products.product_details_info,
            UpsellList: products.up_sell_product !== null && products.up_sell_product !== '' ? products.up_sell_product : [],
            crossSellList: products.cross_sell_product !== null && products.cross_sell_product !== '' ? products.cross_sell_product : [],
            price_range: products.price_range,

            // allowShippingTime: products.is_estimated_shipping,
            allowRecommendProduct: products.is_feature_product,
            allowPriceRange: products.is_price_range,
            allowMandatoryAdvance:products.is_mandatory_advance_payment,
            gallery_uploaded_images: products.gallery_images,

          }

        }
      }
      return null
    }
    return null

  }

  submit(event) {
    const {
      allowRecommendProduct,
      allowProductSeo,
      allowPriceRange,
      attributeDetails,
      allowProductSize,
      allowShippingTime,
      vendor,
      attributes_values,
      featureFile,
      allowPreOrder,
      gallery_images,
      selected_primary,
      selected_sceondary,
      selected_child,
      allowFreeDelivery,
      allowFastDelivery,
      galleries,
      allowUpsell,
      allowCrossSell,
      allowWholeSell,
      selected_tags,
      selected_taxs,
      attributeoptions,
      allowMandatoryAdvance,
      price_range
    } = this.state;
    var form_data = new FormData();

    form_data.append("other_products_info", JSON.stringify(attributeDetails));
    var child_attr=[]
    if(attributes_values.length>0){
      for (var i =0;i<attributeoptions.length;i++){
        let attr=[]
          for (let j=0;j<attributeoptions[i].length;j++){
          
            attr.push(attributeoptions[i][j].value)
          }
        
          if (attr !==null)
            var attr_options= Object.assign(attributes_values[i], {'attribute_options':attr});
            child_attr.push(attr_options)
            if(attributes_values[i].feature_image!=undefined && attributes_values[i].feature_image!=''){
             if(typeof(attributes_values[i].feature_image)!=='string'){
              form_data.append(`attr_image`+i, attributes_values[i].feature_image, attributes_values[i].feature_image.name);
             }
            
            }
      }
   
      
      
  }

     
   
    form_data.append("child_product_info", JSON.stringify(child_attr));
  

    // if (selected_primary == null || selected_primary == '') {
    //   this.setState({ errorPrimary: true })
    //   return false
    // }
    // if (selected_child == null || selected_child == '') {
    //   this.setState({ errorChild: true })
    //   return false
    // }
    // if (selected_sceondary == null || selected_sceondary == '') {
    //   this.setState({ errorSecondary: true })
    //   return false
    // }
    // if (vendor == null || vendor == '') {
    //   this.setState({ errorvendor: true })
    //   return false
    // }
    // if (selected_tags === null || selected_tags === '') {
    //   this.setState({ errorTags: true })
    //   return false
    // }
    // if (selected_taxs === null || selected_taxs === '') {
    //   this.setState({ errorTaxs: true })
    //   return false
    // }

    for (let i = 0; i < galleries.length; i++) {

      let image = galleries[i].image
      if (image != null) {
        form_data.append(`gallery_images[]`, image, image.name)
      }
    }

    if (selected_primary != null) {

      form_data.append("primary_categories", JSON.stringify(selected_primary));
      this.setState({ errorPrimary: false })
    }
    if (selected_sceondary != null) {
      form_data.append("secondary_categories", JSON.stringify(selected_sceondary));
      this.setState({ errorSecondary: false })
    }
    if (selected_child != null) {
      form_data.append("child_categories", JSON.stringify(selected_child));
      this.setState({ errorChild: false })
    }
    if (vendor != null) {
      form_data.append("vendor", SingleIdSelect(vendor));
      this.setState({ errorvendor: false })
    }
    if (selected_tags != null) {

      form_data.append("tags", JSON.stringify(selected_tags));

    }
    if (selected_taxs != null) {

      form_data.append("taxs", JSON.stringify(selected_taxs));

    }
    
    form_data.append("product_name", event.product_name ? event.product_name : '');
    form_data.append("product_sku", event.product_sku ? event.product_sku : '');
    
    form_data.append("minimum_purchase_price", event.minimum_purchase_price ? event.minimum_purchase_price : 0);
    form_data.append("regular_price", event.regular_price ? event.regular_price : 0);
    form_data.append("discount_price", event.discount_price ? event.discount_price : 0);
    form_data.append("discount_type", event.discount_type ? event.discount_type : 3);
    form_data.append("product_unit", event.product_unit ? event.product_unit : '');
    form_data.append("unit_amount", event.unit_amount ? event.unit_amount : 0);
    if (event.discount_from_date != '' && event.discount_from_date != undefined) {
      form_data.append("discount_from_date", dateTime(event.discount_from_date));
    }
    if (event.discount_to_date != '' && event.discount_to_date != undefined) {
      form_data.append("discount_to_date", dateTime(event.discount_to_date));
    }
    
    form_data.append("alert_stock_quantity", event.alert_stock_quantity ? event.alert_stock_quantity : 0);
    if (allowProductSize) {
      form_data.append("is_attribute", allowProductSize);
      form_data.append("other_products_info", JSON.stringify(attributeDetails));
    }
    form_data.append("is_free_delivery", allowFreeDelivery);
    form_data.append("is_fast_delivery", allowFastDelivery);
    form_data.append("is_feature_product", allowRecommendProduct);
    form_data.append("is_mandatory_advance_payment", allowMandatoryAdvance);
    form_data.append("product_summary", this.state.product_summary.toString('html') ? this.state.product_summary.toString('html') : '');
    form_data.append("product_details", this.state.product_details.toString('html') ? this.state.product_details.toString('html') : '');
    form_data.append("brand", event.brand ? event.brand : '');
    form_data.append("stock_quantity", event.stock_quantity ? event.stock_quantity : 0);

    if (allowProductSeo) {
      form_data.append("is_product_seo", allowProductSeo);
      form_data.append("meta_tag", event.meta_tag ? event.meta_tag : '');
      form_data.append("meta_description", event.meta_description ? event.meta_description : '');
    }
    if (!allowProductSeo) {
      form_data.append("meta_tag", '');
      form_data.append("meta_description", '');
    }
    if (allowPriceRange) {

      form_data.append("is_price_range", allowPriceRange);
      form_data.append("price_range", JSON.stringify(price_range));
    }
    if (!allowPriceRange) {
      form_data.append("price_range", '');
    }
    if (!allowUpsell) {
      form_data.append("up_sell_product", '');
    }
    if (!allowCrossSell) {
      form_data.append("cross_sell_product", '');
    }
    if (!allowWholeSell) {
      form_data.append("whole_sell_price", '');
      form_data.append("minimum_purchase_quantity", '');
    }


    form_data.append("youtube_url", event.youtube_url ? event.youtube_url : '');

    if (allowWholeSell) {
      form_data.append("is_whole_sell", allowWholeSell);
      form_data.append("whole_sell_price", event.whole_sell_price ? event.whole_sell_price : 0);
      form_data.append("minimum_purchase_quantity", event.purchase_quantity ? event.purchase_quantity : 0);
    }

    if (allowCrossSell) {
      var cross_sell_product = []
      for (var i = 0; i < this.state.crossSellList.length; i++) {

        cross_sell_product.push(this.state.crossSellList[i].id)
      }
      form_data.append("is_cross_sell", allowCrossSell);
      form_data.append("cross_sell_product", JSON.stringify(cross_sell_product));
    }
    if (allowUpsell) {
      var upsells_product = []
      for (var i = 0; i < this.state.UpsellList.length; i++) {

        upsells_product.push(this.state.UpsellList[i].id)
      }
      form_data.append("is_up_sell", allowUpsell);
      form_data.append("up_sell_product", JSON.stringify(upsells_product));
    }
    if (allowPreOrder) {
      form_data.append("is_pre_order", allowPreOrder);
      form_data.append("pre_order_shipping_estimate", event.pre_order_shipping_estimate ? event.pre_order_shipping_estimate : '');
      form_data.append("pre_order_policy", event.pre_order_policy ? event.pre_order_policy : '');

    }
    if (!allowPreOrder) {

      form_data.append("pre_order_shipping_estimate", '');
      form_data.append("pre_order_policy", '');

    }
    if (allowShippingTime) {
      form_data.append("is_estimated_shipping", allowShippingTime);
      form_data.append("estimated_shipping_time", event.estimated_shipping_time ? event.estimated_shipping_time : '');
    }
    if (!allowShippingTime) {

      form_data.append("estimated_shipping_time", '');
    }
    if (featureFile != null) {
      let newImage = new File([featureFile], featureFile.name, { type: featureFile.type });
      form_data.append("feature_image", newImage, newImage.name);
    }
    let id = this.props.match.params.id;
    //alert(PRODUCT_URL+id)
   this.props.updateData(form_data, PRODUCT_URL + id + '/', "Product")
  }


  render() {

    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
      INLINE_STYLE_BUTTONS: [
        { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
        { label: 'Italic', style: 'ITALIC' },
        { label: 'Underline', style: 'UNDERLINE' }
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: 'Normal', style: 'unstyled' },
        { label: 'Heading Large', style: 'header-one' },
        { label: 'Heading Medium', style: 'header-two' },
        { label: 'Heading Small', style: 'header-three' }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: 'UL', style: 'unordered-list-item' },
        { label: 'OL', style: 'ordered-list-item' }
      ]
    };

    const { handleSubmit,
      product_units,
      tags, taxs, pristine, reset, sellers, submitting, primarycategory, childcategory, secondarycategory, sizes, attributes, attribute_options }
      = this.props;

    const {
      allowRecommendProduct,
      allowProductSeo,
      allowPriceRange,
      attributeDetails,
      allowProductSize,
      allowShippingTime,
      allowUpsell,
      allowCrossSell,
      allowWholeSell,
      vendor,
      attributes_values,
      vendor_lists,
      selected_tags,
      selected_taxs,
      src,
      crop,
      croppedImageUrl,
      allowPreOrder,
      gallery_images,
      selected_primary,
      selected_sceondary,
      secondary_categories,
      selected_child,
      product_details,
      product_summary,
      allowFreeDelivery,
      allowFastDelivery,
      errorPrimary,
      errorSecondary,
      errorChild,
      errorvendor,
      value,
      crossproduct,
      totalAmount,
      totalQuantity,
      attributeoptions,
      allowMandatoryAdvance,
      price_range

    } = this.state;

    const inputProps = {
      placeholder: 'Type Upsells Product Name or Product Sku Here',
      value,
      type: "search",
      onChange: this.onChange
    };
    const inputCrossProps = {
      placeholder: 'Type Cross Sell Product Name or Product Sku Here',
      value: crossproduct,
      type: "search",
      onChange: this.onCrossChange
    };
    if (this.props.is_updated) {
      setTimeout(function () {

        window.location.href = '/product-list'
      },
        3000);
    }
    return (


      <div>
        <div className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="m-0 text-dark">Product Update</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-header">
            <h3 className="card-title">Product Update Form</h3>
            <div className="float-right">
              <Link
                to="/product-list"
                className="btn btn-primary float-right text-white"
              >
                <b>
                  <i className="fa fa-backward"></i>
                      Back To List
                    </b>
              </Link>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(this.submit.bind(this))}
          // onChange={this.handleChangeForm}
          >
            <ToastContainer autoClose={3000} />
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Field
                    name="product_name"
                    type="text"
                    component={renderField}
                    label="Product Name"
                    validate={productName}
                    // warn={alphaNumeric}
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="product_sku"
                    type="text"
                    component={renderField}
                    label="Product SKU"
                    validate={productSKU}
                    // warn={alphaNumeric}
                    required={true}
                  />
                </div>

                {/* <div className="col-md-6">
                  <label>Primary Category</label>
                  <span className="requiredField">*</span>
                  <Select
                    value={selected_primary}
                    onChange={this.primaryChange}
                    required={true}
                    options={primarycategory}
                    placeholder="Select Primary Category"
                    isMulti
                    isClearable
                  />{errorPrimary ? <span className="text-danger">Select Primary Category</span> : ''}

                </div>
                <div className="col-md-6">
                  <label>Secondary Category</label>
                  
                  <Select
                    value={selected_sceondary}
                    onChange={this.secondaryChange}
                    placeholder="Secondary Category"
                    options={secondarycategory}
                    required={false}
                    isMulti
                    isClearable
                  />
                </div>
                <div className="col-md-6">
                  <label>Child Category</label>
                
                  <Select
                    value={selected_child}
                    onChange={this.childChange}
                    options={childcategory}
                    placeholder="Child Category"
                    isMulti
                    isClearable
                  />
                </div> */}
                <div className="col-md-6">
                  <label>Merchent / vendor </label>
                  <span className="requiredField">*</span>
                  <Select
                    value={vendor}
                    onChange={this.vendorChange}
                    options={sellers}
                    component={renderField}
                    placeholder="Merchent / vendor"
                  />{errorvendor ? <span className="text-danger">Select  Vendor</span> : ''}
                </div>
                {/* <div className="col-md-6">
                  <Field
                    name="minimum_purchase_price"
                    type="number"
                    component={renderField}
                    label="Minimum Purchase Price"
                    validate={purchasePrice}

                    required={true}
                  />
                </div> */}
                <div className="col-md-6">
                  <Field
                    name="regular_price"
                    type="number"
                    component={renderField}
                    label="Product Regular Price"
                    validate={regularPrice}
                    // warn={alphaNumeric}
                    required={true}
                  />
                </div>
                <div className="col-md-6">
                  <label>Discount Type</label>
                  <span className="requiredField">*</span>
                  <Field name="discount_type" component="select" className="form-control"
                  >
                    <option value="">Select Discount Type</option>
                    <option value="1">Percentage (%)</option>
                    <option value="2">Flat Discount (BDT)</option>
                    <option value="3">No Discount</option>


                  </Field>
                </div>
                <div className="col-md-6">
                  <Field
                    name="discount_price"
                    type="number"
                    component={renderField}
                    label="Product Discount Price"
                    warn={alphaNumeric}
                  />
                </div>

                <div className="col-md-6">
                  <Field
                    showTime={false}
                    name="discount_from_date"
                    type="date"
                    component={renderDateTimePicker}
                    label="Product Discount From"
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    showTime={false}
                    name="discount_to_date"
                    type="date"
                    component={renderDateTimePicker}
                    label="Product Discount To"
                  />
                </div>

                <div className="col-md-6">
                  <Field
                    name="alert_stock_quantity"
                    type="number"
                    component={renderField}
                    label="Alert Stock Quantity"
                    warn={alphaNumeric}
                  // required={true}
                  />
                </div>
                <div className="col-md-6">
                  <Field
                    name="stock_quantity"
                    type="number"
                    component={renderField}
                    label="Stock Quantity"
                    warn={alphaNumeric}
                  // required={true}
                  />
                </div>

                <div className="col-md-6">
                  <label>Product Tags</label>

                  <Select
                    value={selected_tags}
                    onChange={this.onTagsChange}
                    options={tags}
                    placeholder="Product Tags"
                    isMulti
                    isClearable
                  />
                  {/* {this.state.errorTags ? <span className="text-danger">Select Product Tags</span> : ''} */}
                </div>
                <div className="col-md-6">
                  <label>Unit</label>

                  <Field
                    name="product_unit"
                    component="select"
                    className="form-control"

                  // onChange={this.handleSelectedValues(idx)}
                  >
                    <option value="">--Select Unit--</option>

                    {product_units.map((unit, index) => (
                      <option key={index} value={unit.id}>{unit.name}</option>

                    ))}
                  </Field>
                </div>
                <div className="col-md-6">
                  <Field
                    name="unit_amount"
                    type="number"
                    component={renderField}
                    label="Product Weight (KG)"
                    warn={alphaNumeric}
                  // required={true}
                  />
                </div>
                <div className="col-md-6">
                  <label>Product Brand</label>

                  <Field
                    name="brand"
                    component="select"
                    className="form-control"

                  // onChange={this.handleSelectedValues(idx)}
                  >
                    <option value="">--Select Product Brand--</option>

                    {this.props.brands.map((brand, index) => (
                      <option key={index} value={brand.id}>{brand.name}</option>

                    ))}
                  </Field>
                </div>
                <div className="col-md-6">
                  <label> Tax</label>

                  <Select
                    value={selected_taxs}
                    onChange={this.onTaxsChange}
                    options={taxs}
                    placeholder="Tax"
                    isMulti
                    isClearable
                  />
                  {/* {this.state.errorTaxs ? <span className="text-danger">Select Product Taxs</span> : ''} */}
                </div>
                <div className="col-md-6">
                  <Field
                    name="youtube_url"
                    type="text"
                    component={renderField}
                    label="Product Video URL(https://www.youtube.com/embed/RnEE8X60ug8)"
                    warn={alphaNumeric}
                  // required={true}
                  />
                </div>
                {/* <div class="col-md-6">
                  <Field
                    name="product_summary"
                    type="text"
                    component={renderTextArea}
                    label="Product Summary"
                  // required={true}
                  />
                </div> */}
                <div class="col-md-12">
                  <label>Product Summary</label>
                  <RichTextEditor
                    // defaultContentState="test"
                    //deavalue={product_details}
                    //  toolbarConfig={toolbarConfig}
                    value={product_summary}
                    // setValue="ajdkajkdjak"

                    onChange={this.onChangeProductSummary}
                  />
                </div>
                <div class="col-md-12">
                  <label>Product Details</label>
                  <RichTextEditor
                    // defaultContentState="test"
                    //deavalue={product_details}
                    //  toolbarConfig={toolbarConfig}
                    value={product_details}
                    // setValue="ajdkajkdjak"

                    onChange={this.onChangeDetails}
                  />
                </div>

                <div className="col-md-12">
                  {/* <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="allow-product-color"
                      checked={allowProductSize ? true : false}
                      value={allowProductSize}
                      onChange={this.allowSizeChange}
                    />
                    <label className="form-check-label" htmlFor="allow-product-size">
                      <b>Allow Attributes</b>
                    </label>
                  </div> */}

                  {allowProductSize ? (
                    <div className="col-md-12">
                      <table className="table">
                        <tr>
                          <th>Attribute</th>
                          <th>Attribute Option</th>
                          <th>Action</th>
                        </tr>
                        <tbody>
                          {attributeDetails.map((colors, idx) => (

                            <tr key={colors.index}>
                              <td>

                                <select
                                  name="attribute"
                                  component="select"
                                  className="form-control"
                                  data-id={idx}
                                  id={"size" + idx}
                                  onChange={this.handleSelectedValues(idx)}
                                >
                                  <option value="">--Select Attribute--</option>
                                  {attributes.map((attribute, index) => (
                                    <option key={index} selected={colors.attribute === attribute.id ? true : false} value={attribute.id}>{attribute.name}</option>

                                  ))}
                                </select>
                              </td>

                              <td>
                                <Select
                                  value={colors.selected_option}
                                  onChange={this.attributeOptionChange(idx)}
                                  required={true}
                                  options={colors.attribute_options}
                                  placeholder="Select Attribute Option"
                                  isMulti
                                  isClearable
                                  id={"attributeoption" + idx}
                                // onChange={this.handleSelectedValues(idx)}
                                />
                              </td>


                              {/* <td>
                                  <div>
                                    <input
                                      type="file"
                                      onChange={this.handleChangeColoImage}
                                      data-id={idx}
                                      id={"file" + idx}
                                      name="file"
                                    />
                                    {colors.file ? (
                                      <img
                                        src={colors.file}
                                        height="40px"
                                        width="40px"
                                        data-id={idx}
                                        id={"img" + idx}
                                      />
                                    ) : null}
                                  </div>
                                </td> */}
                              <td>
                                {idx === 0 ? (
                                  <button
                                    onClick={() => this.addNewRow()}
                                    type="button"
                                    className="btn btn-primary text-center"
                                  >
                                    <i
                                      className="fa fa-plus-circle"
                                      aria-hidden="true"
                                    />
                                  </button>
                                ) : (
                                    <button
                                      className="btn btn-danger"
                                      onClick={() => this.deteteRow(colors)}
                                    >
                                      <i
                                        className="fa fa-minus"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      {attributeoptions.map((attr_options,optindex)=>(
                      <div>
                        <div className="row">  
                      
                       
                        {attributeDetails.length>0 && attributes_values.length>0? 
                          <>
                         
                            {attributeDetails.map((colors,index)=>(
                              
                            <>
                           
                             {colors.selected_option !==null && colors.selected_option.length>0 ?
                             <>
                               <div className="col-md-3 mt-2 mb-2">
                                <label>Attribute Option</label>
                                    <select
                                        name="attribute_option"
                                        component="select"
                                        className="form-control"
                                        data-id={index}
                                        id={"size1" + index}
                                       //onChange={this.handleSelectedValues(idx)}
                                      >
                                         <option  key={optindex} value={attr_options[index] ? attr_options[index].value :''  }>{attr_options[index] ? attr_options[index].label :'' }</option>

                                        {/* {
                                        colors.selected_option.map((option, optindex) => (
                                          <option selected={attr_options[index] && attr_options[index].value===option.value ? true :false } key={optindex} value={option.value}>{option.label}</option>

                                        ))} */}
                                      </select>
                                  </div>
                               </>  
                              :null
                              }
                            </>  
                           ))}     
                          </>
                         :null
                        } 
                        {attributes_values.length>0 ?
                          <>
                            <div className="col-md-3 mt-2 mb-2">
                            <label>Product Image</label>
                            <input
                              type="file"
                              onChange={this.handletextChild(optindex)}
                              data-id={optindex}
                              id={"file" + optindex}
                              name="feature_image"
                          />
                            </div> 
                            <div className="col-md-3 mt-2 mb-2">  
                            <label>Product SKU</label>
                              <input className="form-control"   onChange={this.handletextChild(optindex)} value={attributes_values[optindex].product_sku} type="text" name="product_sku" placeholder="Product SKU"/>
                            </div> 
                            <div className="col-md-3 mt-2 mb-2">
                            <label>Product Regular Price</label>
                              <input className="form-control" type="number" value={attributes_values[optindex].regular_price}   onChange={this.handletextChild(optindex)}  name="regular_price" placeholder="Product Regular Price"/>
                            </div> 
                            <div className="col-md-3 mt-2 mb-2">
                            <label>Stock Quantity</label>
                              <input className="form-control" type="number" value={attributes_values[optindex].stock_quantity} onChange={this.handletextChild(optindex)}  name="stock_quantity" placeholder="Stock Quantity"/>
                            </div> 
                            <div className="col-md-3 mt-2 mb-2">
                            <label>Discount Type</label>
                            {/* <span className="requiredField">*</span> */}
                            <select name="discount_type"  onChange={this.handletextChild(optindex)}  className="form-control"
                            >
                              <option value=""  selected={attributes_values[optindex].discount_type===''? true :false}>Select Discount Type</option>
                              <option value="1" selected={attributes_values[optindex].discount_type===1 ? true :false}>Percentage (%)</option>
                              <option value="2" selected={attributes_values[optindex].discount_type===2 ? true :false}>Flat Discount (BDT)</option>
                              <option value="3" selected={attributes_values[optindex].discount_type===3 ? true :false}>No Discount</option>

                            </select>
                          </div>
                          <div className="col-md-3 mt-2 mb-2">
                          <label>Discount Amount</label>
                            <input name="discount_price" type="number" value={attributes_values[optindex].discount_price} onChange={this.handletextChild(optindex)}  placeholder="Discount Amount" className="form-control"
                              label="Discount Amount" />
                          </div>
                          </>
                          :null
                          }
                          </div> 
                      </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                {/* <div className="col-md-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="allow-free-delivery"
                      checked={allowFreeDelivery ? true : false}
                      value={allowFreeDelivery}
                      onChange={this.allowFreeDeliveryChange}
                    />
                    <label className="form-check-label" htmlFor="allow-free-delivery">
                      <strong>Allow Free Delivery</strong>
                    </label>
                  </div>
                </div> */}
                {/* <div className="col-md-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="allow-fast-delivery"
                      checked={allowFastDelivery ? true : false}
                      value={allowFastDelivery}
                      onChange={this.allowFastDeliveryChange}
                    />
                    <label className="form-check-label" htmlFor="allow-fast-delivery">
                      <strong> Allow Fast delivery</strong>
                    </label>
                  </div>
                </div> */}

                <div className="col-md-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="price-range"
                      checked={allowPriceRange ? true : false}
                      value={allowPriceRange}
                      onChange={this.allowPriceRangeChange}
                    />
                    <label className="form-check-label" htmlFor="price-range">
                      <strong> Allow Price Range </strong>
                    </label>
                    {allowPriceRange>0 ? (
                      <table className="table">
                      <tr>
                        <th>Minimum</th>
                        <th>Maximum</th>
                        <th>Price</th>

                        <th>Action</th>
                      </tr>
                      <tbody>
                        {this.state.price_range !==null && this.state.price_range.map((colors, idx) => (
                          <tr key={colors.index}>
                            <td>
                              <input
                                name="minimum"
                                type="number"
                                placeholder="Minimum"
                                data-id={idx}
                                className="form-control"
                                id={"minimum" + idx}
                                onChange={this.handlePriceRangeValues(idx)}
                                value={this.state.price_range[idx].minimum}
                                required
                              />
                            </td>
                            <td>
                              <input
                                name="maximum"
                                type="number"
                                placeholder="Maximum"
                                data-id={idx}
                                className="form-control"
                                id={"maximum" + idx}
                                onChange={this.handlePriceRangeValues(idx)}
                                value={this.state.price_range[idx].maximum}
                                required
                              />
                            </td>
                            <td>
                              <input
                                name="price"
                                type="number"
                                placeholder="Price"
                                data-id={idx}
                                className="form-control"
                                id={"price" + idx}
                                onChange={this.handlePriceRangeValues(idx)}
                                value={this.state.price_range[idx].price}
                                required
                              />
                            </td>
                            
                            <td>
                              {idx === 0 ? (
                                <button
                                  onClick={() => this.addNewRowPriceRange()}
                                  type="button"
                                  className="btn btn-primary text-center"
                                >
                                  <i
                                    className="fa fa-plus-circle"
                                    aria-hidden="true"
                                  />
                                </button>
                               ) : (
                                <button
                                  className="btn btn-danger"
                                  onClick={() => this.deteteRowPriceRange(colors)}
                                >
                                  <i
                                    className="fa fa-minus"
                                    aria-hidden="true"
                                  />
                                </button>
                              )} 
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="recommend-product"
                      checked={allowRecommendProduct ? true : false}
                      value={allowRecommendProduct}
                      onChange={this.allowRecommendChange}
                    />
                    <label className="form-check-label" htmlFor="recommend-product">
                      <strong> Set as Popular Product </strong>
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="product-seo"
                      checked={allowProductSeo ? true : false}
                      value={allowProductSeo}
                      onChange={this.allowproductSeoChange}
                    />
                    <label className="form-check-label" htmlFor="product-seo">
                      <strong> Allow Product SEO </strong>
                    </label>
                    {allowProductSeo ? (
                      <div className="col-md-12">
                        <div className="col-md-6">
                          <Field
                            name="meta_tag"
                            type="text"
                            component={renderField}
                            label="Meta Tag"
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="meta_description"
                            type="text"
                            component={renderTextArea}
                            label="Meta Description"
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="allow-advance-mandatory-payment"
                      checked={allowMandatoryAdvance ? true : false}
                      value={allowMandatoryAdvance}
                      onChange={this.allowMandatoryAdvanceChange}
                    />
                    <label className="form-check-label" htmlFor="allow-advance-mandatory-payment">
                      <strong> Mandatory Advance Payment</strong>
                    </label>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="product-seo"
                      checked={allowPreOrder ? true : false}
                      value={allowPreOrder}
                      onChange={this.allowPreorderChnage}
                    />
                    <label className="form-check-label" htmlFor="product-seo">
                      <strong> Allow Pre Order </strong>
                    </label>
                    {allowPreOrder ? (
                      <div className="col-md-12">
                        <div className="col-md-6">
                          <Field
                            name="pre_order_shipping_estimate"
                            type="text"
                            component={renderField}
                            label="Pre Order Shipping Estimate"
                            value="15-45 Days"
                          />
                        </div>
                        <div className="col-md-6">
                          <Field
                            name="pre_order_policy"
                            type="text"
                            component={renderTextArea}
                            label="Pre Order Policy"
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-12">
                    < div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="is_whole_sell"
                        checked={allowWholeSell ? true : false}
                        value={allowWholeSell}
                        onChange={this.allowWholeSellChange}
                      />
                      <label className="form-check-label" htmlFor="product-seo">
                        <strong> Allow Whole Sell </strong>
                      </label>
                      {allowWholeSell ? (
                        <div className="col-md-12">
                          <div className="col-md-6">
                            <Field
                              name="whole_sell_price"
                              type="number"
                              component={renderField}
                              label="Whole Sell Price"

                            />
                          </div>
                          <div className="col-md-6">
                            <Field
                              name="purchase_quantity"
                              type="number"
                              component={renderField}
                              label="Minimum Purchase Quantity"
                              warn={alphaNumeric}
                            // required={true}
                            />
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="is_up_sell"
                        checked={allowUpsell ? true : false}
                        value={allowUpsell}
                        onChange={this.allowUpsellsChnage}
                      />
                      <label className="form-check-label" htmlFor="product-seo">
                        <strong> Allow Up Sells </strong>
                      </label>
                      {allowUpsell ? (
                        <>

                          <div className="col-md-12">

                            <label>Type Your Upsells Product</label>
                            <Autosuggest
                              suggestions={this.props.upsellProducts ? this.props.upsellProducts : []}
                              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              inputProps={inputProps}
                              onSuggestionSelected={this.onSuggestionSelected}
                            />
                          </div>
                          {this.state.UpsellList.length > 0 ? (
                            <>
                              <div className="col-md-12">
                                <strong>Upsells Product List</strong>
                              </div>

                              <div className="col-md-12">
                                <table className="table orderlist">
                                  <tr>
                                    <th>Image</th>
                                    <th>Product Name</th>
                                    <th>Product Sku</th>
                                    <th>Vendor/Seller</th>
                                    <th>Price</th>
                                    <th>Action</th>
                                  </tr>
                                  <tbody>
                                    {this.state.UpsellList.map((product, index) => (

                                      <tr id={product.id} key={index}>
                                        <td>
                                          <img src={product.feature_image} height="100px" width="100px" alt="No Image" />
                                        </td>
                                        <td>
                                          {product.product_name}
                                        </td>
                                        <td>
                                          {product.product_sku}
                                        </td>
                                        <td>
                                          {product.vendor ? product.vendor.shop_name : ''}
                                        </td>

                                        <td>
                                          {product.regular_price}
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-danger" id={product.id}
                                            onClick={() => this.deteteUpSellsRow(product)}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            />
                                          </button>

                                        </td>
                                      </tr>
                                    ))}
                                    {/* <tr>
                              <th colSpan="3" className="">Total</th>
                              <th colSpan="4" className="text-center">{totalQuantity}</th>
                            
                            </tr> */}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ) : null}
                        </>


                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="is_cross_sell"
                        checked={allowCrossSell ? true : false}
                        value={allowCrossSell}
                        onChange={this.allowCrossSellsChnage}
                      />
                      <label className="form-check-label" htmlFor="product-seo">
                        <strong> Allow Cross sells </strong>
                      </label>
                      {allowCrossSell ? (
                        <>

                          <div className="col-md-12">

                            <label>Type Your Cross sells Product</label>
                            <Autosuggest
                              suggestions={this.props.upsellProducts ? this.props.upsellProducts : []}
                              onSuggestionsFetchRequested={this.onCrossSuggestionsFetchRequested}
                              onSuggestionsClearRequested={this.onCrossSuggestionsClearRequested}
                              getSuggestionValue={this.getCrossSuggestionValue}
                              renderSuggestion={this.renderCrossSuggestion}
                              inputProps={inputCrossProps}
                              onSuggestionSelected={this.onCrossSuggestionSelected}
                            />
                          </div>
                          {this.state.crossSellList.length > 0 ? (
                            <>
                              <div className="col-md-12">
                                <strong>Cross Sells Product List</strong>
                              </div>

                              <div className="col-md-12">
                                <table className="table orderlist">
                                  <tr>
                                    <th>Image</th>
                                    <th>Product Name</th>
                                    <th>Product Sku</th>
                                    <th>Vendor/Seller</th>
                                    <th>Price</th>
                                    <th>Action</th>
                                  </tr>
                                  <tbody>
                                    {this.state.crossSellList.map((product, index) => (

                                      <tr id={product.id} key={index}>
                                        <td>
                                          <img src={product.feature_image} height="100px" width="100px" alt="No Image" />
                                        </td>
                                        <td>
                                          {product.product_name}
                                        </td>
                                        <td>
                                          {product.product_sku}
                                        </td>
                                        <td>
                                          {product.vendor ? product.vendor.shop_name : ''}
                                        </td>

                                        <td>
                                          {product.regular_price}
                                        </td>
                                        <td>
                                          <button
                                            className="btn btn-danger" id={product.id}
                                            onClick={() => this.deteteCrossSellRow(product)}
                                          >
                                            <i
                                              className="fa fa-trash"
                                              aria-hidden="true"
                                            />
                                          </button>

                                        </td>
                                      </tr>
                                    ))}
                                    {/* <tr>
                              <th colSpan="3" className="">Total</th>
                              <th colSpan="4" className="text-center">{totalQuantity}</th>
                            
                            </tr> */}
                                  </tbody>
                                </table>
                              </div>
                            </>
                          ) : null}
                        </>


                      ) : null}
                    </div>
                  </div>
                </div>


                <div className="col-md-12">

                  <label>Feature Image</label>
                  <br />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={this.onSelectFile}
                  />
                </div>
                <div className="col-md-6">
                  {src && (
                    <ReactCrop
                      src={src}
                      crop={crop}
                      ruleOfThirds
                      onImageLoaded={this.onImageLoaded}
                      onComplete={this.onCropComplete}
                      onChange={this.onCropChange}
                    />
                  )}
                </div>
                <div className="col-md-6">
                  {croppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{ maxWidth: "100%" }}
                      src={croppedImageUrl}
                      height="150px"
                      width="150px"
                    />
                  )}
                </div>
                <div className="col-md-12">
                  <strong>Uploaded Feature image</strong>
                  <img
                    alt="Uploaded Image"
                    style={{ maxWidth: "100%" }}
                    src={this.props.products.feature_image}
                    height="100px"
                    width="100px"
                  />

                </div>

                <div className="col-md-12">
                  <label>Gallery Images</label>
                  <br />

                  {gallery_images.map((images, idx) => (
                    <div key={images.index}>
                      <input
                        type="file"
                        onChange={this.handleChangeColoImage}
                        data-id={idx}
                        id={"file" + idx}
                        name="file"
                      />
                      { images.image ? (
                        <img
                          src={images.image}
                          height="40px"
                          width="40px"
                          data-id={idx}
                          id={"img" + idx}
                          alt="No Image"
                        />
                      ) : null
                      }
                          &nbsp;
                      <span>
                        {idx === 0 ? (
                          <button
                            onClick={() => this.addGalleryImage()}
                            type="button"
                            className="btn btn-primary text-center"
                          >
                            <i
                              className="fa fa-plus-circle"
                              aria-hidden="true"
                            />
                                Add More Images
                          </button>
                        ) : (
                            <button
                              className="btn btn-danger"
                              onClick={() => this.deteteGalleryImage(images)}
                            >
                              <i
                                className="fa fa-minus-circle"
                                aria-hidden="true"
                              />
                                Remove Image
                            </button>
                          )}
                      </span>
                    </div>

                  ))}
                  <div>

                    {this.state.gallery_uploaded_images ? (
                      this.state.gallery_uploaded_images.map((gallery_image, idx) => (
                        <div key={idx} className="col-md-6">
                          <strong>Uploaded Gallery Images</strong>
                          <div className="col-md-4">
                            <img
                              src={BASE_URL + gallery_image.image}
                              height="100px"
                              width="100px"
                              data-id={idx}
                              id={"img" + idx}
                              alt="No Image"
                            /><span> <button
                              className="btn btn-danger"
                              onClick={() => this.deteteUploadGalleryImage(gallery_image)}
                            >
                              <i
                                className="fa fa-minus-circle"
                                aria-hidden="true"
                              />
                          Remove Image
                          </button></span>
                          </div>
                        </div>

                      ))) : null
                    }
                  </div>
                </div>
              </div>
              <div></div>
            </div>

            <div className="card-footer">
              <div>
                <button
                  type="submit"
                  className="btn btn-success float-right"
                  disabled={submitting}
                >
                  <i className="fa fa-edit "></i>Update
                    </button>
                {/* <button
                      type="button"
                      className="btn btn-danger"
                      disabled={pristine || submitting}
                      onClick={reset}
                    >
                      Clear
                    </button> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}


ProductUpdate.propTypes = {
  addProduct: PropTypes.func.isRequired,
  deleteGallryImage: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  getPrimaryCategoryForSelect: PropTypes.func.isRequired,
  getChildCategoryForSelect: PropTypes.func.isRequired,
  getSecondaryCategoryForSelect: PropTypes.func.isRequired,
  getAttribute: PropTypes.func.isRequired,
  fetchSellersForSelect: PropTypes.func.isRequired,
  getAttributeOptionColor: PropTypes.func.isRequired,
  getAttributeOptionSize: PropTypes.func.isRequired,
  UpSellSearchProducts: PropTypes.func.isRequired,
  CrossSellSearchProducts: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  products: state.products.product,
  upsellProducts: state.upsellProducts.upsellProducts,
  crossSellProducts: state.crossSellProducts.crossSellProducts,
  is_added: state.products.is_added,
  is_updated: state.products.is_updated,
  is_loading:state.products.is_loading,
  sizes: state.attribute_options.size,
  product_units: state.product_units.product_units,
  primarycategory: state.primarycategory.primary_categories,
  childcategory: state.childcategory.child_categories,
  secondarycategory: state.secondarycategory.secondary_categories,
  attributes: state.attributes.attributes,
  attribute_options: state.attribute_options.attribute_options,
  sellers: state.sellers.sellers,
  tags: state.tags.tags,
  taxs: state.taxs.taxs,
  is_getting: state.products.is_getting,
  brands: state.brands.brands,

  initialValues: state.products.product,


});

ProductUpdate = reduxForm({
  form: 'ProductUpdateForm',
  enableReinitialize: true
}
)(ProductUpdate);

export default connect(mapStateToProps, {
  updateData,
  getPrimaryCategoryForSelect,
  getChildCategoryForSelect,
  getSecondaryCategoryForSelect,
  getAttribute,
  getAttributeOption,
  fetchSellersForSelect,
  productDetail,
  deleteGallryImage,
  getAttributeOptionColor,
  getAttributeOptionSize,
  getProductTagsForSelect,
  fetchBrandsAll,
  fetchProductUnitALL,
  getProductTaxsForSelect,
  UpSellSearchProducts,
  CrossSellSearchProducts,

})(ProductUpdate);
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { required } from "../../../constants/Validate";
import axios from 'axios'
import {CheckPermisionAction} from "../../../constants/CustomMethod"
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import isEqual from "react-fast-compare";
import { BulkAction } from '../../../actions/ProductAction';
import { USER_CREATE_URL,PRODUCT_BULK_URL,CHILD_CATEGORY_BULK_URL } from '../../../constants/api'


// import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import { Modal, Button } from 'react-bootstrap'

import CircularProgress from "@material-ui/core/CircularProgress";
import Swal from 'sweetalert2'

import { getChildCategory, updateChildCategory, deleteChildCategory, addChildCategory } from '../../../actions/ChildCategoryAction';
import { getSecondaryCategoryForSelect } from '../../../actions/SecondaryCategoryAction';
import { GET_CHILD_CATEGORY_URL } from '../../../constants/api';
// import { email } from '../../constants/Validate';
// import { loadUser } from '../../actions/auth';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

// Modals.setAppElement('#modalRender');

class ChildCategoryList extends Component {
    constructor() {
        super();
        this.opensweetalert = this.opensweetalert.bind(this)
        this.handleModalShow = this.handleModalShow.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleEditClose = this.handleEditClose.bind(this)
        this.updateChildCategory = this.updateChildCategory.bind(this)
        this.saveChildCategory = this.saveChildCategory.bind(this)
        this.childCategoryEdit = this.childCategoryEdit.bind(this)

    }


    state = {
        // search: " ",
        // phone_no: "",
        // regional:null,
        child_name: "",
        secondary_category: "",
        sec_category: "",
        image: null,
        child_category_image: null,
        name: "",
        show: false,
        showedit: false,
        isShowing: false,
        modalIsOpen: false,
        hideButton: false,
        allchecked: false,
        bulk:false,
        childcategories:[],
        load:false
    };

    secondaryChange = (e) => {
        this.setState({ sec_category: e.target.value })
    }

    handleModalShow() {
        this.setState({ show: true })
    }
    handleClose() {
        this.setState({ show: false })
    }
    handleEditClose() {
        this.setState({ showedit: false })
    }
    updateChildCategory(e) {
        var data = {
            name: this.state.child_name,
            image: this.state.image,
            secondary_category: this.state.sec_category,

        }
        // alert(JSON.stringify(data))
        this.props.updateChildCategory(this.state.id, data)
        this.setState({ showedit: false })
    }
    onChangeEdit() {
        this.setState({ showedit: true })
    }
    componentDidMount() {
        this.props.getChildCategory(1);
        this.props.getSecondaryCategoryForSelect();

    }

    opensweetalert(data) {

        Swal.fire({
            text: 'Are you want to delete ' + data.name + ' ?',
            // text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.value) {
                if (data.id) {
                    this.props.deleteChildCategory(data.id)
                    //alert(JSON.stringify(result))
                    Swal.fire(
                        'Deleted!',
                        'Your Data has been deleted ' + data.name + '.',
                        'success'
                    )
                }
            }
        })
    }

    handleBulkStatus = (e) => {

        if (value !== '') {
            var value = e.target.value
            var results = []

            for (var i = 0; i < this.state.childcategories.length; i++) {
                if (this.state.childcategories[i].checked) {
                    results.push(this.state.childcategories[i].id)
                }
            }

            if (value === "1") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, CHILD_CATEGORY_BULK_URL
                )
            } else if (value === "0") {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, CHILD_CATEGORY_BULK_URL
                )
            
            }else {
                this.props.BulkAction(
                    { 'id': results, 'action': value }, CHILD_CATEGORY_BULK_URL
                )
            }
        }
    }


    toggleAllChange=(e)=> {
        const { value, checked } = e.target;
        let childcategories = [...this.props.childcategory]
        childcategories.forEach(product => product.checked = e.target.checked)
        if (checked) {
            this.setState({ allchecked: checked, childcategories, bulk: true })
        } else {
            this.setState({ allchecked: checked, childcategories, bulk: false })
        }
    }

    onSingleChange = index => (e) => {
        let childcategories = [...this.state.childcategories]
        // alert(primcategories)
        childcategories[index]["checked"] = e.target.checked
        let recent_length = childcategories.filter(product => product.checked).length;

        if (childcategories.length === recent_length) {
            this.setState({ allchecked: true, bulk: true })
        } else {
            this.setState({ allchecked: false })
        }
        if (recent_length < 1) {
            this.setState({ bulk: false })
        } else {
            this.setState({ bulk: true })
        }
        // this.setState({ primcategories })

    };

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.is_list) {
    //  alert(JSON.stringify(nextProps.is_list))
            if (nextProps.is_list !== prevState.load) {
                var childcategories = []
                
                for (var i = 0; i < nextProps.childcategory.length; i++) {
                    Object.assign(nextProps.childcategory[i], { 'checked': false });
                    childcategories.push(nextProps.childcategory[i])
                    // alert(JSON.stringify(primcategories))
                }
                if (!isEqual(nextProps.childcategory, prevState.childcategories)) {
                    return {

                        childcategories:[...childcategories],
                        load: true
                    }
                  }
                

            }
        }else return null;

    }

    openModal() {
        this.setState({ modalIsOpen: true });
    }

    afterOpenModal() {
        // references are now sync'd and can be accessed.
        this.subtitle.style.color = '#f00';
    }

    closeModal() {
        this.setState({ modalIsOpen: false });
    }

    openModalHandler = () => {
        this.setState({
            isShowing: true
        })
    };

    closeModalHandler = () => {
        // const isShowing = this.state.isShowing;
        this.setState({
            isShowing: false
        })
    };

    onChange = e => this.setState({ [e.target.name]: e.target.value });

    onSubmit = e => {
        e.preventDefault();
        const { name } = this.state;
        console.log([name]);

        this.props.getChildCategory('', this.state);
        this.setState({ hideButton: true })

    };
    childCategoryEdit(data) {
        this.setState({ showedit: true, child_name: data.name, image: data.image, secondary_category: data.secondary_category, id: data.id })
    }
    saveChildCategory(event) {

        const { name, sec_category, child_category_image } = this.state
        var data = {
            name: name,
            child_category_image: child_category_image,
            secondary_category: sec_category,

        }
        // alert(JSON.stringify(data))
        this.props.addChildCategory(data)
        this.setState({ show: false, name: "", child_category_image: "", secondary_category: "" })
        // this.props.getChildCategory()
    }

    handlePageClick = (data) => {
        var pageNo = Number(data.selected) + 1
        this.props.getChildCategory(pageNo)

    }
    handleImageChange = (e) => {
        this.setState({
            image: e.target.files[0]
        })
    }
    handleImageSubmit = (e) => {
        e.preventDefault()
        console.log(this.state)
        let id = this.props.match.params.id;
        let form_data = new FormData()
        form_data.append('image', this.state.image, this.state.image.name)
        let token = localStorage.getItem('token')
        let url = GET_CHILD_CATEGORY_URL
        axios.post(url, form_data, {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }
        })
            .then(res => {
                toast.success("Successfully updated")
                console.log(res.data)
                window.location.reload()
            })
            .catech(err => console.log(err))
    }

    render() {

        const page = Math.ceil(this.props.count / 10)
        const { show, showedit, hideButton,allchecked,bulk } = this.state
        const { childcategory, is_loading, count ,permissions} = this.props
        if (this.props.is_updated) {
            setTimeout(function () {

                window.location.href = "/child-category-list"
            }, 3000)
        }
        if (this.props.is_added) {
            setTimeout(function () {

                window.location.href = "/child-category-list"
            }, 3000)
        }

        if (this.props.bulk) {
            setTimeout(function () {
                window.location.href = '/child-category-list'
            },
                1000);
        }

        return (
            <div>
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Child Category List </h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><Link className="text-info" to="/">Dashboard</Link></li>
                                    <li className="breadcrumb-item active">Child Category list</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>

                <div className="row">
                    <ToastContainer />



                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Child Category List</h3>
                                <div className="float-right">

                                {CheckPermisionAction(permissions,'Category','add_child_category')?

                                    <Link to="/add-child-category">
                                        <button className="btn btn-primary text-white" onClick={this.handleModalShow}>
                                            <b> <i className="fa fa-plus-circle"></i>
                                        Add
                                        Child Category</b></button></Link>
                                    :null}
                                </div>
                            </div>


                            <div style={{ margin: "15px" }} className="card-tools">
                                <div className="float-left">
                                    <form className="form-inline my-2 my-lg-0" onSubmit={this.onSubmit}>
                                        <input className="form-control mr-sm-4" name="name" type="text"
                                            onChange={this.onChange} placeholder=" Name"
                                            aria-label="Search" />
                                {CheckPermisionAction(permissions,'Category','child_category_search')?

                                        <button onClick={this.onSubmit} className="btn btn-outline-info my-4 my-sm-0 float-right"
                                            type="submit"><i className="fas fa-search"></i> Search
                                        </button>
                                    :null}
                                    </form>
                                </div>


                            </div>
                            {/* <!-- /.card-header --> */}
                            {/* <div id="modalRender">

                            </div> */}

                            <div className="col-md-3">
                                {bulk ? (
                                    <div className="float-left">
                                        <select
                                            name="bulk_status"
                                            component="select"
                                            className="form-control"

                                            onChange={this.handleBulkStatus}
                                        >
                                            <option value="">--Action--</option>
                                            <option value="1" >Activated</option>
                                            <option value="0">Deactivated</option>
                                            {/* <option value="2">Delete</option> */}
                                            

                                        </select>
                                    </div>
                                ) : ''
                                }
                            </div>
                                {CheckPermisionAction(permissions,'Category','view_child_category_list')?

                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover">
                                    <thead>
                                        <tr>
                                        <th>
                                                <input type="checkbox"
                                                    checked={allchecked}
                                                    onChange={this.toggleAllChange}
                                                />
                                            </th>
                                            <th>SL</th>
                                            <th>Name</th>
                                            <th>Image</th>
                                            <th>Secodary Category Name</th>
                                            <th>Slug</th>
                                            <th>Status</th>
                                            <th>Action</th>

                                            {hideButton ?
                                                <th><Link to="/child-category-list" onClick={() => { window.location.href = "/child-category-list" }} className="btn btn-info float-right text-white"> <i className="fa fa-arrow-left"> List </i>

                                                </Link></th> : null}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {is_loading ? <tr className="text-center">
                                            <td>
                                                <CircularProgress />
                                            </td>
                                        </tr> : childcategory.length !== undefined && childcategory.map((childcategory, index) => (
                                            <tr key={childcategory.id}>
                                                <td>
                                                    <input type="checkbox" onChange={this.onSingleChange(index)} checked={childcategory.checked ? true : false} value={childcategory.id} />
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>{childcategory.name}</td>
                                                <td>{
                                                    childcategory.image != null ? (
                                                        <img src={childcategory.image} height="100px" width="100px" alt="No Image" />
                                                    ) : null
                                                }</td>
                                                <td>{childcategory.secondary_categories.name}</td>

                                                <td>{childcategory.slug}</td>
                                                <td>{childcategory.is_active ===true? "Active":"Inactive"} </td>
                                                <td className="btn-group btn-group-sm">

                                                    <div className="btn-group">


                                                        <button type="button" className="btn btn-success dropdown-toggle" data-toggle="dropdown">Options</button>

                                                        <div className="dropdown-menu">
                                                            <a href={ "/child-category-update/"+childcategory.id} className="dropdown-item">Edit</a>
                                                            <div className="dropdown-divider"></div>
                                                            
                                                            <a href="#" onClick={() => this.opensweetalert(childcategory)} className="dropdown-item">Delete</a>

                                                        </div>
                                                    </div>
                                                    </td>

                                                {/* <td>
                                                    
                                                    {CheckPermisionAction(permissions,'Category','child_category_edit')?
                                                    
                                                    <button className="btn btn-warning text-white" onClick={() => this.childCategoryEdit(childcategory)}>
                                                        <i className="fas fa-eye text-white" >View</i>
                                                    </button>
                                                    :null}
                                                    
                                                    {CheckPermisionAction(permissions,'Category','child_category_delete')?
                                                    
                                                    <button className="btn btn-danger text-white" onClick={() => this.opensweetalert(childcategory)}>
                                                        <i className="fas fa-trash text-white" >Delete</i>
                                                    </button>
                                                    :null}
                                                    

                                                </td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            :null}
                            {/* <!-- /.card-body --> */}

                            <div className="card-footer clearfix">
                                {page > 1 ? (
                                    <ul className="pagination pagination-sm m-0 float-right">
                                        <ReactPaginate
                                            previousLabel={'previous'}
                                            nextLabel={'next'}
                                            breakLabel={'...'}
                                            breakClassName={'break-me'}
                                            pageCount={page}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={this.handlePageClick}
                                            containerClassName={'pagination'}
                                            subContainerClassName={'pages pagination'}
                                            activeClassName={'active'}
                                        />
                                    </ul>
                                ) : null
                                }
                            </div>
                            <Modal
                                show={show}
                                onHide={this.handleClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Add Child Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>


                                    <div className="col-6">
                                        <input
                                            name="name"
                                            type="text"
                                            className="form-control"
                                            placeholder="Child Category Name"
                                            validate={required}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label>Secondary Category</label>
                                        <span className="requiredField">*</span>
                                        <select name="sec_category" component="select" onChange={this.secondaryChange} className="form-control"
                                            validate={required}>
                                            <option value="">Select secondary category</option>
                                            {this.props.secondarycategory.map(sec_category => (
                                                <option key={sec_category.id} value={sec_category.value}>{sec_category.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* <div className="col-6">
                                        <label>Attribute</label>
                                        <span className="requiredField">*</span>
                                        <select name="attribute" multiple={true} type="select-multiple" component="select" onChange={this.onChange} className="form-control"
                                            validate={required}>
                                            secondaryChange
                                            {this.props.attributes.map(attribute => (
                                                <option key={attribute.id} value={attribute.id}>{attribute.name}</option>
                                            ))}
                                        </select>
                                    </div> */}
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.saveChildCategory}>
                                        Save
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal
                                show={showedit}
                                onHide={this.handleEditClose}
                                backdrop="static"
                                keyboard={false}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>Update Child Category</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <div className='col-6'>
                                        <input
                                            name="id"
                                            type="hidden"
                                            value={this.state.id}
                                            className="form-control"
                                            placeholder="Child Category id"
                                            validate={required}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className='col-6'>
                                        <input
                                            name="child_name"
                                            type="text"
                                            value={this.state.child_name}
                                            className="form-control"
                                            placeholder="Child Category Name"
                                            validate={required}
                                            onChange={this.onChange}
                                        />
                                    </div>
                                    <div className="col-md-6">
                                        {/* <button type="button" className="btn btn-info" data-toggle="modal"
                                            data-target="#UploadDrivingLicense">
                                            Upload Driving License
                                        </button> */}
                                        <br />
                                        <br />
                                        <img src={this.state.image}
                                            style={{ width: "250px" }} alt="Not Found" />
                                        <p>
                                            <a href={this.props.secondarycategory.image}>{this.props.secondarycategory.image}</a>
                                        </p>
                                    </div>
                                    <form onSubmit={this.handleImageSubmit}>
                                        <div className="modal-body">
                                            <p>
                                                <input type="file"
                                                    id="image"
                                                    onChange={this.handleImageChange}
                                                    required />
                                            </p>
                                        </div>

                                    </form>
                                    <div className="col-6">
                                        <label>Secondary Category</label>
                                        <span className="requiredField">*</span>
                                        <select name="sec_category" component="select" className="form-control" onClick={this.secondaryChange}
                                            validate={required}>
                                            <option value="">Select secondary category</option>
                                            {this.props.secondarycategory.map(sec_category => (
                                                <option key={sec_category.id} value={sec_category.value}>{sec_category.label}</option>
                                            ))}
                                        </select>
                                    </div>

                                    {/* <div className="col-6">
                                        <label>Attribute</label>
                                        <span className="requiredField">*</span>
                                        <select name="attribute" multiple={true} type="select-multiple" component="select" onChange={this.onChange} className="form-control"
                                            validate={required}>

                                            {this.props.attributes.map(attribute => (
                                                <option key={attribute.id} value={attribute.id}>{attribute.name}</option>
                                            ))}
                                        </select>
                                    </div> */}
                                </Modal.Body>

                                <Modal.Footer>
                                    <Button variant="secondary" onClick={this.handleEditClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" onClick={this.updateChildCategory}>
                                        Update
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                        </div>
                        {/* <!-- /.card --> */}
                    </div>
                </div>
                {/* <!-- /.row --> */}
            </div >
        )
    }
}

ChildCategoryList.propTypes = {
    getChildCategory: PropTypes.func.isRequired,
    deleteChildCategory: PropTypes.func.isRequired,
    BulkAction:PropTypes.func.isRequired,

};

const mapStateToProps = state => ({
    secondarycategory: state.secondarycategory.secondary_categories,
    childcategory: state.childcategory.child_categories,
    count: state.childcategory.count,
    next: state.childcategory.next,
    is_loading: state.childcategory.is_loading,
    is_added: state.childcategory.is_added,
    is_updated: state.childcategory.is_updated,
    auth: state.auth,
    permissions:state.userpermissions.userpermissions,
    bulk: state.products.bulk,
    is_list:state.childcategory.is_list,
});
// const modalElement = document.getElementById('modalRender');
// export default connect(mapStateToProps, {fetchRd, deleteRd})(ProductList);
export default connect(mapStateToProps, { addChildCategory, getChildCategory, deleteChildCategory, getSecondaryCategoryForSelect, updateChildCategory,BulkAction })(ChildCategoryList);

import {
    LOADING_ATTRIBUTE_OPTION,
    GETTING_ATTRIBUTE_OPTION,
    DETAIL_ATTRIBUTE_OPTION,
    UPDATE_ATTRIBUTE_OPTION,
    DELETE_ATTRIBUTE_OPTION,
    NOT_FOUND_ATTRIBUTE_OPTION,
    GETTING_ATTRIBUTE_OPTION_SIZE
} from '../actions/types';

const initialState = {
    attribute_options: [],
    attribute_option: {},
    size: [],
    is_getting_option: false,
    count: null,
    next: null,
    is_loading: false,
    errors: ''
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADING_ATTRIBUTE_OPTION:
            return {
                ...state,
                is_loading: true
            };
        case GETTING_ATTRIBUTE_OPTION:
            return {
                ...state,
                attribute_options: action.payload,
                count: action.count,
                is_getting_option: true,
                next: action.next,
                is_loading: false
            };
        case DETAIL_ATTRIBUTE_OPTION:
            return {
                ...state,
                attribute_option: action.payload,

            };
        case UPDATE_ATTRIBUTE_OPTION:
            return {
                ...state,
                attribute_options: action.payload,
                is_loading: true
            };
        case DELETE_ATTRIBUTE_OPTION:
            return {
                ...state,
                attribute_options: state.attribute_options.filter(attribute_option => attribute_option.id !== action.payload)
            };
        case GETTING_ATTRIBUTE_OPTION_SIZE:
            // alert(JSON.stringify(action.payload))
            return {
                ...state,
                size: action.payload,
                count: action.count,
                next: action.next,
                is_loading: false
            };
        case NOT_FOUND_ATTRIBUTE_OPTION:
            return {
                ...state,
                attribute_options: [],
                count: 0,
                next: 0,
                is_loading: false,

            };
        default:
            return state;
    }
}
import {
    LOADING_SECONDARY_CATEGORY,
    GETTING_SECONDARY_CATEGORY,
    ADD_SECONDARY_CATEGORY,
    DETAIL_SECONDARY_CATEGORY,
    UPDATE_SECONDARY_CATEGORY,
    DELETE_SECONDARY_CATEGORY,
    NOT_FOUND_SECONDARY_CATEGORY

} from './types';

import { GET_SECONDARY_CATEGORY_URL, GET_ALL_SECONDARY_CATEGORY_URL, GET_SECONDARY_CATEGORY_CREATE_URL } from '../constants/api';
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { SearchURLGenerate } from '../constants/CustomMethod'



// FETCH Secondary CATEGORY
export const getSecondaryCategory = (pageNumber, parameters = {}) => (dispatch, getState) => {
    dispatch({ type: LOADING_SECONDARY_CATEGORY })
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }


    let search_key = ["name",]

    var search_url = SearchURLGenerate(parameters, search_key);

    if ((search_url !== '' && search_url !== null) || pageNumber !== '') {
        var url = GET_SECONDARY_CATEGORY_URL
        if (search_url !== '' && search_url !== null) {
            url = url + "?" + search_url;
        } else if (pageNumber) {
            url = url + '?page=' + pageNumber
        }
        axios.get(url, { headers, })
            .then(response => {
                // alert(JSON.stringify(response))
                dispatch({
                    type: GETTING_SECONDARY_CATEGORY,
                    payload: response.data.results ? response.data.results : response.data,
                    count: response.data.count,
                    next: response.data.next
                })
            })
    } else {
        toast.warning("Not Found!!!");
        dispatch({ type: NOT_FOUND_SECONDARY_CATEGORY });

    }



};



// Secondary Category Detail
export const secondaryCategoryDetail = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let url = GET_SECONDARY_CATEGORY_URL + `${id}/`;

    axios.get(url, { headers })
        .then(response => {

            dispatch({
                type: DETAIL_SECONDARY_CATEGORY,
                payload: response.data,
            })
        })
};



// Update Secondary Category
export const updateSecondaryCategory = (id, data) => (dispatch, getState) => {

    let headers = { "Content-Type": "multipart/form-data" };
    // 'Content-Type': 'multipart/form-data'
    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    let form_data = new FormData();
    form_data.append('name', data.name);
    form_data.append('class_name', data.class_name);
    form_data.append('serial_no', data.serial_no);
    form_data.append('primary_category', data.primary_category)
    if (data.images !=null) {
        form_data.append('image', data.images, data.images.name);
    }
    // let form_data = {
    //     'name': data.name,
    //     'image': data.image,
    //     'primary_category': data.primary_category
    // }

    let url = GET_SECONDARY_CATEGORY_CREATE_URL + `${id}/`;

    axios.put(url, form_data, { headers })
        .then(response => {
            console.log(response.data)
            dispatch({
                type: UPDATE_SECONDARY_CATEGORY,
                payload: response.data
            });

            toast.success("Secondary Category successfully updated");
            console.log('Success');
        })
        .catch(err => {
            // dispatch();
            toast.error('Please try again', err);
            console.log(err);
        })
};


// Delete Secondary Category
export const deleteSecondaryCategory = id => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    axios
        .delete(GET_SECONDARY_CATEGORY_URL + `${id}/`, { headers })
        .then(response => {
            dispatch({
                type: DELETE_SECONDARY_CATEGORY,
                payload: id,
            });
            toast.success('Secondary Category deleted successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};


export const getSecondaryCategoryForSelect = (pageNumber, id) => (dispatch, getState) => {
    let headers = { "Content-Type": "application/json" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }

    dispatch({ type: LOADING_SECONDARY_CATEGORY });
    var url = GET_ALL_SECONDARY_CATEGORY_URL

    if (id) {
        url = GET_ALL_SECONDARY_CATEGORY_URL + `${id}/`;
    } else if (pageNumber) {
        url = GET_ALL_SECONDARY_CATEGORY_URL + '?page=' + pageNumber
    }
    axios.get(url, { headers, })

        .then(response => {
            var results = []
            for (var i = 0; i < response.data.length; i++) {
                results.push({ label: response.data[i].name, value: response.data[i].id })
            }
            dispatch({
                type: GETTING_SECONDARY_CATEGORY,
                payload: response.data ? results : [],
                count: response.data.count,
                next: response.data.next
            })
        })




};

// Add Secondary Category
export const addSecondaryCategory = (data) => (dispatch, getState) => {
    let headers = { "Content-Type": "multipart/form-data" };

    let { token } = getState().auth;
    if (token) {
        headers["Authorization"] = `Token ${token}`;
    }
    let form_data = new FormData()
    form_data.append('name', data.name)
    if (data.image) {
        form_data.append('image', data.image, data.image.name)
    }
    form_data.append('primary_category', data.primary_category)
    axios
        .post(GET_SECONDARY_CATEGORY_CREATE_URL, data, { headers })
        .then(response => {
            console.log(response.data)

            dispatch({
                type: ADD_SECONDARY_CATEGORY,
                payload: response.data.results ? response.data.results : response.data,
            });
            toast.success('Secondary Category added successfully');
            console.log(response.data)
        })
        .catch(error => {
            console.log(error)
        })
};
import {
    USER_STATISTICS
} from '../actions/types'

const initialState = {
  user_state: {}
};

export default function (state= initialState, action) {
    switch (action.type) {
        case USER_STATISTICS:
            return {
                ...state,
                user_state: action.payload
            };
        default:
            return state
    }
}

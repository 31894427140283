import React from 'react'
import {CSVLink} from 'react-csv'
import FileSaver from "file-saver";

import moment from 'moment'
 
import * as XLSX from 'xlsx/xlsx.mjs';


export const SearchURLGenerate = (parameters,search_key=[]) => {
var  search_url='';
var filter_params={}  
// alert(search_key)
if(search_key.length>0){

    
    

    for (var key in parameters) {
        // console.log("Data",filter_params[key]);
        // console.log(search_key.indexOf(key))
        if ((key, search_key.indexOf(key)) > -1 ) {
            filter_params[key]=parameters[key]
        }       
    }
}else{
    filter_params=parameters   
}
   
//console.log("gsasghf",filter_params.length)
if(Object.keys(filter_params).length>0){

        Object.keys(filter_params).forEach(function(key) {

            if( filter_params[key]!=='' &&  filter_params[key] !== null){
                search_url += key + '=' + filter_params[key] + "&"
                
            }
        
        
        });
        
        if(search_url !== '' && search_url !== null){
            
            search_url = search_url.slice(0, -1) //

        }
}
// alert(search_url)
    // if(parameters.agent_id != '' && parameters.agent_id != null){
   return search_url
       
}

export const findArrayElementByTitle = (array, status) => {

    return array.find((element) => {
      return element.id === status;
    })
  }

export const sumTotalAmount = (oderList) => {
    let totalAmount=0
    if(oderList.length>0){
        totalAmount = oderList.map( ( product ) => {
        return Number(product.subtotal);
        } ).reduce( ( total, current ) => total += current );
    }
    //this.setState( {
    //  totalAmount
    //} );
    return totalAmount;
}
    
export const sumTotalQuantity= (oderList) => {
    let totalQuantity=0
    if(oderList.length>0){
        totalQuantity = oderList.map( ( product ) => {
        return Number(product.quantity);
        } ).reduce( ( total, current ) => total += current );
    }
    //this.setState( {
    //  totalAmount
    //} );
    return totalQuantity;
}



export const sumTotalProductWeigth= (oderList) => {
    let totalProductWeigth=0
    if(oderList.length>0){
        totalProductWeigth = oderList.map( ( product ) => {
        return Number(product.unit_amount);
        } ).reduce( ( total, current ) => total += current );
    }
    //this.setState( {
    //  totalAmount
    //} );
    return totalProductWeigth;
}



export const ExportReactCSV = ({csvData, fileName}) => {
    return (
            <button className='btn btn-info'>
                
            <CSVLink className='text-white' data={csvData} filename={fileName}>Export</CSVLink>

            </button>
        
    )
}


export const CheckPermisionAction = (DataList, ActionName,visibilityAction='') => {
    var valid=false;
    if(DataList.length>0){
        let check = DataList.findIndex(o => o.content_type.app_label === ActionName);
    
        if(check>-1&visibilityAction!==''){
            let visibledata = DataList.filter(o => o.content_type.app_label === ActionName);
            var visibility=visibledata.findIndex(o => o.codename === visibilityAction)
            if(visibility>-1){
                valid=true;
            }
        }else if(check>-1&visibilityAction===''){
            valid=true;
        }
    }

    return valid
     
}


export const  calculateCouponDiscount = (discountType,couponType,amount,total_amount)=>{
    var discount=0
   
    if(discountType!=='' &&  couponType!==''){

        if(discountType===1 && amount<=total_amount){
            discount= (parseFloat(total_amount)*(parseFloat(amount)/100))
            // alert(discount)
        }else{
            discount=amount
        }
      
    }
    return parseFloat(discount)
}

export const getNumberToMonth=(number=0)=>{
    var monthName=''
    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    if (number>0 && number<=12){
         monthName=months[number-1]; 
    }
    return monthName
} 


export const exportToCSV = (csvData, fileName, wscols,Heading=[],header=[]) => {

let  fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  let fileExtension = ".xlsx";

//   let Heading = [
//     {
//       firstName: "First Name",
//       lastName: "Last Name",
//       email: "Email",
//       address: "Address",
//       postcode: "Postcode"
//     }
//   ];

    let ws = XLSX.utils.json_to_sheet(Heading, {
      header: header,
      skipHeader: true,
      origin: 0 //ok
    });
    ws["!cols"] = wscols;
    XLSX.utils.sheet_add_json(ws, csvData, {
      header: header,
      skipHeader: true,
      origin: -1 //ok
    });
    let wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    let excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    let data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName+ fileExtension);
  };



// Input Type

const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

export const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};



// Text area type
export const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};




export const convertDate =(str)=> {
    var date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
    return [day, mnth, date.getFullYear(),].join("-");
}
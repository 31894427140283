import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form';
import axios from 'axios';
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { GET_CHILD_CATEGORY_URL, GET_CHILD_CATEGORY_CREATE_URL } from "../../../constants/api";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { getChildCategory, addChildCategory } from '../../../actions/ChildCategoryAction'
import { getSecondaryCategoryForSelect } from '../../../actions/SecondaryCategoryAction'


import {
    renderDateTimePicker,

} from '../../../constants/FormInputType';
import {


    productName,
    productSKU,
    purchasePrice,
    permanentAddress,
    presentAddress,
    phoneNumber,
    nidOrPassport,
    match,
    number
} from '../../../constants/Validate'
import { connect } from 'react-redux';
import { propTypes } from 'react-widgets/lib/DateTimePicker';




const required = value => (value || typeof value === 'number' ? undefined : 'Required');



const alphaNumeric = value =>
    value && /[^a-zA-Z0-9 ]/i.test(value)
        ? 'Only alphanumeric characters'
        : undefined;


const renderField = ({ input, label, type, meta: { touched, error, warning }, required = false }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div className="form-group">
            <label className="text-sm">{label}</label>{required ? <span className='requiredField'>*</span> : ""}
            <div>
                <input {...input} placeholder={label} type={type} className={className} />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>
    )
};



// Text area type
const renderTextArea = ({ input, label, meta: { touched, error, warning } }) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <label className="text-sm">{label}</label>
            <div>
                <textarea {...input} placeholder={label} rows="3" cols="40" className={className} />
                {touched && ((error && <span className="text-danger">{error}</span>) || (warning &&
                    <span>{warning}</span>))}
            </div>
        </div>
    )
};

// File Input type
const adaptFileEventToValue = delegate => e => delegate(e.target.files[0]);

const FileInput = ({
    input: { value: omitValue, onChange, label, onBlur, ...inputProps },
    meta: { omitMeta, touched, error, warning },
    ...props
}) => {
    const className = `form-control ${error && touched ? 'is-invalid' : ''}`;
    return (
        <div>
            <div className="form-group">
                <input
                    onChange={adaptFileEventToValue(onChange)}
                    onBlur={adaptFileEventToValue(onBlur)}
                    type="file"
                    {...props.input}
                    {...props}
                    className={className}
                />
                {touched &&
                    ((error && <span className="text-danger">{error}</span>) ||
                        (warning && <span>{warning}</span>))}
            </div>
        </div>

    );
};


const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));

const submitToServer = (data) => {

    console.log('data', data);
    let form_data = new FormData();

    form_data.append('name', data.name);
    form_data.append('class_name', data.class_name);
    form_data.append('serial_no', data.serial_no);
    if (data.image) {
        form_data.append('image', data.image, data.image.name);
    }
    form_data.append("secondary_category", data.secondary_category)



    console.log('data', form_data);


    let url = GET_CHILD_CATEGORY_CREATE_URL;

    let token = localStorage.getItem('token');
    // window.alert(`I submited:: \n${JSON.stringify(form_data, null, 2)}`);
    console.log(token);
    axios.post(url, form_data,
        {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': `Token ${token}`
            }

        })
        .then(res => {
            console.log(res.data);
            // dispatch(reset('PartnerAddForm'))
            toast.success('Successfully added a new Child Category.')
        })
        .catch(err => console.log(err))
};


class AddChildCategory extends Component {
    state = {
        options:
            [
                { label: "Electronics", value: 1 },
                { label: "Fashion", value: 2 },
            ],
        selectedOption: null,
        secondary: null,
        child: null

    }
    handleChange = selectedOption => {
        //  alert(selectedOption)
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };
    secondaryChange = secondary => {
        //  alert(selectedOption)
        this.setState({ secondary });
        //   console.log(`Option selected:`, selectedOption);
    };
    childChange = child => {
        //  alert(selectedOption)
        this.setState({ child });
        //   console.log(`Option selected:`, selectedOption);
    };

    componentDidMount() {
        this.props.getSecondaryCategoryForSelect(0)
        this.props.getChildCategory()
    }

    submit(values) {
        const { reset } = this.props;
        return sleep(1000).then(() => {
            // console.log(values);
            submitToServer(values);
            reset();
        });
    }

    render() {
        // alert(this.props.childcategory.name)
        const { handleSubmit, pristine, reset, submitting } = this.props;
        if (this.props.is_added) {
            setTimeout(function () {

                window.location.href = "/child-category-list"
            }, 3000)
        }

        return (
            <div>
                <div className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1 className="m-0 text-dark">Add Child Category</h1>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title">Add Child Category</h3>
                        <div className="float-right">
                            <Link to="/child-category-list" className="btn btn-primary float-right text-white"><b>
                                <i className="fa fa-backward"></i>
                                Back To List</b>
                            </Link>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit(this.submit.bind(this))}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-6">
                                    <Field
                                        name="name"
                                        type="text"
                                        component={renderField}
                                        label="Child Category Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="serial_no"
                                        type="text"
                                        component={renderField}
                                        label="Serial No"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Field
                                        name="class_name"
                                        type="text"
                                        component={renderField}
                                        label="Class Name"
                                        validate={required}

                                    // warn={alphaNumeric}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label className="text-sm">Child Category Image</label>
                                    <Field
                                        name="image"
                                        type="file"
                                        component={FileInput}
                                        accept="image/*"
                                        label="Child Category image"
                                    // validate={[required]}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <label>Secondary Category</label>
                                    <span className="requiredField">*</span>
                                    <Field name="secondary_category" component="select" className="form-control"
                                        validate={required}>
                                        <option value="">Select secondary category</option>
                                        {this.props.secondarycategory.map(sec_category => (
                                            <option key={sec_category.id} value={sec_category.value}>{sec_category.label}</option>
                                        ))}
                                    </Field>
                                </div>

                                {/* <div className="col-md-6">
                                    <label>Secondary Caregory</label><span className='requiredField'>*</span>
                                    <Select
                                        value={this.state.selectedOption}
                                        onChange={this.handleChange}
                                        options={this.state.options}
                                        placeholder="Select Secondary Caregory"
                                        isMulti
                                        isClearable
                                    />
                                </div> */}
                            </div>
                        </div>

                        <div className="card-footer">
                            <div>
                                <button
                                    type="submit"
                                    className="btn btn-info float-right"
                                    disabled={submitting}>
                                    Submit
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    disabled={pristine || submitting}
                                    onClick={reset}>
                                    Clear
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}


AddChildCategory.propTypes = {
    getChildCategory: PropTypes.func.isRequired,
    getSecondaryCategoryForSelect: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
    secondarycategory: state.secondarycategory.secondary_categories,
    childcategory: state.childcategory.child_categories,
    is_added: state.childcategory.is_added
})

AddChildCategory = reduxForm({
    form: 'AddChildCategory',
})(AddChildCategory);


export default connect(mapStateToProps, { getChildCategory, addChildCategory, getSecondaryCategoryForSelect })(AddChildCategory);

